import React, { Dispatch, SetStateAction } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { NumberFormatCustom } from '../../../shared/components/ui/NumberFormat';
import { AltSwitch } from '../../../shared/components/ui/inputs/AltSwitch';
import { BACKGROUND_COLOR, ERROR_COLOR } from '../../../shared/theme';
import {
  AutoPayCreateUpdateDialogState,
  ErrorStateType,
} from './AutoPayCreateUpdateDialog';

type PayInFullTextFieldProps = {
  amount?: number;
  payInFull: boolean;
  stateErrors: {
    begins: boolean;
    expires: boolean;
    payInFull: boolean;
    chargeOn: boolean;
    amount: boolean;
    acceptedTermsAndConditions: boolean;
  };
  setState: Dispatch<SetStateAction<AutoPayCreateUpdateDialogState>>;
  initialStateErrors: ErrorStateType;
  setStateErrors: React.Dispatch<React.SetStateAction<ErrorStateType>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payInFullBox: {
      padding: theme.spacing(3, 1),
      backgroundColor: BACKGROUND_COLOR,
    },
    typographyBold: {
      fontWeight: 'bolder',
    },
    payInFullHelperText: {
      marginTop: '20px',
      color: ERROR_COLOR,
    },
  }),
);

export const PayInFullTextField: React.FC<PayInFullTextFieldProps> = (
  props,
) => {
  const {
    amount,
    payInFull,
    stateErrors,
    setState,
    setStateErrors,
    initialStateErrors,
  } = props;
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Box width="100%" className={classes.payInFullBox}>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            mb="20px">
            <Typography className={classes.typographyBold}>
              Pay Balance in Full
            </Typography>
            <AltSwitch
              checked={payInFull}
              onChange={() => {
                setState((prevProps) => ({
                  ...prevProps,
                  payInFull: !payInFull,
                }));
                setStateErrors(initialStateErrors);
              }}
              color="primary"
            />
          </Box>
          <TextField
            error={stateErrors.amount}
            variant="outlined"
            fullWidth
            placeholder={'Enter amount'}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            value={amount}
            disabled={payInFull}
            onChange={(event) => {
              if (Number(event.target.value) < 0) {
                setState((prevProps) => ({
                  ...prevProps,
                  amount: 0,
                }));
                setStateErrors(initialStateErrors);
              } else {
                setState((prevProps) => ({
                  ...prevProps,
                  amount: parseInt(event.target.value, 10),
                }));
                setStateErrors(initialStateErrors);
              }
            }}
          />
          {(stateErrors.amount || stateErrors.payInFull) && (
            <Box width="100%" className={classes.payInFullHelperText}>
              <Typography variant={'body2'}>
                If the AutoPay is not set to pay in full, you should specify an
                amount.
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};
