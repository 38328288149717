import {
  OpportunityNotification,
  LeaseStatus,
} from '@8baselabs/resident-io-shared';

export type OpportunitiesNotificationsActions = {
  type: number;
  payload: {
    opportunityNotification?: OpportunityNotification;
    opportunitiesNotifications?: OpportunityNotification[];
  };
};

export enum OpportunitiesNotificationsActionsTypes {
  ADD_EVENT,
  SET_STATE,
}

export enum LeaseCategories {
  OPEN_INVITATION = 'Open Invitation',
  IN_PROCESS = 'In Process',
  MANAGER_READY = ' Manager Ready',
  APPROVED = 'Approved',
  TOTAL = 'Total',
  NEW = 'New,',
}

export type FilterLeaseType = {
  property: string;
  leasePeriod: string;
  unit: string;
  building: string;
  bed: string;
  room: string;
  floor: string;
  moveOut: string;
  moveIn: string;
};

export enum StatusValues {
  NEW = 'New',
  LEASE_PERIOD = 'Bed Selection',
  BED_SELECTED = 'Bed Selected',
  SELECT_PAYMENT_PLAN = 'Payment Plan Selection',
  FINALIZED = 'Finalized Lease',
  APPLICATION = 'Payment Plan Selected',
  MANAGER_READY = 'Manager Ready',
  PAYMENT_FAILED = 'Payment Failed',
  CONTRACT = 'Application Completed',
  REQUIRED_DOCUMENTS = 'Documents Required',
  PAYMENT_PROCESSING = 'Payment Processing',
  GUARANTOR = 'Guarantor Required',
  PAID = 'Paid',
  PAYMENT = 'Contract Completed',
  APPROVED = 'Approved',
  DENIED = 'Denied',
  'MOVE-IN' = 'Move In',
  'MOVE-OUT' = 'Move Out',
  'MOVE-OUT_REFUND_ISSUED' = 'Move Out Refund Issued',
  'MOVE-OUT_BALANCE_OWED' = 'Move Out Balance Owed',
  DRAFT = 'Draft',
}

export const LeaseStatusValues = {
  NEW: {
    key: 'NEW',
    value: StatusValues.NEW,
  },
  DENIED: {
    key: LeaseStatus.DENIED,
    value: StatusValues.DENIED,
  },
  SELECT_UNIT: {
    key: LeaseStatus.SELECT_UNIT,
    value: StatusValues.BED_SELECTED,
  },
  SELECT_PAYMENT_PLAN: {
    key: LeaseStatus.SELECT_PAYMENT_PLAN,
    value: StatusValues.SELECT_PAYMENT_PLAN,
  },
  LEASE_PERIOD: {
    key: LeaseStatus.LEASE_PERIOD,
    value: StatusValues.LEASE_PERIOD,
  },
  OPTIONAL_PREFERENCE: {
    key: LeaseStatus.OPTIONAL_PREFERENCE,
    value: StatusValues.APPLICATION,
  },
  FRIENDS: {
    key: LeaseStatus.FRIENDS,
    value: StatusValues.APPLICATION,
  },
  CONTRACT: {
    key: LeaseStatus.CONTRACT,
    value: StatusValues.CONTRACT,
  },
  PAYMENT: {
    key: LeaseStatus.PAYMENT,
    value: StatusValues.PAYMENT,
  },
  PAID: {
    key: LeaseStatus.PAID,
    value: StatusValues.PAID,
  },
  ON_REVIEW: {
    key: LeaseStatus.ON_REVIEW,
    value: StatusValues.MANAGER_READY,
  },
  FINALIZED: {
    key: LeaseStatus.FINALIZED,
    value: StatusValues.FINALIZED,
  },
  APPROVED: {
    key: LeaseStatus.APPROVED,
    value: StatusValues.APPROVED,
  },
  APLICATION: {
    key: LeaseStatus.APLICATION,
    value: StatusValues.APPLICATION,
  },
  REQUIRED_DOCUMENTS: {
    key: LeaseStatus.REQUIRED_DOCUMENTS,
    value: StatusValues.REQUIRED_DOCUMENTS,
  },
  PAYMENT_PROCESSING: {
    key: LeaseStatus.PAYMENT_PROCESSING,
    value: StatusValues.PAYMENT_PROCESSING,
  },
  PAYMENT_FAILED: {
    key: LeaseStatus.PAYMENT_FAILED,
    value: StatusValues.PAYMENT_FAILED,
  },
  'MOVE-IN': {
    key: LeaseStatus['MOVE-IN'],
    value: StatusValues['MOVE-IN'],
  },
  'MOVE-OUT': {
    key: LeaseStatus['MOVE-OUT'],
    value: StatusValues['MOVE-OUT'],
  },
  'MOVE-OUT_BALANCE_OWED': {
    key: LeaseStatus['MOVE-OUT_BALANCE_OWED'],
    value: StatusValues['MOVE-OUT_BALANCE_OWED'],
  },
  'MOVE-OUT_REFUND_ISSUED': {
    key: LeaseStatus['MOVE-OUT_REFUND_ISSUED'],
    value: StatusValues['MOVE-OUT_REFUND_ISSUED'],
  },
  GUARANTOR: {
    key: LeaseStatus.GUARANTOR,
    value: StatusValues.GUARANTOR,
  },
};

export const NON_RESETEABLE_STATUS = [
  StatusValues.APPROVED,
  StatusValues.DENIED,
  StatusValues['MOVE-IN'],
  StatusValues['MOVE-OUT'],
  StatusValues['MOVE-OUT_BALANCE_OWED'],
  StatusValues['MOVE-OUT_REFUND_ISSUED'],
];
