import { useAuth0 } from '@auth0/auth0-react';
import { useSubscription } from '@8baselabs/react-simple-state';
import {
  createStyles,
  makeStyles,
  Grid,
  Theme,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { AlertContext } from '../../../routes/AlertContext';
import { TextFieldDefault } from '../../../shared/components/ui/inputs/TextFieldDefault';
import { useSession } from '../../session/session-hooks';
import { changeEmailAction, changePasswordAction } from './my-info-actions';
import {
  ChangeEmailError,
  ChangeEmailEvent,
  ChangePasswordError,
  ChangePasswordEvent,
} from './my-info-events';
import { securityValidationSchema } from './my-info-validators';
import { MyInfo } from './MyInfo';
import { Divider } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    title: {
      fontSize: '20px',
      lineHeight: '48px',
    },
    verificationContainer: {
      border: 'solid 1px  #D2D2D2',
      borderRadius: 5,
      padding: `${theme.spacing(2, 2.5, 1.5, 2.5)}!important`,
      margin: '12px',
      '& .MuiTypography-root': {
        fontWeight: 'normal',
      },
    },
  }),
);

export const Security: React.FC = () => {
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const [session] = useSession();
  const { user } = useAuth0();
  const { logout } = useAuth0();

  const formik = useFormik({
    initialValues: {
      updateEmail: false,
      email: '',
      newEmail: '',
      confirmNewEmail: '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    initialErrors: {
      updateEmail: '',
      email: '',
      newEmail: '',
      confirmNewEmail: '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    isInitialValid: true,
    validationSchema: securityValidationSchema,
    onSubmit({ updateEmail }) {
      if (updateEmail) {
        changeEmailAction({
          newEmail: formik.values.newEmail,
          email: user?.email,
          userId: user?.sub,
          id: session?.user.id,
        });
      } else {
        changePasswordAction({
          password: formik.values.password,
          newPassword: formik.values.newPassword,
          email: user?.email,
          userId: user?.sub,
        });
      }
    },
  });

  const changingPassword =
    !!formik.values.password ||
    !!formik.values.confirmNewPassword ||
    !!formik.values.newPassword;

  const changingEmail =
    !!formik.values.newEmail || !!formik.values.confirmNewEmail;

  useSubscription(ChangePasswordEvent, (data) => {
    formik.setSubmitting(false);
    if (!data?.validCurrentPassword) {
      formik.setErrors({ password: 'Invalid password' });
    } else if (!data.success) {
      alert({
        error: true,
        message: 'Something went wrong. Password could not be changed',
      });
    } else {
      alert({
        success: true,
        message: 'Password changed successfully',
      });
      logout({ localOnly: true });
    }
  });

  useSubscription(ChangePasswordError, () => {
    formik.setSubmitting(false);
    alert({
      error: true,
      message: 'Something went wrong. Password could not be changed',
    });
  });

  useSubscription(ChangeEmailEvent, (data) => {
    formik.setSubmitting(false);
    if (!data?.validCurrentEmail) {
      formik.setErrors({ email: 'Invalid email' });
    } else if (!data.success) {
      alert({
        error: true,
        message: 'Something went wrong. Email could not be changed',
      });
    } else {
      alert({
        success: true,
        message: 'Email changed successfully',
      });
      setTimeout(() => logout({ localOnly: true }), 3000);
    }
  });

  useSubscription(ChangeEmailError, () => {
    formik.setSubmitting(false);
    alert({
      error: true,
      message: 'Someting went wrong. Email could not be changed',
    });
  });

  useEffect(() => {
    formik.setValues({
      ...formik.initialValues,
      email: session?.user.email as string,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user]);

  useEffect(() => {
    if (changingEmail) {
      formik.setFieldValue('updateEmail', {
        updateEmail: true,
      });
    } else {
      formik.setFieldValue('updateEmail', {
        updateEmail: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingEmail]);

  return (
    <MyInfo session={session} loading={false}>
      <Grid container justifyContent="space-around" alignItems="baseline">
        <Grid item xs={11}>
          <Typography variant="h5" className={classes.header}>
            Login Settings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box padding={4}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="baseline"
                spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldDefault
                    label="YOUR EMAIL"
                    name="email"
                    disabled={changingPassword || !!formik.values.email}
                    placeholder="Enter your email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldDefault
                    label="NEW EMAIL"
                    name="newEmail"
                    disabled={changingPassword}
                    placeholder="Enter your new email"
                    value={formik.values.newEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newEmail && Boolean(formik.errors.newEmail)
                    }
                    helperText={formik.errors.newEmail}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldDefault
                    label="CONFIRM YOUR NEW EMAIL"
                    disabled={changingPassword}
                    name="confirmNewEmail"
                    placeholder="Re-enter your email"
                    value={formik.values.confirmNewEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmNewEmail &&
                      Boolean(formik.errors.confirmNewEmail)
                    }
                    helperText={formik.errors.confirmNewEmail}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldDefault
                    name="password"
                    label="YOUR PASSWORD"
                    disabled={changingEmail}
                    type={'password'}
                    placeholder="Enter your password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={formik.errors.password}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldDefault
                    name="newPassword"
                    label="NEW PASSWORD"
                    disabled={changingEmail}
                    placeholder="Enter your new password"
                    type={'password'}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.newPassword &&
                      Boolean(formik.errors.newPassword)
                    }
                    helperText={formik.errors.newPassword}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldDefault
                    name="confirmNewPassword"
                    placeholder="Re-enter your new password"
                    label="CONFIRM YOUR NEW PASSWORD"
                    type={'password'}
                    disabled={changingEmail}
                    value={formik.values.confirmNewPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmNewPassword &&
                      Boolean(formik.errors.confirmNewPassword)
                    }
                    helperText={formik.errors.confirmNewPassword}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    After saving your new email or new password you will be
                    logged out and will then be able to log back in using your
                    new email or new password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    {/* <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => formik.resetForm()}
                        fullWidth>
                        Cancel
                      </Button>
                    </Grid>*/}
                    <Grid item>
                      <Button
                        disabled={formik.isSubmitting || !formik.dirty}
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </MyInfo>
  );
};
