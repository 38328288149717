import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles({
  boldTitle: {
    fontWeight: 700,
  },
  subtitleBold: {
    fontWeight: 600,
  },
  subtitle: {
    fontWeight: 500,
  },
  subtitleMuted: {
    fontWeight: 400,
    color: '#9E9E9E',
  },
});

export const BoldTitle: React.FC<TypographyProps> = ({
  variant = 'h3',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography {...rest} variant={variant} className={classes.boldTitle} />
  );
};

export const PageTitle: React.FC<TypographyProps> = ({
  variant = 'h4',
  ...rest
}) => <Typography {...rest} variant={variant} />;

export const SetionTitle: React.FC<TypographyProps> = ({
  variant = 'h6',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography {...rest} variant={variant} className={classes.subtitle} />
  );
};

export const Subtitle: React.FC<TypographyProps> = ({
  variant = 'subtitle1',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={`${classes.subtitle} ${rest.className}`}
      {...rest}
    />
  );
};

export const SubtitleMuted: React.FC<TypographyProps> = ({
  variant = 'subtitle2',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={clsx(rest.className, classes.subtitleMuted)}
      {...rest}
    />
  );
};

export const SubtitleBold: React.FC<TypographyProps> = ({
  variant = 'h5',
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant={variant}
      className={`${classes.subtitleBold} ${rest.className}`}
      {...rest}
    />
  );
};
