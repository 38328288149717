import { useFetchAction, useSubscription } from '@8baselabs/react-simple-state';
import { Lease, RequiredFile } from '@8baselabs/resident-io-shared';
import { Box, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { PageTitle } from '../../../shared/components/ui/texts/CommonTexts';
import addDocumentSvg from '../../../shared/assets/addDocument.svg';
import { UploadDocumentModal } from '../components/UploadDocumentModal';
import { DocumentRowDef, DocumentsTable } from '../components/DocumentsTable';
import { MainLoader } from '../../../shared/components/MainLoader';
import { AddCircleButton } from '../../../shared/components/ui/buttons/AddCircleButton';
import {
  createDocumentsEvent,
  deleteDocumentEvent,
  deleteDocumentsEvent,
} from '../people-event';
import {
  getRequiredDocuments,
  getUserRequiredFiles,
} from '../../opportunities/opportunities-actions';
import { useParams } from 'react-router';
import moment from 'moment';

export const DocumentView: React.FC<{ selectedLease?: Lease }> = ({
  selectedLease,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id: userId } = useParams<{ id: string }>();
  const [uploads, loadingUploads] = useFetchAction(
    getRequiredDocuments,
    [selectedLease?.property?.id],
    {
      skip: !selectedLease,
    },
  );

  const signedDocuments = useMemo(() => {
    const formatL = moment.localeData().longDateFormat('ll');
    const contract =
      selectedLease?.leaseContractDocumentRelation?.items.map((cd) => {
        return {
          id: cd.id ?? '',
          name: 'Lease Agreement',
          type:
            `${cd.signer === 'RESIDENT' ? 'TENANT' : cd.signer} - Contract` ??
            '',
          date: moment(cd.updatedAt).format(formatL),
          actions: '',
          downloadUrl: cd.downloadURL ?? '',
        };
      }) ?? [];
    const terms =
      selectedLease?.leaseTermsDocumentRelation?.items.map((ta) => {
        return {
          id: ta.id ?? '',
          name: 'Terms and Conditions',
          type:
            `${ta.signer === 'RESIDENT' ? 'TENANT' : ta.signer} - Terms` ?? '',
          date: moment(ta.updatedAt).format(formatL),
          actions: '',
          downloadUrl: ta.downloadURL ?? '',
        };
      }) ?? [];
    return contract?.concat(terms);
  }, [selectedLease]);

  const [files, loadingFiles, { refetch: refetchFiles }] = useFetchAction(
    getUserRequiredFiles,
    [userId],
    {
      skip: !userId,
    },
  );

  const handleClose = (): void => {
    setIsOpen(false);
  };
  const handleOpen = (): void => {
    setIsOpen(true);
  };
  useSubscription(createDocumentsEvent, () => {
    refetchFiles();
    handleClose();
  });
  useSubscription(deleteDocumentEvent, () => {
    refetchFiles();
  });
  useSubscription(deleteDocumentsEvent, () => {
    refetchFiles();
  });

  return (
    <Box p="40px" height="1000px">
      <Box display="flex" justifyContent="space-between">
        <PageTitle variant="h5">Documents</PageTitle>
      </Box>
      {loadingFiles || loadingUploads ? (
        <MainLoader />
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end" alignItems="end">
            {files?.length !== 0 && (
              <AddCircleButton onClick={handleOpen}>
                {' '}
                Upload Documents
              </AddCircleButton>
            )}
          </Box>
          {files?.length !== 0 ? (
            <DocumentsTable
              files={files}
              signedDocuments={signedDocuments as DocumentRowDef[]}
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="center"
              justifyContent="center">
              <Box width="210px" height="210px" marginBottom="14px">
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={addDocumentSvg}
                  alt="Add Document"
                />
              </Box>
              <Typography variant="body2">
                We couldn’t find any documents
              </Typography>
              <Box onClick={handleOpen} width="210px" marginTop="22px">
                <ButtonAction fullWidth>ADD A DOCUMENT</ButtonAction>
              </Box>
            </Box>
          )}
          <UploadDocumentModal
            onClose={handleClose}
            isOpen={isOpen}
            requiredUploads={uploads as DocumentType[]}
            uploadedFiles={files as RequiredFile[]}
            userId={userId}
            RefetchFiles={refetchFiles}
          />
        </>
      )}
    </Box>
  );
};
