import React, { Dispatch, SetStateAction } from 'react';
import {
  alpha,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  InputBase,
  makeStyles,
  Theme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
/* import FilterListIcon from '@material-ui/icons/FilterList'; */
import { BORDER_COLOR, MUTED_COLOR } from '../../shared/theme';
import { multiTriggeredActionHandler } from '../../shared/utils';
import {
  OnOpenInboxMessageModal,
  onSetSelectedThreadId,
} from '../../modules/inbox/inbox-event';
import { IconButton } from '@mui/material';
import { deleteThread } from '../../modules/inbox/inbox-actions';
import { useCallAction, useEvent } from '@8baselabs/react-simple-state';
import { InsertOnField } from '../../modules/settings-view/message-templates/components/InsertVariablesButton';

import { useLocalStorage } from './components-hooks';
import { Property } from '../../schema-types';
import { fetchUserEvent } from '../../modules/people/people-event';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      width: 'calc(100% - 330px)',
      backgroundColor: alpha(MUTED_COLOR, 0.1),
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: '16px 14px',
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      borderRadius: '25px',
      border: `1px solid ${BORDER_COLOR}`,
    },
    search: {
      position: 'relative',
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      border: `1px solid ${BORDER_COLOR}`,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      width: 200,
      marginLeft: 60,
    },
    searchContainer: {
      padding: '0',
    },
    searchIcon: {
      alignItems: 'center',
      color: MUTED_COLOR,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      pointerEvents: 'none',
      position: 'absolute',
    },
    iconContainer: {
      lineHeight: '50px',
      paddingLeft: '15px',
    },
    circularProgress: {
      lineHeight: '80px',
      paddingLeft: '15px',
    },
  }),
);

type MessagesToolbarProps = {
  filterBy: {
    search: string;
    idFrom: string[];
    idTo: string[];
    conversation: boolean;
    switches?: {
      read: boolean;
    };
  };
  selectedPropertiesCount?: number;
  setFilterBy: Dispatch<
    React.SetStateAction<{
      search: string;
      idFrom: string[];
      idTo: string[];
      conversation: boolean;
      switches?: {
        read: boolean;
      };
    }>
  >;
  sortMessagesDesc: boolean;
  isPM?: boolean;
  setSortMessagesDesc: Dispatch<SetStateAction<boolean>>;
  userIDs?: string[];
  leaseProperty?: Property;
  isPeopleProfile?: boolean;
  leaseId?: string;
};

export const MessagesToolbar: React.FC<MessagesToolbarProps> = (props) => {
  const {
    filterBy,
    selectedPropertiesCount = 0,
    setFilterBy,
    isPM = true,
    sortMessagesDesc,
    setSortMessagesDesc,
    userIDs,
    leaseProperty,
    isPeopleProfile,
    leaseId,
  } = props;
  const classes = useStyles();

  const threadEvent = useEvent(onSetSelectedThreadId);

  const [onDeleteThread, isLoadingDelete] = useCallAction(deleteThread, {
    onCompleted: () => {
      onSetSelectedThreadId.dispatch({
        selectedThreadId: '',
      });
    },
  });

  const fromUser = useEvent(fetchUserEvent);

  return (
    <>
      <Grid item className={classes.searchContainer}>
        <InputBase
          placeholder="Search"
          onChange={(e) =>
            multiTriggeredActionHandler(() =>
              setFilterBy({
                ...filterBy,
                search: e.target.value,
              }),
            )
          }
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          startAdornment={
            <div className={classes.searchIcon}>
              {' '}
              <SearchIcon />{' '}
            </div>
          }
        />
      </Grid>
      {filterBy.switches?.read && isPM ? (
        <Button
          color="primary"
          style={{ marginLeft: '1rem' }}
          startIcon={<VisibilityOffIcon />}
          size="small"
          onClick={() => {
            setFilterBy({
              ...filterBy,
              switches: {
                read: !filterBy.switches?.read,
              },
            });
            setSortMessagesDesc(!sortMessagesDesc);
          }}>
          Hide Read
        </Button>
      ) : !filterBy.switches?.read && isPM ? (
        <Button
          color="primary"
          style={{ marginLeft: '1rem' }}
          startIcon={<VisibilityIcon />}
          size="small"
          onClick={() => {
            setFilterBy({
              ...filterBy,
              switches: {
                read: !filterBy.switches?.read,
              },
            });
            setSortMessagesDesc(!sortMessagesDesc);
          }}>
          Show Read
        </Button>
      ) : (
        <></>
      )}
      {isPM && (
        <Grid item className={classes.iconContainer}>
          <IconButton
            aria-label="compose-message"
            disabled={selectedPropertiesCount > 1 ?? false}
            sx={{ border: `1px solid ${BORDER_COLOR}` }}
            onClick={() =>
              OnOpenInboxMessageModal.dispatch({
                open: true,
                fromField: isPeopleProfile
                  ? InsertOnField.PEOPLE
                  : InsertOnField.COMPOSE,
                userIDs: userIDs ? userIDs : undefined,
                leaseIDs: leaseId ? [leaseId] : undefined,
                leaseProperty,
                fromUser,
                isPeopleProfile,
              })
            }>
            <EditIcon />
          </IconButton>
        </Grid>
      )}
      {isPM && (
        <Grid item className={classes.iconContainer}>
          <IconButton
            aria-label="delete"
            sx={{ border: `1px solid ${BORDER_COLOR}` }}
            onClick={() => {
              if (!threadEvent?.selectedThreadId) return;
              onDeleteThread(threadEvent?.selectedThreadId);
            }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
      {isLoadingDelete && (
        <Grid item className={classes.circularProgress}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};
