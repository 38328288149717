import React, { useContext, useEffect, useState } from 'react';
import { useCallAction, useFetchAction } from '@8baselabs/react-simple-state';
import {
  LeaseOptionalPreferenceUpdateInput,
  LeaseOptionalPreferenceKeyFilter,
  OptionalPreference,
  OptionalPreferenceInvetory,
  Lease,
} from '../../../schema-types';
import { Box, Grid, MenuItem, Select } from '@material-ui/core';
import { AlertContext } from '../../../routes/AlertContext';
import { Dialog } from '../../../shared/components/Dialog';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { ButtonDefault } from '../../../shared/components/ui/buttons/ButtonDefault';
import { TextFieldDefault } from '../../../shared/components/ui/inputs/TextFieldDefault';
import {
  getOptionalPreferenceInventoryAction,
  updateLeaseOptionalPreferencesAction,
} from '../people-actions';

type Props = {
  onClose: () => void;
  open: boolean;
  selectedLease: Lease;
  selectedLeaseOptionalPreferenceId: string;
  selectedOptionalPreference: OptionalPreference | null;
  selectedInventory: OptionalPreferenceInvetory | null;
  selectedAdditionalAppData: JSON | null;
  refetch: () => void;
};

export const EditOptionalPreferencesDialog: React.FC<Props> = ({
  onClose,
  open,
  selectedLease,
  selectedOptionalPreference,
  selectedLeaseOptionalPreferenceId,
  selectedInventory,
  selectedAdditionalAppData,
  refetch,
}) => {
  const alert = useContext(AlertContext);
  const [form, setForm] = useState(selectedAdditionalAppData || {});
  const [errors, setErrors] = useState({});
  const [newInventory, setNewInventory] =
    useState<OptionalPreferenceInvetory>();

  useEffect(() => {
    setNewInventory(selectedInventory || {});
  }, [selectedInventory]);

  const [preferenceInventory, loadingInventory, { refetch: refetchInventory }] =
    useFetchAction(
      getOptionalPreferenceInventoryAction,
      [selectedOptionalPreference?.id || ''],
      {
        skip: !selectedOptionalPreference?.id,
      },
    );

  const handleClose = (): void => {
    onClose();
    setNewInventory({});
    refetchInventory();
    setForm({});
    setErrors({});
  };

  useEffect(() => {
    setForm(selectedAdditionalAppData || {});
  }, [selectedAdditionalAppData]);

  const [updateLeaseOptionalPreferencesCall, loadingSubmit] = useCallAction(
    updateLeaseOptionalPreferencesAction,
    {
      onCompleted() {
        handleClose();
        refetch();
        alert({
          success: true,
          message: 'Optional Preference edited successfully!',
        });
      },
      onError() {
        alert({
          error: false,
          message: 'Failed to edit Optional Preference!',
        });
      },
    },
  );

  const onChangeAdditionalData = (key: string, value: string): void => {
    if (errors[key]) {
      setErrors((pe) => ({ ...pe, [key]: false }));
    }
    setForm((prevVal) => ({
      ...prevVal,
      [key]: value || '',
    }));
  };

  const handleSubmit = (): void => {
    const additionalDataList =
      selectedOptionalPreference?.optionalPreferenceAdditionalDataRelation
        ?.items || [];
    let withErrors = false;
    for (let i = 0; i < additionalDataList.length; i += 1) {
      const additionalData = additionalDataList[i];

      if (additionalData?.required) {
        const key = additionalData.title || '';
        const value = form[key];
        if (!value) {
          withErrors = true;
          setErrors((pe) => ({ ...pe, [key]: true }));
        }
      }
    }

    if (withErrors) return;

    if (
      selectedLeaseOptionalPreferenceId &&
      selectedOptionalPreference &&
      selectedLease
    ) {
      const filter: LeaseOptionalPreferenceKeyFilter = {
        id: selectedLeaseOptionalPreferenceId,
      };

      const data: LeaseOptionalPreferenceUpdateInput = {
        additionalAppData: form,
        inventory: {
          reconnect: {
            id: newInventory?.id || selectedInventory?.id || undefined,
          },
        },
      };
      updateLeaseOptionalPreferencesCall(data, filter);
    }
  };

  return (
    <Dialog
      isVisible={open}
      title="Edit Optional Preference"
      size="sm"
      fullWidth
      onClose={handleClose}
      actions={
        <>
          <ButtonDefault onClick={handleClose} disabled={loadingSubmit}>
            Cancel
          </ButtonDefault>
          <ButtonAction
            isLoading={loadingSubmit}
            disabled={!selectedOptionalPreference || loadingInventory}
            onClick={handleSubmit}>
            Edit
          </ButtonAction>
        </>
      }>
      <Box fontSize="16px" mb="15px" fontWeight={500}>
        Select Optional Preference
      </Box>
      <Select
        variant="outlined"
        fullWidth
        disabled={true}
        value={selectedOptionalPreference?.id || ''}>
        <MenuItem
          key={selectedOptionalPreference?.id}
          value={selectedOptionalPreference?.id}
          selected={true}>
          {selectedOptionalPreference?.name}
        </MenuItem>
      </Select>
      {selectedInventory?.id ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt="20px" fontSize="16px" fontWeight={500}>
              Select available Inventory
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Select
              variant="outlined"
              fullWidth
              value={newInventory?.id || selectedInventory?.id || ''}
              onChange={(e) => {
                const inventoryItem = preferenceInventory?.find(
                  (item) => item.id === e.target.value,
                );
                if (inventoryItem) {
                  setNewInventory(inventoryItem);
                }
              }}>
              <MenuItem
                key={selectedInventory?.id}
                value={selectedInventory?.id}>
                {selectedInventory?.name}
              </MenuItem>

              {preferenceInventory?.map((inv) => (
                <MenuItem key={inv?.id} value={inv?.id}>
                  {inv?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      ) : undefined}
      {form && Object.keys(form).length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt="20px" fontSize="16px" fontWeight={500}>
              Additional App Data
            </Box>
          </Grid>
          {Object.keys(form).map((key) => {
            return (
              <Grid item xs={6} key={key}>
                <TextFieldDefault
                  value={form[key]}
                  helperText={errors[key] ? 'This field is required' : ''}
                  error={errors[key]}
                  onChange={(e) =>
                    onChangeAdditionalData(key, e.target.value || '')
                  }
                  label={key?.toLocaleUpperCase()}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : undefined}
    </Dialog>
  );
};
