import {
  InvitationStatus,
  LeaseFilter,
  LeaseStatus,
  RequiredFile,
  DocumentType,
  RequiredUpload,
  Lease,
} from '@8baselabs/resident-io-shared';
import {
  AddReservationFetchLeaseEvent,
  AddReservationExistentResidentEvent,
  AddReservationSelectBedEvent,
  AddReservationSelectBuildingEvent,
  AddReservationSelectCustomPeriodEvent,
  AddReservationSelectFloorEvent,
  AddReservationSelectLeasePeriodEvent,
  AddReservationSelectPaymentPlanEvent,
  AddReservationSelectPropertyEvent,
  AddReservationSelectResidentApplicationEvent,
  AddReservationSelectResidentEvent,
  AddReservationSelectRoomEvent,
  AddReservationSelectUnitEvent,
  requiredFilesEvent,
} from './opportunities-event';

import { LeaseCategories, LeaseStatusValues } from './opportunities-types';

import * as yup from 'yup';
import { isValid } from 'date-fns';
// import * as filestack from 'filestack-js';
import { FileValue } from '@8base-react/file-input';

export const getLeaseCategory = (leaseType): LeaseCategories | undefined => {
  switch (leaseType) {
    case LeaseStatus.SELECT_UNIT:
      return LeaseCategories.OPEN_INVITATION;
    case LeaseStatus.APLICATION:
      return LeaseCategories.MANAGER_READY;
    case LeaseStatus.APPROVED:
      return LeaseCategories.APPROVED;
    default:
      return LeaseCategories.IN_PROCESS;
  }
};

export const getLeaseStatus = (leaseCategory: LeaseCategories): string[] => {
  switch (leaseCategory) {
    case LeaseCategories.OPEN_INVITATION:
      return [LeaseStatus.SELECT_UNIT];
    case LeaseCategories.IN_PROCESS:
      return [
        LeaseStatus.OPTIONAL_PREFERENCE,
        LeaseStatus.FRIENDS,
        LeaseStatus.CONTRACT,
        LeaseStatus.PAYMENT,
        LeaseStatus.REQUIRED_DOCUMENTS,
        LeaseStatus.APLICATION,
      ];
    case LeaseCategories.MANAGER_READY:
      return [LeaseStatus.APPROVED];
    case LeaseCategories.APPROVED:
      return [LeaseStatus.APPROVED];
    case LeaseCategories.TOTAL:
      return Object.values(LeaseStatus).map((status) => status);

    default:
      return [];
  }
};

export const getLeaseStatusValue = (
  leaseStatus: LeaseStatus | undefined,
): string => {
  return leaseStatus ? LeaseStatusValues[leaseStatus].value : '';
};

export const getLeaseFilterByCategory = (
  leaseCategory: LeaseCategories,
): LeaseFilter[] => {
  switch (leaseCategory) {
    case LeaseCategories.OPEN_INVITATION:
      return [
        {
          AND: [
            {
              invitationResident: {
                status: {
                  equals: InvitationStatus.PENDING,
                },
              },
            },

            {
              status: {
                not_equals: LeaseStatus['MOVE-IN'],
              },
            },
            {
              status: {
                not_equals: LeaseStatus.DENIED,
              },
            },
            {
              status: {
                not_equals: LeaseStatus['MOVE-OUT'],
              },
            },
            {
              status: {
                not_equals: 'MOVE-OUT_BALANCE_OWED',
              },
            },
            {
              status: {
                not_equals: 'MOVE-OUT_REFUND_ISSUED',
              },
            },
          ],
        },
      ];
    case LeaseCategories.IN_PROCESS:
      //@ts-expect-error not recognizing null as valid filter
      return [
        LeaseStatus.SELECT_UNIT,
        LeaseStatus.OPTIONAL_PREFERENCE,
        LeaseStatus.FRIENDS,
        LeaseStatus.CONTRACT,
        LeaseStatus.PAYMENT,
        LeaseStatus.REQUIRED_DOCUMENTS,
        LeaseStatus.APLICATION,
        LeaseStatus.LEASE_PERIOD,
        LeaseStatus.PAID,
        LeaseStatus.PAYMENT_PROCESSING,
        LeaseStatus.PAYMENT_FAILED,
        LeaseStatus.SELECT_PAYMENT_PLAN,
        LeaseStatus.NEW,
        LeaseStatus.GUARANTOR,
      ].map((status) => ({
        status: {
          equals: status,
        },
        OR: [
          {
            invitationResident: {
              status: {
                not_equals: InvitationStatus.PENDING,
              },
            },
          },
          {
            invitationResident: null,
          },
        ],
      }));
    case LeaseCategories.MANAGER_READY:
      return [
        {
          status: {
            equals: LeaseStatus.ON_REVIEW,
          },
          OR: [
            {
              invitationResident: {
                status: {
                  not_equals: InvitationStatus.PENDING,
                },
              },
            },
            {
              //@ts-expect-error null is valid filter
              invitationResident: null,
            },
          ],
        },
      ];
    case LeaseCategories.APPROVED:
      return [LeaseStatus.APPROVED /* LeaseStatus['MOVE-IN'] */].map(
        (status) => ({
          status: {
            equals: status,
          },
        }),
      );
    case LeaseCategories.TOTAL:
      return [];

    default:
      return [];
  }
};

export const cleanAllReservationEvents = (): void => {
  AddReservationFetchLeaseEvent.dispatch(null);
  AddReservationExistentResidentEvent.dispatch(true);
  AddReservationSelectResidentEvent.dispatch(null);
  AddReservationSelectPropertyEvent.dispatch(null);
  AddReservationSelectCustomPeriodEvent.dispatch(null);
  AddReservationSelectLeasePeriodEvent.dispatch(null);

  AddReservationSelectBuildingEvent.dispatch(null);
  AddReservationSelectFloorEvent.dispatch(null);
  AddReservationSelectUnitEvent.dispatch(null);
  AddReservationSelectRoomEvent.dispatch(null);
  AddReservationSelectBedEvent.dispatch(null);

  AddReservationSelectPaymentPlanEvent.dispatch(null);

  AddReservationSelectResidentApplicationEvent.dispatch({
    sections: {},
    application: {},
    user: {},
    guarantor: {},
    fields: {},
  });
};

export const validateFileCount = (
  uploads: DocumentType[],
  uploadedFiles: RequiredFile[],
  formType: 'TENANT_ONLY' | 'GUARANTOR_ONLY' | 'BOTH' | string,
): void => {
  const uploadsRequired: RequiredUpload[] = [];
  const requiredUploadedFiles: RequiredFile[] = [];
  if (uploads?.length === 0) {
    requiredFilesEvent.dispatch({
      allFilesUploaded: true,
    });
  }

  if (!uploads || !uploadedFiles) {
    return;
  }

  if (uploads.length === 0) {
    requiredFilesEvent.dispatch({
      allFilesUploaded: true,
    });
  }

  const requiredUploadsIds: string[] = [];

  for (const requiredUpload in uploads) {
    uploads[requiredUpload]?.documentTypeRequiredUploadRelation?.items?.forEach(
      (document) => {
        if (document.isIncluded && document.isRequired) {
          if (formType === 'TENANT_ONLY') {
            if (document.isTenant) {
              uploadsRequired.push(document);
              requiredUploadsIds.push(document.id || '');
            }
          }
          if (
            formType === 'GUARANTOR_ONLY' &&
            document.isRequired &&
            document.isGuarantor
          ) {
            if (document.isGuarantor) {
              uploadsRequired.push(document);
              requiredUploadsIds.push(document.id || '');
            }
          }
          if (formType === 'BOTH') {
            uploadsRequired.push(document);
            requiredUploadsIds.push(document.id || '');
          }
        }
      },
    );
  }

  uploadedFiles.forEach((file) => {
    const currentFileReqUpload =
      file.requiredFileDocumentTypeRelation?.documentTypeRequiredUploadRelation?.items.find(
        (requiredUpload) => {
          if (formType === 'TENANT_ONLY') {
            return requiredUpload.id
              ? requiredUploadsIds.includes(requiredUpload.id) &&
                  file.type === 'TENANT' &&
                  requiredUpload.isRequired &&
                  requiredUpload.isTenant
              : false;
          } else if (formType === 'GUARANTOR_ONLY') {
            return requiredUpload.id
              ? requiredUploadsIds.includes(requiredUpload.id) &&
                  file.type === 'GUARANTOR' &&
                  requiredUpload.isRequired &&
                  requiredUpload.isGuarantor
              : false;
          } else
            return requiredUpload.id
              ? requiredUploadsIds.includes(requiredUpload.id) &&
                  requiredUpload.isRequired
              : false;
        },
      );

    if (currentFileReqUpload) {
      requiredUploadedFiles.push(file);
    }
  });

  if (uploadsRequired.length <= requiredUploadedFiles.length) {
    requiredFilesEvent.dispatch({
      allFilesUploaded: true,
    });
  } else {
    requiredFilesEvent.dispatch({
      allFilesUploaded: false,
    });
  }
};

export const shouldFieldValidate = (
  fields,
  name: string,
  category: string,
): boolean => {
  if (category === 'user' || category === 'guarantor') {
    const validate = fields?.find((field) => {
      return field.category === category && field.name === name;
    });
    return !!validate;
  }
  const validate = fields?.find((field) => field.name === name);
  return !!validate;
};

export const sectionsToValidate = {
  201: {
    firstName: yup
      .string()
      .required('Guarantor - First name is required')
      .nullable(),
    lastName: yup
      .string()
      .required('Guarantor - Last name is required')
      .nullable(),
    birthdate: yup
      .date()
      .max(new Date())
      .required('Guarantor - Birthday is required')
      // .test(
      //   'is-older-than-18',
      //   'Guarantor - Must be 18 or older in order to register',
      //   (value) =>
      //     differenceInYears(
      //       new Date(),
      //       value ? new Date(`${value}`) : new Date(),
      //     ) > 17,
      // )
      .test(
        'is-valid-date',
        'Guarantor - You must provide a valid date',
        (date) => isValid(date),
      )
      .nullable(),
    email: yup
      .string()
      .email('Guarantor - Enter a valid email')
      .required('Guarantor - Email is required')
      .nullable(),
    phoneNumber: yup
      .string()
      .matches(
        /^\+{0,1}([0-9]+ |[0-9]{0,1})(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
        'Guarantor - Please enter correct phone number',
      )
      .required('Guarantor - Cell Phone is required')
      .nullable(),
  },
  202: {
    personalGuarantee: yup
      .string()
      .required('Guarantor - Personal Guarantee is required')
      .nullable(),
  },
  203: {
    currentAdressCountry: yup
      .string()
      .required('Guarantor - Country is required')
      .nullable(),
    currentAdressLine1: yup
      .string()
      .required('Guarantor - Street Address 1 is required')
      .nullable(),
    currentAdressLine2: yup
      .string()
      .required('Guarantor - Street Address 2 is required')
      .nullable(),
    currentAdresssCity: yup
      .string()
      .required('Guarantor - City / Town is required')
      .nullable(),
    currentAdressState: yup
      .string()
      .required('Guarantor - State is required')
      .nullable(),
    currentAdressZipCode: yup
      .string()
      .required('Guarantor - Zip Code is required')
      .nullable(),
    currentAdressHowLong: yup
      .string()
      .required(
        'Guarantor - How long have you been living at this address? is required',
      )
      .nullable(),
    currentAdressRentOrOwn: yup
      .string()
      .required('Guarantor - Do you rent or own this property? is required')
      .nullable(),
  },
  204: {
    ssn: yup
      .string()
      .required('Guarantor - Do you have a Social Security Number? is required')
      .nullable(),
    country: yup
      .string()
      .required('Guarantor - Country is required')
      .nullable(),
    documentType: yup
      .string()
      .required('Guarantor - Document Type is required')
      .nullable(),
    govIssuingId: yup
      .string()
      .required('Guarantor - Government Issued ID is required')
      .nullable(),
    govIdNumber: yup
      .string()
      .required('Guarantor - Government ID Number is required')
      .nullable(),
  },
  206: {
    primaryIncomeSource: yup
      .string()
      .required('Guarantor - Primary Income Source is required')
      .nullable(),
  },
  207: {
    doYouHaveACurrentJob: yup
      .string()
      .required('Guarantor - Do you have a current job? is required')
      .nullable(),
  },
  209: {
    termsAck: yup
      .string()
      .required('Guarantor - Terms and Acknowledgement is required')
      .nullable(),
  },
  210: {
    checkConsent: yup
      .string()
      .required(
        'Guarantor - Background Screening and Credit Check Consent is required',
      )
      .nullable(),
  },
  211: {
    acknowledgement: yup
      .string()
      .required('Guarantor - Acknowledgement is required')
      .nullable(),
    applicationAck: yup
      .string()
      .required('Guarantor - Application is required')
      .nullable(),
  },
};

export type FileCreateInputType = {
  file: FileValue;
  requiredUploadId: string;
  documentId: string;
  userId: string;
  type: string;
};

export const formatBedDetails = (
  lease: Lease,
  type: 'opportunities' | 'leaseSelector',
): string => {
  if (lease.bed?.number === undefined && lease.unit?.code === undefined) {
    return 'No bed selected';
  }

  const buildingCode = lease.floor?.building?.code || '';

  let formattedBuildingCode = ' ';

  if (buildingCode.split(' ')?.length < 2) {
    formattedBuildingCode = buildingCode?.substring(0, 2);
  } else {
    const codeByWord = buildingCode?.split(' ');
    codeByWord.forEach((word, i) => {
      const firstLetter = word.substring(0, 1);
      if (i >= 3) return;
      formattedBuildingCode += firstLetter;
    });
  }

  if (type === 'leaseSelector') {
    return `${formattedBuildingCode.toUpperCase()}-${
      lease.floor?.number || ' '
    }-${lease.unit?.code?.substring(0, 2).toUpperCase() || ' '}-${
      lease.room?.code?.substring(0, 2).toUpperCase() || ' '
    }`;
  }

  return `${formattedBuildingCode.toUpperCase()}-${
    lease.floor?.number || ' '
  }-${lease.unit?.code?.substring(0, 2).toUpperCase() || ' '}-${
    lease.room?.code?.substring(0, 2).toUpperCase() || ' '
  }-${(lease.bed?.id?.substring(23, 25).toUpperCase() as string) || ' '}`;
};
