import { Box, BoxProps, Menu, MenuItem } from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import {
  VARIABLES_MODELS,
  VARIABLES_MODELS_COMPOSE,
  VARIABLES_MODELS_SUBJECT,
} from '../message-templates-model';

export enum InsertOnField {
  SUBJECT = 'SUBJECT',
  OPPORTUNITIES = 'OPPORTUNITIES',
  COMPOSE = 'COMPOSE',
  PEOPLE = 'PEOPLE',
}

type Props = {
  insertVariable: (value: { label: string; value: string }) => void;
  containerProps?: BoxProps;
  field?: InsertOnField;
};

export const InsertVariablesButton: React.FC<Props> = ({
  insertVariable,
  containerProps = {},
  field,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [variablesToShow, setVariablesToShow] = useState<
    { label: string; value: string }[]
  >([]);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event): void => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleInsertVariable = (text: {
    label: string;
    value: string;
  }): void => {
    insertVariable(text);
  };

  useEffect(() => {
    setVariablesToShow(VARIABLES_MODELS);
  }, []);

  return (
    <>
      <Box
        fontSize="16px"
        fontWeight="500"
        display="flex"
        width={'fit-content'}
        onClick={handleOpenMenu}
        {...containerProps}
        style={{ cursor: 'pointer' }}>
        Variables
        <ArrowDownIcon />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {variablesToShow.map((variableName) => (
          <MenuItem
            key={variableName.label}
            selected={false}
            onClick={() => handleInsertVariable(variableName)}>
            {variableName.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
