import { createAction } from '@8baselabs/react-simple-state';
import {
  fetchChecksEvent,
  fetchChecksEventError,
  updateCheckEvent,
  updateCheckEventError,
  updateChecksEvent,
  updateChecksEventError,
  createCheckEvent,
  createCheckEventError,
  sendChecksEvent,
  sendChecksEventError,
  updatePropertyCheckbookAccountByIdEvent,
  updatePropertyCheckbookAccountByIdEventError,
  addSignatureEvent,
  addSignatureEventError,
} from './checks-event';
import {
  Check,
  CheckFilter,
  CheckUpdateInput,
  CheckCreateInput,
  PropertyCheckbookAccountUpdateInput,
  PropertyCheckbookAccount,
} from '@8baselabs/resident-io-shared';
import { ChecksDataGridFilter } from './checks-types';

import {
  FETCH_CHECKS,
  UPDATE_CHECK,
  CREATE_CHECK,
  SEND_CHECKS,
  ADD_SIGNATURE,
  UPDATE_PROPERTY_CHECKBOOK_ACCOUNT_BY_ID,
} from './checks-queries';
import { apolloClient } from '../../shared/apollo';

export const fetchChecksAction = createAction(
  fetchChecksEvent,
  fetchChecksEventError,
  async (_filter: ChecksDataGridFilter) => {
    const { selectedPropertyId } = _filter;

    const filter: CheckFilter = {
      property: {
        id: {
          equals: selectedPropertyId,
        },
      },
    };

    const response = await apolloClient.query({
      query: FETCH_CHECKS,
      variables: {
        filter: filter,
      },
    });

    return response.data.checksList.items as Check[];
  },
);

export const updateCheckAction = createAction(
  updateCheckEvent,
  updateCheckEventError,
  async (check: CheckUpdateInput, filter: ChecksDataGridFilter) => {
    const { selectedPropertyId } = filter;

    const response = await apolloClient.mutate({
      mutation: UPDATE_CHECK,
      variables: {
        data: check,
      },
      refetchQueries: [
        {
          query: FETCH_CHECKS,
          variables: {
            propertyIds: [selectedPropertyId],
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    return response.data.checkUpdate as Check;
  },
);

export const updateChecksAction = createAction(
  updateChecksEvent,
  updateChecksEventError,
  async (checks: CheckUpdateInput[], selectedPropertyId: string) => {
    const promises = checks.map(async (check) => {
      await apolloClient.mutate({
        mutation: UPDATE_CHECK,
        variables: {
          data: check,
        },
        refetchQueries: [
          {
            query: FETCH_CHECKS,
            variables: {
              propertyIds: [selectedPropertyId],
            },
          },
        ],
        awaitRefetchQueries: true,
      });
    });

    //await apolloClient.cache.reset();

    const responses = await Promise.all(promises);

    return responses;
  },
);

export const createCheckAction = createAction(
  createCheckEvent,
  createCheckEventError,
  async (check: CheckCreateInput) => {
    const response = await apolloClient.mutate({
      mutation: CREATE_CHECK,
      variables: {
        data: check,
      },
    });

    return response.data.checkCreate;
  },
);

export const updatePropertyCheckbookAccountByIdAction = createAction(
  updatePropertyCheckbookAccountByIdEvent,
  updatePropertyCheckbookAccountByIdEventError,
  async (data: PropertyCheckbookAccountUpdateInput) => {
    const response = await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_CHECKBOOK_ACCOUNT_BY_ID,
      variables: {
        data: data,
      },
    });

    return response.data
      .propertyCheckbookAccountUpdate as PropertyCheckbookAccount;
  },
);

export const addSignatureAction = createAction(
  addSignatureEvent,
  addSignatureEventError,
  async (propertyId: string, signature: string) => {
    const response = await apolloClient.mutate({
      mutation: ADD_SIGNATURE,
      variables: {
        propertyId: propertyId,
        signature: signature,
      },
    });

    return response.data.success;
  },
);

export const sendChecksAction = createAction(
  sendChecksEvent,
  sendChecksEventError,
  async (checks: Check[], selectedPropertyId: string) => {
    const response = await apolloClient.mutate({
      mutation: SEND_CHECKS,
      variables: {
        checksIds: checks.map((check) => {
          return check.id;
        }),
        propertyId: selectedPropertyId,
      },
      refetchQueries: [
        {
          query: FETCH_CHECKS,
          variables: {
            propertyIds: [selectedPropertyId],
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    return response.data.sendChecks;
  },
);
