import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import { useSubscription } from '@8baselabs/react-simple-state';
import { NavigationRoute, TENANT_ROUTES } from '../../routes/types';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR, SECONDARY_COLOR } from '../theme';
import { StepType } from '../../modules/tenant/tenant-types';
import { Step, nextStep, StepError } from '../../modules/tenant/tenant-events';
import { FooterSteps } from './ui/stepper/FooterSteps';
import { useSubscription as useSubscriptionApollo } from '@apollo/client';
import {
  SaveResidentApplicationEvent,
  SaveResidentApplicationEventError,
  SkippedTenantApplication,
} from '../../modules/opportunities/opportunities-event';
import { LEASE_STATUS_SUBSCRIPTION } from '../../modules/reservation/reservation-queries';
import { AlertContext } from '../../routes/AlertContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      transition: 'width 0.35s',
    },
    drawerPaper: {
      transition: 'width 0.35s',
      marginTop: 160,
    },
    drawerOpen: {
      height: 60,
    },
    drawerContainer: {
      marginTop: 30,
    },
    drawerContainerMobile: {
      marginTop: 30,
    },
    toolbar: {
      justifyContent: 'space-between',
    },
    drawerLink: {
      color: 'inherit',
      textDecoration: 'none',
      '::focus': {
        color: 'inherit',
      },
    },
    drawerListItem: {
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
      marginTop: 20,
    },
    drawerListItemIcon: {
      minWidth: 35,
    },
    listItemIcon: {
      color: PRIMARY_TEXT_COLOR,
    },
    list: {
      width: '100%',
    },
    selectedListItem: {
      color: '#fff !important',
      backgroundColor: `${SECONDARY_COLOR} !important`,
    },
    selectedListItemIcon: {
      color: '#fff !important',
    },
    ButtomListItem: {
      color: '#fff !important',
      backgroundColor: `${PRIMARY_COLOR} !important`,
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

type SidebarProps = {
  isOpen: boolean;
};

export const TenantFooter: React.FC<SidebarProps> = ({ isOpen }) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const index = useRef(0);
  const nextStepRef = useRef('');
  const previousStep = useRef('');
  const [skippedSteps, setSkipedStep] = useState<string[]>([]);
  const [step, setStep] = useState<StepType | null>(Step.get());
  const [loading, setLoading] = useState(false);

  const handleNext = (): void => {
    setLoading(true);
    step?.handleFinish?.();
  };

  const handleBack = (): void => {
    let { path } = NavigationRoute[previousStep.current];
    if (
      SkippedTenantApplication.get()?.valueOf() &&
      previousStep.current === 'application'
    ) {
      path = '/invite-friends';
    }

    if (index.current === 0) {
      history.push(`${TENANT_ROUTES.resident.path}/${step?.propertyId}${path}`);
      return;
    }
    if (previousStep.current === 'select') {
      history.push(`${TENANT_ROUTES.resident.path}/${step?.propertyId}${path}`);
      return;
    }
    if (skippedSteps.length) {
      const navigationKeys = Object.keys(NavigationRoute);
      const navigationValues = Object.values(NavigationRoute);
      const skippedStepsKeys = skippedSteps.map((skippedStep) =>
        navigationKeys.findIndex((key) => key === skippedStep),
      );
      const skippedStepKey = Math.min(...skippedStepsKeys);
      path = navigationValues[skippedStepKey - 1]?.path;
      history.push(`${TENANT_ROUTES.resident.path}/${step?.leaseId}${path}`);
    } else {
      history.push(`${TENANT_ROUTES.resident.path}/${step?.leaseId}${path}`);
    }
  };

  const handleSave = (): void => {
    setLoading(true);
    step?.handleSave?.();
  };

  const { data: subscriptionResponse } = useSubscriptionApollo(
    LEASE_STATUS_SUBSCRIPTION,
    {
      variables: { leaseId: step?.leaseId },
      skip: !step?.leaseId,
      onSubscriptionData() {
        console.log(subscriptionResponse);
        alert({
          error: true,
          message: `Your lease has been changed by the property owner, please contact contact@residentio.com for more information`,
        });
        history.push(`${TENANT_ROUTES.resident.path}`);
      },
    },
  );

  useSubscription(Step, (data: StepType | null) => {
    setStep(data);
  });

  useSubscription(nextStep, (skippedStepEvent) => {
    if (skippedStepEvent?.step) {
      setSkipedStep((currentSteps) => [
        ...currentSteps,
        skippedStepEvent?.step,
      ]);
    }
    history.push(
      `${TENANT_ROUTES.resident.path}/${step?.leaseId}${
        NavigationRoute[nextStepRef.current].path
      }`,
    );
    setLoading(false);
  });

  useSubscription(StepError, () => {
    setLoading(false);
  });
  useSubscription(SaveResidentApplicationEvent, () => {
    setLoading(false);
  });
  useSubscription(SaveResidentApplicationEventError, () => {
    setLoading(false);
  });

  useEffect(() => {
    let keys = Object.keys(NavigationRoute);
    if (step?.disablePayment) {
      keys = keys.filter((value) => value !== 'payment');
    }
    if (step?.disablePersonality) {
      keys = keys.filter((value) => value !== 'personality');
    }
    index.current = keys.indexOf(step?.step ?? '');
    nextStepRef.current = keys[index.current + 1];
    if (index.current === 0) {
      previousStep.current = '';
    } else {
      previousStep.current = keys[index.current - 1];
    }
  }, [step]);

  const getDisableNext = (stepName: string): boolean => {
    if (step?.disableNext) return step.disableNext?.();

    switch (stepName) {
      case 'select':
        return !step?.bedId;
        break;
      case 'paymentPlan':
        return !step?.paymentPlan;
        break;
      case 'optional':
        return false;
        break;
      case 'friends':
        return false;
        break;
      case 'application':
        return false;
        break;
      case 'contract':
        return step?.awaitingSign === 'awaiting'
          ? false
          : step?.awaitingSign === 'ready'
          ? false
          : true;
      case 'payment':
        return step?.paymentResult ? false : true;
        break;
      default:
        return true;
        break;
    }
  };

  if (!isOpen) return <></>;

  return (
    <Grid container xs={12} className={classes.drawerContainer}>
      <Grid
        item
        md={step?.step === 'application' ? 12 : 8}
        className={classes.list}>
        <FooterSteps
          handleBack={handleBack}
          handleNext={handleNext}
          handleSave={step?.step === 'application' ? handleSave : undefined}
          isLoading={loading}
          lastStep={Object.keys(NavigationRoute).length - 1}
          activeStep={index.current}
          disableNext={getDisableNext(step?.step as string)}
        />
      </Grid>
    </Grid>
  );
};
