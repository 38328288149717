import { createTheme } from '@material-ui/core/styles';

export const BACKGROUND_COLOR = '#F8FAFB';
export const SECONDARY_BACKGROUND_COLOR = '#F1F1F1';

export const PRIMARY_COLOR = '#4D7CFE';
export const SECONDARY_COLOR = '#FE4D97';

export const SUCCESS_COLOR = '#6DD230';
export const SUCCESS_COLOR_BACKGROUND = '#cdf2b6';
export const WARNING_COLOR = '#F57C00';
export const WARNING_COLOR_BACKGROUND = '#ffbf80';
export const ERROR_COLOR = '#D56C6F';
export const ERROR_COLOR_BACKGROUND = '#ecbbbd';
export const SMALL_TITLE_COLOR = '#252631';
export const PRIMARY_TEXT_COLOR = 'rgba(0,0,0,0.87)';
export const CAPTION_TEXT_COLOR = '#5D6E7F';
export const TABLE_HEADER = '#F8FAFB';
export const TITLE_TABLE_HEADER = '#5D6E7F';
export const BORDER_COLOR = '#D2D2D2';
export const PENDING_COLOR = '#FFAB2B';
export const MUTED_COLOR = '#D2D2D2';
export const FACEBOOK = '#2E52B5';
export const TWITTER = '#2AADE8';
export const LINKEDIN = '#257BA2';
export const PINTEREST = '#EB4336';
export const INSTAGRAM = '#da4e5e';
export const SNAPCHAT = '#FFD800';
export const WHATSAPP = '#52AA1C';
export const WECHAT = '#60C822';

export const ROOM_CHOICE_THEME = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    success: {
      main: SUCCESS_COLOR,
    },
    warning: {
      main: WARNING_COLOR,
    },
    text: {
      primary: PRIMARY_TEXT_COLOR,
      secondary: CAPTION_TEXT_COLOR,
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#fff',
      },
    },
    MuiCheckbox: {
      indeterminate: {
        color: PRIMARY_COLOR,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          height: 8,
          width: 10,
        },
        '*::-webkit-scrollbar-track-piece': {
          background: '#F0F0F0',
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#c7c7c7',
          borderRadius: 20,
        },
      },
    },
    MuiIcon: {
      root: {
        fontFamily: 'Material Icons Outlined',
        height: '1.5em',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 5px 21px rgb(171 171 171 / 17%)',
      },
    },
    MuiTypography: {
      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 500,
      },
      body1: {
        fontWeight: 600,
      },
      body2: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
      },
    },
  },
  typography: {
    fontFamily: [
      'Barlow',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
  },
});
