import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Chip, { ChipProps } from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tag: {
      backgroundColor: ({ backgroundColor }: TagProps) => backgroundColor,
      padding: '2px 0px !important',
      borderRadius: '3px !important',
      height: 'fit-content !important',
      userSelect: 'none',
    },
  }),
);

/**
 * Stat card component for dashboards.
 *
 * @param param0 - Props.
 * @param param0.backgroundColor - Color for icon and stat text.
 * @param param0.label - Title text.
 * @returns - Stat Element.
 */
export const Tag: React.FC<TagProps> = ({
  backgroundColor,
  label,
  ...rest
}) => {
  const classes = useStyles({ backgroundColor });
  return (
    <Chip {...rest} className={classes.tag} label={label} color="primary" />
  );
};
type TagProps = ChipProps & {
  backgroundColor: string;
};
