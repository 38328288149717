import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  createStyles,
  Typography,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect, useContext, Suspense } from 'react';
import { EditorState, convertToRaw, Modifier, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Visibility from '@material-ui/icons/Visibility';
import { useHistory, useParams } from 'react-router-dom';
import { useSubscription } from '@8baselabs/react-simple-state';
import htmlToDraft from 'html-to-draftjs';
import { ValidationError } from 'yup';
import { SelectDefault } from '../../../shared/components/ui/inputs/SelectDefault';
import { TextFieldDefault } from '../../../shared/components/ui/inputs/TextFieldDefault';
import { MESSAGE_TEMPLATE_TYPE_OPTIONS } from './message-templates-model';
import { InsertVariablesButton } from './components/InsertVariablesButton';
import {
  onUpdateTemplateErrorEvent,
  onUpdateTemplateEvent,
  onFetchTemplateEvent,
  onFetchTemplatesErrorEvent,
  onSendTemplateEvent,
  onSendTemplateErrorEvent,
} from './message-template-events';
import { getErrorsAsObject } from '../../../shared/utils/errors';
import { MessageTemplateErrorType } from './message-template-types';
import {
  updateTemplate,
  fetchMessageTemplate,
  sendTemplateEmail,
} from './message-template-actions';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { MainLoader } from '../../../shared/components/MainLoader';
import { PreviewFormDialog } from './components/PreviewFormDialog';
import { AlertContext } from '../../../routes/AlertContext';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  bracketsStrategy,
  HandleBrackets,
} from '../../../shared/utils/message-decorators';
import { TABLE_HEADER } from '../../../shared/theme';

const TemplateEditor = React.lazy(() =>
  import('./components/TemplateEditor').then((module) => ({
    default: module.TemplateEditor,
  })),
);

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      width: '130px',
    },
    cancelButton: {
      width: '130px',
      background: '#f3f3f3',
    },
    subjectContent: {
      border: '1px solid #e5e5e5',
      paddingLeft: '20px',
      overflow: 'hidden !important',
      '& .public-DraftStyleDefault-block': {
        overflowX: 'auto',
        whiteSpace: 'pre',
      },
    },
  }),
);

export const EditMessageTemplateView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [editorSubjectState, seteditorSubjectState] = useState(
    EditorState.createEmpty(),
  );

  const [smsContent, setSmsContent] = useState<string>();
  const [loading, setloading] = useState(true);
  const [errors, setErrors] = useState<Partial<MessageTemplateErrorType>>();
  const [openPreviewDialog, setopenPreviewDialog] = useState(false);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const alert = useContext(AlertContext);
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [form, setForm] = useState({
    name: '',
    type: '',
    subject: '',
  });

  useSubscription(onUpdateTemplateEvent, (data) => {
    setloadingSubmit(false);
    alert({
      error: false,
      success: true,
      message: 'Template successfully updated!',
    });
    history.push('/settings/message-templates');
  });

  useSubscription(onUpdateTemplateErrorEvent, (error) => {
    setloadingSubmit(false);
    if (error && error instanceof ValidationError) {
      setErrors(getErrorsAsObject(error));
      alert({
        error: true,
        success: false,
        message: 'Errors encountered',
      });
    }
  });

  useSubscription(onFetchTemplateEvent, (template) => {
    setloading(false);
    setForm({
      name: template?.name || '',
      type: template?.type || '',
      subject: template?.subject || '',
    });
    if (template?.type === 'email') {
      const blocksFromHtml = htmlToDraft(template?.template);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const nEditorState = EditorState.createWithContent(contentState);

      seteditorState(nEditorState);

      const blocksFromHtmlToSubject = htmlToDraft(template?.subject);
      const {
        contentBlocks: contentBlocksSubject,
        entityMap: entityMapSubject,
      } = blocksFromHtmlToSubject;
      const contentStateSubject = ContentState.createFromBlockArray(
        contentBlocksSubject,
        entityMapSubject,
      );
      const nEditorSubjectState =
        EditorState.createWithContent(contentStateSubject);
      seteditorSubjectState(nEditorSubjectState);

      seteditorState(nEditorState);
    } else {
      setSmsContent(template?.template);
    }
  });

  useSubscription(onFetchTemplatesErrorEvent, () => {
    setloading(false);
    alert({
      error: true,
      success: false,
      message: 'Error to get previous template data',
    });
  });

  useSubscription(onSendTemplateEvent, () => {
    alert({
      error: false,
      success: true,
      message: 'Preview successfully sent',
    });
  });

  useSubscription(onSendTemplateErrorEvent, () => {
    alert({
      error: true,
      success: false,
      message: 'Error to send preview',
    });
  });

  useEffect(() => {
    const subjectValue = editorSubjectState.getCurrentContent().getPlainText();
    setForm((prevState) => ({
      ...prevState,
      subject: subjectValue,
    }));
  }, [editorSubjectState]);

  const onEditorStateChange = (
    state: React.SetStateAction<EditorState>,
  ): void => {
    seteditorState(state);
  };

  const onEditorSubjectStateChange = (
    state: React.SetStateAction<EditorState>,
  ): void => {
    seteditorSubjectState(state);
  };

  const onSave = (): void => {
    const htmlValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    setloadingSubmit(true);
    setErrors(undefined);
    updateTemplate({
      ...form,
      subject: form.type === 'email' ? form.subject : '',
      template: form.type === 'email' ? htmlValue : smsContent,
      id: params.id,
    });
  };

  const insertVariable = (placeholderText: {
    label: string;
    value: string;
  }): void => {
    if (form.type === 'email') {
      const variable = ` ${placeholderText.value} `;

      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();

      const newContent = Modifier.replaceText(
        currentContent,
        currentSelection,
        variable,
      );

      const newEditorState = EditorState.push(
        editorState,
        newContent,
        'insert-characters',
      );
      seteditorState(
        EditorState.forceSelection(
          newEditorState,
          newContent.getSelectionAfter(),
        ),
      );
    }
    if (form.type === 'sms') {
      const newSMSContent =
        smsContent && smsContent?.length !== 0
          ? smsContent + ' ' + placeholderText.value
          : placeholderText.value;
      setSmsContent(newSMSContent);
    }
  };

  const onChange = (property: string, value: string): void => {
    setForm((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  const getDescription = (value: string): string => {
    return value
      .replaceAll('Resident First Name', '[Preview Resident First Name]')
      .replaceAll('Resident Last Name', '[Preview Resident Last Name]')
      .replaceAll('Resident Full Name', '[Preview Resident Full Name]')
      .replaceAll('Resident Email', '[Preview Resident Email]')
      .replaceAll(
        'Resident Bed Assignment',
        '[Preview Resident Bed Assignment]',
      )
      .replaceAll('Resident Move-In Date', '[Preview Resident Move-In Date]')
      .replaceAll(
        'Resident Current Balance',
        '[Preview Resident Current Balance]',
      )
      .replaceAll('Resident Balance Owed', '[Preview Resident Balance Owed]')
      .replaceAll('Resident Login URL', '[Preview Resident Login URL]')
      .replaceAll('Resident Payment URL', '[Preview Resident Payment URL]')
      .replaceAll('Resident Move-Out Date', '[Preview Resident Move-Out Date]')
      .replaceAll(
        'Resident Assigned Property',
        '[Preview Resident Assigned Property]',
      )
      .replaceAll(
        'Resident Reservation Status',
        '[Preview Resident Reservation Status]',
      )
      .replaceAll('Guarantor First Name', '[Preview Guarantor First Name]')
      .replaceAll('Guarantor Last Name', '[Preview Guarantor Last Name]')
      .replaceAll('Guarantor Full Name', '[Preview Guarantor Full Name]')
      .replaceAll('Guarantor Email', '[Preview Guarantor Email]');
  };

  const onSubmitPreview = (email: string): void => {
    if (!form.type) return;
    let data = {};
    if (form.type === 'email') {
      const htmlValue: string = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );
      const htmlValueEdited = getDescription(htmlValue);
      const newForm = form.subject
        .replaceAll('Resident First Name', '[Preview Resident First Name]')
        .replaceAll('Resident Last Name', '[Preview Resident Last Name]')
        .replaceAll('Resident Full Name', '[Preview Resident Full Name]')
        .replaceAll('Resident Email', '[Preview Resident Email]')
        .replaceAll(
          'Resident Bed Assignment',
          '[Preview Resident Bed Assignment]',
        )
        .replaceAll('Resident Move-In Date', '[Preview Resident Move-In Date]')
        .replaceAll(
          'Resident Current Balance',
          '[Preview Resident Current Balance]',
        )
        .replaceAll('Resident Balance Owed', '[Preview Resident Balance Owed]')
        .replaceAll('Resident Login URL', '[Preview Resident Login URL]')
        .replaceAll('Resident Payment URL', '[Preview Resident Payment URL]')
        .replaceAll(
          'Resident Move-Out Date',
          '[Preview Resident Move-Out Date]',
        )
        .replaceAll(
          'Resident Assigned Property',
          '[Preview Resident Assigned Property]',
        )
        .replaceAll(
          'Resident Reservation Status',
          '[Preview Resident Reservation Status]',
        )
        .replaceAll('Guarantor First Name', '[Preview Guarantor First Name]')
        .replaceAll('Guarantor Last Name', '[Preview Guarantor Last Name]')
        .replaceAll('Guarantor Full Name', '[Preview Guarantor Full Name]')
        .replaceAll('Guarantor Email', '[Preview Guarantor Email]');

      data = {
        isSMS: false,
        subject: newForm,
        template: htmlValueEdited,
        to: email,
      };
    }
    if (form.type === 'sms') {
      const newSMSContent = getDescription(smsContent as string);

      data = {
        subject: '',
        template: newSMSContent,
        to: email,
        isSMS: true,
      };
    }
    sendTemplateEmail(data);
  };

  const onAddVariableInSubject = (placeholderText: {
    label: string;
    value: string;
  }): void => {
    const text = `${placeholderText.value}`;
    const variable = ` ${text} `;

    const currentContent = editorSubjectState.getCurrentContent();
    const currentSelection = editorSubjectState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      variable,
    );

    const newEditorState = EditorState.push(
      editorSubjectState,
      newContent,
      'insert-characters',
    );
    seteditorSubjectState(
      EditorState.forceSelection(
        newEditorState,
        newContent.getSelectionAfter(),
      ),
    );
  };

  useEffect(() => {
    const { id } = params;
    if (id) {
      fetchMessageTemplate(id);
      setloading(true);
    }
  }, [params]);

  if (loading) {
    return (
      <Box>
        <MainLoader />
      </Box>
    );
  }

  return (
    <Suspense fallback={<>Loading...</>}>
      <Card>
        <Box p="30px">
          <Box fontSize="22px" fontWeight="500" mb="30px">
            Edit Template
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <Box mb="10px" fontWeight={500}>
                Template name
              </Box>
              <TextFieldDefault
                error={!!errors?.name}
                value={form.name}
                onChange={(e) => onChange('name', e.target.value)}
              />
              {!!errors?.name && (
                <Typography variant="body2" color="error">
                  {errors?.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={5}>
              <Box mb="10px" fontWeight={500}>
                Type
              </Box>
              <SelectDefault
                error={!!errors?.type}
                options={MESSAGE_TEMPLATE_TYPE_OPTIONS}
                value={form.type}
                onChange={(e) => onChange('type', e.target.value)}
              />
              {!!errors?.type && (
                <Typography variant="body2" color="error">
                  {errors?.type}
                </Typography>
              )}
            </Grid>

            {(form.type === 'email' || !form.type) && (
              <>
                <Grid item xs={5}>
                  <Box mb="10px" fontWeight={500}>
                    Subject
                  </Box>
                  <Editor
                    editorState={editorSubjectState}
                    editorClassName={classes.subjectContent}
                    onEditorStateChange={onEditorSubjectStateChange}
                    toolbarStyle={{
                      border: '1px solid #e5e5e5',
                    }}
                    toolbarHidden={true}
                    customDecorators={[
                      {
                        strategy: bracketsStrategy,
                        component: HandleBrackets,
                      },
                    ]}
                    wrapperClassName="input-editor-wrapper"
                  />

                  {!!errors?.subject && (
                    <Typography variant="body2" color="error">
                      {errors?.subject}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={5}>
                  <InsertVariablesButton
                    containerProps={{
                      ml: '-15px',
                      mt: '10px',
                      height: '100%',
                      alignItems: 'center',
                    }}
                    insertVariable={onAddVariableInSubject}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Box mb="10px" fontWeight={500}>
                Description
              </Box>
              {form.type === 'email' || !form.type ? (
                <>
                  <TemplateEditor
                    onEditorStateChange={onEditorStateChange}
                    editorState={editorState}
                    insertVariable={insertVariable}
                  />

                  {!!errors?.template && (
                    <Typography variant="body2" color="error">
                      {errors?.template}
                    </Typography>
                  )}
                </>
              ) : (
                <Grid>
                  <Grid style={{ position: 'relative' }}>
                    <TextField
                      style={{ position: 'relative' }}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        style: {
                          marginTop: '2rem',
                        },
                      }}
                      error={!!errors?.template}
                      multiline
                      minRows={15}
                      value={smsContent ?? ''}
                      onChange={(e) => {
                        setSmsContent(e.target.value);
                      }}
                    />
                    <Box
                      style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: 1.5,
                        left: 1.5,
                        backgroundColor: TABLE_HEADER,
                        width: '99.7%',
                      }}>
                      <InsertVariablesButton
                        containerProps={{
                          ml: '20px',
                          paddingBottom: '10px',
                          mt: '10px',
                          height: '100%',
                          alignItems: 'center',
                        }}
                        insertVariable={insertVariable}
                      />
                    </Box>
                    {!!errors?.template && (
                      <Typography variant="body2" color="error">
                        {errors?.template}
                      </Typography>
                    )}
                  </Grid>
                  {smsContent?.length ?? 0}/160
                </Grid>
              )}
              <Box
                display="flex"
                style={{ marginTop: '1%' }}
                justifyContent="space-between">
                <Button
                  size="large"
                  className={classes.cancelButton}
                  onClick={() => history.push('/settings/message-templates')}>
                  {' '}
                  CANCEL{' '}
                </Button>
                <Box>
                  <Button
                    size="large"
                    className={classes.buttons}
                    onClick={() => setopenPreviewDialog(true)}>
                    <Visibility />
                    &nbsp; PREVIEW
                  </Button>
                  <ButtonAction
                    onClick={onSave}
                    isLoading={loadingSubmit}
                    size="large"
                    className={classes.buttons}
                    variant="contained"
                    color="primary">
                    SAVE
                  </ButtonAction>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <PreviewFormDialog
            open={openPreviewDialog}
            onClose={() => setopenPreviewDialog(false)}
            onSubmitEmailOrPhone={onSubmitPreview}
            isSMS={form.type === 'sms'}
          />
        </Box>
      </Card>
    </Suspense>
  );
};
