import { MessageUpdateInput } from './../../../../shared/src/schema-types';
import { FileValue } from '@8base-react/file-input';
import { createAction } from '@8baselabs/react-simple-state';
import {
  MessageCreateInput,
  MessageFilter,
  TicketFilter,
  MessageThreadDeleteInput,
  MessageThreadFilter,
  TicketCreateInput,
  TicketUpdateInput,
  Mutation,
  Query,
  TicketAttachment,
  Property,
  Ticket,
  InfoAvailabilityUpdateInput,
} from '@8baselabs/resident-io-shared';
import { Message } from '@8baselabs/resident-io-shared/src/schema-types';
import { User as UserInterface } from '@8baselabs/resident-io-shared/src/schema-types';
import { UserType } from '@8baselabs/resident-io-shared/lib/constants';
import { apolloClient } from '../../shared/apollo';
import {
  OnCreateMessages,
  OnCreateMessagesError,
  onDeleteThread,
  onDeleteThreadErrorEvent,
  OnGetClientEmails,
  OnGetClientEmailsError,
  OnGetMessages,
  OnGetMessagesError,
  OnGetTickets,
  OnGetTicketsError,
  onGetThreads,
  OnGetThreadsError,
  OnGetPropertiesByClient,
  OnGetPropertiesByClientError,
  OnCreateTicket,
  OnCreateTicketError,
  OnUpdateTicket,
  OnUpdateTicketError,
  OnUpdateTicketStatus,
  OnUpdateTicketStatusError,
  OnUpdateTicketAssignedTo,
  OnUpdateTicketAssignedToError,
  OnReadMessagesEvent,
  OnReadMessagesError,
  OnGetPropertyContactData,
  OnGetPropertyContactDataError,
  OnGetPropertyUsers,
  OnGetPropertyUsersError,
  OnGetPropertyUsersByPMPO,
  OnGetPropertyUsersByPMPOError,
  OnUpdateMarketingSiteMessageStatus,
  OnUpdateMarketingSiteMessageStatusError,
} from './inbox-event';
import {
  CREATE_MESSAGE,
  DELETE_THREAD,
  GET_CLIENT_EMAILS,
  GET_MESSAGES,
  GET_TICKETS,
  GET_MESSAGES_BY_THREAD_ID,
  GET_THREADS,
  CREATE_TICKET,
  GET_INBOX_PROPERTIES_BY_CLIENT,
  UPDATE_TICKET,
  UPDATE_TICKET_STATUS,
  UPDATE_TICKET_ASSIGNED_TO,
  READ_MESSAGE,
  GET_PROPERTY_CONTACT_DATA,
  GET_USERS_BY_CLIENT,
  GET_USERS_BY_PM_PO,
  UPDATE_MARKETING_SITE_MESSAGE_STATUS,
} from './inbox-queries';
import { MessageVia } from './components/MessageContent';

export const getMesssages = createAction(
  OnGetMessages,
  OnGetMessagesError,
  async (userId: string | undefined, search: string) => {
    if (!userId) return undefined;

    const filter: MessageFilter = {
      AND: [
        {
          OR: [
            {
              content: {
                contains: search,
              },
            },
            {
              messageThread: {
                subject: {
                  contains: search,
                },
              },
            },
            {
              from: {
                firstName: {
                  contains: search,
                },
              },
            },
            {
              from: {
                lastName: {
                  contains: search,
                },
              },
            },
            {
              to: {
                firstName: {
                  contains: search,
                },
              },
            },
            {
              to: {
                lastName: {
                  contains: search,
                },
              },
            },
          ],
        },
        {
          OR: [
            {
              from: {
                id: {
                  equals: userId,
                },
              },
            },
            {
              to: {
                id: {
                  equals: userId,
                },
              },
            },
          ],
        },
      ],
    };

    const res = await apolloClient.query<Query>({
      query: GET_MESSAGES,
      variables: { filter },
    });

    return res.data.messagesList.items;
  },
);

export const getMessagesByThreadId = createAction(
  OnGetMessages,
  OnGetMessagesError,
  async (threadID: string) => {
    if (!threadID) return undefined;

    const filter: MessageFilter = {
      messageThread: {
        id: {
          equals: threadID,
        },
      },
    };

    const res = await apolloClient.query<Query>({
      query: GET_MESSAGES_BY_THREAD_ID,
      variables: { filter },
    });

    return res.data.messagesList.items;
  },
);

export const deleteThread = createAction(
  onDeleteThread,
  onDeleteThreadErrorEvent,
  async (threadID: string) => {
    if (!threadID) return undefined;

    const data: MessageThreadDeleteInput = {
      id: threadID,
    };

    const res = await apolloClient.mutate<Mutation>({
      mutation: DELETE_THREAD,
      variables: { data },
    });

    return res.data?.messageThreadDelete;
  },
);

export const getThreads = createAction(
  onGetThreads,
  OnGetThreadsError,
  async (
    filterBy: {
      search: string;
      idFrom: string[];
      idTo: string[];
      conversation?: boolean;
      switches?: {
        read: boolean;
      };
    },
    clientId: string,
    leaseID?: string,
  ) => {
    let filter: MessageThreadFilter =
      clientId !== ''
        ? {
            property: {
              client: {
                id: {
                  equals: clientId,
                },
              },
            },
            messageMessageThreadRelation: {
              some: {
                isRead: {
                  equals: filterBy.switches?.read,
                },
              },
            },
          }
        : {
            messageThreadLeaseRelation: {
              id: {
                equals: leaseID,
              },
            },
          };

    if (filterBy.search !== '') {
      filter = {
        ...filter,
        AND: [
          {
            OR: [
              {
                messageMessageThreadRelation: {
                  some: {
                    OR: [
                      {
                        content: {
                          contains: filterBy.search,
                        },
                      },
                      {
                        messageThread: {
                          subject: {
                            contains: filterBy.search,
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                fromUser: {
                  firstName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                fromProp: {
                  name: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                fromUser: {
                  lastName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toUser: {
                  firstName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toUser: {
                  lastName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toProp: {
                  name: {
                    contains: filterBy.search,
                  },
                },
              },
            ],
          },
        ],
      };
    }

    if (filterBy?.conversation) {
      filter = {
        ...filter,
        OR: [
          {
            AND: [
              {
                fromUser: {
                  id: {
                    in: filterBy?.idTo,
                  },
                },
              },
              {
                toProp: {
                  id: {
                    in: filterBy?.idFrom,
                  },
                },
              },
            ],
          },
          {
            AND: [
              {
                fromProp: {
                  id: {
                    in: filterBy?.idFrom,
                  },
                },
              },
              {
                toUser: {
                  id: {
                    in: filterBy?.idTo,
                  },
                },
              },
            ],
          },
        ],
      };
    } else {
      filter = {
        ...filter,
        OR: [
          {
            fromUser: {
              id: {
                equals: filterBy?.idTo[0] || '',
              },
            },
          },
          {
            fromProp: {
              id: {
                equals: filterBy?.idFrom[0] || '',
              },
            },
          },
          {
            toUser: {
              id: {
                equals: filterBy?.idTo[0] || '',
              },
            },
          },
          {
            toProp: {
              id: {
                equals: filterBy?.idFrom[0] || '',
              },
            },
          },
        ],
      };
    }

    const res = await apolloClient.query<Query>({
      query: GET_THREADS,
      variables: { filter },
    });

    return res.data.messageThreadsList.items;
  },
);

export const createMessage = createAction(
  OnCreateMessages,
  OnCreateMessagesError,
  async ({
    content,
    id,
    leaseId,
    type,
    to,
    propertyId,
    subject,
    attachments,
  }: {
    content: string;
    id: string;
    leaseId?: string;
    to: string;
    type: Message['type'];
    propertyId: string;
    subject: string;
    attachments: FileValue[];
  }) => {
    const data: MessageCreateInput = {
      content,
      type,
      fromProperty: {
        connect: {
          id: id,
        },
      },
      to: {
        connect: {
          id: to,
        },
      },
      messageThread: {
        create: {
          subject,
          fromProp: {
            connect: {
              id: id,
            },
          },
          toUser: {
            connect: {
              id: to,
            },
          },
          messageThreadLeaseRelation:
            leaseId !== ''
              ? {
                  connect: {
                    id: leaseId,
                  },
                }
              : undefined,
          property: {
            connect: {
              id: propertyId,
            },
          },
        },
      },
      messageAttachmentMessageRelation: {
        create: attachments.map(({ fileId, filename }) => ({
          file: {
            create: {
              filename,
              fileId,
            },
          },
        })),
      },
    };

    const res = await apolloClient.mutate<Mutation>({
      mutation: CREATE_MESSAGE,
      variables: { data },
    });

    return res.data?.messageCreate;
  },
);

export const replyMessage = createAction(
  OnCreateMessages,
  OnCreateMessagesError,
  async ({
    fromProperty,
    isPM,
    content,
    id,
    to,
    type,
    attachments,
    messageThreadId,
  }: {
    fromProperty: boolean;
    isPM: boolean;
    content: string;
    id: string;
    to: string;
    type?: MessageVia;
    attachments: FileValue[];
    messageThreadId: string;
  }) => {
    const data: MessageCreateInput = {
      content,
      type,
      fromProperty:
        fromProperty && isPM
          ? {
              connect: {
                id: id,
              },
            }
          : undefined,
      toProperty:
        fromProperty && isPM
          ? undefined
          : {
              connect: {
                id: to,
              },
            },
      from:
        fromProperty && isPM
          ? undefined
          : {
              connect: {
                id: id,
              },
            },
      to:
        fromProperty && isPM
          ? {
              connect: {
                id: to,
              },
            }
          : undefined,
      messageThread: {
        connect: {
          id: messageThreadId,
        },
      },
      messageAttachmentMessageRelation: {
        create: attachments.map(({ fileId, filename }) => ({
          file: {
            create: {
              filename,
              fileId,
            },
          },
        })),
      },
    };

    const res = await apolloClient.mutate<Mutation>({
      mutation: CREATE_MESSAGE,
      variables: { data },
    });

    return res.data?.messageCreate;
  },
);

export const getClientEmails = createAction(
  OnGetClientEmails,
  OnGetClientEmailsError,
  async (propertyId: string | undefined) => {
    if (!propertyId) return undefined;
    const res = await apolloClient.query<Query>({
      query: GET_CLIENT_EMAILS,
      variables: { propertyId },
    });

    return res.data.usersList.items;
  },
);

export const getUsersByClient = createAction(
  OnGetPropertyUsers,
  OnGetPropertyUsersError,
  async (
    clientId: string | undefined,
    isEmail: boolean | undefined,
    isSMS: boolean | undefined,
  ) => {
    if (!clientId) return undefined;

    const res = await apolloClient.query<Query>({
      query: GET_USERS_BY_CLIENT,
      variables: { clientId, isEmail, isSMS },
    });

    return res.data.client;
  },
);

export const getUsersByPMorPO = createAction(
  OnGetPropertyUsersByPMPO,
  OnGetPropertyUsersByPMPOError,
  async (
    clientUserId: string | undefined,
    isEmail: boolean | undefined,
    isSMS: boolean | undefined,
  ) => {
    if (!clientUserId) return undefined;

    const res = await apolloClient.query<Query>({
      query: GET_USERS_BY_PM_PO,
      variables: { clientUserId, isEmail, isSMS },
    });

    return res.data.propertiesList;
  },
);

export const getPropertyContactData = createAction(
  OnGetPropertyContactData,
  OnGetPropertyContactDataError,
  async (propertyId: string | undefined) => {
    if (!propertyId) return undefined;
    const res = await apolloClient.query<Query>({
      query: GET_PROPERTY_CONTACT_DATA,
      variables: { propertyId },
    });

    return res.data.propertiesList.items;
  },
);

export const getTickets = createAction(
  OnGetTickets,
  OnGetTicketsError,
  async (properties, user: UserInterface, role: UserType) => {
    let propertyIds: (string | undefined)[] = [];
    if (properties === undefined) return undefined;

    propertyIds = properties.map((property) => {
      return property.id;
    });

    const filter: TicketFilter = {
      property: {
        id: {
          in: propertyIds as string[],
        },
      },
      OR: [
        {
          assignedTo: {
            some: {
              id: {
                in: user.userClientUserRelation?.items?.map(
                  (client) => client?.id as string,
                ),
              },
            },
          },
        },
        {
          requestedBy: {
            id: {
              equals: user?.id,
            },
          },
        },
      ],
    };

    const res = await apolloClient.query<Query>({
      query: GET_TICKETS,
      variables: { filter },
    });

    return res.data.ticketsList?.items;
  },
);

export const getTicketsForResident = createAction(
  OnGetTickets,
  OnGetTicketsError,
  async (properties: Property[], userID: string) => {
    const filter: TicketFilter = {
      property: {
        id: {
          equals: properties[0].id as string,
        },
      },
      requestedBy: {
        id: {
          equals: userID,
        },
      },
    };

    const res = await apolloClient.query<Query>({
      query: GET_TICKETS,
      variables: { filter },
    });

    return res.data.ticketsList.items as readonly Ticket[];
  },
);

export const fetchPropertiesByClient = createAction(
  OnGetPropertiesByClient,
  OnGetPropertiesByClientError,
  async (clientId: string | undefined) => {
    if (!clientId) return undefined;
    const res = await apolloClient.query<Query>({
      query: GET_INBOX_PROPERTIES_BY_CLIENT,
      variables: { clientId },
    });
    return res.data.propertiesList.items;
  },
);

export const createTicket = createAction(
  OnCreateTicket,
  OnCreateTicketError,
  async ({
    property,
    unit,
    bed,
    requestedBy,
    assignedTo,
    priority,
    category,
    subject,
    status,
    description,
    copyRoommates = false,
    attachments,
  }: {
    property: string;
    unit: string;
    bed: string;
    requestedBy: string;
    assignedTo?: string[];
    priority: string;
    category: string;
    subject: string;
    status: string;
    description: string;
    copyRoommates?: boolean;
    attachments: readonly TicketAttachment[];
  }) => {
    const data: TicketCreateInput = {
      copyRoommates: copyRoommates,
      property: {
        connect: {
          id: property,
        },
      },
      unit: {
        connect: {
          id: unit,
        },
      },
      bed: {
        connect: {
          id: bed,
        },
      },
      requestedBy: {
        connect: {
          id: requestedBy,
        },
      },
      assignedTo:
        assignedTo?.length !== 0
          ? {
              connect: assignedTo?.map((clientID) => ({
                id: clientID,
              })),
            }
          : undefined,
      priority,
      category,
      subject,
      status,
      description,
      ticketAttachmentTicketRelation: {
        create: attachments?.map(({ file }) => ({
          file: {
            create: {
              fileId: file?.fileId,
              filename: file?.filename,
            },
          },
        })),
      },
    };

    const res = await apolloClient.mutate<Mutation>({
      mutation: CREATE_TICKET,
      variables: { data, skip: !bed },
      
    });

    //await apolloClient.cache.reset();

    return res.data?.ticketCreate;
  },
);

export const updateMarketingSiteMessages = createAction(
  OnUpdateMarketingSiteMessageStatus,
  OnUpdateMarketingSiteMessageStatusError,
  async ({ ids, status }: { ids: string[]; status: string }) => {
    const dataArr: InfoAvailabilityUpdateInput[] = ids.map((id) => ({
      id,
      status,
    }));

    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: UPDATE_MARKETING_SITE_MESSAGE_STATUS,
        variables: {
          data,
        },
      });
    });

    const responses = await Promise.all(promises);

    return responses;
  },
);

export const updateTicket = createAction(
  OnUpdateTicket,
  OnUpdateTicketError,
  async ({
    id,
    requestedBy,
    assignedTo,
    priority,
    category,
    status,
    oldAttachments,
    attachments,
    notes,
    resolution,
  }: {
    id: string;
    requestedBy: string;
    assignedTo: string[];
    priority: string;
    category: string;
    status: string;
    oldAttachments: readonly TicketAttachment[];
    attachments: readonly TicketAttachment[];
    notes: string;
    resolution: string;
  }) => {
    const disconnectAttachments = oldAttachments
      ?.filter((attachment) => {
        const isInAttachmentList = attachments.find(
          (a) => a?.file?.fileId === attachment?.file?.fileId,
        );
        return !isInAttachmentList;
      })
      .map((attachment) => ({ id: attachment.id }));

    const createAttachments = attachments
      ?.filter((a) => {
        const isInAttachmentList = oldAttachments?.find(
          (attachment) => a?.file?.fileId === attachment?.file?.fileId,
        );
        return !isInAttachmentList;
      })
      .map((a) => ({
        file: {
          create: {
            fileId: a?.file?.fileId,
            filename: a?.file?.filename,
          },
        },
      }));

    const updateAttachments = oldAttachments
      ?.filter((attachment) => {
        const isInAttachmentList = attachments.find(
          (a) => a?.file?.fileId === attachment?.file?.fileId,
        );
        return isInAttachmentList;
      })
      .map((ticketAttachment) => {
        const attachment = attachments.find(
          (a) => a?.file?.fileId === ticketAttachment?.file?.fileId,
        );
        return {
          data: {
            id: attachment?.id,
          },
        };
      });

    const data: TicketUpdateInput = {
      id,
      requestedBy: {
        reconnect: {
          id: requestedBy,
        },
      },
      assignedTo:
        assignedTo?.length !== 0
          ? {
              reconnect: assignedTo?.map((clientID) => ({
                id: clientID,
              })),
            }
          : undefined,
      priority,
      category,
      status,
      notes,
      resolution,
      ticketAttachmentTicketRelation: {
        disconnect: disconnectAttachments,
        create: createAttachments,
        update: updateAttachments,
      },
    };

    const { data: response } = await apolloClient.mutate({
      mutation: UPDATE_TICKET,
      variables: {
        data,
      },
    });

    //await apolloClient.cache.reset();

    return response?.ticketUpdate;
  },
);

export const updateTicketStatus = createAction(
  OnUpdateTicketStatus,
  OnUpdateTicketStatusError,
  async ({
    ids,
    status,
    resolution,
  }: {
    ids: string[];
    status: string;
    resolution: string;
  }) => {
    const dataArr: TicketUpdateInput[] = ids.map((id) => ({
      id,
      status,
      resolution,
    }));

    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: UPDATE_TICKET_STATUS,
        variables: {
          data,
        },
      });
    });

    //await apolloClient.cache.reset();

    const responses = await Promise.all(promises);

    return responses;
  },
);

export const updateTicketAssignedTo = createAction(
  OnUpdateTicketAssignedTo,
  OnUpdateTicketAssignedToError,
  async ({ ids, assignedTo }: { ids: string[]; assignedTo: string[] }) => {
    const dataArr: TicketUpdateInput[] = ids.map((id) => ({
      id,
      assignedTo:
        assignedTo.length !== 0
          ? {
              reconnect: assignedTo.map((clientID) => ({
                id: clientID,
              })),
            }
          : undefined,
    }));

    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: UPDATE_TICKET_ASSIGNED_TO,
        variables: {
          data,
        },
      });
    });

    //await apolloClient.cache.reset();

    const responses = await Promise.all(promises);

    return responses;
  },
);

export const readMessages = createAction(
  OnReadMessagesEvent,
  OnReadMessagesError,
  async (ids: string[]) => {
    const dataArr: MessageUpdateInput[] = ids.map((id) => ({
      id,
      isRead: true,
    }));

    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: READ_MESSAGE,
        variables: {
          data,
        },
      });
    });

    //await apolloClient.cache.reset();

    const responses = await Promise.all(promises);

    return responses;
  },
);
