import HelloSign from 'hellosign-embedded';

export const WORKSPACE_ID = process.env.REACT_APP_WORKSPACE_ID;
export const ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME || '';
export const WORKSPACE_ENDPOINT = process.env.REACT_APP_WORKSPACE_ENDPOINT;
export const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH_CLIENT_DOMAIN =
  process.env.REACT_APP_AUTH0_CLIENT_DOMAIN || '';
export const EIGHTBASE_WS_ENDPOINT = 'wss://ws.8base.com';
export const AUTH_CLIENT_REDIRECT_URI = `${window.location.origin}/auth/callback`;
export const AUTH_CLIENT_REDIRECT_LOGOUT = `${window.location.origin}/logout`;

export const DAYS_TO_OFFER_RENEWAL_BEFORE_LEASEENDDATE = '360';
export const HELLOSIGN_CLIENT_ID =
  process.env.REACT_APP_HELLOSIGN_CLIENT_ID || '';

export const MARKETING_SITE_URL = process.env.REACT_APP_MARKETING_SITE_URL;

export const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'http://localhost:3000';

// UI APP Variables
export const DRAWER_WIDTH = 240;

export type ResultType = {
  success: boolean;
  message: string;
};

export const helloSignClient = new HelloSign({
  clientId: HELLOSIGN_CLIENT_ID,
  testMode: true,
});

export const buildingLayoutTemplateBase64 =
  'QmVkLEdlbmRlcixSb29tLFJvb20tVHlwZSxVbml0LFVuaXQtVHlwZSxGbG9vcixCdWlsZGluZwox LEZlbWFsZSxBLGN1c3RvbSwxMDEsY3VzdG9tLDEsb25lCjEsRmVtYWxlLEIsY3VzdG9tLDEwMSxjdXN0b20sMSxvbmUKMSxNYWxlLEEsY3VzdG9tLDIwMSxjdXN0b20sMixvbmUKMSxGaXJzdCBnZW5kZXIsQSxjdXN0b20sMTAxLGN1c3RvbSwxLHR3bwo=';

export const residentImportBase64 =
  'Rmlyc3QgTmFtZSxMYXN0IE5hbWUsRW1haWwsQ2VsbCBQaG9uZSxHZW5kZXIsUHJvcGVydHkgQ29kZSxMZWFzZSBQZXJpb2QgQ29kZSxCZWQgQ29kZSxMb2NrZWQ/LFBheW1lbnQgUGxhbiBDb2RlLFJlc2VydmF0aW9uIFN0YXR1cyxDdXJyZW50IEJhbGFuY2UsRGVwb3NpdCBPbiBIYW5kLERlcG9zaXQgT24gSGFuZCBDcmVkaXQKSm9obixEb2UsZW1AYWlsLmNvbSwoMDAwKSAwMDAtMDAwMCxNLDQ3LDQ2LDEwODgzLHllcywxLEFwcHJvdmVkLC0xNDA1LC01MDAsNTAwCg==';

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const TRAITIFY_PUBLIC_KEY = process.env.REACT_APP_TRAITIFY_PUBLIC_KEY;

// Sentry Configuration
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const SENTRY_TRACE_SAMPLE_RATE = process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE;
