import { useContext, useState, useEffect } from 'react';
import { useEvent, useSubscription } from '@8baselabs/react-simple-state';
import {
  MessageListResponse,
  MessageThreadFilter,
} from '@8baselabs/resident-io-shared';
import {
  OnCreateMessages,
  onDeleteThread,
  OnReadMessagesEvent,
} from './inbox-event';
import { AlertContext } from '../../routes/AlertContext';
import { useQuery } from '@apollo/client';
import { GET_THREADS } from './inbox-queries';
import { OnLeasePeriodSelectedId, OnSelectProperties } from '../settings-view/settings-events';
import { useLocalStorage } from '../../shared/components/components-hooks';

export const useMessages = (
  filterBy: {
    search: string;
    idFrom: string[];
    idTo: string[];
    conversation;
    switches?: {
      read: boolean;
    };
  },
  clientId?: string,
  leaseID?: string,
  fromPeople?: boolean,
  fromTenantPortal?: boolean,
): {
  messagesThreads: MessageListResponse[] | undefined;
  loadingThreads: boolean;
} => {
  const selectedProperties = useEvent(OnSelectProperties);
  const [selectedProperty] = useLocalStorage('selected-property-id', '');
  const [selectedLocalLeaseId] = useLocalStorage('selected-lease-id', '');
  const [messages, setMessages] = useState<MessageListResponse[]>();
  const alert = useContext(AlertContext);
  
  const selectedLeaseId = useEvent(OnLeasePeriodSelectedId) || selectedLocalLeaseId;

  const generateFilter: (filterBy) => MessageThreadFilter = () => {
    let filter: MessageThreadFilter =
      clientId !== ''
        ? {
            property: {
              id: {
                in: fromPeople
                  ? [selectedProperty]
                  : selectedProperties?.map((prop) => prop?.id as string) ?? [],
              },
            },
            messageThreadLeaseRelation: fromPeople
              ? {
                  id: {
                    equals: selectedLeaseId?.leaseId,
                  },
                }
              : undefined,
          }
        : {
            messageThreadLeaseRelation: fromTenantPortal
              ? undefined
              : {
                  id: {
                    equals: leaseID,
                  },
                },
          };
    if (filterBy.search !== '') {
      filter = {
        ...filter,
        AND: [
          {
            OR: [
              {
                messageMessageThreadRelation: {
                  some: {
                    OR: [
                      {
                        content: {
                          contains: filterBy.search,
                        },
                      },
                      {
                        messageThread: {
                          subject: {
                            contains: filterBy.search,
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                fromProp: {
                  name: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                fromProp: {
                  name: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toProp: {
                  name: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toProp: {
                  name: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                fromUser: {
                  firstName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                fromUser: {
                  lastName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toUser: {
                  firstName: {
                    contains: filterBy.search,
                  },
                },
              },
              {
                toUser: {
                  lastName: {
                    contains: filterBy.search,
                  },
                },
              },
            ],
          },
        ],
      };
    }

    if (filterBy?.conversation) {
      filter = {
        ...filter,
        OR: [
          {
            AND: [
              {
                fromProp: {
                  id: {
                    in: filterBy?.idFrom,
                  },
                },
              },
              {
                toUser: {
                  id: {
                    equals: filterBy?.idTo[0],
                  },
                },
              },
            ],
          },
          {
            AND: [
              {
                fromUser: {
                  id: {
                    equals: filterBy?.idTo[0],
                  },
                },
              },
              {
                toProp: {
                  id: {
                    in: filterBy?.idFrom,
                  },
                },
              },
            ],
          },
        ],
      };
    } else {
      filter = {
        ...filter,
        OR: [
          {
            fromProp: {
              id: {
                in: filterBy?.idFrom || '',
              },
            },
          },
          {
            toUser: {
              id: {
                equals: filterBy?.idTo[0] || '',
              },
            },
          },
          {
            fromUser: {
              id: {
                equals: filterBy?.idFrom[0] || '',
              },
            },
          },
          {
            toProp: {
              id: {
                in: filterBy?.idTo || '',
              },
            },
          },
        ],
      };
    }
    return filter;
  };

  const { loading: loadingThreads, refetch } = useQuery(GET_THREADS, {
    variables: {
      filter: generateFilter(filterBy),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (messageThread) => {
      setMessages(
        messageThread?.messageThreadsList?.items.map(
          (thread) =>
            thread.messageMessageThreadRelation as MessageListResponse,
        ),
      );
    },
    skip: filterBy.idFrom[0] === undefined || filterBy.idTo[0] === undefined,
  });

  useEffect(() => {
    refetch();
  }, [
    filterBy.search,
    filterBy.idFrom,
    filterBy.switches,
    filterBy.idTo,
    refetch,
  ]);

  useSubscription(OnReadMessagesEvent, async () => {
    refetch();
  });

  useSubscription(OnCreateMessages, async () => {
    refetch();
  });

  useSubscription(onDeleteThread, async () => {
    refetch();
    alert({
      success: true,
      message: 'Message thread successfully deleted',
    });
  });

  return {
    messagesThreads: messages,
    loadingThreads,
  };
};
