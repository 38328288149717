/* eslint-disable import/no-extraneous-dependencies */
import {
  DepositStatus,
  LedgerPaymentStatus,
} from '@8baselabs/resident-io-shared';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import DownloadIcon from '@mui/icons-material/Download';
import { Style } from '@react-pdf/types';
import { ERROR_COLOR, SUCCESS_COLOR, WARNING_COLOR } from '../../../theme';

interface PDFProps {
  /** Loading used (Ex. Datagrid) */
  loadingPDF: boolean;
  /** Name of columns */
  columns: string[];
  /** Name of rows */
  rowNames: string[];
  /** Aditional styles */
  customStyles: Style[];
  /** Data to be rendered */
  data: unknown[];
  /** Filename */
  filename: string;
}
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    cellSpacing: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 8,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  tableTitle: {
    marginTop: 5,
    fontSize: 9,
    textAlign: 'center',
  },
  alignIcon: {
    alignItems: 'flex-end',
  },
  pill: {
    borderRadius: '5px',
    padding: 6,
    color: 'white',
    marginBottom: '10px',
  },
  pillSuccess: {
    backgroundColor: SUCCESS_COLOR,
  },
  pillPending: {
    backgroundColor: WARNING_COLOR,
  },
  pillError: {
    backgroundColor: ERROR_COLOR,
  },
});

const getPaymentStyles = (status: string) => {
  return !status
    ? {}
    : status === LedgerPaymentStatus.SUCCEEDED.toUpperCase()
    ? styles.pillSuccess
    : status === LedgerPaymentStatus.PROCESSING.toUpperCase()
    ? styles.pillPending
    : styles.pillError;
};

const getDepositStyles = (status) => {
  return !status
    ? {}
    : status === DepositStatus.PAID
    ? styles.pillSuccess
    : status === DepositStatus.PENDING
    ? styles.pillPending
    : styles.pillError;
};

const GeneratedPDF = ({
  columns,
  rowNames,
  customStyles,
  data,
}): JSX.Element => (
  <Document>
    <Page orientation="landscape" style={styles.page}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {columns.map((column, index) => {
            return (
              <View style={[styles.tableCol, customStyles[index]]}>
                <Text style={styles.tableTitle} break>
                  {column}
                </Text>
              </View>
            );
          })}
        </View>
        {data.map((rowValue) => {
          return (
            <View style={styles.tableRow}>
              {rowNames.map((row, index) => {
                const style: Style[] = [styles.tableCell];

                if (row === 'paymentStatus') {
                  style.push(getPaymentStyles(rowValue[row]), styles.pill);
                }

                if (row === 'bankDepositStatus') {
                  style.push(getDepositStyles(rowValue[row]), styles.pill);
                }

                return (
                  <View wrap style={[styles.tableCol, customStyles[index]]}>
                    <Text style={style} break>
                      {rowValue[row]}
                    </Text>
                  </View>
                );
              })}
              ;
            </View>
          );
        })}
      </View>
    </Page>
  </Document>
);

const ExportToPDF = ({
  loadingPDF,
  columns,
  rowNames,
  customStyles,
  data,
  filename,
}: PDFProps): JSX.Element => {
  return data?.length > 0 ? (
    <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <PDFDownloadLink
        document={
          <GeneratedPDF
            columns={columns}
            rowNames={rowNames}
            customStyles={customStyles}
            data={data}
          />
        }
        fileName={filename}>
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download now!'
        }
        <IconButton disabled={loadingPDF}>
          <DownloadIcon color="disabled" style={styles.alignIcon} />
        </IconButton>
      </PDFDownloadLink>
    </Grid>
  ) : (
    <></>
  );
};

export default ExportToPDF;
