import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    boxShadow: ({ boxShadow }: { boxShadow: string }) => boxShadow,
  },
  padding: {
    padding: '15px 30px',
  },
});

export const PdfViewer: React.FC<{
  pdfUrl?: string;
  pdf?: string;
  title?: string;
  small?: boolean;
  height?: string;
  width?: string;
  noHeader?: boolean;
  verySmall?: boolean;
  boxShadow?: string;
}> = ({
  pdfUrl,
  pdf,
  noHeader = false,
  title,
  height,
  width,
  verySmall = false,
  small,
  boxShadow = '0px 0px 10px 0px rgba(0,0,0,0.44)',
}) => {
  const classes = useStyles({ boxShadow });

  return (
    <>
      {pdfUrl && (
        <Paper className={classes.container}>
          <Grid direction="column">
            {!noHeader && (
              <>
                <Grid item className={classes.padding}>
                  <Typography>{title || 'PDF Viewer'}</Typography>
                </Grid>
                <Divider />
              </>
            )}
            <Grid item className={classes.padding}>
              <embed
                width={
                  width ? width : verySmall ? '320' : small ? '620' : '900'
                }
                height={
                  height ? height : verySmall ? '150' : small ? '200' : '1100'
                }
                src={`${pdfUrl}#toolbar=0`}
                title={title}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};
