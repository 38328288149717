import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { SelectOptions } from '../../../../shared/components/ui/inputs/SelectDefault';
import { SetionTitle } from '../../../../shared/components/ui/texts/CommonTexts';
import { ApplicationFormField } from './ApplicationFormField';

type ApplicationFormFieldConstructor = {
  name: string;
  label: string;
  type: string;
  options?: Array<SelectOptions>;
  conditionField?: string;
  condition?: string;
  required?: boolean;
  section?: string,
  alwaysDisabled?: boolean
};

type ApplicationFormSectionType = {
  id: string;
  title: string;
  text: string;
  userFields: Array<ApplicationFormFieldConstructor>;
  applicationFields: Array<ApplicationFormFieldConstructor>;
  extraApplicationFields: Array<ApplicationFormFieldConstructor>;
  guarantorFields?: Array<ApplicationFormFieldConstructor>;
};

type ApplicationFormCustomSectionType = {
  id?: string;
  title: string;
  text: string;
  customFields: Array<ApplicationFormFieldConstructor>;
};

interface Props {
  section?: ApplicationFormSectionType;
  customSection?: ApplicationFormCustomSectionType;
  disabledFields?: boolean;
  readOnly?: boolean;
  hiddenTitle?: boolean;
}

const MApplicationFormSection: React.FC<Props> = ({
  section,
  customSection,
  disabledFields,
  hiddenTitle,
  readOnly,
}: Props) => {
  const fields = section
    ? [
      ...section.userFields.map((userField) => ({
        label: userField.label,
        fieldName: userField.name,
        type: userField.type,
        category: 'user',
        disabled: userField.alwaysDisabled ?? disabledFields ?? false,
        readOnly: readOnly ?? false,
        isExtraData: false,
        options: userField.options,
        conditionField: userField.conditionField,
        condition: userField.condition,
        required: userField.required,
        section: section.title,
      })),
      ...section.applicationFields.map((applicationField) => ({
        label: applicationField.label,
        fieldName: applicationField.name,
        type: applicationField.type,
        category: 'application',
        disabled: applicationField.alwaysDisabled ?? disabledFields ?? false,
        readOnly: readOnly ?? false,
        isExtraData: false,
        options: applicationField.options,
        conditionField: applicationField.conditionField,
        condition: applicationField.condition,
        required: applicationField.required,
        section: section.title,
      })),
      ...(section.guarantorFields?.map((guarantorField) => ({
        label: guarantorField.label,
        fieldName: guarantorField.name,
        type: guarantorField.type,
        category: 'guarantor',
        disabled: guarantorField.alwaysDisabled ?? disabledFields ?? false,
        readOnly: readOnly ?? false,
        isExtraData: false,
        options: guarantorField.options,
        conditionField: guarantorField.conditionField,
        condition: guarantorField.condition,
        required: guarantorField.required,
        section: section.title,
      })) ?? []),
      ...section.extraApplicationFields.map((applicationField) => ({
        label: applicationField.label,
        fieldName: applicationField.name,
        type: applicationField.type,
        category: 'application',
        disabled: applicationField.alwaysDisabled ?? disabledFields ?? false,
        readOnly: readOnly ?? false,
        isExtraData: true,
        options: applicationField.options,
        conditionField: applicationField.conditionField,
        condition: applicationField.condition,
        required: applicationField.required,
        section: section.title,
      })),
    ]
    : customSection
      ? [
        ...customSection.customFields.map((applicationField) => ({
          label: applicationField.label,
          fieldName: applicationField.name,
          type: applicationField.type,
          category: 'application',
          disabled: disabledFields ?? applicationField.alwaysDisabled ?? false,
          readOnly: readOnly ?? false,
          isExtraData: true,
          options: applicationField.options,
          conditionField: applicationField.conditionField,
          condition: applicationField.condition,
          required: applicationField.required,
          section: customSection.title,
        })),
      ]
      : [];


      return (
    <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
      <Grid item xs={12}>
        {!hiddenTitle && (
          <SetionTitle>
            {section ? section.title : customSection ? customSection.title : ''}
          </SetionTitle>
        )}
        <Typography>
          {section ? section.text : customSection ? customSection.text : ''}
        </Typography>
      </Grid>
      {fields.map((field, idx: number) => (
        <ApplicationFormField
          {...field}
          key={`section-${section ? section.id : customSection ? customSection.id : ''
            }-field-${idx}`}
        />
      ))}
    </Grid>
  );
};

export const ApplicationFormSection = React.memo(MApplicationFormSection);
