import { createEvent } from '@8baselabs/react-simple-state';
import {
  ClientListResponse,
  User,
  UserListResponse,
  Fee,
  FeeSetting,
  PropertyPortfolioListResponse,
  StudentLifeListResponse,
  StudentLife,
  SuccessResponse,
  Property,
  ClientUser,
  Client,
  // AdminSetting,
  // AdminSettingManyResponse,
  // AdminSettingListResponse,
  // FeeListResponse,
} from '@8baselabs/resident-io-shared';
import { ValidationError } from 'yup';
import { School, SchoolListResponse } from '../../schema-types';
import { ResultType } from '../../shared/constants';
import {
  HelloSignGlobalSettingData,
  InstagramGlobalData,
  PropertyListResponseNew,
} from './admin-types';

export const OnFetchAllUsers = createEvent<UserListResponse>();
export const OnFetchAllUsersError = createEvent<Error>();

export const OnFetchResidentUsers = createEvent<UserListResponse>();
export const OnFetchResidentUsersError = createEvent<Error>();

export const OnFetchClientUsers = createEvent<UserListResponse>();
export const OnFetchClientUsersError = createEvent<Error>();

export const OnFetchUserClientUsers = createEvent<User | undefined>();
export const OnFetchUserClientUsersError = createEvent<Error>();

export const OnFetchAllClients = createEvent<ClientListResponse>();
export const OnFetchAllClientsError = createEvent<Error>();

export const OnFetchClients = createEvent<ClientListResponse>();
export const OnFetchClientsError = createEvent<Error>();

export const OnCreateInvitation = createEvent<void>();
export const OnCreateInvitationError = createEvent<Error | ValidationError>();

export const OnUserToggled = createEvent<User | null>();
export const OnUserToggledError = createEvent<Error>();

export const OnAssignInitialFees = createEvent<ResultType>();
export const OnAssignInitialFeesError = createEvent<Error>();

export const OnFetchAllFees = createEvent<Fee>();
export const OnFetchAllFeesError = createEvent<Error>();

export const OnUpdateAllFees = createEvent<Fee>();
export const OnUpdateAllFeesError = createEvent<
  Fee,
  Error | ValidationError | Fee
>();
export const OnConfigureInitialFees = createEvent<Fee>();
export const OnConfigureInitialFeesError = createEvent<Error>();

export const OnUpdateFeeSettings = createEvent<FeeSetting>();
export const OnUpdateFeeSettingsError = createEvent<Error>();

export const OnUserChangeRole = createEvent<User | null>();
export const OnUserChangeRoleError = createEvent<Error>();

export const OnFetchAllSchools = createEvent<SchoolListResponse>();
export const OnFetchAllSchoolsError = createEvent<Error>();

export const OnDeleteSchool = createEvent<School>();
export const OnDeleteSchoolError = createEvent<Error>();

export const OnCreateSchool = createEvent<School>();
export const OnCreateSchoolError = createEvent<Error>();

export const OnFetchSchool = createEvent<School>();
export const OnFetchSchoolError = createEvent<Error>();

export const OnUpdateSchool = createEvent<School>();
export const OnUpdateSchoolError = createEvent<Error>();

export const OnCreateSchoolPortfolio = createEvent<{ message: string }>();
export const OnCreateSchoolPortfolioError = createEvent<Error>();

export const OnFetchPropertyPortfolio =
  createEvent<PropertyPortfolioListResponse>();
export const OnFetchPropertyPortfolioError = createEvent<Error>();

export const OnFetchSchoolPortfolio = createEvent<EventSchoolPortFolio[]>();
export const OnFetchSchoolPortfolioError = createEvent<Error>();

export const OnDeleteSchoolPortfolio = createEvent<{ message: string }>();
export const OnDeleteSchoolPortfolioError = createEvent<Error>();

export const onSearchUserEvent = createEvent<string>();

export const onFetchGlobalInstagram = createEvent<InstagramGlobalData>();
export const onFetchGlobalInstagramError = createEvent<Error>();

export const onCreateGlobalInstagram = createEvent<number>();
export const onCreateGlobalInstagramError = createEvent<Error>();

export const onUpdateGlobalInstagram = createEvent<void>();
export const onUpdateGlobalInstagramError = createEvent<Error>();

export const onFetchAllLifeStudent = createEvent<StudentLifeListResponse>();
export const onFetchAllLifeStudentError = createEvent<Error>();

export const onCreateStudentLife = createEvent<{ message: string }>();
export const onCreateStudentLifeError = createEvent<Error>();

export const OnDeleteStudentLife = createEvent<StudentLife>();
export const OnDeleteStudentLifeError = createEvent<Error>();

export const OnEditStudentLife = createEvent<StudentLife>();
export const OnEditStudentLifeError = createEvent<Error>();

export const onFetchHelloSignSetting =
  createEvent<HelloSignGlobalSettingData>();
export const onFetchHelloSignSettingError = createEvent<Error>();

export const onCreateHelloSignSetting = createEvent<number>();
export const onCreateHelloSignSettingError = createEvent<Error>();

export const onUpdateHelloSignSetting = createEvent<void>();
export const onUpdateHelloSignSettingError = createEvent<Error>();

export const onFetchPropertiesByClient = createEvent<PropertyListResponseNew>();
export const onFetchPropertiesByClientError = createEvent<Error>();

export const onUpdateFeatureProperty = createEvent<void>();
export const onUpdateFeaturePropertyError = createEvent<Error>();
export const SelectedPropertyFeature = createEvent<{
  properties: Array<string>;
  features: string;
}>();

export const onUpdateSchoolProperty = createEvent<void>();
export const onUpdateSchoolPropertyError = createEvent<Error>();
export const SelectedPropertySchool = createEvent<{
  properties: Array<string>;
  school: string;
}>();

export const onUserDelete = createEvent<SuccessResponse>();
export const onUserDeleteError = createEvent<Error>();
export const selectedPropertyToManage = createEvent<{
  id: string;
  role?: { role: string; id: string };
}>();
export const clientUserIdToManage = createEvent<{ id: string }>();

export const onFetchClientUserByProperty = createEvent<Property>();
export const onFetchClientUserByPropertyError = createEvent<Error>();

export const onFetchClientUserByUser = createEvent<User>();
export const onFetchClientUserByUserError = createEvent<Error>();

export const onUpdateClientUserProperty = createEvent<Property | undefined>();
export const onUpdateClientUserPropertyError = createEvent<Error>();

export const onCreateClientUser = createEvent<ClientUser | undefined>();
export const onCreateClientUserError = createEvent<Error>();

export const onDisconnectClientUserProperty = createEvent<
  Property | undefined
>();
export const onDisconnectClientUserPropertyError = createEvent<Error>();

// // Move to shared
export type EventSchoolPortFolio = {
  id: string | undefined;
  properties: string | undefined;
  image: string | undefined;
};

export const OnFetchManager = createEvent<User | undefined>();
export const OnFetchManagerError = createEvent<Error>();

export const OnManyClientsDelete = createEvent<boolean>();
export const OnManyClientsDeleteError = createEvent<Error>();

export const OnManyPropertiesDelete = createEvent<boolean>();
export const OnManyPropertiesDeleteError = createEvent<Error>();

export const onCreateClient = createEvent<Client | undefined>();
export const onCreateClientError = createEvent<Error>();
