import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSubscription } from '@8baselabs/react-simple-state';
import { Grid, Paper } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {
  Loading,
  LoadingContainer,
} from '../../../shared/components/ui/Loading';
import { ButtonDefault } from '../../../shared/components/ui/buttons/ButtonDefault';
import {
  createEventEvent,
  deleteEventEvent,
  onOpenEventEditModal,
  onOpenNewEventModal,
  updateEventEvent,
} from '../calendar-event';
import { CalendarToolbar } from './CalendarToolbar';
import { FETCH_PROPERTY_EVENTS_QUERY } from '../calendar-queries';
import { useQuery } from '@apollo/client';
import { Property } from '@8baselabs/resident-io-shared';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(2),
    },
    container: {
      marginTop: '2vh',
    },
  }),
);

interface FormattedDateEvent extends Event {
  start: Date;
  end: Date;
}

type Props = {
  selectedProperty?: string;
};

export const EventCalendar: React.FC<Props> = ({ selectedProperty }) => {
  const classes = useStyles();
  const [formattedDates, setFormattedDates] = useState<FormattedDateEvent[]>(
    [],
  );

  //React-big-calendar requires dates to be generated from Date()
  const formatDates = (property: Property): void => {
    if (property) {
      const formattedEvents = property.propertyEventRelation?.items.map(
        (event) => {
          return {
            ...event,
            start: new Date(event.start || ''),
            end: new Date(event.end || ''),
          } as FormattedDateEvent;
        },
      );
      setFormattedDates(formattedEvents || []);
    }
  };

  const { loading, refetch } = useQuery(FETCH_PROPERTY_EVENTS_QUERY, {
    variables: {
      id: selectedProperty,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (propertyData) => {
      formatDates(propertyData?.property || {});
    },
    skip: !selectedProperty,
  });

  const handleCreate = (e): void => {
    if (e && e.end) {
      onOpenNewEventModal.dispatch({
        open: true,
        event: {
          start: e.start,
          end: e.end,
        },
      });
      return;
    }
    onOpenNewEventModal.dispatch({ open: true });
  };

  const handleEditEvent = (e): void => {
    onOpenEventEditModal.dispatch({
      open: true,
      eventId: e.id,
      event: {
        title: e.title,
        start: e.start,
        end: e.end,
        propertyId: selectedProperty || '',
      },
    });
  };

  useSubscription(createEventEvent, () => {
    refetch();
  });

  useSubscription(updateEventEvent, () => {
    refetch();
  });

  useSubscription(deleteEventEvent, () => {
    refetch();
  });

  const localizer = momentLocalizer(moment);
  if (loading) {
    return (
      <LoadingContainer marginTop="2rem">
        <Loading size={64} />
      </LoadingContainer>
    );
  }
  return (
    <>
      <Grid xs={12} className={classes.container}>
        <Paper className={classes.paper}>
          <Calendar
            views={['month', 'week', 'day']}
            localizer={localizer}
            toolbar={true}
            components={{
              toolbar: CalendarToolbar,
            }}
            events={formattedDates.length ? formattedDates : []}
            startAccessor="start"
            endAccessor="end"
            defaultView="month"
            onSelectSlot={handleCreate}
            selectable
            style={{ height: 600 }}
            onSelectEvent={handleEditEvent}
          />
          <Grid>
            <ButtonDefault
              title="Add Event"
              onClick={handleCreate}
              color={'primary'}>
              Add Event
            </ButtonDefault>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
