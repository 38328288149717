import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { ButtonDefault } from '../../../shared/components/ui/buttons/ButtonDefault';

type Props = {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  title: string;
  text: string;
  onDelete: () => void;
};
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '30px 34px',
      width: '368px',
    },
  }),
);
export const ConfirmBulkDialog: React.FC<Props> = ({
  open,
  onClose,
  loading,
  onDelete,
  title,
  text,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Grid container className={classes.content} spacing={2}>
          <Typography variant="body1">{text}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDefault onClick={onClose}>Cancel</ButtonDefault>
        <ButtonAction isLoading={loading} onClick={onDelete}>
          CONFIRM
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
