import { Redirect, useHistory } from 'react-router-dom';
import { TENANT_ROUTES } from '../../routes/types';

export const CheckAvailability: React.FC = () => {
  const history = useHistory();
  const searchParams = history.location.search.split('?')[1].split('&');
  const idProperty = searchParams[0].split('=')[1];
  const leasePeriod = searchParams[1].split('=')[1];
  localStorage.setItem('check-availability', idProperty);

  return (
    <Redirect
      to={{
        pathname: '/auth',
        state: {
          returnTo: `${TENANT_ROUTES['select-unit'].path.replace(
            ':id',
            idProperty,
          )}?${leasePeriod}`,
        },
      }}
    />
  );
};
