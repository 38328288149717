export const getMainWindowsList = (): never[] => {
  let tempMainWindowsList = [];
  
  if (localStorage.mainWindows) {
    tempMainWindowsList = localStorage.mainWindows.split(',');
  }

  return tempMainWindowsList;
};

export const getActiveWindowsList = (): never[] => {
  let tempActiveWindowsList = [];

  if (localStorage.activedWindows) {
    tempActiveWindowsList = localStorage.activedWindows.split(',');
  }

  return tempActiveWindowsList;
};

export const removeFromActiveWindows = (windowId: string): never[] => {
  const tempActiveWindowsList = getActiveWindowsList();
  const index = tempActiveWindowsList.indexOf(windowId as never);

  if (index > -1) {
    tempActiveWindowsList.splice(index, 1);
  }

  localStorage.activedWindows = tempActiveWindowsList.join(',');
  return tempActiveWindowsList;
};

export const removeFromMainWindowsList = (windowId: string): string[] => {
  let tempMainWindowsList = [];
  if (localStorage.mainWindows) {
    tempMainWindowsList = localStorage.mainWindows.split(',');
  }
  const index = tempMainWindowsList.indexOf(windowId as never);
  if (index > -1) {
    tempMainWindowsList.splice(index, 1);
  }
  localStorage.mainWindows = tempMainWindowsList.join(',');

  //remove from active windows too
  removeFromActiveWindows(windowId);

  return tempMainWindowsList;
};

export const addToMainWindowsList = (windowId: string): never[] => {
  const tempMainWindowsList = getMainWindowsList();
  const index = tempMainWindowsList.indexOf(windowId as never);

  if (index < 0) {
    //this window is not in the list currently
    tempMainWindowsList.push(windowId as never);
  }

  localStorage.mainWindows = tempMainWindowsList.join(',');

  return tempMainWindowsList;
};

export const addToActiveWindows = (windowId: string): never[] => {
  const tempActiveWindowsList = getActiveWindowsList();
  const index = tempActiveWindowsList.indexOf(windowId as never);

  if (index < 0) {
    //this window is not in active list currently
    tempActiveWindowsList.push(windowId as never);
  }

  localStorage.activedWindows = tempActiveWindowsList.join(',');

  return tempActiveWindowsList;
};
