import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Ledger } from '@8baselabs/resident-io-shared';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Paper, Typography } from '@material-ui/core';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { formatCurrency } from '../../../shared/utils';
import { LEDGER_TRANSACTION_INFORMATION_FORM_BUILDER } from '@8baselabs/resident-io-shared';
import {
  sanitizeTextToTitleCase,
  capitalizeFromUpperCase,
} from '../../../shared/utils';

type LedgerViewDetailsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  ledger: Ledger;
};
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '10px 18px 10px 10px',
      width: '568px',
    },
    paper: {
      padding: '20px 0',
    },
    grid: {
      padding: '8px 25px!important',
    },
    gray: {
      backgroundColor: '#F1F1F1',
    },
    textGray: {
      color: 'rgba(0,0,0,0.87)',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    textEnd: {
      textAlign: 'end',
      fontWeight: 600,
    },
  }),
);

export const LedgerViewDetailsDialog: React.FC<
  LedgerViewDetailsDialogProps
> = ({ isOpen, onClose, ledger }) => {
  const classes = useStyles();

  const relationToShow = LEDGER_TRANSACTION_INFORMATION_FORM_BUILDER.find(
    (relation) => relation.subCategory === ledger.subCategory,
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm">
      <DialogTitle>Transactions Details</DialogTitle>
      <DialogContent dividers>
        <Paper className={classes.paper}>
          {' '}
          <Grid container className={classes.content} spacing={2}>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  {' '}
                  Type
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {sanitizeTextToTitleCase(ledger?.paymentType)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Category
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {sanitizeTextToTitleCase(ledger?.category)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Sub Category
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {sanitizeTextToTitleCase(ledger?.subCategory)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Amount
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {formatCurrency(ledger?.amount ?? 0)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Date
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {new Intl.DateTimeFormat('us').format(
                    ledger?.effectiveDate
                      ? new Date(ledger?.effectiveDate)
                      : new Date(),
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Reservation ID
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">{ledger?.lease?.id}</Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Lease Period
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {ledger?.lease?.leasePeriod?.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Sub Period
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {ledger?.leaseSubPeriod?.name || 'Not assigned'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Status
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {capitalizeFromUpperCase(ledger.paymentStatus || '')}
                </Typography>
              </Grid>
            </Grid>
            {relationToShow ? (
              relationToShow?.transactionInformationFields.map((field) => {
                return (
                  <Grid
                    item
                    container
                    className={`${classes.grid} ${classes.gray}`}
                    xs={12}>
                    <Grid xs={6} item>
                      <Typography variant="body1" className={classes.textGray}>
                        {capitalizeFromUpperCase(field.label)}
                      </Typography>
                    </Grid>
                    <Grid xs={6} className={classes.textEnd} item>
                      <Typography variant="body1">
                        {ledger?.ledgerTransactionInformationRelation &&
                          ledger?.ledgerTransactionInformationRelation[
                            field.key
                          ]}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Grid
                item
                container
                className={`${classes.grid} ${classes.gray}`}
                xs={12}>
                <Grid xs={6} item>
                  <Typography variant="body1" className={classes.textGray}>
                    No Reference
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Resident ID
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  {ledger?.lease?.userResident?.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Ledger Entry ID
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">{ledger?.id}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Added By
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">
                  Resident: {ledger?.lease?.userResident?.firstName}{' '}
                  {ledger?.lease?.userResident?.lastName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Created
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">{ledger?.createdAt}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Updated
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">{ledger?.updatedAt}</Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.grid} container xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Description
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">{ledger?.description}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={`${classes.grid} ${classes.gray}`}
              xs={12}>
              <Grid xs={6} item>
                <Typography variant="body1" className={classes.textGray}>
                  Notes
                </Typography>
              </Grid>
              <Grid xs={6} className={classes.textEnd} item>
                <Typography variant="body1">{ledger?.notes}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <ButtonAction
          fullWidth={false}
          onClick={() => {
            onClose();
          }}>
          Close
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
