/* eslint-disable @typescript-eslint/no-shadow */
import PermIdentity from '@material-ui/icons/PermIdentityOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import CreateIcon from '@material-ui/icons/CreateOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
// import CheckIcon from '@material-ui/icons/CheckOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LocalHotelIcon from '@material-ui/icons/LocalHotelOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import Cancel from '@material-ui/icons/Cancel';
import ThumbDown from '@material-ui/icons/ThumbDownOutlined';
import Thumbup from '@material-ui/icons/ThumbUpOutlined';
import { alpha, createStyles, makeStyles } from '@material-ui/core';
import { LeaseStatus as LeaseStatusEnum } from '@8baselabs/resident-io-shared';
import {
  BORDER_COLOR,
  SUCCESS_COLOR,
  ERROR_COLOR,
  MUTED_COLOR,
} from '../../theme';

import {
  LeaseStatusValues,
  StatusValues,
} from '../../../modules/opportunities/opportunities-types';
import { getEnumKeyByEnumValue } from '../../utils';

const STATUS_ICONS = [
  PermIdentity,
  LocalHotelIcon,
  ReceiptIcon,
  PersonAddIcon,
  AttachFileIcon,
  CreateIcon,
  PaymentIcon,
];

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      borderRadius: '5px',
      margin: '0 2px',
      color: 'white',
      height: 32,
      width: 32,
      backgroundColor: SUCCESS_COLOR,
      border: `5px solid ${SUCCESS_COLOR}`,
    },
    activatedIcon: {
      borderRadius: '5px',
      margin: '0 2px',
      color: BORDER_COLOR,
      height: 32,
      width: 32,
      backgroundColor: alpha(BORDER_COLOR, 0.14),
      border: `5px solid ${alpha(BORDER_COLOR, 0.14)}`,
    },
    errorIcon: {
      borderRadius: '5px',
      margin: '0 2px',
      color: 'white',
      height: 32,
      width: 32,
      backgroundColor: ERROR_COLOR,
      border: `5px solid ${ERROR_COLOR}`,
    },
    mutedIcon: {
      borderRadius: '5px',
      margin: '0 2px',
      color: 'white',
      height: 32,
      width: 32,
      backgroundColor: MUTED_COLOR,
      border: `5px solid ${MUTED_COLOR}`,
    },
  }),
);

const ACTIVE_STATUS = {
  NEW: [0],
  LEASE_PERIOD: [0],
  SELECT_PAYMENT_PLAN: [0, 1],
  BED_SELECTED: [0, 1],
  APPLICATION: [0, 1, 2],
  MANAGER_READY: [0, 1, 2, 3, 4, 5, 6],
  CONTRACT: [0, 1, 2, 3, 4, 5],
  PAYMENT_FAILED: [0, 1, 2, 3, 4, 5, 6],
  PAYMENT_PROCESSING: [0, 1, 2, 3, 4, 5, 6],
  PAID: [0, 1, 2, 3, 4, 5, 6],
  PAYMENT: [0, 1, 2, 3, 4, 5, 6],
  APPROVED: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  DENIED: [-1],
  FINALIZED: [0],
  'MOVE-IN': [0, 1, 2, 3, 4, 5, 6, 7, 8],
  'MOVE-OUT': [0, 1, 2, 3, 4, 5, 6, 7, 8],
  'MOVE-OUT_REFUND_ISSUED': [0, 1, 2, 3, 4, 5, 6, 7, 8],
  'MOVE-OUT_BALANCE_OWED': [0, 1, 2, 3, 4, 5, 6, 7, 8],
  GUARANTOR: [0, 1, 2, 3, 4],
  REQUIRED_DOCUMENTS: [0, 1, 2, 3, 4],
  DRAFT: [0, 1],
};

const shouldBeDeactive = (index: number, status: string): boolean => {
  return (
    !ACTIVE_STATUS[
      getEnumKeyByEnumValue(StatusValues, LeaseStatusValues[status].value)
    ].includes(index) ?? false
  );
};
export const LeaseStatus: React.FC<{ status?; disablePayment? }> = ({
  status,
  disablePayment = false,
}) => {
  const progress = Object.values(LeaseStatusValues).findIndex((leaseStatus) =>
    leaseStatus.value.includes(status || ('' as LeaseStatusEnum)),
  );
  const { icon, activatedIcon, errorIcon, mutedIcon } = useStyles();
  return (
    <>
      {STATUS_ICONS.map((Icon, index) => (
        <Icon
          key={`${status}${progress + index}`}
          fontSize="small"
          className={`${
            index === 6 && status === LeaseStatusEnum.PAYMENT_FAILED
              ? errorIcon
              : LeaseStatusEnum['MOVE-OUT'] === status ||
                'MOVE-OUT_REFUND_ISSUED' === status ||
                'MOVE-OUT_BALANCE_OWED' === status
              ? mutedIcon
              : icon
          } ${
            shouldBeDeactive(
              index,
              disablePayment && status === LeaseStatusEnum.ON_REVIEW
                ? 'CONTRACT'
                : status,
            ) && activatedIcon
          }`}
        />
      ))}
      {status !== LeaseStatusEnum.DENIED ? (
        LeaseStatusEnum['MOVE-OUT'] === status ||
        'MOVE-OUT_REFUND_ISSUED' === status ||
        'MOVE-OUT_BALANCE_OWED' === status ? (
          <Thumbup
            key={`${status}${progress}`}
            fontSize="small"
            className={`${mutedIcon} ${
              shouldBeDeactive(7, status) && activatedIcon
            }`}
          />
        ) : (
          <Thumbup
            key={`${status}${progress}`}
            fontSize="small"
            className={`${icon} ${
              shouldBeDeactive(7, status) && activatedIcon
            }`}
          />
        )
      ) : (
        <ThumbDown
          key={`${status}${progress}`}
          fontSize="small"
          className={`${errorIcon} ${
            shouldBeDeactive(-1, status) && activatedIcon
          }`}
        />
      )}
    </>
  );
};
