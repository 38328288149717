import { gql } from '@apollo/client';

export const USER_UPDATE_MUTATION = gql`
  mutation USER_UPDATE_MUTATION($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      allowChecks
      allowCreditCards
    }
  }
`;

export const SEND_VERIFICATION_CODE_QUERY = gql`
  query SEND_VERIFICATION_CODE_QUERY($id: ID!) {
    sendVerifyCodeResolver(id: $id) {
      success
      message
    }
  }
`;

export const VERIFY_CODE_QUERY = gql`
  query VERIFY_CODE_QUERY($code: Int!) {
    userVerifyCodeResolver(code: $code) {
      success
      message
    }
  }
`;

export const FETCH_ADMIN_INVITATION_QUERY = gql`
  query FETCH_ADMIN_INVITATION_QUERY($filter: AdminInvitationFilter) {
    adminInvitationsList(filter: $filter) {
      count
      items {
        id
        firstName
        lastName
        email
        role
        client {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_TERMS_AND_CONDITION = gql`
  query FETCH_TERMS_AND_CONDITION {
    termAndConditionsList(sort: { createdAt: DESC }) {
      items {
        id
        termAndCondition {
          downloadUrl
          filename
        }
        privacyPolicy {
          downloadUrl
          filename
        }
      }
    }
  }
`;
export const ACCEPT_ADMIN_INVITATION_MUTATION = gql`
  mutation ACCEPT_ADMIN_INVITATION_MUTATION($data: AcceptAdminInvitationInput!) {
    acceptAdminInvitation(data: $data) {
      message
      success
    }
  }
`;
export const UPDATE_INVITATION = gql`
  mutation UPDATE_INVITATION($data: AdminInvitationUpdateInput!) {
    adminInvitationUpdate(data: $data) {
      id
    }
  }
`;
export const CREATE_USER_TERMS = gql`
  mutation CREATE_USER_TERMS($data: TermsAndConditionsUserCreateInput!) {
    termsAndConditionsUserCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomer(
    $userId: String
    $propertyId: String
    $stripeAccountId: String
  ) {
    createCustomer(
      userId: $userId
      propertyId: $propertyId
      stripeAccountId: $stripeAccountId
    ) {
      stripeCustomerId
    }
  }
`;

export const LIST_CARDS_PAYMENT_METHODS_QUERY = gql`
  query ListCardPaymentMethods(
    $stripeCustomerId: String
    $stripeAccountId: String
  ) {
    listCardPaymentMethods(
      stripeCustomerId: $stripeCustomerId
      stripeAccountId: $stripeAccountId
    ) {
      id
      card {
        exp_year
        exp_month
        last4
        brand
      }
    }
  }
`;
//BANK
export const LIST_BANKS_PAYMENT_METHODS_QUERY = gql`
  query ListBanksPaymentMethods(
    $stripeCustomerId: String
    $stripeAccountId: String
  ) {
    listBanksPaymentMethods(
      stripeCustomerId: $stripeCustomerId
      stripeAccountId: $stripeAccountId
    ) {
      id
      billing_details {
        name
      }
      us_bank_account {
        routing_number
        last4
        bank_name
      }
    }
  }
`;

export const CREATE_SETUP_INTENT_MUTATION = gql`
  mutation CREATE_SETUP_INTENT_MUTATION(
    $stripeCustomerId: String
    $stripeAccountId: String
    $type: String
  ) {
    createSetupIntent(
      stripeCustomerId: $stripeCustomerId
      stripeAccountId: $stripeAccountId
      type: $type
    ) {
      client_secret
    }
  }
`;

export const CREATE_PAYMENT_INTENT_MUTATION = gql`
  mutation CREATE_PAYMENT_INTENT_MUTATION(
    $amount: Int
    $stripeCustomerId: String
    $stripeAccountId: String
    $paymentMethodId: String
    $reservationFee: Int
    $paymentSubCategory: String!
    $paymentIntentFees: [String]!
    $clientId: String!
  ) {
    createPaymentIntent(
      amount: $amount
      stripeCustomerId: $stripeCustomerId
      stripeAccountId: $stripeAccountId
      paymentMethodId: $paymentMethodId
      paymentSubCategory: $paymentSubCategory
      paymentIntentFees: $paymentIntentFees
      clientId: $clientId
      reservationFee: $reservationFee
    ) {
      id
      client_secret
      connected_account_payment_method_id
    }
  }
`;

export const CREATE_PROPERTY_STRIPE_ACCOUNT_MUTATION = gql`
  mutation CREATE_PROPERTY_STRIPE_ACCOUNT_MUTATION($email: String) {
    createPropertyStripeAccount(email: $email) {
      accountId
    }
  }
`;

export const CREATE_STRIPE_ACCOUNT_LINK = gql`
  mutation CREATE_STRIPE_ACCOUNT_LINK(
    $accountId: String
    $refreshUrl: String
    $returnUrl: String
  ) {
    createStripeAccountLink(
      accountId: $accountId
      refreshUrl: $refreshUrl
      returnUrl: $returnUrl
    ) {
      accountLink
    }
  }
`;

export const GET_STRIPE_ACCOUNT = gql`
  query GET_STRIPE_ACCOUNT($accountId: String!) {
    getStripeAccount(accountId: $accountId) {
      stripeAccount {
        id
        email
        charges_enabled
        details_submitted
      }
    }
  }
`;

export const FETCH_USER_STRIPE_CUSTOMER_BY_FILTER = gql`
  query FETCH_USER_STRIPE_CUSTOMER_BY_FILTER(
    $filter: UserStripeCustomerFilter!
  ) {
    userStripeCustomersList(filter: $filter) {
      items {
        id
        stripeCustomerId
      }
    }
  }
`;

export const CREATE_ASSESSMENT_ID = gql`
  mutation CREATE_ASSESSMENT_ID($userId: String!) {
    createAssessment(userId: $userId) {
      assessmentId
    }
  }
`;

export const CONFIRM_PAYMENT_INTENT_MUTATION = gql`
  mutation ConfirmPaymentIntent(
    $client_secret: String
    $connected_account_payment_method_id: String
  ) {
    confirmPaymentIntent(
      client_secret: $client_secret
      connected_account_payment_method_id: $connected_account_payment_method_id
    ) {
      success
    }
  }
`;

export const SAVE_ASSESSMENT = gql`
  mutation SAVE_ASSESSMENT($userId: String!, $assessmentId: String!) {
    saveAssessmentResults(userId: $userId, assessmentId: $assessmentId) {
      sucess
    }
  }
`;
