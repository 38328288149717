import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from '../modules/auth/Auth';
import { Register } from '../modules/auth/Register';
import { AuthCallback } from '../modules/auth/auth-callback/AuthCallback';
import { UserBlocked } from '../modules/auth/UserBlocked';

import { Logout } from '../modules/auth/Logout';
import {
  useSetupAuth0Token,
  useDefaultRedirect,
} from '../modules/auth/auth-hooks';
import {
  TENANT_ROUTES,
  ROUTES,
  INVITATIONS_ROUTES,
  ONBOARDING_ROUTES,
  GUARANTOR_ROUTES,
} from './types';
import { MainLoader } from '../shared/components/MainLoader';
import { Layout } from '../shared/components/Layout';
import { Session } from '../modules/session/Session';
import { ProtectedRoute } from './ProtectedRoute';
import { CreatePM } from '../modules/user/CreatePM';
import { InvitationPreview } from '../modules/invitation/InvitationPreview';
import { CheckAvailability } from '../modules/user/CheckAvailability';
import { EditMessageTemplateView } from '../modules/settings-view/message-templates/EditMessageTemplateView';
import { VerifyEmailView } from '../modules/auth/VerifyEmailView';
import { ConfirmVerifyEmailView } from '../modules/auth/ConfirmEmailView';
import { Notifications } from '../modules/profile/my-info/Notifications';
import { Security } from '../modules/profile/my-info/Security';
import { PeopleView } from '../modules/people/PeopleView';
import { CalendarView } from '../modules/calendar/CalendarView';
import { PeopleFullProfileView } from '../modules/people/PeopleFullProfileView';
import { AlertSnackbar } from '../shared/components/ui/Alert';

/*
WARNING: It is not necessary to add a protected route to each component within session, 
an example of how to add the routes I will leave below
  <ProtectedRoute path="/">
    <Session>
     <Route path="/1" component={AuthCallback} />
     <Route path="/2/4" component={AuthCallback} />
     <Route path="/3/5" component={AuthCallback} />
    </Session>
  </ProtectedRoute>
*/

const Reports = lazy(() =>
  import('../modules/reports/ReportsView').then((view) => ({
    default: view.ReportsView,
  })),
);
const Invitation = lazy(() =>
  import('../modules/invitation/InvitationView').then((view) => ({
    default: view.InvitationView,
  })),
);
const Dashboard = lazy(() =>
  import('../modules/dashboard/DashboardView').then((view) => ({
    default: view.Dashboard,
  })),
);
const Timeline = lazy(() =>
  import('../modules/timeline/TimelineView').then((view) => ({
    default: view.TimelineView,
  })),
);
const InboxMessages = lazy(() =>
  import('../modules/inbox/MessagesViewInbox').then((view) => ({
    default: view.MessagesViewInbox,
  })),
);
const InboxMaintenance = lazy(() =>
  import('../modules/inbox/MaintenanceView').then((view) => ({
    default: view.MaintenanceView,
  })),
);

const InboxMarketingSiteMessages = lazy(() =>
  import('../modules/inbox/MarketingSiteMessagesView').then((view) => ({
    default: view.MarketingSiteMessagesView,
  })),
);
const ClientInformation = lazy(() =>
  import('../modules/owner-settings/ClientInformation').then((view) => ({
    default: view.ClientInformation,
  })),
);
const ClientPage = lazy(() =>
  import('../modules/owner-settings/ClientPage').then((view) => ({
    default: view.ClientPage,
  })),
);
const Pricing = lazy(() =>
  import('../modules/owner-settings/Pricing').then((view) => ({
    default: view.Pricing,
  })),
);
const ChartOfAccounts = lazy(() =>
  import('../modules/owner-settings/ChartOfAccounts').then((view) => ({
    default: view.ChartOfAccounts,
  })),
);
const ManageUsers = lazy(() =>
  import('../modules/owner-settings/ManageUsers').then((view) => ({
    default: view.ManageUsers,
  })),
);
const PropertyPortfolios = lazy(() =>
  import('../modules/owner-settings/PropertyPortfolios').then((view) => ({
    default: view.PropertyPortfolios,
  })),
);
const SettingsView = lazy(() =>
  import('../modules/settings-view/SettingsView').then((view) => ({
    default: view.SettingsView,
  })),
);
const LeasePeriodsView = lazy(() =>
  import('../modules/settings-view/LeasePeriodsView').then((view) => ({
    default: view.LeasePeriodsView,
  })),
);
const LeaseSubPeriodsView = lazy(() =>
  import('../modules/settings-view/LeaseSubPeriodsView').then((view) => ({
    default: view.LeaseSubPeriodsView,
  })),
);
const MessageTemplateView = lazy(() =>
  import('../modules/settings-view/message-templates/MessageTemplateView').then(
    (view) => ({
      default: view.MessageTemplateView,
    }),
  ),
);

const SiteSettingsView = lazy(() =>
  import('../modules/settings-view/site-settings/SiteSettingsView').then(
    (view) => ({
      default: view.SiteSettingsView,
    }),
  ),
);
const NewMessageTemplateView = lazy(() =>
  import(
    '../modules/settings-view/message-templates/NewMessageTemplateView'
  ).then((view) => ({
    default: view.NewMessageTemplateView,
  })),
);
const PropertyDocumentsView = lazy(() =>
  import(
    '../modules/settings-view/property-documents/PropertyDocumentsView'
  ).then((view) => ({
    default: view.PropertyDocumentsView,
  })),
);
const LateFeesView = lazy(() =>
  import('../modules/settings-view/late-fees/LateFees').then((view) => ({
    default: view.LateFees,
  })),
);
const UnitAndRoomTypesView = lazy(() =>
  import('../modules/settings-view/unit-room-types/UnitAndRoomTypesView').then(
    (view) => ({
      default: view.UnitAndRoomTypesView,
    }),
  ),
);
const CreateDocumentView = lazy(() =>
  import('../modules/settings-view/property-documents/CreateDocumentView').then(
    (view) => ({
      default: view.CreateDocumentView,
    }),
  ),
);
const OpportunitiesView = lazy(() =>
  import('../modules/opportunities/OpportunitiesView').then((view) => ({
    default: view.OpportunitiesView,
  })),
);
const PaymentsView = lazy(() =>
  import('../modules/payments/PaymentsView').then((view) => ({
    default: view.PaymentsView,
  })),
);
const BankDepositsView = lazy(() =>
  import('../modules/bank-deposits/BankDepositsView').then((view) => ({
    default: view.BankDepositsView,
  })),
);
const ChecksView = lazy(() =>
  import('../modules/checks/ChecksView').then((view) => ({
    default: view.ChecksView,
  })),
);
const AddReservationsView = lazy(() =>
  import('../modules/opportunities/AddReservationsView').then((view) => ({
    default: view.AddReservationsView,
  })),
);
const ApproveReservationView = lazy(() =>
  import('../modules/opportunities/ApproveReservation').then((view) => ({
    default: view.ApproveReservationView,
  })),
);
const BuildingLayoutsView = lazy(() =>
  import('../modules/settings-view/building-layouts/BuilldingLayoutsView').then(
    (view) => ({
      default: view.BuildingLayoutsView,
    }),
  ),
);
const PropertyInformationView = lazy(() =>
  import(
    '../modules/settings-view/property-information/PropertyInformationView'
  ).then((view) => ({
    default: view.PropertyInformationView,
  })),
);
const PaymentPlanView = lazy(() =>
  import('../modules/settings-view/payment-plans/PaymentPlansView').then(
    (view) => ({
      default: view.PaymentPlanView,
    }),
  ),
);
const AssignPaymentPlanView = lazy(() =>
  import('../modules/settings-view/payment-plans/AssignPaymentPlansView').then(
    (view) => ({
      default: view.AssignPaymentPlanView,
    }),
  ),
);
const AccountInformation = lazy(() =>
  import('../modules/settings-view/account/AccountInformation').then(
    (view) => ({
      default: view.AccountInformation,
    }),
  ),
);
const AccountPermissions = lazy(() =>
  import('../modules/settings-view/account/AccountPermissions').then(
    (view) => ({
      default: view.AccountPermissions,
    }),
  ),
);

const AccountSecurity = lazy(() =>
  import('../modules/settings-view/account/AccountSecurity').then((view) => ({
    default: view.AccountSecurity,
  })),
);

const AccountNotifications = lazy(() =>
  import('../modules/settings-view/account/AccountNotifications').then(
    (view) => ({
      default: view.AccountNotifications,
    }),
  ),
);
const AdminManageUsers = lazy(() =>
  import('../modules/admin/ManageUsers').then((view) => ({
    default: view.ManageUsersView,
  })),
);
const AdminManageClientUsers = lazy(() =>
  import('../modules/admin/ManageClientUsers').then((view) => ({
    default: view.ManageClientUsers,
  })),
);
const AdminManageAdmins = lazy(() =>
  import('../modules/admin/ManageAdmins').then((view) => ({
    default: view.ManageAdmins,
  })),
);
const AdminGlobalFees = lazy(() =>
  import('../modules/admin/GlobalFees').then((view) => ({
    default: view.GlobalFees,
  })),
);

const AdminSchools = lazy(() =>
  import('../modules/admin/schools/SchoolsView').then((view) => ({
    default: view.SchoolsView,
  })),
);

const AdminSchoolPage = lazy(() =>
  import('../modules/admin/schools/SchoolPage').then((view) => ({
    default: view.SchoolPage,
  })),
);

const LatestPostHoc = lazy(() =>
  import('../modules/admin/LatestPost').then((view) => ({
    default: view.LatestPostHoc,
  })),
);

const StudentLife = lazy(() =>
  import('../modules/admin/studentLife/StudentLifeView').then((view) => ({
    default: view.StudentLifeView,
  })),
);

const HelloSignSetting = lazy(() =>
  import('../modules/admin/HelloSignSetting').then((view) => ({
    default: view.HelloSignSetting,
  })),
);

const PropertiesAdmin = lazy(() =>
  import('../modules/admin/Properties').then((view) => ({
    default: view.Properties,
  })),
);

const ClientsAdmin = lazy(() =>
  import('../modules/admin/Clients').then((view) => ({
    default: view.Clients,
  })),
);

const PropertyPageView = lazy(() =>
  import('../modules/settings-view/property-page/PropertyPageView').then(
    (view) => ({
      default: view.PropertyPageView,
    }),
  ),
);
// RESIDENT ROUTES
// const MyInfoView = lazy(() =>
//   import('../modules/profile/my-info/MyInfoView').then((view) => ({
//     default: view.MyInfoView,
//   })),
// );

const ContactInfoView = lazy(() =>
  import('../modules/profile/my-info/ContactInfo').then((view) => ({
    default: view.ContactInfo,
  })),
);

const ReservationLedgerView = lazy(() =>
  import('../modules/tenant/components/reservation/ReservationLedger').then(
    (view) => ({
      default: view.ReservationLedger,
    }),
  ),
);

const ReservationInboxView = lazy(() =>
  import('../modules/tenant/components/reservation/ReservationInbox').then(
    (view) => ({
      default: view.ReservationInbox,
    }),
  ),
);

const FriendsView = lazy(() =>
  import(
    '../modules/tenant/components/reservation/ReservationFriendsView'
  ).then((view) => ({
    default: view.ReservationFriendsView,
  })),
);

const SelectPaymentPlan = lazy(() =>
  import('../modules/reservation/SelectPaymentPlan').then((view) => ({
    default: view.SelectPaymentPlan,
  })),
);

const TransferView = lazy(() =>
  import(
    '../modules/tenant/components/reservation/ReservationTransferView'
  ).then((view) => ({
    default: view.ReservationTransferView,
  })),
);

const ApplicationView = lazy(() =>
  import(
    '../modules/tenant/components/reservation/ReservationApplicationView'
  ).then((view) => ({
    default: view.ReservationApplicationView,
  })),
);

const DocumentsView = lazy(() =>
  import(
    '../modules/tenant/components/reservation/ReservationDocumentsView'
  ).then((view) => ({
    default: view.ReservationDocumentsView,
  })),
);

const PreferencesView = lazy(() =>
  import(
    '../modules/tenant/components/reservation/ReservationPreferencesView'
  ).then((view) => ({
    default: view.ReservationPreferencesView,
  })),
);

const TenantHistoryView = lazy(() =>
  import('../modules/profile/my-info/TenantHistory').then((view) => ({
    default: view.TenantHistory,
  })),
);

const SocialNetworksView = lazy(() =>
  import('../modules/profile/my-info/SocialNetworks').then((view) => ({
    default: view.SocialNetworks,
  })),
);

const PersonalityView = lazy(() =>
  import('../modules/profile/my-info/Personality').then((view) => ({
    default: view.Personality,
  })),
);

const TraitsView = lazy(() =>
  import('../modules/profile/my-info/Traits').then((view) => ({
    default: view.Traits,
  })),
);

const PersonalityBreakdownView = lazy(() =>
  import('../modules/profile/my-info/PersonalityBreakdown').then((view) => ({
    default: view.PersonalityBreakdown,
  })),
);

const PersonalityDetailsView = lazy(() =>
  import('../modules/profile/my-info/PersonalityDetails').then((view) => ({
    default: view.PersonalityDetails,
  })),
);
/* 
const IdentityView = lazy(() =>
  import('../modules/profile/my-info/Identity').then((view) => ({
    default: view.Identity,
  })),
); */

/* const CommunicationsView = lazy(() =>
  import('../modules/profile/communications/CommunicationsView').then(
    (view) => ({
      default: view.CommunicationsView,
    }),
  ),
); */
const ReservationView = lazy(() =>
  import('../modules/tenant/ReservationView').then((view) => ({
    default: view.ReservationView,
  })),
);
const Personality = lazy(() =>
  import('../modules/reservation/Personality').then((view) => ({
    default: view.Personality,
  })),
);

const SelectUnitRoom = lazy(() =>
  import('../modules/reservation/SelectUnitRoom').then((view) => ({
    default: view.ReservationView,
  })),
);

const Application = lazy(() =>
  import('../modules/guarantor/TenantPortalApplication').then((view) => ({
    default: view.TenantPortalApplication,
  })),
);
/* const TenantDocuments = lazy(() =>
  import('../modules/reservation/TenantDocuments').then((view) => ({
    default: view.TenantDocuments,
  })),
); */
const SubmissionComplete = lazy(() =>
  import('../modules/reservation/SubmissionComplete').then((view) => ({
    default: view.SubmissionComplete,
  })),
);

const Contract = lazy(() =>
  import('../modules/reservation/Contract').then((view) => ({
    default: view.Contract,
  })),
);

const PaymentView = lazy(() =>
  import('../modules/reservation/PaymentView').then((view) => ({
    default: view.PaymentView,
  })),
);

// ONBOARDING ROUTES
const RegisterUserView = lazy(() =>
  import('../modules/user/RegisterUserView').then((view) => ({
    default: view.RegisterUserView,
  })),
);

const PersonalityQuestionView = lazy(() =>
  import('../modules/user/PersonalityQuestion').then((view) => ({
    default: view.PersonalityQuestion,
  })),
);
const AboutMeView = lazy(() =>
  import('../modules/user/AboutMe').then((view) => ({
    default: view.AboutMe,
  })),
);
const AcceptTermsAndConditionsView = lazy(() =>
  import('../modules/user/TermsAndConditions').then((view) => ({
    default: view.AcceptTermsAndConditions,
  })),
);

const UpdateProfileView = lazy(() =>
  import('../modules/user/UpdateProfile').then((view) => ({
    default: view.UpdateProfile,
  })),
);

const PersonalityAssessmentView = lazy(() =>
  import('../modules/user/PersonalityAssessment').then((view) => ({
    default: view.PersonalityAssessment,
  })),
);

const PersonalityResultsView = lazy(() =>
  import('../modules/user/PersonalityResults').then((view) => ({
    default: view.PersonalityResults,
  })),
);

const SocialNetworksOnboardView = lazy(() =>
  import('../modules/user/SocialNetworks').then((view) => ({
    default: view.SocialNetworks,
  })),
);
const OptionalServiceView = lazy(() =>
  import('../modules/optional-services/OptionalServiceView').then((view) => ({
    default: view.OptionalServiceView,
  })),
);

// GUARANTOR ROUTES
const RegisterApplicationGuarantor = lazy(() =>
  import('../modules/guarantor/GuarantorView').then((view) => ({
    default: view.GuarantorView,
  })),
);
const UpdateGuarantor = lazy(() =>
  import('../modules/guarantor/UpdateGuarantor').then((view) => ({
    default: view.UpdateGuarantor,
  })),
);

const AcceptGuarantorInvitation = lazy(() =>
  import('../shared/components/InvitationView').then((view) => ({
    default: view.InvitationView,
  })),
);

const UninvitedGuarantor = lazy(() =>
  import('../modules/guarantor/components/UninvitedGuarantor').then((view) => ({
    default: view.UninvitedGuarantor,
  })),
);

const InviteFriends = lazy(() =>
  import('../modules/reservation/InviteFriends').then((view) => ({
    default: view.InviteFriends,
  })),
);
const PaymentPlanDetails = lazy(() =>
  import('../modules/settings-view/payment-plans/PaymentPlansViewDetails').then(
    (view) => ({
      default: view.PaymentPlanViewDetails,
    }),
  ),
);
const CreateFormView = lazy(() =>
  import('../modules/settings-view/property-documents/CreateFormView').then(
    (view) => ({
      default: view.CreateFormView,
    }),
  ),
);

const OptionalPreferenceDetails = lazy(() =>
  import(
    '../modules/settings-view/optional-preference/OptionalPreferencesViewDetails'
  ).then((view) => ({
    default: view.OptionalPreferenceViewDetails,
  })),
);
const OptionalPreferenceView = lazy(() =>
  import(
    '../modules/settings-view/optional-preference/OptionalPreferenceView'
  ).then((view) => ({
    default: view.OptionalPreferenceView,
  })),
);

const CollectionsView = lazy(() =>
  import('../modules/collections/CollectionsView').then((view) => ({
    default: view.CollectionsView,
  })),
);
// const AdminInvitations = lazy(() =>
//   import('../modules/user/AdminInvitations').then((view) => ({
//     default: view.AdminInvitation,
//   })),
// );

export const Routes: React.FC = () => {
  const loadingToken = useSetupAuth0Token();

  useDefaultRedirect();

  return loadingToken ? (
    <MainLoader />
  ) : (
    <>
      <Switch>
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/auth/callback" component={AuthCallback} />

        <Route exact path="/create-pm" component={CreatePM} />
        <Route exact path="/check-availabilty" component={CheckAvailability} />

        <Route exact path="/invitation" component={InvitationPreview} />

        <Session>
          <Suspense fallback={<MainLoader />}>
            <AlertSnackbar>
              <Route exact path="/blocked-user" component={UserBlocked} />
              <Route
                path={`${INVITATIONS_ROUTES.verifyEmail.path}`}
                component={VerifyEmailView}
                exact
              />
              <Route
                path={`${INVITATIONS_ROUTES.confirmVerifyEmail.path}`}
                component={ConfirmVerifyEmailView}
                exact
              />
              <Route
                path={`${INVITATIONS_ROUTES.acceptinvitation.path}`}
                component={Invitation}
                exact
              />

              <ProtectedRoute
                role={GUARANTOR_ROUTES.update.roles}
                path={`${GUARANTOR_ROUTES.update.path}`}
                element={<UpdateGuarantor />}
              />

              <ProtectedRoute
                role={GUARANTOR_ROUTES.invitation.roles}
                path={`${GUARANTOR_ROUTES.invitation.path}`}
                element={<AcceptGuarantorInvitation />}
              />

              <ProtectedRoute
                role={GUARANTOR_ROUTES.review.roles}
                path={`${GUARANTOR_ROUTES.review.path}`}
                element={<RegisterApplicationGuarantor />}
                exact
              />

              <ProtectedRoute
                role={GUARANTOR_ROUTES.noInvitation.roles}
                path={`${GUARANTOR_ROUTES.noInvitation.path}`}
                element={<UninvitedGuarantor />}
                exact
              />
            </AlertSnackbar>

            <AlertSnackbar>
              <Layout>
                <Route
                  path={`${ONBOARDING_ROUTES.createprofile.path}`}
                  component={RegisterUserView}
                  exact
                />
                <Route
                  path={`${ONBOARDING_ROUTES.personality.path}`}
                  component={PersonalityQuestionView}
                  exact
                />
                <Route
                  path={`${ONBOARDING_ROUTES.social.path}`}
                  component={SocialNetworksOnboardView}
                  exact
                />
                <Route
                  path={`${ONBOARDING_ROUTES['about-me'].path}`}
                  component={AboutMeView}
                  exact
                />
                <Route
                  path={`${INVITATIONS_ROUTES['accept-terms'].path}`}
                  component={AcceptTermsAndConditionsView}
                  exact
                />

                <Route
                  path={`${ONBOARDING_ROUTES['update-profile'].path}`}
                  component={UpdateProfileView}
                  exact
                />
                <Route
                  path={`${ONBOARDING_ROUTES['personality-test'].path}`}
                  component={PersonalityAssessmentView}
                  exact
                />
                <Route
                  path={`${ONBOARDING_ROUTES['personality-results'].path}`}
                  component={PersonalityResultsView}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.dashboard.roles}
                  path={ROUTES.dashboard.path}
                  element={<Dashboard />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.inbox.roles}
                  path={`${ROUTES.inbox.path}${ROUTES.inbox?.subRoutes?.messages.path}`}
                  element={<InboxMessages />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.people.roles}
                  path={`${ROUTES.inbox.path}${ROUTES.inbox?.subRoutes?.maintenance.path}`}
                  element={<InboxMaintenance />}
                />
                <ProtectedRoute
                  role={ROUTES.people.roles}
                  path={`${ROUTES.inbox.path}${ROUTES.inbox?.subRoutes?.['marketing-site-messages'].path}`}
                  element={<InboxMarketingSiteMessages />}
                />
                <ProtectedRoute
                  role={ROUTES.timeline.roles}
                  path={ROUTES.timeline.path}
                  element={<Timeline />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.opportunities.roles}
                  path={ROUTES.opportunities.path}
                  element={<OpportunitiesView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.payments.roles}
                  path={ROUTES.payments.path}
                  element={<PaymentsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.deposits.roles}
                  path={ROUTES.deposits.path}
                  element={<BankDepositsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.checks.roles}
                  path={ROUTES.checks.path}
                  element={<ChecksView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.opportunities.roles}
                  path={`${ROUTES.opportunities.path}${ROUTES.opportunities.subRoutes?.addreservations.path}${ROUTES.opportunities.subRoutes?.addreservations.param}`}
                  element={<AddReservationsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.people.roles}
                  path={ROUTES.people.path}
                  element={<PeopleView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.people.roles}
                  path={`${ROUTES.people.path}${ROUTES.people?.subRoutes?.profile.path}`}
                  element={<PeopleFullProfileView />}
                />
                <ProtectedRoute
                  role={ROUTES.opportunities.roles}
                  path={`${ROUTES.opportunities.path}${ROUTES.opportunities.subRoutes?.approvereservations.path}`}
                  element={<ApproveReservationView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}`}
                  element={
                    <Redirect
                      to={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.clientinformation.path}`}
                    />
                  }
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.clientpage.path}`}
                  element={<ClientPage />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.clientinformation.path}`}
                  element={<ClientInformation />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.pricing.path}`}
                  element={<Pricing />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.chartofaccounts.path}`}
                  element={<ChartOfAccounts />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.manageusers.path}`}
                  element={<ManageUsers />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.ownersettings.roles}
                  path={`${ROUTES.ownersettings.path}${ROUTES.ownersettings.subRoutes?.propertyportfolios.path}`}
                  element={<PropertyPortfolios />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={ROUTES.settings.path}
                  element={<SettingsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['lease-periods'].path}`}
                  element={<LeasePeriodsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['late-fees'].path}`}
                  element={<LateFeesView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.subperiods.path}`}
                  element={<LeaseSubPeriodsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['message-templates'].path}`}
                  element={<MessageTemplateView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['site-settings'].path}`}
                  element={<SiteSettingsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.newMessageTemplates.path}`}
                  element={<NewMessageTemplateView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.editMessageTemplates.path}`}
                  element={<EditMessageTemplateView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['property-documents'].path}`}
                  element={<PropertyDocumentsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['unit-room-types'].path}`}
                  element={<UnitAndRoomTypesView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.createdocument.path}`}
                  element={<CreateDocumentView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['create-form'].path}`}
                  element={<CreateFormView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.buildinglayouts.path}`}
                  element={<BuildingLayoutsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.propertyinformation.path}${ROUTES.settings.subRoutes?.propertyinformation?.param}`}
                  element={<PropertyInformationView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.propertypage.path}${ROUTES.settings.subRoutes?.propertypage?.param}`}
                  element={<PropertyPageView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.paymentplans.path}`}
                  element={<PaymentPlanView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['payment-plan-details'].path}`}
                  element={<PaymentPlanDetails />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['optional-preferences'].path}`}
                  element={<OptionalPreferenceView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.['optional-preferences-details'].path}`}
                  element={<OptionalPreferenceDetails />}
                  exact
                />

                <ProtectedRoute
                  role={ROUTES.settings.roles}
                  path={`${ROUTES.settings.path}${ROUTES.settings.subRoutes?.assignpaymentplans.path}`}
                  element={<AssignPaymentPlanView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.accountsettings.roles}
                  path={`${ROUTES.accountsettings.path}`}
                  element={
                    <Redirect
                      to={`${ROUTES.accountsettings.path}${ROUTES.accountsettings.subRoutes?.info.path}`}
                    />
                  }
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.accountsettings.roles}
                  path={`${ROUTES.accountsettings.path}${ROUTES.accountsettings.subRoutes?.info.path}`}
                  element={<AccountInformation />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.accountsettings.roles}
                  path={`${ROUTES.accountsettings.path}${ROUTES.accountsettings.subRoutes?.permissions.path}`}
                  element={<AccountPermissions />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.accountsettings.roles}
                  path={`${ROUTES.accountsettings.path}${ROUTES.accountsettings.subRoutes?.security.path}`}
                  element={<AccountSecurity />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.accountsettings.roles}
                  path={`${ROUTES.accountsettings.path}${ROUTES.accountsettings.subRoutes?.notifications.path}`}
                  element={<AccountNotifications />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}`}
                  element={
                    <Redirect
                      to={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.manageusers.path}`}
                    />
                  }
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.manageusers.path}`}
                  element={<AdminManageUsers />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.manageclientusers.path}`}
                  element={<AdminManageClientUsers />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.manageadmins.path}`}
                  element={<AdminManageAdmins />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.globalfees.path}`}
                  element={<AdminGlobalFees />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.schools.path}`}
                  element={<AdminSchools />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.schoolPage.path}`}
                  element={<AdminSchoolPage />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.['latest-post'].path}`}
                  element={<LatestPostHoc />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.['student-life'].path}`}
                  element={<StudentLife />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.['hellosign-setting'].path}`}
                  element={<HelloSignSetting />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.properties.path}`}
                  element={<PropertiesAdmin />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.adminsettings.roles}
                  path={`${ROUTES.adminsettings.path}${ROUTES.adminsettings.subRoutes?.manageclients.path}`}
                  element={<ClientsAdmin />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.calendar.roles}
                  path={ROUTES.calendar.path}
                  element={<CalendarView />}
                  exact
                />
                {/* <ProtectedRoute
                role={TENANT_ROUTES.profile.roles}
                path={`${TENANT_ROUTES.profile.path}`}
                element={<MyInfoView />}
                exact
              /> */}
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.contactinfo.path}`}
                  element={<ContactInfoView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.ledger.path}`}
                  element={<ReservationLedgerView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.inbox.path}`}
                  element={<ReservationInboxView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.roommates.path}`}
                  element={<FriendsView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.transfer.path}`}
                  element={<TransferView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.['reservation-application'].path}`}
                  element={<ApplicationView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.['reservation-documents'].path}`}
                  element={<DocumentsView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.reservation.roles}
                  path={`${TENANT_ROUTES.reservation.path}${TENANT_ROUTES.reservation.subRoutes?.['reservation-preferences'].path}`}
                  element={<PreferencesView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.tenanthistory.path}`}
                  element={<TenantHistoryView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.socialnetworks.path}`}
                  element={<SocialNetworksView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personality.path}`}
                  element={<PersonalityView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.traits.path}`}
                  element={<TraitsView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personalitybreakdown.path}`}
                  element={<PersonalityBreakdownView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personalitydetails.path}`}
                  element={<PersonalityDetailsView />}
                  exact
                />
                {/* <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.identity.path}`}
                  element={<IdentityView />}
                  exact
                /> */}
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.notifications.path}`}
                  element={<Notifications />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.profile.roles}
                  path={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.security.path}`}
                  element={<Security />}
                  exact
                />
                {/* <ProtectedRoute
                  role={TENANT_ROUTES['email-preferences'].roles}
                  path={`${TENANT_ROUTES['email-preferences'].path}`}
                  element={<CommunicationsView />}
                  exact
                /> */}

                <ProtectedRoute
                  role={TENANT_ROUTES.resident.roles}
                  path={TENANT_ROUTES.resident.path}
                  element={<ReservationView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES['personality-assessment'].roles}
                  path={`${TENANT_ROUTES['personality-assessment'].path}`}
                  element={<Personality />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES['select-unit'].roles}
                  path={`${TENANT_ROUTES['select-unit'].path}`}
                  element={<SelectUnitRoom />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES['select-payment-plan'].roles}
                  path={`${TENANT_ROUTES['select-payment-plan'].path}`}
                  element={<SelectPaymentPlan />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.application.roles}
                  path={`${TENANT_ROUTES.application.path}`}
                  element={<Application />}
                />
                <ProtectedRoute
                  role={TENANT_ROUTES['invite-friends'].roles}
                  path={`${TENANT_ROUTES['invite-friends'].path}`}
                  element={<InviteFriends />}
                />
                <ProtectedRoute
                  role={TENANT_ROUTES['optional-service'].roles}
                  path={`${TENANT_ROUTES['optional-service'].path}`}
                  element={<OptionalServiceView />}
                  exact
                />
                {/* <ProtectedRoute
                  role={TENANT_ROUTES.documents.roles}
                  path={`${TENANT_ROUTES.documents.path}`}
                  element={<TenantDocuments />}
                  exact
                /> */}
                <ProtectedRoute
                  role={TENANT_ROUTES.contract.roles}
                  path={`${TENANT_ROUTES.contract.path}`}
                  element={<Contract />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES.payment.roles}
                  path={`${TENANT_ROUTES.payment.path}`}
                  element={<PaymentView />}
                  exact
                />
                <ProtectedRoute
                  role={TENANT_ROUTES['submission-complete'].roles}
                  path={`${TENANT_ROUTES['submission-complete'].path}`}
                  element={<SubmissionComplete />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.collections.roles}
                  path={ROUTES.collections.path}
                  element={<CollectionsView />}
                  exact
                />
                <ProtectedRoute
                  role={ROUTES.reports.roles}
                  path={ROUTES.reports.path}
                  element={<Reports />}
                  exact
                />
              </Layout>
            </AlertSnackbar>
          </Suspense>
        </Session>
      </Switch>
    </>
  );
};
