import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  createStyles,
  alpha,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/FormatIndentIncrease';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { useSubscription, useCallAction } from '@8baselabs/react-simple-state';

import { DRAWER_WIDTH } from '../constants';
import {
  BORDER_COLOR,
  MUTED_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../theme';
import { Search } from '../../modules/search/Search';
import { useActiveRouteAndSubRoutesName } from '../hooks';
import { ROUTES } from '../../routes/types';
import { PrimaryButton } from './ui/buttons';
import { useSession } from '../../modules/session/session-hooks';
import { resendEmailVerification } from './components-actions';
import {
  OnRequestEmailVerification,
  OnRequestEmailVerificationError,
} from './components-events';
import { replaceAll } from '../utils';
import { AlertContext } from '../../routes/AlertContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: '0px 5px 15px rgb(171 171 171 / 17%)',
      padding: theme.spacing(0, 2),
      transition: 'width 0.35s, margin 0.35s',
    },
    appBarWithSidebar: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: DRAWER_WIDTH,
    },
    menuButton: {
      color: '#B2B2B2',
      marginRight: theme.spacing(2),
    },
    send: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    title: {
      flexGrow: 1,
    },
    role: {
      fontWeight: 'normal',
      color: '#ed5d96',
    },
    search: {
      position: 'relative',
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      border: `1px solid ${BORDER_COLOR}`,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        minWidth: 200,
        width: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 450,
      },
    },
    searchIcon: {
      alignItems: 'center',
      color: MUTED_COLOR,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      pointerEvents: 'none',
      position: 'absolute',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    notificationIcon: {
      color: MUTED_COLOR,
      margin: theme.spacing(0, 0, 0, 3),
    },
    userMenuButton: {
      margin: theme.spacing(0, 0, 0, 3),
    },
    userMenuIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
    },
    userMenuItem: {
      padding: theme.spacing(2, 2),
    },
    userMenuItemLogout: {
      color: PRIMARY_COLOR,
      '& .MuiListItemIcon-root': {
        color: PRIMARY_COLOR,
      },
    },
    userMenuItemIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    advMenuItem: {
      backgroundColor: 'red',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
    },
    tool: {
      justifyContent: 'space-between',
    },
    cursor: {
      cursor: 'pointer',
    },
    fontWeight: {
      fontWeight: 'bold',
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        color: PRIMARY_TEXT_COLOR,
      },
    },
  }),
);

type TopBarProps = {
  isSidebarOpen: boolean;
  handleDrawerToggle: () => void;
};

export const Topbar: React.FC<TopBarProps> = ({
  isSidebarOpen,
  handleDrawerToggle,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { logout } = useAuth0();
  const [session] = useSession();
  const [searchTerm, setSearchTerm] = useState('');
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const anchorSearchRef = useRef<HTMLInputElement>(null);
  const anchorProfileRef = useRef<HTMLButtonElement>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };
  const { user: auth0userData } = useAuth0();
  const alert = useContext(AlertContext);

  const [callResendEmailVerification] = useCallAction(resendEmailVerification);

  useSubscription(OnRequestEmailVerification, () => {
    alert({
      success: true,
      message: 'Email verification successfully sent',
    });
  });

  useSubscription(OnRequestEmailVerificationError, () => {
    alert({
      error: true,
      message: 'Something went wrong while sending email verification',
    });
  });

  const handleProfileMenu = (): void => {
    setOpenProfileMenu((prevOpen) => !prevOpen);
  };

  const handleProfileClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      anchorProfileRef.current &&
      anchorProfileRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenProfileMenu(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProfileMenu(false);
    }
  };

  const handleLogout = (): void =>
    logout({
      returnTo: window.location.origin,
    });

  const handleNewLead = (): void => {
    history.push(
      `${ROUTES.opportunities.path}${ROUTES.opportunities.subRoutes?.addreservations?.path}/1`,
    );
  };
  const handleAccountSettings = (): void => {
    history.push(
      `${ROUTES.accountsettings.path}${ROUTES.accountsettings.subRoutes?.info.path}`,
    );
    setOpenProfileMenu(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openProfileMenu);
  useEffect(() => {
    if (Boolean(prevOpen.current) && !openProfileMenu) {
      anchorProfileRef.current?.focus();
    }

    prevOpen.current = openProfileMenu;
  }, [openProfileMenu]);

  const toolbarTitles = useActiveRouteAndSubRoutesName();

  const user = session?.user;

  const userRole =
    user?.userClientUserRelation?.items?.[0]?.role ?? user?.userType ?? '';

  const userRoleFormatted = replaceAll(userRole, '_', ' ');

  const [firstNameLetter] = user?.firstName ?? '';
  const [lastNameLetter] = user?.lastName ?? '';

  return (
    <>
      <AppBar
        className={
          isSidebarOpen
            ? `${classes.appBar} ${classes.appBarWithSidebar}`
            : classes.appBar
        }
        color="default">
        <Toolbar className={classes.tool}>
          <IconButton
            color="inherit"
            aria-label="open or close drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" className={classes.title}>
            {toolbarTitles.map((route, i) => {
              if (i !== toolbarTitles.length - 1) {
                return (
                  <Link
                    to={route.path || location.pathname}
                    className={classes.link}
                    key={i}>
                    <Typography
                      color="inherit"
                      variant="body1"
                      noWrap
                      key={route.name}>
                      {route.name}
                    </Typography>
                  </Link>
                );
              }
              return (
                <Link
                  to={route.path || location.pathname}
                  className={classes.link}
                  key={i}>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    noWrap
                    key={route.name}>
                    {route.name}
                  </Typography>
                </Link>
              );
            })}
          </Breadcrumbs>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              ref={anchorSearchRef}
              placeholder="Type to Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={searchTerm}
              onChange={handleChange}
            />
          </div>
          <PrimaryButton onClick={handleNewLead}>NEW LEAD</PrimaryButton>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls={
                openProfileMenu ? 'menu-profile-appbar' : undefined
              }
              aria-haspopup="true"
              ref={anchorProfileRef}
              className={classes.userMenuButton}
              onClick={handleProfileMenu}
              color="inherit">
              {auth0userData?.email_verified ? (
                <Avatar
                  src={user?.avatar?.downloadUrl}
                  className={classes.userMenuIcon}>
                  {firstNameLetter} {lastNameLetter}
                </Avatar>
              ) : (
                <Badge badgeContent="" overlap="circular" color="secondary">
                  <Avatar
                    src={user?.avatar?.downloadUrl}
                    className={classes.userMenuIcon}>
                    {firstNameLetter} {lastNameLetter}
                  </Avatar>
                </Badge>
              )}
            </IconButton>
            <Popper
              open={openProfileMenu}
              anchorEl={anchorProfileRef.current}
              role={undefined}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'right top' : 'right bottom',
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleProfileClose}>
                      <MenuList
                        autoFocusItem={openProfileMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}>
                        <Box
                          display="flex"
                          padding={2}
                          alignItems="center"
                          justifyContent="space-between">
                          <Avatar
                            src={user?.avatar?.downloadUrl}
                            className={classes.userMenuItemIcon}>
                            {firstNameLetter} {lastNameLetter}
                          </Avatar>
                          <div
                            style={{
                              padding: '5px',
                              maxWidth: '220px',
                              wordBreak: 'break-word',
                              overflowWrap: 'break-word',
                            }}>
                            <Typography
                              variant="h6"
                              className={classes.fontWeight}>
                              {user?.firstName && user?.lastName
                                ? user?.firstName + ' ' + user?.lastName
                                : user?.email}
                            </Typography>
                            <Typography className={classes.role}>
                              {userRoleFormatted}
                            </Typography>
                          </div>
                        </Box>
                        <Divider />

                        {!auth0userData?.email_verified && (
                          <div className={classes.advMenuItem}>
                            Verify your email, or&nbsp;
                            <span
                              className={classes.send}
                              onClick={() =>
                                callResendEmailVerification(auth0userData)
                              }
                              role="none">
                              send the verification email again
                            </span>
                          </div>
                        )}
                        <MenuItem
                          onClick={handleAccountSettings}
                          className={classes.userMenuItem}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          Account Settings
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          onClick={handleLogout}
                          button
                          className={clsx([
                            classes.userMenuItem,
                            classes.userMenuItemLogout,
                          ])}>
                          <ListItemIcon>
                            <PowerSettingsNewOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          Sign Out
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Search anchorRef={anchorSearchRef} term={searchTerm} />
    </>
  );
};
