import {
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import { useCallback, useEffect, useState } from 'react';
import { authenticated } from '../auth/auth-callback/auth-callback-event';
import { UpdateUser } from '../profile/my-info/my-info-events';
import { fetchSession, sessionSubscription } from './session-actions';
import { OnFetchSession, OnFetchSessionError } from './session-events';
import { FetchSessionData } from './session-types';

/**
 * @param {boolean} loading - To start fetching or not.
 * @returns  {[FetchSessionData,boolean, () => void]} Loading - To start fetching or not.
 */
export function useSetupSession(
  loading: boolean,
): [FetchSessionData | undefined, boolean, () => void] {
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const refetch = useCallback(() => {
    fetchSession();
  }, []);
  const session = useEvent(OnFetchSession);
  useEffect(() => {
    if (session && loadingSession && session.user.id) {
      setLoadingSession(false);
    }
  }, [session, loadingSession]);

  useSubscription(authenticated, (data) => {
    if (data && !loading) {
      fetchSession();
    }
  });

  useSubscription(OnFetchSessionError, (err) => {
    if (err) {
      setLoadingSession(false);
    }
  });

  useEffect(() => {
    if (loading || !session?.user?.id) {
      return () => {};
    }
    const subscription = sessionSubscription(
      { user: session?.user.id },
      (subData) => {
        const currentSession = OnFetchSession.get() ?? ({} as FetchSessionData);

        if (subData?.Users.node.id !== currentSession.user.id) {
          console.error('Session subscription error: Session query user id does not match the session subscription user id');
          fetchSession();
          return;
        }
        const newData = subData?.Users.updatedFields.reduce(
          (prevuis, key) => ({ ...prevuis, [key]: subData.Users.node[key] }),
          currentSession,
        );

        const newSession = {
          ...currentSession,
          user: { ...currentSession.user, ...newData },
        };

        OnFetchSession.dispatch(newSession as FetchSessionData);
      },
    );

    return subscription.unsubscribe;
  }, [session?.user.id, loading]);
  useEffect(() => {
    if (loading) {
      return;
    }
    setLoadingSession(true);
    fetchSession();
  }, [loading]);
  return [session, loadingSession || loading, refetch];
}

/**
 * @returns {[FetchSessionData,boolean,boolean, boolean, () => void, boolean]} - Session component.
 */
export const useSession = (): [
  FetchSessionData | null,
  boolean,
  boolean,
  boolean,
  () => void,
  boolean,
] => {
  const [isFetchSession, setFetchSession] = useState(true);
  const [isError, setError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [user, setUser] = useState<FetchSessionData | null>(null);

  const [, loadingSessionData, { refetch }] = useFetchAction(fetchSession, [], {
    onCompleted(data) {
      setFetchSession(false);
      setError(false);
      setIsAuthenticated(true);
      if (data) setUser(data);
    },
    onError() {
      setFetchSession(false);
      setError(true);
      setIsAuthenticated(false);
    },
  });

  useSubscription(authenticated, (data) => {
    setIsAuthenticated(false);
    if (data) {
      setFetchSession(true);
      refetch();
    }
  });
  useSubscription(UpdateUser, (data) => {
    if (data) {
      setUser((currentState) => {
        if (currentState) {
          const newState: FetchSessionData = {
            ...currentState,
            user: {
              ...currentState.user,
              ...data,
            },
          };
          return newState;
        }
        return currentState;
      });
    }
  });
  return [
    user,
    isError,
    isFetchSession,
    isAuthenticated,
    refetch,
    loadingSessionData,
  ];
};
