import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useEvent } from '@8baselabs/react-simple-state';
import { UserType, ClientUserType } from '@8baselabs/resident-io-shared';

import { BoldTitle } from './ui/texts/CommonTexts';
import { ButtonAction } from './ui/buttons/ButtonAction';
import { OnFetchSession } from '../../modules/session/session-events';
import { GUARANTOR_ROUTES, ROUTES, TENANT_ROUTES } from '../../routes/types';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: '95vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
/**
 * @param {Props}props - Props component.
 * @returns {JSX.Element} - Error view message component.
 */
export function ErrorView({ title }: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const session = useEvent(OnFetchSession);
  return (
    <Container classes={{ root: classes.loaderContainer }}>
      <BoldTitle>{title}</BoldTitle>
      <Box height={10} />
      <ButtonAction
        fullWidth={false}
        onClick={() => {
          if (session?.user.userType === UserType.RESIDENT) {
            history.push(TENANT_ROUTES.resident.path);
          } else if (
            session.user.userClientUserRelation?.items?.[0]?.role ===
              ClientUserType.PROPERTY_OWNER ||
            session.user.userClientUserRelation?.items?.[0]?.role ===
              ClientUserType.PROPERTY_MANAGER
          ) {
            history.push(ROUTES.dashboard.path);
          } else {
            history.push(GUARANTOR_ROUTES.invitation.path);
          }
        }}>
        Let&apos;s get out of here
      </ButtonAction>
    </Container>
  );
}

interface Props {
  title?: string;
}
ErrorView.defaultProps = {
  title: 'You do not have right access to this view!',
};
