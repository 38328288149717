import { Message } from '@8baselabs/resident-io-shared';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useMemo, useState } from 'react';
import sanitize from 'sanitize-html';
import {
  FileTypesEnum,
  PreviewFile,
} from '../../../shared/components/PreviewFile';
import { SubtitleMuted } from '../../../shared/components/ui/texts/CommonTexts';
import { BORDER_COLOR, MUTED_COLOR } from '../../../shared/theme';
import SendIcon from '@material-ui/icons/Send';
import TextField from '@mui/material/TextField';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { readMessages, replyMessage } from '../inbox-actions';
import { useEvent, useSubscription } from '@8baselabs/react-simple-state';
import { OnCreateMessages } from '../inbox-event';
// import { apolloClient } from '../../../shared/apollo';
import { GET_MESSAGES_BY_THREAD_ID } from '../inbox-queries';
import { useQuery } from '@apollo/client';
import { OnSelectProperties } from '../../settings-view/settings-events';
import { useLocalStorage } from '../../../shared/components/components-hooks';
import { Tag } from '../../../shared/components/ui/Tag';
import { getPropertyImage } from '../../settings-view/settings-utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      border: `1px solid ${BORDER_COLOR}`,
      borderRadius: '0',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      padding: '20px 15px',
      margin: '0',
      backgroundColor: '#ecf1ff',
    },
    headerNameAndLocation: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonContainer: {
      marginLeft: '10px',
      justifyContent: 'flex-end',
    },
    buttonActive: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    textFieldContainer: {
      display: 'flex',
      flexGrow: 1,
      borderWidth: '0px',
    },
    textField: {
      '& fieldset': {
        borderRadius: '4px',
        borderWidth: '0px',
        fontSize: '12px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '0',
      },
    },
    divider: {
      margin: '30px 0',
    },
    container: {
      padding: 20,
      borderRadius: '0',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      border: `1px solid ${BORDER_COLOR}`,
    },
    contentContainer: {
      minHeight: 120,
      backgroundColor: alpha(MUTED_COLOR, 0.1),
    },
    messageContainer: {
      margin: '15px 0px',
      padding: '20px 15px',
      borderBottom: `1px solid ${BORDER_COLOR}`,
    },
    footerContainer: {
      alignItems: 'end',
      padding: '20px',
      backgroundColor: '#ecf1ff',
    },
  }),
);

interface Props {
  messagesFromList: Message[];
  isPM?: boolean;
  fromPeople?: boolean;
}

export enum MessageVia {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export const MessageContent: React.FC<Props> = ({
  messagesFromList,
  isPM = true,
  fromPeople = false,
}) => {
  const classes = useStyles();
  const selectedProperty = useEvent(OnSelectProperties);
  const [selectedPropertyId] = useLocalStorage('selected-property-id', '');
  const [messages, setMessages] = useState<Message[]>(messagesFromList);
  const [messageVia, setMessageVia] = useState<string | undefined>(
    messages ? messages[0].type : MessageVia.EMAIL,
  );
  const [content, setContent] = useState<string>('');
  const [loadingWhenSending, setLoadingWhenSending] = useState<boolean>(false);
  const recipientName: string =
    messages && isPM && !fromPeople
      ? selectedProperty.some(
          (prop) => messages[0].fromProperty?.email === prop.email,
        )
        ? (messages[0].to?.firstName || '') +
          ' ' +
          (messages[0].to?.lastName || '')
        : (messages[0].from?.firstName || '') +
          ' ' +
          (messages[0].from?.lastName || '')
      : messages && !isPM
      ? selectedPropertyId === messages[0].fromProperty?.id
        ? messages[0].fromProperty?.name || ''
        : messages[0].toProperty?.name || ''
      : messages && isPM && fromPeople
      ? messages[0].fromProperty?.name || ''
      : '';

  const messageFromProperty = useMemo(() => {
    return isPM && !fromPeople
      ? selectedProperty.some(
          (prop) => prop.email === messages?.[0].fromProperty?.email,
        )
      : selectedPropertyId === messages?.[0].fromProperty?.id;
  }, [fromPeople, isPM, messages, selectedProperty, selectedPropertyId]);
  // const [, loading, { refetch }] = useFetchAction(
  //   getMessagesByThreadId,
  //   messagesFromList.length !== 0
  //     ? [messagesFromList[0].messageThread?.id as string]
  //     : [''],
  //   {
  //     onCompleted: (messagesResponse) => {
  //       if (messagesResponse) {
  //         setMessages(messagesResponse as Message[]);

  //         const unreadMessages = messagesResponse.filter(
  //           (message) => !message.isRead,
  //         );
  //         if (unreadMessages.length > 0) {
  //           readMessages(unreadMessages.map((message) => message.id || ''));
  //         }
  //       }
  //       setLoadingWhenSending(false);
  //     },
  //   },
  // );

  const { loading, refetch } = useQuery(GET_MESSAGES_BY_THREAD_ID, {
    variables: {
      filter: {
        messageThread: {
          id: {
            equals:
              messagesFromList.length !== 0
                ? (messagesFromList[0].messageThread?.id as string)
                : '',
          },
        },
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      const messagesResponse = response.messagesList.items;
      if (messagesResponse) {
        setMessages(messagesResponse as Message[]);

        const unreadMessages = messagesResponse.filter(
          (message) => !message.isRead,
        );
        if (unreadMessages.length > 0) {
          readMessages(unreadMessages.map((message) => message.id || ''));
        }
      }
      setLoadingWhenSending(false);
    },
  });

  useSubscription(OnCreateMessages, async () => {
    // await apolloClient.cache.reset();
    refetch();
  });

  if (loading)
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <>
      {messages ? (
        <>
          <Card className={classes.headerContainer}>
            <Grid item container>
              <Grid item sm={1} md={1} lg={1} xl={1}>
                <Avatar
                  alt={
                    messageFromProperty && isPM
                      ? messages[0].to?.email
                      : messageFromProperty && !isPM
                      ? messages[0].to?.email
                      : messages[0].fromProperty?.email
                  }
                  src={
                    messageFromProperty && isPM && !fromPeople
                      ? messages[0].to?.avatar?.downloadUrl
                      : getPropertyImage(messages[0].fromProperty)
                  }
                />
              </Grid>
              <Grid
                item
                sm={11}
                md={11}
                lg={11}
                xl={11}
                className={classes.headerNameAndLocation}>
                <Grid item>
                  <Typography variant="body1">{recipientName}</Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {moment(messages[0].createdAt?.split('T')[0]).format(
                      'MMM DD',
                    )}
                    , {messages[0].createdAt?.split('T')[1].split(':')[0]}:
                    {messages[0].createdAt?.split('T')[1].split(':')[1]}
                  </Typography>
                </Grid>
                <Grid item>
                  {
                    <SubtitleMuted>
                      {messageFromProperty && isPM
                        ? messages[0].to?.address
                        : `${messages[0].fromProperty?.state} ${messages[0].fromProperty?.street}`}
                    </SubtitleMuted>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Card className={classes.container}>
            {messages.map((message) => {
              const propertyMessage: boolean =
                isPM && !fromPeople
                  ? selectedProperty.some(
                      (prop) => prop.email === message.fromProperty?.email,
                    )
                  : selectedPropertyId === message.fromProperty?.id;

              return (
                <Grid
                  container
                  className={classes.messageContainer}
                  key={message.id}>
                  <Grid container spacing={4}>
                    <Grid item sm={1} md={1} lg={1} xl={1}>
                      <Avatar
                        alt={
                          propertyMessage
                            ? message.fromProperty?.email
                            : message.toProperty?.email
                        }
                        src={
                          propertyMessage
                            ? getPropertyImage(message?.fromProperty)
                            : message.from?.avatar?.downloadUrl
                        }
                      />
                    </Grid>
                    <Grid item sm={11} md={11} lg={11} xl={11}>
                      <Grid item>
                        <Typography variant="body1">
                          {propertyMessage
                            ? `${message.fromProperty?.name}`
                            : `${message.from?.firstName} ${message.from?.lastName}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {propertyMessage
                            ? message.fromProperty?.email
                            : message.from?.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid container justifyContent="space-between">
                    <Typography variant="body1">
                      {message.messageThread?.subject}
                    </Typography>
                    <Grid>
                      <Typography variant="subtitle2" color="textSecondary">
                        {moment(message.createdAt?.split('T')[0]).format(
                          'MMM DD',
                        )}
                        , {message.createdAt?.split('T')[1].split(':')[0]}:
                        {message.createdAt?.split('T')[1].split(':')[1]}
                      </Typography>
                      <Box display="flex" style={{ justifyContent: 'center' }}>
                        {message.type === MessageVia.EMAIL ? (
                          <Tag
                            label={MessageVia.EMAIL}
                            backgroundColor="#4D7CFE"
                            color="primary"
                            style={{
                              fontSize: '12px',
                            }}
                          />
                        ) : (
                          <Tag
                            label={MessageVia.SMS}
                            backgroundColor="#FE4D97"
                            color="secondary"
                            style={{
                              fontSize: '12px',
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.contentContainer}>
                    <div
                      style={{
                        maxWidth: 300,
                      }}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitize(message.content || ''),
                      }}
                    />
                    <Grid container item spacing={3}>
                      {message.messageAttachmentMessageRelation?.items.map(
                        ({ file, id }) => {
                          let fileType: FileTypesEnum = FileTypesEnum.OTHER;

                          if (file?.filename) {
                            const auxLegnth =
                              file?.filename?.split('.').length || 0;
                            const auxType =
                              file?.filename?.split('.')[auxLegnth - 1];

                            if (auxType === 'pdf') {
                              fileType = FileTypesEnum.PDF;
                            } else if (
                              auxType === 'jpg' ||
                              auxType === 'jpeg' ||
                              auxType === 'png'
                            ) {
                              fileType = FileTypesEnum.IMAGE;
                            }
                          }

                          return (
                            <Grid item key={id}>
                              <PreviewFile
                                fileURL={file?.downloadUrl || ''}
                                fileName={file?.filename || 'No file name'}
                                fileType={fileType}
                              />
                            </Grid>
                          );
                        },
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container xs={12} className={classes.footerContainer}>
              <Grid item xs={9} className={classes.textFieldContainer}>
                <TextField
                  value={content}
                  minRows={3}
                  multiline
                  onChange={(event) => setContent(event.target.value)}
                  placeholder={['Message', recipientName].join(' ')}
                  className={classes.textField}
                  inputProps={{
                    style: {
                      padding: '8px 14px',
                      fontSize: '14px',
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                style={{ display: 'flex', justifyContent: 'center' }}
                xs={3}>
                {isPM && (
                  <Grid item className={classes.buttonContainer}>
                    <Button
                      onClick={() =>
                        setMessageVia('EMAIL' as unknown as MessageVia)
                      }
                      variant="outlined"
                      color="primary"
                      className={
                        messageVia === ('EMAIL' as unknown as MessageVia)
                          ? classes.buttonActive
                          : ''
                      }>
                      EMAIL
                    </Button>
                  </Grid>
                )}
                {isPM && (
                  <Grid item className={classes.buttonContainer}>
                    <Button
                      onClick={() =>
                        setMessageVia('SMS' as unknown as MessageVia)
                      }
                      variant="outlined"
                      color="primary"
                      className={
                        messageVia === ('SMS' as unknown as MessageVia)
                          ? classes.buttonActive
                          : ''
                      }>
                      SMS
                    </Button>
                  </Grid>
                )}
                <Grid item className={classes.buttonContainer}>
                  <ButtonAction
                    variant={isPM ? 'text' : 'contained'}
                    disabled={content.length === 0}
                    onClick={() => {
                      setLoadingWhenSending(true);
                      replyMessage({
                        content: content,
                        isPM: isPM,
                        fromProperty: messageFromProperty && !fromPeople,
                        id:
                          messageFromProperty && isPM && !fromPeople
                            ? (selectedProperty.find(
                                (prop) =>
                                  messages?.[0].fromProperty?.email ===
                                  prop.email,
                              )?.id as string)
                            : messageFromProperty && isPM && fromPeople
                            ? (messages[0].to?.id as string)
                            : (messages[0].to?.id as string),
                        to:
                          messageFromProperty && isPM && !fromPeople
                            ? (messages[0].to?.id as string)
                            : messageFromProperty && isPM && fromPeople
                            ? selectedPropertyId
                            : (messages[0].fromProperty?.id as string),
                        type: messageVia as MessageVia,
                        attachments: [],
                        messageThreadId: messages[0].messageThread
                          ?.id as string,
                      });
                      setContent('');
                    }}
                    isLoading={loading || loadingWhenSending}>
                    {isPM ? <SendIcon /> : 'REPLY'}
                  </ButtonAction>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        ''
      )}
    </>
  );
};
