import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CreateOutlined, MoreVertOutlined } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import {
  GridCellParams,
  GridColDef,
  GridRowData,
  GridToolbarContainer,
} from '@material-ui/data-grid';
import { Box, ListItemIcon, Switch } from '@material-ui/core';
import {
  useCallAction,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import { Lease, AutoPay } from '@8baselabs/resident-io-shared';
import { AddCircleButton } from '../../../shared/components/ui/buttons/AddCircleButton';
import { formatCurrency, rootStyleTable } from '../../../shared/utils';
import { AutoPayCreateUpdateDialog } from '../components/AutoPayCreateUpdateDialog';
import { MainLoader } from '../../../shared/components/MainLoader';
import { getAutoPays, updateAutoPayAction } from '../people-actions';
import { createAutoPayEvent, updateAutoPayEvent } from '../people-event';
import moment from 'moment';
import { CustomDataGrid } from '../../../shared/components/CustomDataGrid';

const useStyles = makeStyles(() =>
  createStyles({
    dataGrid: {
      minHeight: '900px',
    },
    row: {
      minHeight: 'fit-content !important',
    },
    cell: {
      minHeight: 'auto !important',
    },
    root: rootStyleTable,
  }),
);

const ActionsButton: React.FC<{
  params: GridCellParams;
  setAutoPayId: (autoPayId: string) => void;
  openAddEdit: () => void;
}> = ({ params, setAutoPayId, openAddEdit }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disableFocusRipple
        disableRipple
        aria-controls="delete-subperiod-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="autopay-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            openAddEdit();
            setAutoPayId(params.row.id);
          }}>
          <ListItemIcon>
            <CreateOutlined fontSize="small" />
          </ListItemIcon>
          <Box>Edit</Box>
        </MenuItem>
      </Menu>
    </>
  );
};

/**
 * @param {Function} handleOpenAdd - Action button.
 * @returns - Custom Toolbar for Bulk actions.
 */
const Toolbar: React.FC<{
  handleOpenAddEdit: () => void;
}> = ({ handleOpenAddEdit }) => {
  return (
    <GridToolbarContainer>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Box>
          <AddCircleButton onClick={handleOpenAddEdit}>
            Add Entry
          </AddCircleButton>
        </Box>
      </Box>
    </GridToolbarContainer>
  );
};

export const AutoPayView: React.FC<{ selectedLease?: Lease }> = ({
  selectedLease,
}) => {
  const [isOpenAddEdit, setIsOpenAddEdit] = useState<boolean>(false);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [autoPayId, setAutoPayId] = useState('');
  const [autoPayState, setAutoPays] = useState<AutoPay[]>([]);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const [autoPays, , { error, refetch }] = useFetchAction(
    getAutoPays,
    [selectedLease?.id || ''],
    {
      skip: !selectedLease?.id,
      onCompleted: (response) => {
        setAutoPays(response as AutoPay[]);
        if (response) {
          setRows(
            response?.map((autoPay) => {
              return {
                id: autoPay.id,
                createdAt: moment(autoPay.createdAt).format('MM/DD/YYYY'),
                paymentMethodId: autoPay.paymentMethodId,
                begins: moment(autoPay.begins).format('MM/DD/YYYY'),
                expires: moment(autoPay.expires).format('MM/DD/YYYY'),
                payInFull: autoPay.payInFull,
                amount: autoPay.amount,
                chargeOn: autoPay.chargeOn,
                lastRun: autoPay.lastRun
                  ? Intl.DateTimeFormat('en-US').format(
                      new Date(autoPay.lastRun),
                    )
                  : '-',
                status: autoPay.status,
              };
            }),
          );
          setLoading(false);
        }
      },
    },
  );

  const [editAutoPayCall] = useCallAction(updateAutoPayAction, {});

  useEffect(() => {
    setLoading(true);
    refetch();
  }, [selectedLease, refetch]);

  const handleCloseAddEdit = (): void => {
    setIsOpenAddEdit(false);
    setAutoPayId('');
  };

  useSubscription(createAutoPayEvent, () => {
    handleCloseAddEdit();
    setLoading(true);
    refetch();
  });

  useSubscription(updateAutoPayEvent, () => {
    handleCloseAddEdit();
    setLoading(true);
    refetch();
  });

  const handleOpenAddEdit = (): void => {
    setIsOpenAddEdit(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 200,
    },
    {
      field: 'createdAt',
      headerName: 'CREATED AT',
      minWidth: 160,
    },
    {
      field: 'begins',
      headerName: 'BEGINS',
      minWidth: 160,
    },
    {
      field: 'expires',
      headerName: 'EXPIRES',
      minWidth: 160,
    },
    {
      field: 'payInFull',
      headerName: 'PAY IN FULL?',
      renderCell: (params) => (params.row.payInFull ? 'Yes' : 'No'),
      minWidth: 160,
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      renderCell: (params) => formatCurrency(params.row.amount),
      minWidth: 140,
    },
    {
      field: 'chargeOn',
      headerName: 'CHARGE ON',
      minWidth: 160,
    },
    {
      field: 'lastRun',
      headerName: 'LAST RUN',
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      renderCell: (params) => (
        <Switch
          checked={params.row.status}
          disabled={params.row.loading}
          onChange={() => {
            const autopayUpdateData: {
              id: string;
              status: boolean;
              lastActivation?: string;
            } = {
              id: params.row.id,
              status: !params.row.status,
            };
            if (!params.row.status) {
              autopayUpdateData.lastActivation = `${moment().format(
                'YYYY-MM-DD',
              )}`;
            }
            editAutoPayCall(autopayUpdateData);
            const currentRowIndex = rows.findIndex(
              (row) => row.id === params.row.id,
            );
            const auxRows = [...rows];
            auxRows[currentRowIndex].status = !auxRows[currentRowIndex].status;
            auxRows[currentRowIndex].loading = false;

            setRows(auxRows);
          }}></Switch>
      ),
      minWidth: 140,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      headerAlign: 'center',
      align: 'center',
      minWidth: 110,
      flex: 1,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideSortIcons: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <ActionsButton
          openAddEdit={handleOpenAddEdit}
          setAutoPayId={setAutoPayId}
          params={params}
        />
      ),
    },
  ];

  if (!!selectedLease && loading && !autoPays)
    return <MainLoader title="Loading..." />;

  const autoPay = autoPayState?.find((item) => item.id === autoPayId);

  const count = rows?.length || 0;
  const rowsPerPageOptions =
    //count > 100 ? [5, 10, 20, 50, 100, count] :
    [5, 10, 20, 50, 100];

  return (
    <Box padding="20px">
      <CustomDataGrid
        className={classes.dataGrid}
        classes={{
          cell: classes.cell,
          row: classes.row,
          root: classes.root,
        }}
        components={{
          Toolbar: () => <Toolbar handleOpenAddEdit={handleOpenAddEdit} />,
        }}
        onPageChange={(data) => {
          setPage(data);
        }}
        onPageSizeChange={(data) => {
          setPage(0);
          setRowPerPage(data);
        }}
        pageSize={rowPerPage}
        rows={rows}
        page={page}
        columns={columns}
        error={error}
        loading={loading}
        rowCount={rows.length}
        checkboxSelection
        rowsPerPageOptions={rowsPerPageOptions}
        hideFooterPagination={!count}
        autoHeight
        pagination
        paginationMode="server"
      />
      {isOpenAddEdit && (
        <AutoPayCreateUpdateDialog
          isOpen={isOpenAddEdit}
          lease={selectedLease}
          leaseSubPeriod={autoPay?.leaseSubPeriod}
          begins={autoPay?.begins}
          expires={autoPay?.expires}
          payInFull={autoPay?.payInFull}
          amount={autoPay?.amount}
          chargeOn={autoPay?.chargeOn}
          status={autoPay?.status}
          id={autoPay?.id}
          onClose={handleCloseAddEdit}
        />
      )}
    </Box>
  );
};
