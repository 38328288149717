import React from 'react';
import ReactDOM from 'react-dom';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from './App';
import { reportWebVitals } from './reportWebVitals';
import './shared/css/index.css';
import { ROOM_CHOICE_THEME } from './shared/theme';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_TRACE_SAMPLE_RATE } from './shared/constants';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: SENTRY_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(SENTRY_TRACE_SAMPLE_RATE),
});

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={ROOM_CHOICE_THEME}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
