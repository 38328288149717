import {
  Card,
  CardActionArea,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: 70,
      width: 150,
      maxWidth: 150,
      padding: 15,
    },
    actionContainer: {
      height: 1,
    },
  }),
);

export enum FileTypesEnum {
  IMAGE = 'image',
  PDF = 'pdf',
  OTHER = 'other',
}

export const PreviewFile: React.FC<{
  fileType: FileTypesEnum;
  fileName: string;
  fileURL: string;
  onDelete?: () => void;
}> = ({ fileType, fileName, onDelete, fileURL }) => {
  const classes = useStyles();
  const [fileNameShort, setFileNameShort] = useState('');

  useEffect(() => {
    const fileNameSplited = fileName?.split('') || [];

    if (fileNameSplited?.length > 12) {
      setFileNameShort(`${fileNameSplited?.slice(0, 12).join('')}...`);
    } else {
      setFileNameShort(fileNameSplited?.join(''));
    }
  }, [fileName]);

  return (
    <Card>
      <CardActionArea onClick={() => window.open(fileURL)}>
        {Boolean(onDelete) && (
          <Grid
            container
            justifyContent="flex-end"
            className={classes.actionContainer}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                if (!onDelete) return;
                onDelete();
              }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
        <Grid
          className={classes.container}
          container
          justifyContent="space-between"
          alignItems="center">
          <Grid container alignItems="center">
            {fileType === FileTypesEnum.OTHER && (
              <AttachmentIcon color="disabled" />
            )}
            {fileType === FileTypesEnum.IMAGE && <ImageIcon color="disabled" />}

            {fileType === FileTypesEnum.PDF && (
              <PictureAsPdfIcon color="disabled" />
            )}
          </Grid>
          <Grid container alignItems="center">
            {fileNameShort}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};
