import React, { useEffect, useState, useMemo } from 'react';
import { useEvent, useFetchAction } from '@8baselabs/react-simple-state';
import { useHistory, useLocation } from 'react-router-dom';
import { PropertyFilter } from '@8baselabs/resident-io-shared';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { ResidentItem } from './components/ResidentItem';
import { fetchPropertiesByFilter } from '../properties/properties-actions';
import { ROUTES } from '../../routes/types';
import { getPropertyImage } from '../settings-view/settings-utils';
import { getLeasesUsers } from './search-actions';
import { getTenantImage } from './searchUtils';
import { ERROR_COLOR, SUCCESS_COLOR, WARNING_COLOR } from '../../shared/theme';
import { useLocalStorage } from '../../shared/components/components-hooks';
import { LeaseStatusValues } from '../opportunities/opportunities-types';
import { OnChangeClientSelected } from '../settings-view/settings-events';
import { useDebounce } from 'use-debounce';
import { useSession } from '../session/session-hooks';

const ITEM_HEIGHT = 60;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 200,
      },
      [theme.breakpoints.up('lg')]: {
        width: 450,
      },
      zIndex: theme.zIndex.modal,
    },
    paper: {
      maxHeight: ITEM_HEIGHT * 4.5,
      overflowY: 'auto',
    },
  }),
);

type SearchProps = {
  anchorRef: React.RefObject<HTMLInputElement>;
  term: string;
};

/**
 * @param root0 - Search component props.
 * @param root0.anchorRef - Element ref to the origin search input.
 * @param root0.term - Term to search.
 * @returns {React.FC} - Component to search for leases.
 */
export const Search: React.FC<SearchProps> = ({ anchorRef, term }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const selectedClientId = useEvent(OnChangeClientSelected);

  const [session] = useSession();

  useEffect(() => {
    const userType = session?.user?.userRole?.includes('MANAGER')
      ? 'MANAGER'
      : 'OWNER';

    if (!selectedClientId)
      OnChangeClientSelected.dispatch(
        session?.user?.userClientUserRelation?.items.find((clientUser) =>
          clientUser.role?.includes(userType),
        )?.client?.id || '',
      );
  }, [selectedClientId, session]);

  const [, setLocalStoreSelectedLeaseId] = useLocalStorage(
    'selected-lease-id',
    '',
  );

  const [debouncedTerm] = useDebounce(term, 500);

  const filter: PropertyFilter = useMemo(
    () => ({
      name: { contains: debouncedTerm },
      client: { id: { equals: selectedClientId || '' } },
    }),
    [selectedClientId, debouncedTerm],
  );
  const [properties] = useFetchAction(fetchPropertiesByFilter, [filter], {
    skip: !debouncedTerm,
  });
  const [leases] = useFetchAction(
    getLeasesUsers,
    [selectedClientId, debouncedTerm],
    {
      skip: !debouncedTerm,
    },
  );

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    setOpen(Boolean(debouncedTerm));
  }, [debouncedTerm]);

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'right top' : 'right bottom',
          }}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {leases?.items.map((lease) => (
                  <ResidentItem
                    onClick={() => {
                      setLocalStoreSelectedLeaseId(lease?.id);
                      history.push(
                        `/people/${lease?.userResident?.id}/profile`,
                      );
                      setOpen(false);
                    }}
                    src={getTenantImage(lease.userResident)}
                    name={
                      (lease?.userResident?.firstName || '') +
                      ' ' +
                      (lease?.userResident?.lastName || '')
                    }
                    status={
                      lease.status === LeaseStatusValues['MOVE-OUT'].key
                        ? 'Ended'
                        : lease.status === LeaseStatusValues.APPROVED.key ||
                          lease.status === LeaseStatusValues['MOVE-IN'].key
                        ? 'Active'
                        : 'Pending'
                    }
                    statusBackground={
                      lease.status === LeaseStatusValues['MOVE-OUT'].key
                        ? ERROR_COLOR
                        : lease.status === LeaseStatusValues.APPROVED.key ||
                          lease.status === LeaseStatusValues['MOVE-IN'].key
                        ? SUCCESS_COLOR
                        : WARNING_COLOR
                    }
                    email={lease?.userResident?.email}
                    phoneNumber={lease?.userResident?.phoneNumber}
                    leasePeriod={lease?.leasePeriod}
                  />
                ))}
                {properties?.map((property) => (
                  <ResidentItem
                    onClick={() => {
                      localStorage.setItem(
                        'selected-property-id',
                        JSON.stringify(property.id),
                      );
                      if (location.pathname !== ROUTES.settings.path) {
                        history.push(ROUTES.settings.path);
                      } else {
                        window.location.reload();
                      }
                      setOpen(false);
                    }}
                    src={getPropertyImage(property)}
                    name={property.name ?? ''}
                    description={property.location || 'No address'}
                  />
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
