import { createEvent } from '@8baselabs/react-simple-state';
import {
  Bed,
  Building,
  Floor,
  Lease_InvitationResidentCreateInput,
  Lease,
  LeasePeriod,
  Property,
  ResidentApplication,
  Room,
  Unit,
  User,
  OptionalPreferenceListResponse,
  LeaseListResponse,
  OpportunityNotification,
  MessageTemplate,
  InvitationResident,
  PaymentPlan,
  UserUpdateInput,
  GuarantorUpdateInput,
  ContractDocument,
  ClientUser,
  LeaseManyResponse,
  SendLeadInvitationResult,
  PaymentPlanItem,
  Form,
  RequiredFile,
  SuccessResponse,
  TermsDocument,
  InvitationResidentListResponse,
} from '../../schema-types';
import { ValidationError } from 'yup';

export const OpportunitiesLeasesEvent = createEvent<Lease[]>();
export const OpportunitiesLeasesEventError = createEvent<Error>();

export const OpportunitiesFetchLeasesEvent = createEvent<Lease[]>();
export const OpportunitiesFetchLeasesEventError = createEvent<Error>();

export const OnGetOpportunitiesMetrics = createEvent<{
  total: number;
  open_invitation: number;
  in_process: number;
  manager_ready: number;
  new: number;
  blocked?: number;
  beds?: number;
  approved: number;
  all: number;
}>();
export const OnGetOpportunitiesMetricsError = createEvent<Error>();

export const OnFetchOptionalPreferenceListEvent =
  createEvent<OptionalPreferenceListResponse>();
export const OnFetchOptionalPreferenceListEventError = createEvent<Error>();
export const AddReservationResidentsEvent = createEvent<User[]>();
export const AddReservationResidentsEventError = createEvent<Error>();

export const OnGetOpportunitiesNotifications = createEvent<
  readonly OpportunityNotification[] | undefined
>();
export const OnGetOpportunitiesNotificationsError = createEvent<Error>();

export const OnCreateOpportunityNotification = createEvent<
  OpportunityNotification | undefined
>();
export const OnCreateOpportunityNotificationError = createEvent<Error>();

export const OnGetMessageTemplates = createEvent<
  MessageTemplate[] | undefined
>();
export const OnGetMessageTemplatesError = createEvent<Error>();

export const OnGetPaymentPlanItemsByLease = createEvent<PaymentPlanItem[]>();
export const OnGetPaymentPlanItemsByLeaseError = createEvent<Error>();

export const OnDeleteOpportunityNotification = createEvent<string>();
export const OnDeleteOpportunityNotificationError = createEvent<Error>();

export const OnUpdateOpportunityNotification = createEvent<
  OpportunityNotification | undefined
>();
export const OnUpdateOpportunityNotificationError = createEvent<Error>();

export const OpportunitiesLeaseDataEvent = createEvent<Lease | undefined>();
export const OpportunitiesLeaseDataEventError = createEvent<Error>();

export const OpportunitiesLeasesDataEvent = createEvent<
  readonly Lease[] | undefined
>();
export const OpportunitiesLeasesDataEventError = createEvent<Error[]>();

export const SendRemindersEvent = createEvent<void>();
export const SendRemindersEventError = createEvent<Error>();

export const AddReservationBuildingsEvent = createEvent<Building[]>();
export const AddReservationBuildingsEventError = createEvent<Error>();

export const AddReservationFloorsEvent = createEvent<Floor[]>();
export const AddReservationFloorsEventError = createEvent<Error>();

export const AddReservationUnitsEvent = createEvent<Unit[]>();
export const AddReservationUnitsEventError = createEvent<Error>();

export const AddReservationUnitsByGenderEvent = createEvent<Unit[]>();
export const AddReservationUnitsByGenderEventError = createEvent<Error>();

export const AddReservationRoomsEvent = createEvent<Room[]>();
export const AddReservationRoomsEventError = createEvent<Error>();

export const AddReservationBedsEvent = createEvent<Bed[]>();
export const AddReservationBedsEventError = createEvent<Error>();

export const AddReservationPaymentPlansEvent = createEvent<PaymentPlan[]>();
export const AddReservationPaymentPlansEventError = createEvent<Error>();

export const AddReservationExistentResidentEvent = createEvent<boolean>({
  initialValue: true,
});

export const AddReservationNewResidentEvent =
  createEvent<Lease_InvitationResidentCreateInput>({
    initialValue: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });
export const AddReservationNewResidentValidationEvent =
  createEvent<ValidationError>();
export const AddReservationSelectResidentEvent = createEvent<User>();
export const AddReservationSelectResidentEventError = createEvent<Error>();

export const AddReservationSelectPropertyEvent = createEvent<Property>();
export const AddReservationSelectLeasePeriodEvent = createEvent<LeasePeriod>();
export const AddReservationSelectCustomPeriodEvent = createEvent<{
  startDate: string;
  endDate: string;
}>();

export const AddReservationSelectBuildingEvent = createEvent<Building>();
export const AddReservationSelectFloorEvent = createEvent<Floor>();
export const AddReservationSelectUnitEvent = createEvent<Unit>();
export const AddReservationSelectRoomEvent = createEvent<Room>();
export const AddReservationSelectBedEvent = createEvent<Bed>();
export const FormHasContent = createEvent<boolean>({ initialValue: true });
export const SkippedTenantApplication = createEvent<boolean>({
  initialValue: false,
});

export const LeasePeriodBedsAvailableEvent = createEvent<{
  maleCount: number;
  femaleCount: number;
  leasePeriod: string;
}>();
export const LeasePeriodBedsAvailableError = createEvent<Error>();

export const AddReservationSelectPaymentPlanEvent = createEvent<PaymentPlan>();

export const AddReservationSelectResidentApplicationEvent = createEvent<{
  sections: Form;
  application: ResidentApplication;
  user: UserUpdateInput;
  guarantor: GuarantorUpdateInput;
  fieldsForPartialValidation?;
  fields;
}>({
  initialValue: {
    sections: {},
    application: {},
    user: {},
    guarantor: {},
    fields: [],
    fieldsForPartialValidation: [],
  },
});

export const AddReservationFetchLeaseEvent = createEvent<Lease | null>();
export const AddReservationFetchLeaseEventError = createEvent<Error>();

export const AddReservationCreateEvent = createEvent<Lease | null>();
export const AddReservationCreateEventError = createEvent<
  Error | ValidationError
>();

export const AddReservationContractEvent = createEvent<ContractDocument[]>({
  initialValue: [],
});
export const AddReservationContractEventError = createEvent<Error>();

export const AddReservationTermsEvent = createEvent<TermsDocument[]>({
  initialValue: [],
});
export const AddReservationTermsEventError = createEvent<Error>();

export const AddReservationUpdateEvent = createEvent<Lease | null>();
export const AddReservationUpdateEventError = createEvent<Error>();

export const UpdateManyReservationEvent = createEvent<
  LeaseManyResponse | undefined
>();
export const UpdateManyReservationEventError = createEvent<Error>();

export const AddReservationUpdateResidentApplicationEvent =
  createEvent<Lease | null>();
export const AddReservationUpdateResidentApplicationEventError = createEvent<
  Error | ValidationError
>();

export const SaveResidentApplicationEvent = createEvent<Lease | null>();
export const SaveResidentApplicationEventError = createEvent<
  Error | ValidationError
>();

export const UpdateApplicationEvent = createEvent<Lease | null>();
export const UpdateApplicationEventError = createEvent<
  Error | ValidationError
>();

export const OnCreateResidentInvitation = createEvent<
  InvitationResident | undefined
>();
export const OnCreateResidentInvitationError = createEvent<Error>();

export const OnUpdateSectionPropertyForm = createEvent<Form | undefined>();
export const OnUpdateSectionPropertyFormError = createEvent<Error>();

export const createInvitationEvent = createEvent<InvitationResident>();
export const createInvitationEventError = createEvent<Error>();

export const getInvitations = createEvent<InvitationResidentListResponse>();
export const getInvitationsError = createEvent<Error>();

export const getLeaseList = createEvent<LeaseListResponse>();
export const getLeaseListError = createEvent<Error>();

export const getManagersEvent = createEvent<readonly ClientUser[] | null>();
export const getManagersEventError = createEvent<Error>();

export const sendLeadInvitationEvent = createEvent<SendLeadInvitationResult>();
export const sendLeadInvitationEventError = createEvent<Error>();

export const getRequiredDocumentsEvent = createEvent<DocumentType[]>();
export const getRequiredDocumentsEventError = createEvent<Error>();

export const createRequiredFileEvent = createEvent<RequiredFile>();
export const createRequiredFileEventError = createEvent<Error>();

export const updateRequiredFileEvent = createEvent<
  SuccessResponse | undefined
>();
export const updateRequiredFileEventError = createEvent<Error>();

export const getRequiredFilesEvent = createEvent<RequiredFile[]>();
export const getRequiredFilesEventError = createEvent<Error>();

export const deleteRequiredFilesEvent = createEvent<
  SuccessResponse | undefined
>();
export const deleteRequiredFilesEventError = createEvent<Error>();

export const requiredFilesEvent = createEvent<{ allFilesUploaded: boolean }>({
  initialValue: { allFilesUploaded: false },
});

export const onGetPropertyRequiredUploads = createEvent<
  DocumentType[] | undefined
>();
export const onGetPropertyRequiredUploadsError = createEvent<Error>();

export const copyPaymentPlanEvent = createEvent<SuccessResponse | undefined>();
export const copyPaymentPlanError = createEvent<Error>();

export const createResidentApprovalInvoiceEvent = createEvent<boolean>();
export const createResidentApprovalInvoiceError = createEvent<Error>();

export const createPropertyApprovalInvoiceEvent = createEvent<boolean>();
export const createPropertyApprovalInvoiceError = createEvent<Error>();

export const chargeApprovalEvent = createEvent<boolean>();
export const chargeApprovalError = createEvent<Error>();

export const onResidentFeeStatusEvent = createEvent<boolean>({
  initialValue: false,
});
export const onResidentFeeStatusError = createEvent<Error>();
