import {
  useCallAction,
  useEvent,
  useSubscription,
} from '@8baselabs/react-simple-state';
import {
  Lease,
  LeaseUpdateInput,
  PublicRoomateProfile,
  ResidentApplication,
  ResidentApplicationCreateInput,
  Form,
  GuarantorUpdateInput,
  UserUpdateInput,
} from '../../../schema-types';
import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { fetchUserActions, updateLeaseAction } from '../people-actions';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { updateLeaseError, updateLeaseEvent } from '../people-event';
import { AlertContext } from '../../../routes/AlertContext';
import {
  AddReservationFetchLeaseEvent,
  AddReservationSelectResidentApplicationEvent,
  AddReservationUpdateResidentApplicationEventError,
  FormHasContent,
} from '../../opportunities/opportunities-event';
import { updateResidentApplicationLeaseOpportunitiesAction } from '../../opportunities/opportunities-actions';
import { Application } from '../../opportunities/components/Application';
import { MainLoader } from '../../../shared/components/MainLoader';
import { useLazyQuery } from '@apollo/client';
import { GET_LEASE_BY_ID } from '../../opportunities/opportunities-queries';

const emptyResidentApplication: ResidentApplication = {
  convictedCrime: undefined,
  attendingCollege: '',
  schoolStartDate: undefined,
  smoke: undefined,
  bringingVehicle: undefined,
  shareRide: undefined,
};

const emptyRoomateProfile: PublicRoomateProfile = {
  displayName: '',
  school: '',
  age: undefined,
  smoking: undefined,
  hometown: '',
  allergies: '',
  aboutYourself: '',
};

export const AplicationView: React.FC<{ selectedLease?: Lease }> = ({
  selectedLease,
}) => {
  const params = useParams<{ id: string }>();
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors] = useState<
    Partial<{ residentApplication: ResidentApplicationCreateInput }>
  >({});
  const [updateLeaseCall, loading] = useCallAction(updateLeaseAction);
  const alert = useContext(AlertContext);

  const [fetchLease, { loading: loadingFetchLease }] = useLazyQuery(
    GET_LEASE_BY_ID,
    {
      onCompleted(data) {
        if (!data) return;
        AddReservationFetchLeaseEvent.dispatch(data?.lease);
      },
    },
  );

  useEffect(() => {
    if (selectedLease?.id) {
      fetchLease({ variables: { leaseId: selectedLease.id } });
    }
  }, [selectedLease?.id]); //eslint-disable-line react-hooks/exhaustive-deps

  const lease = useEvent(AddReservationFetchLeaseEvent);

  const {
    application,
    user: userUpdate,
    guarantor: guarantorCreate,
    fields,
  } = useEvent(AddReservationSelectResidentApplicationEvent);

  useEffect(() => {
    if (lease) {
      AddReservationSelectResidentApplicationEvent.dispatch({
        sections:
          (lease.leasePeriod?.propertyFormLeasePeriodRelation
            ?.items[0] as Form) || {},
        application: (lease.residentApplication as ResidentApplication) || {},
        user: (lease.userResident as UserUpdateInput) || {},
        guarantor: (lease.guarantor as GuarantorUpdateInput) || {},
        fields,
      });
    }
  }, [lease, fields]);

  const [
    updateResidentApplicationLease,
    loadingResidentApplicationLeaseUpdate,
  ] = useCallAction(updateResidentApplicationLeaseOpportunitiesAction);

  useSubscription(updateLeaseEvent, () => {
    alert({
      success: true,
      message: 'Application updated successfully',
    });
    fetchUserActions(params?.id);
  });

  useSubscription(updateLeaseError, () => {
    alert({
      error: true,
      message: 'Failed to update Application',
    });
  });

  const formik = useFormik({
    initialValues: {
      residentApplication:
        selectedLease?.residentApplication || emptyResidentApplication,
      roomateProfile:
        selectedLease?.publicRoomateProfile || emptyRoomateProfile,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const residentApplication: ResidentApplication = {};
      const publicRoomateProfile: PublicRoomateProfile = {};

      Object.keys(values.residentApplication).forEach((key) => {
        if (key === '__typename') return;
        if (key === 'id') return;
        const value =
          values.residentApplication[key as keyof ResidentApplication];
        if (value === '') return;
        residentApplication[key] = value;
        if (value === 'true') residentApplication[key] = true;
        if (value === 'false') residentApplication[key] = false;
      });

      Object.keys(values.roomateProfile).forEach((key) => {
        if (key === '__typename') return;
        if (key === 'id') return;
        const value = values.roomateProfile[key as keyof PublicRoomateProfile];
        if (value === '') return;
        publicRoomateProfile[key] = value;
        if (value === 'true') publicRoomateProfile[key] = true;
        if (value === 'false') publicRoomateProfile[key] = false;
      });

      let residentApplicationData;

      if (values.residentApplication?.id)
        residentApplicationData = {
          update: {
            ...residentApplication,
            schoolStartDate: residentApplication?.schoolStartDate
              ? moment(residentApplication?.schoolStartDate).format(
                  'YYYY-MM-DD',
                )
              : '',
          },
        };
      else
        residentApplicationData = {
          create: {
            ...residentApplication,
            schoolStartDate: residentApplication?.schoolStartDate
              ? moment(residentApplication?.schoolStartDate).format(
                  'YYYY-MM-DD',
                )
              : '',
          },
        };

      let publicRoomateProfileData;

      if (values.roomateProfile?.id)
        publicRoomateProfileData = {
          update: publicRoomateProfile,
        };
      else
        publicRoomateProfileData = {
          create: publicRoomateProfile,
        };

      const updateLeaseData: LeaseUpdateInput = {
        id: selectedLease?.id,
        residentApplication: residentApplicationData,
        publicRoomateProfile: publicRoomateProfileData,
      };

      if (!selectedLease?.id)
        return alert({
          error: true,
          message: 'You must select a Lease',
        });

      return updateLeaseCall(updateLeaseData);
    },
  });

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLease]);

  if (loadingFetchLease) {
    return (
      <Box>
        <MainLoader />
      </Box>
    );
  }

  return (
    <Box padding="40px">
      <Application formType="TENANT_ONLY" />
      {FormHasContent?.get() && (
        <Box mt="40px" display="flex" justifyContent="flex-end">
          <ButtonAction
            fullWidth={false}
            disabled={loading || loadingResidentApplicationLeaseUpdate}
            isLoading={loading || loadingResidentApplicationLeaseUpdate}
            onClick={() => {
              AddReservationUpdateResidentApplicationEventError.dispatch(null);
              if (selectedLease?.id)
                updateResidentApplicationLease(
                  selectedLease.id,
                  application,
                  {
                    id: userUpdate.id,
                    firstName: userUpdate.firstName,
                    middleName: userUpdate.middleName,
                    lastName: userUpdate.lastName,
                    preferredName: userUpdate.preferredName,
                    email: userUpdate.email || '',
                    birthdate: userUpdate.birthdate,
                    gender: userUpdate.gender,
                    genderIdentify: userUpdate.genderIdentify,
                    phoneNumber: userUpdate.phoneNumber,
                    homePhone: userUpdate.homePhone,
                    studentIdOrEmployeeNumber:
                      userUpdate.studentIdOrEmployeeNumber,
                    address: userUpdate.address,
                    city: userUpdate.city,
                    state: userUpdate.state,
                    zipCode: userUpdate.zipCode,
                    aboutMe: userUpdate.aboutMe,
                  },
                  guarantorCreate,
                  fields,
                  'both',
                );
            }}
            size="large">
            SAVE CHANGES
          </ButtonAction>
        </Box>
      )}
    </Box>
  );
};
