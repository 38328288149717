import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Message } from '@8baselabs/resident-io-shared';
import {
  Avatar,
  CardActionArea,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import sanitize from 'sanitize-html';
import { SubtitleMuted } from '../../../shared/components/ui/texts/CommonTexts';
import { BORDER_COLOR } from '../../../shared/theme';
import { useSession } from '../../session/session-hooks';
import { useMessages } from '../inbox-hooks';
import { useLocalStorage } from '../../../shared/components/components-hooks';
import { useEvent, useSubscription } from '@8baselabs/react-simple-state';
import { OnSelectProperties } from '../../settings-view/settings-events';
import { onSetSelectedThreadId } from '../inbox-event';
import { getPropertyImage } from '../../settings-view/settings-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      padding: '5px 10px',
    },
    card: {
      height: '120px',
      borderRadius: '8px',
      border: `1px solid ${BORDER_COLOR}`,
      marginBottom: '10px',
    },
    unReadCard: {
      height: '120px',
      borderRadius: '8px',
      border: `1px solid ${BORDER_COLOR}`,
      marginBottom: '10px',
      backgroundColor: theme.palette.grey[300],
    },
    selectedCard: {
      height: '120px',
      borderRadius: '8px',
      border: `1px solid ${BORDER_COLOR}`,
      marginBottom: '10px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    noThread: {
      height: '250px',
      border: `1px solid ${BORDER_COLOR}`,
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '290px',
    },
  }),
);

export enum MessageType {
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
}

export const ListedMessages: React.FC<{
  setSelectedThread?: Dispatch<SetStateAction<Message[] | undefined>>;
  leaseID?: string;
  isPM?: boolean;
  filterBy: {
    search: string;
    idFrom: string[];
    idTo: string[];
    conversation: boolean;
    switches?: {
      read: boolean;
    };
  };
  sortDesc: boolean;
  fromPeople?: boolean;
  isTenantPortal?: boolean;
}> = ({
  filterBy,
  sortDesc = true,
  isPM = true,
  setSelectedThread = () => {},
  leaseID,
  fromPeople = false,
  isTenantPortal,
}) => {
  const classes = useStyles();
  const [session] = useSession();
  const [selectedPropertyId] = useLocalStorage('selected-property-id', '');
  const { messagesThreads, loadingThreads } = useMessages(
    filterBy,
    session?.user?.selectedClient?.id ||
      session?.user?.userClientUserRelation?.items[0]?.client?.id ||
      '',
    isPM ? undefined : leaseID,
    fromPeople,
    isTenantPortal,
  );
  const selectedProperty = useEvent(OnSelectProperties);
  const [threads, setThreads] = useState<Message[]>();
  const threadEvent = useEvent(onSetSelectedThreadId);

  useEffect(() => {
    if (messagesThreads?.length === 0) {
      setSelectedThread([]);
      setThreads([]);
      return;
    } else {
      if (threadEvent?.selectedThreadId) {
        const selectedThread = messagesThreads?.filter(
          (a) => a.items[0].messageThread?.id === threadEvent?.selectedThreadId,
        );

        let selectedThreadMessage: Message[] = [];
        if (selectedThread?.length) {
          selectedThreadMessage = selectedThread[0].items as Message[];
          setSelectedThread(selectedThreadMessage);
        }
      }
      const threadsWithReadProperty = messagesThreads?.map((thread) => {
        // check if thread has at least one unread message
        if (thread.items.filter((message) => !message.isRead).length > 0) {
          return {
            ...thread.items[0],
            isRead: false,
          };
        }
        return {
          ...thread.items[0],
          isRead: true,
        };
      }) as Message[];

      setThreads(threadsWithReadProperty);
    }
  }, [messagesThreads, setSelectedThread, threadEvent]);

  const getThreads = useCallback(
    (switches: { read: boolean }): Message[] => {
      if (!threads) return [];
      switch (switches?.read) {
        case true:
          return threads;
        case false:
          return threads.filter((message) => message?.isRead === false);
        default:
          return threads;
      }
    },
    [threads],
  );

  useEffect(() => {
    if (getThreads(filterBy?.switches as { read: boolean }).length === 0) {
      setSelectedThread([]);
    }
  }, [filterBy?.switches, getThreads, setSelectedThread]);

  if (loadingThreads)
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <>
      {threads?.length &&
      getThreads(filterBy?.switches as { read: boolean }).length ? (
        getThreads((filterBy?.switches as { read: boolean }) ?? { read: true })
          .sort((a, b) => {
            if ((a.createdAt || '') > (b.createdAt || '')) {
              return -1;
            } else {
              return 1;
            }
          })
          .map((item) => {
            const contentSplited = item.content?.split('') || [];
            let shortMessage = '';
            if (contentSplited?.length > 35) {
              shortMessage = `${contentSplited?.slice(0, 35).join('')}`;
            } else {
              shortMessage = contentSplited?.join('');
            }

            const propertyMessage: boolean =
              isPM && !fromPeople
                ? selectedProperty?.some(
                    (prop) => prop.email === item.fromProperty?.email,
                  )
                : selectedPropertyId === item.fromProperty?.id;

            return (
              <CardActionArea
                key={item.id}
                className={
                  threadEvent?.selectedThreadId === item.messageThread?.id
                    ? classes.selectedCard
                    : item.isRead
                    ? classes.card
                    : classes.unReadCard
                }
                onClick={() => {
                  onSetSelectedThreadId.dispatch({
                    selectedThreadId: item?.messageThread?.id,
                  });
                }}>
                <Grid className={classes.tab}>
                  <Grid container justifyContent="space-between">
                    <Grid item style={{ display: 'flex' }}>
                      <Avatar
                        alt={
                          propertyMessage && isPM
                            ? item.fromProperty?.email
                            : propertyMessage && isPM
                            ? item.fromProperty?.email
                            : item.from?.email
                        }
                        src={
                          propertyMessage && isPM
                            ? getPropertyImage(item?.fromProperty)
                            : propertyMessage && !isPM
                            ? getPropertyImage(item?.fromProperty)
                            : item.from?.avatar?.downloadUrl
                        }
                      />
                      <Grid item style={{ paddingLeft: '10px' }}>
                        <Typography variant="body1">
                          {propertyMessage && isPM
                            ? `${item.fromProperty?.name}`
                            : propertyMessage && !isPM
                            ? `${item.fromProperty?.name}`
                            : `${item.from?.firstName} ${item.from?.lastName}`}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={
                            threadEvent?.selectedThreadId ===
                            item.messageThread?.id
                              ? 'textPrimary'
                              : 'textSecondary'
                          }
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '150px',
                          }}>
                          {item.messageThread?.subject}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      variant="subtitle2"
                      color={
                        threadEvent?.selectedThreadId === item.messageThread?.id
                          ? 'textPrimary'
                          : 'textSecondary'
                      }>
                      {moment(item.createdAt?.split('T')[0]).format('MMM DD')}
                      {/*  {moment(item.createdAt?.split('T')[0]).format('MMM DD')},{' '}
                    {item.createdAt?.split('T')[1].split(':')[0]}:
                    {item.createdAt?.split('T')[1].split(':')[1]} */}
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingTop: '5px' }}>
                    <div
                      style={{ overflow: 'hidden' }}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitize(shortMessage),
                      }}
                    />
                  </Grid>
                </Grid>
              </CardActionArea>
            );
          })
      ) : (
        <Grid container className={classes.noThread}>
          <Grid item direction="column">
            <Typography variant="body1">
              We couldn't find any results
            </Typography>
            <SubtitleMuted>
              Please try again with another search terms and/or filter
              combination
            </SubtitleMuted>
          </Grid>
        </Grid>
      )}
    </>
  );
};
