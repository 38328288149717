import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation UPDATE_USER($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
      avatar {
        downloadUrl
        fileId
        filename
      }
      email
      homePhone
      birthdate
      phoneNumber
      firstName
      middleName
      lastName
      preferredName
      gender
      genderIdentify
      lang
      timezone
      lastLogin
      aboutMe
    }
  }
`;
export const UPDATE_TENANT_PROFILE = gql`
  mutation UPDATE_TENANT_PROFILE($data: TenantProfileUpdateInput!) {
    tenantProfileUpdate(data: $data) {
      id
      sms
      email
      facebook
      twitter
      linkedin
      pinterest
      instagram
      whatspp
      wechat
      snaptchat
      linkedin
      pinterest
      iWillBeA
      shedule
      social
      hostAParty
      goingToBed
      studying
      gradually
      cleaning
      drinking
      proofIdentify {
        downloadUrl
        fileId
        filename
      }
    }
  }
`;
export const CREATE_TENANT_PROFILE = gql`
  mutation CREATE_TENANT_PROFILE($data: TenantProfileCreateInput!) {
    tenantProfileCreate(data: $data) {
      id
      facebook
      twitter
      linkedin
      pinterest
      iWillBeA
      shedule
      social
      hostAParty
      goingToBed
      studying
      gradually
      cleaning
      drinking
      proofIdentify {
        downloadUrl
        fileId
        filename
      }
    }
  }
`;
export const FETCH_UPLOAD_INFO = gql`
  query FETCH_UPLOAD_INFO {
    fileUploadInfo {
      policy
      apiKey
      signature
      path
    }
  }
`;

export const CHANGE_PASSWORD_RESOLVER = gql`
  mutation CHANGE_PASSWORD_RESOLVER($data: ChangeAuth0PasswordInput!) {
    changePasswordResolver(data: $data) {
      message
      validCurrentPassword
      success
    }
  }
`;

export const CHANGE_EMAIL_RESOLVER = gql`
  mutation CHANGE_EMAIL_RESOLVER($data: ChangeAuth0EmailInput!) {
    changeEmailResolver(data: $data) {
      message
      validCurrentEmail
      success
    }
  }
`;
