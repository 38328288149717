import React, { useContext, useState } from 'react';
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Box,
  Button,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import {
  CheckCreateInput,
  DefaultAccount,
  Lease,
} from '@8baselabs/resident-io-shared';
import { formatCurrency } from '../../../shared/utils';
import { makeStyles } from '@material-ui/core';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { getDefaultAccounts } from '../../owner-settings/owner-settings-actions';
import { useFetchAction, useSubscription } from '@8baselabs/react-simple-state';
import { createLedgerAction } from '../people-actions';
import { createCheckAction } from '../../checks/checks-actions';
import { useCallAction } from '@8baselabs/react-simple-state';
import { createLedgerEvent } from '../people-event';
import { AlertContext } from '../../../routes/AlertContext';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  content: {
    fontSize: '1rem',
    lineHeight: '22px',
    opacity: '.87',
  },
  gridItem: {
    paddingBottom: '1rem',
  },
  card: {
    boxShadow: '0 5px 21px 0 rgba(171,171,171,0.17)',
    borderRadius: '8px',
    marginTop: '-10px',
    padding: '10px 20px',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    marginBottom: '1rem',
    margin: '0 2rem',
  },
  totalAmount: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
  },
  amounts: {
    fontWeight: 500,
  },
  lighterText: {
    opacity: '.6',
  },
  greeting: {
    paddingBottom: '1.5rem',
  },
  propertyEmail: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  onClose: () => void;
  onFinalizeMoveOut: () => void;
  loadingUpdate: boolean;
  isOpen: boolean;
  selectedLease: Lease;
  leaseBalance: number;
  depositAmount: number;
  depositRefund: number;
  additionalRefund: number;
  checkNumber?: string;
  userID?: string;
}

export const MoveOutLetterDialog: React.FC<Props> = ({
  onClose,
  onFinalizeMoveOut,
  loadingUpdate,
  isOpen,
  selectedLease,
  leaseBalance,
  depositAmount,
  depositRefund,
  additionalRefund,
  checkNumber,
  userID,
}) => {
  const classes = useStyles();
  const alertSnackbar = useContext(AlertContext);
  const [createLedger, loadingCreateLedger] = useCallAction(createLedgerAction);
  const [depositRefundType, setDepositRefundType] = useState<DefaultAccount>();
  const [additionalRefundType, setAdditionalRefundType] =
    useState<DefaultAccount>();

  const [, loadingDefaultAccountTypes] = useFetchAction(
    getDefaultAccounts,
    [],
    {
      onCompleted: (data) => {
        if (!data) return;
        const additionalRefundItem = data.items.find(
          (defAcc) => defAcc.accountSubCategory?.name === 'PREPAID_RENT_CREDIT',
        );
        const depositRefundItem = data.items.find(
          (defAcc) =>
            defAcc.accountSubCategory?.name === 'SECURITY_DEPOSIT_RETURNED',
        );
        setAdditionalRefundType(additionalRefundItem);
        setDepositRefundType(depositRefundItem);
      },
    },
  );

  const onLedgerCreate = (): void => {
    const effectiveDate = new Date();
    const effectiveDateString = moment(effectiveDate).toISOString();
    if (leaseBalance === 0 && depositAmount === 0) {
      onFinalizeMoveOut();
      return;
    }
    if (leaseBalance > 0) {
      createLedger(
        {
          amount: depositRefund,
          description: depositRefundType?.accountSubCategory
            ?.displayName as string,
          type: depositRefundType?.defaultSign as string,
          category: depositRefundType?.accountCategory?.name as string,
          subCategory: depositRefundType?.accountSubCategory?.name as string,
          paymentType: depositRefundType?.accountType?.name as string,
          effectiveDate: effectiveDateString,
          lease: {
            connect: {
              id: selectedLease?.id,
            },
          },
        },
        selectedLease?.id as string,
      );
      if (additionalRefund > 0) {
        createLedger(
          {
            amount: additionalRefund,
            description: additionalRefundType?.accountSubCategory
              ?.displayName as string,
            type: additionalRefundType?.defaultSign as string,
            category: additionalRefundType?.accountCategory?.name as string,
            subCategory: additionalRefundType?.accountSubCategory
              ?.name as string,
            paymentType: additionalRefundType?.accountType?.name as string,
            effectiveDate: effectiveDateString,
            lease: {
              connect: {
                id: selectedLease?.id,
              },
            },
          },
          selectedLease?.id as string,
        );
      }

      createCheckAction({
        checkNumber: checkNumber,
        amount:
          depositRefund + leaseBalance > 0
            ? depositRefund + leaseBalance
            : depositRefund,
        description: selectedLease?.property?.name || '',
        user: {
          connect: {
            id: userID,
          },
        },
        property: {
          connect: {
            id: selectedLease?.property?.id,
          },
        },
        status: 'READY',
      } as CheckCreateInput);
    } else {
      createLedger(
        {
          amount:
            depositRefund + leaseBalance > 0
              ? depositRefund + leaseBalance
              : depositRefund,
          type: depositRefundType?.defaultSign as string,
          description: depositRefundType?.accountSubCategory
            ?.displayName as string,
          category: depositRefundType?.accountCategory?.name as string,
          subCategory: depositRefundType?.accountSubCategory?.name as string,
          paymentType: depositRefundType?.accountType?.name as string,
          effectiveDate: effectiveDateString,
          lease: {
            connect: {
              id: selectedLease?.id,
            },
          },
        },
        selectedLease?.id as string,
      );
    }
  };

  useSubscription(createLedgerEvent, () => {
    alertSnackbar({
      success: true,
      message: 'Ledger successfully created',
    });
    onFinalizeMoveOut();
  });

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen}>
      <DialogTitle>Move out - Deposit Accounting</DialogTitle>
      <Divider />
      {loadingDefaultAccountTypes ? (
        <Grid
          container
          sx={{ height: '200px' }}
          justifyContent="center"
          alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <DialogContent>
          <Grid
            container
            className={classes.content}
            marginTop="1rem"
            flexDirection="column"
            padding="0 2rem">
            <Grid item xs={12} className={classes.gridItem}>
              <Typography>
                {moment(new Date()).format('MMMM DD, yyyy')}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography fontWeight="bold">
                {selectedLease?.property?.name ?? ''}
              </Typography>
              <Typography fontWeight="bold">
                {selectedLease?.property?.city ?? ''}
              </Typography>
              <Typography fontWeight="bold">
                {selectedLease?.property?.state},{' '}
                {selectedLease?.property?.stateCode}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Typography>
                {selectedLease?.userResident?.firstName}{' '}
                {selectedLease.userResident?.lastName}
              </Typography>
              <Typography className={classes.lighterText}>
                {selectedLease?.userResident?.city}
              </Typography>
              <Typography className={classes.lighterText}>
                {selectedLease?.userResident?.state},{' '}
                {selectedLease?.userResident?.zipCode}
              </Typography>
            </Grid>
            <Divider />
            <Grid item xs={12} margin="2rem 0">
              <Typography fontWeight="bold" className={classes.gridItem}>
                {leaseBalance > 0
                  ? 'Re: Security Deposit Return'
                  : 'Re: Security Deposit Return, Balance Due Notice'}
              </Typography>
              <Typography className={classes.gridItem}>
                Dear {selectedLease?.userResident?.firstName}{' '}
                {selectedLease?.userResident?.lastName},
              </Typography>
              <Typography className={classes.gridItem}>
                {leaseBalance > 0
                  ? `Thank you for staying with us. According to our records, your current account credit is 
                    ${formatCurrency(
                      depositRefund + additionalRefund,
                      false,
                    )}. This amount represents a refund of your security deposit and other rent credits, 
                    as shown in the summary below.`
                  : `Thank you for staying with us. According to our records, your current account balance is 
                    ${formatCurrency(
                      depositRefund + leaseBalance,
                      false,
                    )}. This amount represents an 
                    outstanding balance after all security deposits and other rent credits are applied, as shown in 
                    the summary below.`}
              </Typography>
            </Grid>
            {leaseBalance > 0 ? (
              <Box className={classes.card}>
                <Box className={classes.cardRow}>
                  <p>Deposit Returned</p>
                  <p className={classes.amounts}>
                    {formatCurrency(depositRefund, false)}
                  </p>
                </Box>
                <Box className={classes.cardRow}>
                  <p>Additional Refunded Amount</p>
                  <p className={classes.amounts}>
                    {formatCurrency(additionalRefund, false)}
                  </p>
                </Box>
                <Box className={classes.totalAmount}>
                  <p>Total Amount Returned</p>
                  <p className={classes.amounts}>
                    {formatCurrency(depositRefund + additionalRefund, false)}
                  </p>
                </Box>
              </Box>
            ) : (
              <Box className={classes.card}>
                <Box className={classes.cardRow}>
                  <p>Balance Prior to Refund</p>
                  <p className={classes.amounts}>
                    {formatCurrency(leaseBalance, false)}
                  </p>
                </Box>

                <Box className={classes.cardRow}>
                  <p>Security Deposit Refund</p>
                  <p className={classes.amounts}>
                    {formatCurrency(depositAmount, false)}
                  </p>
                </Box>

                <Box className={classes.totalAmount}>
                  <p>Balance Due</p>
                  <p className={classes.amounts}>
                    {formatCurrency(depositRefund + leaseBalance, false)}
                  </p>
                </Box>
              </Box>
            )}
            <Grid item xs={12} margin="2rem 0">
              <Typography className={classes.greeting}>
                {leaseBalance > 0 ? (
                  <p>
                    To view an itemized statement, please log into your Resident
                    Portal. If you have any questions or concerns regarding your
                    refund, please don't hesitate to contact us at &nbsp;
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`mailto: ${selectedLease?.property?.email}?subject=RE: Move Out - Deposit Accounting`}>
                      {selectedLease?.property?.email}
                    </a>
                  </p>
                ) : (
                  <p>
                    To view an itemized statement, please log into your Resident
                    Portal. If you have any questions or concerns regarding your
                    balance due, don't hesitate to contact us at &nbsp;
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`mailto: ${selectedLease?.property?.email}?subject=RE: Move Out - Deposit Accounting`}>
                      {selectedLease?.property?.email}
                    </a>
                  </p>
                )}
              </Typography>
              <Typography className={classes.greeting}>Regards,</Typography>
              <Typography className={classes.greeting}>
                {selectedLease?.property?.name} Management
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        <Button variant="text" onClick={onClose}>
          CANCEL
        </Button>
        <ButtonAction
          isLoading={
            loadingUpdate || loadingCreateLedger || loadingDefaultAccountTypes
          }
          variant="contained"
          fullWidth={false}
          color="primary"
          onClick={onLedgerCreate}>
          FINALIZE MOVE OUT
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
