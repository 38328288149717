import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Loading } from '../Loading';

interface ButtonActionProps extends ButtonProps {
  isLoading?: boolean;
}

export const ButtonAction: React.FC<ButtonActionProps> = ({
  isLoading = false,
  children,
  variant = 'contained',
  size = 'large',
  color = 'primary',
  disabled = false,
  fullWidth = true,
  ...rest
}) => (
  <Button
    disabled={isLoading || disabled}
    disableElevation
    variant={variant}
    size={size}
    color={color}
    fullWidth={fullWidth}
    {...rest}>
    {isLoading && <Loading />}
    {children}
  </Button>
);
