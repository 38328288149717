import React, { useState, useReducer, useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MoreVertOutlined from '@material-ui/icons/MoreVertOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';

import SwapVertOutlined from '@material-ui/icons/SwapVertOutlined';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownOutlined from '@material-ui/icons/ArrowDropDownOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  GridCellParams,
  GridColDef,
  GridRowId,
  GridToolbarContainer,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';

import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';

import { AlertContext } from '../../../routes/AlertContext';
import { rootStyleTable } from '../../../shared/utils';
import { deleteDocument, deleteDocuments } from '../people-actions';
import { ConfirmDeleteDocumentDialog } from './ConfirmDeleteDocumentDialog';
import { createDocumentsError, deleteDocumentEvent } from '../people-event';
import { RequiredFile } from '../../../schema-types';
import { CustomDataGrid } from '../../../shared/components/CustomDataGrid';

export type DocumentRowDef = {
  id: string;
  name: string;
  type: string;
  date: string;
  actions: string;
  downloadUrl: string;
};

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(4, 6),
  },
  paper: {
    padding: 40,
  },
  border: {
    marginTop: theme.spacing(2),
  },
  dataGridContainer: {
    height: 500,
  },
  dataGridContainerMobile: {
    height: 500,
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      whiteSpace: 'pre',
    },
  },
  dataGrid: {
    height: '100%',
  },
  root: rootStyleTable,

  row: {
    minHeight: 'fit-content !important',
  },
  cell: {
    minHeight: 'auto !important',
  },
  rowMobile: {
    minHeight: 'fit-content !important',
  },
  cellMobile: {
    lineHeight: 'normal !important',
    minHeight: 'auto !important',
    maxHeight: 'fit-content !important',
  },
  bulkButton: {
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'none',
  },
  addButton: {
    textTransform: 'none',
    '& svg': {
      marginRight: 5,
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const ActionsButton: React.FC<{
  params: GridCellParams;
  fileDownloadUrl?: string;
  signedDocDownloadUrl?: string;
  isSignedDoc?: boolean;
  signedDocType?: boolean;
  fileId: string;
}> = ({
  params,
  fileId,
  signedDocDownloadUrl = '',
  fileDownloadUrl = '',
  signedDocType = false,
  isSignedDoc = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmationDelete, setOpenConfirmationDelete] =
    useState<boolean>(false);
  const alert = useContext(AlertContext);
  const [deleteDocumente, loading] = useCallAction(deleteDocument);

  useSubscription(deleteDocumentEvent, () => {
    setOpenConfirmationDelete(false);
    alert({ success: true, message: 'Document deleted successfully' });
  });

  useSubscription(createDocumentsError, () => {
    setOpenConfirmationDelete(false);
    alert({ error: true, message: 'Something went wrong while deleting' });
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disableFocusRipple
        disableRipple
        aria-controls="documents-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="documents-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {!signedDocType && (
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenConfirmationDelete(true);
            }}
            color="primary">
            <DeleteForeverOutlined fontSize={'small'} /> Delete Document
          </MenuItem>
        )}
        {!isSignedDoc ? (
          <MenuItem
            disabled={!fileDownloadUrl}
            onClick={() => {
              handleClose();
              window.open(fileDownloadUrl);
            }}
            color="primary">
            <VisibilityIcon fontSize={'small'} /> View Document
          </MenuItem>
        ) : undefined}
        {isSignedDoc ? (
          <MenuItem
            disabled={!signedDocDownloadUrl}
            onClick={() => {
              handleClose();
              window.open(signedDocDownloadUrl, '_blank');
            }}
            color="primary">
            <DownloadIcon fontSize={'small'} /> Download{' '}
            {signedDocType ? 'Contract' : 'Terms'}
          </MenuItem>
        ) : undefined}
      </Menu>
      <ConfirmDeleteDocumentDialog
        loading={loading}
        onClose={() => setOpenConfirmationDelete(false)}
        onDelete={() => deleteDocumente(params.row.id, fileId)}
        open={openConfirmationDelete}
      />
    </>
  );
};
/*
 * @returns - Custom Toolbar for Bulk actions.
 */
const PaymentPlantToolbar: React.FC<{
  setForceRenderFlag: React.Dispatch<React.SetStateAction<boolean>>;
  leaseId: string;
  rowCount?: number;
}> = ({ setForceRenderFlag, leaseId, rowCount }) => {
  const classes = useStyles();
  const { state, apiRef } = useGridSlotComponentProps();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { totalRowCount, allRows } = state.rows;

  const selectedRowsMap = apiRef.current.getSelectedRows();
  const { size: selectedSize } = selectedRowsMap;
  const [deleteDocumens] = useCallAction(deleteDocuments);
  const handleBulkClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkClose = (): void => {
    deleteDocumens(
      [...(Array.from<GridRowId>(selectedRowsMap.keys()) as string[])],
      leaseId,
    );
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <Checkbox
        checked={selectedSize === totalRowCount && !!rowCount}
        indeterminate={selectedSize > 0 && selectedSize !== totalRowCount}
        onChange={() => {
          if (apiRef.current.getSelectedRows().size === allRows.length) {
            apiRef.current.setSelectionModel([]);
            setForceRenderFlag((flag) => !flag);
            return;
          }
          apiRef.current.setSelectionModel(allRows);
          setForceRenderFlag((flag) => !flag);
        }}
        inputProps={{ 'aria-label': 'selected rows checkbox' }}
        color="primary"
      />
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={classes.bulkButton}
        onClick={handleBulkClick}>
        Bulk actions <ArrowDropDownOutlined />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleBulkClose}>Delete All</MenuItem>
      </Menu>
      <div className={classes.grow} />
    </GridToolbarContainer>
  );
};
type Props = { files: RequiredFile[]; signedDocuments?: DocumentRowDef[] };

/**
 * Payment Plan Customized DataGrid.
 *
 * @param {Props} props - Properties.
 * @returns - DocumentTable Component.
 */

export const DocumentsTable: React.FC<Props> = ({
  files,
  signedDocuments = [],
}) => {
  const classes = useStyles();

  const [documentsRows, setDocumentsRows] = useState<Array<DocumentRowDef>>([]);
  const [documentsRowsMobile, setDocumentsRowsMobile] = useState<
    Array<DocumentRowDef>
  >([]);

  // Add force update function to force the browser render. More about force update: https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const formatL = moment.localeData().longDateFormat('ll');

    const rows: Array<DocumentRowDef> =
      files
        ?.map((doc) => ({
          id: doc.id ?? '',
          name: doc.file?.filename || '',
          type:
            `${doc.type} - ${doc.requiredFileDocumentTypeRelation?.name}` ?? '',
          date: doc.userUploadDate
            ? moment(doc.userUploadDate).format(formatL)
            : moment(doc.updatedAt).format(formatL),
          actions: '',
          downloadUrl: doc.file?.downloadUrl as string,
        }))
        .concat(signedDocuments) ?? [];

    const rowsMobile: Array<DocumentRowDef> =
      files
        ?.map((doc) => ({
          id: doc.id ?? '',
          name:
            (doc.file?.filename || '') +
            '\n' +
            (`${doc.type} - ${doc.requiredFileDocumentTypeRelation?.name}` ??
              '') +
            '\n' +
            moment(doc.updatedAt).format(formatL),
          type: '',
          date: '',
          actions: '',
          downloadUrl: doc.file?.downloadUrl as string,
        }))
        .concat(signedDocuments) ?? [];

    setDocumentsRows(rows);
    setDocumentsRowsMobile(rowsMobile);
    // Force render on setSubperiodRows because sometimes it hook does not update the view
    forceUpdate();
  }, [setDocumentsRows, setDocumentsRowsMobile, files, signedDocuments]);
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      hide: true,
      width: 100,
    },
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'TYPE',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'DATE',
      flex: 1,
    },

    {
      field: 'actions',
      headerName: 'ACTIONS',
      renderCell: (params) => {
        return (
          <ActionsButton
            params={params}
            fileId={params.row.id}
            fileDownloadUrl={params.row.downloadUrl}
            signedDocType={params.row.type.includes('Contract')}
            isSignedDoc={signedDocuments?.some((cd) => cd.id === params.row.id)}
            signedDocDownloadUrl={params.row.downloadUrl}
          />
        );
      },
      flex: 0.5,
    },
  ];

  const columnsMobile: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      hide: true,
      width: 100,
    },
    {
      field: 'name',
      headerName: 'NAME / TYPE / DATE',
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      renderCell: (params) => {
        return (
          <ActionsButton
            params={params}
            fileId={params.row.id}
            fileDownloadUrl={params.row.downloadUrl}
            signedDocType={params.row.type.includes('Contract')}
            isSignedDoc={signedDocuments?.some((cd) => cd.id === params.row.id)}
            signedDocDownloadUrl={params.row.downloadUrl}
          />
        );
      },
      width: 90,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
  ];

  const count = documentsRows?.length || 0;
  const rowsPerPageOptions =
    //count > 100 ? [5, 10, 20, 50, 100, count] :
    [5, 10, 20, 50, 100];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Box height={5} />
      <Grid container item alignItems="center" className={classes.border}>
        <Grid
          item
          xs={12}
          className={
            matches
              ? classes.dataGridContainer
              : classes.dataGridContainerMobile
          }>
          <CustomDataGrid
            className={classes.dataGrid}
            onCellClick={(row, e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            classes={
              matches
                ? {
                    cell: classes.cell,
                    row: classes.row,
                    root: classes.root,
                  }
                : {
                    cell: classes.cellMobile,
                    row: classes.rowMobile,
                    root: classes.root,
                  }
            }
            components={{
              ColumnUnsortedIcon: SwapVertOutlined,
              Toolbar: (props) => (
                <PaymentPlantToolbar
                  setForceRenderFlag={forceUpdate}
                  leaseId={files?.[0]?.id ?? ''}
                  rowCount={documentsRows?.length || 0}
                />
              ),
            }}
            rows={matches ? documentsRows : documentsRowsMobile}
            columns={matches ? columns : columnsMobile}
            checkboxSelection
            hideFooterPagination={!count}
            rowsPerPageOptions={rowsPerPageOptions}
            autoPageSize
          />
        </Grid>
      </Grid>
    </>
  );
};
