import {
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import GradeOutlinedIcon from '@material-ui/icons/GradeOutlined';
import { AlertContext } from '../../../routes/AlertContext';
import { PRIMARY_COLOR } from '../../theme';

const useStyles = makeStyles((theme) => ({
  paperSnack: {
    backgroundColor: '#EDF1FE',
    border: `1px solid ${PRIMARY_COLOR}`,
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    width: 400,
  },
  paperSnackError: {
    backgroundColor: '#fdecea',
    borderColor: theme.palette.error.main,
  },
  snackCenter: {
    color: PRIMARY_COLOR,
    textAlign: 'center',
    '& svg': {
      margin: theme.spacing(0.5, 0),
    },
  },
  snackCenterError: {
    color: theme.palette.error.main,
  },
}));

export const AlertSnackbar: React.FC = ({ children }) => {
  const classes = useStyles();
  const [alertMessage, setAlertMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleCloseSnack = (): void => {
    setIsError(false);
    setIsSuccess(false);
    setAlertMessage('');
  };

  const handleSnack = ({
    error,
    success,
    message,
  }: {
    error?: boolean;
    success?: boolean;
    message: string;
  }): void => {
    setAlertMessage(message);
    if (error) {
      setIsError(true);
      return;
    }
    if (success) {
      setIsSuccess(true);
    }
  };

  return (
    <AlertContext.Provider value={handleSnack}>
      {children}
      <Snackbar
        autoHideDuration={5000}
        open={isError || isSuccess}
        onClose={handleCloseSnack}
        onClick={handleCloseSnack}
        style={{ cursor: 'pointer' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Paper
          className={`${classes.paperSnack} ${isError && classes.paperSnackError
            }`}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={2}
              className={`${classes.snackCenter} ${isError && classes.snackCenterError
                }`}>
              {isError && <ErrorOutlineIcon />}
              {isSuccess && <CheckCircleOutlineOutlinedIcon />}
            </Grid>
            <Grid item xs>
              <Typography
                variant="subtitle1"
                color={isError ? 'error' : 'primary'}>
                {isSuccess && 'SUCCESS'}
                {isError && 'ERROR'}
              </Typography>
              <Typography
                style={{ whiteSpace: 'pre-line' }}
              >{alertMessage}</Typography>
            </Grid>
            <Grid item xs={1}>
              {isSuccess && <GradeOutlinedIcon />}
              {isError && <CloseIcon />}
            </Grid>
          </Grid>
        </Paper>
      </Snackbar>
    </AlertContext.Provider>
  );
};
