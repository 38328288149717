import { FileInput, FileValue } from '@8base-react/file-input';
import { useFetchAction } from '@8baselabs/react-simple-state';
import { File } from '@8baselabs/resident-io-shared/src/schema-types';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Dispatch, useCallback, useState } from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { ButtonDefault } from './ui/buttons/ButtonDefault';
import * as filestack from 'filestack-js';
import { fetchUploadInfoAction } from '../../modules/profile/my-info/my-info-actions';
import { Typography } from '@material-ui/core';
import { PRIMARY_COLOR } from '../theme';
//Unused until drop zone is implemented
/* const DashedContainer = styled('div')({
  minWidth: '100%',
  border: '1px dashed #bfbfbf',
  padding: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  cursor: 'pointer',
});
 */

export interface FormattedFiles extends filestack.PickerFileMetadata {
  fileId: string;
  downloadUrl: string;
}

export const FilestackUploadButton: React.FC<{
  images?: FileValue[] | undefined;
  setImages?: Dispatch<FileValue[] | FileValue>;
  onUploadDone: (files: FormattedFiles[]) => void;
  mimetypes?: string[];
  hasFilter?: boolean;
  fromSources?: string[];
  maxFiles?: number;
  accept?: string[];
}> = ({
  images,
  setImages,
  mimetypes,
  onUploadDone,
  hasFilter,
  fromSources,
  maxFiles,
  accept,
}) => {
  const [path, setPath] = useState<string>();
  const [policy, setPolicy] = useState<string>();
  const [signature, setSignature] = useState<string>();
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();

  useFetchAction(fetchUploadInfoAction, [], {
    onCompleted(wrapperUploadInfo) {
      setPath(wrapperUploadInfo.path);
      const clientOptions = {
        security: {
          policy: wrapperUploadInfo.policy,
          signature: wrapperUploadInfo.signature,
        },
      };

      setPolicy(wrapperUploadInfo.policy);
      setSignature(wrapperUploadInfo.signature);

      const initializedFilestack = filestack.init(
        wrapperUploadInfo.apiKey,
        clientOptions,
      );
      setClientFileStack(initializedFilestack);
    },
  });

  const getUrl = (url: string): URL | null => {
    try {
      const res = new URL(url);

      return res;
    } catch (e) {
      console.log({e});
      return null;
    }
  }

  const handleDocument = useCallback(async (): Promise<void> => {
    if (clientFileStack !== undefined) {
      const options: filestack.PickerOptions = {
        fromSources: fromSources ?? ['local_file_system'],
        onUploadDone: (files) => {
          const formattedFiles = files.filesUploaded.map((file) => {
            const url = getUrl(file.url || '');

            if (!url) {
              return { ...file, fileId: file.handle, downloadUrl: file.url };
            }

            const { origin, pathname } = url;
            const downloadUrl = `${origin}/security=p:${policy},s:${signature}${pathname}`;

            return { ...file, fileId: file.handle, downloadUrl };
          });
          onUploadDone(formattedFiles);
        },
        storeTo: { path },
        maxFiles: maxFiles ?? 1,
        accept,
      };

      await clientFileStack.picker(options).open();
    }
  }, [
    accept,
    clientFileStack,
    fromSources,
    maxFiles,
    onUploadDone,
    path,
    policy,
    signature,
  ]);

  return (
    <ErrorBoundary>
      <FileInput>
        {({ pick }) => (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            onClick={() => handleDocument()}>
            <Grid item container justifyContent="center" spacing={2}>
              {images?.map((image) => {
                return (
                  <Grid item>
                    <img
                      style={{ borderRadius: 5 }}
                      height={150}
                      width={200}
                      src={(image as File)?.downloadUrl}
                      alt={(image as File)?.filename}
                      key={(image as File)?.downloadUrl}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ButtonDefault style={{ backgroundColor: PRIMARY_COLOR }}>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                <CloudUploadIcon fontSize="large" htmlColor={'#FFFFFF'} />
                <Typography
                  variant="body1"
                  style={{ color: 'white' }}
                  display="inline"
                  align="center">
                  Upload a file
                </Typography>
              </Grid>
            </ButtonDefault>
          </Grid>
        )}
      </FileInput>
    </ErrorBoundary>
  );
};

/* export const UploadOneImage: React.FC<{
  image?: FileValue | undefined;
  setImage: Dispatch<SetStateAction<FileValue>>;
  isCsv?: boolean;
}> = ({ image, setImage, isCsv }) => {
  const alert = useContext(AlertContext);

  return (
    <ErrorBoundary>
      {' '}
      <FileInput
        onChange={(pictures: FileInputValue) => {
          //@ts-expect-error package typing error
          if (!pictures.mimetype.includes('image/') && !isCsv) {
            return;
          }
          //@ts-expect-error package typing error
          if (!pictures.mimetype.includes('text/csv') && isCsv) {
            alert({
              message: 'Building layouts should be uploaded in a CSV file.',
              error: true,
            });
            return;
          }

          setImage(pictures as FileValue);
        }}
        value={image}
        maxFiles={1}>
        {({ pick }) => (
          <Grid
            onClick={() => pick({})}
            container
            direction="column"
            alignItems="center"
            justifyContent="center">
            <ButtonDefault>
              <Grid item style={{ marginTop: 15 }}>
                <CloudUploadIcon fontSize="large" />
              </Grid>
            </ButtonDefault>
          </Grid>
        )}
      </FileInput>
    </ErrorBoundary>
  );
};
 */
