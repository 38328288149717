import { gql } from '@apollo/client';

export const MESSAGE_TEMPLATE_CREATE_MUTATION = gql`
  mutation MESSAGE_TEMPLATE_CREATE_MUTATION(
    $data: MessageTemplateCreateInput!
  ) {
    messageTemplateCreate(data: $data) {
      id
      name
    }
  }
`;

export const MESSAGE_TEMPLATE_LIST_QUERY = gql`
  query MESSAGE_TEMPLATE_LIST_QUERY(
    $filter: MessageTemplateFilter
    $first: Int
    $skip: Int
  ) {
    messageTemplatesList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        name
        type
        subject
        createdAt
        template
      }
    }
  }
`;

export const MESSAGE_TEMPLATE_QUERY = gql`
  query MESSAGE_TEMPLATE_QUERY($id: ID!) {
    messageTemplate(id: $id) {
      id
      name
      subject
      createdAt
      template
      type
    }
  }
`;

export const MESSAGE_TEMPLATE_DELETE_MUTATION = gql`
  mutation MESSAGE_TEMPLATE_DELETE_MUTATION($id: ID!) {
    messageTemplateDelete(data: { id: $id }) {
      success
    }
  }
`;

export const MESSAGE_TEMPLATE_UPDATE_MUTATION = gql`
  mutation MESSAGE_TEMPLATE_UPDATE_MUTATION(
    $data: MessageTemplateUpdateInput!
  ) {
    messageTemplateUpdate(data: $data) {
      id
    }
  }
`;

export const SEND_EMAIL_RESOLVER = gql`
  mutation SEND_EMAIL_RESOLVER($data: SendEmailResolverInput!) {
    sendEmailResolver(data: $data) {
      success
      message
    }
  }
`;
