import { createEvent } from '@8baselabs/react-simple-state';
import {
  AdminInvitationListResponse,
  UserVerifyCodeResult,
  User,
  TermAndConditionListResponse,
  TermsAndConditionsUser,
  PaymentMethod,
  ResponseCreateAssessment,
  ResponseSaveResultsAssessment,
} from '@8baselabs/resident-io-shared';
import { ValidationError } from 'yup';
import { ResultType } from '../../shared/constants';
import { BankPaymentMethod, CardPaymentMethod } from '../people/people-types';

export const onFinishRegisterEvent = createEvent();
export const onFinishRegisterErrorEvent = createEvent<string>();

export const resendVerifyCodeEvent = createEvent();
export const resendVerifyCodeErrorEvent = createEvent<string>();

export const verifyCodeEvent = createEvent<UserVerifyCodeResult>();
export const verifyCodeErrorEvent = createEvent<Error>();
export const OnFetchTerms = createEvent<TermAndConditionListResponse>();
export const OnFetchTermsError = createEvent<Error>();
export const CreateUserTerms = createEvent<TermsAndConditionsUser>();
export const CreateUserTermsError = createEvent<Error>();
export const OnFetchAdminInvitation =
  createEvent<AdminInvitationListResponse>();
export const OnFetchAdminInvitationError = createEvent<Error>();

export const OnUpdateUser = createEvent<User>();
export const OnUpdateUserError = createEvent<Error>();

export const onFinishAdminInvitationEvent = createEvent<ResultType>();
export const onFinishAdminInvitationErrorEvent = createEvent<
  Error | ValidationError
>();

export const OnUpdateGuarantor = createEvent<User>();
export const OnUpdateGuarantorError = createEvent<Error>();

export const OnUpdateAllowCreditCards = createEvent<User>();
export const OnUpdateAllowCreditCardsError = createEvent<Error>();

export const OnUpdateAllowChecks = createEvent<User>();
export const OnUpdateAllowChecksError = createEvent<Error>();

export const OnGetCardsPaymentMethods = createEvent<CardPaymentMethod[]>();
export const OnGetCardsPaymentMethodsError = createEvent<Error>();

export const OnGetBanksPaymentMethods = createEvent<BankPaymentMethod[]>();
export const OnGetBanksPaymentMethodsError = createEvent<Error>();

export const OnGetAllPaymentMethods =
  createEvent<(PaymentMethod | BankPaymentMethod)[]>();
export const OnGetAllPaymentMethodsError = createEvent<Error>();

export const OnCreateSetupIntent = createEvent<{ client_secret: string }>();
export const OnCreateSetupIntentError = createEvent<Error>();

export const OnCreatePaymentIntent = createEvent<{
  id: string;
  client_secret: string;
  connected_account_payment_method_id?: string;
}>();
export const OnCreatePaymentIntentError = createEvent<Error>();

export const OnCreatePropertyStripeAccountEvent = createEvent<{
  accountId: string;
}>();
export const OnCreatePropertyStripeAccountError = createEvent<Error>();

export const OnCreateStripeAccountLinkEvent = createEvent<{
  accountLink: string;
}>();
export const OnCreateStripeAccountLinkError = createEvent<Error>();

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OnGetStripeAccount = createEvent<any>();
export const OnGetStripeAccountError = createEvent<Error>();

export const OnCreateAssessmentEvent = createEvent<ResponseCreateAssessment>();
export const OnCreateAssessmentErrorEvent = createEvent<Error>();

export const OnSaveAssessmentEvent =
  createEvent<ResponseSaveResultsAssessment>();
export const OnSaveAssessmentErrorEvent = createEvent<Error>();
