import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MainLoader } from '../../shared/components/MainLoader';
import { useLocalStorage } from '../../shared/components/components-hooks';

// TODO add loading component
/**
 * @returns {JSX.Element} - Auth component.
 */
export function Register(): JSX.Element {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  const [registerHint] = useLocalStorage('registerHint', '');
  
  useEffect(() => {
    loginWithRedirect({
      appState: location.state,
      screen_hint: 'signup',
      invited: true,
      login_hint: registerHint ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MainLoader />;
}
