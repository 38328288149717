import { User, Lease, Property } from '@8baselabs/resident-io-shared';
import { createEvent } from '@8baselabs/react-simple-state';
import { ResultType } from '../constants';

export const OnPrevSelectClient = createEvent<string>();
export const OnSelectClient = createEvent<User>();
export const OnSelectClientError = createEvent<Error>();
export const OnDeselectClient = createEvent<User>();
export const OnDeselectClientError = createEvent<Error>();

export const OnRequestEmailVerification = createEvent<ResultType>();
export const OnRequestEmailVerificationError = createEvent<Error>();

export const OnParsedFile = createEvent<void>();
export const OnParsedFileError = createEvent<Error>();

export const OnGetLease = createEvent<Lease>();
export const OnGetLeaseError = createEvent<Error>();

export const OnGetClientRelation = createEvent<Property['clientUser']>();
export const OnGetClientRelationError = createEvent<Error>();

export const OnGetProperty = createEvent<Property>();
export const OnGetPropertyError = createEvent<Error>();
