import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import BedIcon from '@material-ui/icons/KingBedOutlined';
import CreditCardOutlined from '@material-ui/icons/CreditCardOutlined';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import GroupsOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LogoutOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { ProfileView } from './subroutes/ProfileView';
import { NotesView } from './subroutes/NotesView';
import { MoveOut } from './subroutes/MoveOut';
import { LedgerView } from './subroutes/LedgerView';
import { AplicationView } from './subroutes/AplicationView';
import { GuarantorView } from './subroutes/GuarantorView';
import { OptionalPreferences } from './subroutes/OptionalPreferences';
import { DocumentView } from './subroutes/DocumentView';
import { PaymentMethodView } from './subroutes/PaymentMethodView';
import { AutoPayView } from './subroutes/AutoPayView';
import { RoomMateView } from './subroutes/RoomMateView';
import { MessagesView } from './subroutes/MessagesView';
import { ActivityView } from './subroutes/ActivityView';
import { TransferView } from './subroutes/TransferView';

export const PEOPLE_PROFILE = {
  GUARANTOR: {
    name: 'Guarantor',
    icon: <VerifiedUserOutlinedIcon />,
    path: '/guarantor',
    Component: GuarantorView,
  },
};

export const PEOPLE_PROFILE_ROUTES = [
  {
    name: 'Profile',
    icon: <AccountCircleOutlinedIcon />,
    path: '/profile',
    Component: ProfileView,
  },
  {
    name: 'Ledger',
    icon: <BedIcon />,
    path: '/ledger',
    Component: LedgerView,
  },
  {
    name: 'Payment Method',
    icon: <CreditCardOutlined />,
    path: '/payment',
    Component: PaymentMethodView,
  },
  {
    name: 'Autopay',
    icon: <CreditCardOutlined />,
    path: '/autopay',
    separator: true,
    Component: AutoPayView,
  },
  {
    name: 'Messages',
    icon: <InsertCommentOutlinedIcon />,
    path: '/messages',
    Component: MessagesView,
  },
  {
    name: 'Activity feed',
    icon: <CachedOutlinedIcon />,
    path: '/activity',
    Component: ActivityView,
  },
  {
    name: 'Notes',
    icon: <InsertDriveFileOutlinedIcon />,
    path: '/notes',
    Component: NotesView,
  },
  {
    name: 'Roommates',
    icon: <GroupsOutlinedIcon />,
    path: '/roommates',
    Component: RoomMateView,
    separator: true,
  },
  {
    name: 'Move out',
    icon: <LogoutOutlinedIcon />,
    path: '/move-out',
    Component: MoveOut,
  },
  {
    name: 'Transfer',
    icon: <CompareArrowsOutlinedIcon />,
    path: '/transfer',
    Component: TransferView,
    separator: true,
  },
  {
    name: 'Application',
    icon: <InfoOutlinedIcon />,
    path: '/application',
    Component: AplicationView,
  },
  {
    name: 'Documents',
    icon: <DescriptionOutlinedIcon />,
    path: '/documents',
    separator: true,
    Component: DocumentView,
  },
  {
    name: 'Optional Preferences',
    icon: <SettingsOutlinedIcon />,
    path: '/optional-preferences',
    Component: OptionalPreferences,
  },
  {
    ...PEOPLE_PROFILE.GUARANTOR,
  },
];
