import { Grid } from '@mui/material';
import React from 'react';
import { ButtonAction } from '../buttons/ButtonAction';
import { ButtonDefault } from '../buttons/ButtonDefault';

type TypeButtonProps = {
  isLoading: boolean;
  handleNext: () => void;
  activeStep: number;
  lastStep: number;
  disabled?: boolean;
};

export const TypeButton: React.FC<TypeButtonProps> = ({
  isLoading,
  handleNext,
  activeStep,
  lastStep,
  disabled = false,
}) => (
  <ButtonAction
    variant="contained"
    color="primary"
    size="medium"
    type="button"
    isLoading={isLoading}
    fullWidth={false}
    onClick={handleNext}
    disabled={disabled}
    style={{ marginBottom: '10px', minWidth: '90px' }}>
    {activeStep === lastStep ? 'Finish' : 'Next'}
  </ButtonAction>
);

type FooterProps = {
  activeStep: number;
  lastStep: number;
  handleBack?: () => void;
  handleSave?: () => void;
  handleNext: () => void;
  isLoading?: boolean;
  disableNext?: boolean;
  disableBack?: boolean;
  showSkip?: boolean;
  handleSkip?: () => void;
};

export const FooterSteps: React.FC<FooterProps> = ({
  activeStep,
  lastStep,
  handleBack,
  handleNext,
  handleSave,
  isLoading = false,
  disableNext = false,
  disableBack = false,
  showSkip = false,
  handleSkip,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="space-between"
      marginBottom="1rem">
      <Grid item xs={4}>
        {handleBack && (
          <ButtonDefault
            variant="outlined"
            size="medium"
            fullWidth={false}
            disabled={activeStep === 0 || disableBack}
            onClick={handleBack}
            style={{ marginBottom: '10px', width: '90px' }}>
            Back
          </ButtonDefault>
        )}
      </Grid>
      <Grid
        style={{
          display: 'flex',
          marginRight: activeStep === 4 ? '0' : '13px',
        }}
        justifyContent="flex-end"
        item
        xs={7}>
        {handleSave ? (
          <Grid
            item
            display="flex"
            justifyContent="flex-start"
            marginRight="13px">
            <ButtonAction
              variant="contained"
              color="primary"
              size="medium"
              type="button"
              isLoading={isLoading}
              fullWidth={false}
              onClick={handleSave}
              style={{ marginBottom: '10px', minWidth: '90px' }}>
              Save
            </ButtonAction>
          </Grid>
        ) : null}
        {handleSkip ? (
          <Grid
            item
            display="flex"
            justifyContent="flex-start"
            marginRight="13px">
            <ButtonAction
              variant="contained"
              color="primary"
              size="medium"
              type="button"
              isLoading={isLoading}
              fullWidth={false}
              onClick={handleSkip}
              style={{ marginBottom: '10px', minWidth: '90px' }}>
              Skip this step
            </ButtonAction>
          </Grid>
        ) : null}
        <Grid item display="flex" justifyContent="flex-end">
          <TypeButton
            isLoading={isLoading}
            handleNext={handleNext}
            activeStep={activeStep}
            lastStep={lastStep}
            disabled={disableNext}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
