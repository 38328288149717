import { useCallAction, useEvent } from '@8baselabs/react-simple-state';
import { Lease } from '@8baselabs/resident-io-shared';
import { Box, FormControlLabel, Grid, Switch } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { TextFieldDefault } from '../../../shared/components/ui/inputs/TextFieldDefault';
import { TextFieldPhone } from '../../../shared/components/ui/inputs/TextFieldPhone';
import { PageTitle } from '../../../shared/components/ui/texts/CommonTexts';
import { updateUserAction } from '../../profile/my-info/my-info-actions';
import { fetchUserEvent } from '../people-event';

export const ProfileView: React.FC<{ selectedLease?: Lease }> = () => {
  const user = useEvent(fetchUserEvent);
  const [checked, setchecked] = useState(user?.displayNameAndPhoto || false);
  const [updateUserCall, loading] = useCallAction(updateUserAction);

  const onChangeToggle = (): void => {
    updateUserCall({
      id: user?.id,
      displayNameAndPhoto: !checked,
    });
    setchecked(!checked);
  };

  return (
    <Box padding="40px">
      <PageTitle variant="h5">Personal Information</PageTitle>
      <Box pb="40px" />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextFieldDefault
            disabled
            label="First Name"
            value={user?.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault disabled label="LAST NAME" value={user?.lastName} />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault disabled label="EMAIL" value={user?.email} />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault
            disabled
            label="BIRTH DATE"
            value={
              user?.birthdate
                ? moment(user.birthdate).format('MMMM DD, yyyy')
                : ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldPhone
            disabled
            label="CELL PHONE"
            defaultCountry="us"
            value={user?.phoneNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault
            disabled
            label="HOME PHONE"
            value={user?.homePhone}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault
            disabled
            label="STUDENT ID OR EMPLOYEE NUMBER"
            value={user?.studentIdOrEmployeeNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            disabled={loading}
            onChange={onChangeToggle}
            control={
              <Switch
                color="primary"
                defaultChecked={user?.displayNameAndPhoto}
              />
            }
            label="Display Resident Name and Photo on marketing website"
          />
        </Grid>
      </Grid>

      <Box mt="40px" />
      <PageTitle variant="h5">Permanent Address</PageTitle>
      <Box pb="40px" />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextFieldDefault
            disabled
            label="ADDRESS LINE 1"
            value={user?.address}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault disabled label="CITY" value={user?.city} />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault disabled label="STATE" value={user?.state} />
        </Grid>
        <Grid item xs={6}>
          <TextFieldDefault disabled label="ZIP CODE" value={user?.zipCode} />
        </Grid>
      </Grid>
    </Box>
  );
};
