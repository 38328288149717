import { gql } from '@apollo/client';

export const FETCH_PROPERTY_EVENTS_QUERY = gql`
  query FETCH_PROPERTY_EVENTS_QUERY($id: ID!) {
    property(id: $id) {
      name
      propertyEventRelation {
        items {
          title
          start
          end
          allDay
          id
        }
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CREATE_EVENT(
    $id: ID
    $start: String!
    $end: String!
    $title: String!
  ) {
    eventCreate(
      data: {
        end: $end
        title: $title
        start: $start
        propertyEventRelation: { connect: { id: $id } }
      }
    ) {
      id
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UPDATE_EVENT($data: EventUpdateInput!) {
    eventUpdate(data: $data) {
      id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DELETE_EVENT($data: EventDeleteInput!) {
    eventDelete(data: $data) {
      success
    }
  }
`;
