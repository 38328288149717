import React, { useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';
import { DRAWER_WIDTH } from '../constants';
import { ONBOARDING_ROUTES } from '../../routes/types';
import { useActiveOnboardingName } from '../hooks';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR, MUTED_COLOR } from '../theme';
import { Stepper } from './ui/stepper/Stepper';
import { MobileStepper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      width: '100%'
    },
    drawer: {
      flexShrink: 0,
      transition: 'width 0.35s',
    },
    drawerPaper: {
      transition: 'width 0.35s',
      marginTop: 73,
      zIndex: -1,
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    toolbar: {
      justifyContent: 'center',
    },
    drawerLink: {
      color: 'inherit',
      textDecoration: 'none',
      '::focus': {
        color: 'inherit',
      },
    },
    drawerListItem: {
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
      marginTop: 20,
    },
    drawerListItemIcon: {
      minWidth: 35,
    },
    listItemIcon: {
      color: PRIMARY_TEXT_COLOR,
    },
    selectedListItem: {
      color: '#fff !important',
      backgroundColor: `${MUTED_COLOR} !important`,
    },
    selectedListItemIcon: {
      color: '#fff !important',
    },
    ButtomListItem: {
      color: '#fff !important',
      backgroundColor: `${PRIMARY_COLOR} !important`,
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export const OnboardingSidebar: React.FC = () => {
  const classes = useStyles();
  const activeRouteName = useActiveOnboardingName();
  const routes = useRef(Object.keys(ONBOARDING_ROUTES));

  const steps = routes.current
    .map((item) => ONBOARDING_ROUTES[item].name)
    .filter((step) => step) as string[];

  const activeStep = steps.findIndex((step) => step === activeRouteName);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (matches ?
    <Drawer
      className={`${classes.drawer} ${classes.drawerOpen}`}
      variant="persistent"
      open
      classes={{
        paper: `${classes.drawerPaper} ${classes.drawerOpen}`,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <Box height="40px" />
      <div className={classes.drawerContainer}>
        <Stepper steps={steps} activeStep={activeStep} className={classes.stepper} />
      </div>
    </Drawer>
    :
    <MobileStepper
      steps={steps.length}
      activeStep={activeStep}
      nextButton={<>{`${activeStep + 1}/${steps.length}`}</>}
      backButton={<>{steps[activeStep]}</>}
    />
  );
};
