import gql from 'graphql-tag';

export const FETCH_CHECKS = gql`
  query FETCH_CHECKS($filter: CheckFilter) {
    checksList(filter: $filter) {
      items {
        id
        property {
          id
          name
          clientUser {
            items {
              role
              user {
                firstName
                lastName
                email
              }
            }
          }
        }
        checkNumber
        amount
        status
        createdAt
        updatedAt
        description
        user {
          id
          firstName
          lastName
          email
        }
        approvedBy {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_CHECK = gql`
  mutation UPDATE_CHECK($data: CheckUpdateInput!) {
    checkUpdate(data: $data) {
      id
    }
  }
`;

export const CREATE_CHECK = gql`
  mutation CREATE_CHECK($data: CheckCreateInput!) {
    checkCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROPERTY_CHECKBOOK_ACCOUNT_BY_ID = gql`
  mutation UPDATE_PROPERTY_CHECKBOOK_ACCOUNT_BY_ID(
    $data: PropertyCheckbookAccountUpdateInput!
  ) {
    propertyCheckbookAccountUpdate(data: $data) {
      id
      clientId
      apiKey
      apiSecret
      signature
    }
  }
`;

export const SEND_CHECKS = gql`
  mutation SEND_CHECKS($checksIds: [String], $propertyId: String) {
    sendChecksResolver(checksIds: $checksIds, propertyId: $propertyId) {
      success
    }
  }
`;

export const ADD_SIGNATURE = gql`
  mutation ADD_SIGNATURE($propertyId: String!, $signature: String!) {
    addSignatureResolver(propertyId: $propertyId, signature: $signature) {
      success
    }
  }
`;
