import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextsmsIcon from '@material-ui/icons/TextsmsOutlined';
import LikeIcon from '@material-ui/icons/ThumbUpOutlined';
import DislikeIcon from '@material-ui/icons/ThumbDownAltOutlined';
import MoveIn from '@material-ui/icons/ExitToAppOutlined';
import {
  Link,
  useParams,
  Switch,
  useHistory,
  Redirect,
} from 'react-router-dom';
import {
  useCallAction,
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import { CircularProgress, IconButton } from '@material-ui/core';
import {
  Lease,
  LeaseStatus,
  EntryType,
} from '@8baselabs/resident-io-shared';
import { isValidString } from '@8baselabs/validation-utils';
import AttachMoney from '@material-ui/icons/AttachMoney';
import {
  CAPTION_TEXT_COLOR,
  MUTED_COLOR,
  PRIMARY_COLOR,
  SUCCESS_COLOR,
  ERROR_COLOR,
} from '../../shared/theme';
import { PEOPLE_PROFILE, PEOPLE_PROFILE_ROUTES } from './people-routes';
import { ProtectedRoute } from '../../routes/ProtectedRoute';
import { ROUTES } from '../../routes/types';
import { MainLoader } from '../../shared/components/MainLoader';
import { fetchUserActions } from './people-actions';
import { LeaseListSelector } from './components/LeaseListSelector';
import { updateLeaseOpportunitiesAction } from '../opportunities/opportunities-actions';
import { AddReservationUpdateEvent } from '../opportunities/opportunities-event';
import { formatCurrency } from '../../shared/utils';
import {
  createLedgerEvent,
  createOptionalPreferenceLedgerEvent,
  leaseBalanceEvent,
  OnGetLeaseList,
  updateLeaseEvent,
} from './people-event';
import { useSession } from '../session/session-hooks';
import { OnOpenInboxMessageModal } from '../inbox/inbox-event';
import { InsertOnField } from '../settings-view/message-templates/components/InsertVariablesButton';
import { ComposeMessageEmail } from '../inbox/components/ComposeMessageEmail';
import { GET_LEASE_LIST, GET_LEDGERS_BY_FILTER } from './people-query';
import { useQuery } from '@apollo/client';
import { Property } from '../../schema-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    borderRight: {
      borderRight: `1px solid ${MUTED_COLOR}`,
    },
    userMenuItemIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
      fontSize: '2.7em',
      fontWeight: 500,
      height: theme.spacing(12),
      width: theme.spacing(12),
    },
    nameTitle: {
      fontWeight: 500,
      marginTop: '10px',
      textAlign: 'center',
    },
    drawerLink: {
      color: 'inherit',
      textDecoration: 'none',
      '::focus': {
        color: 'inherit',
      },
    },
    drawerListItem: {
      borderLeft: '5px solid transparent',
      padding: theme.spacing(2, 3),
      width: '100%',
    },
    drawerListItemIcon: {
      minWidth: 35,
    },
    selectedListItem: {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      borderLeft: `5px solid ${PRIMARY_COLOR}`,
    },
    selectedListItemIcon: {
      color: `${PRIMARY_COLOR} !important`,
    },
    dataGridContainer: {
      minHeight: 500,
    },
    sectionText: {
      padding: '20px',
      paddingBottom: '10px',
      color: CAPTION_TEXT_COLOR,
    },
    balancePaper: {
      display: 'flex',
      height: '6em',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export const PeopleFullProfileView: React.FC = () => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const [user, loading] = useFetchAction(fetchUserActions, [params.id]);
  const [session] = useSession();

  const [selectedLease, setSelectedLease] = useState<Lease | null>();

  const {
    loading: loadingLeases,
    refetch,
    data: leasesData,
  } = useQuery(GET_LEASE_LIST, {
    variables: {
      userId: user?.id || '',
      clientId:
        session?.user?.userClientUserRelation?.items?.[0]?.client?.id ||
        session?.user?.selectedClient?.id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (!data) return;
      if (!selectedLease) {
        setSelectedLease(data.leasesList.items[0]);
      } else {
        const leaseRefetched = data.leasesList.items?.filter(
          (lease) => lease.id === selectedLease.id,
        );
        if (!leaseRefetched) return;
        setSelectedLease(leaseRefetched[0]);
      }
    },
    skip:
      user?.id === undefined ||
      (session?.user?.userClientUserRelation?.items?.[0]?.client?.id ||
        session?.user?.selectedClient?.id) === undefined,
  });

  const [updateLeaseCall, loadingUpdate] = useCallAction(
    updateLeaseOpportunitiesAction,
  );
  const balance = useEvent(leaseBalanceEvent);

  const { loading: loadingLedgers, refetch: refetchLedger } = useQuery(
    GET_LEDGERS_BY_FILTER,
    {
      variables: {
        filter: {
          lease: {
            id: { equals: selectedLease?.id },
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !isValidString(selectedLease?.id),

      onCompleted(ledgers) {
        let newBalance = 0;
        ledgers?.ledgersList.items.forEach((ledger) => {
          newBalance =
            ledger.type === EntryType.DEBIT
              ? newBalance + (ledger.amount || 0)
              : newBalance - (ledger.amount || 0);

          leaseBalanceEvent.dispatch({ amount: newBalance });
        });
      },
    },
  );

  useSubscription(AddReservationUpdateEvent, async (data) => {
    refetch();
    refetchLedger();
    setSelectedLease(data);
  });

  useSubscription(updateLeaseEvent, () => {
    refetch();
  });
  useSubscription(createOptionalPreferenceLedgerEvent, () => {
    refetch();
  });

  useSubscription(OnGetLeaseList, (data) => {
    if (!selectedLease) return;
    const leaseRefetched = data?.filter(
      (lease) => lease.id === selectedLease.id,
    );
    if (!leaseRefetched) return;
    setSelectedLease(leaseRefetched[0]);
  });

  useSubscription(createLedgerEvent, async (data) => {
    refetchLedger();
    refetch();
  });

  const pathName = history.location.pathname;

  if (loading) {
    return <MainLoader />;
  }

  if (!loading && !user) {
    history.push('/people');
  }

  return (
    <>
      <Grid
        container={true}
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid container={true} justifyContent="space-between">
              <Grid item xs={2} md={2} lg={2} className={classes.borderRight}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  padding="40px 10px 10px 10px">
                  <Avatar
                    src={user?.avatar?.downloadUrl}
                    className={classes.userMenuItemIcon}>
                    {user?.firstName?.[0]} {user?.lastName?.[0]}
                  </Avatar>
                  <Typography variant="h6" className={classes.nameTitle}>
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Box
                    mt="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Box color={SUCCESS_COLOR}>{user?.gender}</Box>
                    <IconButton
                      style={{ color: SUCCESS_COLOR }}
                      onClick={() => {
                        OnOpenInboxMessageModal.dispatch({
                          open: true,
                          userIDs: user?.id ? [user?.id as string] : undefined,
                          fromField: InsertOnField.COMPOSE,
                          leaseProperty:
                            selectedLease?.property ||
                            leasesData.leasesList.items?.[0]?.property,
                          isPeopleProfile: true,
                        });
                      }}>
                      <TextsmsIcon />
                    </IconButton>
                    <Divider orientation="vertical" flexItem />
                    {loadingUpdate ||
                      (loadingLedgers && (
                        <CircularProgress
                          style={{ paddingLeft: '1rem' }}
                          size="1rem"
                        />
                      ))}
                    {!loadingUpdate &&
                      selectedLease?.status === LeaseStatus.ON_REVIEW &&
                      selectedLease?.reservationPaid && (
                        <IconButton
                          onClick={() => {
                            history.push(
                              `${ROUTES.opportunities.path}${ROUTES.opportunities.subRoutes?.approvereservations.path}?leaseId=${selectedLease?.id}`,
                            );
                          }}
                          style={{ color: PRIMARY_COLOR, padding: '10px' }}>
                          <LikeIcon />
                        </IconButton>
                      )}
                    {!loadingUpdate &&
                      selectedLease?.status === LeaseStatus.ON_REVIEW && (
                        <IconButton
                          onClick={() => {
                            updateLeaseCall(
                              {
                                id: selectedLease?.id,
                                status: LeaseStatus.DENIED,
                              },
                              user?.id,
                              session?.user?.userClientUserRelation?.items?.[0]
                                ?.client?.id ||
                                session?.user?.selectedClient?.id,
                            );
                          }}
                          style={{ color: PRIMARY_COLOR, padding: '10px' }}>
                          <DislikeIcon />
                        </IconButton>
                      )}
                    {!loadingUpdate &&
                      selectedLease?.status === LeaseStatus.APPROVED && (
                        <IconButton
                          onClick={() => {
                            updateLeaseCall(
                              {
                                id: selectedLease?.id,
                                status: LeaseStatus['MOVE-IN'],
                              },
                              user?.id,
                              session?.user?.userClientUserRelation?.items?.[0]
                                ?.client?.id ||
                                session?.user?.selectedClient?.id,
                            );
                          }}
                          style={{ color: PRIMARY_COLOR, padding: '10px' }}>
                          <MoveIn />
                        </IconButton>
                      )}
                  </Box>
                </Box>
                <Divider />
                <List>
                  {PEOPLE_PROFILE_ROUTES.map(
                    ({ path, icon, name, separator }) =>
                      name === PEOPLE_PROFILE.GUARANTOR.name &&
                      (selectedLease === undefined ||
                        selectedLease?.guarantor === null)
                        ? null
                        : !(
                            name === 'Move out' &&
                            selectedLease?.status !== LeaseStatus['MOVE-IN']
                          ) &&
                          !(
                            name === 'Transfer' &&
                            selectedLease?.status !== LeaseStatus.APPROVED &&
                            selectedLease?.status !== LeaseStatus['MOVE-IN']
                          ) && (
                            <>
                              <Link
                                key={name}
                                to={`/people/${params.id}${path}`}
                                className={classes.drawerLink}>
                                <ListItem
                                  button
                                  classes={{
                                    selected: classes.selectedListItem,
                                  }}
                                  className={classes.drawerListItem}
                                  selected={pathName?.includes(path)}>
                                  <ListItemIcon
                                    classes={{
                                      root: pathName?.includes(path)
                                        ? classes.selectedListItemIcon
                                        : undefined,
                                    }}
                                    className={classes.drawerListItemIcon}>
                                    {icon}
                                  </ListItemIcon>
                                  <ListItemText primary={name} />
                                </ListItem>
                              </Link>
                              {separator && <Divider />}
                            </>
                          ),
                  )}
                </List>
              </Grid>
              <Grid item xs={10} md={10} lg={10}>
                <Grid
                  container={true}
                  justifyContent="space-around"
                  alignItems="baseline">
                  <Grid item xs={12}>
                    <Box padding="20px">
                      <Grid container={true} spacing={2}>
                        <Grid item xs={9}>
                          {loadingLeases ? (
                            <Grid
                              container={true}
                              justifyContent="center"
                              alignItems="center">
                              <CircularProgress />
                            </Grid>
                          ) : (
                            <LeaseListSelector
                              leaseList={
                                (leasesData?.leasesList.items as Lease[]) || []
                              }
                              selectedLease={
                                selectedLease ??
                                leasesData?.leasesList?.items?.[0]
                              }
                              setSelectedLease={setSelectedLease}
                              isLoading={loading}
                            />
                          )}
                        </Grid>

                        <Grid item xs={3}>
                          <Paper className={classes.balancePaper}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              padding="0.5rem"
                              borderRadius="50%"
                              bgcolor={
                                balance?.amount <= 0
                                  ? SUCCESS_COLOR
                                  : ERROR_COLOR
                              }
                              color="white">
                              <AttachMoney />
                            </Box>
                            <Box width="70%" paddingLeft="1rem">
                              <Typography>
                                {formatCurrency(balance?.amount, true)}
                              </Typography>
                              <Typography>
                                {balance?.amount <= 0
                                  ? 'Balance Prepaid'
                                  : 'Balance Due'}
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.dataGridContainer}>
                    <Switch>
                      {PEOPLE_PROFILE_ROUTES.map((Route) => (
                        <ProtectedRoute
                          key={Route.name}
                          role={ROUTES.people.roles}
                          path={`/people/:id${Route.path}`}
                          element={
                            Route.Component ? (
                              <Route.Component
                                selectedLeaseProperty={
                                  (selectedLease?.property as Property) ??
                                  leasesData?.leasesList?.items?.[0]?.property
                                }
                                selectedLease={
                                  (selectedLease) ??
                                  leasesData?.leasesList?.items?.[0]
                                }
                                type={'people'}
                                refetchLease={refetch}
                                userId={user?.id}
                              />
                            ) : (
                              <div />
                            )
                          }
                        />
                      ))}

                      <Redirect to={`/people/${params.id}/profile`} />
                    </Switch>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ComposeMessageEmail />
    </>
  );
};
