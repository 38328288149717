import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiPhoneNumber, {
  MaterialUiPhoneNumberProps,
} from 'material-ui-phone-number';

const useStyles = makeStyles({
  input: {
    fontWeight: 500,
    fontSize: 14,
    color: '#5D6E7F',
  },
});

export const TextFieldPhone: React.FC<MaterialUiPhoneNumberProps> = ({
  variant = 'outlined',
  size = 'medium',
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiPhoneNumber
      disableAreaCodes
      fullWidth
      onlyCountries={['us']}
      variant={variant}
      size={size}
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
      {...rest}
    />
  );
};
