import * as yup from 'yup';

export const IdentityValidationSchema = yup.object({
  fullLegalName: yup.string().required('Full name is required').nullable(),
});

export const passwordValidationSchema = yup.object({
  password: yup
    .string()
    .required('Your current password is required')
    .nullable(),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_+@$!%*?&])[A-Za-z\d-_+@$!%*?&]{8,}$/,
      'Password should have at least 8 characters including: one uppercase letter, one lowercase letter, one number and one special character',
    )
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('A new password is required')
    .nullable(),
  confirmNewPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .nullable(),
});

export const emailValidationSchema = yup.object({
  email: yup.string().required('Your current email is required'),
  newEmail: yup
    .string()
    .email('Invalid Email format')
    .required('A new email is required')
    .nullable(),
  confirmNewEmail: yup
    .string()
    .required('Confirm email is required')
    .oneOf([yup.ref('newEmail'), null], 'Emails must match')
    .nullable(),
});

export const securityValidationSchema = yup.object({
  email: yup.string().required('Your current email is required'),
  newEmail: yup
    .string()
    .email('Invalid Email format')
    .when('updateEmail', {
      is: true,
      then: yup.string().required('A new email is required').nullable(),
    }),
  confirmNewEmail: yup.string().when('updateEmail', {
    is: true,
    then: yup
      .string()
      .required('Confirm email is required')
      .oneOf([yup.ref('newEmail'), null], 'Emails must match')
      .required('Emails must match'),
  }),
  password: yup.string().when('updateEmail', {
    is: false,
    then: yup.string().required('Your current password is required').nullable(),
  }),
  newPassword: yup.string().when('updateEmail', {
    is: false,
    then: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_+@$!%*?&])[A-Za-z\d-_+@$!%*?&]{8,}$/,
        'Password should have at least 8 characters including: one uppercase letter, one lowercase letter, one number and one special character',
      )
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .required('A new password is required')
      .notOneOf([yup.ref('password'), null], 'Your new password should be different than your current password')
      .nullable(),
  }),
  confirmNewPassword: yup.string().when('updateEmail', {
    is: false,
    then: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .nullable(),
  }),
});
