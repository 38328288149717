import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: '95vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderContainerSmall: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
/**
 * @param {Props}props - Props component.
 * @returns {JSX.Element} - Main loader component.
 */
export function MainLoader({ title, size }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Container
      classes={{
        root:
          size === 'small'
            ? classes.loaderContainerSmall
            : classes.loaderContainer,
      }}>
      <CircularProgress
        size={size === 'small' ? '2rem' : '5rem'}
        title="loading.."
      />
      <Typography color="primary">{title}</Typography>
    </Container>
  );
}
interface Props {
  title?: string;
  size?: 'normal' | 'small';
}
MainLoader.defaultProps = {
  title: 'Loading...',
  size: 'normal',
};
