import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  alpha,
} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { PRIMARY_COLOR } from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 22,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 2,
      transform: 'translateX(2px)',
      color: alpha(theme.palette.common.black, 0.35),
      '&$checked': {
        transform: 'translateX(18px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.success.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.success.main,
        border: `6px solid ${theme.palette.common.white}`,
      },
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: alpha(PRIMARY_COLOR, 0.095),
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
);

type CheckboxProps = {
  name: string;
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

/**
 * @param root0 - Checkbox component props.
 * @param root0.name - Input name.
 * @param root0.checked - Checked state.
 * @param root0.handleChange - Handler for check / uncheck.
 * @param root0.disabled - Input disabled.
 * @returns {React.FC} - Checkbox component.
 */
export const GreenCheckbox: React.FC<CheckboxProps> = ({
  name,
  checked,
  handleChange,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={handleChange}
      name={name}
      checked={checked}
      disabled={disabled}
    />
  );
};
