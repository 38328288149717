import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { PRIMARY_COLOR } from '../../../theme';
import { Loading } from '../Loading';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(77,124,254,0.08)',
    color: `${PRIMARY_COLOR}`,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(77,124,254,0.08)',
    },
  },
});

interface ButtonActionProps extends ButtonProps {
  isLoading?: boolean;
}

export const ButtonDefault: React.FC<ButtonActionProps> = ({
  isLoading = false,
  children,
  variant = 'contained',
  size = 'large',
  disabled = false,
  fullWidth = true,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button
      disabled={isLoading || disabled}
      disableElevation
      variant={variant}
      fullWidth={fullWidth}
      size={size}
      classes={{
        root: classes.root,
      }}
      {...rest}>
      {isLoading && <Loading />}
      {children}
    </Button>
  );
};
