import { createEvent } from '@8baselabs/react-simple-state';
import {
  User,
  TenantProfile,
  FileUploadInfoResponse,
} from '@8baselabs/resident-io-shared';

export type ChangePasswordResponse = {
  success: boolean;
  validCurrentPassword: boolean;
};

export type ChangeEmailResponse = {
  success: boolean;
  message: string;
  validCurrentEmail: boolean;
};

export const UpdateUser = createEvent<User>();
export const UpdateUserError = createEvent<Error>();
export const CreateTenantProfile = createEvent<TenantProfile>();
export const CreateTenantProfileError = createEvent<Error>();

export const UpdateTenantProfile = createEvent<TenantProfile>();
export const UpdateTenantProfileError = createEvent<Error>();
export const FetchUploadInfoEvent = createEvent<FileUploadInfoResponse>();
export const FetchUploadInfoEventError = createEvent<Error>();

export const ChangePasswordEvent = createEvent<ChangePasswordResponse>();
export const ChangePasswordError = createEvent();

export const ChangeEmailEvent = createEvent<ChangeEmailResponse>();
export const ChangeEmailError = createEvent();
