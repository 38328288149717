/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  InsertOnField,
  InsertVariablesButton,
} from '../../modules/settings-view/message-templates/components/InsertVariablesButton';
import { bracketsStrategy, HandleBrackets } from '../utils/message-decorators';

const useStyles = makeStyles(() =>
  createStyles({
    editorContent: {
      border: '1px solid #e5e5e5',
      marginBottom: '20px',
      minHeight: '200px',
      maxWidth: '710px',
    },
    previewContent: {
      border: 'none',
      minHeight: 'unset',
      maxWidth: '654px',
    },
  }),
);

interface Props {
  editorState: EditorState;
  toolbarHidden?: boolean;
  insertVariableOnField?: InsertOnField;
  onPreview?: boolean;
  onEditorStateChange?: (state: React.SetStateAction<EditorState>) => void;
  insertVariable?: (name: { label: string; value: string }) => void;
}

export const MessageEditor: React.FC<Props> = ({
  editorState,
  onEditorStateChange,
  insertVariable = () => {},
  onPreview = false,
  toolbarHidden = false,
  insertVariableOnField,
}: Props) => {
  const classes = useStyles();
  return (
    <Editor
      readOnly={onPreview}
      editorState={editorState}
      toolbar={{
        options: ['inline', 'textAlign', 'blockType'],
        inline: {
          options: ['underline', 'italic', 'bold'],
        },
        textAlign: { options: ['justify'] },
        blockType: {
          inDropdown: false,
          options: ['Blockquote'],
        },
        variables: {
          component: InsertVariablesButton,
        },
      }}
      editorClassName={
        onPreview ? classes.previewContent : classes.editorContent
      }
      toolbarHidden={toolbarHidden}
      onEditorStateChange={onEditorStateChange}
      toolbarStyle={{
        border: '1px solid #e5e5e5',
        width: '698px',
        textAlign: 'center',
        lineHeight: '18px',
        display: 'flex',
        gap: '3px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      toolbarCustomButtons={[
        <InsertVariablesButton
          insertVariable={insertVariable}
          field={insertVariableOnField}
        />,
      ]}
      customDecorators={[
        {
          strategy: bracketsStrategy,
          component: HandleBrackets,
        },
      ]}
    />
  );
};
