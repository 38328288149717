import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Checkbox,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import {
  GridCellParams,
  GridRowId,
  GridColDef,
  GridToolbarContainer,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import {
  ArrowDropDownOutlined,
  MoreVertOutlined,
  SwapVertOutlined,
} from '@material-ui/icons';
import {
  LeaseStatus,
} from '@8baselabs/resident-io-shared';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { useCallAction, useFetchAction } from '@8baselabs/react-simple-state';
import { AddCircleButton } from '../../../shared/components/ui/buttons/AddCircleButton';
import { AddOptionalPreferencesDialog } from '../components/AddOptionalPreferencesDialog';
import { EditOptionalPreferencesDialog } from '../components/EditOptionalPreferencesDialog';
import {
  //deleteLedgerByFilter,
  deleteManyLeaseOptionalPreference,
  deleteOptionalPreferenceLedger,
  getAvailableOptionalPreferences,
  getLeaseOptionalPreferences,
} from '../people-actions';
import { AlertContext } from '../../../routes/AlertContext';
import { ConfirmDeleteOPDialog } from '../components/ConfirmDeleteOPDialog';
import { rootStyleTable } from '../../../shared/utils';
import { LeaseStatusValues } from '../../opportunities/opportunities-types';
import { CustomDataGrid } from '../../../shared/components/CustomDataGrid';
import { Lease, LeasePeriod, OptionalPreference, OptionalPreferenceInvetory } from '../../../schema-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(2),
    },
    dataGrid: {
      height: 500,
    },
    root: rootStyleTable,
    row: {
      minHeight: 'fit-content !important',
    },
    cell: {
      minHeight: 'auto !important',
    },
    bulkButton: {
      fontWeight: 400,
      fontSize: 16,
      textTransform: 'none',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  }),
);

const DISABLED_ADD_STATUSES = [
  'MOVE-OUT',
  'MOVE-OUT_BALANCE_OWED',
  'MOVE-OUT_REFUND_ISSUED',
  LeaseStatusValues.FINALIZED.key,
];

const ActionsButton: React.FC<{
  params: GridCellParams;
  handleDelete: (preferenceIds: string[], leaseId: string) => void;
  handleDeleteLedger: (str: string[]) => void;
  setOpenEditDialog: (open: boolean) => void;
  setSelectedOptionalPreference: (
    selectedOptionalPreference: OptionalPreference,
  ) => void;
  setSelectedInventory: (selectedInventory: OptionalPreferenceInvetory) => void;
  setSelectedLeaseOptionalPreferenceId: (
    selectedLeaseOptionalPreferenceId: string,
  ) => void;
  setSelectedAdditionalAppData: (selectedAdditionalAppData: JSON) => void;
  loading?: boolean;
}> = ({
  params,
  handleDelete,
  handleDeleteLedger,
  setOpenEditDialog,
  setSelectedOptionalPreference,
  setSelectedLeaseOptionalPreferenceId,
  setSelectedInventory,
  setSelectedAdditionalAppData,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disableFocusRipple
        disableRipple
        aria-controls="delete-subperiod-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="delete-subperiod-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => setOpenConfirmDialog(true)}>
          {' '}
          <DeleteForeverOutlined /> Remove
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSelectedOptionalPreference(
              params.row.optionalPreference as OptionalPreference,
            );
            setSelectedInventory(
              params.row.inventory as OptionalPreferenceInvetory,
            );
            setSelectedAdditionalAppData(params.row.additionalAppData as JSON);
            setSelectedLeaseOptionalPreferenceId(params.row.id as string);
            setOpenEditDialog(true);
          }}>
          <EditIcon /> Edit
        </MenuItem>
      </Menu>
      <ConfirmDeleteOPDialog
        loading={loading || false}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onDelete={async () => {
          handleDelete([params.id as string], params.row?.leaseId || '');
        }}
      />
    </>
  );
};

const Toolbar: React.FC<{
  onAddOptionalPreference: () => void;
  handleDelete: (preferenceIds: string[], leaseId: string) => void;
  handleDeleteLedger: (str: string[]) => void;
  loading: boolean;
  setForceRenderFlag: (boolean: boolean) => void;
  selectedLease?: Lease;
  rowCount?: number;
}> = ({
  onAddOptionalPreference,
  handleDelete,
  handleDeleteLedger,
  loading,
  setForceRenderFlag,
  selectedLease,
  rowCount,
}) => {
  const classes = useStyles();
  const { state, apiRef } = useGridSlotComponentProps();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { totalRowCount, allRows } = state.rows;

  const selectedRowsMap = apiRef.current.getSelectedRows();
  const { size: selectedSize } = selectedRowsMap;

  const [optionalPreferencesResponse] = useFetchAction(
    getAvailableOptionalPreferences,
    [
      selectedLease?.property?.id as string,
      selectedLease?.leasePeriod as LeasePeriod,
      selectedLease?.id as string,
      selectedLease?.isRenewal,
    ],
    {
      skip:
        !selectedLease?.property?.id ||
        !selectedLease?.leasePeriod?.id ||
        !selectedLease?.id,
    },
  );

  const filteredOptionalPreferences = useMemo(
    () =>
      optionalPreferencesResponse?.items?.filter((op) => {
        if (
          op?.optionalPreferenceInvetoryRelation?.items &&
          op.optionalPreferenceInvetoryRelation.items.length > 0
        ) {
          return op.optionalPreferenceInvetoryRelation?.items?.some(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (inv: any) => {
              const isDenied =
                inv?.leaseOptionalPreference?.lease?.status ===
                LeaseStatus.DENIED;
              if (!inv?.isSold && (!inv?.leaseOptionalPreference || isDenied))
                return true;
              return false;
            },
          );
        }
        return true;
      }),
    [optionalPreferencesResponse],
  );

  const handleBulkClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkClose = (): void => {
    setAnchorEl(null);
  };

  const handleRemove = (): void => {
    setOpenConfirmDialog(true);
    handleBulkClose();
  };

  return (
    <GridToolbarContainer>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          <Checkbox
            checked={selectedSize === totalRowCount && !!rowCount}
            indeterminate={selectedSize > 0 && selectedSize !== totalRowCount}
            onChange={() => {
              if (apiRef.current.getSelectedRows().size === allRows?.length) {
                apiRef.current.setSelectionModel([]);
                setForceRenderFlag(false);
                return;
              }
              apiRef.current.setSelectionModel(allRows);
              setForceRenderFlag(true);
            }}
            inputProps={{ 'aria-label': 'selected rows checkbox' }}
            color="primary"
          />
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.bulkButton}
            onClick={handleBulkClick}>
            Bulk actions <ArrowDropDownOutlined />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}>
            <MenuItem
              disabled={!Array.from(selectedRowsMap?.keys() || []).length}
              onClick={handleRemove}>
              Remove all
            </MenuItem>
          </Menu>
        </Box>
        <div
          style={{
            flexGrow: 1,
          }}
        />

        <AddCircleButton
          onClick={onAddOptionalPreference}
          disabled={
            filteredOptionalPreferences?.length === 0 ||
            DISABLED_ADD_STATUSES.includes(selectedLease?.status as LeaseStatus)
          }>
          Add Optional Preference
        </AddCircleButton>
      </Box>
      <ConfirmDeleteOPDialog
        loading={loading || false}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onDelete={() =>
          handleDelete(
            Array.from<GridRowId>(selectedRowsMap.keys()) as string[],
            selectedLease?.id || '',
          )
        }
      />
    </GridToolbarContainer>
  );
};

export const OptionalPreferences: React.FC<{ selectedLease?: Lease }> = ({
  selectedLease,
}) => {
  const alert = useContext(AlertContext);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedOptionalPreference, setSelectedOptionalPreference] =
    useState<OptionalPreference | null>(null);
  const [
    selectedLeaseOptionalPreferenceId,
    setSelectedLeaseOptionalPreferenceId,
  ] = useState<string>('');
  const [selectedInventory, setSelectedInventory] =
    useState<OptionalPreferenceInvetory | null>(null);
  const [selectedAdditionalAppData, setSelectedAdditionalAppData] =
    useState<JSON | null>(null);
  const [, setForceRenderFlag] = useState(false);
  const [leaseOptionalPreferencesList, loading, { refetch }] = useFetchAction(
    getLeaseOptionalPreferences,
    [selectedLease?.id as string],
    {
      skip: !selectedLease?.id,
    },
  );

  const [deleteManyCall, loadingDelete] = useCallAction(
    deleteManyLeaseOptionalPreference,
    {
      onCompleted() {
        refetch();
        alert({
          success: true,
          message: 'Optional Preference Removed successfully',
        });
      },
      onError() {
        alert({
          error: true,
          message: 'Failed to remove Optional Preference',
        });
      },
    },
  );
  const [deleteLedger] = useCallAction(deleteOptionalPreferenceLedger);

  const classes = useStyles();

  const columns: GridColDef[] = [
    {
      field: 'optionalPreferenceName',
      headerName: 'NAME',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return <span>{params.row.optionalPreference.name}</span>;
      },
      valueGetter(params) {
        return params.row.optionalPreference.name;
      },
    },
    {
      field: 'optionalPreferenceDescription',
      headerName: 'DESCRIPTION',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return <span>{params.row.optionalPreference.description}</span>;
      },
      valueGetter(params) {
        return params.row.optionalPreference.description;
      },
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      renderCell: (params) => (
        <ActionsButton
          params={params}
          handleDelete={deleteManyCall}
          handleDeleteLedger={deleteLedger}
          setOpenEditDialog={setOpenEditDialog}
          setSelectedOptionalPreference={setSelectedOptionalPreference}
          setSelectedLeaseOptionalPreferenceId={
            setSelectedLeaseOptionalPreferenceId
          }
          setSelectedInventory={setSelectedInventory}
          setSelectedAdditionalAppData={setSelectedAdditionalAppData}
          loading={loadingDelete || loading}
        />
      ),
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideSortIcons: true,
      filterable: false,
      sortable: false,
    },
  ];

  const count = leaseOptionalPreferencesList?.items?.length || 0;
  const rowsPerPageOptions =
    //count > 100 ? [5, 10, 20, 50, 100, count] :
    [5, 10, 20, 50, 100];

  return (
    <Box m="20px">
      <Box mt="20px" />
      <CustomDataGrid
        loading={loading || loadingDelete}
        className={classes.dataGrid}
        classes={{
          cell: classes.cell,
          row: classes.row,
          root: classes.root,
        }}
        components={{
          ColumnUnsortedIcon: SwapVertOutlined,
          Toolbar: Toolbar,
        }}
        componentsProps={{
          toolbar: {
            setForceRenderFlag: setForceRenderFlag,
            onAddOptionalPreference: () => setOpenAddDialog(true),
            loading: loadingDelete || loading,
            handleDelete: deleteManyCall,
            handleDeleteLedger: deleteLedger,
            selectedLease: selectedLease,
            rowCount: leaseOptionalPreferencesList?.items?.length || 0,
          },
        }}
        rows={
          leaseOptionalPreferencesList?.items?.map((op) => {
            return {
              optionalPreference: { ...op.optionalPreference },
              inventory: { ...op.inventory },
              additionalAppData: { ...op.additionalAppData },
              id: op.id,
              leaseId: op.lease?.id || '',
            };
          }) || []
        }
        columns={columns}
        // error={error}
        checkboxSelection
        rowsPerPageOptions={rowsPerPageOptions}
        hideFooterPagination={!count}
        autoHeight
      />
      <AddOptionalPreferencesDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        selectedLease={selectedLease as Lease}
        refetch={() => refetch()}
      />
      <EditOptionalPreferencesDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        selectedLease={selectedLease as Lease}
        selectedLeaseOptionalPreferenceId={selectedLeaseOptionalPreferenceId}
        selectedOptionalPreference={selectedOptionalPreference}
        selectedInventory={selectedInventory}
        selectedAdditionalAppData={selectedAdditionalAppData}
        refetch={() => refetch()}
      />
    </Box>
  );
};
