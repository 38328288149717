import { createEvent } from '@8baselabs/react-simple-state';

import {
  Message,
  MessageThread,
  SuccessResponse,
  Ticket,
  User,
  Property,
  Client,
  PropertyListResponse,
  InfoAvailability,
} from '@8baselabs/resident-io-shared';
import { InsertOnField } from '../settings-view/message-templates/components/InsertVariablesButton';
import { InfoAvailabilityStatus } from './inbox-types';

export const OnOpenInboxMessageModal = createEvent<{
  open: boolean;
  leaseIDs?: string[];
  fromField?: InsertOnField;
  userIDs?: string[];
  fromUser?: User;
  leaseProperty?: Property;
  isPeopleProfile?: boolean;
  reply?: {
    email: string;
    subject: string;
    name: string;
    threadId: string;
    type?: Message['type'];
  };
}>();

export const OnOpenNewTicketModal = createEvent<{
  open: boolean;
}>();

export const OnToggleTicketVisibility = createEvent<{
  show: boolean;
}>({ initialValue: { show: false } });

export const OnToggleMarketingSiteMessageVisibility = createEvent<{
  show: boolean;
}>({ initialValue: { show: false } });

export const OnOpenUpdateTicketModal = createEvent<{
  open: boolean;
  ticket?: Ticket;
}>();

export const OnOpenTicketStatusModal = createEvent<{
  open: boolean;
  ticketIds?: string[];
  status?: string;
  resolution?: string;
}>();

export const OnOpenMarketingSiteMessageModal = createEvent<{
  open: boolean;
  message?: InfoAvailability;
}>();

export const OnOpenMarketingSiteMessageStatusModal = createEvent<{
  open: boolean;
  messagesIds?: string[];
  status?: InfoAvailabilityStatus;
}>();

export const OnOpenTicketAssignedToModal = createEvent<{
  open: boolean;
  property?: Property;
  ticketIds?: string[];
}>();

export const OnGetMessages = createEvent<readonly Message[] | undefined>();
export const OnGetMessagesError = createEvent<Error>();

export const onGetThreads = createEvent<readonly MessageThread[] | undefined>();
export const OnGetThreadsError = createEvent<Error>();

export const OnCreateMessages = createEvent<Message | undefined>();
export const OnCreateMessagesError = createEvent<Error>();

export const OnGetClientEmails = createEvent<readonly User[] | undefined>();
export const OnGetClientEmailsError = createEvent<Error>();

export const OnGetPropertyUsers = createEvent<Client | undefined>();
export const OnGetPropertyUsersError = createEvent<Error>();

export const OnGetPropertyUsersByPMPO = createEvent<
  PropertyListResponse | undefined
>();
export const OnGetPropertyUsersByPMPOError = createEvent<Error>();

export const OnGetPropertyContactData = createEvent<
  readonly Property[] | undefined
>();
export const OnGetPropertyContactDataError = createEvent<Error>();

export const OnGetTickets = createEvent<readonly Ticket[] | undefined>();
export const OnGetTicketsError = createEvent<Error>();

export const onSendMessageEmailEvent = createEvent();
export const onSendMessageEmailErrorEvent = createEvent<Error>();

export const onDeleteThread = createEvent<SuccessResponse | undefined>();
export const onDeleteThreadErrorEvent = createEvent<Error>();

export const OnGetPropertiesByClient = createEvent<
  readonly Property[] | undefined
>();
export const OnGetPropertiesByClientError = createEvent<Error>();

export const OnCreateTicket = createEvent<Ticket | undefined>();
export const OnCreateTicketError = createEvent<Error>();

export const OnUpdateTicket = createEvent<Ticket>();
export const OnUpdateTicketError = createEvent<Error>();

export const OnUpdateTicketStatus = createEvent<void[]>();
export const OnUpdateTicketStatusError = createEvent<Error>();

export const OnUpdateMarketingSiteMessageStatus = createEvent<void[]>();
export const OnUpdateMarketingSiteMessageStatusError = createEvent<Error>();

export const OnUpdateTicketAssignedTo = createEvent<void[]>();
export const OnUpdateTicketAssignedToError = createEvent<Error>();

export const OnReadMessagesEvent = createEvent<void[]>();
export const OnReadMessagesError = createEvent<Error>();

export const onSetSelectedThreadId = createEvent<{
  selectedThreadId: string | undefined
}>();