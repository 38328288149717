import { isValidString } from '@8baselabs/validation-utils';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ONBOARDING_ROUTES, ROUTES, TENANT_ROUTES } from '../../routes/types';
import { getIsDocumentHidden, replaceAll } from '../utils';

/**
 * Hook to return the current active route name.
 *
 * @returns - Route Name.
 */
export const useActiveRouteName = (): string => {
  const location = useLocation();
  const [checkResult, setCheckResult] = useState('');

  useEffect(() => {
    const pathSplit = location.pathname.split('/');
    const primaryPath = replaceAll(pathSplit[pathSplit.length - 1], '-', '');
    const originalPath = pathSplit[pathSplit.length - 1];

    let newRouteName = '';

    if (pathSplit.length > 2) {
      const parentPath = pathSplit[1];
      const subRoutes = ROUTES[parentPath]?.subRoutes
        ? ROUTES[parentPath]?.subRoutes
        : TENANT_ROUTES[pathSplit[2]]?.subRoutes;

      newRouteName = subRoutes
        ? subRoutes[primaryPath]?.name || subRoutes[originalPath]?.name
        : '';
    } else {
      newRouteName = ROUTES[primaryPath]?.name
        ? ROUTES[primaryPath]?.name
        : TENANT_ROUTES[primaryPath]?.name;
    }

    setCheckResult(newRouteName);
  }, [location]);

  return checkResult;
};
/**
 * Hook to return the current active route name.
 *
 * @returns - Route Name.
 */
export const useActiveTenantRouteName = (): string => {
  const location = useLocation();

  const pathSplit = location.pathname.split('/');
  const primaryPath = pathSplit[pathSplit.length - 1];

  return TENANT_ROUTES[primaryPath]?.name ?? '';
};

export const useActiveOnboardingName = (): string => {
  const location = useLocation();
  const [routeName, setRouteName] = useState('');

  useEffect(() => {
    const pathSplit = location.pathname.split('/');
    const primaryPath = pathSplit[pathSplit.length - 1];

    let newRouteName = '';

    newRouteName = ONBOARDING_ROUTES[primaryPath]?.name;

    setRouteName(newRouteName);
  }, [location]);

  return routeName;
};
/**
 * Hook to return the current active route with the parents for Topbar breadcrumbs.
 *
 * @returns - Route Name.
 */
export const useActiveRouteAndSubRoutesName = (): Array<{
  path: string;
  name: string;
}> => {
  const location = useLocation();
  const [routesName, setCheckResult] = useState<
    Array<{
      path: string;
      name: string;
    }>
  >([]);

  useEffect(() => {
    const pathSplit = location.pathname.split('/').slice(1);

    const newRoutes: Array<{
      path: string;
      name: string;
    }> = [];

    pathSplit.forEach((path) => {
      const subRoutes = ROUTES[pathSplit[0]]?.subRoutes;

      if (ROUTES[path]?.name)
        newRoutes.push({
          name: ROUTES[path]?.name,
          path: ROUTES[path].path,
        });
      else if (subRoutes && subRoutes[path]?.name)
        newRoutes.push({
          name: subRoutes[path]?.name,
          path:
            subRoutes[path]?.path?.includes(':') || subRoutes[path]?.param
              ? ''
              : `${ROUTES[pathSplit[0]]?.path}${subRoutes[path]?.path}`,
        });
    });

    setCheckResult(newRoutes);
  }, [location]);

  return routesName;
};

/**
 * @param {string} queryString - Query string to be parsed.
 * @returns {URLSearchParams} - Params.
 */
export function useQuery(queryString?: string): URLSearchParams {
  const { search } = useLocation();
  return new URLSearchParams(isValidString(queryString) ? queryString : search);
}

export enum CheckRoleState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

/**
 * Hook to return the current active route roles to rbac.
 *
 * @param role - Role name to check.
 * @returns - Route Roles.
 */
export const useActiveRouteRoleCheck = (role: string | undefined): string =>
  CheckRoleState.SUCCESS;

/**
 * Hook to return the visibility of the current tab.
 *
 * @returns - Boolean indicating the visibility of the page.
 */
export const usePageVisibility = (): boolean => {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = (): void => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  });

  return isVisible;
};

/**
 * Hook to return wheter or not activity is being held on the current tab.
 *
 * @param timeout - The time before it is considered an inactive page.
 * @returns - Boolean indicating the activity of the page.
 */
export const usePageActivity = (timeout: number) : boolean => {
  const [activityPulse, setPulse] = React.useState(true);
  const onActivityChange = (): void => {
    setPulse(!activityPulse); // Send activity pulse (heart-rate)
    localStorage.expiredTime = Date.now() + timeout;
  }

  React.useEffect(() => {
    if (window.location.hostname === 'localhost') {
      return;
    }

    const activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];

    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, onActivityChange, true);
    });

    return () => {
      activityEvents.forEach((eventName) => {
        document.removeEventListener(eventName, onActivityChange, true);
      });
    };
  });

  return activityPulse;
};
