import Icon from '@material-ui/core/Icon';
import BarChartOutlined from '@material-ui/icons/BarChartOutlined';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import EventOutlined from '@material-ui/icons/EventOutlined';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import Flare from '@material-ui/icons/Flare';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import KingBedOutlined from '@material-ui/icons/KingBedOutlined';
import MoveToInboxOutlined from '@material-ui/icons/MoveToInboxOutlined';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import Speed from '@material-ui/icons/Speed';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import PaymentOutlined from '@material-ui/icons/PaymentOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AttachMoney from '@material-ui/icons/AttachMoney';
import FeaturedPlayListOutlined from '@material-ui/icons/FeaturedPlayListOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import ForumIcon from '@material-ui/icons/ForumOutlined';
import EmojiEmotionsOutlined from '@material-ui/icons/EmojiEmotionsOutlined';
import SchoolIcon from '@material-ui/icons/School';
import PostAddIcon from '@material-ui/icons/PostAdd';
import InstagramIcon from '@material-ui/icons/Instagram';
import AccountBoxOutlined from '@material-ui/icons/AccountBoxOutlined';
import { UserType, ClientUserType } from '@8baselabs/resident-io-shared';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SecurityIcon from '@material-ui/icons/Lock';
import CreditCardOutlined from '@material-ui/icons/CreditCardOutlined';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import GroupsOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LogoutOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import CreateIcon from '@material-ui/icons/Create';
import BusinessIcon from '@material-ui/icons/Business';
import PaymentsIcon from '@mui/icons-material/Payments';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleIcon from '@mui/icons-material/People';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AirplayIcon from '@mui/icons-material/Airplay';

type RouteType = {
  name: string;
  icon: JSX.Element;
  path: string;
  roles: string[];
  subRoutes?: {
    [key: string]: {
      name: string;
      icon: JSX.Element;
      path: string;
      param?: string;
      isSidebar?: boolean;
    };
  };
  isSidebar?: boolean;
  displayClientSelector?: boolean;
};

export type RoutesType = {
  [key: string]: RouteType;
};

export type CommonRoutesType = {
  [key: string]: Omit<RouteType, 'icon'>;
};

export type NavigationRouteType = {
  [key: string]: {
    name: string;
    path: string;
  };
};
export const ROUTES: RoutesType = {
  dashboard: {
    name: 'Dashboard',
    icon: <Speed />,
    path: '/dashboard',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  inbox: {
    name: 'Inbox',
    icon: <MoveToInboxOutlined />,
    path: '/inbox',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
    subRoutes: {
      messages: {
        name: 'Messages',
        icon: <SettingsIcon />,
        path: '/messages',
      },
      maintenance: {
        name: 'Maintenance',
        icon: <BuildOutlinedIcon />,
        path: '/maintenance',
      },
      'marketing-site-messages': {
        name: 'Marketing Site Messages',
        icon: <SettingsIcon />,
        path: '/marketing-site-messages',
      },
    },
  },
  timeline: {
    name: 'Timeline',
    icon: <KingBedOutlined />,
    path: '/timeline',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  opportunities: {
    name: 'Opportunities',
    icon: <FileCopyOutlined />,
    path: '/opportunities',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
    subRoutes: {
      addreservations: {
        name: 'Add Reservations',
        icon: <FileCopyOutlined />,
        path: '/addreservations',
        param: '/:step?',
      },
      approvereservations: {
        name: 'Approve Reservation',
        icon: <FileCopyOutlined />,
        path: '/approvereservations',
      },
    },
  },
  payments: {
    name: 'Payments',
    icon: <Icon>payments_outlined</Icon>,
    path: '/payments',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  deposits: {
    name: 'Bank Deposits',
    icon: <Flare />,
    path: '/deposits',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  checks: {
    name: 'Send Checks',
    icon: <Icon>request_quote_outlined</Icon>,
    path: '/checks',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  collections: {
    name: 'Collections',
    icon: <BookmarkBorder />,
    path: '/collections', // NOTE: Empty route
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  people: {
    name: 'People',
    icon: <PeopleAltOutlined />,
    path: '/people',
    displayClientSelector: true,
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    subRoutes: {
      profile: {
        name: 'Profile',
        icon: <PeopleAltOutlined />,
        path: '/:id',
      },
      ledger: {
        name: 'Ledger',
        icon: <PeopleAltOutlined />,
        path: '/:id/ledger',
      },
      payment: {
        name: 'Payment Method',
        icon: <CreditCardOutlined />,
        path: '/:id/payment',
      },
      autopay: {
        name: 'Autopay',
        icon: <CreditCardOutlined />,
        path: '/:id/autopay',
      },
      messages: {
        name: 'Messages',
        icon: <InsertCommentOutlinedIcon />,
        path: '/:id/messages',
      },
      activity: {
        name: 'Activity feed',
        icon: <CachedOutlinedIcon />,
        path: '/:id/activity',
      },
      notes: {
        name: 'Notes',
        icon: <InsertDriveFileOutlinedIcon />,
        path: '/:id/notes',
      },
      roommates: {
        name: 'Roommates',
        icon: <GroupsOutlinedIcon />,
        path: '/:id/roommates',
      },
      'move-out': {
        name: 'Move out',
        icon: <LogoutOutlinedIcon />,
        path: '/:id/move-out',
      },
      transfer: {
        name: 'Transfer',
        icon: <CompareArrowsOutlinedIcon />,
        path: '/:id/transfer',
      },
      application: {
        name: 'Application',
        icon: <InfoOutlinedIcon />,
        path: '/:id/aplication',
      },
      documents: {
        name: 'Documents',
        icon: <DescriptionOutlinedIcon />,
        path: '/:id/documents',
      },
      'optional-preferences': {
        name: 'Optional Preferences',
        icon: <SettingsOutlined />,
        path: '/:id/optional-preferences',
      },
      guarantor: {
        name: 'Guarantor',
        icon: <VerifiedUserOutlinedIcon />,
        path: '/:id/guarantor',
      },
    },
  },
  calendar: {
    name: 'Calendar',
    icon: <EventOutlined />,
    path: '/calendar', // NOTE: Empty route
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  reports: {
    name: 'Reports',
    icon: <BarChartOutlined />,
    path: '/reports', // NOTE: Empty route
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
  },
  settings: {
    name: 'Property Settings',
    icon: <SettingsOutlined />,
    path: '/settings',
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
    displayClientSelector: true,
    subRoutes: {
      'lease-periods': {
        name: 'Manage Lease Periods',
        icon: <SettingsOutlined />,
        path: '/lease-periods',
      },
      subperiods: {
        name: 'Manage Subperiods',
        icon: <SettingsOutlined />,
        path: '/subperiods',
      },
      buildinglayouts: {
        name: 'Building Layouts',
        icon: <SettingsOutlined />,
        path: '/buildinglayouts',
      },
      propertyinformation: {
        name: 'Property Information',
        icon: <SettingsOutlined />,
        path: '/propertyinformation',
        param: '/:id',
      },
      propertypage: {
        name: 'Property Page',
        icon: <SettingsOutlined />,
        path: '/propertypage',
        param: '/:id',
      },
      paymentplans: {
        name: 'Payment Plans',
        icon: <SettingsOutlined />,
        path: '/paymentplans',
      },
      'payment-plan-details': {
        name: 'Payment Plans',
        icon: <SettingsOutlined />,
        path: '/:id/payment-plan-details',
      },
      'optional-preferences-details': {
        name: 'Optional Preferences',
        icon: <SettingsOutlined />,
        path: '/:id/optional-preferences-details',
      },
      'optional-preferences': {
        name: 'Optional Preferences',
        icon: <SettingsOutlined />,
        path: '/optional-preferences',
      },

      assignpaymentplans: {
        name: 'Assign Payment Plans',
        icon: <SettingsOutlined />,
        path: '/assignpaymentplans',
      },
      'message-templates': {
        name: 'Message Templates',
        icon: <SettingsOutlined />,
        path: '/message-templates',
      },
      newMessageTemplates: {
        name: 'New Message Template',
        icon: <SettingsOutlined />,
        path: '/message-templates/new',
      },
      editMessageTemplates: {
        name: 'Edit Message Template',
        icon: <SettingsOutlined />,
        path: '/message-templates/edit/:id',
      },
      'site-settings': {
        name: 'Site Settings',
        icon: <SettingsOutlined />,
        path: '/site-settings',
      },
      'property-documents': {
        name: 'Property Documents',
        icon: <SettingsOutlined />,
        path: '/property-documents',
      },
      'late-fees': {
        name: 'Late Fees',
        icon: <SettingsOutlined />,
        path: '/late-fees',
      },
      'unit-room-types': {
        name: 'Unit & Room types',
        icon: <SettingsOutlined />,
        path: '/unit-room-types',
      },
      createdocument: {
        name: 'Create Document',
        icon: <SettingsOutlined />,
        path: '/property-documents/createdocument',
      },
      'create-form': {
        name: 'Create Document',
        icon: <SettingsOutlined />,
        path: '/property-documents/create-form',
      },
    },
  },
  accountsettings: {
    name: 'Account Settings',
    icon: <SettingsOutlined />,
    path: '/accountsettings',
    subRoutes: {
      info: {
        name: 'Account Information',
        icon: <AccountCircleOutlinedIcon />,
        path: '/info',
      },
      permissions: {
        name: 'Permissions',
        icon: <VpnKeyOutlinedIcon />,
        path: '/permissions',
      },
      security: {
        name: 'Sign-in & Security',
        icon: <SecurityIcon />,
        path: '/security',
      },
      notifications: {
        name: 'Notifications',
        icon: <NotificationsIcon />,
        path: '/notifications',
      },
    },
    isSidebar: false,
    roles: [
      UserType.ADMIN,
      ClientUserType.PROPERTY_OWNER,
      ClientUserType.PROPERTY_MANAGER,
    ],
  },
  ownersettings: {
    name: 'Owner Settings',
    icon: <VpnKeyOutlinedIcon />,
    path: '/ownersettings',
    roles: [ClientUserType.PROPERTY_OWNER],
    subRoutes: {
      propertyportfolios: {
        name: 'Property Portfolios',
        icon: <WorkOutlineOutlinedIcon />,
        path: '/propertyportfolios',
        isSidebar: false,
      },
      clientpage: {
        name: 'Company Page',
        icon: <AirplayIcon />,
        path: '/clientpage',
        isSidebar: false,
      },
      manageusers: {
        name: 'Manage Users',
        icon: <PeopleAltOutlined />,
        path: '/manageusers',
        isSidebar: false,
      },
      clientinformation: {
        name: 'Company Information',
        icon: <BusinessOutlinedIcon />,
        path: '/clientinformation',
        isSidebar: false,
      },
      pricing: {
        name: 'Pricing',
        icon: <AttachMoney />,
        path: '/pricing',
        isSidebar: false,
      },
      chartofaccounts: {
        name: 'Chart of Accounts',
        icon: <FeaturedPlayListOutlined />,
        path: '/chartofaccounts',
        isSidebar: false,
      },
    },
  },
  adminsettings: {
    name: 'Admin Settings',
    roles: [UserType.ADMIN],
    icon: <Icon>admin_panel_settings</Icon>,
    path: '/adminsettings',
    displayClientSelector: true,
    subRoutes: {
      manageusers: {
        name: 'Manage Users',
        icon: <PeopleAltOutlined />,
        path: '/manage-users',
        isSidebar: false,
      },
      manageclientusers: {
        name: 'Manage Client Users',
        icon: <PeopleAltOutlined />,
        path: '/manage-client-users',
        isSidebar: false,
      },
      manageadmins: {
        name: 'Manage Admins',
        icon: <PeopleAltOutlined />,
        path: '/manage-admins',
        isSidebar: false,
      },
      globalfees: {
        name: 'Global Fees',
        icon: <PaymentOutlined />,
        path: '/globalfees',
        isSidebar: false,
      },
      schools: {
        name: 'Schools',
        icon: <SchoolIcon />,
        path: '/schools',
        isSidebar: false,
      },
      schoolPage: {
        name: 'School Page',
        icon: <SchoolIcon />,
        path: '/:id/schools',
        isSidebar: false,
      },
      'latest-post': {
        name: 'Latest Post',
        icon: <InstagramIcon />,
        path: '/latest-post',
        isSidebar: false,
      },
      'student-life': {
        name: 'Student Life',
        icon: <PostAddIcon />,
        path: '/student-life',
        isSidebar: false,
      },
      'hellosign-setting': {
        name: 'Hellosign Setting',
        icon: <CreateIcon />,
        path: '/hellosign-setting',
        isSidebar: false,
      },
      properties: {
        name: 'Properties',
        icon: <BusinessIcon />,
        path: '/properties',
        isSidebar: false,
      },
      manageclients: {
        name: 'Manage Clients',
        icon: <PeopleAltOutlined />,
        path: '/manage-clients',
        isSidebar: false,
      },
    },
  },
};
export const ONBOARDING_ROUTES: CommonRoutesType = {
  createprofile: {
    name: 'Create profile',
    path: '/createprofile',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  social: {
    name: 'Social Networks',
    path: '/social',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  personality: {
    name: 'Personality Questions',
    path: '/personality',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  'about-me': {
    name: 'Describe Yourself',
    path: '/about-me',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  'update-profile': {
    name: 'Show your smile',
    path: '/update-profile',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  'personality-test': {
    name: 'Personality assessment',
    path: '/personality-test',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  'personality-results': {
    name: 'Personality results',
    path: '/personality-results',
    roles: [UserType.RESIDENT, UserType.USER],
  },
};
export const INVITATIONS_ROUTES: CommonRoutesType = {
  verifyEmail: {
    name: 'Email verification',
    path: '/verify-email',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  confirmVerifyEmail: {
    name: 'Confirm email verification',
    path: '/confirm-email',
    roles: [UserType.RESIDENT, UserType.USER],
  },
  acceptinvitation: {
    name: 'Accept pending invitations',
    path: '/acceptinvitation',
    roles: [],
  },
  'accept-terms': {
    name: 'Accept Terms And Conditions',
    path: '/accept-terms',
    roles: [],
  },
};

export const NavigationRoute: NavigationRouteType = {
  personality: {
    name: 'Personality Assessment',
    path: '/personality-assessment',
  },
  select: {
    name: 'Select unit',
    path: '/select-unit',
  },
  paymentPlan: {
    name: 'Select payment plan',
    path: '/select-payment-plan',
  },
  optional: {
    name: 'Optional Preferences',
    path: '/optional-service',
  },
  friends: {
    name: 'Friends',
    path: '/invite-friends',
  },
  application: {
    name: 'Application',
    path: '/application',
  },
  /*  documents: {
    name: 'Required documents',
    path: '/documents',
  }, */
  contract: {
    name: 'Contract',
    path: '/contract',
  },
  payment: {
    name: 'Payment',
    path: '/payment',
  },
  submission: {
    name: 'Submission Complete',
    path: '/submission-complete',
  },
};
export const TENANT_ROUTES: CommonRoutesType = {
  'personality-assessment': {
    name: 'Personality Assessment',
    path: '/resident/:id/personality-assessment',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  'select-unit': {
    name: 'Room Selection',
    path: '/resident/:id/select-unit',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  'select-payment-plan': {
    name: 'Payment Plan',
    path: '/resident/:id/select-payment-plan',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  'optional-service': {
    name: 'Preferences',
    path: '/resident/:id/optional-service',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  'invite-friends': {
    name: 'Friends',
    path: '/resident/:id/invite-friends',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  application: {
    name: 'Application',
    path: '/resident/:id/application',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  /* documents: {
    name: 'Required documents',
    path: '/resident/:id/documents',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  }, */
  contract: {
    name: 'Contract',
    path: '/resident/:id/contract',
    isSidebar: true,
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  payment: {
    name: 'Payment',
    path: '/resident/:id/payment',
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
    isSidebar: true,
  },
  'submission-complete': {
    name: 'Submission Complete',
    path: '/resident/:id/submission-complete',
    roles: [UserType.ADMIN, UserType.USER],
    isSidebar: false,
  },
  profile: {
    name: 'Account Settings',
    path: '/resident/profile',
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
    subRoutes: {
      contactinfo: {
        name: 'Contact Info',
        icon: <EmailIcon />,
        path: '/contactinfo',
        isSidebar: false,
      },
      tenanthistory: {
        name: 'Resident History',
        icon: <AccountBoxOutlined />,
        path: '/tenanthistory',
        isSidebar: false,
      },
      socialnetworks: {
        name: 'Social Networks',
        icon: <GroupWorkOutlinedIcon />,
        path: '/socialnetworks',
        isSidebar: false,
      },
      personality: {
        name: 'Personality Questions',
        icon: <ForumIcon />,
        path: '/personality',
        isSidebar: false,
      },
      traits: {
        name: 'Personality Traits',
        icon: <EmojiEmotionsOutlined />,
        path: '/traits',
        isSidebar: false,
      },
      personalitybreakdown: {
        name: 'Personality Breakdown',
        icon: <EmojiEmotionsOutlined />,
        path: '/personalitybreakdown',
        isSidebar: false,
      },
      personalitydetails: {
        name: 'Personality Blend',
        icon: <EmojiEmotionsOutlined />,
        path: '/personalitydetails',
        isSidebar: false,
      },
      /* identity: {
        name: 'Identity & Verification',
        icon: <VerifiedUserOutlinedIcon />,
        path: '/identity',
        isSidebar: false,
      }, */
      notifications: {
        name: 'Notifications',
        icon: <NotificationsIcon />,
        path: '/notifications',
        isSidebar: false,
      },
      security: {
        name: 'Login & Security',
        icon: <SecurityIcon />,
        path: '/security',
        isSidebar: false,
      },
    },
  },
  resident: {
    name: 'New Reservations',
    path: '/resident',
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  'email-preferences': {
    name: 'Login Settings',
    path: '/resident/profile/email-preferences',
    roles: [UserType.ADMIN, UserType.RESIDENT, UserType.USER],
  },
  reservation: {
    name: 'Reservation',
    path: '/resident/reservation/:id',
    roles: [UserType.ADMIN, UserType.USER],
    subRoutes: {
      ledger: {
        name: 'Ledger',
        icon: <PaymentsIcon />,
        path: '/ledger',
      },
      inbox: {
        name: 'Inbox',
        icon: <MoveToInboxIcon />,
        path: '/inbox',
      },
      roommates: {
        name: 'Roommates',
        icon: <PeopleIcon />,
        path: '/roommates',
      },
      transfer: {
        name: 'Transfer',
        icon: <CompareArrowsIcon />,
        path: '/transfer',
      },
      'reservation-application': {
        name: 'Application',
        icon: <InfoOutlinedIcon />,
        path: '/reservation-application',
      },
      'reservation-documents': {
        name: 'Documents',
        icon: <DescriptionOutlinedIcon />,
        path: '/reservation-documents',
      },
      'reservation-preferences': {
        name: 'Preferences',
        icon: <SettingsOutlined />,
        path: '/reservation-preferences',
      },
    },
  },
};

export const GUARANTOR_ROUTES: CommonRoutesType = {
  update: {
    name: 'Guarantor update',
    path: '/guarantor/update/:id',
    roles: [UserType.ADMIN, ClientUserType.GUARANTOR, UserType.USER],
  },
  review: {
    name: 'Guarantor Application',
    path: '/guarantor/review/:id',
    roles: [UserType.ADMIN, ClientUserType.GUARANTOR, UserType.USER],
  },
  invitation: {
    name: 'Guarantor update',
    path: '/guarantor/invitation',
    roles: [UserType.ADMIN, ClientUserType.GUARANTOR, UserType.USER],
  },
  noInvitation: {
    name: 'Guarantor update',
    path: '/guarantor/completed',
    roles: [UserType.ADMIN, ClientUserType.GUARANTOR, UserType.USER],
  },
};
