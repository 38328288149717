import { createEvent } from '@8baselabs/react-simple-state';
import { MessageTemplateListResponse } from '@8baselabs/resident-io-shared';
import { MessageTemplateType } from './message-template-types';

export type MessageTemplateResponse = {
  items: MessageTemplateType[];
  count: number;
};

export const onFetchTemplatesEvent = createEvent<MessageTemplateListResponse['items']>();
export const onFetchTemplatesErrorEvent = createEvent<Error>();

export const onFetchTemplateEvent = createEvent<MessageTemplateType>();
export const onFetchTemplateErrorEvent = createEvent<Error>();

export const onCreateTemplateEvent = createEvent();
export const onCreateTemplateErrorEvent = createEvent<Error>();

export const onUpdateTemplateEvent = createEvent<void>();
export const onUpdateTemplateErrorEvent = createEvent<Error>();

export const onDeleteTemplateEvent = createEvent();
export const onDeleteTemplateErrorEvent = createEvent<Error>();

export const onSendTemplateEvent = createEvent();
export const onSendTemplateErrorEvent = createEvent<Error>();
