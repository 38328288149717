import { gql } from '@apollo/client';

export const FETCH_ALL_USERS_QUERY = gql`
  query FETCH_ALL_USERS_QUERY($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        firstName
        lastName
        email
        userType
        status
        userClientUserRelation {
          items {
            id
            client {
              id
            }
            role
          }
        }
        userPropertyOwnershipRelation {
          items {
            id
            property {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_USERS = gql`
  query GET_CLIENT_USERS($first: Int, $skip: Int, $filter: UserFilter) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        status
        firstName
        lastName
        email
        userClientUserRelation {
          items {
            id

            client {
              id
              name
              clientPropertyRelation {
                items {
                  id
                  name
                }
              }
            }
            role
          }
        }
        userPropertyOwnershipRelation {
          items {
            id
            property {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const GET_USER_CLIENT_USERS = gql`
  query GET_USER_CLIENT_USERS($id: ID!) {
    user(id: $id) {
      userClientUserRelation {
        items {
          id
          property {
            items {
              name
            }
          }

          role
        }
      }
    }
  }
`;
export const GET_MANAGER = gql`
  query GET_MANAGER($id: ID!) {
    user(id: $id) {
      userClientUserRelation(filter: { role: { not_contains: "OWNER" } }) {
        items {
          id
          property {
            items {
              name
              id
            }
          }

          role
        }
      }
    }
  }
`;

export const FETCH_RESIDENT_USERS = gql`
  query FETCH_RESIDENT_USERS($first: Int!, $skip: Int, $filter: UserFilter) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        firstName
        lastName
        email
        userType
        status
        userRole
      }
    }
  }
`;

export const CREATE_ADMIN_INVITATION_MUTATION = gql`
  mutation CREATE_ADMIN_INVITATION_MUTATION($data: SendAdminInvitationInput!) {
    sendAdminInvitation(data: $data) {
      message
      success
    }
  }
`;

export const CREATE_INVITATION_RESIDENT_MUTATION = gql`
  mutation CREATE_INVITATION_RESIDENT_MUTATION(
    $data: InvitationResidentCreateInput!
  ) {
    invitationResidentCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_INVITATION_ADMIN_MUTATION = gql`
  mutation CREATE_INVITATION_ADMIN_MUTATION(
    $data: InvitationAdminCreateInput!
  ) {
    invitationAdminCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_INVITATION_PROPERTY_OWNER_MUTATION = gql`
  mutation CREATE_INVITATION_PROPERTY_OWNER_MUTATION(
    $data: InvitationPropertyOwnerCreateInput!
  ) {
    invitationPropertyOwnerCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_INVITATION_PROPERTY_MANAGER_MUTATION = gql`
  mutation CREATE_INVITATION_PROPERTY_MANAGER_MUTATION(
    $data: InvitationPropertyManagerCreateInput!
  ) {
    invitationPropertyManagerCreate(data: $data) {
      id
    }
  }
`;

export const TOGGLE_USER_STATUS_MUTATION = gql`
  mutation TOGGLE_USER_STATUS_MUTATION($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      id
    }
  }
`;
export const UPDATE_CLIENT_USER_MUTATION = gql`
  mutation UPDATE_CLIENT_USER_MUTATION($data: ClientUserUpdateInput!) {
    clientUserUpdate(data: $data) {
      id
    }
  }
`;
export const FETCH_ALL_CLIENTS_QUERY = gql`
  query FETCH_ALL_CLIENTS_QUERY {
    clientsList {
      items {
        id
        name
        clientClientUserRelation {
          items {
            user {
              id
              firstName
              email
            }
          }
        }
      }
    }
  }
`;

export const FETCH_CLIENTS_QUERY = gql`
  query FETCH_CLIENTS_QUERY($first: Int, $skip: Int) {
    clientsList(first: $first, skip: $skip) {
      count
      items {
        id
        name
        clientClientUserRelation {
          items {
            user {
              id
              firstName
              email
            }
          }
        }
      }
    }
  }
`;

export const ASSIGN_INITIAL_FEES_MUTATION = gql`
  mutation ASSIGN_INITIAL_FEES_MUTATION($data: AssignInitialFeesInput!) {
    assignInitialFees(data: $data) {
      success
      message
    }
  }
`;

export const CONFIGURE_INITIAL_FEES_MUTATION = gql`
  mutation CONFIGURE_INITIAL_FEES($id: ID!) {
    feeUpdate(
      filter: { id: $id }
      data: {
        settings: {
          create: {
            passReservationFeeToTenant: true
            passOnlineNSFChargebackFeeToTenant: true
            enableOnlineLeasingCreditCard: false
            enableOnlineLeasingInternationalCreditCard: true
            enableOnlineLeasingACHPayment: true
            enableTenantPortalCreditCard: true
            enableTenantPortalInternationalCreditCard: false
            enableTenantPortalACHPayment: true
          }
        }
      }
    ) {
      id
      updatedAt
    }
  }
`;

export const FETCH_ALL_FEES_QUERY = gql`
  query FETCH_ALL_FEES_QUERY($id: ID!) {
    fee(id: $id) {
      id
      reservationFee
      creditCardRateAmount
      creditCardRatePercentage
      intCreditCardRateAmount
      intCreditCardRatePercentage
      expiredCreditCardUpdate
      onlineAchPayment
      checkSendingDigital
      checkSendingPaper
      onlineNSFChargebackFee
      perBedHostingAndSetup
      settings {
        id
        passReservationFeeToTenant
        passOnlineNSFChargebackFeeToTenant
        enableOnlineLeasingCreditCard
        enableOnlineLeasingInternationalCreditCard
        enableOnlineLeasingACHPayment
        enableTenantPortalCreditCard
        enableTenantPortalInternationalCreditCard
        enableTenantPortalACHPayment
      }
    }
  }
`;

export const UPDATE_ALL_FEES_MUTATION = gql`
  mutation UPDATE_ALL_FEES($data: FeeUpdateInput!) {
    feeUpdate(data: $data) {
      updatedAt
    }
  }
`;

export const UPDATE_FEE_SETTINGS_MUTATION = gql`
  mutation UPDATE_FEE_SETTINGS_MUTATION($data: FeeSettingUpdateInput!) {
    feeSettingUpdate(data: $data) {
      updatedAt
    }
  }
`;

export const FETCH_ALL_SCHOOLS_QUERY = gql`
  query FETCH_ALL_SCHOOLS_QUERY($first: Int, $skip: Int) {
    schoolsList(first: $first, skip: $skip) {
      items {
        name
        id
        city
        address
        state
      }
      count
    }
  }
`;

export const DELETE_SCHOOL_MUTATION = gql`
  mutation DELETE_SCHOOL($data: SchoolDeleteInput!) {
    schoolDelete(data: $data) {
      success
    }
  }
`;

export const DELETE_CLIENTS_BY_FILTER_MUTATION = gql`
  mutation DELETE_CLIENTS_BY_FILTER($filter: ClientFilter!) {
    clientDeleteByFilter(filter: $filter, force: true) {
      success
    }
  }
`;

export const DELETE_PROPERTIES_BY_FILTER_MUTATION = gql`
  mutation DELETE_PROPERTIES_BY_FILTER($filter: PropertyFilter!) {
    propertyDeleteByFilter(filter: $filter, force: true) {
      success
    }
  }
`;

export const CREATE_SCHOOL_MUTATION = gql`
  mutation CREATE_SCHOOL($data: SchoolCreateInput!) {
    schoolCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_SCHOOL_TESTIMONIAL = gql`
  mutation CREATE_SCHOOL_TESTIMONIAL($data: SchoolTestimonialCreateInput!) {
    schoolTestimonialCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_SCHOOL_TESTIMONIAL = gql`
  mutation UPDATE_SCHOOL_TESTIMONIAL($data: SchoolTestimonialUpdateInput!) {
    schoolTestimonialUpdate(data: $data) {
      id
    }
  }
`;

export const FETCH_SCHOOL_QUERY = gql`
  query SchoolInfo($id: ID) {
    school(id: $id) {
      headerImage {
        fileId
        downloadUrl
        filename
      }
      schoolPropertyRelation {
        items {
          id
          name
        }
      }
      name
      address
      schoolTestimonials {
        items {
          id
          content
          name
          image {
            downloadUrl
          }
        }
      }
      city
      state
      zipCode
      stateCode
      zipCodeSuffix
      phoneNumber
      email
      managerLastName
      managerFirstName
      webUrl
      officeHours
      facebookUrl
      twitterUrl
      aboutYourLocation
      instagramUrl
      instagramAt
      instagramHashtag
      urlSubdomain
      customDomain
      siteDescription
      metaDescription
      siteLogo {
        fileId
        downloadUrl
        filename
      }
    }
  }
`;

export const UPDATE_SCHOOL_MUTATION = gql`
  mutation UPDATE_SCHOOL($data: SchoolUpdateInput!) {
    schoolUpdate(data: $data) {
      id
    }
  }
`;

export const CREATE_SCHOOL_PORTFOLIO = gql`
  mutation CREATE_SCHOOL_PORTFOLIO($data: SchoolPortfolioCreateInput!) {
    schoolPortfolioCreate(data: $data) {
      id
    }
  }
`;

export const GET_PROPERTY_PORTFOLIO = gql`
  query GET_PROPERTY_PORTFOLIO {
    propertyPortfoliosList {
      items {
        id
        name
        property {
          items {
            name
          }
        }
      }
    }
  }
`;

export const GET_SCHOOL_PORTFOLIO = gql`
  query GET_SCHOOL_PORTFOLIO($filter: SchoolPortfolioFilter!) {
    schoolPortfoliosList(filter: $filter) {
      count
      items {
        id
        image {
          downloadUrl
        }
        school {
          name
        }
        propertyPortfolio {
          property {
            items {
              name
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SCHOOL_PORTFOLIO = gql`
  mutation DELETE_SCHOOL_PORTFOLIO($filter: SchoolPortfolioKeyFilter!) {
    schoolPortfolioDelete(filter: $filter) {
      success
    }
  }
`;

export const CHECK_PORTFOLIO_ID = gql`
  query CHECK_PORTFOLIO_ID($filter: SchoolPortfolioFilter!) {
    schoolPortfoliosList(filter: $filter) {
      count
    }
  }
`;

export const GET_INSTAGRAM_GLOBAL_SETTING = gql`
  query GET_INSTAGRAM_GLOBAL_SETTING {
    adminSettingsList(filter: { name: { contains: "instagram" } }) {
      items {
        name
        id
        value
      }
    }
  }
`;

export const CREATE_GLOBAL_SETTING = gql`
  mutation CREATE_GLOBAL_SETTING($data: [AdminSettingCreateManyInput]!) {
    adminSettingCreateMany(data: $data) {
      count
      items {
        id
        name
        value
      }
    }
  }
`;

export const UPDATE_GLOBAL_SETTING = gql`
  mutation UPDATE_GLOBAL_SETTING(
    $data: AdminSettingUpdateByFilterInput!
    $filter: AdminSettingFilter
  ) {
    __typename
    adminSettingUpdateByFilter(filter: $filter, data: $data) {
      count
      items {
        id
        name
      }
    }
  }
`;

export const FETCH_ALL_STUDENT_LIFE_QUERY = gql`
  query FETCH_ALL_STUDENT_LIFE_QUERY($first: Int, $skip: Int) {
    studentLivesList(skip: $skip, first: $first) {
      items {
        id
        link
        profileName
        profilePhoto {
          filename
          fileId
          downloadUrl
        }
        title
        coverPhoto {
          filename
          fileId
          downloadUrl
        }
      }
    }
  }
`;

export const CREATE_STUDENT_LIFE_MUTATION = gql`
  mutation CREATE_STUDENT_LIFE_MUTATION($data: StudentLifeCreateInput!) {
    studentLifeCreate(data: $data) {
      id
    }
  }
`;

export const DELETE_STUDENT_LIFE_MUTATION = gql`
  mutation DELETE_STUDENT_LIFE($data: StudentLifeDeleteInput!) {
    studentLifeDelete(data: $data) {
      success
    }
  }
`;

export const UPDATE_STUDENT_LIFE_MUTATION = gql`
  mutation UPDATE_STUDENT_LIFE($data: StudentLifeUpdateInput!) {
    studentLifeUpdate(data: $data) {
      id
    }
  }
`;

export const GET_HELLOSIGN_SETTING = gql`
  query GET_HELLOSIGN_SETTING {
    adminSettingsList(filter: { name: { contains: "HELLOSIGN" } }) {
      items {
        name
        id
        value
      }
    }
  }
`;

export const GET_PROPERTIES_BY_CLIENT = gql`
  query GET_PROPERTIES_BY_CLIENT($filter: PropertyFilter, $first: Int, $skip: Int) {
    propertiesList(filter: $filter, first: $first, skip: $skip) {
      items {
        id
        name
        street
        type
        city
        featured
        school {
          id
          name
        }
      }
      count
    }
  }
`;

export const UPDATE_FEATURE_PROPERTY = gql`
  mutation UPDATE_FEATURE_PROPERTY($data: PropertyUpdateInput!) {
    propertyUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_SCHOOL_PROPERTY = gql`
  mutation UPDATE_SCHOOL_PROPERTY($data: PropertyUpdateInput!) {
    propertyUpdate(data: $data) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($data: UserDeleteInput!) {
    userDelete(data: $data, force: true) {
      success
    }
  }
`;
export const FETCH_CLIENT_USER_BY_PROPERTY = gql`
  query FETCH_CLIENT_USER_BY_PROPERTY($id: ID!) {
    property(id: $id) {
      clientUser {
        items {
          id
          role
          client {
            id
          }
          user {
            firstName
            lastName
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_PROPERTY_CLIENT_USER = gql`
  mutation UPDATE_PROPERTY_CLIENT_USER($data: PropertyUpdateInput!) {
    propertyUpdate(data: $data) {
      id
      name
      clientUser {
        items {
          id
          property {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CLIENT_USER = gql`
  mutation CREATE_CLIENT_USER($data: ClientUserCreateInput!) {
    clientUserCreate(data: $data) {
      id
      role
    }
  }
`;

export const DISCONNECT_CLIENT_USER = gql`
  mutation DISCONNECT_CLIENT_USER($data: PropertyUpdateInput!) {
    propertyUpdate(data: $data) {
      id
      name
      clientUser {
        items {
          id
          property {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CLIENT_MUTATION = gql`
  mutation CREATE_CLIENT_MUTATION($data: ClientCreateInput!) {
    clientCreate(data: $data) {
      id
    }
  }
`;
