import {
  User,
  FetchInvitationResult,
  TermAndConditionListResponse,
  SystemConfigListResponse,
} from '@8baselabs/resident-io-shared';

export type UserPick = Pick<
  User,
  | 'id'
  | 'email'
  | 'avatar'
  | 'taxID'
  | 'homePhone'
  | 'userClientUserRelation'
  | 'firstName'
  | 'preferredName'
  | 'middleName'
  | 'lastName'
  | 'gender'
  | 'genderIdentify'
  | 'aboutMe'
  | 'address'
  | 'userTermsAndConditionsUserRelation'
  | 'phoneNumber'
  | 'emergencyPhoneNumber'
  | 'birthdate'
  | 'school'
  | 'state'
  | 'registerFinish'
  | 'timezone'
  | 'lang'
  | 'userType'
  | 'userTenantProfileRelation'
  | 'guarantorData'
  | 'guarantorDocuments'
  | 'selectedClient'
  | 'userUserDocumentRelation'
  | 'stripeCustomerId'
  | 'traitifyAssessmentID'
  | 'traitifyAssessmentResults'
  | 'userRole'
>;
export interface FetchSessionData {
  user: UserPick;
  fetchInvitation: FetchInvitationResult;
  termAndConditionsList: TermAndConditionListResponse;
  systemConfigsList: SystemConfigListResponse;
}

type Userskeys = keyof UserPick;
export interface SessionSubscription {
  Users: {
    mutation?: string;
    updatedFields: Userskeys[];
    node: UserPick;
  };
}

export const SENDGRID_DEFAULT_CC_RECEIVER = 'SENDGRID_DEFAULT_CC_RECEIVER';
