import { createAction } from '@8baselabs/react-simple-state';
import { LeaseFilter, Query, UserFilter } from '@8baselabs/resident-io-shared';

import { apolloClient as client } from '../../shared/apollo';
import {
  fetchLeasesError,
  fetchLeasesEvent,
  fetchUserResidentsError,
  fetchUserResidentsEvent,
} from './search-events';
import { FETCH_LEASES_USERS } from './search-queries';
import {FETCH_RESIDENT_USERS} from "../admin/admin-queries";

export const getUsers = createAction(
  fetchUserResidentsEvent,
  fetchUserResidentsError,
  async (search = '') => {
    const searchFilter: UserFilter = {
      OR: [
        {
          email: {
            contains: search,
          },
        },
        {
          firstName: {
            contains: search,
          },
        },
        {
          lastName: {
            contains: search,
          },
        },
        {
          address: {
            contains: search,
          },
        },
      ],
    };

    const response = await client.query<Query>({
      query: FETCH_RESIDENT_USERS,
      variables: {
        filter: {
          ...searchFilter,
          userType: { equals: 'USER' },
        },
      },
    });

    return response.data.usersList;
  },
);

export const getLeasesUsers = createAction(
  fetchLeasesEvent,
  fetchLeasesError,
  async (clientID: string, search = '') => {
    if (search === '') return { items: [] };

    const searchFilter: LeaseFilter = {
      property: {
        client: {
          id: {
            equals: clientID,
          },
        },
      },
      userResident: {
        AND: [
          {
            OR: [
              {
                firstName: {
                  contains: search,
                },
              },
              {
                lastName: {
                  contains: search,
                },
              },
              {
                email: {
                  contains: search,
                },
              },
              {
                address: {
                  contains: search,
                },
              },
              {
                phoneNumber: {
                  contains: search,
                },
              },
              {
                fullName: {
                  contains: search,
                },
              },
            ],
          },
          {
            userType: {
              equals: 'USER',
            },
          },
        ],
      },
    };

    const response = await client.query<Query>({
      query: FETCH_LEASES_USERS,
      variables: {
        filter: searchFilter,
        sort: { userResident: { firstName: 'ASC' } },
      },
    });

    const leases = response.data.leasesList;

    return leases;
  },
);
