import Button, { ButtonProps } from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';

export const WrapperAddCircleButton = styled(Button)({
  textTransform: 'none',
  '& svg': {
    marginRight: 5,
  },
});

export const AddCircleButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => (
  <WrapperAddCircleButton
    {...props}
    color="primary"
    startIcon={<AddCircleOutlineOutlined />}
    size="small">
    {children}
  </WrapperAddCircleButton>
);
