import React, { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';
import {
  UserType,
  ClientUserType,
  OboardingStep,
  UserRoleType,
} from '@8baselabs/resident-io-shared';
import { MainLoader } from '../../shared/components/MainLoader';
import { useSetupSession } from './session-hooks';
import { INVITATIONS_ROUTES, ONBOARDING_ROUTES } from '../../routes/types';
import { CheckRoleState, useActiveRouteRoleCheck } from '../../shared/hooks';
import {
  assignInitialFees,
  configureInitialFees,
} from '../admin/admin-actions';
import { ErrorView } from '../../shared/components/ErrorView';
import { useLocalStorage } from '../../shared/components/components-hooks';
import {
  OnAcceptGuarantorInvitation,
  OnAcceptInvitation,
} from '../invitation/invitation-events';
import { SessionRedirecting } from './session-events';
import { apolloClient } from '../../shared/apollo';
import { useQuery } from '@apollo/client';
import { FETCH_SESSION_QUERY } from './session-queries';


type SessionProps = {
  children: React.ReactNode;
};

/**
 * @param {object} props - Props.
 * @param {JSX.Element}  props.children - Children.
 * @returns {JSX.Element} - Session component.
 */
export function Session({ children }: SessionProps): JSX.Element {
  // TODO: Study how to prevent error on apollo queries without token
  //       before hydrated

  const history = useHistory();
  const { isAuthenticated, isLoading, user, logout } = useAuth0();
  const [session, isFetchSession, refetch] = useSetupSession(isLoading);

  useQuery(FETCH_SESSION_QUERY, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 100000,
    onError: (error) => {
      logout();
    }
  });

  const checkPermission = useActiveRouteRoleCheck(session?.user.userType);
  const location = useLocation();
  const [redirectUrl] = useLocalStorage('redirect-url', '');
  const [, setSelectedProperty] = useLocalStorage('selected-property', '');

  const hasPendingTerms = useMemo(() => {
    const userTermsMatch =
      !!session?.termAndConditionsList?.items?.[0]?.id &&
      session?.termAndConditionsList?.items?.[0]?.id !==
        session?.user?.userTermsAndConditionsUserRelation?.items?.[0]
          ?.termsAndCondition?.id;
    if (!userTermsMatch) {
      SessionRedirecting.dispatch({ terms: true });
    } else {
      SessionRedirecting.dispatch({ terms: false });
    }
    return userTermsMatch;
  }, [session]);

  const [callAssignInitialFees, isAssigningInitialFees] =
    useCallAction(assignInitialFees);
  const [callConfigureInitialFees, isConfiguringInitialFees] =
    useCallAction(configureInitialFees);
  /* const [, isDeselectingClient] =
    useCallAction(deselectClient); */
  const pathSplit = location.pathname.split('/');
  const primaryPath = pathSplit[pathSplit.length - 1];
  // This conditions prevent the user from being redirected if the url contains this strings.
  const hasPendingInvitations = useMemo(
    () =>
      !!session?.fetchInvitation?.id &&
      !history.location.pathname.includes(
        INVITATIONS_ROUTES.acceptinvitation.path,
      ),
    [session, history],
  );

  const allowRedirect =
    !(
      hasPendingTerms &&
      history.location.pathname.includes(
        INVITATIONS_ROUTES['accept-terms'].path,
      )
    ) &&
    !history.location.pathname.includes('/blocked-user') &&
    !history.location.pathname.includes(INVITATIONS_ROUTES.verifyEmail.path) &&
    !history.location.pathname.includes(
      INVITATIONS_ROUTES.confirmVerifyEmail.path,
    ) &&
    (!ONBOARDING_ROUTES[primaryPath] ||
      (ONBOARDING_ROUTES['update-profile'] && session?.user.registerFinish)) &&
    (!history.location.pathname.includes(
      INVITATIONS_ROUTES.acceptinvitation.path,
    ) ||
      (!hasPendingInvitations &&
        history.location.pathname.includes(
          INVITATIONS_ROUTES.acceptinvitation.path,
        ))) &&
    !history.location.pathname.includes('/guarantor/review');

  if (hasPendingInvitations && allowRedirect) {
    history.push(INVITATIONS_ROUTES.acceptinvitation.path);
  }

  if (!isLoading && user && !user.email_verified && allowRedirect) {
    history.push(INVITATIONS_ROUTES.verifyEmail.path);
  }

  const isAdminPMOrPO = useMemo(
    () =>
      session?.user?.userType &&
      [
        UserRoleType.ADMIN,
        UserRoleType.PROPERTY_MANAGER,
        UserRoleType.PROPERTY_OWNER,
        UserRoleType.MAINTENANCE,
        UserRoleType.TEAM_MEMBER,
      ].includes(
        (session?.user?.userRole ||
          session?.user?.userClientUserRelation?.items?.[0]?.role ||
          session?.user?.userType) as UserRoleType,
      ),
    [session],
  );

  const isUninvitedGuarantor = useMemo(
    () =>
      session?.user?.userType &&
      [UserRoleType.GUARANTOR].includes(
        (session?.user?.userRole ||
          session?.user?.userClientUserRelation?.items?.[0]?.role ||
          session?.user?.userType) as UserRoleType,
      ),
    [session],
  );

  useSubscription(OnAcceptInvitation, async (res) => {
    if (res) {
      await apolloClient.cache.reset();
      refetch();
    }
  });
  useSubscription(OnAcceptGuarantorInvitation, (res) => {
    if (res) {
      if (res.status !== 'REJECTED') {
        history.push(`/guarantor/review/${res.id}`);
      } else {
        refetch();
      }
    }
  });

  /*********************************************************************************
   ** Listen for session being closed in another window so we log out in this one **
   *********************************************************************************/

  React.useEffect(() => {
    const onLogoutOnOtherWindow = (event): void => {
      if (event.key === 'loggedIn' && event.newValue === 'false') {
        logout({ localOnly: true });
      }
    };

    window.addEventListener('storage', onLogoutOnOtherWindow, false);

    return () => {
      document.removeEventListener('storage', onLogoutOnOtherWindow);
    };
  });

  if (!isLoading && !isAuthenticated && allowRedirect) {
    return (
      <Redirect
        to={{
          pathname: '/auth',
          state: {
            returnTo: location.pathname !== '/' ? location : undefined,
          },
        }}
      />
    );
  }

  if (isAuthenticated) {
    localStorage.setItem('loggedIn', 'true');
    localStorage.setItem('blockedUser', 'false');
  }

  if (
    isFetchSession ||
    isAssigningInitialFees ||
    isConfiguringInitialFees ||
    checkPermission === CheckRoleState.LOADING
  ) {
    return <MainLoader />;
  }
  if (checkPermission === CheckRoleState.ERROR) {
    return <ErrorView />;
  }

  if (
    isUninvitedGuarantor &&
    !hasPendingInvitations &&
    primaryPath !== 'completed'
  ) {
    history.push('/guarantor/completed');
  }

  if (
    !isLoading &&
    redirectUrl !== '' &&
    session &&
    !session?.fetchInvitation &&
    session?.user.registerFinish &&
    allowRedirect
  ) {
    history.push(redirectUrl);
  }

  if (
    !isLoading &&
    session &&
    !isUninvitedGuarantor &&
    !isAdminPMOrPO &&
    !session?.fetchInvitation &&
    !session?.user.registerFinish &&
    allowRedirect
  ) {
    if (session?.user.userTenantProfileRelation?.id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switch (session.user.userTenantProfileRelation.step) {
        case OboardingStep.SOCIAL:
          history.push(ONBOARDING_ROUTES.personality.path);
          break;
        case OboardingStep.PERSONALITY:
          history.push(ONBOARDING_ROUTES['about-me'].path);
          break;
        case OboardingStep.ABOUT:
          history.push(ONBOARDING_ROUTES['update-profile'].path);
          break;
        case OboardingStep.PROFILE:
          history.push(ONBOARDING_ROUTES['personality-test'].path);
          break;
        case OboardingStep.ASSESSMENT:
          history.push(ONBOARDING_ROUTES['personality-results'].path);
          break;
        default:
          history.push(ONBOARDING_ROUTES.social.path);
          break;
      }
    } else {
      history.push(ONBOARDING_ROUTES.createprofile.path);
    }
  }

  if (
    session?.user?.userType !== UserType.ADMIN &&
    !!session?.user?.selectedClient?.id
  ) {
    setSelectedProperty('');
  }

  // Some already existing users don't have a fee configured, this
  // switch statement calls the `assignInitialFees` resolver whenever
  // an admin/owner logs into the system and doesn't have a fee
  // assigned.
  if (session) {
    switch (session?.user?.userClientUserRelation?.items?.[0]?.role) {
      case ClientUserType.PROPERTY_OWNER:
        if (
          !session?.user.userClientUserRelation?.items?.[0].client?.clientFee
            ?.id &&
          !localStorage.getItem('feeCreated')
        ) {
          callAssignInitialFees({
            id: session?.user.userClientUserRelation?.items?.[0].client?.id,
          });
          localStorage.setItem('feeCreated', 'true');
        }
        if (
          !session?.user.userClientUserRelation?.items?.[0].client?.clientFee
            ?.settings?.id &&
          !localStorage.getItem('feeConfigured')
        ) {
          callConfigureInitialFees(
            session?.user.userClientUserRelation?.items?.[0].client?.clientFee
              ?.id,
          );
          localStorage.setItem('feeConfigured', 'true');
        }
        break;
      default:
        break;
    }
  }

  if (hasPendingTerms && allowRedirect) history.push('/accept-terms');
  return <>{children}</>;
}
