/* eslint-disable */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { useEvent } from '@8baselabs/react-simple-state';
import { UserType } from '@8baselabs/resident-io-shared';

import { DRAWER_WIDTH } from '../constants';
import { ROUTES } from '../../routes/types';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../theme';
import { OnFetchSession } from '../../modules/session/session-events';
import { ClientSelector } from './ClientSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      transition: 'width 0.35s',
    },
    drawerPaper: {
      boxShadow: '5px 5px 20px rgb(171 171 171 / 17%)',
      transition: 'width 0.35s',
      borderRight: '0px',
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    toolbar: {
      justifyContent: 'center',
      '& img': {
        width: '100%',
      },
    },
    drawerLink: {
      color: 'inherit',
      textDecoration: 'none',
      '::focus': {
        color: 'inherit',
      },
    },
    drawerListItem: {
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
    },
    drawerListItemIcon: {
      minWidth: 35,
    },
    listItemIcon: {
      color: PRIMARY_TEXT_COLOR,
    },
    selectedListItem: {
      color: '#fff !important',
      backgroundColor: `${PRIMARY_COLOR} !important`,
      '& > .MuiListItemIcon-root': {
        color: '#fff !important',
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    select: {
      '& .MuiOutlinedInput-input': {
        padding: '8px !important',
      },
    },
  }),
);

type SidebarProps = {
  isOpen: boolean;
};

const LinkContainer: React.FC<{ item: string }> = ({ item, children }) => {
  const classes = useStyles();

  if (item === 'inbox')
    return (
      <Link
        to={`${ROUTES[item].path}${ROUTES[item].subRoutes?.messages.path}`}
        className={classes.drawerLink}>
        {children}
      </Link>
    );
  return (
    <Link
      to={
        item === 'adminsettings' || item === 'ownersettings'
          ? `${ROUTES[item].path}${
              ROUTES[item].subRoutes?.[
                `${
                  item === 'adminsettings' ? 'manageusers' : 'clientinformation'
                }`
              ].path
            }`
          : ROUTES[item].path
      }
      className={classes.drawerLink}>
      {children}
    </Link>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({ isOpen }) => {
  const classes = useStyles();
  const session = useEvent(OnFetchSession);

  const { pathname } = useLocation();

  return (
    <Drawer
      className={
        isOpen ? `${classes.drawer} ${classes.drawerOpen}` : classes.drawer
      }
      variant="persistent"
      open={isOpen}
      classes={{
        paper: isOpen
          ? `${classes.drawerPaper} ${classes.drawerOpen}`
          : classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <Toolbar className={classes.toolbar}>
        <a href="/"><img src="/assets/resident.svg" alt="Resident.io Logo" /></a>
      </Toolbar>
      <div className={classes.drawerContainer}>
        <List>
          {ROUTES[pathname?.split('/')[1]]?.displayClientSelector &&
            session?.user?.userType === UserType.ADMIN && (
              <ClientSelector noPropertySelector />
            )}
          {Object.keys(ROUTES).map((item) => {
            if (
              (ROUTES[item].isSidebar !== undefined &&
                !ROUTES[item].isSidebar) ||
              !ROUTES[item].roles.includes(
                (session?.user.userClientUserRelation?.items?.[0]?.role ||
                  session?.user.userType) ??
                  '',
              )
            ) {
              return <div key={item} />;
            }

            return (
              <LinkContainer item={item} key={item}>
                <ListItem
                  button
                  classes={{
                    selected: classes.selectedListItem,
                  }}
                  className={classes.drawerListItem}
                  selected={pathname.includes(ROUTES[item].path)}>
                  <ListItemIcon className={classes.drawerListItemIcon}>
                    {ROUTES[item].icon}
                  </ListItemIcon>
                  <ListItemText primary={ROUTES[item].name} />
                </ListItem>
              </LinkContainer>
            );
          })}
        </List>
      </div>
    </Drawer>
  );
};
