import React, { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core/styles';
import moment from 'moment';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Lease,
  Property,
  LeaseStatus as LeaseStatusEnum,
} from '@8baselabs/resident-io-shared';
import { Box } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { useLocalStorage } from '../../../shared/components/components-hooks';
import { getPropertyImage } from '../../settings-view/settings-utils';
import { LeaseStatus } from '../../../shared/components/ui/LeaseStatus';
import {
  LeaseStatusValues,
  StatusValues,
} from '../../opportunities/opportunities-types';
import { formatBedDetails } from '../../opportunities/opportunities-utils';
import {
  OnSelectProperties,
  OnLeasePeriodSelectedId,
} from '../../settings-view/settings-events';
import { useEvent } from '@8baselabs/react-simple-state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      cursor: 'pointer',
      width: 'auto',
      boxShadow: '0px 5px 21px rgb(171 171 171 / 17%)',
      borderRadius: 5,
      height: '6em',
      padding: 10,
      maxWidth: '63vw',
    },
    text: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    input: {
      minWidth: 300,
    },
    root: {
      position: 'relative',
    },
    dropdown: {
      overflowY: 'auto',
      maxHeight: '60vh',
      maxWidth: '64vw',
      position: 'absolute',
      top: '6em',
      right: 0,
      left: 0,
      zIndex: 2,
      border: `1px ${theme.palette.background}`,
      borderRadius: 3,
      padding: theme.spacing(1),
      boxShadow: '0px 0px 5px 5px rgba(0,0,0,0.1)',
      backgroundColor: theme.palette.background.paper,
      overflowWrap: 'normal',
      blockOverflow: 'normal',
    },
    optionContainer: {
      '&:hover': {
        backgroundColor: 'lightgray',
      },
      cursor: 'pointer',
      marginBottom: '10px',
    },
    moveChip: {
      height: '1.2rem',
      marginLeft: '0.5rem',
      padding: 0,
      borderRadius: '5px',
    },
  }),
);

const OptionsImage = styled('div')({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 60,
  height: 60,
  borderRadius: 3,
  marginRight: 8,
});

type PropsTypes = {
  setSelectedLease: (val: Lease | null | undefined) => void;
  selectedLease: Lease | null | undefined;
  isLoading?: boolean;
  leaseList: Lease[];
};
export const LeaseListSelector: React.FC<PropsTypes> = ({
  isLoading,
  selectedLease,
  setSelectedLease,
  leaseList,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedLocalLeaseId, setLocalStoreSelectedLeaseId] = useLocalStorage(
    'selected-lease-id',
    '',
  );
  const [localStorePropertyId, setLocalStorePropertyId] = useLocalStorage(
    'selected-property-id',
    '',
  );

  const selectedProperties =
    useEvent(OnSelectProperties) ?? localStorePropertyId;
  const selectedLeaseId =
    useEvent(OnLeasePeriodSelectedId) ?? selectedLocalLeaseId;

  useEffect(() => {
    setLocalStorePropertyId(
      localStorePropertyId ?? selectedProperties?.[0]?.id ?? '',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperties]);

  useEffect(() => {
    const defaultValue = selectedLeaseId?.leaseId;

    if (!leaseList?.length) {
      setSelectedLease(null);
      return;
    }
    if (
      selectedLease?.id === defaultValue &&
      selectedLease?.property?.id === localStorePropertyId
    )
      return;
    if (
      localStorePropertyId &&
      selectedLease?.property?.id !== localStorePropertyId
    ) {
      const lease = leaseList.filter(
        (leaseItem) => leaseItem.property?.id === localStorePropertyId,
      )[0];
      return setSelectedLease(lease);
    }

    if (defaultValue) {
      const lease = leaseList.filter((p) => p?.id === defaultValue)[0];

      if (lease) {
        setSelectedLease(lease);
      } else {
        setSelectedLease(
          leaseList.filter((p) => p?.property?.id === localStorePropertyId)[0],
        );
      }
    } else {
      OnLeasePeriodSelectedId.dispatch({
        leaseId: selectedLocalLeaseId,
      });

      setSelectedLease(
        leaseList.filter((p) => p?.id === selectedLocalLeaseId)[0],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseList, setSelectedLease, selectedLeaseId]);

  const handleClick = (): void => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = (): void => {
    setOpen(false);
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}>
      <div className={classes.root}>
        {isLoading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <>
            <Grid
              className={classes.select}
              onClick={handleClick}
              container
              justifyContent="space-between"
              alignItems="center"
              direction="row">
              <Grid item container xs={11}>
                <Grid item xs={1}>
                  <OptionsImage
                    style={{
                      paddingLeft: '8px',
                      backgroundImage: `url(${getPropertyImage(
                        selectedLease?.property as Property,
                      )})`,
                    }}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: '20px' }}>
                  <Typography variant="h6" className={classes.text}>
                    {selectedLease?.property?.name}
                  </Typography>
                  <Box display="flex" alignItems="center" width="85%">
                    <Typography variant="body2" className={classes.text}>
                      {selectedLease
                        ? `${selectedLease?.property?.city},  ${
                            selectedLease?.leasePeriod?.name || ''
                          } • ${moment(selectedLease?.startDate).format(
                            'YYYY',
                          )} - ${moment(selectedLease?.endDate).format('YYYY')}`
                        : null}
                    </Typography>
                    {selectedLease?.status === LeaseStatusEnum['MOVE-IN'] && (
                      <Chip
                        className={classes.moveChip}
                        label="Move in"
                        color="primary"
                      />
                    )}
                    {(selectedLease?.status === 'MOVE-OUT' ||
                      selectedLease?.status === 'MOVE-OUT_BALANCE_OWED' ||
                      selectedLease?.status === 'MOVE-OUT_REFUND_ISSUED') && (
                      <Chip
                        className={classes.moveChip}
                        label={
                          Object.entries(StatusValues).filter(
                            (status) => status[0] === selectedLease?.status,
                          )[0][1] ?? 'Move out'
                        }
                        color="secondary"
                      />
                    )}
                  </Box>
                </Grid>
                {selectedLease?.status && (
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%">
                      <LeaseStatus
                        status={selectedLease?.status as LeaseStatusEnum}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={1} justifyContent="center" alignItems="center">
                <IconButton>
                  <ArrowDropDownIcon />
                </IconButton>
              </Grid>
            </Grid>
            {open ? (
              <Grid container direction="row" className={classes.dropdown}>
                {leaseList &&
                  leaseList?.map((lease) => (
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      className={classes.optionContainer}
                      key={lease.id}
                      onClick={() => {
                        setSelectedLease(lease);
                        setLocalStoreSelectedLeaseId(lease.id || '');
                        OnLeasePeriodSelectedId.dispatch({
                          leaseId: lease?.id,
                        });
                        setLocalStorePropertyId(
                          (lease.property?.id as string) || '',
                        );
                        handleClickAway();
                      }}>
                      <OptionsImage
                        style={{
                          backgroundImage: `url(${getPropertyImage(
                            lease?.property,
                          )})`,
                        }}
                      />
                      <Box display="flex" flexDirection="column">
                        <Box>{lease?.property?.name}</Box>
                        <Box>{lease ? `${lease?.property?.street}` : null}</Box>
                        <Box>
                          {lease
                            ? `${lease?.property?.city},  ${
                                lease?.leasePeriod?.name || ''
                              } • ${moment(lease?.startDate).format(
                                'YYYY',
                              )} - ${moment(lease?.endDate).format('YYYY')}`
                            : null}
                        </Box>
                        <Box>
                          {formatBedDetails(lease, 'leaseSelector') + ', '}
                          {lease.bed?.code ? `Bed: ${lease.bed.code}` : null}
                          {lease?.bed?.code && lease.status ? ', ' : ''}
                          {lease?.status
                            ? `Status: ${
                                LeaseStatusValues[lease?.status].value
                              }`
                            : null}
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            ) : null}
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};
