import { createAction } from '@8baselabs/react-simple-state';
import {
  Mutation,
  Property,
} from '@8baselabs/resident-io-shared';
import { apolloClient as client } from '../../shared/apollo';
import {
  fetchEventsFromPropertyError,
  fetchEventsFromPropertyEvent,
  createEventError,
  createEventEvent,
  updateEventEvent,
  updateEventError,
  deleteEventEvent,
  deleteEventError,
} from './calendar-event';
import {
  FETCH_PROPERTY_EVENTS_QUERY,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
} from './calendar-queries';

export const fetchEventsAction = createAction(
  fetchEventsFromPropertyEvent,
  fetchEventsFromPropertyError,
  async (propertyId: string | undefined) => {
    const id = propertyId;
    const response = await client.query<{
      property: Property;
    }>({
      query: FETCH_PROPERTY_EVENTS_QUERY,
      variables: {
        id,
      },
    });
    return response.data.property;
  },
);

export const createEventAction = createAction(
  createEventEvent,
  createEventError,
  async (data: {
    propertyId: string;
    start: string;
    end: string;
    title: string;
  }) => {
    const { propertyId, start, end, title } = data;
    const response = await client.mutate<Mutation>({
      mutation: CREATE_EVENT,
      variables: {
        id: propertyId,
        start: start,
        end: end,
        title: title,
      },
    });

    //await client.cache.reset();
    return response.data?.eventCreate;
  },
);

export const updateEventAction = createAction(
  updateEventEvent,
  updateEventError,
  async (rawData: {
    propertyId: string;
    eventId: string;
    start: string;
    end: string;
    title: string;
  }) => {
    const data = {
      id: rawData.eventId,
      start: rawData.start,
      end: rawData.end,
      title: rawData.title,
      propertyEventRelation: {
        connect: { id: rawData.propertyId },
      },
    };
    const response = await client.mutate<Mutation>({
      mutation: UPDATE_EVENT,
      variables: { data },
    });

    //await client.cache.reset();

    return response.data?.eventUpdate;
  },
);

export const deleteEventAction = createAction(
  deleteEventEvent,
  deleteEventError,
  async (eventId: string) => {
    const data = {
      id: eventId,
    };

    const response = await client.mutate<Mutation>({
      mutation: DELETE_EVENT,
      variables: { data },
    });

    //await client.cache.reset();

    return response.data?.eventDelete;
  },
);
