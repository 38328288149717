import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidString } from '@8baselabs/validation-utils';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {
  AutoPayCreateInput,
  AutoPayUpdateInput,
  Property,
  User,
} from '../../../schema-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  MUTED_COLOR,
  PRIMARY_COLOR,
  CAPTION_TEXT_COLOR,
  ERROR_COLOR,
} from '../../../shared/theme';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { PayInFullTextField } from '../components/PayInFullTextField';
import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';
import { createAutoPayAction, updateAutoPayAction } from '../people-actions';
import moment from 'moment';
import { PdfViewer } from '../../../shared/components/ui/PdfViewer';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PaymentMethodSelector } from './LedgerCreateUpdateDialog/PaymentMethodSelector';
import { MainLoader } from '../../../shared/components/MainLoader';
import {
  OnGetBanksPaymentMethods,
  OnGetBanksPaymentMethodsError,
  OnGetCardsPaymentMethods,
  OnGetCardsPaymentMethodsError,
} from '../../user/user-events';
import { AlertContext } from '../../../routes/AlertContext';
import { Lease, LeaseSubPeriod } from '@8baselabs/resident-io-shared';

type AutoPayCreateUpdateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  begins?: string;
  expires?: string;
  payInFull?: boolean;
  chargeOn?: number;
  id?: string;
  leaseSubPeriod: LeaseSubPeriod | undefined;
  lease: Lease | undefined;
  amount?: number;
  status?: boolean;
};

export type AutoPayCreateUpdateDialogState = {
  paymentMethodId: string;
  begins?: Date;
  expires?: Date;
  payInFull: boolean;
  chargeOn?: number;
  id?: string;
  amount?: number;
  status: boolean;
  leaseSubPeriod?: string | undefined;
  acceptedTermsAndConditions: boolean;
};

export type ErrorStateType = {
  paymentMethodId: boolean;
  begins: boolean;
  expires: boolean;
  payInFull: boolean;
  chargeOn: boolean;
  amount: boolean;
  acceptedTermsAndConditions: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    termsAndConditionsCheck: {
      display: 'flex',
    },
    termsAndConditionsText: {
      lineHeight: 2.6,
    },
    openTermsAndConditions: {
      color: PRIMARY_COLOR,
      '&:hover': {
        cursor: 'pointer',
        color: CAPTION_TEXT_COLOR,
      },
    },
    termsAndConditionsHelperText: {
      color: ERROR_COLOR,
    },
    termsAndConditionsPdf: {
      padding: '20px 0',
    },
  }),
);

const stateSetter = (isEditing, data): AutoPayCreateUpdateDialogState => {
  if (isEditing) {
    return {
      paymentMethodId: data.paymentMethodId,
      begins: new Date(`${data.begins}T00:00:00`),
      expires: new Date(`${data.expires}T00:00:00`),
      payInFull: data.payInFull,
      chargeOn: data.chargeOn,
      amount: data.amount,
      status: data.status,
      leaseSubPeriod: data.leaseSubPeriod?.id,
      acceptedTermsAndConditions: false,
    };
  } else {
    return {
      paymentMethodId: '',
      begins: new Date(),
      expires: new Date(),
      payInFull: false,
      chargeOn: 1,
      status: true,
      acceptedTermsAndConditions: false,
    };
  }
};

const initialStateErrors = {
  paymentMethodId: false,
  begins: false,
  expires: false,
  payInFull: false,
  chargeOn: false,
  amount: false,
  acceptedTermsAndConditions: false,
};

export const AutoPayCreateUpdateDialog: React.FC<
  AutoPayCreateUpdateDialogProps
> = (props) => {
  const { isOpen, onClose, id, lease, ...defaultData } = props;

  const classes = useStyles();
  const isEditing = isValidString(id);

  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);

  const [state, setState] = useState<AutoPayCreateUpdateDialogState>(
    stateSetter(isEditing, defaultData),
  );
  const [stateErrors, setStateErrors] =
    useState<ErrorStateType>(initialStateErrors);

  const [paymentMethodId, setPaymentMethodId] = useState(state.paymentMethodId);
  const [loadingListBankPaymentMethods, setLoadingListBankPaymentMethods] =
    useState(true);
  const [loadingListCardPaymentMethods, setLoadingListCardPaymentMethods] =
    useState(true);

  useSubscription(OnGetCardsPaymentMethods, () =>
    setLoadingListCardPaymentMethods(false),
  );
  useSubscription(OnGetCardsPaymentMethodsError, () =>
    setLoadingListCardPaymentMethods(false),
  );
  useSubscription(OnGetBanksPaymentMethods, () =>
    setLoadingListBankPaymentMethods(false),
  );
  useSubscription(OnGetBanksPaymentMethodsError, () =>
    setLoadingListBankPaymentMethods(false),
  );

  const alert = useContext(AlertContext);

  const resetForm = (): void => {
    setState(stateSetter(false, undefined));
  };

  const [createAutoPayCall, loadingCreate] = useCallAction(
    createAutoPayAction,
    {
      onCompleted() {
        resetForm();
        onClose();
      },
    },
  );

  const [editAutoPayCall, loadingEdit] = useCallAction(updateAutoPayAction, {
    onCompleted() {
      resetForm();
      onClose();
    },
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      paymentMethodId: paymentMethodId,
    }));
  }, [paymentMethodId]);

  const handleSubmitCreate = (): void => {
    setStateErrors(initialStateErrors);
    let hasErrors = false;

    if (!state.paymentMethodId) {
      setStateErrors((prevErrors) => ({
        ...prevErrors,
        paymentMethodId: true,
      }));
      hasErrors = true;
      alert({
        error: true,
        message: 'Please select a payment method',
      });
    }

    if (!state.payInFull && !state.amount) {
      setStateErrors((prevErrors) => ({
        ...prevErrors,
        payInFull: true,
        amount: true,
      }));
      hasErrors = true;
    }
    if (!state.begins || state.begins.toString() === 'Invalid Date') {
      setStateErrors((prevErrors) => ({ ...prevErrors, begins: true }));
      hasErrors = true;
    }
    if (!state.expires || state.expires.toString() === 'Invalid Date') {
      hasErrors = true;
      setStateErrors((prevErrors) => ({ ...prevErrors, expires: true }));
    }
    if (moment(state.begins).isSameOrAfter(moment(state.expires))) {
      hasErrors = true;
      setStateErrors((prevErrors) => ({
        ...prevErrors,
        begins: true,
        expires: true,
      }));
    }
    if (state.amount && Number.isNaN(Number(state.amount))) {
      hasErrors = true;
      setStateErrors((prevErrors) => ({ ...prevErrors, amount: true }));
    }

    if (!state.acceptedTermsAndConditions) {
      hasErrors = true;
      setStateErrors((prevErrors) => ({
        ...prevErrors,
        acceptedTermsAndConditions: true,
      }));
    }

    if (hasErrors) {
      return;
    }

    if (isEditing) {
      editAutoPayCall({
        id: id,
        amount: state.amount,
        begins: moment(state.begins).format('YYYY-MM-DD') ?? '',
        expires: moment(state.expires).format('YYYY-MM-DD') ?? '',
        payInFull: state.payInFull,
        chargeOn: state.chargeOn,
        paymentMethodId: state.paymentMethodId,
      } as AutoPayUpdateInput);
    } else {
      createAutoPayCall({
        amount: state.amount,
        begins: moment(state.begins).format('YYYY-MM-DD') ?? '',
        expires: moment(state.expires).format('YYYY-MM-DD') ?? '',
        payInFull: state.payInFull,
        chargeOn: state.chargeOn,
        paymentMethodId: state.paymentMethodId,
        lease: { connect: { id: lease?.id } },
        // leaseSubPeriod: isValidString(state?.leaseSubPeriod)
        //   ? { connect: { id: state.leaseSubPeriod } }
        //   : undefined,
      } as AutoPayCreateInput);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        resetForm();
        onClose();
      }}
      maxWidth="md"
      fullWidth>
      <DialogTitle>{isEditing ? 'Edit AutoPay' : 'Add AutoPay'}</DialogTitle>
      <DialogContent dividers>
        {!lease?.userResident || !lease?.property ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainLoader></MainLoader>
            </Grid>{' '}
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PaymentMethodSelector
                  user={lease?.userResident as User}
                  fromAutoPay={true}
                  property={lease.property as Property}
                  setPaymentMethodId={setPaymentMethodId}
                  paymentMethodId={paymentMethodId}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  clearable
                  fullWidth
                  disablePast
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="BEGINS DATE"
                  format="MM-dd-yyyy"
                  name="begins"
                  onChange={(_, value) => {
                    setStateErrors(initialStateErrors);
                    setState((prevState) => ({
                      ...prevState,
                      begins: new Date(`${value}`),
                    }));
                  }}
                  value={state.begins}
                  InputAdornmentProps={{
                    style: {
                      borderLeft: `1px solid ${MUTED_COLOR}`,
                      height: 'auto',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={stateErrors.begins}
                  helperText={
                    stateErrors.begins &&
                    'This field is required and should be before the expire date.'
                  }
                  disabled={isEditing}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  clearable
                  fullWidth
                  disablePast
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="EXPIRES DATE"
                  format="MM-dd-yyyy"
                  name="expires"
                  onChange={(_, value) => {
                    setStateErrors(initialStateErrors);
                    setState((prevState) => ({
                      ...prevState,
                      expires: new Date(`${value}`),
                    }));
                  }}
                  value={state.expires}
                  InputAdornmentProps={{
                    style: {
                      borderLeft: `1px solid ${MUTED_COLOR}`,
                      height: 'auto',
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={stateErrors.expires}
                  helperText={
                    stateErrors.expires &&
                    'This field is required and should be after the begin date.'
                  }
                  disabled={isEditing}
                />
              </Grid>
              <PayInFullTextField
                amount={state.amount || 0}
                payInFull={state.payInFull}
                setState={setState}
                setStateErrors={setStateErrors}
                stateErrors={stateErrors}
                initialStateErrors={initialStateErrors}
              />
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder={'Enter day'}
                  label={`WITHDRAW FUNDS ON DAY ${
                    state.chargeOn || '_'
                  } OF THE MONTH`}
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 10,
                      onKeyDown: (event) => {
                        event.preventDefault();
                      },
                    },
                  }}
                  value={state.chargeOn}
                  onChange={(event) => {
                    setStateErrors(initialStateErrors);
                    setState((prevState) => ({
                      ...prevState,
                      chargeOn: parseInt(event.target.value, 10),
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.termsAndConditionsCheck}>
                <Checkbox
                  checked={state.acceptedTermsAndConditions}
                  disabled={state.acceptedTermsAndConditions}
                  onChange={() => {
                    setStateErrors(initialStateErrors);
                    setState((prevState) => ({
                      ...prevState,
                      acceptedTermsAndConditions: true,
                    }));
                  }}></Checkbox>
                <Typography
                  variant={'body2'}
                  className={classes.termsAndConditionsText}>
                  I agree to the
                  <Typography
                    onClick={() => {
                      setOpenTermsAndConditions(!openTermsAndConditions);
                    }}
                    variant={'body2'}
                    display={'inline'}
                    className={classes.openTermsAndConditions}>
                    {' '}
                    terms and conditions
                  </Typography>
                </Typography>
              </Grid>
              {stateErrors.acceptedTermsAndConditions && (
                <Grid
                  item
                  xs={12}
                  className={classes.termsAndConditionsHelperText}>
                  <Typography
                    variant={'body2'}
                    className={classes.termsAndConditionsText}>
                    You need to accept the terms and conditions before creating
                    an AutoPay.
                  </Typography>
                </Grid>
              )}
            </Grid>
            {openTermsAndConditions && (
              <Grid item xs={12} className={classes.termsAndConditionsPdf}>
                <PdfViewer
                  boxShadow=""
                  height="500px"
                  overflow-y="scroll"
                  width="100%"
                  pdfUrl={
                    'https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf'
                  }
                  title={'AutoPay Terms and Conditions'}
                />
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}>
          Cancel
        </Button>
        <ButtonAction
          fullWidth={false}
          disabled={!paymentMethodId}
          onClick={handleSubmitCreate}
          isLoading={
            loadingCreate ||
            loadingEdit ||
            loadingListBankPaymentMethods ||
            loadingListCardPaymentMethods
          }>
          {isEditing ? 'EDIT AUTOPAY' : 'ADD AUTOPAY'}
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
