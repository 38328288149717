import { gql } from '@apollo/client';

export const GET_MESSAGES = gql`
  query GET_MESSAGES($filter: MessageFilter!) {
    messagesList(filter: $filter) {
      items {
        id
        from {
          firstName
          lastName
          email
          avatar {
            downloadUrl
          }
        }
        to {
          firstName
          lastName
          email
          avatar {
            downloadUrl
          }
        }
        type
        content
        createdAt
        isRead
        messageAttachmentMessageRelation {
          items {
            file {
              filename
              downloadUrl
            }
          }
        }
        messageThread {
          id
          subject
        }
      }
    }
  }
`;

export const GET_MESSAGES_BY_THREAD_ID = gql`
  query GET_MESSAGES_BY_THREAD_ID($filter: MessageFilter!) {
    messagesList(filter: $filter) {
      items {
        id
        from {
          firstName
          lastName
          id
          email
          avatar {
            downloadUrl
          }
        }
        to {
          firstName
          lastName
          id
          email
          avatar {
            downloadUrl
          }
        }
        fromProperty {
          name
          email
          id
          primaryPropertyImage {
            downloadUrl
          }
          state
          street
          headerImage {
            downloadUrl
          }
          propertyImagePropertyRelation {
            items {
              id
              file {
                downloadUrl
                fileId
              }
              caption
              position
              type
            }
          }
        }
        toProperty {
          name
          email
          id
          state
          street
          primaryPropertyImage {
            downloadUrl
          }
          headerImage {
            downloadUrl
          }
        }
        type
        content
        isRead
        createdAt
        messageAttachmentMessageRelation {
          items {
            file {
              filename
              downloadUrl
            }
          }
        }
        messageThread {
          id
          subject
        }
      }
    }
  }
`;

export const GET_THREADS = gql`
  query GET_THREADS($filter: MessageThreadFilter!) {
    messageThreadsList(filter: $filter) {
      items {
        id
        fromUser {
          id
          firstName
          lastName
          email
        }
        toUser {
          id
          firstName
          lastName
          email
        }
        fromProp {
          id
          email
          name
          state
          street
          primaryPropertyImage {
            downloadUrl
          }
          headerImage {
            downloadUrl
          }
        }
        toProp {
          id
          email
          name
          state
          street
          primaryPropertyImage {
            downloadUrl
          }
          headerImage {
            downloadUrl
          }
        }
        subject
        messageMessageThreadRelation(sort: { updatedAt: DESC }, first: 1) {
          items {
            id
            from {
              firstName
              lastName
              email
              avatar {
                downloadUrl
              }
            }
            to {
              firstName
              lastName
              email
              avatar {
                downloadUrl
              }
            }
            fromProperty {
              name
              email
              id
              primaryPropertyImage {
                downloadUrl
              }
              state
              street
              headerImage {
                downloadUrl
              }
              propertyImagePropertyRelation {
                items {
                  id
                  file {
                    downloadUrl
                    fileId
                  }
                  caption
                  position
                  type
                }
              }
            }
            toProperty {
              id
              email
              name
              state
              street
              primaryPropertyImage {
                downloadUrl
              }
              headerImage {
                downloadUrl
              }
            }
            type
            content
            createdAt
            isRead
            messageAttachmentMessageRelation {
              items {
                file {
                  filename
                  downloadUrl
                }
              }
            }
            messageThread {
              id
              subject
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_EMAILS = gql`
  query GET_CLIENT_EMAILS($propertyId: ID!) {
    usersList(
      filter: {
        OR: [
          {
            userPropertyOwnershipRelation: {
              some: { id: { equals: $propertyId } }
            }
          }
          {
            userClientUserRelation: {
              some: {
                client: {
                  clientPropertyRelation: {
                    some: { id: { equals: $propertyId } }
                  }
                }
              }
            }
          }
        ]
      }
    ) {
      items {
        email
        phoneNumber
        firstName
        id
        lastName
        guarantorData {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const GET_USERS_BY_CLIENT = gql`
  query GET_USERS_BY_CLIENT(
    $clientId: ID!
    $isEmail: Boolean
    $isSMS: Boolean
  ) {
    client(id: $clientId) {
      clientPropertyRelation {
        items {
          id
          propertyLeaseRelation(
            filter: {
              userResident: {
                userTenantProfileRelation: {
                  email: { equals: $isEmail }
                  sms: { equals: $isSMS }
                }
              }
            }
          ) {
            items {
              userResident {
                email
                phoneNumber
                firstName
                id
                lastName
                guarantorData {
                  firstName
                  lastName
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_USERS_BY_PM_PO = gql`
  query GET_USERS_BY_PM_PO(
    $clientUserId: ID!
    $isEmail: Boolean
    $isSMS: Boolean
  ) {
    propertiesList(
      filter: {
        clientUser: { some: { user: { id: { equals: $clientUserId } } } }
      }
    ) {
      items {
        name
        id
        propertyLeaseRelation(
          filter: {
            userResident: {
              userTenantProfileRelation: {
                email: { equals: $isEmail }
                sms: { equals: $isSMS }
              }
            }
          }
        ) {
          items {
            userResident {
              email
              phoneNumber
              firstName
              id
              lastName
              guarantorData {
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY_CONTACT_DATA = gql`
  query GET_PROPERTY_CONTACT_DATA($propertyId: ID!) {
    propertiesList(filter: { id: { equals: $propertyId } }) {
      items {
        name
        id
        email
        phone
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation CREATE_MESSAGE($data: MessageCreateInput!) {
    messageCreate(data: $data) {
      id
    }
  }
`;

export const GET_MARKETING_SITE_MESSAGES = gql`
  query GET_MARKETING_SITE_MESSAGES(
    $filter: InfoAvailabilityFilter!
    $first: Int
    $skip: Int
  ) {
    infoAvailabilitiesList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        createdAt
        name
        phone
        context
        type
        email
        status
        infoAvailabilityClientRelation {
          id
        }
        infoAvailabilityPropertyRelation {
          id
          name
          email
        }
      }
    }
  }
`;

export const GET_TICKETS = gql`
  query GET_TICKETS($filter: TicketFilter!, $first: Int, $skip: Int) {
    ticketsList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        createdAt
        property {
          id
          name
          clientUser {
            items {
              id
              user {
                email
              }
            }
          }
        }
        unit {
          id
          code
          floor {
            number
            building {
              code
            }
          }
        }
        bed {
          id
          number
        }
        requestedBy {
          id
          email
          phoneNumber
          firstName
          lastName
        }
        assignedTo {
          items {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
        subject
        category
        priority
        status
        description
        notes
        resolution
        ticketAttachmentTicketRelation {
          items {
            id
            file {
              fileId
              filename
              downloadUrl
            }
          }
        }
      }
    }
  }
`;

export const SEND_MESSAGE_EMAIL_RESOLVER = gql`
  mutation SEND_MESSAGE_EMAIL_RESOLVER($data: SendEmailResolverInput!) {
    sendEmailResolver(data: $data) {
      success
      message
    }
  }
`;

export const DELETE_THREAD = gql`
  mutation DELETE_THREAD($data: MessageThreadDeleteInput!) {
    messageThreadDelete(force: true, data: $data) {
      success
    }
  }
`;

export const GET_INBOX_PROPERTIES_BY_CLIENT = gql`
  query GET_INBOX_PROPERTIES_BY_CLIENT($clientId: ID!) {
    propertiesList(filter: { client: { id: { equals: $clientId } } }) {
      items {
        id
        name
        clientUser {
          items {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
        buildingLayout {
          building {
            items {
              buildingFloorRelation {
                items {
                  floorUnitRelation {
                    items {
                      id
                      code
                      unitRoomRelation {
                        items {
                          roomBedRelation {
                            items {
                              id
                              number
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_TICKET = gql`
  mutation CREATE_TICKET($data: TicketCreateInput!) {
    ticketCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation UPDATE_TICKET($data: TicketUpdateInput!) {
    ticketUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_MARKETING_SITE_MESSAGE_STATUS = gql`
  mutation UPDATE_MARKETING_SITE_MESSAGE_STATUS(
    $data: InfoAvailabilityUpdateInput!
  ) {
    infoAvailabilityUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_TICKET_STATUS = gql`
  mutation UPDATE_TICKET_STATUS($data: TicketUpdateInput!) {
    ticketUpdate(data: $data) {
      id
      status
    }
  }
`;

export const UPDATE_TICKET_ASSIGNED_TO = gql`
  mutation UPDATE_TICKET_ASSIGNED_TO($data: TicketUpdateInput!) {
    ticketUpdate(data: $data) {
      id
    }
  }
`;

export const READ_MESSAGE = gql`
  mutation READ_MESSAGE($data: MessageUpdateInput!) {
    messageUpdate(data: $data) {
      id
      isRead
    }
  }
`;
