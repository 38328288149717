import { createEvent } from '@8baselabs/react-simple-state';
import { ValidationError } from 'yup';
import {
  PropertyPortfolioListResponse,
  SuccessResponse,
  Client,
  ClientListResponse,
  PropertyPortfolio,
  InvitationPropertyManager,
  InvitationPropertyManagerListResponse,
  ClientUserListResponse,
  ClientUser,
  AccountListResponse,
  DefaultAccountListResponse,
  Account,
  InvitationPropertyOwner,
  InvitationPropertyOwnerListResponse,
  ClientPage,
  InvitationMaintenance,
  InvitationTeamMember,
  InvitationMaintenanceListResponse,
  InvitationTeamMemberListResponse,
} from '@8baselabs/resident-io-shared';

export const OnFetchPortfolios = createEvent<PropertyPortfolioListResponse>();
export const OnFetchPortfoliosError = createEvent<Error>();

export const OnCreatePortfolio = createEvent<PropertyPortfolio | undefined>();
export const OnCreatePortfolioError = createEvent<Error>();

export const OnDeletePortfolio = createEvent<SuccessResponse>();
export const OnDeletePortfolioError = createEvent<Error>();

export const OnUpdateClientInvitations = createEvent<void>();
export const OnUpdateClientInvitationsError = createEvent<Error>();

export const OnCreatePropertyOwnerInvitation = createEvent<
  InvitationPropertyOwner | undefined
>();
export const OnCreatePropertyOwnerInvitationError = createEvent<Error>();

export const OnEditPortfolio = createEvent<PropertyPortfolio | undefined>();
export const OnEditPortfolioError = createEvent<Error>();

export const OnFetchClient = createEvent<Client>();
export const OnFetchClientError = createEvent<Error>();

export const OnUpdateClient = createEvent<Client, Client | undefined>();
export const OnUpdateClientError = createEvent<
  ClientListResponse,
  Error | ValidationError | ClientListResponse
>();

export const OnCreateManagerInvitation = createEvent<
  InvitationPropertyManager | undefined
>();
export const OnCreateManagerInvitationError = createEvent<Error>();

export const OnCreateMaintenanceInvitation = createEvent<
  InvitationMaintenance | undefined
>();
export const OnCreateMaintenanceInvitationError = createEvent<Error>();

export const OnCreateTeamMemberInvitation = createEvent<
  InvitationTeamMember | undefined
>();
export const OnCreateTeamMemberInvitationError = createEvent<Error>();

export const OnGetManagersAndInvitations = createEvent<
  | {
      managerInvitations: InvitationPropertyManagerListResponse | undefined;
      ownerInvitations: InvitationPropertyOwnerListResponse | undefined;
      maintenanceInvitations: InvitationMaintenanceListResponse | undefined;
      teamMemberInvitations: InvitationTeamMemberListResponse | undefined;
      clientUsers: ClientUserListResponse | undefined;
    }
  | undefined
>();
export const OnGetManagersAndInvitationsError = createEvent<Error>();

export const OnDeletePropertyManagerInvitation = createEvent<
  SuccessResponse | undefined
>();
export const OnDeletePropertyManagerInvitationError = createEvent<Error>();

export const OnDeleteMaintenanceInvitation = createEvent<
  SuccessResponse | undefined
>();
export const OnDeleteMaintenanceInvitationError = createEvent<Error>();

export const OnDeleteTeamMemberInvitation = createEvent<
  SuccessResponse | undefined
>();
export const OnDeleteTeamMemberInvitationError = createEvent<Error>();

export const OnDeletePropertyOwnerInvitation = createEvent<
  SuccessResponse | undefined
>();
export const OnDeletePropertyOwnerInvitationError = createEvent<Error>();

export const OnDeleteClientUser = createEvent<SuccessResponse | undefined>();

export const OnDeleteClientUserError = createEvent<Error>();

export const OnEditPropertyManagerInvitation = createEvent<
  InvitationPropertyManager | undefined
>();
export const OnEditPropertyManagerInvitationError = createEvent<Error>();

export const OnEditMaintenanceInvitation = createEvent<
  InvitationMaintenance | undefined
>();
export const OnEditMaintenanceInvitationError = createEvent<Error>();

export const OnEditTeamMemberInvitation = createEvent<
  InvitationTeamMember | undefined
>();
export const OnEditTeamMemberInvitationError = createEvent<Error>();

export const OnEditPropertyOwnerInvitation = createEvent<
  InvitationPropertyManager | undefined
>();
export const OnEditPropertyOwnerInvitationError = createEvent<Error>();

export const OnEditPropertyManager = createEvent<ClientUser | undefined>();
export const OnEditPropertyManagerError = createEvent<Error>();

export const OngetAccountsAction = createEvent<AccountListResponse>();
export const OngetAccountsActionError = createEvent<Error>();

export const OnToggleAccount = createEvent<Account>();
export const OnToggleAccountError = createEvent<Error>();

export const OnGetDefaultAccountsEvent =
  createEvent<DefaultAccountListResponse>();
export const OnGetDefaultAccountsError = createEvent<Error>();

export const OnFetchClientPage = createEvent<ClientPage>();
export const OnFetchClientPageError = createEvent<Error>();

export const OnUpdateClientPage = createEvent<ClientPage>();
export const OnUpdateClientPageError = createEvent<Error>();
