import { gql } from '@apollo/client';

export const GET_LEASE_CONTRACT_DOCUMENTS = gql`
  query GET_LEASE_CONTRACT_DOCUMENTS($filter: ContractDocumentFilter!) {
    contractDocumentsList(filter: $filter) {
      items {
        file {
          downloadUrl
          filename
        }
      }
    }
  }
`;

export const GET_RESIDENT_HELLO_SIGN = gql`
  mutation GET_RESIDENT_HELLO_SIGN($data: CreateManagerSignatureInput!) {
    createSignaturesRequest(data: $data) {
      message
      success
      statusCode
      template {
        sign_url
        edit_url
      }
    }
  }
`;

export const GET_PAYMENT_PLANS_BY_LEASE_PERIOD_ID = gql`
  query GET_PAYMENT_PLANS_BY_LEASE_PERIOD_ID($id: ID, $bedID: ID) {
    paymentPlansList(
      filter: {
        leasePeriod: { id: { equals: $id } }
        isActive: { equals: true }
        paymentPlanAvailabilityRelation: {
          some: { availability: { bed: { id: { equals: $bedID } } } }
        }
      }
      sort: { guarantorRequired: DESC }
    ) {
      items {
        id
        name
        guarantorRequired
        paymentPlanPaymentPlanItemRelation {
          items {
            id
            dueDate
            dueOn
            type
            category
            description
            amount
            leaseSubPeriod {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_LEASE_BY_ID_QUERY = gql`
  query GET_LEASE_BY_ID_QUERY($id: ID) {
    lease(id: $id) {
      id
      status
      paymentType
      reservationPaid
      leaseOptionalPreferenceRelation {
        items {
          ledger {
            amount
          }
        }
      }
      leaseLedgerRelation {
        count
        items {
          id
        }
      }
      leasePeriod {
        id
        name
        residentPropDocumentLeasePeriodRelation {
          items {
            type
            name
            status
            document {
              downloadUrl
              previewUrl
              filename
            }
          }
        }
        guarantorPropDocumentLeasePeriodRelation {
          items {
            type
            name
            status
            document {
              downloadUrl
              previewUrl
              filename
            }
          }
        }
      }
      opcionalPreferenceLeaseRelation {
        items {
          id

          opcionalOpcionalPreferenceItemRelation {
            items {
              amount
              type
            }
          }
          name
          title
          description
          status
          isActive
          leasePeriod {
            id
            name
          }
          total
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
      unit {
        id
        code
      }
      room {
        id
        code
        type
        roomType {
          typeName
        }
      }
      bed {
        id
        number
        code
      }
      floor {
        id
        number
      }
      paymentPlan {
        id
        name
        total
        paymentPlanPaymentPlanItemRelation {
          count
          items {
            id
            dueDate
            dueOn
            type
            category
            description
            amount
            leaseSubPeriod {
              id
              name
            }
          }
        }
      }
      userResident {
        id
        avatar {
          downloadUrl
        }
        firstName
        middleName
        fullName
        allowCreditCards
        allowChecks
        lastName
        preferredName
        email
        taxID
        gender
        genderIdentify
        birthdate
        phoneNumber
        homePhone
        studentIdOrEmployeeNumber
        city
        zipCode
        state
        address
        aboutMe
      }
      startDate
      endDate
      property {
        siteSetting {
          offlinePaymentsAddress
          enablePaymentsOnTenantProfile
          offlinePaymentsAddressStreetAddress
          offlinePaymentsAddressCity
          offlinePaymentsAddressZipCode
          enablePaymentsOnReservationCheckout
          enableTenantPortalCreditCardPayment
          enableTenantPortalACHPayment
          enableOnlineLeasingCreditCardPayment
          enableOnlineLeasingACHPayment
          requiresRoommateMatchingQuiz
        }
        client {
          id
          name
        }
        city
        state
        stripeAccountId
        propertyDocumentTemplatePropertyRelation {
          items {
            type
            name
            status
            document {
              downloadUrl
              filename
            }
          }
        }
        propertyLeasePeriodRelation {
          items {
            startDate
            endDate
            name
            id
            leasePeriodAvailabilityRelation {
              count
            }
          }
        }
        id
        name
        images {
          items {
            downloadUrl
            fileId
          }
        }
        primaryPropertyImage {
          downloadUrl
        }
        propertyImagePropertyRelation {
          items {
            id
            file {
              downloadUrl
              fileId
            }
            caption
            type
          }
        }
      }
      guarantor {
        id
        email
        status
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const LEASE_STATUS_SUBSCRIPTION = gql`
  subscription LEASE_STATUS_SUBSCRIPTION($leaseId: ID!) {
    Lease(
      filter: {
        mutation_in: [update]
        node: { id: { equals: $leaseId }, status: { equals: "LEASE_PERIOD" } }
      }
    ) {
      node {
        status
      }
    }
  }
`;

export const GET_LEASES_BY_PROPERTYID_LEASEPERIODID = gql`
  query GET_LEASES_BY_PROPERTYID_LEASEPERIODID($filter: LeaseFilter!) {
    leasesList(filter: $filter) {
      items {
        id
        userResident {
          id
        }
        bed {
          id
          number
          code
          bedLeaseRelation {
            items {
              id
              userResident {
                id
                avatar {
                  downloadUrl
                }
                firstName
                lastName
              }
            }
          }
        }
        leasePeriod {
          id
          startDate
          endDate
        }
        room {
          id
          code
        }
        floor {
          id
          number
        }
        unit {
          id
          code
        }
        paymentPlan {
          id
          name
          paymentPlanPaymentPlanItemRelation {
            items {
              id
              dueDate
              dueOn
              type
              category
              description
              amount
              leaseSubPeriod {
                id
                name
              }
            }
          }
        }
        property {
          propertyLeasePeriodRelation {
            items {
              startDate
              endDate
              name
              id
              leasePeriodAvailabilityRelation {
                count
              }
            }
          }
          id
          name
          images {
            items {
              downloadUrl
              fileId
            }
          }
          primaryPropertyImage {
            downloadUrl
          }
          propertyImagePropertyRelation {
            items {
              id
              file {
                downloadUrl
                fileId
              }
              caption
              type
            }
          }
        }
        guarantor {
          email
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;

export const CREATE_MOVE_OUT_REQUEST = gql`
  mutation CREATE_MOVE_OUT_REQUEST($data: MoveOutRequestCreateInput!) {
    moveOutRequestCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_LEASE = gql`
  mutation UPDATE_LEASE($data: LeaseUpdateInput!) {
    leaseUpdate(data: $data) {
      id
      status
    }
  }
`;

export const UPDATE_LEASE_ACTION = gql`
  mutation UPDATE_LEASE_ACTION($data: LeaseUpdateInput!) {
    leaseUpdate(data: $data) {
      id
    }
  }
`;

export const GET_LEASE_STATUS = gql`
  query GET_LEASE_STATUS($id: ID) {
    lease(id: $id) {
      status
      paymentPlan {
        id
      }
    }
  }
`;

export const GET_UNITS_BY_FILTER = gql`
  query GET_UNITS_BY_FILTER(
    $filter: UnitFilter
    $page: Int
    $pageSize: Int
    $upperPrice: Float
    $lowerPrice: Float
    $lowerThreshold: Int
    $upperThreshold: Int
    $userId: String
  ) {
    getUnitsByFilterResolver(
      filter: $filter
      page: $page
      pageSize: $pageSize
      upperPrice: $upperPrice
      lowerPrice: $lowerPrice
      lowerThreshold: $lowerThreshold
      upperThreshold: $upperThreshold
      userId: $userId
    ) {
      count
      items {
        unitType {
          typeName
        }
        unitLeaseRelation {
          items {
            userResident {
              id
              avatar {
                downloadUrl
              }
              firstName
              lastName
              traitifyPersonalityTypeIdeal
              traitifyAssessmentResults
            }
            availability {
              leasePeriod {
                id
              }
              gender
              id
            }
          }
        }
        id
        code
        beds
        floor {
          id
          number
          building {
            id
            code
          }
        }
        gender
        type
        unitRoomRelation {
          count
          items {
            id
            type
            roomType {
              id
              typeName
            }
            roomBedRelation {
              count
              items {
                id
                price
                code
                number
                bedAvailabilityRelation {
                  items {
                    available
                    type
                    availabilityPaymentPlanRelation(
                      sort: { paymentPlan: { total: ASC } }
                    ) {
                      items {
                        paymentPlan {
                          name
                          total
                        }
                      }
                    }
                  }
                }
                bedLeaseRelation {
                  items {
                    id
                    userResident {
                      avatar {
                        downloadUrl
                      }
                      firstName
                      lastName
                      traitifyAssessmentResults
                    }
                  }
                }
                room {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_UNITS_BY_PROPERTYID = gql`
  query GET_UNITS_BY_PROPERTYID($filter: UnitFilter) {
    unitsList(filter: $filter) {
      count
      items {
        unitType {
          typeName
        }
        id
        code
        beds
        floor {
          id
          number
        }
        gender
        type
      }
    }
  }
`;

export const GET_UNITS_LEASE_RESPONSE_BASIC_BY_PROPERTYID = gql`
  query GET_UNITS_LEASE_RESPONSE_BASIC_BY_PROPERTYID($filter: UnitFilter) {
    unitsList(filter: $filter) {
      count
      items {
        unitLeaseRelation {
          items {
            availability {
              leasePeriod {
                id
              }
              gender
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_UNITS_LEASE_RESPONSE_TRATIFY_BY_PROPERTYID = gql`
  query GET_UNITS_LEASE_RESPONSE_TRATIFY_BY_PROPERTYID($filter: UnitFilter) {
    unitsList(filter: $filter) {
      count
      items {
        unitLeaseRelation {
          items {
            userResident {
              traitifyAssessmentResults
            }
          }
        }
      }
    }
  }
`;

export const GET_UNITS_ROOM_RELATION_BASIC_BY_PROPERTYID = gql`
  query GET_UNITS_ROOM_RELATION_BASIC_BY_PROPERTYID($filter: UnitFilter) {
    unitsList(filter: $filter) {
      count
      items {
        unitRoomRelation {
          count
          items {
            id
            type
            roomType {
              id
              typeName
            }
            roomBedRelation {
              count
              items {
                id
                price
                number
                code
                bedLeaseRelation {
                  items {
                    id
                  }
                }
                room {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_UNITS_ROOM_RELATION_TRATIFY_BY_PROPERTYID = gql`
  query GET_UNITS_ROOM_RELATION_TRATIFY_BY_PROPERTYID($filter: UnitFilter) {
    unitsList(filter: $filter) {
      count
      items {
        unitRoomRelation {
          count
          items {
            roomBedRelation {
              count
              items {
                code
                number
                bedLeaseRelation {
                  items {
                    userResident {
                      traitifyAssessmentResults
                      avatar {
                        downloadUrl
                      }
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const SEND_FRIEND_NOTIFICATION = gql`
  mutation SEND_FRIEND_NOTIFICATION($id: ID!) {
    sendFriendNotificationResolver(id: $id) {
      success
    }
  }
`;

export const UPDATE_UNIT_GENDER = gql`
  mutation UPDATE_UNIT_GENDER($data: UnitUpdateInput!) {
    unitUpdate(data: $data) {
      id
      gender
    }
  }
`;

export const CREATE_USER_RESIDENT_MUTATION = gql`
  mutation CREATE_USER_RESIDENT_MUTATION($data: UserCreateInput!) {
    userCreate(data: $data) {
      id
      firstName
      lastName
      email
      gender
      phoneNumber
      homePhone
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($email: String) {
    user(email: $email) {
      id
    }
  }
`;

export const GET_TENANT_RESERVATION_FEE_SETTING = gql`
  query GET_TENANT_RESERVATION_FEE_SETTING($clientId: ID) {
    feeSettingsList(
      filter: { fee: { clientFee: { id: { equals: $clientId } } } }
    ) {
      items {
        id
        passReservationFeeToTenant
      }
    }
  }
`;

export const GET_RESERVATION_FEE = gql`
  query GET_RESERVATION_FEE($clientId: ID) {
    feesList(filter: { clientFee: { id: { equals: $clientId } } }) {
      items {
        id
        reservationFee
      }
    }
  }
`;
