import { useState, useContext, useEffect } from 'react';
import {
  useCallAction,
  useEvent,
  useSubscription,
} from '@8baselabs/react-simple-state';

import {} from '@8baselabs/resident-io-shared';

import {
  alpha,
  createStyles,
  Grid,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { Dialog } from '../../../shared/components/Dialog';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { MutedButton } from '../../../shared/components/ui/buttons';
import { TextFieldDefault } from '../../../shared/components/ui/inputs/TextFieldDefault';
import { MUTED_COLOR } from '../../../shared/theme';

import {
  createEventError,
  createEventEvent,
  deleteEventError,
  deleteEventEvent,
  onOpenEventEditModal,
  onOpenNewEventModal,
  updateEventError,
  updateEventEvent,
} from '../calendar-event';
import { AlertContext } from '../../../routes/AlertContext';
import {
  createEventAction,
  deleteEventAction,
  updateEventAction,
} from '../calendar-actions';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

type NewTicketDialogProps = {
  propertyId: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      backgroundColor: alpha(MUTED_COLOR, 0.1),
      maxWidth: '800px',
    },
    button: {
      marginLeft: 10,
      width: 68,
    },
    loadingButton: {
      marginLeft: 10,
      width: 110,
    },
    buttonContainer: {
      marginTop: 80,
    },
  }),
);

export const EventDialog: React.FC<NewTicketDialogProps> = (props) => {
  const { propertyId } = props;
  const classes = useStyles();

  const [title, setTitle] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>();
  const [startTime, setStartTime] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();
  const [eventId, setEventId] = useState<string>('');

  const [error, setError] = useState({
    title: false,
    startDate: false,
    startTime: false,
    endDate: false,
    endTime: false,
  });

  const alert = useContext(AlertContext);

  const formatDate = (
    date: Date | null | undefined,
    time: Date | null | undefined,
  ): string => {
    if (!date || !time) {
      alert({
        success: false,
        message: 'Something went wrong creating the event',
      });
      return '';
    }
    const formattedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
    );
    return formattedDate.toJSON();
  };

  const modalDataCreate = useEvent(onOpenNewEventModal);
  const modalDataEdit = useEvent(onOpenEventEditModal);

  useEffect(() => {
    setTitle(modalDataEdit?.event?.title || '');

    if (modalDataEdit?.event?.end) {
      setEndDate(new Date(modalDataEdit?.event?.end));
      setEndTime(new Date(modalDataEdit?.event?.end));
    }

    if (modalDataEdit?.event?.start) {
      setStartDate(new Date(modalDataEdit?.event?.start));
      setStartTime(new Date(modalDataEdit?.event?.start));
    }

    setEventId(modalDataEdit?.eventId || '');
  }, [modalDataEdit]);

  useEffect(() => {
    const endString = modalDataCreate?.event?.end ?? '';
    const startString = modalDataCreate?.event?.start ?? '';

    if (endString) {
      setEndDate(new Date(endString));
      setEndTime(new Date(endString));
    } else {
      setEndDate(null);
      setEndTime(null);
    }
    if (startString) {
      setStartDate(new Date(startString));
      setStartTime(new Date(startString));
    } else {
      setStartDate(null);
      setStartTime(null);
    }
  }, [modalDataCreate]);

  const handleClose = (): void => {
    onOpenNewEventModal.dispatch({ open: false });
    onOpenEventEditModal.dispatch({
      open: false,
      eventId: '',
      event: {
        title: '',
        start: '',
        end: '',
        propertyId: '',
      },
    });
    setTitle('');
    setStartDate(undefined);
    setEndDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);

    setError({
      title: false,
      startDate: false,
      endDate: false,
      startTime: false,
      endTime: false,
    });
  };

  useSubscription(createEventEvent, () => {
    handleClose();
    alert({
      success: true,
      message: 'Event created successfully',
    });
  });

  useSubscription(createEventError, () => {
    handleClose();
    alert({
      error: true,
      message: 'Something went wrong creating the event',
    });
  });

  useSubscription(updateEventEvent, () => {
    handleClose();
    alert({
      success: true,
      message: 'Event updated successfully',
    });
  });

  useSubscription(updateEventError, () => {
    handleClose();
    alert({
      error: true,
      message: 'Something went wrong updating the event',
    });
  });

  useSubscription(deleteEventEvent, () => {
    handleClose();
    alert({
      success: true,
      message: 'Event deleted successfully',
    });
  });

  useSubscription(deleteEventError, () => {
    handleClose();
    alert({
      error: true,
      message: 'Something went wrong deleting the event',
    });
  });

  const [createEvent, creating] = useCallAction(createEventAction);
  const [updateEvent, updating] = useCallAction(updateEventAction);
  const [deleteEvent, deleting] = useCallAction(deleteEventAction);

  const handleConfirmButton = (): void => {
    const localError = {
      title: false,
      startDate: false,
      endDate: false,
      startTime: false,
      endTime: false,
    };
    if (!title) {
      localError.title = true;
    }
    if (!startDate) {
      localError.startDate = true;
    }
    if (!endDate) {
      localError.endDate = true;
    }
    if (!startTime) {
      localError.startTime = true;
    }
    if (!endTime) {
      localError.endTime = true;
    }

    if (
      localError.title ||
      localError.startDate ||
      localError.endDate ||
      localError.startTime ||
      localError.endTime
    ) {
      setError(localError);
      return;
    }

    const start = formatDate(startDate, startTime);
    const end = formatDate(endDate, endTime);

    if (modalDataEdit?.open) {
      updateEvent({
        eventId,
        title,
        start,
        end,
        propertyId,
      });
    } else {
      createEvent({
        title,
        start,
        end,
        propertyId,
      });
    }
  };

  const handleDeleteButton = (): void => {
    deleteEvent(eventId ?? '');
  };

  return (
    <Dialog
      title={modalDataCreate?.open ? 'Create Event' : 'Update Event'}
      size="lg"
      className={classes.dialog}
      isVisible={modalDataCreate?.open || modalDataEdit?.open || false}
      onClose={handleClose}>
      <Grid container>
        <Grid item container spacing={1} style={{ marginBottom: 30 }}>
          <Grid item xs={12}>
            <TextFieldDefault
              label="TITLE"
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              value={title ? title : ''}
              error={Boolean(error.title)}
              type={'text'}>
              <Divider />
            </TextFieldDefault>
          </Grid>
          <Grid item xs={7}>
            <KeyboardDatePicker
              autoOk
              clearable
              InputProps={{ onKeyDown: (e) => e.preventDefault() }}
              error={error.startDate}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="START DATE"
              format="MMMM dd, yyyy"
              value={startDate}
              maxDate={endDate ?? undefined}
              maxDateMessage="Start date cannot be later than the selected end date"
              onChange={(value) => {
                setStartDate(value);
              }}
              InputAdornmentProps={{
                style: {
                  borderLeft: `1px solid ${MUTED_COLOR}`,
                  height: 'auto',
                },
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <KeyboardTimePicker
              id="time-picker"
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              error={error.startTime}
              InputProps={{ onKeyDown: (e) => e.preventDefault() }}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="START TIME"
              format="hh:mm a"
              value={startTime}
              initialFocusedDate={new Date(new Date().setHours(0, 0, 0, 0))}
              onChange={(value) => {
                setStartTime(value);
              }}
              keyboardIcon={<QueryBuilderIcon />}
              InputAdornmentProps={{
                style: {
                  borderLeft: `1px solid ${MUTED_COLOR}`,
                  height: 'auto',
                },
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <KeyboardDatePicker
              clearable
              autoOk
              InputProps={{ onKeyDown: (e) => e.preventDefault() }}
              error={error.endDate}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="END DATE"
              format="MMMM dd, yyyy"
              value={endDate}
              minDate={startDate ?? undefined}
              maxDateMessage="End date cannot be earlier than the selected start date"
              onChange={(value) => setEndDate(value)}
              InputAdornmentProps={{
                style: {
                  borderLeft: `1px solid ${MUTED_COLOR}`,
                  height: 'auto',
                },
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <KeyboardTimePicker
              id="time-picker"
              InputProps={{ onKeyDown: (e) => e.preventDefault() }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              error={error.endTime}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="END TIME"
              format="hh:mm a"
              value={endTime}
              initialFocusedDate={new Date(new Date().setHours(0, 0, 0, 0))}
              onChange={(value) => {
                setEndTime(value);
              }}
              keyboardIcon={<QueryBuilderIcon />}
              InputAdornmentProps={{
                style: {
                  borderLeft: `1px solid ${MUTED_COLOR}`,
                  height: 'auto',
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          className={classes.buttonContainer}
          justifyContent="space-between">
          <ButtonAction
            isLoading={deleting}
            style={modalDataCreate?.open ? { visibility: 'hidden' } : {}}
            className={deleting ? classes.loadingButton : classes.button}
            onClick={handleDeleteButton}>
            Delete
          </ButtonAction>
          <div>
            <MutedButton className={classes.button} onClick={handleClose}>
              Cancel
            </MutedButton>
            <ButtonAction
              isLoading={modalDataCreate?.open ? creating : updating}
              onClick={handleConfirmButton}
              className={
                creating || updating ? classes.loadingButton : classes.button
              }>
              {modalDataCreate?.open ? 'Create' : 'Update'}
            </ButtonAction>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};
