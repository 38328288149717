import { gql } from '@apollo/client';

export const SELECT_CLIENT_MUTATION = gql`
  mutation SELECT_CLIENT_MUTATION($userId: ID!, $clientId: ID!) {
    userUpdate(
      filter: { id: $userId }
      data: { selectedClient: { connect: { id: $clientId } } }
    ) {
      selectedClient {
        id
        clientPropertyRelation {
          items {
            id
            name
            email
          }
        }
      }
    }
  }
`;

export const DESELECT_CLIENT_MUTATION = gql`
  mutation DESELECT_CLIENT_MUTATION($userId: ID!, $clientId: ID!) {
    userUpdate(
      filter: { id: $userId }
      data: { selectedClient: { disconnect: { id: $clientId } } }
    ) {
      id
      updatedAt
    }
  }
`;

export const REQUEST_EMAIL_VERIFICATION_MUTATION = gql`
  mutation REQUEST_EMAIL_VERIFICATION_MUTATION(
    $data: RequestVerificationEmailInput!
  ) {
    requestVerificationEmail(data: $data) {
      success
      message
    }
  }
`;

export const GET_LEASE = gql`
  query GET_LEASE($id: ID) {
    lease(id: $id) {
      id
      unit {
        id
      }
      bed {
        id
      }
      leaseContractDocumentRelation {
        items {
          id
          signer
          updatedAt
          downloadURL
        }
      }
      leaseTermsDocumentRelation {
        items {
          id
          signer
          updatedAt
          downloadURL
        }
      }
      property {
        id
        name
        siteSetting {
          enablePaymentsOnTenantProfile
          enableOnlineLeasingCreditCardPayment
          enableOnlineLeasingACHPayment
          enablePaymentsOnReservationCheckout
          enableTenantPortalCreditCardPayment
          enableTenantPortalACHPayment
        }
        location
        stripeAccountId
        client {
          id
        }
        primaryPropertyImage {
          downloadUrl
        }
        images {
          items {
            downloadUrl
          }
        }
        headerImage {
          downloadUrl
        }
        propertyImagePropertyRelation {
          items {
            file {
              downloadUrl
            }
          }
        }
      }
      leaseLeaseDocumentRelation {
        count
        items {
          id
          type
          createdAt
          document {
            filename
            downloadUrl
          }
        }
      }
      userResident {
        id
        firstName
        allowCreditCards
        allowChecks
        lastName
        stripeCustomerId
        email
        requiredFileUserRelation(
          filter: {
            requiredFileRequiredUploadRelation: {
              isIncluded: { equals: true }
              isRequired: { equals: true }
            }
          }
        ) {
          items {
            id
            requiredFileDocumentTypeRelation {
              name
            }
            createdAt
            updatedAt
            type
            file {
              filename
              downloadUrl
            }
          }
        }
      }
      leasePeriod {
        name
        id
      }
      status
      leaseLedgerRelation {
        items {
          amount
          type
        }
      }
      paymentPlan {
        paymentPlanPaymentPlanItemRelation {
          items {
            amount
            dueDate
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY_BY_LEASE = gql`
  query GET_PROPERTY_BY_LEASE($id: ID) {
    lease(id: $id) {
      property {
        id
        name
        location
      }
    }
  }
`;

export const GET_PROPERTY_CLIENT_USER_RELATION = gql`
  query GET_PROPERTY_CLIENT_USER_RELATION($id: ID) {
    property(id: $id) {
      clientUser {
        items {
          id
          role
          user {
            id
            email
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY = gql`
  query GET_PROPERTY($id: ID) {
    property(id: $id) {
      location
      id
      name
      client {
        id
      }
      siteSetting {
        disableTenantMaintenanceTickets
      }
      clientUser {
        items {
          id
          role
          user {
            id
            email
            firstName
            lastName
          }
        }
      }
      buildingLayout {
        building {
          items {
            buildingFloorRelation {
              items {
                floorUnitRelation {
                  items {
                    id
                    code
                    unitRoomRelation {
                      items {
                        roomBedRelation {
                          items {
                            id
                            number
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
