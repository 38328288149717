import * as yup from 'yup';
import { createAction } from '@8baselabs/react-simple-state';
import {
  LeasePeriodDeleteInput,
  LeasePeriodListResponse,
  LeaseSubPeriodCreateInput,
  LeaseSubPeriodDeleteInput,
  LeaseSubPeriodFilter,
  LeaseSubPeriodUpdateInput,
  Mutation,
  PaymentPlanCreateInput,
  PaymentPlanListResponse,
  PropertyFilter,
  PropertyKeyFilter,
  PropertyListResponse,
  PropertyUpdateInput,
  Query,
  PaymentPlanItem,
  PaymentPlanItemCreateInput,
  PaymentPlanItemUpdateInput,
  Unit,
  SuccessResponse,
  AvailabilityCreateInput,
  PaymentPlan,
  PaymentPlanDeleteInput,
  PaymentPlanUpdateInput,
  OptionalPreferenceItem,
  OptionalPreferenceItemCreateInput,
  OptionalPreference,
  OptionalPreferenceUpdateInput,
  OptionalPreferenceItemUpdateInput,
  OptionalPreferenceDeleteInput,
  OptionalPreferenceCreateInput,
  RoomTypeListResponse,
  UnitTypeListResponse,
  LateFeeListResponse,
  LateFee,
  BuildingLayoutCreateInput,
  BuildingCreateInput,
  Building,
  BuildingUpdateInput,
  Floor,
  FloorCreateInput,
  UnitCreateInput,
  UnitUpdateInput,
  OptionalPreferenceInvetory,
  ImportResidentData,
  ImportResidentsResponse,
  UnitEditResult,
  ClientUserUpdateInput,
  ClientUserKeyFilter,
  ClientUser,
  AvailabilityListResponse,
  OptionalPreferenceAdditionalDatum,
  SiteSettingListResponse,
  SiteSettingUpdateInput,
  SiteSettingCreateInput,
  LedgerCreateInput,
  UnitFilter,
  LedgerListResponse,
  PropertyCreateInput,
  Client,
  ClientUpdateInput,
  ClientPage,
  BedFilter,
  BedListResponse,
  LeasePeriod,
  OptionalPreferenceListResponse,
  LateFeeCreateInput,
  LateFeeUpdateInput,
  LeaseSubPeriodListResponse,
  Property,
  Subscription,
} from '../../schema-types';
import {
  Testimonial,
  TestimonialCreateInput,
  TestimonialUpdateInput,
} from '../../schema-types';

import { FileValue } from '@8base-react/file-input';

import moment from 'moment';
import {
  AvailabilityFilter,
  AvailabilityUpdateInput,
  Availability_AvailabilityPlaymentPlanCreateInput,
  Availability_AvailabilityOptionalPreferenceCreateInput,
  PeriodSubPeriodFilter,
  AvailabilityUpdateByFilterInput,
  PropertyImage,
  LeasePeriodUpdateInput,
  Availability,
} from '@8baselabs/resident-io-shared';
import { apolloClient } from '../../shared/apollo';
import {
  OnGetProperties,
  OnGetPropertiesError,
  OnSaveProperty,
  onMutateAvailability,
  OnSavePropertyError,
  OnLeasePeriodCreated,
  OnLeasePeriodCreatedError,
  OnLeasePeriodDeleted,
  OnLeasePeriodDeletedError,
  OnLeasePeriodList,
  OnLeasePeriodListError,
  OnLeasePeriodUpdated,
  OnLeasePeriodUpdatedError,
  OnSubperiodCreated,
  OnSubperiodCreatedError,
  OnSubperiodDeleted,
  OnSubperiodDeletedError,
  OnSubperiodList,
  OnSubperiodListError,
  OnSubperiodUpdated,
  OnSubperiodUpdatedError,
  OnGetBuildingLayoutError,
  OnGetBuildingLayout,
  OnGetPropertyInformation,
  OnGetPropertyInformationError,
  onPropertyUpdateEvent,
  onPropertyUpdateEventError,
  onFetchPaymentPlansEvent,
  onFetchPaymentPlansEventError,
  onFetchPaymentPlanEvent,
  onFetchPaymentPlanEventError,
  onPaymentPlanCreatedEvent,
  onPaymentPlanCreatedEventError,
  onBedPaymentPlanCreatedEvent,
  onBedPaymentPlanCreatedEventError,
  onBedPaymentPlanDeletedEvent,
  onBedPaymentPlanDeletedEventError,
  OnUnitUpdated,
  OnUnitUpdatedError,
  OnGetPropertyData,
  OnGetPropertyDataError,
  onPropertyPageUpdate,
  onPropertyPageUpdateError,
  onCreatePaymentPlanItemEvent,
  onCreatePaymentPlanItemEventError,
  onUpdatePaymentPlanItemEvent,
  onUpdatePaymentPlanItemEventError,
  onDeletePaymentPlanItemEvent,
  onDeletePaymentPlanItemEventError,
  onUpdatePaymentPlanEvent,
  onUpdatePaymentPlanEventError,
  onFetchOptionalPreferenceEvent,
  onFetchOptionalPreferenceEventError,
  onUpdateOptionalPreferenceItemEvent,
  onUpdateOptionalPreferenceItemEventError,
  onUpdateOptionalPreferenceEvent,
  onUpdateOptionalPreferenceEventError,
  onCreateOptionalPreferenceItemEvent,
  onCreateOptionalPreferenceItemEventError,
  onDeleteOptionalPreferenceItemEvent,
  onDeleteOptionalPreferenceItemEventError,
  onDeleteOptionalPreferenceEvent,
  onDeleteOptionalPreferenceEventError,
  onOptionalPreferenceCreatedEvent,
  onOptionalPreferenceCreatedEventError,
  onFetchOptionalPreferencesEvent,
  onFetchOptionalPreferencesEventError,
  onFetchRoomTypesEventError,
  onFetchRoomTypesEvent,
  onFetchUnitTypesEvent,
  onFetchUnitTypesEventError,
  OnGetPropertyLateFees,
  OnGetPropertyLateFeesError,
  OnUpdateLateFee,
  OnUpdateLateFeeError,
  OnCreateLateFee,
  OnCreateLateFeeError,
  onDeletePaymentPlanEvent,
  onDeletePaymentPlanEventError,
  OnBuildingLayoutCreated,
  OnBuildingLayoutCreatedError,
  OnBuildingCreated,
  OnBuildingCreatedError,
  OnBuildingUpdated,
  OnBuildingUpdatedError,
  OnBuildingDeleted,
  OnBuildingDeletedError,
  OnFloorAdded,
  OnFloorAddedError,
  OnFloorDeleted,
  OnFloorDeletedError,
  OnUnitCreated,
  OnUnitCreatedError,
  OnUnitDeleted,
  OnUnitDeletedError,
  OnUnitEdited,
  OnUnitEditedError,
  onDeleteManyOptionalPreferenceEvent,
  onDeleteManyOptionalPreferenceEventError,
  OnBuildingLayoutLoaded,
  OnBuildingLayoutLoadedError,
  onMutateAvailabilityError,
  OnImportResidentsEvent,
  OnImportResidentsEventError,
  onSaveOptionalPreferenceInventory,
  onSaveOptionalPreferenceInventoryError,
  OnUpdateClientUser,
  OnUpdateClientUserError,
  OnGetPropertyFilterItems,
  OnGetPropertyFilterItemsError,
  OnGetAvailabilityList,
  OnGetAvailabilityListError,
  onDeleteManyPaymentPlanEvent,
  onDeleteManyPaymentPlanEventError,
  onDeleteManyOptionalPreferenceItemEvent,
  onDeleteManyOptionalPreferenceItemEventError,
  onDeleteManyPaymentPlanItemEvent,
  onDeleteManyPaymentPlanItemEventError,
  onFetchPaymentPlanAndOptionalPreferences,
  onFetchPaymentPlanAndOptionalPreferencesError,
  onSaveOptionalPreferenceAdditionalData,
  onSaveOptionalPreferenceAdditionalDataError,
  OnPeriodSubPeriodRelationDeleted,
  OnPeriodSubPeriodRelationDeletedError,
  OnGetPropertySiteSettingsError,
  OnGetPropertySiteSettings,
  onPropertySiteSettingsUpdateError,
  onPropertySiteSettingsUpdate,
  onCreatePropertySiteSettings,
  onCreatePropertySiteSettingsError,
  OnUpdatePropertyDataEvent,
  OnUpdatePropertyDataEventError,
  OnGetAllAvailabilities,
  OnGetAllAvailabilitiesError,
  OnFetchLedgerError,
  OnFetchLedgerEvent,
  OnFetchsubscriptionEvent,
  OnFetchsubscriptionError,
  OnGetSubdomainAvailabilityError,
  OnGetSubdomainAvailability,
  OnSaveSubdomain,
  OnSaveSubdomainError,
  OnGetClientPageData,
  OnGetClientPageDataError,
  OnGetPropertyDataForSettings,
  OnGetPropertyDataForSettingsError,
  OnGetAllAvailabilitiesCount,
  OnGetAllAvailabilitiesCountError,
  OnEditLateFee,
  OnEditLateFeeError,
  OnDeleteLateFee,
  OnDeleteLateFeeError,
  OnLeaseSubPeriodList,
  OnLeaseSubPeriodListError,
  OnBuildingsDelete,
  OnBuildingsDeleteError,
  CsvBuildings,
} from './settings-events';
import {
  GET_CLIENT_PROPERTIES_QUERY,
  SAVE_PROPERTY_MUTATION,
  CREATE_LEASEPERIODS,
  CREATE_SUBPERIOD,
  DELETE_LEASEPERIODS,
  DELETE_SUBPERIOD,
  READ_LEASEPERIODS,
  CREATE_BED_PAYMENT_PLANS,
  READ_SUBPERIODS,
  UPDATE_LEASEPERIODS,
  DELETE_PAYMENT_PLAN,
  UPDATE_SUBPERIOD,
  GET_PROPERTY_INFORMATION,
  UPDATE_PROPERTY_INFORMATION,
  FETCH_PAYMENT_PLANS_LIST,
  CREATE_PAYMENT_PLAN_MUTATION,
  DELETE_BED_PAYMENT_PLANS,
  UPDATE_UNIT_MUTATION,
  GET_PROPERTY_DATA,
  UPDATE_PROPERTY_PAGE,
  CREATE_TESTIMONIAL,
  UPDATE_TESTIMONIAL,
  FETCH_PAYMENT_PLAN_BY_ID,
  UPDATE_PAYMENT_PLAN_ITEM,
  CREATE_PAYMENT_PLAN_ITEM,
  DELETE_PAYMENT_PLAN_ITEM,
  UPDATE_PAYMENT_PLAN,
  CREATE_OPTIONAL_PREFERENCE_MUTATION,
  FETCH_OPTIONAL_PREFERENCE_LIST,
  DELETE_OPTIONAL_PREFERENCE,
  FETCH_OPTIONAL_PREFERENCE_BY_ID,
  CREATE_OPTIONAL_PREFERENCE_ITEM,
  DELETE_OPTIONAL_PREFERENCE_ITEM,
  UPDATE_OPTIONAL_PREFERENCE,
  UPDATE_OPTIONAL_PREFERENCE_ITEM,
  READ_ROOM_TYPES,
  READ_UNIT_TYPES,
  GET_PROPERTY_LATE_FEES,
  TOGGLE_LATE_FEE,
  CREATE_LATE_FEE,
  CREATE_BUILDING_LAYOUT,
  CREATE_BUILDING,
  UPDATE_BUILDING,
  FLOOR_CREATE,
  FLOOR_UPDATE,
  UNIT_CREATE,
  UNIT_DELETE,
  EDIT_UNIT,
  CREATE_FLOORS_RESOLVER,
  DELETE_BIG_BUILDING,
  DELETE_BIG_FLOOR,
  MUTATE_AVAILABILITY,
  IMPORT_RESIDENTS,
  CREATE_INVENTORY_ITEM,
  UPDATE_INVENTORY_ITEM,
  UPDATE_CLIENT_USER,
  FETCH_PROPERTY_FILTER_QUERY,
  AVAILABILITY_LIST_QUERY,
  AVAILABILITY_UPDATE_BYFILTER_MUTATION,
  FETCH_PAYMENT_PLANS_AND_OPTIONAL_PREFERENCES,
  CREATE_ADDITIONAL_DATA_MUTATION,
  UPDATE_ADDITIONAL_DATA,
  DELETE_PERIODSUBPERIODRELATIONBYFILTER,
  UPDATE_LEASEPERIODS_DISPLAYMANAGER_FIELD,
  GET_PROPERTY_SITE_SETTINGS,
  UPDATE_PROPERTY_SITE_SETTINGS,
  CREATE_PROPERTY_SITE_SETTINGS,
  UNIT_UPDATE_BY_FILTER,
  FETCH_PROPERTY_LEASE_PERIOD,
  FETCH_LEDGERS,
  GET_CUSTOMER_SUBSCRIPTION_INVOICES,
  GET_BUILDING_LAYOUT_ID,
  GET_BUILDING_LAYOUT,
  GET_SUBDOMAIN_AVAILABILITY,
  SAVE_SUBDOMAIN,
  GET_CLIENT_PAGE_DATA,
  GET_PROPERTY_DATA_FOR_SETTINGS,
  AVAILABILITY_LIST_BASIC_QUERY,
  AVAILABILITY_LIST_PP_QUERY,
  AVAILABILITY_LIST_OP_QUERY,
  AVAILABILITY_COUNT_QUERY,
  FETCH_SUBPERIODS,
  DELETE_LATE_FEE,
  UPDATE_LATE_FEE,
  FETCH_SUBPERIODS_BY_PROPERTY,
  FETCH_LEASE_PERIODS_BY_PROPERTY,
  DELETE_BUILDINGS_BY_FILTER,
  DELETE_BUILDING,
  LAYOUT_LOADED_SUBSCRIPTION,
  CREATE_BUILDING_UPLOAD,
} from './settings-queries';
import {
  BuildingLayoutAction,
  BuildingLayoutActionTypes,
  BuildingType,
  FloorType,
  PaymentPlansAndOptionalPreferences,
  PropertyImageFile,
  SchoolCompanySubdomainResponse,
} from './settings-types';
import { Dispatch } from 'react';
import { createLedgerError, createLedgerEvent } from '../people/people-event';
import { CREATE_LEDGER } from '../people/people-query';
import { extend } from '../../shared/utils';
import { gql } from '@apollo/client';
import {
  AvailabilityPaymentPlanType,
  ClientUserType,
  LateFeeDeleteInput,
} from '@8baselabs/resident-io-shared';

export const saveSubdomain = createAction(
  OnSaveSubdomain,
  OnSaveSubdomainError,
  async (clientOwnerId: string, subdomain: string): Promise<Client> => {
    const data: ClientUpdateInput = {
      id: clientOwnerId,
      clientPage: {
        update: {
          urlSubdomain: subdomain,
        },
      },
    };

    const response = await apolloClient.mutate({
      mutation: SAVE_SUBDOMAIN,
      fetchPolicy: 'no-cache',
      variables: { data },
    });

    return response.data;
  },
);

export const getClientPageData = createAction(
  OnGetClientPageData,
  OnGetClientPageDataError,
  async (clientOwnerId: string): Promise<ClientPage> => {
    const response = await apolloClient.query({
      query: GET_CLIENT_PAGE_DATA,
      fetchPolicy: 'no-cache',
      variables: { clientOwnerId },
    });

    return response.data.client.clientPage;
  },
);

export const saveProperty = createAction(
  OnSaveProperty,
  OnSavePropertyError,
  async (
    data,
    client: Client | undefined,
  ): Promise<Property> => {

    const newData = {
      ...data,
    };

    const clientOwner = client?.clientClientUserRelation?.items.find(
      (clientUser) => clientUser.role?.includes('PROPERTY_OWNER'),
    );

    let clientConnect;
    if (client?.id) {
      clientConnect = {
        connect: {
          id: client?.id,
        },
      };
    }
    const parsedData: PropertyCreateInput = {
      ...newData,
      client: clientConnect,
      subdomain: newData.subdomain,
      buildings: Number(newData.buildings),
      units: Number(newData.units),
      beds: Number(newData.beds),
      siteSetting: {
        create: {
          allowSiteToSendEmails: false,
          conditionallyRequireGuarantor: 'ALWAYS-REQUIRED',
          autofillNewRenewalApplication: true,
          enableOnlineLeasingACHPayment: true,
          enableOnlineLeasingCreditCardPayment: true,
          enablePaymentsOnReservationCheckout: true,
          enablePaymentsOnTenantProfile: true,
          enableTenantPortalACHPayment: false,
          enableTenantPortalCreditCardPayment: true,
          disableTenantMaintenanceTickets: false,
          offlinePaymentsAddress: '',
          requiresIdentityVerificationOnTenant: true,
          requiresRoommateMatchingQuiz: false,
        },
      },
      clientUser: clientOwner
        ? { connect: { id: clientOwner?.id } }
        : undefined,
    };

    const response = await apolloClient.mutate({
      mutation: SAVE_PROPERTY_MUTATION,
      fetchPolicy: 'no-cache',
      variables: { data: parsedData },
    });
    // await apolloClient.cache.reset();

    return response.data.propertyCreate as Property;
  },
);

/**
 * Fetch a list of properties subdomains.
 */
/* export const getPropertiesSubdomains = createAction(
  OnGetPropertiesSubdomains,
  OnGetPropertiesSubdomainsError,
  async (subdomain: string) => {
    const response = await apolloClient.query<Query>({
      // update shared
      query: GET_PROPERTIES_SUBDOMAIN,
      variables: { subdomain },
    });

    return response.data.propertiesList;
  },
); */

/**
 * Get data from property by id.
 */
export const getSubdomainAvailability = createAction(
  OnGetSubdomainAvailability,
  OnGetSubdomainAvailabilityError,
  async (
    subdomain: string,
  ): Promise<SchoolCompanySubdomainResponse['getSchoolCompanyBySubdomain']> => {
    const response = await apolloClient.query<SchoolCompanySubdomainResponse>({
      query: GET_SUBDOMAIN_AVAILABILITY,
      variables: { subdomain },
    });

    return response.data.getSchoolCompanyBySubdomain;
  },
);

export const getProperties = createAction(
  OnGetProperties,
  OnGetPropertiesError,
  async (
    clientId: string,
    clientUserId: string,
    role: ClientUserType,
  ): Promise<PropertyListResponse | undefined> => {
    let filter: PropertyFilter = {};
    if (role === ClientUserType.PROPERTY_MANAGER) {
      filter = {
        clientUser: {
          some: {
            id: {
              equals: clientUserId,
            },
          },
        },
      };
    } else {
      filter = {
        client: {
          id: {
            equals: clientId,
          },
        },
      };
    }

    if (!Object.keys(filter).length) {
      return undefined;
    }

    const response = await apolloClient.query<Query>({
      query: GET_CLIENT_PROPERTIES_QUERY,
      variables: { filter },
    });

    return response.data.propertiesList;
  },
);

/*
 * Update client user.
 */
export const updateClientUser = createAction(
  OnUpdateClientUser,
  OnUpdateClientUserError,
  async (
    filter: ClientUserKeyFilter,
    data: ClientUserUpdateInput,
  ): Promise<ClientUser | undefined> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_CLIENT_USER,
      variables: {
        filter,
        data,
      },
    });

    return response.data?.clientUserUpdate;
  },
);

/**
 * Get all Lease Periods.
 */
export const getAllLeasePeriods = createAction(
  OnLeasePeriodList,
  OnLeasePeriodListError,
  async (
    propertyId: string | undefined,
  ): Promise<LeasePeriodListResponse | undefined> => {
    if (!propertyId) return undefined;
    const {
      data: { leasePeriodsList },
    } = await apolloClient.query<{
      leasePeriodsList: LeasePeriodListResponse;
    }>({
      query: READ_LEASEPERIODS,
      variables: {
        propertyId,
      },
    });

    return leasePeriodsList;
  },
);
/**
 * Get all Lease Periods.
 */
export const getAllUnitTypes = createAction(
  onFetchUnitTypesEvent,
  onFetchUnitTypesEventError,
  async (propertyId: string): Promise<UnitTypeListResponse> => {
    const {
      data: { unitTypesList },
    } = await apolloClient.query<{
      unitTypesList: UnitTypeListResponse;
    }>({
      query: READ_UNIT_TYPES,
      variables: {
        propertyId,
      },
    });

    return unitTypesList;
  },
);
/**
 * Get all Lease Periods.
 */
export const getAllroomTypes = createAction(
  onFetchRoomTypesEvent,
  onFetchRoomTypesEventError,
  async (propertyId: string): Promise<RoomTypeListResponse> => {
    const {
      data: { roomTypesList },
    } = await apolloClient.query<{
      roomTypesList: RoomTypeListResponse;
    }>({
      query: READ_ROOM_TYPES,
      variables: {
        propertyId,
      },
    });

    return roomTypesList;
  },
);
/**
 * Create a Lease Period.
 */
export const createLeasePeriod = createAction(
  OnLeasePeriodCreated,
  OnLeasePeriodCreatedError,
  async (data: unknown): Promise<void> => {
    await apolloClient.mutate({
      mutation: CREATE_LEASEPERIODS,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Update a Lease Period.
 */
export const updateLeasePeriod = createAction(
  OnLeasePeriodUpdated,
  OnLeasePeriodUpdatedError,
  async (data: Omit<LeasePeriodUpdateInput, 'description'>): Promise<void> => {
    await apolloClient.mutate({
      mutation: UPDATE_LEASEPERIODS,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Update displayManagerOnDashboard field of a Lease Period.
 */
export const updateLeasePeriodDisplayManagerField = createAction(
  OnLeasePeriodUpdated,
  OnLeasePeriodUpdatedError,
  async (data: { displayOnDashboard: boolean; id: string }): Promise<void> => {
    await apolloClient.mutate({
      mutation: UPDATE_LEASEPERIODS_DISPLAYMANAGER_FIELD,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Delete a Lease Period.
 */
export const deleteLeasePeriod = createAction(
  OnLeasePeriodDeleted,
  OnLeasePeriodDeletedError,
  async (data: LeasePeriodDeleteInput): Promise<void> => {
    await apolloClient.mutate({
      mutation: DELETE_LEASEPERIODS,
      variables: {
        data,
      },
    });
    await apolloClient.cache.reset();
  },
);

/**
 * Delete PeriodSubPeriodRelation by filtering with period ID.
 */
export const deletePeriodSubPeriodRelationByFilter = createAction(
  OnPeriodSubPeriodRelationDeleted,
  OnPeriodSubPeriodRelationDeletedError,
  async (filter: PeriodSubPeriodFilter): Promise<void> => {
    await apolloClient.mutate({
      mutation: DELETE_PERIODSUBPERIODRELATIONBYFILTER,
      variables: {
        filter,
      },
    });
    await apolloClient.cache.reset();
  },
);

/**
 * Delete many Lease Period.
 */
export const deleteManyLeasePeriod = createAction(
  OnLeasePeriodDeleted,
  OnLeasePeriodDeletedError,
  async (ids: string[]): Promise<void> => {
    const dataArr: LeasePeriodDeleteInput[] = ids.map((id) => ({ id }));

    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: DELETE_LEASEPERIODS,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();
  },
);

/**
 * Get all Subperiods.
 */
export const getAllSubperiods = createAction(
  OnSubperiodList,
  OnSubperiodListError,
  async (
    filter?: LeaseSubPeriodFilter,
    first?: number,
    skip?: number,
  ): Promise<LeaseSubPeriodListResponse> => {
    const {
      data: { leaseSubPeriodsList },
    } = await apolloClient.query<{
      leaseSubPeriodsList: LeaseSubPeriodListResponse;
    }>({
      query: READ_SUBPERIODS,
      variables: {
        filter,
        first,
        skip,
      },
    });

    apolloClient.cache.reset();

    return leaseSubPeriodsList;
  },
);

/**
 * Create a Subperiod.
 */
export const createSubperiod = createAction(
  OnSubperiodCreated,
  OnSubperiodCreatedError,
  async (
    data: Omit<LeaseSubPeriodCreateInput, 'description'>,
  ): Promise<void> => {
    await apolloClient.mutate({
      mutation: CREATE_SUBPERIOD,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Update a Subperiod.
 */
export const updateSubperiod = createAction(
  OnSubperiodUpdated,
  OnSubperiodUpdatedError,
  async (
    data: Omit<LeaseSubPeriodUpdateInput, 'description'>,
  ): Promise<void> => {
    await apolloClient.mutate({
      mutation: UPDATE_SUBPERIOD,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Delete a Subperiod.
 */
export const deleteSubperiods = createAction(
  OnSubperiodDeleted,
  OnSubperiodDeletedError,
  async (data: LeaseSubPeriodDeleteInput): Promise<void> => {
    await apolloClient.mutate({
      mutation: DELETE_SUBPERIOD,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Edit unit.
 */

export const editUnitAction = createAction(
  OnUnitEdited,
  OnUnitEditedError,

  async (
    data: UnitUpdateInput,
    id: string,
  ): Promise<UnitEditResult | undefined> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: EDIT_UNIT,
      variables: {
        data,
        id,
      },
    });

    apolloClient.cache.reset();

    return response.data?.unitEditResolver;
  },
);

/**
 * Delete many Subperiods.
 */
export const deleteManySubperiods = createAction(
  OnSubperiodDeleted,
  OnSubperiodDeletedError,
  async (ids: string[]): Promise<void> => {
    const dataArr: LeaseSubPeriodDeleteInput[] = ids.map((id) => ({ id }));

    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: DELETE_SUBPERIOD,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();
  },
);

/**
 * Create building layout.
 */
export const createBuildingLayoutAction = createAction(
  OnBuildingLayoutCreated,
  OnBuildingLayoutCreatedError,
  async (data: BuildingLayoutCreateInput): Promise<string | undefined> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: CREATE_BUILDING_LAYOUT,
      variables: {
        data,
      },
    });

    return response.data?.buildingLayoutCreate?.id;
  },
);

/**
 * Create building.
 */

export const createBuildingAction = createAction(
  OnBuildingCreated,
  OnBuildingCreatedError,
  async (
    propertyId: string,
    data: BuildingCreateInput,
  ): Promise<Building | undefined> => {
    if (data?.buildingLayoutBuildingRelation?.connect?.id) {
      const response = await apolloClient.mutate<Mutation>({
        mutation: CREATE_BUILDING,
        variables: {
          data,
        },
      });
      return response.data?.buildingCreate;
    } else {
      const buildingLayoutData = await apolloClient.query<Query>({
        query: GET_BUILDING_LAYOUT_ID,
        variables: {
          id: propertyId,
        },
      });

      const layoutData = {
        ...data,
        buildingLayoutBuildingRelation: {
          connect: {
            id:
              buildingLayoutData?.data?.buildingLayoutsList?.items[0]?.id || '',
          },
        },
      };

      const response = await apolloClient.mutate<Mutation>({
        mutation: CREATE_BUILDING,
        variables: {
          data: layoutData,
        },
      });
      return response.data?.buildingCreate;
    }
  },
);

/**
 * Get building layout with proerty id.
 */
export const getBuildingLayout = createAction(
  OnGetBuildingLayout,
  OnGetBuildingLayoutError,
  async (propertyId: string): Promise<BuildingType[]> => {
    const queryResponse = await apolloClient.query<Query>({
      query: GET_BUILDING_LAYOUT,
      fetchPolicy: 'no-cache',
      variables: { propertyId },
    });

    const parsedResponse: BuildingType[] =
      queryResponse.data.property?.buildingLayout?.building?.items.map((b) => ({
        id: b.id || '',
        code: b.code || '',
        // buildings: queryResponse.data.property?.buildingLayout?.building?.items,
        floors: b.buildingFloorRelation?.items.map((f) => ({
          id: f.id,
          number: f.number,
          units: f.floorUnitRelation?.items.map((u) => ({
            id: u.id,
            code: u.code,
            gender: u.gender,
            type: u.unitType?.typeName,
            rooms: u.unitRoomRelation?.items.map((r) => ({
              id: r.id,
              type: r.roomType?.id,
              code: r.code,
              beds: r.roomBedRelation?.items,
            })),
          })),
        })),
      })) as BuildingType[];

    return parsedResponse;
  },
);

/**
 *  Update building data.
 */

export const updateBuildingAction = createAction(
  OnBuildingUpdated,
  OnBuildingUpdatedError,
  async (data: BuildingUpdateInput): Promise<Building | undefined> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_BUILDING,
      variables: {
        data,
      },
    });

    return response.data?.buildingUpdate;
  },
);

// /**
//  * Delete building.
//  */

// export const deleteBuildingAction = createAction(
//   OnBuildingDeleted,
//   OnBuildingDeletedError,
//   async (id: string): Promise<void> => {
//     await apolloClient.mutate<Mutation>({
//       mutation: DELETE_BUILDING,
//       variables: {
//         id,
//       },
//     });
//   },
// );

/**
 * Delete big building.
 */

export const deleteBigBuildingAction = createAction(
  OnBuildingDeleted,
  OnBuildingDeletedError,
  async (id: string): Promise<void> => {
    await apolloClient.mutate<Mutation>({
      mutation: DELETE_BIG_BUILDING,
      variables: {
        id,
      },
    });
  },
);

/**
 * Delete big building.
 */

export const deleteBigFloor = createAction(
  OnFloorDeleted,
  OnFloorDeletedError,
  async (
    id: string,
    floors: FloorType[] | undefined,
    buildingIndex: number | undefined,
    dispatch: Dispatch<BuildingLayoutAction>,
  ): Promise<void> => {
    // Update floors with index greater than the floor being deleted
    if (floors) {
      floors
        .filter(
          (floor) =>
            Number(floor.number) >
            Number(floors.find((f) => f.id === id)?.number),
        )
        .forEach(async (floor) => {
          const data = {
            id: floor.id,
            number: (Number(floor.number) - 1).toString(),
          };

          dispatch({
            type: BuildingLayoutActionTypes.UPDATE_FLOOR_ID,
            payload: {
              buildingIndex,
              floorNumber: data?.number,
              floorId: data?.id,
            },
          });

          apolloClient.mutate<Mutation>({
            mutation: FLOOR_UPDATE,
            variables: {
              data,
            },
          });
        });
    }

    dispatch({
      type: BuildingLayoutActionTypes.DELETE_FLOOR,
      payload: {
        buildingIndex,
        floorId: id,
      },
    });

    await apolloClient.mutate<Mutation>({
      mutation: DELETE_BIG_FLOOR,
      variables: {
        id,
      },
    });
  },
);

/**
 * Add floor.
 */

export const addFloorAction = createAction(
  OnFloorAdded,
  OnFloorAddedError,
  async (data: FloorCreateInput): Promise<Floor | undefined> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: FLOOR_CREATE,
      variables: {
        data,
      },
    });

    return response.data?.floorCreate;
  },
);

// /*
//  * Delete floor.
//  */

// export const deleteFloorAction = createAction(
//   OnFloorDeleted,
//   OnFloorDeletedError,
//   async (id: string): Promise<void> => {
//     await apolloClient.mutate<Mutation>({
//       mutation: FLOOR_DELETE,
//       variables: {
//         id,
//       },
//     });
//   },
// );

/*
 * Delete unit.
 */

export const deleteUnitAction = createAction(
  OnUnitDeleted,
  OnUnitDeletedError,
  async (id: string): Promise<void> => {
    await apolloClient.mutate<Mutation>({
      mutation: UNIT_DELETE,
      variables: {
        id,
      },
    });
  },
);

/**
 * Create unit.
 */

export const createUnitAction = createAction(
  OnUnitCreated,
  OnUnitCreatedError,
  async (data: UnitCreateInput): Promise<Unit | undefined> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UNIT_CREATE,
      variables: {
        data,
      },
    });

    apolloClient.cache.reset();

    return response.data?.unitCreate;
  },
);

/**
 * Get property information.
 */
export const getPropertyInformation = createAction(
  OnGetPropertyInformation,
  OnGetPropertyInformationError,
  async (propertyId: string): Promise<Property> => {
    const response = await apolloClient.query<Query>({
      query: GET_PROPERTY_INFORMATION,
      variables: { propertyId },
    });

    return response.data.property as Property;
  },
);

export const propertyUpdate = createAction(
  onPropertyUpdateEvent,
  onPropertyUpdateEventError,
  async (
    data: Property & {
      clientId?: string;
      apiKey?: string;
      apiSecret?: string;
    },
    images: PropertyImageFile[],
    originalPropertyImages?: PropertyImage[],
  ) => {
    let propertyCheckbookAccountRelation;

    if (data.clientId || data.apiKey || data.apiSecret) {
      propertyCheckbookAccountRelation = {
        create: {
          clientId: data?.clientId || '',
          apiKey: data?.apiKey || '',
          apiSecret: data?.apiSecret || '',
        },
      };
    }

    const parsedData: PropertyUpdateInput = {
      name: data?.name,
      email: data.email,
      street: data?.street,
      city: data.city,
      state: data?.state,
      zipCode: data?.zipCode,
      stateCode: data?.stateCode,
      zipCodeSuffix: data?.zipCodeSuffix,
      location: data?.location,
      phone: data?.phone,
      type: data?.type,
      buildings: Number(data?.buildings),
      units: Number(data?.units),
      beds: Number(data?.beds),
      closedDate:
        data?.closedDate && moment(data.closedDate).format('YYYY-MM-DD'),
      propertyCheckbookAccountRelation: propertyCheckbookAccountRelation,
      propertyImagePropertyRelation: {
        disconnect: originalPropertyImages
          ?.filter((propertyImage) => {
            const isInImageList = images.find(
              (i) => i.fileId === propertyImage?.file?.fileId,
            );
            return !isInImageList;
          })
          .map((propertyImage) => ({ id: propertyImage.id })),
        create: images
          ?.filter((i) => {
            const isInImageList = originalPropertyImages?.find(
              (propertyImage) => i.fileId === propertyImage?.file?.fileId,
            );
            return !isInImageList;
          })
          .map((i) => ({
            file: {
              create: {
                fileId: i.fileId,
              },
            },
            caption: i.caption || '',
          })),
        update: originalPropertyImages
          ?.filter((propertyImage) => {
            const isInImageList = images.find(
              (i) => i.fileId === propertyImage?.file?.fileId,
            );
            return isInImageList;
          })
          .map((propertyImage) => {
            const image = images.find(
              (i) => i.fileId === propertyImage?.file?.fileId,
            );
            return {
              data: {
                id: propertyImage.id,
                caption: image?.caption,
              },
            };
          }),
      },
      stripeCustomerId: data?.stripeCustomerId,
      timezone: data?.timezone,
    };

    await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_INFORMATION,
      variables: { data: parsedData, propertyId: data.id },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Get all Payment Plans.
 */
export const getAllPaymentPlans = createAction(
  onFetchPaymentPlansEvent,
  onFetchPaymentPlansEventError,
  async (
    propertyId: string,
    leasePeriodId: string,
  ): Promise<PaymentPlanListResponse> => {
    const {
      data: { paymentPlansList },
    } = await apolloClient.query<{
      paymentPlansList: PaymentPlanListResponse;
    }>({
      query: FETCH_PAYMENT_PLANS_LIST,
      variables: {
        propertyId,
        leasePeriodId,
      },
    });

    return paymentPlansList;
  },
);
/**
 * Get all Payment Plans.
 */
export const getAllOptionalPreferences = createAction(
  onFetchOptionalPreferencesEvent,
  onFetchOptionalPreferencesEventError,
  async (
    propertyId: string,
    leaseSubPeriodId: string,
  ): Promise<OptionalPreferenceListResponse> => {
    const {
      data: { optionalPreferencesList },
    } = await apolloClient.query<{
      optionalPreferencesList: OptionalPreferenceListResponse;
    }>({
      query: FETCH_OPTIONAL_PREFERENCE_LIST,
      variables: {
        propertyId,
        subPeriods: [leaseSubPeriodId],
      },
    });

    return optionalPreferencesList;
  },
);

/**
 * Create a Payment Plan.
 */
export const createOptionalPreference = createAction(
  onOptionalPreferenceCreatedEvent,
  onOptionalPreferenceCreatedEventError,
  async (data: OptionalPreferenceCreateInput): Promise<OptionalPreference> => {
    const { data: response } = await apolloClient.mutate<{
      optionalPreferenceCreate: OptionalPreference;
    }>({
      mutation: CREATE_OPTIONAL_PREFERENCE_MUTATION,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();

    return response?.optionalPreferenceCreate || {};
  },
);
/**
 * Update a Payment Plans on Beds.
 */
export const createBedPaymentPlan = createAction(
  onBedPaymentPlanCreatedEvent,
  onBedPaymentPlanCreatedEventError,
  async (datas: AvailabilityCreateInput[]): Promise<void> => {
    const promises = datas.map(async (data) =>
      apolloClient.mutate({
        mutation: CREATE_BED_PAYMENT_PLANS,
        variables: {
          data,
        },
      }),
    );

    await Promise.all(promises);
    await apolloClient.cache.reset();
  },
);

export const deleteBedPaymentPlan = createAction(
  onBedPaymentPlanDeletedEvent,
  onBedPaymentPlanDeletedEventError,
  async (id) => {
    await apolloClient.mutate({
      mutation: DELETE_BED_PAYMENT_PLANS,
      variables: {
        id,
      },
    });

    await apolloClient.cache.reset();
  },
);

export const updateUnits = createAction(
  OnUnitUpdated,
  OnUnitUpdatedError,
  async (units: Unit[]): Promise<Unit> => {
    const promises = units.map(async (data) => {
      await apolloClient.mutate<{ updatedUnit: Unit }>({
        mutation: UPDATE_UNIT_MUTATION,
        variables: {
          data,
        },
      });
    });

    await Promise.all(promises);
    await apolloClient.cache.reset();

    return {} as Unit;
  },
);

/*
 * Import residents
 */

export const importResidentsAction = createAction(
  OnImportResidentsEvent,
  OnImportResidentsEventError,
  async (data: ImportResidentData): Promise<ImportResidentsResponse> => {
    const response = await apolloClient.mutate({
      mutation: IMPORT_RESIDENTS,
      variables: {
        data,
      },
    });

    return response.data.ImportResidentsResponse;
  },
);

/**
 * Get property data.
 */
export const getPropertyData = createAction(
  OnGetPropertyData,
  OnGetPropertyDataError,
  async (propertyId: string): Promise<Property> => {
    const response = await apolloClient.query<Query>({
      query: GET_PROPERTY_DATA,
      variables: { propertyId },
    });

    return response.data.property as Property;
  },
);

/**
 * Get property site settings.
 */
export const getPropertySiteSettings = createAction(
  OnGetPropertySiteSettings,
  OnGetPropertySiteSettingsError,
  async (propertyId: string): Promise<SiteSettingListResponse> => {
    const response = await apolloClient.query<Query>({
      query: GET_PROPERTY_SITE_SETTINGS,
      variables: { propertyId },
    });

    return response.data.siteSettingsList as SiteSettingListResponse;
  },
);

/**
 * Get property data for site settings.
 */
export const getPropertyDataForSettings = createAction(
  OnGetPropertyDataForSettings,
  OnGetPropertyDataForSettingsError,
  async (propertyId: string): Promise<Property> => {
    const response = await apolloClient.query<Query>({
      query: GET_PROPERTY_DATA_FOR_SETTINGS,
      variables: { propertyId },
    });

    return response.data.property as Property;
  },
);

/**
 * Create property site settings.
 */
export const createPropertySiteSettings = createAction(
  onCreatePropertySiteSettings,
  onCreatePropertySiteSettingsError,
  async (
    siteSettings: SiteSettingCreateInput,
    propertyId: string,
  ): Promise<void> => {
    await apolloClient.mutate({
      mutation: CREATE_PROPERTY_SITE_SETTINGS,
      variables: { data: siteSettings },
    });

    await apolloClient.mutate({
      mutation: gql`
        mutation UPDATE_HAS_CHANGED_SETTINGS($data: PropertyUpdateInput!) {
          propertyUpdate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data: {
          hasChangedSiteSettings: true,
          id: propertyId,
        },
      },
    });

    await apolloClient.cache.reset();
  },
);

/**
 * Update property site settings.
 */
export const updatePropertySiteSettings = createAction(
  onPropertySiteSettingsUpdate,
  onPropertySiteSettingsUpdateError,
  async (
    siteSettingId: string,
    siteSettings: SiteSettingUpdateInput,
    propertyId?: string,
  ): Promise<void> => {
    await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_SITE_SETTINGS,
      variables: { siteSettingId, data: siteSettings },
    });

    if (propertyId) {
      await apolloClient.mutate({
        mutation: gql`
          mutation UPDATE_HAS_CHANGED_SETTINGS($data: PropertyUpdateInput!) {
            propertyUpdate(data: $data) {
              id
            }
          }
        `,
        variables: {
          data: {
            hasChangedSiteSettings: true,
            id: propertyId,
          },
        },
      });
    }

    await apolloClient.cache.reset();
  },
);

/**
 * Update Property data.
 */

export const propertyPageUpdate = createAction(
  onPropertyPageUpdate,
  onPropertyPageUpdateError,
  async (
    data: Property,
    previusData: Property | null | undefined,
    images: PropertyImageFile[],
    headerImage: FileValue | undefined,
    primaryImage: FileValue | undefined,
    testimonial: Testimonial[],
    prevTestimonial: Testimonial[],
    editTestimonial: Testimonial[],
    deleteTestimonial: string[],
    originalPropertyImages?: PropertyImage[],
  ) => {
    let primaryPropertyImage;
    let headerImageCondition;
    if (!previusData?.primaryPropertyImage?.fileId) {
      if (primaryImage?.fileId !== '' && primaryImage?.fileId !== undefined) {
        primaryPropertyImage = {
          create: {
            fileId: primaryImage?.fileId,
            filename: primaryImage?.filename,
            public: true,
          },
        };
      }
    } else if (
      previusData?.primaryPropertyImage?.fileId !== primaryImage?.fileId
    ) {
      if (primaryImage?.fileId !== '' && primaryImage?.fileId !== undefined) {
        primaryPropertyImage = {
          update: {
            fileId: primaryImage?.fileId,
            filename: primaryImage?.filename,
            public: true,
          },
        };
      } else {
        primaryPropertyImage = {
          disconnect: {
            id: previusData?.primaryPropertyImage?.id,
          },
        };
      }
    }

    if (!previusData?.headerImage?.fileId) {
      if (headerImage?.fileId !== '' && headerImage?.fileId !== undefined) {
        headerImageCondition = {
          create: {
            fileId: headerImage?.fileId,
            filename: headerImage?.filename,
            public: true,
          },
        };
      }
    } else if (previusData?.headerImage?.fileId !== headerImage?.fileId) {
      if (headerImage?.fileId !== '' && headerImage?.fileId !== undefined) {
        headerImageCondition = {
          update: {
            fileId: headerImage?.fileId,
          },
        };
      } else {
        headerImageCondition = {
          disconnect: {
            id: previusData.headerImage?.id,
          },
        };
      }
    }
    const fileIdTestimonial: Partial<string[]> = prevTestimonial.map(
      (item) => item.image?.fileId,
    );
    const parsedData: PropertyUpdateInput = {
      location: data.location,
      phone: data.phone,
      email: data.email,
      street: data.street,
      city: data.city,
      state: data.state,
      stateCode: data.stateCode,
      zipCode: data.zipCode,
      zipCodeSuffix: data.zipCodeSuffix,
      managerFirstName: data.managerFirstName,
      managerLastName: data.managerLastName,
      websiteUrl: data.websiteUrl,
      officeHours: data.officeHours,
      facebookUrl: data.facebookUrl,
      instagramUrl: data.instagramUrl,
      twitterUrl: data.twitterUrl,
      switchAbout: data.switchAbout,
      switchAdditionalResources: data.switchAdditionalResources,
      switchContactForm: data.switchContactForm,
      switchFeatures: data.switchFeatures,
      switchFriends: data.switchFriends,
      switchGallery: data.switchGallery,
      switchHowItWorks: data.switchHowItWorks,
      switchLatestPost: data.switchLatestPost,
      switchLeasePeriods: data.switchLeasePeriods,
      switchLocation: data.switchLocation,
      switchPeopleSay: data.switchPeopleSay,
      switchSimilarProperties: data.switchSimilarProperties,
      websiteVisibility: data.websiteVisibility,
      isHtmlAboutDescription: data.isHtmlAboutDescription,
      leasePeriodDescription: data.leasePeriodDescription,
      featuresDescription: data.featuresDescription,
      friendsDescription: data.friendsDescription,
      aboutDescription: data.aboutDescription,
      galleryDescription: data.galleryDescription,
      aditionalResourcesDescription: data.aditionalResourcesDescription,
      hasChangedPropertyPage: data.hasChangedPropertyPage,
      subdomain: data?.subdomain || '',
      headerImage: headerImageCondition || [],
      primaryPropertyImage: primaryPropertyImage || [],
      propertyImagePropertyRelation: {
        disconnect: originalPropertyImages
          ?.filter((propertyImage) => {
            const isInImageList = images.find(
              (i) => i.fileId === propertyImage?.file?.fileId,
            );
            return !isInImageList;
          })
          .map((propertyImage) => ({ id: propertyImage.id })),
        create: images
          ?.filter((i) => {
            const isInImageList = originalPropertyImages?.find(
              (propertyImage) => i.fileId === propertyImage?.file?.fileId,
            );
            return !isInImageList;
          })
          .map((i) => ({
            file: {
              create: {
                fileId: i.fileId,
              },
            },
            caption: i.caption || '',
            position: i?.position || 0,
            type: i?.type,
          })),
        update: originalPropertyImages
          ?.filter((propertyImage) => {
            const isInImageList = images.find(
              (i) => i.fileId === propertyImage?.file?.fileId,
            );
            return isInImageList;
          })
          .map((propertyImage) => {
            const image = images.find(
              (i) => i.fileId === propertyImage?.file?.fileId,
            );
            return {
              data: {
                id: propertyImage.id,
                caption: image?.caption,
                position: image?.position || 0,
              },
            };
          }),
      },
      testimonialProperty: {
        disconnect: deleteTestimonial.map((testimonialId) => ({
          id: testimonialId,
        })),
      },
    };
    const testimonialWhitOutEdit: Testimonial[] = testimonial?.filter(
      (filterEdit) => filterEdit.id === undefined,
    );
    const dataTestimonial: TestimonialCreateInput[] = testimonialWhitOutEdit
      ?.filter(
        (item) =>
          !fileIdTestimonial
            .map((element) => element)
            .includes(item.image?.fileId),
      )
      .map((mapTestimonial) => ({
        content: mapTestimonial.content,
        name: mapTestimonial.name,
        image: {
          create: {
            fileId: mapTestimonial.image?.fileId,
            filename: mapTestimonial.image?.filename,
            public: true,
          },
        },
        enable: mapTestimonial.enable,
        testimonialPropertyRelation: { connect: { id: data.id } },
      }));
    const dataEditTestimonial: TestimonialUpdateInput[] = editTestimonial.map(
      (mapTestimonialEdit) => {
        let validateImage;
        const lastImgId = prevTestimonial.find(
          (element) => element.id === mapTestimonialEdit.id,
        )?.image?.fileId;
        if (lastImgId !== mapTestimonialEdit.image?.fileId) {
          validateImage = {
            image: {
              update: {
                fileId: mapTestimonialEdit.image?.fileId,
              },
            },
          };
        }
        return {
          content: mapTestimonialEdit.content,
          name: mapTestimonialEdit.name,
          id: mapTestimonialEdit.id,
          enable: mapTestimonialEdit.enable,
          ...validateImage,
        };
      },
    );

    const propertyId: PropertyKeyFilter = {
      id: data.id,
    };
    Promise.all(
      dataTestimonial.map(async (item) => {
        await apolloClient.mutate({
          mutation: CREATE_TESTIMONIAL,
          variables: { data: item },
        });
      }),
    );

    Promise.all(
      dataEditTestimonial.map(async (item) => {
        await apolloClient.mutate({
          mutation: UPDATE_TESTIMONIAL,
          variables: { data: item },
        });
      }),
    );

    await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_PAGE,
      variables: { data: parsedData, propertyId },
    });

    await apolloClient.cache.reset();
  },
);

export const updatePropertyData = createAction(
  OnUpdatePropertyDataEvent,
  OnUpdatePropertyDataEventError,
  async (data: Property) => {
    const parsedData: PropertyUpdateInput = {
      location: data.location,
      phone: data.phone,
      email: data.email,
      street: data.street,
      city: data.city,
      state: data.state,
      stateCode: data.stateCode,
      zipCode: data.zipCode,
      zipCodeSuffix: data.zipCodeSuffix,
      managerFirstName: data.managerFirstName,
      managerLastName: data.managerLastName,
      websiteUrl: data.websiteUrl,
      officeHours: data.officeHours,
      facebookUrl: data.facebookUrl,
      instagramUrl: data.instagramUrl,
      twitterUrl: data.twitterUrl,
      switchAbout: data.switchAbout,
      switchAdditionalResources: data.switchAdditionalResources,
      switchContactForm: data.switchContactForm,
      switchFeatures: data.switchFeatures,
      switchFriends: data.switchFriends,
      switchGallery: data.switchGallery,
      switchHowItWorks: data.switchHowItWorks,
      switchLatestPost: data.switchLatestPost,
      switchLeasePeriods: data.switchLeasePeriods,
      switchLocation: data.switchLocation,
      switchPeopleSay: data.switchPeopleSay,
      switchSimilarProperties: data.switchSimilarProperties,
      websiteVisibility: data.websiteVisibility,
      isHtmlAboutDescription: data.isHtmlAboutDescription,
      leasePeriodDescription: data.leasePeriodDescription,
      featuresDescription: data.featuresDescription,
      friendsDescription: data.friendsDescription,
      aboutDescription: data.aboutDescription,
      galleryDescription: data.galleryDescription,
      aditionalResourcesDescription: data.aditionalResourcesDescription,
      stripeAccountId: data.stripeAccountId,
    };

    const propertyId: PropertyKeyFilter = {
      id: data.id,
    };

    await apolloClient.mutate({
      mutation: UPDATE_PROPERTY_PAGE,
      variables: { data: parsedData, propertyId },
    });
  },
);

/**
 * Select city by state.
 */

export const getPaymentPlan = createAction(
  onFetchPaymentPlanEvent,
  onFetchPaymentPlanEventError,
  async (id: string): Promise<PaymentPlan> => {
    // #{ adminCode: { equals: "NY" } }
    const response = await apolloClient.query<Query>({
      query: FETCH_PAYMENT_PLAN_BY_ID,
      variables: { id },
    });

    return response.data.paymentPlan as PaymentPlan;
  },
);

/**
 * Select city by state.
 */

export const updatePaymentPlanItem = createAction(
  onUpdatePaymentPlanItemEvent,
  onUpdatePaymentPlanItemEventError,
  async (data: PaymentPlanItemUpdateInput): Promise<PaymentPlanItem> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_PAYMENT_PLAN_ITEM,
      variables: { data },
    });
    await apolloClient.cache.reset();
    return response.data?.paymentPlanItemUpdate as PaymentPlanItem;
  },
);
export const updatePaymentPlan = createAction(
  onUpdatePaymentPlanEvent,
  onUpdatePaymentPlanEventError,
  async (data: PaymentPlanUpdateInput): Promise<PaymentPlan> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_PAYMENT_PLAN,
      variables: { data },
    });
    await apolloClient.cache.reset();
    return response.data?.paymentPlanUpdate as PaymentPlan;
  },
);
/**
 * Select city by state.
 */

export const CreatePaymentPlanItem = createAction(
  onCreatePaymentPlanItemEvent,
  onCreatePaymentPlanItemEventError,
  async (data: PaymentPlanItemCreateInput): Promise<PaymentPlanItem> => {
    let newData;
    if (data.dueDate === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { dueDate, ...rest } = data;
      newData = rest;
    } else {
      newData = data;
    }
    const response = await apolloClient.mutate<Mutation>({
      mutation: CREATE_PAYMENT_PLAN_ITEM,
      variables: { newData },
    });
    await apolloClient.cache.reset();
    return response.data?.paymentPlanItemCreate as PaymentPlanItem;
  },
);
/**
 * Select city by state.
 */

export const deletePaymentPlanItem = createAction(
  onDeletePaymentPlanItemEvent,
  onDeletePaymentPlanItemEventError,
  async (id: string): Promise<SuccessResponse> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: DELETE_PAYMENT_PLAN_ITEM,
      variables: { id },
    });
    await apolloClient.cache.reset();
    return response.data?.paymentPlanItemDelete as SuccessResponse;
  },
);

export const deletePaymentPlan = createAction(
  onDeletePaymentPlanEvent,
  onDeletePaymentPlanEventError,
  async (data: PaymentPlanDeleteInput): Promise<SuccessResponse> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: DELETE_PAYMENT_PLAN,
      variables: { data },
    });
    await apolloClient.cache.reset();
    return response.data?.paymentPlanDelete as SuccessResponse;
  },
);

/**
 * Select city by state.
 */

export const getOptionalPreference = createAction(
  onFetchOptionalPreferenceEvent,
  onFetchOptionalPreferenceEventError,
  async (id: string): Promise<OptionalPreference> => {
    // #{ adminCode: { equals: "NY" } }
    const response = await apolloClient.query<Query>({
      query: FETCH_OPTIONAL_PREFERENCE_BY_ID,
      variables: { id },
    });

    return response.data.optionalPreference as OptionalPreference;
  },
);

/**
 * Select city by state.
 */

export const updateOptionalPreferenceItem = createAction(
  onUpdateOptionalPreferenceItemEvent,
  onUpdateOptionalPreferenceItemEventError,
  async (
    data: OptionalPreferenceItemUpdateInput,
  ): Promise<OptionalPreferenceItem> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_OPTIONAL_PREFERENCE_ITEM,
      variables: { data },
    });
    await apolloClient.cache.reset();
    return response.data
      ?.optionalPreferenceItemUpdate as OptionalPreferenceItem;
  },
);
export const updateOptionalPreference = createAction(
  onUpdateOptionalPreferenceEvent,
  onUpdateOptionalPreferenceEventError,
  async (data: OptionalPreferenceUpdateInput): Promise<OptionalPreference> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_OPTIONAL_PREFERENCE,
      variables: { data },
    });
    await apolloClient.cache.reset();
    return response.data?.optionalPreferenceUpdate as OptionalPreference;
  },
);
/**
 * Select city by state.
 */

export const createOptionalPreferenceItem = createAction(
  onCreateOptionalPreferenceItemEvent,
  onCreateOptionalPreferenceItemEventError,
  async (
    data: OptionalPreferenceItemCreateInput,
  ): Promise<OptionalPreferenceItem> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: CREATE_OPTIONAL_PREFERENCE_ITEM,
      variables: { data },
    });

    await apolloClient.cache.reset();
    return response.data
      ?.optionalPreferenceItemCreate as OptionalPreferenceItem;
  },
);

export const createLedgersAction = createAction(
  createLedgerEvent,
  createLedgerError,
  async (data: LedgerCreateInput) => {
    apolloClient.mutate<Mutation>({
      mutation: CREATE_LEDGER,
      variables: {
        data,
      },
    });

    apolloClient.cache.reset();

    return [{}];
  },
);
/**
 * Select city by state.
 */

export const deleteOptionalPreferenceItem = createAction(
  onDeleteOptionalPreferenceItemEvent,
  onDeleteOptionalPreferenceItemEventError,
  async (id: string): Promise<SuccessResponse> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: DELETE_OPTIONAL_PREFERENCE_ITEM,
      variables: { id },
    });
    await apolloClient.cache.reset();
    return response.data?.optionalPreferenceItemDelete as SuccessResponse;
  },
);

export const deleteOptionalPreference = createAction(
  onDeleteOptionalPreferenceEvent,
  onDeleteOptionalPreferenceEventError,
  async (data: OptionalPreferenceDeleteInput): Promise<SuccessResponse> => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: DELETE_OPTIONAL_PREFERENCE,
      variables: { data },
    });
    await apolloClient.cache.reset();
    return response.data?.optionalPreferenceDelete as SuccessResponse;
  },
);
/**
 * Delete many Lease Period.
 */
export const deleteManyOptionalPreference = createAction(
  onDeleteManyOptionalPreferenceEvent,
  onDeleteManyOptionalPreferenceEventError,
  async (dataOptional: OptionalPreference[]): Promise<void> => {
    const filterData: OptionalPreference | undefined = dataOptional.find(
      (item) => item.status === 'APPROVED',
    );
    if (filterData) throw new Error('Entries must not be approved for delete');
    const dataArr: OptionalPreferenceDeleteInput[] = dataOptional.map(
      ({ id }) => ({ id }),
    );
    const promises = dataArr.map(async ({ id }) => {
      await apolloClient.mutate({
        mutation: DELETE_OPTIONAL_PREFERENCE,
        variables: {
          id,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();
  },
);
export const deleteManyOptionalPreferenceItem = createAction(
  onDeleteManyOptionalPreferenceItemEvent,
  onDeleteManyOptionalPreferenceItemEventError,
  async (
    dataOptional: OptionalPreference[],
    paymentStatus: boolean,
  ): Promise<void> => {
    if (paymentStatus)
      throw new Error('Entries must not be approved for delete');
    const dataArr: OptionalPreferenceDeleteInput[] = dataOptional.map(
      ({ id }) => ({ id }),
    );
    const promises = dataArr.map(async ({ id }) => {
      await apolloClient.mutate({
        mutation: DELETE_OPTIONAL_PREFERENCE_ITEM,
        variables: {
          id,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();
  },
);
export const deleteManyPaymentPlanItem = createAction(
  onDeleteManyPaymentPlanItemEvent,
  onDeleteManyPaymentPlanItemEventError,
  async (ids: string[], paymentStatus): Promise<void> => {
    if (paymentStatus)
      throw new Error('Entries must not be approved for delete');
    const dataArr: PaymentPlanDeleteInput[] = ids.map((id) => ({ id }));
    const promises = dataArr.map(async ({ id }) => {
      await apolloClient.mutate({
        mutation: DELETE_PAYMENT_PLAN_ITEM,
        variables: {
          id,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();
  },
);
/**
 * Delete many Lease Period.
 */
export const deleteManyPaymentPlan = createAction(
  onDeleteManyPaymentPlanEvent,
  onDeleteManyPaymentPlanEventError,
  async (dataPayment: PaymentPlan[]): Promise<void> => {
    const filterData: PaymentPlan | undefined = dataPayment.find(
      (item) => item.status === 'APPROVED',
    );
    if (filterData) throw new Error('Entries must not be approved for delete');
    const dataArr: PaymentPlanDeleteInput[] = dataPayment.map(({ id }) => ({
      id,
    }));
    const promises = dataArr.map(async (data) => {
      await apolloClient.mutate({
        mutation: DELETE_PAYMENT_PLAN,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();
  },
);
export const getPropertyLateFees = createAction(
  OnGetPropertyLateFees,
  OnGetPropertyLateFeesError,
  async (id: string): Promise<LateFeeListResponse> => {
    const response = await apolloClient.query<Query>({
      query: GET_PROPERTY_LATE_FEES,
      variables: { id },
    });

    return response?.data?.lateFeesList as LateFeeListResponse;
  },
);

export const toggleLateFee = createAction(
  OnUpdateLateFee,
  OnUpdateLateFeeError,
  async (id: string, status: string): Promise<LateFee> => {
    const response = await apolloClient.mutate({
      mutation: TOGGLE_LATE_FEE,
      variables: { id, status },
    });

    await apolloClient.cache.reset();

    return response.data.lateFeeUpdate as LateFee;
  },
);

export const createLateFee = createAction(
  OnCreateLateFee,
  OnCreateLateFeeError,
  async (data: LateFeeCreateInput): Promise<LateFee> => {
    const res = await apolloClient.mutate({
      mutation: CREATE_LATE_FEE,
      variables: { data },
    });

    await apolloClient.cache.reset();

    return res.data?.lateFeeCreate as LateFee;
  },
);

export const editLateFee = createAction(
  OnEditLateFee,
  OnEditLateFeeError,
  async (data: LateFeeUpdateInput): Promise<LateFee> => {
    const res = await apolloClient.mutate({
      mutation: UPDATE_LATE_FEE,
      variables: { data },
    });

    await apolloClient.cache.reset();

    return res.data?.lateFeeCreate as LateFee;
  },
);

export const deleteLateFee = createAction(
  OnDeleteLateFee,
  OnDeleteLateFeeError,
  async (data: LateFeeDeleteInput): Promise<boolean> => {
    const res = await apolloClient.mutate({
      mutation: DELETE_LATE_FEE,
      variables: { data },
    });

    await apolloClient.cache.reset();

    return res.data?.success;
  },
);

export const updateAvailabilitiesGender = createAction(
  onMutateAvailability,
  onMutateAvailabilityError,
  async (units: Unit[], leasePeriod) => {
    const availabilityPromises = units.map(async (unit) => {
      const data = {
        gender: {
          set: unit.gender,
        },
      };

      const filter: AvailabilityFilter = {
        bed: {
          room: {
            unit: {
              id: {
                equals: unit?.id,
              },
            },
          },
        },
        leasePeriod: {
          id: {
            equals: leasePeriod,
          },
        },
      };

      await apolloClient.mutate<{ availability: Availability }>({
        mutation: AVAILABILITY_UPDATE_BYFILTER_MUTATION,
        variables: {
          data,
          filter,
        },
      });
    });

    const bedPromises = units.map(async (unit) => {
      const data = {
        gender: {
          set: unit.gender,
        },
      };

      const filter: UnitFilter = {
        id: {
          equals: unit?.id,
        },
      };

      await apolloClient.mutate<{ availability: Unit }>({
        mutation: UNIT_UPDATE_BY_FILTER,
        variables: {
          data,
          filter,
        },
      });
    });

    await Promise.all(availabilityPromises);
    await Promise.all(bedPromises);

    await apolloClient.cache.reset();

    return {} as Availability;
  },
);

export const saveOptionalPreferenceInventory = createAction(
  onSaveOptionalPreferenceInventory,
  onSaveOptionalPreferenceInventoryError,
  async (inventories: OptionalPreferenceInvetory[]) => {
    const promises = inventories.map(
      ({ id, isSold, name, opcionalPreference }) => {
        if (!name) return {};
        if (opcionalPreference) {
          return apolloClient.mutate({
            mutation: CREATE_INVENTORY_ITEM,
            variables: {
              data: {
                name,
                isSold,
                opcionalPreference: {
                  connect: {
                    id: opcionalPreference?.id || '',
                  },
                },
              },
            },
          });
        }
        return apolloClient.mutate({
          mutation: UPDATE_INVENTORY_ITEM,
          variables: {
            data: {
              id,
              name,
              isSold,
            },
          },
        });
      },
    );

    await Promise.all(promises);
    await apolloClient.cache.reset();

    return {} as OptionalPreferenceInvetory;
  },
);

export const saveOptionalPreferenceAdditionalData = createAction(
  onSaveOptionalPreferenceAdditionalData,
  onSaveOptionalPreferenceAdditionalDataError,
  async (additionalDataList: OptionalPreferenceAdditionalDatum[]) => {
    const promises = additionalDataList.map(
      ({ id, required, title, optionalPreference }) => {
        if (!title) return {};
        if (optionalPreference) {
          return apolloClient.mutate({
            mutation: CREATE_ADDITIONAL_DATA_MUTATION,
            variables: {
              data: {
                title,
                required,
                optionalPreference: {
                  connect: {
                    id: optionalPreference?.id || '',
                  },
                },
              },
            },
          });
        }
        return apolloClient.mutate({
          mutation: UPDATE_ADDITIONAL_DATA,
          variables: {
            data: {
              id,
              title,
              required,
            },
          },
        });
      },
    );

    await Promise.all(promises);
    await apolloClient.cache.reset();

    return {} as OptionalPreference;
  },
);

export const fetchPropertyFilterItems = createAction(
  OnGetPropertyFilterItems,
  OnGetPropertyFilterItemsError,
  async (propertyId): Promise<Property> => {
    const response = await apolloClient.query<Query>({
      query: FETCH_PROPERTY_FILTER_QUERY,
      variables: { propertyId },
    });

    return response?.data?.property as Property;
  },
);
/**
 * Get availabilities by lease period.
 */
export const getAllAvailabilities = createAction(
  OnGetAllAvailabilities,
  OnGetAllAvailabilitiesError,
  async (
    leasePeriodId: string,
    page: number,
    pageSize: number,
    filterData?,
  ): Promise<AvailabilityListResponse> => {
    const bedFilter: BedFilter = {
      bedAvailabilityRelation: {
        some: {
          leasePeriod: {
            id: {
              equals: leasePeriodId,
            },
          },
        },
      },

      room: {
        unit: {
          id: filterData.unit
            ? {
                equals: filterData.unit,
              }
            : {},
          floor: {
            id: filterData.floor
              ? {
                  equals: filterData.floor,
                }
              : {},
            building: filterData?.building
              ? {
                  id: {
                    equals: filterData.building,
                  },
                }
              : {},
          },
          unitType: filterData.unitType
            ? {
                id: {
                  equals: filterData.unitType,
                },
              }
            : {},
        },
        roomType: filterData.roomType
          ? {
              id: {
                equals: filterData.roomType,
              },
            }
          : {},
      },
    };

    const {
      data: { bedsList: availabilitiesBasicList },
    } = await apolloClient.query<{
      bedsList: BedListResponse;
    }>({
      query: AVAILABILITY_LIST_BASIC_QUERY,
      variables: {
        filter: bedFilter,
        skip: page * pageSize,
        pageSize,
      },
    });

    const {
      data: { bedsList: availabilitiesOPList },
    } = await apolloClient.query<{
      bedsList: BedListResponse;
    }>({
      query: AVAILABILITY_LIST_OP_QUERY,
      variables: {
        filter: bedFilter,
        skip: page * pageSize,
        pageSize,
      },
    });

    const {
      data: { bedsList: availabilitiesPPList },
    } = await apolloClient.query<{
      bedsList: BedListResponse;
    }>({
      query: AVAILABILITY_LIST_PP_QUERY,
      variables: {
        filter: bedFilter,
        skip: page * pageSize,
        pageSize,
      },
    });

    const availabilitiesList = extend([
      availabilitiesBasicList.items.flatMap(
        (bed) => bed?.bedAvailabilityRelation?.items || [],
      ),
      availabilitiesOPList.items.flatMap(
        (bed) => bed?.bedAvailabilityRelation?.items || [],
      ),
      availabilitiesPPList.items.flatMap(
        (bed) => bed?.bedAvailabilityRelation?.items || [],
      ),
    ]);

    return availabilitiesList as AvailabilityListResponse;
  },
);

/**
 * Get availabilities by lease period.
 */
export const getAvailabilityList = createAction(
  OnGetAvailabilityList,
  OnGetAvailabilityListError,
  async (
    leasePeriodId: string,
    first = 100,
    skip = 0,
    filterData?,
  ): Promise<AvailabilityListResponse> => {
    const filter: AvailabilityFilter = {
      leasePeriod: {
        id: {
          equals: leasePeriodId,
        },
      },
      bed: {
        room: {
          unit: {
            id: filterData.unit
              ? {
                  equals: filterData.unit,
                }
              : {},
            floor: {
              id: filterData.floor
                ? {
                    equals: filterData.floor,
                  }
                : {},
              building: filterData?.building
                ? {
                    id: {
                      equals: filterData.building,
                    },
                  }
                : {},
            },
            unitType: filterData.unitType
              ? {
                  id: {
                    equals: filterData.unitType,
                  },
                }
              : {},
          },
          roomType: filterData.roomType
            ? {
                id: {
                  equals: filterData.roomType,
                },
              }
            : {},
        },
      },
    };

    const {
      data: { availabilitiesList },
    } = await apolloClient.query<{
      availabilitiesList: AvailabilityListResponse;
    }>({
      query: AVAILABILITY_LIST_QUERY,
      variables: {
        filter,
        first,
        skip,
      },
    });

    return availabilitiesList;
  },
);
/**
 * Get availabilities by lease.
 */
export const getAvailabilityByLease = createAction(
  OnGetAvailabilityList,
  OnGetAvailabilityListError,
  async (leaseId: string): Promise<AvailabilityListResponse> => {
    const filter: AvailabilityFilter = {
      bed: {
        bedLeaseRelation: {
          some: {
            id: {
              equals: leaseId,
            },
          },
        },
      },
      leasePeriod: {
        leasePeriodLeaseRelation: {
          some: {
            id: {
              equals: leaseId,
            },
          },
        },
      },
    };

    const {
      data: { availabilitiesList },
    } = await apolloClient.query<{
      availabilitiesList: AvailabilityListResponse;
    }>({
      query: AVAILABILITY_LIST_QUERY,
      variables: {
        filter,
        first: 1,
        skip: 0,
      },
    });

    return availabilitiesList;
  },
);
export const updateAvailabilityAction = createAction(
  onMutateAvailability,
  onMutateAvailabilityError,
  async (availabilityInput: AvailabilityUpdateInput) => {
    const response = await apolloClient.mutate<{
      availabilityUpdate: Availability;
    }>({
      mutation: MUTATE_AVAILABILITY,
      variables: {
        data: availabilityInput,
      },
    });

    await apolloClient.cache.reset();
    return response?.data?.availabilityUpdate;
  },
);

export const updateManyAvailabilitiesAction = createAction(
  onMutateAvailability,
  onMutateAvailabilityError,
  async (
    availabilityInput: AvailabilityUpdateByFilterInput,
    availabilities: string[],
    leasePeriodId: string,
    applyToAll?: boolean,
  ) => {
    const filter: AvailabilityFilter = {
      id: !applyToAll
        ? {
            in: availabilities.map((id) => id),
          }
        : undefined,
      // OR: availabilities.map((id) => ({
      //   id: {
      //     equals: id,
      //   },
      // })),
      leasePeriod: leasePeriodId
        ? {
            id: {
              equals: leasePeriodId,
            },
          }
        : undefined,
    };
    const response = await apolloClient.mutate<{
      availabilityUpdate: Availability;
    }>({
      mutation: AVAILABILITY_UPDATE_BYFILTER_MUTATION,
      variables: {
        data: availabilityInput,
        filter,
      },
    });
    apolloClient.cache.reset();
    return response?.data?.availabilityUpdate;
  },
);

export const assignPaymentPlansToMany = createAction(
  onMutateAvailability,
  onMutateAvailabilityError,
  async (
    availabilities: Availability[],
    paymentPlans: PaymentPlan[],
    types,
    leasePeriodId?: string,
    applyToAll?: boolean,
  ): Promise<Availability> => {
    let availabilitiesToProcess: Availability[] = availabilities;

    if (applyToAll) {
      const {
        data: { availabilitiesList },
      } = await apolloClient.query<{
        availabilitiesList: AvailabilityListResponse;
      }>({
        query: gql`
          query GET_ALL_AVAILABILITIES($periodId: ID!) {
            availabilitiesList(
              filter: { leasePeriod: { id: { equals: $periodId } } }
            ) {
              items {
                id
              }
            }
          }
        `,
        variables: {
          periodId: leasePeriodId,
        },
      });

      availabilitiesToProcess = availabilitiesList.items as Availability[];
    }

    const promises = availabilitiesToProcess.map(async (availability) => {
      const create: Availability_AvailabilityPlaymentPlanCreateInput[] = [];

      if (types.newResident) {
        const noSavedPaymentPlans = paymentPlans.filter(
          (payment) =>
            !availability.availabilityPaymentPlanRelation?.items
              ?.filter(
                (p) => p.type === AvailabilityPaymentPlanType.NEW_RESIDENT,
              )
              ?.find((p) => p.paymentPlan?.id === payment.id),
        );
        noSavedPaymentPlans?.forEach((p) => {
          create.push({
            paymentPlan: {
              connect: {
                id: p.id,
              },
            },
            type: AvailabilityPaymentPlanType.NEW_RESIDENT,
          });
        });
      }

      if (types.renewalResident) {
        const noSavedPaymentPlans = paymentPlans.filter(
          (payment) =>
            !availability.availabilityPaymentPlanRelation?.items
              ?.filter(
                (p) => p.type === AvailabilityPaymentPlanType.RENEWAL_RESIDENT,
              )
              ?.find((p) => p.paymentPlan?.id === payment.id),
        );
        noSavedPaymentPlans?.forEach((p) => {
          create.push({
            paymentPlan: {
              connect: {
                id: p.id,
              },
            },
            type: AvailabilityPaymentPlanType.RENEWAL_RESIDENT,
          });
        });
      }

      if (types.renewalTransfer) {
        const noSavedPaymentPlans = paymentPlans.filter(
          (payment) =>
            !availability.availabilityPaymentPlanRelation?.items
              ?.filter(
                (p) => p.type === AvailabilityPaymentPlanType.RENEWAL_TRANSFER,
              )
              ?.find((p) => p.paymentPlan?.id === payment.id),
        );
        noSavedPaymentPlans?.forEach((p) => {
          create.push({
            paymentPlan: {
              connect: {
                id: p.id,
              },
            },
            type: AvailabilityPaymentPlanType.RENEWAL_TRANSFER,
          });
        });
      }

      const data: AvailabilityUpdateInput = {
        id: availability.id,
        leasePeriod: {
          connect: {
            id: leasePeriodId,
          },
        },
        availabilityPaymentPlanRelation: {
          create,
        },
      };
      await apolloClient.mutate({
        mutation: MUTATE_AVAILABILITY,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();

    return {} as Availability;
  },
);

export const assignOptionalPreferencesToMany = createAction(
  onMutateAvailability,
  onMutateAvailabilityError,
  async (
    availabilities: Availability[],
    optionalPreferences: OptionalPreference[],
    types,
    leasePeriodId?: string,
    applyToAll?: boolean,
  ): Promise<Availability> => {
    let availabilitiesToProcess: Availability[] = availabilities;

    if (applyToAll) {
      const {
        data: { availabilitiesList },
      } = await apolloClient.query<{
        availabilitiesList: AvailabilityListResponse;
      }>({
        query: gql`
          query GET_ALL_AVAILABILITIES($periodId: ID!) {
            availabilitiesList(
              filter: { leasePeriod: { id: { equals: $periodId } } }
            ) {
              items {
                id
              }
            }
          }
        `,
        variables: {
          periodId: leasePeriodId,
        },
      });

      availabilitiesToProcess = availabilitiesList.items as Availability[];
    }

    const promises = availabilitiesToProcess.map(async (availability) => {
      const create: Availability_AvailabilityOptionalPreferenceCreateInput[] =
        [];

      if (types.newResident) {
        const noSavedOptionalPreferences = optionalPreferences.filter(
          (payment) =>
            !availability.availabilityOptionalPreferenceRelation?.items
              ?.filter(
                (p) => p.type === AvailabilityPaymentPlanType.NEW_RESIDENT,
              )
              ?.find((p) => p.optionalPreference?.id === payment.id),
        );
        noSavedOptionalPreferences?.forEach((p) => {
          create.push({
            optionalPreference: {
              connect: {
                id: p.id,
              },
            },
            type: AvailabilityPaymentPlanType.NEW_RESIDENT,
          });
        });
      }

      if (types.renewalResident) {
        const noSavedOptionalPreferences = optionalPreferences.filter(
          (payment) =>
            !availability.availabilityOptionalPreferenceRelation?.items
              ?.filter(
                (p) => p.type === AvailabilityPaymentPlanType.RENEWAL_RESIDENT,
              )
              ?.find((p) => p.optionalPreference?.id === payment.id),
        );
        noSavedOptionalPreferences?.forEach((p) => {
          create.push({
            optionalPreference: {
              connect: {
                id: p.id,
              },
            },
            type: AvailabilityPaymentPlanType.RENEWAL_RESIDENT,
          });
        });
      }

      if (types.renewalTransfer) {
        const noSavedOptionalPreferences = optionalPreferences.filter(
          (payment) =>
            !availability.availabilityOptionalPreferenceRelation?.items
              ?.filter(
                (p) => p.type === AvailabilityPaymentPlanType.RENEWAL_TRANSFER,
              )
              ?.find((p) => p.optionalPreference?.id === payment.id),
        );
        noSavedOptionalPreferences?.forEach((p) => {
          create.push({
            optionalPreference: {
              connect: {
                id: p.id,
              },
            },
            type: AvailabilityPaymentPlanType.RENEWAL_TRANSFER,
          });
        });
      }

      const data: AvailabilityUpdateInput = {
        id: availability.id,
        leasePeriod: {
          connect: {
            id: leasePeriodId,
          },
        },
        availabilityOptionalPreferenceRelation: {
          create,
        },
      };
      await apolloClient.mutate({
        mutation: MUTATE_AVAILABILITY,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    await apolloClient.cache.reset();

    return {} as Availability;
  },
);
/**
 * Create a Payment Plan.
 */
export const createPaymentPlan = createAction(
  onPaymentPlanCreatedEvent,
  onPaymentPlanCreatedEventError,
  async (
    data: PaymentPlanCreateInput,
    availability?: Availability,
  ): Promise<void> => {
    const validationSchema = yup.object({
      name: yup.string().required('This field is required'),
      // title: yup.string().required('This field is required'),
      leasePeriod: yup
        .string()
        .required('You have to create a Lease period to create a Payment Plan'),
    });

    const leasePeriod = data?.leasePeriod?.connect?.id;

    await validationSchema.validate(
      { ...data, leasePeriod },
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );

    const result = (
      await apolloClient.mutate<Mutation>({
        mutation: CREATE_PAYMENT_PLAN_MUTATION,
        variables: {
          data,
        },
        refetchQueries: [
          {
            query: FETCH_PAYMENT_PLANS_LIST,
            variables: {
              propertyId: data?.property?.connect?.id,
              leasePeriodId: data?.leasePeriod?.connect?.id,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
    ).data?.paymentPlanCreate;
    if (availability) {
      await assignPaymentPlansToMany([availability], [result], {
        newResident: true,
      });
    }
  },
);

export const fetchPaymentPlansAndOptionalPreferences = createAction(
  onFetchPaymentPlanAndOptionalPreferences,
  onFetchPaymentPlanAndOptionalPreferencesError,
  async (
    leasePeriodId: string,
  ): Promise<PaymentPlansAndOptionalPreferences> => {
    const subPeriodsResponse = await apolloClient.query({
      query: FETCH_SUBPERIODS,
      variables: {
        leasePeriodId,
      },
    });

    const period: LeasePeriod = subPeriodsResponse.data.leasePeriod;
    const subPeriods: string[] =
      period?.leasePeriodPeriodSubPeriodRelation?.items?.map((subPeriod) => {
        return subPeriod?.leaseSubPeriod?.id || '';
      }) || [];

    const { data } = await apolloClient.query({
      query: FETCH_PAYMENT_PLANS_AND_OPTIONAL_PREFERENCES,
      variables: {
        leasePeriodId,
        subPeriods,
      },
    });
    return data;
  },
);

export const fetchLedgersByProperty = createAction(
  OnFetchLedgerEvent,
  OnFetchLedgerError,
  async (propertyId) => {
    const filter = {
      subCategory: {
        equals: 'RESERVATION_FEE',
      },
      lease: {
        property: {
          id: {
            equals: propertyId,
          },
        },
      },
    };
    const { data } = await apolloClient.query({
      query: FETCH_LEDGERS,
      variables: {
        filter,
      },
    });
    return data.ledgersList as LedgerListResponse;
  },
);

export const fetchCustomerSubscriptions = createAction(
  OnFetchsubscriptionEvent,
  OnFetchsubscriptionError,
  async (stripeCustomerId) => {
    const { data } = await apolloClient.mutate({
      mutation: GET_CUSTOMER_SUBSCRIPTION_INVOICES,
      variables: {
        stripeCustomerId,
      },
    });

    return data;
  },
);

export const getAvailabilitiesCount = createAction(
  OnGetAllAvailabilitiesCount,
  OnGetAllAvailabilitiesCountError,
  async (
    leasePeriodId: string,
    filterData?,
  ): Promise<AvailabilityListResponse> => {
    const filter: AvailabilityFilter = {
      leasePeriod: {
        id: {
          equals: leasePeriodId,
        },
      },
      bed: {
        room: {
          unit: {
            id: filterData.unit
              ? {
                  equals: filterData.unit,
                }
              : {},
            floor: {
              id: filterData.floor
                ? {
                    equals: filterData.floor,
                  }
                : {},
              building: filterData?.building
                ? {
                    id: {
                      equals: filterData.building,
                    },
                  }
                : {},
            },
            unitType: filterData.unitType
              ? {
                  id: {
                    equals: filterData.unitType,
                  },
                }
              : {},
          },
          roomType: filterData.roomType
            ? {
                id: {
                  equals: filterData.roomType,
                },
              }
            : {},
        },
      },
    };

    const {
      data: { availabilitiesList },
    } = await apolloClient.query<{
      availabilitiesList: AvailabilityListResponse;
    }>({
      query: AVAILABILITY_COUNT_QUERY,
      variables: {
        filter,
      },
    });

    return availabilitiesList as AvailabilityListResponse;
  },
);

/**
 * Get all Subperiods.
 */
export const getPropertySubperiods = createAction(
  OnLeaseSubPeriodList,
  OnLeaseSubPeriodListError,
  async (
    propertyId: string | undefined,
  ): Promise<LeaseSubPeriodListResponse | undefined> => {
    if (!propertyId) return undefined;
    const {
      data: { leaseSubPeriodsList },
    } = await apolloClient.query<{
      leaseSubPeriodsList: LeaseSubPeriodListResponse;
    }>({
      query: FETCH_SUBPERIODS_BY_PROPERTY,
      variables: {
        propertyId,
      },
    });

    return leaseSubPeriodsList;
  },
);

/**
 * Get all Lease Periods.
 */
export const getPropertyLeasePeriods = createAction(
  OnLeasePeriodList,
  OnLeasePeriodListError,
  async (
    propertyId: string | undefined,
  ): Promise<LeasePeriodListResponse | undefined> => {
    if (!propertyId) return undefined;
    const {
      data: { leasePeriodsList },
    } = await apolloClient.query<{
      leasePeriodsList: LeasePeriodListResponse;
    }>({
      query: FETCH_LEASE_PERIODS_BY_PROPERTY,
      variables: {
        propertyId,
      },
    });

    return leasePeriodsList as LeasePeriodListResponse;
  },
);

const createFloor = async (
  floorInput: FloorCreateInput,
): Promise<string | undefined> => {
  const response = await apolloClient.mutate<Mutation>({
    mutation: FLOOR_CREATE,
    variables: {
      data: floorInput,
    },
  });
  return response.data?.floorCreate.id;
};

export const createLayoutFromCSV = createAction(
  OnBuildingLayoutLoaded,
  OnBuildingLayoutLoadedError,
  async (
    buildingLayout: BuildingType[],
    buildingLayoutId: string,
    propertyId: string,
  ): Promise<string> => {
    const buildingsCreatedIds: string[] = [];
    const buildingsPromises = buildingLayout.map(async (building) => {
      const data: BuildingCreateInput = {
        code: building.code || '',
        property: {
          connect: {
            id: propertyId,
          },
        },
        buildingLayoutBuildingRelation: {
          connect: {
            id: buildingLayoutId,
          },
        },
      };
      const response = await apolloClient.mutate<Mutation>({
        mutation: CREATE_BUILDING,
        variables: {
          data,
        },
      });

      if (response?.data?.buildingCreate?.id) {
        buildingsCreatedIds.push(response?.data?.buildingCreate?.id);
      }
      return { building, id: response.data?.buildingCreate.id };
    });

    const buildingsWithId = await Promise.all(buildingsPromises);

    const upload = await apolloClient.mutate<Mutation>({
      mutation: CREATE_BUILDING_UPLOAD,
      variables: {
        data: {
          building: {
            connect: buildingsCreatedIds.map((id) => ({ id })),
          },
        },
      },
    });
    OnBuildingLayoutLoaded.dispatch(
      //@ts-expect-error schema
      upload.data?.buildingLayoutUploadCreate?.id,
    );
    const layoutPromises = buildingsWithId.map(async (building) => {
      const floorsResponse = await apolloClient.mutate<Mutation>({
        mutation: CREATE_FLOORS_RESOLVER,
        variables: {
          buildingLayout: JSON.stringify(building.building.floors),
          buildingId: building.id || '',
          uploadId: upload.data?.buildingLayoutUploadCreate?.id,
        },
      });

      return floorsResponse;
    });
    try {
      CsvBuildings.dispatch(buildingsCreatedIds);
      const runSequentialPromises = (promises: Promise<any>[]) => {
        return promises.reduce((promise, next) => {
          return promise.then(() => next);
        }, Promise.resolve());
      };

      await runSequentialPromises(layoutPromises);
      //await Promise.all(layoutPromises);
      return upload.data?.buildingLayoutUploadCreate?.id || '';
    } catch {
      await Promise.all(
        buildingsCreatedIds.map(async (id) => {
          await apolloClient.mutate<Mutation>({
            mutation: DELETE_BIG_BUILDING,
            variables: {
              id,
            },
          });
        }),
      ); 

      return upload.data?.buildingLayoutUploadCreate?.id || '';
    }
  },
);

export const deleteBuildingsAction = createAction(
  OnBuildingsDelete,
  OnBuildingsDeleteError,
  async (ids: string[]): Promise<void> => {
    await Promise.all(
      ids.map(async (id) => {
        const promise = await apolloClient.mutate<Mutation>({
          mutation: DELETE_BIG_BUILDING,
          variables: {
            id,
          },
        });
        return promise;
      }),
    );
  },
);
