import React, { useEffect } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TransferInfo } from '../../tenant/components/reservation/TransferInfo';
import { Lease } from '@8baselabs/resident-io-shared';
import { LeaseStatusValues } from '../../opportunities/opportunities-types';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '0 5px 21px 0 rgba(171,171,171,0.17)',
    borderRadius: '8px',
    padding: '20px',
  },
  listItem: {
    margin: '1rem 0',
  },
}));

interface Props {
  selectedLease?: Lease;
  userId?: string;
}

export const TransferView: React.FC<Props> = ({
  selectedLease,
  userId,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (!selectedLease) return;

    if (
      selectedLease?.status !== LeaseStatusValues['MOVE-IN'].key &&
      selectedLease?.status !== LeaseStatusValues.APPROVED.key
    ) {
      history.push(`/people/${userId}/profile`);
    }
  }, [history, selectedLease, userId]);

  if (!selectedLease) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box p="40px">
      <Box fontSize="20px" fontWeight="500">
        Transfer
      </Box>
      <Box className={classes.card} mt="30px" mb="50px">
        <Box fontWeight="500" fontSize="16px">
          How it works
        </Box>
        <ul>
          <li className={classes.listItem}>
            The transfer recipient will receive an invitation to log in, select
            optional services and sign a contract
          </li>
          <li className={classes.listItem}>
            The transfer recipient’s contract will begin as of the date
            specified below
          </li>
          <li className={classes.listItem}>
            Any monthly charges will be prorated; any installment charges will
            need to be pro-rated manually
          </li>
          <li className={classes.listItem}>
            The contract will not be transferred until the transfer recipient
            accepts the invitation
          </li>
        </ul>
      </Box>
      <Box fontSize="20px" fontWeight="500" marginBottom="1.5rem">
        Contract Transfer / Bed Assignment
      </Box>
      <TransferInfo selectedLease={selectedLease} userId={userId} />
    </Box>
  );
};
