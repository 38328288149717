import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useCallAction, useEvent } from '@8baselabs/react-simple-state';
import {
  AddReservationSelectResidentApplicationEvent,
  AddReservationUpdateResidentApplicationEventError,
} from '../../../opportunities/opportunities-event';
import { TextFieldPhone } from '../../../../shared/components/ui/inputs/TextFieldPhone';
import {
  SelectDefault,
  SelectOptions,
} from '../../../../shared/components/ui/inputs/SelectDefault';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MUTED_COLOR } from '../../../../shared/theme';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Radio,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { getErrorsAsObject } from '../../../../shared/utils/errors';
import { ValidationError } from 'yup';
import { format } from 'date-fns';
import { updateResidentApplicationLeaseOpportunitiesAction } from '../../../opportunities/opportunities-actions';
import { GOOGLE_API_KEY } from '../../../../shared/constants';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import maleIcon from '../../../user/resources/male.png';
import femaleIcon from '../../../user/resources/female.png';
import { Gender } from '@8baselabs/resident-io-shared/lib/constants';
import csc from 'country-state-city';

interface Props {
  label: string;
  fieldName: string;
  type: string;
  category: string;
  disabled: boolean;
  readOnly?: boolean;
  options?: Array<SelectOptions>;
  conditionField?: string;
  condition?: string;
  isExtraData: boolean;
  required?: boolean;
  section?: string;
}

enum GeoType {
  COUNTRY = 'country',
  STREET_NUMBER = 'street_number',
  STREET = 'route',
  CITY = 'locality',
  STATE = 'administrative_area_level_1',
  ZIP_CODE = 'postal_code',
  ZIP_CODE_SUFFIX = 'postal_code_suffix',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    genderEndIcon: {
      marginLeft: 'auto',
    },
    genderIcons: {
      height: 24,
      objectFit: 'cover',
      width: 24,
    },
    checkboxDisabled: {
      '& .PrivateRadioButtonIcon-checked-24': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

export const ApplicationFormField: React.FC<Props> = ({
  label,
  fieldName,
  category,
  type,
  disabled,
  isExtraData,
  options,
  conditionField,
  condition,
  readOnly,
  required,
  section,
}: Props) => {
  const classes = useStyles();

  const [updateResidentApplicationLease] = useCallAction(
    updateResidentApplicationLeaseOpportunitiesAction,
  );

  const values = useEvent(AddReservationSelectResidentApplicationEvent);
  const errors = useEvent(AddReservationUpdateResidentApplicationEventError);

  let fieldError =
    errors instanceof ValidationError
      ? Boolean(getErrorsAsObject(errors)[fieldName])
      : undefined;
  let fieldHelperText =
    errors instanceof ValidationError
      ? getErrorsAsObject(errors)[fieldName]
      : '';
  if (
    category === 'user' &&
    errors instanceof ValidationError &&
    getErrorsAsObject(errors)[fieldName]?.startsWith('Guarantor')
  ) {
    fieldError = false;
    fieldHelperText = '';
  }
  if (
    category === 'application' &&
    errors instanceof ValidationError &&
    getErrorsAsObject(errors)[fieldName]?.startsWith('Guarantor')
  ) {
    fieldError = false;
    fieldHelperText = '';
  }
  if (
    category === 'guarantor' &&
    errors instanceof ValidationError &&
    !getErrorsAsObject(errors)[fieldName]?.startsWith('Guarantor')
  ) {
    fieldError = false;
    fieldHelperText = '';
  }

  const fieldValue =
    type === 'date'
      ? !isExtraData
        ? values?.[category]?.[fieldName] ?? null
        : values?.[category]?.extraData?.[fieldName] ?? null
      : type === 'countryUs'
      ? !isExtraData
        ? values?.[category]?.[fieldName] ?? 'US'
        : values?.[category]?.extraData?.[fieldName] ?? 'US'
      : !isExtraData
      ? values?.[category]?.[fieldName] ?? ''
      : values?.[category]?.extraData?.[fieldName] ?? '';

  const fieldArray =
    type === 'fieldArray'
      ? !isExtraData
        ? values?.[category]?.[fieldName] ?? [
            JSON.parse(JSON.stringify(options)),
          ]
        : values?.[category]?.extraData?.[fieldName] ?? [
            JSON.parse(JSON.stringify(options)),
          ]
      : [];
  const extraData = 'extraData';

  const handleDispatch = ({ value, name }): void => {
    const valuesToSend = {
      application: values.application,
      user: {
        id: values.user.id,
        firstName: values.user.firstName,
        middleName: values.user.middleName,
        lastName: values.user.lastName,
        preferredName: values.user.preferredName,
        email: values.user.email || '',
        birthdate: values.user.birthdate,
        gender: values.user.gender,
        genderIdentify: values.user.genderIdentify,
        phoneNumber: values.user.phoneNumber,
        homePhone: values.user.homePhone,
        studentIdOrEmployeeNumber: values.user.studentIdOrEmployeeNumber,
        address: values.user.address,
        city: values.user.city,
        state: values.user.state,
        zipCode: values.user.zipCode,
        aboutMe: values.user.aboutMe,
      },
      guarantor: values.guarantor,
      sections: values.sections,
      fields: values.fields,
      fieldsForPartialValidation: values.fieldsForPartialValidation,
    };
    AddReservationSelectResidentApplicationEvent.dispatch(
      !isExtraData
        ? {
            ...valuesToSend,
            [category]: {
              ...valuesToSend?.[category],
              [name]: value,
            },
          }
        : {
            ...valuesToSend,
            [category]: {
              ...valuesToSend?.[category],
              extraData: {
                ...valuesToSend?.[category][extraData],
                [name]: value,
              },
            },
          },
    );
  };
  const [cachedValue, setCachedValue] = useState(fieldValue);
  const [cachedArray, setCachedArray] = useState(fieldArray);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    useDebouncedCallback(
      (
        changedValues, //eslint-disable-line react-hooks/exhaustive-deps
      ) => {
        handleDispatch(changedValues);
      },
      500,
    ),
    [],
  );

  useEffect(() => {
    setCachedValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    setCachedArray(fieldArray);
  }, [values]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = ({ name, value }): void => {
    const changedValues = { name: name, value: value };
    setCachedValue(changedValues.value);
    debounceCallback(changedValues);
  };

  useEffect(() => {
    if (type !== 'countryUs' || values?.[category]?.extraData?.[fieldName])
      return;
    handleChange({ name: fieldName, value: 'US' });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const handleArrayChange = ({ name, value }): void => {
    const changedValues = { name: name, value: value };
    setCachedArray([...changedValues.value]);
    debounceCallback(changedValues);
  };

  const handleBlur = (
    value: string | Date | null,
    isFieldArray?: boolean,
    arrayIdx?: number,
    itemIdx?: number,
  ): void => {
    let tempArray;
    if (
      isFieldArray &&
      typeof arrayIdx === 'number' &&
      typeof itemIdx === 'number'
    ) {
      tempArray = JSON.parse(JSON.stringify(fieldArray));
      tempArray[arrayIdx][itemIdx].value = value;
    }

    updateResidentApplicationLease(
      '',
      Object.assign(
        {},
        values.application,
        category === 'application'
          ? !isExtraData
            ? { [fieldName]: isFieldArray ? tempArray : value }
            : {
                extraData: {
                  ...values.application.extraData,
                  [fieldName]: isFieldArray ? tempArray : value,
                },
              }
          : {},
      ),
      Object.assign(
        {},
        values.user,
        category === 'user'
          ? {
              [fieldName]:
                type === 'date'
                  ? format((value as Date) ?? new Date(), 'yyyy-MM-dd')
                  : value,
            }
          : {},
      ),
      Object.assign(
        {},
        values.guarantor,
        category === 'guarantor'
          ? {
              [fieldName]: isFieldArray
                ? tempArray
                : type === 'date'
                ? format((value as Date) ?? new Date(), 'yyyy-MM-dd')
                : value,
            }
          : {},
      ),
      values.fieldsForPartialValidation,
      'both',
      true,
      undefined,
      false,
      false,
      true,
    );

    return;
  };

  const handleFocus = (): void => {
    if (
      values.fieldsForPartialValidation?.some(
        (field) => field.name === fieldName && category === field.category,
      )
    )
      return;
    AddReservationSelectResidentApplicationEvent.dispatch({
      ...values,
      fieldsForPartialValidation: [
        ...(values.fieldsForPartialValidation ?? []),
        {
          name: fieldName,
          label,
          category,
          required,
          type,
          section,
          conditionField,
          condition,
          options,
        },
      ],
    });
    return;
  };

  const handleAutoComplete = async (e): Promise<void> => {
    const address = await geocodeByPlaceId(e.value.place_id);
    let street = '';
    let streetNumber = '';
    let state = '';
    let city = '';
    let zipCode = '';
    let country = '';

    address[0].address_components.forEach((element) => {
      if (element.types.includes(GeoType.STATE)) {
        state = element.long_name;
      }
      if (element.types.includes(GeoType.CITY)) {
        city = element.long_name;
      }
      if (element.types.includes(GeoType.ZIP_CODE)) {
        zipCode = element.long_name;
      }
      if (element.types.includes(GeoType.COUNTRY)) {
        country = element.short_name;
      }
      if (element.types.includes(GeoType.STREET)) {
        street = element.long_name;
      }
      if (element.types.includes(GeoType.STREET_NUMBER)) {
        streetNumber = element.long_name;
      }
    });

    const line1Field =
      options?.find((option) => option.value === 'line1')?.label ?? 'line1';
    const cityField =
      options?.find((option) => option.value === 'city')?.label ?? 'city';
    const stateField =
      options?.find((option) => option.value === 'state')?.label ?? 'state';
    const zipCodeField =
      options?.find((option) => option.value === 'zipCode')?.label ?? 'zipCode';
    const countryField =
      options?.find((option) => option.value === 'country')?.label ?? 'country';

    AddReservationSelectResidentApplicationEvent.dispatch(
      !isExtraData
        ? {
            ...values,
            [category]: {
              ...values?.[category],
              [fieldName]: e,
              [line1Field]: `${streetNumber} ${street}`,
              [cityField]: city,
              [stateField]: state,
              [zipCodeField]: zipCode,
              [countryField]: country,
            },
          }
        : {
            ...values,
            [category]: {
              ...values?.[category],
              extraData: {
                ...values?.[category][extraData],
                [fieldName]: e,
                [line1Field]: `${streetNumber} ${street}`,
                [cityField]: city,
                [stateField]: state,
                [zipCodeField]: zipCode,
                [countryField]: country,
              },
            },
          },
    );
  };

  const renderField = (): JSX.Element => {
    let handleAdd, handleRemove, handleChangeArrayItem, countryList, stateList;

    if (type === 'country' || type === 'countryUs')
      countryList = csc.getAllCountries();
    if (type === 'stateUs') stateList = csc.getStatesOfCountry('US');

    if (type === 'fieldArray') {
      countryList = csc.getAllCountries();
      stateList = csc.getStatesOfCountry('US');

      handleAdd = () => {
        const tempArray = JSON.parse(JSON.stringify(fieldArray));
        tempArray.push(JSON.parse(JSON.stringify(options)));
        handleDispatch({
          value: tempArray,
          name: fieldName,
        });
      };
      handleRemove = (idx) => {
        const tempArray = JSON.parse(JSON.stringify(fieldArray));
        tempArray.splice(idx, 1);
        handleDispatch({
          value: tempArray,
          name: fieldName,
        });
      };
      handleChangeArrayItem = (value, arrayIdx, itemIdx) => {
        const tempArray = JSON.parse(JSON.stringify(fieldArray));
        tempArray[arrayIdx][itemIdx].value = value;
        handleArrayChange({
          value: tempArray,
          name: fieldName,
        });
      };
    }

    const renderTable = {
      text: (
        <TextField
          fullWidth
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          name={fieldName}
          value={cachedValue}
          onChange={(event) => handleChange(event.currentTarget)}
          disabled={disabled}
          error={fieldError}
          helperText={fieldHelperText}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          type={fieldName === 'ssnNumber' ? 'password' : undefined}
        />
      ),
      email: (
        <TextField
          fullWidth
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          name={fieldName}
          value={cachedValue}
          onChange={(event) => handleChange(event.target)}
          disabled={disabled}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      textarea: (
        <TextField
          fullWidth
          multiline
          rows={4}
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          name={fieldName}
          value={cachedValue}
          onChange={(event) => handleChange(event.target)}
          disabled={disabled}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      cellphone: (
        <TextFieldPhone
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          name={fieldName}
          value={cachedValue}
          onChange={(value) => handleChange({ value: value, name: fieldName })}
          disabled={disabled}
          defaultCountry="us"
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      dropdown: (
        <SelectDefault
          name={fieldName}
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          options={options || []}
          disabled={readOnly}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      radioGender: (
        <>
          <FormControl error={fieldError}>
            <FormLabel>
              {label.toLocaleUpperCase() + (required ? ' *' : '')}
            </FormLabel>
          </FormControl>
          <Grid container spacing={1}>
            {options?.map((option) => (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  startIcon={
                    <img
                      src={
                        option.value === Gender.Male
                          ? maleIcon
                          : option.value === Gender.Female
                          ? femaleIcon
                          : ''
                      }
                      alt={`${option.label} icon`}
                      className={classes.genderIcons}
                    />
                  }
                  classes={{
                    endIcon: classes.genderEndIcon,
                  }}
                  onClick={() =>
                    handleDispatch({ name: fieldName, value: option.value })
                  }
                  endIcon={
                    <Radio
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      disabled
                      className={classes.checkboxDisabled}
                      checked={fieldValue === option.value}
                    />
                  }>
                  {option.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </>
      ),
      longdropdown: (
        <SelectDefault
          name={fieldName}
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          options={options || []}
          disabled={readOnly}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      yesno: (
        <SelectDefault
          name={fieldName}
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          disabled={readOnly}
          options={[
            {
              label: 'Yes',
              value: 'YES',
            },
            {
              label: 'No',
              value: 'NO',
            },
          ]}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      longyesno: (
        <SelectDefault
          name={fieldName}
          disabled={readOnly}
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          options={[
            {
              label: 'Yes',
              value: 'YES',
            },
            {
              label: 'No',
              value: 'NO',
            },
          ]}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      date: (
        <KeyboardDatePicker
          autoOk
          openTo="year"
          views={['year', 'month', 'date']}
          clearable
          fullWidth
          disabled={disabled}
          variant="inline"
          inputVariant="outlined"
          label={label.toLocaleUpperCase() + (required ? ' *' : '')}
          format="MMMM dd, yyyy"
          name={fieldName}
          onChange={(value) => {
            handleFocus();
            handleBlur(value);
            handleChange({
              value: format(value ?? new Date(), 'yyyy-MM-dd'),
              name: fieldName,
            });
          }}
          value={cachedValue?.toString().replace(/-/g, '/')}
          inputProps={{ disabled: true }}
          InputLabelProps={{
            shrink: true,
          }}
          InputAdornmentProps={{
            style: {
              borderLeft: `1px solid ${MUTED_COLOR}`,
              height: 'auto',
            },
          }}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      checkbox: (
        <FormControl
          required
          sx={{ m: 3 }}
          component="fieldset"
          variant="standard"
          error={fieldError}>
          <FormLabel color="primary" component="legend">
            {label?.toLocaleUpperCase()}
          </FormLabel>
          <FormGroup>
            {options?.map((option, idx: number) => (
              <FormControlLabel
                key={`${fieldName}-${idx}`}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'flex',
                  my: 1,
                }}
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={
                      !isExtraData
                        ? values?.[category]?.[fieldName] === option.value
                        : values?.[category]?.extraData?.[fieldName] ===
                          option.value
                    }
                    onChange={() =>
                      handleDispatch({ value: option.value, name: fieldName })
                    }
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      ),
      singlecheckbox: (
        <FormControl
          required
          sx={{ m: 3 }}
          component="fieldset"
          variant="standard"
          error={fieldError}>
          <FormLabel
            color="primary"
            sx={{ wordBreak: 'break-word' }}
            component="legend">
            {label?.toLocaleUpperCase()}
          </FormLabel>
          <FormGroup>
            {options?.map((option, idx: number) => (
              <FormControlLabel
                key={`${fieldName}-${idx}`}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'flex',
                  my: 1,
                }}
                control={
                  <Checkbox
                    onBlur={(e: any) =>
                      handleBlur(e.target.checked ? 'YES' : null)
                    } // eslint-disable-line @typescript-eslint/no-explicit-any
                    onFocus={handleFocus}
                    disabled={disabled}
                    checked={fieldValue === 'YES'}
                    onChange={(_, check) =>
                      handleDispatch({
                        value: check ? 'YES' : null,
                        name: fieldName,
                      })
                    }
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      ),
      fieldArray: (
        <Grid item xs={12}>
          {cachedArray?.map((arrayItems, idx: number) => (
            <Grid item xs={12} key={`fieldArray-${idx}`}>
              <Typography>
                {`${label} #${idx + 1}`}
                {fieldArray.length > 1 && !readOnly && (
                  <Button
                    style={{ textTransform: 'none', marginLeft: '1rem' }}
                    color="primary"
                    onClick={() => handleRemove(idx)}
                    startIcon={<RemoveCircleOutlineIcon />}>
                    Remove {label}
                  </Button>
                )}
              </Typography>
              <Grid container spacing={2}>
                {arrayItems.map((arrayItem, itemIdx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`fieldArrayItem-${idx}-${itemIdx}`}>
                    {arrayItem.type === 'text' && (
                      <TextField
                        fullWidth
                        label={
                          arrayItem.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        name={arrayItem.label}
                        value={arrayItem.value}
                        onChange={(event) =>
                          handleChangeArrayItem(
                            event.target.value,
                            idx,
                            itemIdx,
                          )
                        }
                        disabled={disabled}
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                      />
                    )}
                    {arrayItem.type === 'email' && (
                      <TextField
                        fullWidth
                        label={
                          arrayItem.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        name={arrayItem.label}
                        value={arrayItem.value}
                        onChange={(event) =>
                          handleChangeArrayItem(
                            event.target.value,
                            idx,
                            itemIdx,
                          )
                        }
                        disabled={disabled}
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                      />
                    )}
                    {arrayItem.type === 'phone' && (
                      <TextFieldPhone
                        label={
                          arrayItem?.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        name={arrayItem.label}
                        value={arrayItem.value}
                        onChange={(value) =>
                          handleChangeArrayItem(value, idx, itemIdx)
                        }
                        disabled={disabled}
                        defaultCountry="us"
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                      />
                    )}
                    {arrayItem.type === 'countryUs' && (
                      <SelectDefault
                        name={arrayItem.label}
                        label={
                          arrayItem.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        disabled={readOnly}
                        options={countryList?.map((country) => {
                          return {
                            label: `${country.flag} ${country.name}`,
                            value: country.isoCode,
                          };
                        })}
                        value={arrayItem.value}
                        onChange={(event) =>
                          handleChangeArrayItem(
                            event.target.value,
                            idx,
                            itemIdx,
                          )
                        }
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                      />
                    )}
                    {arrayItem.type === 'employmentStatus' && (
                      <SelectDefault
                        name={arrayItem.label}
                        disabled={readOnly}
                        label={
                          arrayItem.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                        options={[
                          {
                            label: 'Employed - Full Time',
                            value: 'EMPLOYED_FULL_TIME',
                          },
                          {
                            label: 'Employed - Part Time',
                            value: 'EMPLOYED_PART_TIME',
                          },
                          {
                            label: 'Student Loans',
                            value: 'STUDENT_LOANS',
                          },
                          {
                            label: 'Self-Employed - Full Time',
                            value: 'SELF_EMPLOYED_FULL_TIME',
                          },
                          {
                            label: 'Self-Employed - Part-time',
                            value: 'SELF_EMPLOYED_PART_TIME',
                          },
                          {
                            label: 'Family Support',
                            value: 'FAMILY_SUPPORT',
                          },
                        ]}
                        value={arrayItem.value}
                        onChange={(event) =>
                          handleChangeArrayItem(
                            event.target.value,
                            idx,
                            itemIdx,
                          )
                        }
                      />
                    )}
                    {arrayItem.type === 'emergencyContactType' && (
                      <SelectDefault
                        name={arrayItem.label}
                        disabled={readOnly}
                        label={
                          arrayItem.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                        options={[
                          {
                            label: 'Parent',
                            value: 'PARENT',
                          },
                          {
                            label: 'Guardian',
                            value: 'GUARDIAN',
                          },
                          {
                            label: 'Other',
                            value: 'OTHER',
                          },
                        ]}
                        value={arrayItem.value}
                        onChange={(event) =>
                          handleChangeArrayItem(
                            event.target.value,
                            idx,
                            itemIdx,
                          )
                        }
                      />
                    )}
                    {arrayItem.type === 'ferpaContactType' && (
                      <SelectDefault
                        name={arrayItem.label}
                        disabled={readOnly}
                        label={
                          arrayItem.label?.toLocaleUpperCase() +
                          (required && !idx ? ' *' : '')
                        }
                        onBlur={(e) =>
                          handleBlur(e.target.value, true, idx, itemIdx)
                        }
                        onFocus={handleFocus}
                        error={
                          !idx && errors instanceof ValidationError
                            ? Boolean(
                                getErrorsAsObject(errors)[
                                  `${fieldName}${itemIdx}`
                                ],
                              )
                            : undefined
                        }
                        helperText={
                          !idx && errors instanceof ValidationError
                            ? getErrorsAsObject(errors)[
                                `${fieldName}${itemIdx}`
                              ]
                            : ''
                        }
                        options={[
                          {
                            label: 'Parent',
                            value: 'PARENT',
                          },
                          {
                            label: 'Guardian',
                            value: 'GUARDIAN',
                          },
                          {
                            label: 'Other',
                            value: 'OTHER',
                          },
                        ]}
                        value={arrayItem.value}
                        onChange={(event) =>
                          handleChangeArrayItem(
                            event.target.value,
                            idx,
                            itemIdx,
                          )
                        }
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
          {!readOnly && (
            <Button
              style={{ textTransform: 'none' }}
              color="primary"
              onClick={() => handleAdd()}
              startIcon={<AddCircleOutlineIcon />}>
              Add {label}
            </Button>
          )}
        </Grid>
      ),
      country: (
        <SelectDefault
          name={fieldName}
          label={label?.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          options={countryList?.map((country) => {
            return {
              label: `${country.flag} ${country.name}`,
              value: country.isoCode,
            };
          })}
          disabled={readOnly || disabled}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      countryUs: (
        <SelectDefault
          name={fieldName}
          disabled={readOnly || disabled}
          label={label?.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          options={countryList?.map((country) => {
            return {
              label: `${country.flag} ${country.name}`,
              value: country.isoCode,
            };
          })}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      stateUs: (
        <SelectDefault
          name={fieldName}
          disabled={readOnly || disabled}
          label={label?.toLocaleUpperCase() + (required ? ' *' : '')}
          onBlur={(e) => handleBlur(e.target.value)}
          onFocus={handleFocus}
          options={stateList?.map((state) => {
            return { label: `${state.name}`, value: state.isoCode };
          })}
          value={fieldValue}
          onChange={(event) => handleDispatch(event.target)}
          error={fieldError}
          helperText={fieldHelperText}
        />
      ),
      googlePlaces: (
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            types: ['address'],
            componentRestrictions: {
              country: ['us', 'ca'],
            },
          }}
          selectProps={{
            value: fieldValue,
            onChange: handleAutoComplete,
            placeHolder: 'Address',
            styles: {
              label: (provided) => ({
                ...provided,
                fontColor: '#fff',
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 100,
              }),
              input: (provided) => ({
                ...provided,
                font: 'inherit',
                fontSize: '16px',
                height: '50px',
                lineHeight: '50px',
                color: '#3c3c3c',
              }),
              option: (provided) => ({
                ...provided,
                color: '#3c3c3c',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#3c3c3c',
              }),
            },
          }}
          apiKey={GOOGLE_API_KEY}
        />
      ),
    };

    return renderTable[type];
  };

  return (
    <Suspense fallback={<></>}>
      <Grid
        item
        xs={12}
        sm={
          type === 'checkbox' ||
          type === 'singlecheckbox' ||
          type === 'longyesno' ||
          type === 'longdropdown' ||
          type === 'fieldArray' ||
          type === 'googlePlaces'
            ? 12
            : 6
        }>
        {!conditionField
          ? renderField()
          : !isExtraData
          ? values?.[category]?.[conditionField] === condition && renderField()
          : values?.[category]?.extraData?.[conditionField] === condition &&
            renderField()}
      </Grid>
    </Suspense>
  );
};
