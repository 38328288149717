import {
  Gender,
  Property,
  RoomType,
  UnitType,
} from '@8baselabs/resident-io-shared';
import { GridColDef } from '@material-ui/data-grid';
import { OptionalPreference, OptionalPreferenceAdditionalDatum } from '../../schema-types';
import { HandleCSVTypes } from '../../shared/components/DragAndDropCSV';
import {
  BuildingCSVType,
  BuildingType,
  RoomsEnum,
  RoomI,
  UnitI,
} from './settings-types';
/*
 * Get the total units of one builidng layout.
 */
export const getTotalUnits = (buildingLayoutState: BuildingType[]): number => {
  let totalUnits = 0;
  buildingLayoutState.forEach((b) => {
    b?.floors?.forEach((f) => {
      totalUnits = (f?.units?.length || 0) + totalUnits;
    });
  });
  return totalUnits;
};
/*
 * Get the total beds of one builidng layout.
 */
export const getTotalBeds = (buildingLayoutState: BuildingType[]): number => {
  let totalBeds = 0;
  buildingLayoutState.forEach((b) => {
    b?.floors?.forEach((f) => {
      f?.units?.forEach((u) => {
        u?.rooms?.forEach((r) => {
          totalBeds = (r.beds?.length || 0) + totalBeds;
        });
      });
    });
  });
  return totalBeds;
};
/*
 * Get the total beds of one builidng layout.
 */
export const getTotalBuildings = (
  buildingLayoutState: BuildingType[],
): number => buildingLayoutState.length;
/*
 * Add beds in te unit state layout.
 */
export const addBedAction = (
  state: UnitI,
  roomIdx: number | undefined,
): UnitI => {
  if (typeof roomIdx !== 'number') return state;
  const newBedsGroup = [
    ...(state?.rooms?.[roomIdx]?.beds || []),
    { number: `${(state?.rooms?.[roomIdx]?.beds?.length || 0) + 1}` },
  ];

  return {
    ...state,
    rooms: [
      ...(state.rooms as RoomI[]).map((r, actualRoomIndex) => {
        if (actualRoomIndex === roomIdx) {
          return { ...r, beds: newBedsGroup };
        }
        return r;
      }),
    ],
  };
};

/*
 * Reset beds in te unit state layout.
 */
export const resetBedAction = (
  state: UnitI,
  roomIdx: number | undefined,
): UnitI => {
  if (typeof roomIdx !== 'number') return state;

  return {
    ...state,
    rooms: [
      ...(state.rooms as RoomI[]).map((r, actualRoomIndex) => {
        if (actualRoomIndex === roomIdx) {
          return { ...r, beds: [] };
        }
        return r;
      }),
    ],
  };
};

/*
 * Add rooms in the unit state layout.
 */
export const addRoomAction = (state: UnitI, rooms: number): UnitI => {
  if (!RoomsEnum[rooms]) return state;
  const initialRoom = {
    type: '',
    code: RoomsEnum[rooms],
    beds: [{ number: '1' }],
  };
  return {
    ...state,
    rooms: [...(state.rooms as RoomI[]), initialRoom],
  };
};

/*
 * Reset rooms in the unit state layout.
 */
export const resetRoomsAction = (state: UnitI): UnitI => ({
  ...state,
  rooms: [],
});

/*
 * Change room type, in unit state modal.
 */
export const changeRoomType = (
  state: UnitI,
  roomIdx: number,
  value: string,
): UnitI => {
  if (typeof roomIdx !== 'number') return state;

  return {
    ...state,
    rooms: [
      ...(state.rooms as RoomI[]).map((r, actualRoomIndex) => {
        if (actualRoomIndex === roomIdx) {
          return { ...r, type: value };
        }
        return r;
      }),
    ],
  };
};

/*
 * Change room code, in unit state modal.
 */
export const changeRoomCode = (
  state: UnitI,
  roomIdx: number,
  value: string,
): UnitI => {
  if (typeof roomIdx !== 'number') return state;

  return {
    ...state,
    rooms: [
      ...(state.rooms as RoomI[]).map((r, actualRoomIndex) => {
        if (actualRoomIndex === roomIdx) {
          return { ...r, code: value };
        }
        return r;
      }),
    ],
  };
};

/*
 * Change Bed number in state unit.
 */
export const changeBedNumber = (
  state: UnitI,
  roomIdx: number,
  bedIdx: number,
  value: string,
): UnitI => {
  if (typeof roomIdx !== 'number') return state;

  return {
    ...state,
    rooms: [
      ...(state.rooms as RoomI[]).map((r, actualRoomIndex) => {
        if (actualRoomIndex === roomIdx) {
          const beds = r.beds?.map((b, actualBedIndex) => {
            if (actualBedIndex === bedIdx) {
              return { ...b, number: value };
            }
            return b;
          });
          return { ...r, beds };
        }
        return r;
      }),
    ],
  };
};

/*
 * Change unit type.
 */
export const changeUnitType = (state: UnitI, value: string): UnitI => ({
  ...state,
  type: value,
});

/*
 * Change Unit Number.
 */
export const changeUnitCode = (state: UnitI, value: string): UnitI => ({
  ...state,
  code: value,
});

/*
 * Change unit gender.
 */
export const changeUnitGender = (state: UnitI, value: string): UnitI => ({
  ...state,
  gender: value,
});

/**
 * @description - Numbers of floor in current building.
 * @param buildingsCSV - Building CSV file.
 * @param buildingCode - Building Code.
 * @returns {BuildingCSVType[] | undefined}.
 */
const floorsNumber = (
  buildingsCSV: HandleCSVTypes<BuildingCSVType>[],
  buildingCode: string,
): string[] | undefined =>
  buildingsCSV
    .find(({ fileName }) => fileName === buildingCode)
    ?.data?.reduce((acc: string[], { Floor }: BuildingCSVType) => {
      if (acc.includes(Floor)) {
        return acc;
      }
      return [...acc, Floor];
    }, [])
    .filter((f) => !!f);

/**
 * @description - Units in current building and floor.
 * @param buildingsCSV - Building CSV file.
 * @param buildingCode - Building Code.
 * @param floorNumber - Floor Number.
 * @returns {BuildingCSVType[] | undefined}.
 */
const currentFloorUnits = (
  buildingsCSV: HandleCSVTypes<BuildingCSVType>[],
  buildingCode: string,
  floorNumber: string,
): BuildingCSVType[] | undefined =>
  buildingsCSV
    .find(({ fileName }) => fileName === buildingCode)
    ?.data?.filter((item) => {
      if (item.Floor === floorNumber) {
        return true;
      }
      return false;
    })
    ?.reduce((acc: BuildingCSVType[], cur: BuildingCSVType) => {
      if (acc.map(({ Unit }) => Unit).includes(cur.Unit)) {
        return acc;
      }
      return [...acc, cur];
    }, []);

/**
 * @description - Units in current building and floor.
 * @param buildingsCSV - Building CSV file.
 * @param buildingCode - Building Code.
 * @param floorNumber - Floor Number.
 * @param unitCode - Unit Number.
 * @returns {BuildingCSVType[] | undefined}.
 */
const currentUnitRooms = (
  buildingsCSV: HandleCSVTypes<BuildingCSVType>[],
  buildingCode: string,
  floorNumber: string,
  unitCode: string,
): BuildingCSVType[] | undefined =>
  buildingsCSV
    .find(({ fileName }) => fileName === buildingCode)
    ?.data?.filter(
      ({ Floor, Unit }) => Floor === floorNumber && Unit === unitCode,
    )
    ?.reduce((acc: BuildingCSVType[], cur: BuildingCSVType) => {
      if (acc.map(({ Room }) => Room).includes(cur.Room)) {
        return acc;
      }
      return [...acc, cur];
    }, []);

/**
 * @description - Romms in current unit.
 * @param buildingsCSV - Building CSV file.
 * @param buildingCode - Building Code.
 * @param floorNumber - Floor Number.
 * @param unitCode - Unit Number.
 * @param roomCode - Code of the room.
 * @returns {BuildingCSVType[] | undefined}.
 */
const currentRoomBeds = (
  buildingsCSV: HandleCSVTypes<BuildingCSVType>[],
  buildingCode: string,
  floorNumber: string,
  unitCode: string,
  roomCode: string,
): BuildingCSVType[] | undefined =>
  buildingsCSV
    .find(({ fileName }) => fileName === buildingCode)
    ?.data?.filter(
      ({ Floor, Unit, Room }) =>
        Floor === floorNumber && Unit === unitCode && Room === roomCode,
    );

/* * Parse gender correctly
 */
const genderParser = (gender: string): string => {
  const validGenders = [
    Gender.Female,
    Gender.Male,
    Gender.Any,
    Gender.Other,
    Gender.First_Come,
  ];

  let genderToReturn = '';
  validGenders
    .map((item) => item.replace('_', ' '))
    .forEach((validGender, idx) => {
      if (validGender === gender?.toUpperCase()) {
        genderToReturn = validGenders[idx];
      }
    });

  return genderToReturn;
};

/*
 * Pass from building CSV data to Building layout state type.
 */
export const passBuildingsCSVToBuildingLayout = (
  buildingsCSV: HandleCSVTypes<BuildingCSVType>[],
  { unitTypes, roomTypes }: { unitTypes: UnitType[]; roomTypes: RoomType[] },
): BuildingType[] => {
  const buildingsNames = buildingsCSV.map(({ fileName }) => fileName);

  return buildingsNames.map((buildingCode) => ({
    code: buildingCode,
    floors: floorsNumber(buildingsCSV, buildingCode)?.map((floorNumber) => ({
      number: floorNumber,
      units: currentFloorUnits(buildingsCSV, buildingCode, floorNumber)?.map(
        (i) => ({
          code: i.Unit,
          gender: genderParser(i.Gender),
          type: unitTypes.find(
            (type) =>
              type.typeName?.toUpperCase().trim() ===
              i['Unit-Type']?.toUpperCase().trim(),
          )?.id,
          rooms: currentUnitRooms(
            buildingsCSV,
            buildingCode,
            floorNumber,
            i.Unit,
          )?.map((j) => ({
            code: j.Room,
            type: roomTypes.find((type) => {
              const roomType =
                j['Room-Type']?.toUpperCase().trim() || 'PRIVATE';
              return type.typeName?.toUpperCase().trim() === roomType;
            })?.id,
            beds: currentRoomBeds(
              buildingsCSV,
              buildingCode,
              floorNumber,
              i.Unit,
              j.Room,
            )?.map((bed) => ({
              number: bed.Bed,
              gender: bed.Gender,
            })),
          })),
        }),
      ),
    })),
  }));
};

/*
 * Pass from building CSV data to Building layout state type.
 */

export const passCSVToBuildingsCSV = (
  CSVData: BuildingCSVType[] | null,
): HandleCSVTypes<BuildingCSVType>[] => {
  let buildings: HandleCSVTypes<BuildingCSVType>[] = [];
  CSVData?.forEach((bed) => {
    const isBuildingRecorded = Boolean(
      buildings.filter(({ fileName }) => fileName === bed.Building).length,
    );
    if (isBuildingRecorded) {
      const buildingIndex = buildings.findIndex(
        ({ fileName }) => fileName === bed.Building,
      );

      buildings[buildingIndex] = {
        ...buildings[buildingIndex],
        data: [
          ...(buildings[buildingIndex].data as BuildingCSVType[]),
          {
            ...bed,
            Bed: bed.Bed,
          },
        ],
      };

      return;
    }

    buildings = [
      ...buildings,
      {
        fileName: bed.Building,
        loading: false,
        data: [
          {
            ...bed,
          },
        ],
      },
    ];
  });
  return buildings;
};

/*
 * Count all beds in one unit modal.
 */

export const getUnitBeds = (UnitState: UnitI): number => {
  const total: number =
    UnitState.rooms?.reduce((acc, room) => (room.beds?.length || 0) + acc, 0) ||
    0;
  return total;
};

export const createLateFeesColums = ({
  statusRender,
}: {
  statusRender: (params) => JSX.Element;
}): GridColDef[] => [
  { field: 'id', hide: true },
  { field: 'name', headerName: 'Rule Name', width: 200 },
  {
    field: 'oneTimeStartDay',
    headerName: 'One-Time Start Day',
    width: 200,
    valueFormatter: (value) => value.row.oneTimeStartDay || '-',
  },
  {
    field: 'oneTimeFeeAmount',
    headerName: 'One-Time Fee Amount',
    width: 250,
    valueFormatter: (value) =>
      value.row.oneTimeFeeAmount
        ? `$${value.row.oneTimeFeeAmount?.toFixed(2)}`
        : '-',
  },
  {
    field: 'dailyFeeStartDay',
    headerName: 'Daily Fee Start Day',
    width: 200,
    valueFormatter: (value) => value.row.dailyFeeStartDay || '-',
  },
  {
    field: 'dailyFeeAmount',
    headerName: 'Daily Fee Amount',
    width: 200,
    valueFormatter: (value) =>
      value.row.dailyFeeAmount
        ? `$${value.row.dailyFeeAmount?.toFixed(2)}`
        : '-',
  },
  { field: 'lateFeeFinalDay', headerName: 'Late Fee Final Day', width: 200 },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    renderCell: statusRender,
  },
];

export const getPropertyImage = (property: Property | undefined): string => {
  if (property?.primaryPropertyImage?.downloadUrl) {
    return property.primaryPropertyImage?.downloadUrl;
  }

  if (property?.images?.items.length) {
    return property?.images?.items[0]?.downloadUrl || '';
  }

  if (property?.propertyImagePropertyRelation?.items.length)
    return (
      property?.propertyImagePropertyRelation?.items[0].file?.downloadUrl || ''
    );

  if (property?.headerImage?.downloadUrl)
    return property.headerImage?.downloadUrl || '';

  return '';
};

export const getMandatoryAdditionalData = (
  subCategory: string,
  optionalPreference: OptionalPreference,
  id: string,
  isUpdate?: boolean,
): OptionalPreferenceAdditionalDatum[] => {
  const inputList: OptionalPreferenceAdditionalDatum[] = [];
  switch (subCategory) {
    case 'PARKING_PASS':
      if (
        !optionalPreference.optionalPreferenceAdditionalDataRelation?.items.find(
          (formField) => formField.title === 'Make',
        )
      ) {
        inputList.push({
          title: 'Make',
          required: true,
          optionalPreference,
          id,
        });
      }
      if (
        !optionalPreference.optionalPreferenceAdditionalDataRelation?.items.find(
          (formField) => formField.title === 'License Plate',
        )
      ) {
        inputList.push({
          title: 'License Plate',
          required: true,
          optionalPreference,
          id,
        });
      }
      if (
        !optionalPreference.optionalPreferenceAdditionalDataRelation?.items.find(
          (formField) => formField.title === 'Model',
        )
      ) {
        inputList.push({
          title: 'Model',
          required: true,
          optionalPreference,
          id,
        });
      }
      if (
        !optionalPreference.optionalPreferenceAdditionalDataRelation?.items.find(
          (formField) => formField.title === 'Color',
        )
      ) {
        inputList.push({
          title: 'Color',
          required: true,
          optionalPreference,
          id,
        });
      }

      break;
    default:
      break;
  }
  return inputList;
};

export const timezones = {
  EST: 'America/New_York',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago',
  MST: 'America/Denver',
};
