import { ActivityLog } from './../../../../shared/src/schema-types';
import {
  UserListResponse,
  User,
  NoteListResponse,
  LeaseOptionalPreferenceListResponse,
  Lease,
  SuccessResponse,
  LedgerListResponse,
  Ledger,
  Guarantor,
  PaymentSubCategory,
  AutoPay,
  PropertyLedgerListResponse,
  PropertyLedger,
  PaymentMethod,
  AccountSubCategory,
  PaymentMethodManyResponse,
  OptionalPreferenceInvetory,
} from '../../schema-types';
import { createEvent } from '@8baselabs/react-simple-state';

export const fetchResidentAndGuarantorEvent = createEvent<UserListResponse>();
export const fetchResidentAndGuarantorError = createEvent();

export const fetchUserEvent = createEvent<User>();
export const fetchUserError = createEvent();

export const fetchUsersEvent = createEvent<readonly User[]>();
export const fetchUsersError = createEvent();

export const OnGetLeaseList = createEvent<readonly Lease[] | undefined>();
export const OnGetLeaseListError = createEvent();

export const fetchNotesEvent = createEvent<NoteListResponse>();
export const fetchNotesError = createEvent();

export const createNoteEvent = createEvent();
export const createNoteError = createEvent();
export const fetchLedgerEvent = createEvent<LedgerListResponse>();

export const fetchPropertyLedgerEvent =
  createEvent<PropertyLedgerListResponse>();

export const fetchPropertyLedgerError = createEvent<Error>();

export const createPropertyLedgerEvent = createEvent<PropertyLedger>();
export const createPropertyLedgerError = createEvent<Error>();

export const createLedgerEvent = createEvent<Ledger[]>();
export const createLedgerError = createEvent<Error>();
export const createOptionalPreferenceLedgerEvent = createEvent<Ledger>();
export const createOptionalPreferenceLedgerError = createEvent<Error>();

export const sendReceiptEvent = createEvent<SuccessResponse>();
export const sendReceiptError = createEvent<Error>();
export const fetchLedgerError = createEvent<Error>();
export const createDocumentsEvent = createEvent();
export const createDocumentsError = createEvent<Error>();
export const deleteDocumentEvent = createEvent<SuccessResponse>();
export const deleteDocumentError = createEvent<Error>();
export const deleteDocumentsEvent = createEvent<void>();
export const fetchDocumentTypeEvent = createEvent();
export const fetchDocumentTypeError = createEvent();
export const onSearchByEmailEvent = createEvent<string>();

export const updateLeaseEvent = createEvent();
export const updateLeaseError = createEvent();

export const OnLeaseReset = createEvent<void>();
export const OnLeaseResetError = createEvent<Error>();

export const fetchLeaseOptionalPreferencesEvent =
  createEvent<LeaseOptionalPreferenceListResponse>();
export const fetchLeaseOptionalPreferencesError = createEvent();
export const deleteLeaseOptionalPreferenceEvent = createEvent();
export const deleteLeaseOptionalPreferenceError = createEvent();

export const OnGetGuarantorById = createEvent<Guarantor>();
export const OnGetGuarantorByIdError = createEvent<Error>();

export const OnUpdateGuarantor = createEvent<Guarantor>();
export const OnUpdateGuarantorError = createEvent<Error>();

export const fetchPaymentSubCategoriesEvent = createEvent<
  readonly PaymentSubCategory[] | undefined
>();
export const fetchPaymentSubCategoriesError = createEvent<Error>();
export const fetchAutoPayEvent = createEvent<
  readonly AutoPay[],
  readonly AutoPay[]
>();
export const fetchAutoPayError = createEvent<Error>();

export const createAutoPayEvent = createEvent<AutoPay>();
export const createAutoPayError = createEvent<Error>();
export const updateAutoPayEvent = createEvent<AutoPay>();
export const updateAutoPayError = createEvent();

export const OnFetchUserActivitiesEvent = createEvent<ActivityLog[]>();
export const OnFetchUserActivitiesError = createEvent<Error>();

export const onFetchSubcategoriesEvent = createEvent<AccountSubCategory[]>();
export const onFetchSubcategoriesError = createEvent<Error>();

export const deleteLedgerByFilterEvent = createEvent<
  SuccessResponse | undefined
>();
export const deleteLedgerByFilterError = createEvent<Error>();

export const createPaymentMethodEvent = createEvent<PaymentMethod | boolean>();
export const createPaymentMethodEventError = createEvent<Error>();

export const updatePaymentMethodByFilterEvent = createEvent<PaymentMethod>();
export const updatePaymentMethodByFilterEventError = createEvent<Error>();

export const updatePaymentMethodsByFilterEvent = createEvent<
  PaymentMethodManyResponse | undefined
>();
export const updatePaymentMethodsByFilterEventError = createEvent<Error>();

export const deletePaymentMethodByFilterEvent = createEvent<
  SuccessResponse | undefined
>();
export const deletePaymentMethodByFilterEventError = createEvent<Error>();

export const getPaymentMethodsEvent = createEvent<PaymentMethod[]>();
export const getPaymentMethodsEventError = createEvent<Error>();

export const getPaymentIntentEvent = createEvent<{ id: string; fee: number }>();
export const getPaymentIntentEventError = createEvent<Error>();

export const fetchAccountSubCategoriesEvent = createEvent<
  readonly AccountSubCategory[] | undefined
>();
export const fetchAccountSubCategoriesError = createEvent<Error>();

export const leaseBalanceEvent = createEvent<{ amount: number }>({
  initialValue: { amount: 0 },
});

export const getInventoryEvent = createEvent<OptionalPreferenceInvetory[]>();
export const getInventoryError = createEvent();
