import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  ArrowDropDownOutlined,
  MoreVertOutlined,
  SwapVertOutlined,
  CreateOutlined,
  VisibilityOutlined,
  DescriptionOutlined,
  LoopOutlined,
} from '@material-ui/icons';
import {
  GridCellParams,
  GridColDef,
  GridRowData,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
  useGridSlotComponentProps,
  GridSelectionModel,
  GridToolbarColumnsButton,
} from '@material-ui/data-grid';
import { Box, Grid, ListItemIcon } from '@material-ui/core';
import {
  useCallAction,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import {
  Lease,
  Ledger,
  LedgerCreateInput,
  LedgerListResponse,
} from '../../../schema-types';
import { AddCircleButton } from '../../../shared/components/ui/buttons/AddCircleButton';
import {
  formatCurrency,
  rootStyleTable,
  sanitizeTextToTitleCase,
} from '../../../shared/utils';
import { LedgerCreateUpdateDialog } from '../components/LedgerCreateUpdateDialog/LedgerCreateUpdateDialog';
import { createLedgerEvent, leaseBalanceEvent } from '../people-event';
import { ReverTransactionDialog } from '../components/ReverTransactionDialog';
import { SendReceiptDialog } from '../components/SendReceiptDialog';
import { ConfirmBulkDialog } from '../components/ConfirmBulkDialog';
import { getLedgersByFilter, reverseLedgersAction } from '../people-actions';
import { LedgerViewDetailsDialog } from '../components/LedgerViewDetailsDialog';
import { Checkbox as CustomCheckbox } from '../../../shared/components/ui/inputs/Checkbox';

import { isValidString } from '@8baselabs/validation-utils';
import { StripeWrapper } from '../../../shared/components/StripeWrapper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSession } from '../../session/session-hooks';
import { LedgerFilters } from '../people-utils';
import moment from 'moment';
import {
  EntryType,
  LedgerPaymentCategories,
  LedgerPaymentStatus,
  LedgerPaymentTypes,
  UserRoleType,
} from '@8baselabs/resident-io-shared';

import ExportToPDF from '../../../shared/components/ui/buttons/ExportToPDF';
import { CustomDataGrid } from '../../../shared/components/CustomDataGrid';

const isReversible = (ledger: Ledger): boolean => {
  return (
    !ledger.ledgerDepositRelation?.id &&
    ledger.category !== LedgerPaymentCategories.REFUND
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    dataGrid: {
      minHeight: '900px',
    },
    row: {
      minHeight: 'fit-content !important',
    },
    cell: {
      minHeight: 'auto !important',
    },
    root: rootStyleTable,
    bulkButton: {
      fontWeight: 400,
      fontSize: 16,
      textTransform: 'none',
    },
  }),
);

const ActionsButton: React.FC<{
  params: GridCellParams;
  setLedgerId: (ledgerId: string) => void;
  openSend: () => void;
  openReverse: () => void;
  openEdit: () => void;
  openView: () => void;
}> = ({ params, setLedgerId, openEdit, openSend, openReverse, openView }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        disableFocusRipple
        disableRipple
        aria-controls="delete-subperiod-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="delete-subperiod-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            openEdit();
            setLedgerId(params.row.id);
            setAnchorEl(null);
          }}
          disabled={
            params.row.paymentType === LedgerPaymentTypes.ELECTRONIC_PAYMENT
          }>
          <ListItemIcon>
            <CreateOutlined fontSize="small" />
          </ListItemIcon>
          <Box>Edit</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openView();
            setLedgerId(params.row.id);
            setAnchorEl(null);
          }}>
          <ListItemIcon>
            <VisibilityOutlined fontSize="small" />
          </ListItemIcon>
          <Box>View Details</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openSend();
            setLedgerId(params.row.id);
            setAnchorEl(null);
          }}
          disabled={params.row.category !== LedgerPaymentCategories.PAYMENT}>
          <ListItemIcon>
            <DescriptionOutlined fontSize="small" />
          </ListItemIcon>
          <Box>Send Receipt</Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openReverse();
            setLedgerId(params.row.id);
            setAnchorEl(null);
          }}
          disabled={
            !isReversible(params.row) ||
            params.row.description
              ?.toLowerCase()
              .includes('payment processing fee')
          }>
          <ListItemIcon>
            <LoopOutlined fontSize="small" />
          </ListItemIcon>
          <Box>Reverse Transaction</Box>
        </MenuItem>
      </Menu>
    </>
  );
};

/**
 * @param {Function} handleOpenAddEdit - Action button.
 * @returns - Custom Toolbar for Bulk actions.
 */
const Toolbar: React.FC<{
  handleOpenAddEdit: () => void;
  selectedLease?: Lease;
  ledgers: Ledger[];
  forceRender: React.Dispatch<React.SetStateAction<number>>;
  isTenant: boolean;
  setChecked: React.Dispatch<React.SetStateAction<LedgerFilters>>;
  checked: LedgerFilters;
  columns: string[];
  rowNames: string[];
  loadingGrid: boolean;
  data: GridRowData[];
  rowCount?: number;
}> = ({
  forceRender,
  handleOpenAddEdit,
  selectedLease,
  ledgers,
  isTenant,
  setChecked,
  checked,
  data,
  loadingGrid,
  rowNames,
  columns,
  rowCount,
}) => {
  const classes = useStyles();
  const { state, apiRef } = useGridSlotComponentProps();
  const [notSelectedRowsIds, setNotSelectedRowsIds] = useState<GridRowId[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('Reverse Transactions');
  const [bulk, setBulk] = useState('Reverse');
  const [text, setText] = useState(
    'Are you sure you want to reverse transactions? This action is irreversible.',
  );
  const theme = useTheme();
  const matchesAction = useMediaQuery(theme.breakpoints.up('sm'));
  const [session] = useSession();
  const { totalRowCount, allRows } = state.rows;
  const [reverseTransactionsCall, loadingReverseTransaction] = useCallAction(
    reverseLedgersAction,
    {
      onCompleted() {
        setIsOpen(false);
      },
    },
  );
  const selectedRowsMap = apiRef.current.getSelectedRows();
  const { size: selectedSize } = selectedRowsMap;
  // const [deleteSubperiodCallback] = useCallAction(deleteManySubperiods);
  useEffect(() => {
    const rowsIdsToUpdate = selectedRowsMap.size !== 0 ? [] : allRows;

    setNotSelectedRowsIds(rowsIdsToUpdate);
  }, [allRows, selectedRowsMap]);

  const handleBulkClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleBulkClose = (): void => {
    setAnchorEl(null);
  };
  const handleBulkReverte = (): void => {
    setTitle('Reverse Transactions');
    setText(
      'Are you sure you want to reverse transactions? This action is irreversible.',
    );
    setIsOpen(true);
    handleBulkClose();
    setBulk('Reverse');
  };

  const handleYes = (): void => {
    if (bulk === 'Reverse') {
      const selectedLedgers: LedgerCreateInput[] = (
        Array.from<GridRowId>(selectedRowsMap.keys()) as string[]
      ).map((id) => {
        const ledger = ledgers.find((item) => item.id === id) as Ledger;
        return {
          amount: ledger?.amount || 0,
          category:
            ledger?.paymentType === LedgerPaymentTypes.ELECTRONIC_REFUND
              ? LedgerPaymentCategories.REFUND
              : 'Transaction reversal',
          subCategory: ledger?.subCategory,
          effectiveDate: ledger?.effectiveDate || '',
          type:
            ledger?.type === EntryType.DEBIT
              ? EntryType.CREDIT
              : EntryType.DEBIT,
          paymentType:
            ledger?.paymentType === LedgerPaymentTypes.ELECTRONIC_PAYMENT
              ? LedgerPaymentTypes.ELECTRONIC_REFUND
              : LedgerPaymentTypes.MANUAL_REFUND,
          description: ledger?.description || '',
          lease: { connect: { id: selectedLease?.id } },
          leaseSubPeriod: isValidString(ledger?.leaseSubPeriod)
            ? { connect: { id: ledger?.leaseSubPeriod?.id } }
            : undefined,
          paymentStatus: LedgerPaymentStatus.PROCESSING.toUpperCase(),
          ledgerTransactionInformationRelation: {
            create: {
              checkNumber:
                ledger?.ledgerTransactionInformationRelation?.checkNumber || '',
              stripePyId:
                ledger?.ledgerTransactionInformationRelation?.stripePyId || '',
              transactionId:
                ledger?.ledgerTransactionInformationRelation?.transactionId ||
                '',
              lastFour:
                ledger?.ledgerTransactionInformationRelation?.lastFour || '',
              paypalTransactionId:
                ledger?.ledgerTransactionInformationRelation
                  ?.paypalTransactionId || '',
            },
          },
        } as LedgerCreateInput;
      });

      reverseTransactionsCall(
        selectedLedgers,
        selectedLease?.id || '',
        selectedLease?.property?.stripeAccountId || '',
      );
    }
  };

  return (
    <div>
      <GridToolbarContainer>
        <Grid container>
          <Grid item xs={12}>
            <Box
              width="100%"
              display={matchesAction ? 'flex' : 'block'}
              flexDirection={matchesAction ? 'row' : 'column'}
              justifyContent="space-between">
              <Box>
                <Checkbox
                  checked={selectedSize === totalRowCount && !!rowCount}
                  indeterminate={
                    selectedSize > 0 && selectedSize !== totalRowCount
                  }
                  onChange={() => {
                    apiRef.current.setSelectionModel(notSelectedRowsIds);
                    forceRender((render) => render + 1);
                  }}
                  inputProps={{ 'aria-label': 'selected rows checkbox' }}
                  color="primary"
                />
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className={classes.bulkButton}
                  onClick={handleBulkClick}>
                  Bulk actions <ArrowDropDownOutlined />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}>
                  <MenuItem onClick={handleBulkReverte}>
                    Reverse Transactions
                  </MenuItem>
                </Menu>
              </Box>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}>
                <ExportToPDF
                  loadingPDF={loadingGrid}
                  columns={columns}
                  rowNames={rowNames}
                  data={data}
                  customStyles={[
                    {
                      width: '10.5%',
                    },
                    {
                      width: '10.5%',
                    },
                    {
                      width: '12.5%',
                    },
                    {
                      width: '17.5%',
                    },
                    {
                      width: '17.5%',
                    },
                    {
                      width: '14.5%',
                    },
                    {
                      width: '8.5%',
                    },
                    {
                      width: '8.5%',
                    },
                  ]}
                  filename={'ledgers.pdf'}></ExportToPDF>

                <GridToolbarColumnsButton />
                {(selectedLease?.property?.siteSetting
                  ?.enablePaymentsOnTenantProfile ||
                  session?.user?.userType === UserRoleType.ADMIN ||
                  session?.user?.userRole !== UserRoleType.RESIDENT) && (
                  <Box alignSelf={'center'}>
                    <AddCircleButton
                      onClick={handleOpenAddEdit}
                      disabled={[
                        'MOVE-OUT',
                        'MOVE-OUT_REFUND_ISSUED',
                        'DENIED',
                      ].includes(selectedLease?.status ?? '')}>
                      {isTenant ? 'Make a payment' : 'Add Entry'}
                    </AddCircleButton>
                  </Box>
                )}
              </div>
            </Box>
            <ConfirmBulkDialog
              onDelete={handleYes}
              loading={loadingReverseTransaction}
              open={isOpen}
              onClose={() => {
                setIsOpen(false);
              }}
              title={title}
              text={text}
            />
          </Grid>
          <Grid
            xs={12}
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 10, marginBottom: 10 }}>
            <CustomCheckbox
              style={{ minWidth: 50 }}
              name={LedgerFilters.ALL}
              label={LedgerFilters.ALL}
              handleChange={(_, check) => {
                setChecked(!check ? checked : LedgerFilters.ALL);
              }}
              checked={checked === LedgerFilters.ALL}
            />
            <CustomCheckbox
              style={{ minWidth: 50 }}
              name={LedgerFilters.CURRENT}
              label={LedgerFilters.CURRENT}
              handleChange={(_, check) =>
                setChecked(!check ? checked : LedgerFilters.CURRENT)
              }
              checked={checked === LedgerFilters.CURRENT}
            />
            <CustomCheckbox
              style={{ minWidth: 50 }}
              name={LedgerFilters.FUTURE}
              label={LedgerFilters.FUTURE}
              handleChange={(_, check) =>
                setChecked(!check ? checked : LedgerFilters.FUTURE)
              }
              checked={checked === LedgerFilters.FUTURE}
            />
          </Grid>
        </Grid>
      </GridToolbarContainer>
    </div>
  );
};

const LedgerViewComponent: React.FC<{
  selectedLease?: Lease;
  isTenant: boolean;
  onAddPaymentMethod?: () => void;
}> = ({ selectedLease, isTenant, onAddPaymentMethod }) => {
  const classes = useStyles();
  const [isOpenAddEdit, setIsOpenAddEdit] = useState<boolean>(false);
  const [isOpenView, setIsOpenView] = useState<boolean>(false);
  const [isOpenReverse, setIsOpenReverse] = useState<boolean>(false);
  const [isOpenSend, setIsOpenSend] = useState<boolean>(false);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [ledgerId, setLedgerId] = useState('');
  const [, forceRender] = useState(0);
  const [ledgersState, setLedgers] = useState<Ledger[]>([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<GridRowData[]>([]);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [checked, setChecked] = useState<LedgerFilters>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const setFilteredRows = (ledgerList: LedgerListResponse): void => {
    let balance = 0;
    setRows(
      ledgerList.items
        ?.map((ledger) => {
          balance =
            ledger.type === EntryType.DEBIT
              ? balance + (ledger.amount || 0)
              : balance - (ledger.amount || 0);
          return {
            date: Intl.DateTimeFormat('en-US').format(
              new Date(ledger.effectiveDate ?? ''),
            ),
            id: ledger.id,
            type: ledger.type,
            paymentType: ledger.paymentType,
            category: ledger.category,
            subCategory: ledger.subCategory,
            description: ledger.description,
            notes: ledger.notes,
            effectiveDate: ledger.effectiveDate,
            lease: ledger.lease,
            leaseSubPeriod: ledger.leaseSubPeriod,
            paymentStatus: ledger.paymentStatus,
            ledgerTransactionInformationRelation:
              ledger.ledgerTransactionInformationRelation,
            ledgerDepositRelation: ledger.ledgerDepositRelation,
            amount:
              ledger.type === EntryType.DEBIT
                ? 0 + (ledger.amount || 0)
                : 0 - (ledger.amount || 0),
            balance,
            subPeriod: ledger?.leaseSubPeriod?.name || '',
            reference:
              ledger.ledgerDepositRelation?.referenceId ||
              ledger.ledgerDepositRelation?.bankAccountReferenceId,
          };
        })
        .reverse(),
    );
    leaseBalanceEvent.dispatch({ amount: balance });
  };

  const [, , { refetch: refetchLedgers }] = useFetchAction(
    getLedgersByFilter,
    [selectedLease?.id || ''],
    {
      onCompleted: (ledgers: LedgerListResponse) => {
        setLoadingGrid(false);
        setLedgers(ledgers?.items as Ledger[]);
        setFilteredRows(ledgers);
      },
    },
  );

  useEffect(() => {
    setSelectionModel([]);
    if (checked === LedgerFilters.CURRENT) {
      const currentMonthStart = moment().startOf('month');
      const today = moment();
      const currentList = ledgersState.filter((entry: Ledger) => {
        const entryDate = moment(entry.effectiveDate);

        return (
          entryDate.isSameOrBefore(today) &&
          entryDate.isSameOrAfter(currentMonthStart)
        );
      });
      setFilteredRows({
        items: currentList,
        count: currentList.length,
        groups: [],
      });
    } else if (checked === LedgerFilters.FUTURE) {
      const today = moment();

      const futureList = ledgersState.filter((entry: Ledger) => {
        const entryDate = moment(entry.effectiveDate);
        return entryDate.isAfter(today);
      });
      setFilteredRows({
        items: futureList,
        count: futureList.length,
        groups: [],
      });
    } else {
      setFilteredRows({
        items: ledgersState,
        count: ledgersState.length,
        groups: [],
      });
    }
  }, [checked, ledgersState]);

  const handleCloseAddEdit = (): void => {
    setIsOpenAddEdit(false);
    setLedgerId('');
  };

  const handleCloseView = (): void => {
    setIsOpenView(false);
    setLedgerId('');
  };
  const handleCloseReverse = (): void => {
    setIsOpenReverse(false);
    setLedgerId('');
  };
  const handleCloseSend = (): void => {
    setIsOpenSend(false);
    setLedgerId('');
  };
  const handleOpenAddEdit = (): void => {
    setIsOpenAddEdit(true);
  };
  const handleOpenView = (): void => {
    setIsOpenView(true);
  };
  const handleOpenReverse = (): void => {
    setIsOpenReverse(true);
  };
  const handleOpenSend = (): void => {
    setIsOpenSend(true);
  };

  useSubscription(createLedgerEvent, async () => {
    refetchLedgers();
  });

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'DATE',
      flex: 0.6,
      minWidth: 120,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant="body2">
            {sanitizeTextToTitleCase(params.row.date)}
          </Typography>
        );
      },
    },
    {
      field: 'category',
      headerName: 'TYPE',
      flex: 1,
      minWidth: 160,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant="body2">
            {sanitizeTextToTitleCase(params.row.category)}
          </Typography>
        );
      },
    },
    {
      field: 'subCategory',
      headerName: 'ACCOUNT',
      flex: 1,
      minWidth: 180,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant="body2">
            {sanitizeTextToTitleCase(params.row.subCategory)}
          </Typography>
        );
      },
    },
    {
      field: 'subCategory',
      headerName: 'SUB PERIOD',
      flex: 1,
      minWidth: 180,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant="body2">
            {sanitizeTextToTitleCase(params.row.subPeriod)}
          </Typography>
        );
      },
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      flex: 1.7,
      minWidth: 230,
    },
    {
      field: 'reference',
      headerName: 'PAYMENT REFERENCE',
      flex: 2,
      minWidth: 230,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant="body2">
            {sanitizeTextToTitleCase(params.row.reference)}
          </Typography>
        );
      },
    },
    {
      field: 'paymentStatus',
      headerName: 'PAYMENT STATUS',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <Typography variant="body2">
            {sanitizeTextToTitleCase(params.row.paymentStatus)}
          </Typography>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'AMOUNT',
      renderCell: (params) => formatCurrency(params.row.amount, true),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'balance',
      headerName: 'BALANCE',
      renderCell: (params) => formatCurrency(params.row.balance, true),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideSortIcons: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <ActionsButton
          openReverse={handleOpenReverse}
          openSend={handleOpenSend}
          openEdit={handleOpenAddEdit}
          openView={handleOpenView}
          setLedgerId={setLedgerId}
          params={params}
        />
      ),
      minWidth: 140,
      flex: 0.4,
    },
  ];

  const ledger = ledgersState?.find((item) => item.id === ledgerId);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const count = rows?.length || 0;
  const rowsPerPageOptions =
    //count > 100 ? [5, 10, 20, 50, 100, count] :
    [5, 10, 20, 50, 100];

  return (
    <Box padding={matches ? '20px' : ''}>
      <CustomDataGrid
        className={classes.dataGrid}
        disableColumnFilter
        classes={{
          cell: classes.cell,
          row: classes.row,
          root: classes.root,
        }}
        components={{
          ColumnUnsortedIcon: SwapVertOutlined,
          Toolbar: Toolbar,
        }}
        componentsProps={{
          toolbar: {
            checked,
            setChecked,
            forceRender: forceRender,
            ledgers: ledgersState,
            handleOpenAddEdit: handleOpenAddEdit,
            selectedLease: selectedLease,
            isTenant: isTenant,
            data: rows,
            loadingGrid: loadingGrid,
            rowNames: [
              'date',
              'type',
              'subPeriod',
              'description',
              'reference',
              'paymentStatus',
              'amount',
              'balance',
            ],
            columns: [
              'DATE',
              'TYPE',
              'SUB PERIOD',
              'DESCRIPTION',
              'PAYMENT REFERENCE',
              'PAYMENT STATUS',
              'AMOUNT',
              'BALANCE',
            ],
            rowCount: rows?.length || 0,
          },
        }}
        onPageChange={(data) => {
          setPage(data);
        }}
        onPageSizeChange={(data) => {
          setPage(0);
          setRowPerPage(data);
        }}
        pageSize={rowPerPage}
        rows={rows}
        page={page}
        columns={columns}
        isRowSelectable={(params: GridRowParams) => isReversible(params.row)}
        loading={loadingGrid}
        rowCount={rows.length}
        checkboxSelection
        rowsPerPageOptions={rowsPerPageOptions}
        hideFooterPagination={!count}
        autoHeight
        pagination
        paginationMode="server"
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
      />
      {isOpenAddEdit && selectedLease && (
        <LedgerCreateUpdateDialog
          isOpen={isOpenAddEdit}
          lease={selectedLease}
          amount={ledger?.amount}
          notes={ledger?.notes}
          category={ledger?.category}
          subCategory={ledger?.subCategory}
          paymentType={ledger?.paymentType}
          type={ledger?.type}
          onAddPaymentMethod={onAddPaymentMethod}
          description={ledger?.description}
          ledgerTransactionInformationRelation={
            ledger?.ledgerTransactionInformationRelation
          }
          id={ledger?.id}
          leaseSubPeriod={
            ledger?.leaseSubPeriod ||
            selectedLease?.leasePeriod?.leasePeriodPeriodSubPeriodRelation
              ?.items[0]?.leaseSubPeriod
          }
          effectiveDate={new Date(ledger?.effectiveDate || '')}
          onClose={handleCloseAddEdit}
          isTenant={isTenant}
          setLoadingGrid={setLoadingGrid}
        />
      )}
      {isOpenView && (
        <LedgerViewDetailsDialog
          isOpen={isOpenView}
          ledger={ledger as Ledger}
          onClose={handleCloseView}
        />
      )}
      <ReverTransactionDialog
        isOpen={isOpenReverse}
        lease={selectedLease}
        amount={ledger?.amount}
        description={ledger?.description}
        category={ledger?.category}
        subCategory={ledger?.subCategory}
        paymentType={ledger?.paymentType}
        type={ledger?.type}
        id={ledger?.id}
        ledger={ledger}
        leaseSubPeriod={ledger?.leaseSubPeriod}
        date={new Date(ledger?.effectiveDate || '')}
        onClose={handleCloseReverse}
      />
      <SendReceiptDialog
        isOpen={isOpenSend}
        type={ledger?.type as EntryType}
        amount={ledger?.amount || 0}
        id={ledger?.id || ''}
        onClose={handleCloseSend}
      />
    </Box>
  );
};

export const LedgerView: React.FC<{
  selectedLease?: Lease;
  isTenant?: boolean;
  onAddPaymentMethod?: () => void;
}> = ({ selectedLease, isTenant = false, onAddPaymentMethod }) => {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret',
  );

  let options;

  if (clientSecret) {
    options = {
      clientSecret: clientSecret,
    };
  }

  // If no lease is selected add a typography indicating the user that he should select one
  if (!selectedLease)
    return (
      <Box padding="20px">
        <Typography variant="h6">
          Select a lease to view it's ledgers.
        </Typography>
      </Box>
    );

  return (
    <StripeWrapper
      useAccountId={false}
      //accountId={selectedLease?.property?.stripeAccountId}
      options={options}>
      <LedgerViewComponent
        onAddPaymentMethod={onAddPaymentMethod}
        selectedLease={selectedLease}
        isTenant={isTenant}
      />
    </StripeWrapper>
  );
};
