import { useSubscription } from '@8baselabs/react-simple-state';
import { isValidEmail, isValidPhoneNumber } from '@8baselabs/validation-utils';
import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { Dialog } from '../../../../shared/components/Dialog';
import { ButtonAction } from '../../../../shared/components/ui/buttons/ButtonAction';
import { ButtonDefault } from '../../../../shared/components/ui/buttons/ButtonDefault';
import { TextFieldDefault } from '../../../../shared/components/ui/inputs/TextFieldDefault';
import {
  onSendTemplateErrorEvent,
  onSendTemplateEvent,
} from '../message-template-events';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmitEmailOrPhone: (email: string) => void;
  isSMS?: boolean;
};

export const PreviewFormDialog: React.FC<Props> = (props) => {
  const { open, onClose, onSubmitEmailOrPhone, isSMS } = props;

  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [error, seterror] = useState(false);

  useSubscription(onSendTemplateEvent, () => {
    setloadingSubmit(false);
    onClose();
  });

  useSubscription(onSendTemplateErrorEvent, () => {
    setloadingSubmit(false);
  });

  const onSubmit = (): void => {
    if (!isValidEmail(emailOrPhone || '') && !isSMS) {
      seterror(true);
      return;
    }
    if (!isValidPhoneNumber(emailOrPhone || '') && isSMS) {
      seterror(true);
      return;
    }
    onSubmitEmailOrPhone(emailOrPhone);
    setloadingSubmit(true);
  };

  return (
    <Dialog
      isVisible={open}
      onClose={onClose}
      size="md"
      title="Send Preview"
      actions={
        <>
          <ButtonDefault onClick={onClose}>Cancel</ButtonDefault>
          <ButtonAction isLoading={loadingSubmit} onClick={onSubmit}>
            Submit
          </ButtonAction>
        </>
      }
    >
      <Box width="400px">
        <Box mb="20px" fontSize="15px" fontWeight={500}>
          Enter your {isSMS ? 'phone number' : 'email'} to receive a preview
        </Box>
        <TextFieldDefault
          error={error}
          helperText={error ? 'You must enter a valid ' + (isSMS ? 'phone number' : 'email') : ''}
          placeholder={isSMS ? 'Phone number' : 'Email'}
          value={emailOrPhone}
          onChange={(e) => {
            setEmailOrPhone(e.target.value);
            if (error) seterror(false);
          }}
        />
      </Box>
    </Dialog>
  );
};
