import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { ClientUserType } from '@8baselabs/resident-io-shared';
import {
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import { OnFetchSession } from '../../modules/session/session-events';
import { getProperties } from '../../modules/settings-view/settings-actions';
import { Checkbox } from './ui/inputs/Checkbox';
import { MainLoader } from './MainLoader';
import Tooltip from '@mui/material/Tooltip';
import { OnSelectClient } from './components-events';
import { useLocalStorage } from './components-hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      background: '#fff',
      padding: theme.spacing(3),
      margin: theme.spacing(-3, -3, -3, -3),
    },
  }),
);

type Props = {
  selectedPropertyId: string;
  setSelectedPropertyId: (val: string) => void;
  tooltipMessage?: string;
  isChecksSelector?: boolean;
  disabled?: boolean;
};

export const PropertiesCardSelector: React.FC<Props> = ({
  selectedPropertyId,
  setSelectedPropertyId,
  tooltipMessage,
  isChecksSelector = false,
  disabled,
}) => {
  const classes = useStyles();

  const session = useEvent(OnFetchSession);
  const [propertiesList, loading] = useFetchAction(getProperties, [
    session?.user?.selectedClient?.id ||
      session?.user?.userClientUserRelation?.items[0]?.client?.id ||
      '',
    session?.user.userClientUserRelation?.items[0]?.id || '',
    (session?.user.userClientUserRelation?.items[0]?.role ||
      '') as ClientUserType,
  ]);
  useSubscription(OnSelectClient, () => {
    setSelectedPropertyId('');
  });

  const [, setPropertySelectedId] = useLocalStorage('selected-property-id', '');

  useEffect(() => {
    setPropertySelectedId(selectedPropertyId || '');
  }, [selectedPropertyId, setPropertySelectedId]);

  if (loading) return <MainLoader />;

  return (
    <Toolbar className={classes.toolbar}>
      <Grid container alignItems="center">
        <Grid
          item
          xs={1}
          style={
            !selectedPropertyId
              ? { marginRight: '50px' }
              : { marginRight: '90px' }
          }>
          <Typography variant="h6">Properties</Typography>
        </Grid>
        {propertiesList?.items.map((property) => {
          const propertyNeedsCheckBookApiKey =
            isChecksSelector &&
            !property?.propertyCheckbookAccountRelation?.apiKey;

          const disable = disabled || propertyNeedsCheckBookApiKey;
          return (
            <Grid item key={property.id ?? ''}>
              {propertyNeedsCheckBookApiKey ? (
                <Tooltip title={tooltipMessage || ''} placement="bottom-start">
                  <Checkbox
                    name={property.id ?? ''}
                    label={property.name ?? ''}
                    disabled={disable}
                    checked={property.id === selectedPropertyId}
                    handleChange={() =>
                      setSelectedPropertyId(property.id ?? '')
                    }
                  />
                </Tooltip>
              ) : (
                <Checkbox
                  name={property.id ?? ''}
                  label={property.name ?? ''}
                  disabled={disable}
                  checked={property.id === selectedPropertyId}
                  handleChange={() => setSelectedPropertyId(property.id ?? '')}
                />
              )}
            </Grid>
          );
        })}
        {!loading && (!propertiesList || propertiesList.items.length < 1) ? (
          <Grid item xs={12}>
            <Alert severity="error">You&apos;ve no properties created.</Alert>
          </Grid>
        ) : null}
        {!selectedPropertyId ? (
          <Grid item xs={12}>
            <Alert severity="warning">
              To see more details, please select a property from the options
              provided.
            </Alert>
          </Grid>
        ) : null}
      </Grid>
    </Toolbar>
  );
};
