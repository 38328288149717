import {SuccessResponse, Event, Property } from '@8baselabs/resident-io-shared';
import { createEvent } from '@8baselabs/react-simple-state';

export const fetchEventsFromPropertyEvent = createEvent<Property>();
export const fetchEventsFromPropertyError = createEvent<Error>();

export const createEventEvent = createEvent<Event | undefined>();
export const createEventError = createEvent<Error>();

export const updateEventEvent = createEvent<Event | undefined>();
export const updateEventError = createEvent<Error>();

export const deleteEventEvent = createEvent<SuccessResponse | undefined>();
export const deleteEventError = createEvent<Error>();

export const onOpenNewEventModal = createEvent<{
  open: boolean;
  event?: {
    start: string;
    end: string;
  };
}>();

export const onOpenEventEditModal = createEvent<{
  open: boolean;
  eventId?: string;
  event?: {
    title: string;
    start: string;
    end: string;
    propertyId: string;
  };
}>();

