import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import {
  WORKSPACE_ENDPOINT,
  EIGHTBASE_WS_ENDPOINT,
  WORKSPACE_ID,
  ENVIRONMENT_NAME,
} from '../constants';
import { OnTokenEvent } from '../events/token-event';
import { onError } from '@apollo/client/link/error';
//import { authenticatedVar } from './apollo-utils';

/**
 * @param {Function} getToken - Function to get the token.
 * @param {object} headers - Extra header to the client.
 * @returns {object} Apollo client.
 */
export function createApolloClient(
  getToken: () => string,
  headers = {},
): ApolloClient<NormalizedCacheObject> {
  const environmentName =
    ENVIRONMENT_NAME &&
    ENVIRONMENT_NAME.length > 0 &&
    ENVIRONMENT_NAME.toLowerCase() !== 'master'
      ? ENVIRONMENT_NAME
      : undefined;
  const httpLink = new HttpLink({
    uri: WORKSPACE_ENDPOINT,
  });

  const authLink = setContext((_, { headers: _headers }) => {
    const token = getToken();
    return {
      headers: {
        ...headers,
        ..._headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
  const wsLink = new WebSocketLink({
    uri: `${EIGHTBASE_WS_ENDPOINT}`,
    options: {
      reconnect: true,
      timeout: 10,
      connectionParams: () => {
        const token = getToken();
        return {
          token,
          environmentName,
          workspaceId: WORKSPACE_ID,
        };
      },
    },
    webSocketImpl: class WebSocketWithoutProtocol extends WebSocket {
      // eslint-disable-next-line @typescript-eslint/no-useless-constructor
      constructor(url: string) {
        super(url); // ignore protocol
      }
    },
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const logoutLink = onError(({ graphQLErrors }) => {
  /*   if (
      //@ts-expect-error package typings
      graphQLErrors?.[0]?.code === 'TokenExpiredError'
    ) {
      authenticatedVar(false);
    } else {
      if (graphQLErrors?.[0]?.message.toLowerCase() === 'token validation') {
        console.log('Access token validation error');
      } */

  });

  const client = new ApolloClient({
    uri: WORKSPACE_ENDPOINT,
    link: logoutLink.concat(authLink).concat(splitLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-first',
      },
      mutate: {
        fetchPolicy: 'network-only',
      },
    },
  });
  return client;
}

export const apolloClient: ApolloClient<NormalizedCacheObject> =
  createApolloClient(() => OnTokenEvent.get()?.token as string);
