import React, { useContext, useEffect, useState } from 'react';
import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';
import { EntryType, Lease } from '@8baselabs/resident-io-shared';
import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { AlertContext } from '../../../routes/AlertContext';
import { MUTED_COLOR } from '../../../shared/theme';
import { formatCurrency } from '../../../shared/utils';
import { MoveOutLetterDialog } from '../components/MoveOutLetterDialog';
import { updateLeaseOpportunitiesAction } from '../../opportunities/opportunities-actions';
import { AddReservationUpdateEvent } from '../../opportunities/opportunities-event';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: '0 5px 21px 0 rgba(171,171,171,0.17)',
    borderRadius: '8px',
    padding: '20px',
  },
}));

interface Props {
  selectedLease?: Lease;
}

export const MoveOut: React.FC<Props> = ({ selectedLease }: Props) => {
  const classes = useStyles();
  const alertSnackbar = useContext(AlertContext);
  const history = useHistory();
  const { id: userID } = useParams<{ id: string }>();

  const [updateLeaseStatus, loadingUpdate] = useCallAction(
    updateLeaseOpportunitiesAction,
  );
  const [checkNumber, setCheckNumber] = useState<number>();
  const [moveOutStreet, setMoveOutStreet] = useState<string>();
  const [moveOutStreet2, setMoveOutStreet2] = useState<string>();
  const [moveOutState, setMoveOutState] = useState<string>();
  const [moveOutCity, setMoveOutCity] = useState<string>();
  const [moveOutStateCode, setMoveOutStateCode] = useState<string>();
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const [moveOutDate, setMoveOutDate] = useState<string>();
  const [moveOutNote, setMoveOutNote] = useState<string>();
  const [balance, setBalance] = useState<number>(0);
  const [openLetter, setOpenLetter] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedLease) return;

    if (selectedLease?.status !== 'MOVE-IN') {
      history.push(`/people/${userID}/profile`);
    }

    setMoveOutStreet(selectedLease?.property?.street);
    setMoveOutStreet2(selectedLease?.property?.street2);
    setMoveOutState(selectedLease?.property?.state);
    setMoveOutStateCode(selectedLease?.property?.stateCode);
    setMoveOutCity(selectedLease?.property?.city);
    setMoveOutDate(moment(new Date()).format('YYYY/MM/DD'));
    let accBalance = 0;
    selectedLease?.leaseLedgerRelation?.items?.forEach((ledger) => {
      accBalance =
        ledger.type === EntryType.DEBIT
          ? accBalance - (ledger.amount || 0)
          : accBalance + (ledger.amount || 0);
    });
    setBalance(accBalance);

    const depositLedger = selectedLease?.leaseLedgerRelation?.items.filter(
      (ledger) => ledger.description === 'Security Deposit',
    );
    if (depositLedger?.length !== 0) {
      const depositAmountVariable = (depositLedger?.[0]?.amount as number) ?? 0;
      setDepositAmount(depositAmountVariable);
    } else {
      setDepositAmount(0);
    }
  }, [history, selectedLease, userID]);

  const handleCloseLetterDialog = (): void => {
    setOpenLetter(false);
  };

  const onSubmitHandler = (e: React.FormEvent): void => {
    e.preventDefault();
    setOpenLetter(true);
  };

  const onFinalizeMoveOutHandler = (): void => {
    updateLeaseStatus({
      leaseMoveOutRelation: {
        create: {
          checkNumber: checkNumber?.toString(),
          city: moveOutCity,
          state: moveOutState,
          street: moveOutStreet,
          street2: moveOutStreet2,
          zipCode: moveOutStateCode,
          date: moment(moveOutDate as string).toISOString(),
          note: moveOutNote,
          dispositionLetter: {
            balance: balance,
            depositAmount: depositAmount,
            depositRefund: depositAmount,
            additionalRefund:
              balance > 0 && Math.abs(depositAmount - balance) > 0
                ? balance
                : 0,
            totalRefund:
              balance > 0
                ? depositAmount +
                  (balance > 0 && Math.abs(depositAmount - balance) > 0
                    ? balance
                    : 0)
                : 0,
            balanceDue: balance < 0 ? depositAmount + balance : 0,
          },
        },
      },
      id: selectedLease?.id,
      status:
        depositAmount + balance > 0
          ? 'MOVE-OUT_REFUND_ISSUED'
          : 'MOVE-OUT_BALANCE_OWED',
      bed: {
        disconnect: {
          id: selectedLease?.bed?.id,
        },
      },
      /* unit: {
        disconnect: {
          id: selectedLease?.unit?.id
        }
      },
      room: {
        disconnect: {
          id: selectedLease?.room?.id
        }
      },
      floor: {
        disconnect: {
          id: selectedLease?.floor?.id
        }
      } */
    });
    setOpenLetter(false);
  };

  useSubscription(AddReservationUpdateEvent, () => {
    alertSnackbar({
      success: true,
      message: 'Lease successfully updated',
    });
    history.push(`/people/${userID}/profile`);
  });

  if (!selectedLease) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box p="40px">
      <Box fontSize="20px" fontWeight="500">
        Move Out
      </Box>
      <Box className={classes.card} mt="30px" mb="50px">
        <Box fontWeight="500" fontSize="16px">
          How it works
        </Box>
        <ul>
          <li>
            Enter all charges or credits incurred upon move out before
            proceeding (i.e., cleaning costs, prorated utility fees, lease
            cancellation fees, etc.).
          </li>
          <li>
            In the form below, enter the Resident's forwarding Address. The
            move-out workflow will apply all deposits on hand and other credit
            amounts and create a deposit disposition summary letter showing
            either the refund or balance due on the account.
          </li>
        </ul>
      </Box>
      <form onSubmit={onSubmitHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              clearable
              required
              autoOk
              fullWidth
              variant="inline"
              inputVariant="outlined"
              disableFuture
              minDate={selectedLease.leasePeriod?.startDate}
              maxDate={selectedLease.leasePeriod?.endDate}
              label="MOVE OUT DATE"
              format="MMMM dd, yyyy"
              name="startDate"
              value={moveOutDate}
              onChange={(date, value) =>
                setMoveOutDate(moment(value).format('MM/DD/YYYY'))
              }
              InputAdornmentProps={{
                style: {
                  borderLeft: `1px solid ${MUTED_COLOR}`,
                  height: 'auto',
                },
              }}
              inputProps={{ disabled: true }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="CHECK NUMBER"
              value={checkNumber ?? ''}
              onChange={(event) => {
                setCheckNumber(event.target.value as unknown as number);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="STREET"
              value={moveOutStreet ?? ''}
              onChange={(event) => {
                setMoveOutStreet(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="STREET 2"
              value={moveOutStreet2 ?? '-'}
              onChange={(event) => {
                setMoveOutStreet2(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="CITY"
              value={moveOutCity ?? '-'}
              onChange={(event) => {
                setMoveOutCity(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="STATE"
              value={moveOutState ?? '-'}
              onChange={(event) => {
                setMoveOutState(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="ZIP CODE"
              value={moveOutStateCode ?? '-'}
              onChange={(event) => {
                setMoveOutStateCode(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="MOVE OUT NOTES"
              multiline
              rows={4}
              value={moveOutNote ?? ''}
              onChange={(event) => {
                setMoveOutNote(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Box className={classes.card} mt="30px" mb="50px">
          <Box display="flex" justifyContent="space-between" fontSize="16px">
            <p>Current Balance</p>
            <p>{formatCurrency(balance, false)}</p>
          </Box>

          <Box display="flex" justifyContent="space-between" fontSize="16px">
            <p>Deposit On Hand</p>
            <p>{formatCurrency(depositAmount as number, false)}</p>
          </Box>
          {depositAmount > 0 ? (
            <Box display="flex" justifyContent="space-between" fontSize="16px">
              <p>Deposit Refund</p>
              <p>{formatCurrency(depositAmount as number, false)}</p>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" fontSize="16px">
              <p>Deposit Refund</p>
              <p>{formatCurrency(0, false)}</p>
            </Box>
          )}
          {balance > 0 && Math.abs(depositAmount - balance) > 0 ? (
            <Box display="flex" justifyContent="space-between" fontSize="16px">
              <p>Additional Refund</p>
              <p>{formatCurrency(balance, false)}</p>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" fontSize="16px">
              <p>Additional Refund</p>
              <p>{formatCurrency(0, false)}</p>
            </Box>
          )}
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit">
            Preview Disposition Letter
          </Button>
        </Box>
      </form>
      {openLetter && (
        <MoveOutLetterDialog
          isOpen={openLetter}
          loadingUpdate={loadingUpdate}
          onClose={handleCloseLetterDialog}
          selectedLease={selectedLease}
          depositAmount={depositAmount}
          depositRefund={depositAmount > 0 ? depositAmount : 0}
          additionalRefund={
            balance > 0 && Math.abs(depositAmount - balance) > 0 ? balance : 0
          }
          checkNumber={checkNumber?.toString() ?? ''}
          leaseBalance={balance}
          onFinalizeMoveOut={onFinalizeMoveOutHandler}
          userID={userID}
        />
      )}
    </Box>
  );
};
