import { ValidationError } from 'yup';

/**
 * Transform yup ValidationError to object literals.
 *
 * @param value - Error object.
 * @returns - Errors in object format with string values.
 */
export const getErrorsAsObject = <T extends Record<string, string | undefined>>(
  value: ValidationError,
): T => {
  let flatObject: T = {} as T;
  value.inner.forEach((error) => {
    flatObject = { ...flatObject, [error.path ?? '']: error.message };
  });

  return flatObject;
};
