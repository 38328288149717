import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ValidationError } from 'yup';
import * as filestack from 'filestack-js';
import Grid from '@material-ui/core/Grid';
import {
  useCallAction,
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';

import {
  AddReservationFetchLeaseEvent,
  AddReservationSelectResidentApplicationEvent,
  AddReservationSelectResidentEvent,
  AddReservationUpdateResidentApplicationEvent,
  AddReservationUpdateResidentApplicationEventError,
  createRequiredFileEvent,
  getRequiredFilesEvent,
  getRequiredDocumentsEvent,
  updateRequiredFileEvent,
  //updateRequiredFileEvent,
  updateRequiredFileEventError,
  deleteRequiredFilesEvent,
  requiredFilesEvent,
  FormHasContent,
} from '../opportunities-event';
import { getErrorsAsObject } from '../../../shared/utils/errors';
// import { getObjectAddress } from '../../../shared/utils/google-utils';
import { ApplicationFormSection } from '../../settings-view/property-documents/components/ApplicationFormSection';
import { standardSections } from './AppFormStandardSectionsData';
import { guarantorSections } from './AppFormGuarantorSectionsData';

// import { AccordionSection } from '../../settings-view/property-documents/components/AccordionSection';
import { CustomSectionConstructor } from '../../settings-view/property-documents/components/CustomSections';
import { AlertContext } from '../../../routes/AlertContext';
import {
  createRequiredFile,
  deleteRequiredFile,
  getRequiredDocuments,
  getUserRequiredFiles,
  updateRequiredFile,
} from '../opportunities-actions';
import { SetionTitle } from '../../../shared/components/ui/texts/CommonTexts';
import { downloadFileWithDownloadUrl } from '../../../shared/utils';
import {
  Loading,
  LoadingContainer,
} from '../../../shared/components/ui/Loading';
import { validateFileCount } from '../opportunities-utils';
import { fetchUploadInfoAction } from '../../profile/my-info/my-info-actions';
import { FilestackInput } from '../../../shared/components/ui/inputs/FilestackInput';
import { Alert, Paper } from '@mui/material';
import { nextStep } from '../../tenant/tenant-events';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { FileValue } from '@8base-react/file-input';
import { FormType } from '../../../shared/constants/resident';
import {
  GuarantorUpdateInput,
  ResidentApplication,
  UserUpdateInput,
} from '../../../schema-types';

/**
 *
 * @returns {React.FC} - Application sub view.
 */

interface Props {
  formType: string;
  disableApplication?: boolean;
  guarantorRequirement?: boolean;
  disabledSections?: boolean;
  readOnly?: boolean;
}

interface ICurrentDocument {
  existingDocumentId: string;
  requiredUploadId: string;
  documentToUploadId: string;
  type: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4, 2, 4, 4),
    },
  }),
);

const Mapplication = ({
  formType,
  disableApplication = false,
  guarantorRequirement = false,
  disabledSections,
  readOnly,
}: Props): JSX.Element => {
  const [clickedFileIndex, setClickedFileIndex] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<any>({}); //eslint-disable-line @typescript-eslint/no-unused-vars
  const selectedResident = useEvent(AddReservationSelectResidentEvent);
  const values = useEvent(AddReservationSelectResidentApplicationEvent);
  const lease = useEvent(AddReservationFetchLeaseEvent);
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();

  const currentUploadRef = useRef<ICurrentDocument>({
    existingDocumentId: '',
    requiredUploadId: '',
    documentToUploadId: '',
    type: '',
  });

  const [path, setPath] = useState<string>();

  const standardSectionsIncludes = guarantorRequirement
    ? (lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
        (form) => form.type === FormType.RESIDENT_WITH_GUARANTOR_APPLICATION,
      )?.standardSectionsIncludes?.data as string[]) ?? []
    : (lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
        (form) => form.type === FormType.RESIDENT_APPLICATION,
      )?.standardSectionsIncludes?.data as string[]) ?? [];
  const guarantorSectionsIncludes =
    lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
      (form) => form.type === FormType.GUARANTOR_APPLICATION,
    )?.standardSectionsIncludes?.data as string[];
  const customSections = guarantorRequirement
    ? (lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
        (form) => form.type === FormType.RESIDENT_WITH_GUARANTOR_APPLICATION,
      )?.customSections?.data as CustomSectionConstructor[])
    : (lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
        (form) => form.type === FormType.RESIDENT_APPLICATION,
      )?.customSections?.data as CustomSectionConstructor[]);

  const flatten = (arr): [] => {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
      );
    }, []);
  };
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [applicationInputs, setApplicationInputs] =
    useState<ResidentApplication>({ ...values.application } || {});

  const [userInputs, setuserInputs] = useState<UserUpdateInput>(() => ({
    ...values.user,
  }));
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [guarantorInputs, setguarantorInputs] = useState<GuarantorUpdateInput>(
    { ...values.guarantor } || {},
  );
  const alertSnackbar = useContext(AlertContext);

  const userId = lease?.userResident?.id ?? '';

  const [hasFileSection, setHasFileSection] = useState(false);

  const [uploads, loadingUploads] = useFetchAction(
    getRequiredDocuments,
    [lease?.property?.id],
    {
      onCompleted(data) {
        if (data.length === 0 && disableApplication) nextStep.dispatch();

        let shouldRenderFiles;
        data.forEach((upload) => {
          if (formType === 'TENANT_ONLY' || formType === 'BOTH') {
            const documentToUpload =
              upload?.documentTypeRequiredUploadRelation?.items?.find(
                (document) => {
                  return document.isIncluded && document.isTenant;
                },
              );
            if (documentToUpload) {
              shouldRenderFiles = true;
            }
          }
          if (formType === 'GUARANTOR_ONLY' || formType === 'BOTH') {
            const documentToUpload =
              upload?.documentTypeRequiredUploadRelation?.items?.find(
                (document) => {
                  return document.isIncluded && document.isGuarantor;
                },
              );
            if (documentToUpload) {
              shouldRenderFiles = true;
            }
          }
        });
        if (shouldRenderFiles) {
          setHasFileSection(true);
        }
      },
    },
  );

  const [files, loadingFiles, { refetch: refetchFiles }] = useFetchAction(
    getUserRequiredFiles,
    [userId],
  );

  useFetchAction(fetchUploadInfoAction, [], {
    onCompleted(wrapperUploadInfo) {
      setPath(wrapperUploadInfo.path);
      const clientOptions = {
        security: {
          policy: wrapperUploadInfo.policy,
          signature: wrapperUploadInfo.signature,
        },
      };

      const initializedFilestack = filestack.init(
        wrapperUploadInfo.apiKey,
        clientOptions,
      );
      setClientFileStack(initializedFilestack);
    },
  });

  useEffect(() => {
    const appFields =
      standardSections
        ?.filter((section) => standardSectionsIncludes?.includes(section.id))
        ?.map((section) => [
          ...(section.userFields?.map((userField) => ({
            ...userField,
            category: 'user',
            section: section.title,
          })) ?? []),
          ...(section.extraApplicationFields?.map((applicationField) => ({
            ...applicationField,
            category: 'app',
            section: section.title,
          })) ?? []),
        ]) ?? [];

    const customFields =
      customSections?.map(
        (section) =>
          section.customFields?.map((applicationField) => ({
            ...applicationField,
            category: 'app',
            section: section.title,
          })) ?? [],
      ) ?? [];

    const guarantorFields =
      guarantorSections
        ?.filter((section) => guarantorSectionsIncludes?.includes(section.id))
        ?.map(
          (section) =>
            section.guarantorFields?.map((guarantorField) => ({
              ...guarantorField,
              category: 'guarantor',
              section: section.title,
              sectionId: section.id,
            })) ?? [],
        ) ?? [];

    if (applicationInputs || userInputs) {
      const fields = flatten([
        ...(formType === 'TENANT_ONLY' || formType === 'BOTH' ? appFields : []),
        ...(formType === 'TENANT_ONLY' || formType === 'BOTH'
          ? customFields
          : []),
        ...(formType === 'GUARANTOR_ONLY' || formType === 'BOTH'
          ? guarantorFields
          : []),
      ]);
      AddReservationSelectResidentApplicationEvent.dispatch({
        sections: { ...values.sections },
        application: applicationInputs,
        user: userInputs,
        guarantor: guarantorInputs,
        fields,
        fieldsForPartialValidation: [
          ...(values.fieldsForPartialValidation ?? []),
        ],
      });
    }

    if (!hasFileSection) {
      requiredFilesEvent.dispatch({ allFilesUploaded: true });
    }
    if (formType === 'TENANT_ONLY') {
      if (!customSections?.length && !standardSectionsIncludes?.length) {
        if (!hasFileSection) {
          FormHasContent.dispatch(false);
        }
      }
      return FormHasContent.dispatch(true);
    } else if (formType === 'GUARANTOR_ONLY') {
      if (!guarantorSectionsIncludes?.length) {
        if (!hasFileSection) {
          FormHasContent.dispatch(false);
        }
      }
      return FormHasContent.dispatch(true);
    } else {
      if (
        !customSections?.length &&
        !standardSectionsIncludes?.length &&
        !guarantorSectionsIncludes?.length
      ) {
        if (!hasFileSection) {
          FormHasContent.dispatch(false);
        }
        return FormHasContent.dispatch(true);
      }
    }
  }, [lease, requiredFilesEvent]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedResident && !values.user) {
      setuserInputs({
        id: selectedResident.id,
        firstName: selectedResident.firstName,
        middleName: selectedResident.middleName,
        lastName: selectedResident.lastName,
        preferredName: selectedResident.preferredName,
        email: selectedResident.email || '',
        birthdate: selectedResident.birthdate,
        gender: selectedResident.gender,
        genderIdentify: selectedResident.genderIdentify,
        phoneNumber: selectedResident.phoneNumber,
        homePhone: selectedResident.homePhone,
        studentIdOrEmployeeNumber: selectedResident.studentIdOrEmployeeNumber,
        address: selectedResident.address,
        city: selectedResident.city,
        state: selectedResident.state,
        zipCode: selectedResident.zipCode,
        aboutMe: selectedResident.aboutMe,
      });
    }
  }, [selectedResident, values.user]);

  useSubscription(
    AddReservationUpdateResidentApplicationEventError,
    (value) => {
      if (value && value instanceof ValidationError) {
        let errorMsg = '';
        value.inner.slice(0, 2).map((error) => {
          errorMsg += error.message + '\n';
          return null;
        });
        errorMsg +=
          value.inner.length > 2
            ? `...and ${value.inner.length - 2} more error${
                value.inner.length > 3 ? 's' : ''
              }`
            : '';
        alertSnackbar({
          error: true,
          message: errorMsg,
        });
        setErrors(getErrorsAsObject(value));
      }
    },
  );

  useSubscription(AddReservationUpdateResidentApplicationEvent, (value) => {
    alertSnackbar({
      success: true,
      message: 'Application Data successfully saved',
    });
  });

  useSubscription(getRequiredDocumentsEvent, () => {
    if (!uploads || !files) return;
    validateFileCount(uploads, files, formType);
  });

  useSubscription(getRequiredFilesEvent, () => {
    if (!uploads || !files) return;
    validateFileCount(uploads, files, formType);
  });

  useSubscription(createRequiredFileEvent, () => {
    refetchFiles();
  });
  useSubscription(updateRequiredFileEvent, () => {
    refetchFiles();
  });
  useSubscription(deleteRequiredFilesEvent, () => {
    refetchFiles();
  });

  useSubscription(updateRequiredFileEventError, () => {
    alertSnackbar({
      error: true,
      message: 'Something went wrong updating the document',
    });
  });

  const [createRequiredUploadFile, loadingCreation] = useCallAction(
    createRequiredFile,
    {
      onCompleted: () => {
        refetchFiles();
      },
    },
  );
  const [updateRequiredUploadFile, loadingUpdate] =
    useCallAction(updateRequiredFile);

  const [deleteRequiredUploadFile, loadingDelete] =
    useCallAction(deleteRequiredFile);

  const handleFileUpload = useCallback(
    (
      file: FileValue,
      requiredUploadId: string,
      documentId: string,
      type: string,
    ): void => {
      const data = { file, requiredUploadId, documentId, userId, type };
      createRequiredUploadFile(data);
    },
    [createRequiredUploadFile, userId],
  );

  const handleFileUpdate = useCallback(
    (file: filestack.PickerFileMetadata, fileId: string): void => {
      updateRequiredUploadFile({
        id: fileId,
        file: {
          create: {
            fileId: file.handle,
            filename: file.filename,
          },
        },
      });
    },
    [updateRequiredUploadFile],
  );

  const handleFileDelete = useCallback(
    (fileId: string): void => {
      deleteRequiredUploadFile(fileId);
    },
    [deleteRequiredUploadFile],
  );

  const onUploadDone = useCallback(
    (newFiles: filestack.PickerResponse): void => {
      const { existingDocumentId, documentToUploadId, requiredUploadId, type } =
        currentUploadRef.current;
      const file = {
        filename: newFiles.filesUploaded[0].filename,
        fileId: newFiles.filesUploaded[0].handle,
      };
      if (newFiles?.filesUploaded?.length) {
        if (!existingDocumentId) {
          handleFileUpload(
            // newFiles.filesUploaded[0],
            file,
            documentToUploadId ?? '',
            requiredUploadId ?? '',
            type ?? 'TENANT',
          );
        } else {
          handleFileUpdate(newFiles.filesUploaded[0], existingDocumentId);
        }
      }
    },
    [handleFileUpdate, handleFileUpload, currentUploadRef],
  );
  const handleDocument = useCallback(async (): Promise<void> => {
    if (clientFileStack !== undefined) {
      const options = {
        fromSources: ['local_file_system'],
        onUploadDone,
        storeTo: { path },
        maxFiles: 1,
      };

      clientFileStack.picker(options).open();
    }
  }, [clientFileStack, onUploadDone, path]);

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingUploads) {
    return (
      <>
        <LoadingContainer>
          <Loading size={64} />
        </LoadingContainer>
      </>
    );
  }

  if (
    formType === 'BOTH' &&
    ((guarantorRequirement &&
      (
        lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
          (form) => form.type === FormType.RESIDENT_WITH_GUARANTOR_APPLICATION,
        )?.standardSectionsIncludes?.data as string[]
      )?.length === 0) ||
      (!guarantorRequirement &&
        (
          lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
            (form) => form.type === FormType.RESIDENT_APPLICATION,
          )?.standardSectionsIncludes?.data as string[]
        )?.length === 0)) &&
    (
      lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
        (form) => form.type === FormType.GUARANTOR_APPLICATION,
      )?.standardSectionsIncludes?.data as string[]
    )?.length === 0
  ) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          There is no application form associated to this Lease Period
        </Alert>
      </Grid>
    );
  } else if (
    formType === 'TENANT_ONLY' &&
    ((guarantorRequirement &&
      (
        lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
          (form) => form.type === FormType.RESIDENT_WITH_GUARANTOR_APPLICATION,
        )?.standardSectionsIncludes?.data as string[]
      )?.length === 0) ||
      (!guarantorRequirement &&
        (
          lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.find(
            (form) => form.type === FormType.RESIDENT_APPLICATION,
          )?.standardSectionsIncludes?.data as string[]
        )?.length === 0))
  ) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          There is no application form associated to this Lease Period
        </Alert>
      </Grid>
    );
  } else if (
    formType === 'GUARANTOR_ONLY' &&
    (
      lease?.leasePeriod?.propertyFormLeasePeriodRelation?.items.filter(
        (form) => form.type === FormType.GUARANTOR_APPLICATION,
      )?.[0]?.standardSectionsIncludes?.data as string[]
    )?.length === 0
  ) {
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          There is no application form associated to this Lease Period
        </Alert>
      </Grid>
    );
  }

  if (!FormHasContent.get()) {
    return (
      <Grid item xs={12}>
        <Alert severity="warning">
          There is no application for this lease period.
        </Alert>
      </Grid>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={12}>
          {(formType === 'TENANT_ONLY' || formType === 'BOTH') &&
            standardSections
              ?.filter((section) =>
                standardSectionsIncludes?.includes(section.id),
              )
              .map((section) => (
                <ApplicationFormSection
                  section={section}
                  disabledFields={disabledSections}
                  readOnly={readOnly}
                  key={`appFormSection-${section.id}`}
                />
              ))}
          {(formType === 'TENANT_ONLY' || formType === 'BOTH') &&
            customSections?.map((customSection) => (
              <ApplicationFormSection
                customSection={customSection}
                disabledFields={disabledSections}
                readOnly={readOnly}
                key={`appFormCustomSection-${customSection.id}`}
              />
            ))}
          {(formType === 'GUARANTOR_ONLY' || formType === 'BOTH') &&
            guarantorSections
              ?.filter((section) =>
                guarantorSectionsIncludes?.includes(section.id),
              )
              .map((guarantorSection) => (
                <ApplicationFormSection
                  section={guarantorSection}
                  disabledFields={disabledSections}
                  readOnly={readOnly}
                  key={`appFormGuarantorSection-${guarantorSection.id}`}
                />
              ))}
        </Grid>
      </Grid>
      {hasFileSection && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SetionTitle>Required Documents</SetionTitle>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {(formType === 'TENANT_ONLY' || formType === 'BOTH') &&
                uploads?.map((requiredUpload, index) => {
                  const documentToUpload =
                    requiredUpload?.documentTypeRequiredUploadRelation?.items?.find(
                      (document) => {
                        return document.isIncluded && document.isTenant;
                      },
                    );

                  const existingDocument = files?.find(
                    (file) =>
                      file.type === 'TENANT' &&
                      !!file.requiredFileDocumentTypeRelation?.documentTypeRequiredUploadRelation?.items.find(
                        (upload) =>
                          upload?.id === documentToUpload?.id &&
                          upload?.isTenant &&
                          upload?.isIncluded,
                      ),
                  );

                  return documentToUpload ? (
                    <>
                      <Grid
                        xs={12}
                        item
                        className={''}
                        key={index + 'inputGrid'}>
                        <FilestackInput
                          key={index + 'tenantInput'}
                          docs={existingDocument?.file}
                          loading={
                            (loadingFiles ||
                              loadingCreation ||
                              loadingUpdate ||
                              loadingDelete) &&
                            clickedFileIndex === index + 'tenant'
                          }
                          handleDelete={
                            existingDocument?.file && !disabledSections
                              ? () => {
                                  setClickedFileIndex(() => index + 'tenant');
                                  handleFileDelete(existingDocument.id);
                                }
                              : null
                          }
                          handleClick={
                            disabledSections
                              ? () => {
                                  if (!existingDocument?.file) {
                                    return;
                                  }
                                  downloadFileWithDownloadUrl(
                                    existingDocument?.file.downloadUrl,
                                    existingDocument?.file.filename,
                                  );
                                }
                              : () => {
                                  currentUploadRef.current = {
                                    existingDocumentId:
                                      existingDocument?.id ?? '',
                                    requiredUploadId: requiredUpload.id,
                                    documentToUploadId: documentToUpload.id,
                                    type: 'TENANT',
                                  };
                                  setClickedFileIndex(() => index + 'tenant');
                                  handleDocument();
                                }
                          }
                          buttonTitle={
                            disabledSections ? 'DOWNLOAD' : undefined
                          }
                          label={
                            documentToUpload.isRequired
                              ? `${requiredUpload.name} - Required`
                              : requiredUpload.name
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  );
                })}
              {(formType === 'GUARANTOR_ONLY' || formType === 'BOTH') &&
                uploads?.map((requiredUpload, index) => {
                  const documentToUpload =
                    requiredUpload?.documentTypeRequiredUploadRelation?.items?.find(
                      (document) => {
                        return document.isIncluded && document.isGuarantor;
                      },
                    );

                  const existingDocument = files?.find(
                    (file) =>
                      file.type === 'GUARANTOR' &&
                      !!file.requiredFileDocumentTypeRelation?.documentTypeRequiredUploadRelation?.items.find(
                        (upload) =>
                          upload?.id === documentToUpload?.id &&
                          upload?.isGuarantor &&
                          upload?.isIncluded,
                      ),
                  );

                  return documentToUpload ? (
                    <>
                      <Grid
                        xs={12}
                        item
                        className={''}
                        key={index + 'inputGrid'}>
                        <FilestackInput
                          key={index + 'guarantorInput'}
                          docs={existingDocument?.file}
                          loading={
                            (loadingFiles ||
                              loadingCreation ||
                              loadingUpdate ||
                              loadingDelete) &&
                            clickedFileIndex === index + 'guarantor'
                          }
                          handleDelete={
                            existingDocument?.file && !disabledSections
                              ? () => {
                                  setClickedFileIndex(
                                    () => index + 'guarantor',
                                  );
                                  handleFileDelete(existingDocument.id);
                                }
                              : null
                          }
                          handleClick={
                            disabledSections
                              ? () => {
                                  if (!existingDocument?.file) {
                                    return;
                                  }
                                  downloadFileWithDownloadUrl(
                                    existingDocument?.file.downloadUrl,
                                    existingDocument?.file.filename,
                                  );
                                }
                              : () => {
                                  currentUploadRef.current = {
                                    existingDocumentId:
                                      existingDocument?.id ?? '',
                                    documentToUploadId:
                                      documentToUpload?.id ?? '',
                                    requiredUploadId:
                                      documentToUpload?.documentType?.id ?? '',
                                    type: 'GUARANTOR',
                                  };
                                  setClickedFileIndex(
                                    () => index + 'guarantor',
                                  );
                                  handleDocument();
                                }
                          }
                          buttonTitle={
                            disabledSections ? 'DOWNLOAD' : undefined
                          }
                          label={
                            documentToUpload.isRequired
                              ? `Guarantor ${requiredUpload.name} - Required`
                              : `Guarantor ${requiredUpload.name}`
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    ''
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export const Application = React.memo(Mapplication);
