import React, { useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useSubscription } from '@8baselabs/react-simple-state';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';
import { PRIMARY_COLOR } from '../theme';
import {
  OnRequestEmailVerification,
  OnRequestEmailVerificationError,
} from './components-events';
import { AlertContext } from '../../routes/AlertContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: '0px 5px 15px rgb(171 171 171 / 17%)',
      padding: theme.spacing(0.6),
    },
    menuButton: {
      color: '#B2B2B2',
      marginRight: theme.spacing(2),
    },
    send: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    title: {
      flexGrow: 1,
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    userMenuButton: {
      margin: theme.spacing(0, 0, 0, 3),
      borderRadius: 0,
    },
    userMenuIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
    },
    userMenuItemLogout: {
      color: PRIMARY_COLOR,
      '& .MuiListItemIcon-root': {
        color: PRIMARY_COLOR,
      },
    },
    userMenuItemIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    tool: {
      justifyContent: 'space-between',
    },
    secondaryAppBar: {
      marginTop: 70,
      boxShadow: '0px 5px 15px rgb(171 171 171 / 17%)',
      padding: theme.spacing(1),
      zIndex: 40,
    },
    menuItem: {
      padding: theme.spacing(2),
    },
    fontWeight: {
      fontWeight: 'bold',
    },
    cursor: {
      cursor: 'pointer',
    },
    avatarName: {
      margin: theme.spacing(0, 1),
      fontWeight: 'bold',
    },
    green: {
      color: theme.palette.success.main,
    },
    advMenuItem: {
      backgroundColor: 'red',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      // padding: '6px',
    },
  }),
);

export const OnboardingTopbar: React.FC = () => {
  const classes = useStyles();
  const { logout } = useAuth0();

  const alert = useContext(AlertContext);

  useSubscription(OnRequestEmailVerification, () => {
    alert({
      success: true,
      message: 'Email verification successfully sent',
    });
  });

  useSubscription(OnRequestEmailVerificationError, () => {
    alert({
      error: true,
      message: 'Something went wrong while sending email verification',
    });
  });

  const handleLogout = (): void =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <>
      <AppBar className={classes.appBar} color="default">
        <Toolbar className={classes.tool}>
          <div className={classes.cursor}>
            <a href="/">
              <img
                src="/assets/resident.svg"
                alt="Resident.io Logo"
                style={{ width: '192px' }}
              />
            </a>
          </div>
          <Button color="primary" startIcon={<PowerSettingsNewOutlinedIcon />} onClick={handleLogout}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>{' '}
    </>
  );
};
