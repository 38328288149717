import { createAction } from '@8baselabs/react-simple-state';
import {
  TenantProfile,
  TenantProfileUpdateInput,
  User,
  FileUploadInfoResponse,
  TenantProfileCreateInput,
  UserUpdateInput,
} from '@8baselabs/resident-io-shared';
import { apolloClient } from '../../../shared/apollo';
import { FETCH_SESSION_QUERY } from '../../session/session-queries';
import {
  UpdateUser,
  UpdateTenantProfile,
  UpdateTenantProfileError,
  UpdateUserError,
  FetchUploadInfoEventError,
  FetchUploadInfoEvent,
  ChangePasswordEvent,
  ChangePasswordError,
  ChangePasswordResponse,
  ChangeEmailEvent,
  ChangeEmailError,
  ChangeEmailResponse,
  CreateTenantProfileError,
  CreateTenantProfile,
} from './my-info-events';
import {
  UPDATE_USER,
  UPDATE_TENANT_PROFILE,
  FETCH_UPLOAD_INFO,
  CREATE_TENANT_PROFILE,
  CHANGE_PASSWORD_RESOLVER,
  CHANGE_EMAIL_RESOLVER,
} from './my-info-queries';

/**
 * Update User.
 */
export const updateUserAction = createAction(
  UpdateUser,
  UpdateUserError,
  async (userData: UserUpdateInput) => {
    const apollo = apolloClient;

      const response = await apollo.mutate<{ userUpdate: User }>({
        mutation: UPDATE_USER,
        variables: { data: userData },
        refetchQueries: [{ query: FETCH_SESSION_QUERY }],
        awaitRefetchQueries: true,
      });

    await apollo.cache.reset();

    return response.data?.userUpdate as User;
  },
);

/**
 * Fetch all properties by property owner.
 */
export const updateTenantProfileAction = createAction(
  UpdateTenantProfile,
  UpdateTenantProfileError,
  async (userData: TenantProfileUpdateInput) => {
    const apollo = apolloClient;

    const { data } = await apollo.mutate<{
      tenantProfileUpdate: TenantProfile;
    }>({
      mutation: UPDATE_TENANT_PROFILE,
      variables: { data: userData },
    });
    apollo.cache.reset();
    return data?.tenantProfileUpdate as TenantProfile;
  },
);
/**
 * Fetch all properties by property owner.
 */
export const fetchUploadInfoAction = createAction(
  FetchUploadInfoEvent,
  FetchUploadInfoEventError,
  async () => {
    const apollo = apolloClient;

    const { data } = await apollo.query<{
      fileUploadInfo: FileUploadInfoResponse;
    }>({
      query: FETCH_UPLOAD_INFO,
    });

    return data.fileUploadInfo;
  },
);

export const createTenantProfiAction = createAction(
  CreateTenantProfile,
  CreateTenantProfileError,
  async (userData: TenantProfileCreateInput) => {
    const apollo = apolloClient;

    const { data } = await apollo.mutate<{
      tenantProfileCreate: TenantProfile;
    }>({
      mutation: CREATE_TENANT_PROFILE,
      variables: { data: userData },
    });
    apollo.cache.reset();
    return data?.tenantProfileCreate as TenantProfile;
  },
);

/**
 * Change password.
 */
export const changePasswordAction = createAction(
  ChangePasswordEvent,
  ChangePasswordError,
  async (dataInput) => {
    const apollo = apolloClient;

    const { data } = await apollo.mutate<{
      changePasswordResolver: ChangePasswordResponse;
    }>({
      mutation: CHANGE_PASSWORD_RESOLVER,
      variables: { data: dataInput },
    });

    return data?.changePasswordResolver as ChangePasswordResponse;
  },
);

/**
 * Change Email.
 */
export const changeEmailAction = createAction(
  ChangeEmailEvent,
  ChangeEmailError,
  async (dataInput) => {
    const apollo = apolloClient;

    const { data } = await apollo.mutate<{
      changeEmailResolver: ChangeEmailResponse;
    }>({
      mutation: CHANGE_EMAIL_RESOLVER,
      variables: { data: dataInput },
    });

    return data?.changeEmailResolver as ChangeEmailResponse;
  },
);
