import { Lease, Property, User } from '@8baselabs/resident-io-shared';
import { parse } from 'papaparse';
import { createAction } from '@8baselabs/react-simple-state';
import { apolloClient as client } from '../apollo';

import {
  OnDeselectClient,
  OnDeselectClientError,
  OnGetClientRelation,
  OnGetClientRelationError,
  OnGetLease,
  OnGetLeaseError,
  OnGetProperty,
  OnGetPropertyError,
  OnParsedFile,
  OnParsedFileError,
  OnPrevSelectClient,
  OnRequestEmailVerification,
  OnRequestEmailVerificationError,
  OnSelectClient,
  OnSelectClientError,
} from './components-events';
import {
  DESELECT_CLIENT_MUTATION,
  GET_LEASE,
  GET_PROPERTY,
  GET_PROPERTY_CLIENT_USER_RELATION,
  REQUEST_EMAIL_VERIFICATION_MUTATION,
  SELECT_CLIENT_MUTATION,
} from './components-queries';
import { ResultType } from '../constants';

export const selectClient = createAction(
  OnSelectClient,
  OnSelectClientError,
  async (userId, clientId) => {
    OnPrevSelectClient.dispatch(clientId);
    const userUpdate = (
      await client.mutate<{ userUpdate: User }>({
        mutation: SELECT_CLIENT_MUTATION,
        variables: { userId, clientId },
      })
    ).data?.userUpdate;
    await client.cache.reset();

    return userUpdate as User;
  },
);

export const deselectClient = createAction(
  OnDeselectClient,
  OnDeselectClientError,
  async (userId, clientId) => {
    const userUpdate = (
      await client.mutate<{ userUpdate: User }>({
        mutation: DESELECT_CLIENT_MUTATION,
        variables: { userId, clientId },
      })
    ).data?.userUpdate;
    await client.cache.reset();

    return userUpdate as User;
  },
);

export const fileHandler = createAction(
  OnParsedFile,
  OnParsedFileError,
  async (text: string, fileName: string, handleCsv): Promise<void> => {
    handleCsv({
      fileName,
      data: parse(text, { header: true }).data,
      loading: false,
    });
  },
);

export const resendEmailVerification = createAction(
  OnRequestEmailVerification,
  OnRequestEmailVerificationError,
  async ({ sub }): Promise<ResultType> => {
    const result = (
      await client.mutate<{ result: ResultType }>({
        mutation: REQUEST_EMAIL_VERIFICATION_MUTATION,
        variables: {
          data: {
            sub,
          },
        },
      })
    ).data?.result;

    await client.cache.reset();

    return result as ResultType;
  },
);

export const getLease = createAction(
  OnGetLease,
  OnGetLeaseError,
  async (id): Promise<Lease> => {
    const {
      data: { lease },
    } = await client.query<{ lease: Lease }>({
      query: GET_LEASE,
      variables: {
        id,
      },
    });

    return lease;
  },
);

export const getClientRelationByProperty = createAction(
  OnGetClientRelation,
  OnGetClientRelationError,
  async (id): Promise<Property['clientUser']> => {
    const {
      data: { clientUserList },
    } = await client.query<{ clientUserList: Property['clientUser'] }>({
      query: GET_PROPERTY_CLIENT_USER_RELATION,
      variables: {
        id,
      },
    });

    return clientUserList;
  },
);

export const getProperty = createAction(
  OnGetProperty,
  OnGetPropertyError,
  async (id): Promise<Property> => {
    const {
      data: { property },
    } = await client.query<{ property: Property }>({
      query: GET_PROPERTY,
      variables: {
        id,
      },
    });

    return property;
  },
);
