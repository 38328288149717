import { gql } from '@apollo/client';

export const FETCH_LEASES_USERS = gql`
  query FETCH_LEASES_USERS($filter: LeaseFilter, $sort: [LeaseSort!]) {
    leasesList(sort: $sort, filter: $filter) {
      count
      items {
        id
        leasePeriod {
          name
          property {
            name
          }
        }
        userResident {
          id
          lastName
          firstName
          email
          phoneNumber
          avatar {
            downloadUrl
            id
          }
        }
        status
        property {
          location
        }
      }
    }
  }
`;
