import React from 'react';
import { Dialog } from '../../../shared/components/Dialog';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { ButtonDefault } from '../../../shared/components/ui/buttons/ButtonDefault';

type Props = {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onDelete: () => void;
};
export const ConfirmDeleteDocumentDialog: React.FC<Props> = ({
  open,
  onClose,
  loading,
  onDelete,
}) => (
  <Dialog
    isVisible={open}
    title="Remove Document"
    size="md"
    onClose={onClose}
    actions={
      <>
        <ButtonDefault onClick={onClose}>Cancel</ButtonDefault>
        <ButtonAction isLoading={loading} onClick={onDelete}>
          REMOVE
        </ButtonAction>
      </>
    }>
    Are you sure you want to remove this document? This action is irreversible.
  </Dialog>
);
