import gql from 'graphql-tag';

export const GET_LEASE_BY_USER_EMAIL_AND_PROPERTY = gql`
  query GET_LEASE_BY_USER_EMAIL_AND_PROPERTY($email: String, $propertyId: ID) {
    leasesList(
      filter: {
        userResident: { email: { equals: $email } }
        property: { id: { equals: $propertyId } }
      }
    ) {
      items {
        property {
          id
        }
      }
    }
  }
`;

export const GET_INVITATIONS_BY_USER_RESIDENT_AND_PROPERTY = gql`
  query GET_INVITATIONS_BY_USER_RESIDENT_AND_PROPERTY(
    $userId: ID
    $propertyId: ID
  ) {
    invitationResidentsList(
      filter: {
        inviter: { id: { equals: $userId } }
        property: { id: { equals: $propertyId } }
      }
    ) {
      items {
        id
        status
        name
        userResident {
          id
        }
        email
        unit {
          id
          code
        }
        room {
          id
          type
        }
        bed {
          id
          number
        }
      }
    }
  }
`;

export const SEND_LEAD_INVITATION = gql`
  mutation SEND_LEAD_INVITATION($data: SendLeadInvitationInput!) {
    sendLeadInvitation(data: $data) {
      success
    }
  }
`;

export const CREATE_INVITATION = gql`
  mutation CREATE_INVITATION($data: InvitationResidentCreateInput!) {
    invitationResidentCreate(data: $data) {
      id
      name
      text
      gender
      email
      unit {
        code
      }
      room {
        type
      }
      bed {
        number
      }
      lease {
        id
      }
    }
  }
`;

export const GET_RESIDENT_USERS = gql`
  query GET_RESIDENT_USERS($filter: String!) {
    usersList(
      filter: { AND: { _fullText: $filter, userRole: { equals: "RESIDENT" } } }
    ) {
      items {
        id
        firstName
        lastName
        email
        taxID
        gender
        birthdate
        phoneNumber
        homePhone
        studentIdOrEmployeeNumber
        city
        zipCode
        state
        address
        allowChecks
        allowCreditCards
      }
    }
  }
`;

export const GET_BUILDINGS_BY_PROPERTY = gql`
  query GET_BUILDINGS_BY_PROPERTY($propertyId: ID!) {
    buildingsList(filter: { property: { id: { equals: $propertyId } } }) {
      items {
        id
        name
        code
      }
    }
  }
`;

export const GET_FLOORS_BY_BUILDING = gql`
  query GET_FLOORS_BY_BUILDING($buildingId: ID!) {
    floorsList(filter: { building: { id: { equals: $buildingId } } }) {
      items {
        id
        number
      }
    }
  }
`;

export const GET_UNITS_BY_FLOOR = gql`
  query GET_UNITS_BY_FLOOR($floorId: ID!) {
    unitsList(filter: { floor: { id: { equals: $floorId } } }) {
      items {
        id
        gender
        code
        unitLeaseRelation {
          items {
            id
            unit {
              id
            }
            userResident {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_UNITS_BY_FLOOR_AND_GENDER = gql`
  query GET_UNITS_BY_FLOOR_AND_GENDER(
    $floorId: ID!
    $gender: String
    $leasePeriodId: ID!
  ) {
    unitsList(
      filter: {
        floor: { id: { equals: $floorId } }
        OR: [
          { gender: { equals: $gender } }
          { gender: { equals: "ANY" } }
          {
            gender: { equals: "FIRST_COME" }
            unitRoomRelation: {
              some: {
                roomBedRelation: {
                  some: {
                    bedLeaseRelation: {
                      some: {
                        userResident: { gender: { equals: $gender } }
                        leasePeriod: { id: { equals: $leasePeriodId } }
                      }
                    }
                  }
                }
              }
            }
          }
        ]
      }
    ) {
      items {
        id
        gender
        code
        unitLeaseRelation {
          items {
            id
            unit {
              id
            }
            userResident {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_OPPORTUNITIES_UNITS_BY_FILTER = gql`
  query GET_OPPORTUNITIES_UNITS_BY_FILTER($filter: UnitFilter) {
    unitsList(filter: $filter) {
      items {
        unitType {
          typeName
        }
        unitLeaseRelation {
          items {
            availability {
              leasePeriod {
                id
              }
              id
            }
          }
        }
        id
        code
        floor {
          id
          number
        }
        gender
        type
        unitRoomRelation {
          count
          items {
            id
            type
            roomBedRelation {
              count
              items {
                id
                price
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_ROOMS_BY_UNIT = gql`
  query GET_ROOMS_BY_UNIT($unitId: ID!) {
    roomsList(filter: { unit: { id: { equals: $unitId } } }) {
      items {
        id
        code
        type
      }
    }
  }
`;

export const GET_BEDS_BY_ROOM = gql`
  query GET_BEDS_BY_ROOM($roomId: ID!, $leaseId: ID, $leasePeriodId: ID) {
    bedsList(
      filter: {
        OR: [
          {
            bedLeaseRelation: {
              every: { leasePeriod: { id: { not_equals: $leasePeriodId } } }
            }
          }
          { bedLeaseRelation: { some: { id: { equals: $leaseId } } } }
        ]
        room: { id: { equals: $roomId } }
        bedInvitationRelation: { every: { id: null } }
        bedAvailabilityRelation: {
          some: {
            available: { equals: true }
            leasePeriod: { id: { equals: $leasePeriodId } }
          }
        }
      }
    ) {
      items {
        id
        room {
          unit {
            id
          }
        }
        number
      }
    }
  }
`;

export const GET_BEDS_AVAILABLE = gql`
  query GET_BEDS_AVAILABLE($leasePeriodId: ID, $propertyId: ID) {
    bedsList(
      filter: {
        AND: [
          {
            bedLeaseRelation: {
              every: { leasePeriod: { id: { not_equals: $leasePeriodId } } }
            }
          }
          {
            room: {
              unit: {
                floor: {
                  building: { property: { id: { equals: $propertyId } } }
                }
              }
            }
          }
        ]
      }
    ) {
      items {
        bedAvailabilityRelation {
          items {
            gender
            leasePeriod {
              id
            }
            availabilityPaymentPlanRelation {
              items {
                id
                type
              }
            }
          }
        }
        bedLeaseRelation {
          items {
            leasePeriod {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_PLANS_BY_BED = gql`
  query GET_PAYMENT_PLANS_BY_BED(
    $bedId: ID!
    $leasePeriodId: ID!
    $type: String!
  ) {
    availabilitiesList(
      filter: {
        bed: { id: { equals: $bedId } }
        leasePeriod: { id: { equals: $leasePeriodId } }
        available: { equals: true }
      }
    ) {
      items {
        id
        availabilityPaymentPlanRelation(
          filter: {
            type: { equals: $type }
            paymentPlan: {
              status: { equals: "APPROVED" }
              leasePeriod: { id: { equals: $leasePeriodId } }
            }
          }
        ) {
          items {
            paymentPlan {
              id
              guarantorRequired
              name
              status
              isActive
              paymentPlanPaymentPlanItemRelation {
                items {
                  type
                  id
                  amount
                  description
                  category
                  dueDate
                  leaseSubPeriod {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        leasePeriod {
          id
          name
          onlineType
          displayOnDashboard
          startDate
          endDate
          leasePeriodPeriodSubPeriodRelation {
            items {
              leaseSubPeriod {
                id
                name
                startDate
                endDate
              }
            }
          }
        }
        bed {
          id
        }
      }
    }
  }
`;

export const SEND_REMINDERS = gql`
  mutation SEND_REMINDERS($leases: [String]!) {
    sendReminderResolver(leases: $leases) {
      success
    }
  }
`;

export const GET_ALL_OPPORTUNITIES_NOTIFICATIONS = gql`
  query GET_ALL_OPPORTUNITIES_NOTIFICATIONS(
    $filter: OpportunityNotificationFilter!
  ) {
    opportunityNotificationsList(filter: $filter) {
      items {
        id
        progress
        waitDays
        active
        messageTemplate {
          name
          id
        }
        type
      }
    }
  }
`;

export const CREATE_OPPORTUNITY_NOTIFICATION = gql`
  mutation CREATE_OPPORTUNITY_NOTIFICATION(
    $data: OpportunityNotificationCreateInput!
  ) {
    opportunityNotificationCreate(data: $data) {
      id
    }
  }
`;

export const DELETE_OPPORTUNITY_NOTIFICATION = gql`
  mutation DELETE_OPPORTUNITY_NOTIFICATION($id: ID!) {
    opportunityNotificationDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const UPDATE_OPPORTUNITY_NOTIFICATION = gql`
  mutation UPDATE_OPPORTUNITY_NOTIFICATION(
    $data: OpportunityNotificationUpdateInput!
    $id: ID!
  ) {
    opportunityNotificationUpdate(data: $data, filter: { id: $id }) {
      id
      progress
      active
      waitDays
      messageTemplate {
        name
        id
      }
      type
    }
  }
`;

export const GET_MESSAGE_TEMPLATES = gql`
  query GET_MESSAGE_TEMPLATES($filter: MessageTemplateFilter!) {
    messageTemplatesList(filter: $filter) {
      items {
        id
        type
        name
      }
    }
  }
`;

export const GET_LEASES_BY_PROPERTIES = gql`
  query GET_LEASES_BY_PROPERTIES($filter: LeaseFilter!) {
    leasesList(filter: $filter) {
      items {
        id
        status
        startDate
        endDate
        moveInDate
        moveOutDate
        room {
          number
        }
        floor {
          number
        }
        bed {
          number
          code
          gender
          id
        }
        unit {
          code
          gender
        }
        isApproved
        property {
          id
          name
          buildings
          client {
            id
            clientFee {
              reservationFee
              settings {
                passReservationFeeToTenant
              }
            }
          }
        }
        userResident {
          id
          firstName
          email
          gender
          genderIdentify
          lastName
          messageThreadTo(last: 1) {
            items {
              updatedAt
            }
          }
          messageThreadFrom(last: 1) {
            items {
              updatedAt
            }
          }
          avatar {
            downloadUrl
          }
        }
        agent {
          id
          role
          user {
            id
            email
            firstName
            lastName
            avatar {
              fileId
              downloadUrl
            }
          }
        }
        updatedAt
      }
    }
  }
`;

export const GET_LEASES_CATEGORIES_METRICS = gql`
  query GET_LEASES_CATEGORIES_METRICS(
    $totalFilter: LeaseFilter!
    $openInvitationFilter: LeaseFilter!
    $inProcessFilter: LeaseFilter!
    $managerReadyFilter: LeaseFilter!
    $newFilter: LeaseFilter!
    $bedsCount: PropertyFilter!
    $blockedFilter: LeaseFilter!
    $approvedFilter: LeaseFilter!
    $allFilter: LeaseFilter!
  ) {
    all: leasesList(filter: $allFilter) {
      count
    }
    total: leasesList(filter: $totalFilter) {
      count
    }

    open_invitation: leasesList(filter: $openInvitationFilter) {
      count
    }
    in_process: leasesList(filter: $inProcessFilter) {
      count
    }

    manager_ready: leasesList(filter: $managerReadyFilter) {
      count
    }

    blocked: leasesList(filter: $blockedFilter) {
      count
    }

    beds: propertiesList(filter: $bedsCount) {
      items {
        propertyLeasePeriodRelation {
          count
        }
        buildingLayout {
          building {
            items {
              buildingFloorRelation {
                items {
                  floorUnitRelation {
                    items {
                      unitRoomRelation {
                        items {
                          roomBedRelation {
                            count
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    new: leasesList(filter: $newFilter) {
      count
    }

    approved: leasesList(filter: $approvedFilter) {
      count
    }
  }
`;

export const GET_LEASE_DATA = gql`
  query GET_LEASE_DATA($id: ID!) {
    lease(id: $id) {
      reservationPaid
      paymentType
      leaseOptionalPreferenceRelation {
        count
        items {
          optionalPreference {
            name
            description
            total
            type
            status
          }
        }
      }
      paymentPlan {
        name
        paymentPlanPaymentPlanItemRelation {
          items {
            type
            category
            description
            dueDate
            amount
            dueOn
          }
        }
      }
      status
      leasePeriod {
        id
        name
        startDate
        endDate
        residentPropDocumentLeasePeriodRelation(
          filter: { status: { equals: "ACTIVE" } }
        ) {
          items {
            signers
            type
          }
        }
        guarantorPropDocumentLeasePeriodRelation(
          filter: { status: { equals: "ACTIVE" } }
        ) {
          items {
            signers
            type
          }
        }
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
              startDate
              endDate
            }
          }
        }
      }
      leaseContractDocumentRelation {
        items {
          downloadURL
        }
      }
      endDate
      startDate
      bed {
        number
        room {
          unit {
            code
            floor {
              building {
                code
              }
            }
          }
        }
      }
      userResident {
        id
        firstName
        lastName
        email
        taxID
        gender
        birthdate
        phoneNumber
        homePhone
        studentIdOrEmployeeNumber
        city
        zipCode
        state
        address
        userStripeCustomerUserRelation {
          items {
            stripeCustomerId
            property {
              id
            }
          }
        }
        userUserDocumentRelation {
          items {
            type
            document {
              downloadUrl
            }
          }
        }
        avatar {
          downloadUrl
        }
        gender
        userTenantProfileRelation {
          address2
          proofIdentify {
            downloadUrl
          }
          city
          zipCode
        }
      }
      property {
        name
        stripeAccountId
        stripeCustomerId
        siteSetting {
          enablePaymentsOnTenantProfile
        }
        client {
          id
          clientFee {
            reservationFee
            settings {
              passReservationFeeToTenant
            }
          }
        }
        id
        primaryPropertyImage {
          downloadUrl
        }
        createdBy {
          email
          firstName
        }
        client {
          id
        }
        propertyDocumentTemplatePropertyRelation {
          items {
            id
            name
            helloSignTemplateId
            document {
              id
              fileId
              downloadUrl
              shareUrl
              provider
              filename
              shareUrl
              fields
              previewUrl
              contractDocument_file {
                items {
                  id
                  file {
                    downloadUrl
                    filename
                  }
                }
              }
              leaseDocument_document {
                items {
                  document {
                    filename
                    downloadUrl
                  }
                }
              }
            }
            signers
            status
          }
        }
        propertyImagePropertyRelation {
          items {
            id
            file {
              downloadUrl
              fileId
            }
            caption
            type
          }
        }
      }
      agent {
        id
        role
        user {
          id
          email
          firstName
          lastName
          avatar {
            fileId
            downloadUrl
          }
        }
      }
    }
  }
`;

export const GET_PAYMENT_PLAN_ITEMS_BY_LEASE = gql`
  query GET_PAYMENT_PLAN_ITEMS_BY_LEASE($id: ID!) {
    paymentPlanItemsList(
      filter: {
        paymentPlan: {
          paymentPlanLeaseRelation: { some: { id: { equals: $id } } }
        }
      }
    ) {
      count
      items {
        id
        amount
        dueOn
        type
      }
    }
  }
`;

export const GET_LEASES_DATA_FOR_MESSAGE = gql`
  query GET_LEASES_DATA_FOR_MESSAGE($ids: [ID!]) {
    leasesList(filter: { id: { in: $ids } }) {
      items {
        id
        moveInDate
        moveOutDate
        leaseLedgerRelation {
          items {
            amount
          }
        }
        status
        bed {
          number
        }
        property {
          name
        }
        userResident {
          guarantorData {
            id
            firstName
            lastName
            email
          }
          id
          phoneNumber
          email
          firstName
          lastName
          userTenantProfileRelation {
            email
            sms
          }
        }
      }
    }
  }
`;

export const FETCH_AVAILABILITIES_BY_UNIT = gql`
  query FETCH_AVAILABILITIES_BY_UNIT($filter: AvailabilityFilter!) {
    availabilitiesList(filter: $filter) {
      items {
        id
        gender
        bed {
          room {
            unit {
              id
              gender
            }
          }
        }
      }
    }
  }
`;

export const GET_LEASES_BY_RESIDENT = gql`
  query GET_LEASES_BY_RESIDENT($userId: ID!) {
    leasesList(filter: { userResident: { id: { equals: $userId } } }) {
      items {
        reservationPaid
        leasePeriod {
          id
          displayOnDashboard
          endDate
          onlineType
          leasePeriodRenewals {
            leasePeriods {
              items {
                id
                name
              }
            }
          }
        }
        id
        leaseMoveOutRequestRelation {
          id
          date
          reason
          depositRefund
          overallExperienceRating
          overallExperienceText
        }
        status
        startDate
        endDate
        isApproved
        availability {
          leasePeriod {
            name
          }
        }
        unit {
          code
        }
        room {
          type
          roomType {
            typeName
          }
        }
        bed {
          id
          number
        }
        leaseLedgerRelation {
          items {
            type
            amount
          }
        }
        paymentPlan {
          paymentPlanPaymentPlanItemRelation {
            items {
              amount
              type
            }
          }
        }
        property {
          id
          name
          city
          propertyPaymentPlanRelation(
            sort: { total: ASC }
            first: 1
            filter: { status: { equals: "APPROVED" } }
          ) {
            items {
              total
            }
          }
          siteSetting {
            enablePaymentsOnTenantProfile
            requiresRoommateMatchingQuiz
          }
          state
          clientUser {
            items {
              role
              user {
                id
              }
            }
          }
          primaryPropertyImage {
            downloadUrl
          }
          images {
            items {
              downloadUrl
            }
          }
          propertyImagePropertyRelation {
            items {
              id
              file {
                downloadUrl
                fileId
              }
              caption
              type
            }
          }
          location
        }
        agent {
          id
          role
          user {
            id
            email
            firstName
            lastName
            avatar {
              fileId
              downloadUrl
            }
          }
        }
        userResident {
          id
          address
          firstName
          lastName
          avatar {
            previewUrl
          }
        }
        updatedAt
      }
    }
  }
`;

export const GET_LEASE_BY_ID = gql`
  query GET_LEASE_BY_ID($leaseId: ID!) {
    lease(id: $leaseId) {
      id
      reservationPaid
      isApproved
      paymentType
      createdAt
      endDate
      contractTemplateID
      signOnPlace
      startDate
      moveInDate
      moveOutDate
      isRenewal
      status
      bed {
        id
      }
      leaseOptionalPreferenceRelation {
        items {
          ledger {
            amount
          }
          inventory {
            id
            name
          }
        }
      }
      opcionalPreferenceLeaseRelation {
        items {
          id
          name
          title
          description
          status
          isActive
          leasePeriod {
            id
            name
          }
          total
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
        }
      }
      leaseOptionalPreferenceRelation {
        items {
          optionalPreference {
            id
            name
            title
            status
            total
            type
          }
        }
      }
      userResident {
        id
        firstName
        middleName
        lastName
        preferredName
        email
        taxID
        gender
        genderIdentify
        birthdate
        phoneNumber
        homePhone
        studentIdOrEmployeeNumber
        city
        zipCode
        state
        address
        aboutMe
        allowCreditCards
        allowChecks
      }
      guarantor {
        id
        status
        firstName
        lastName
        middleName
        birthdate
        email
        phoneNumber
        personalGuarantee
        currentAdressCountry
        currentAdressLine1
        currentAdressLine2
        currentAdresssCity
        currentAdressState
        currentAdressZipCode
        currentAdressHowLong
        currentAdressRentOrOwn
        ssn
        ssnNumber
        otherCountryId
        country
        documentType
        govIssuingId
        govIdNumber
        howMakeAnnuallyAfterTax
        primaryIncomeSource
        guarantorJobs
        termsAck
        checkConsent
        acknowledgement
        applicationAck
        doYouHaveACurrentJob
      }
      property {
        id
        stripeAccountId
        name
        siteSetting {
          conditionallyRequireGuarantor
          enablePaymentsOnReservationCheckout
          enableOnlineLeasingCreditCardPayment
          enablePaymentsOnTenantProfile
          enableOnlineLeasingACHPayment
          requiresRoommateMatchingQuiz
        }
        createdBy {
          firstName
          lastName
          email
          phoneNumber
        }
        images {
          items {
            downloadUrl
            fileId
          }
        }
        primaryPropertyImage {
          downloadUrl
        }
        propertyImagePropertyRelation {
          items {
            id
            file {
              downloadUrl
              fileId
            }
            caption
            type
          }
        }
        client {
          id
          clientFee {
            reservationFee
            settings {
              passReservationFeeToTenant
            }
          }
        }
      }
      agent {
        id
        role
        user {
          id
          email
          firstName
          lastName
          avatar {
            fileId
            downloadUrl
          }
        }
      }
      leasePeriod {
        id
        name
        startDate
        endDate
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
            }
          }
        }
        propertyFormLeasePeriodRelation {
          items {
            id
            name
            type
            description
            data
            personalInformation
            leasingAgent
            howDidYouHearAboutUs
            roommateMatchingPreferences
            standardSectionsIncludes
            customSections
            property {
              id
            }
          }
        }
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
              startDate
              endDate
            }
          }
        }
      }
      floor {
        id
        number
        building {
          id
          name
          code
        }
      }
      unit {
        id
        code
        type
        gender
        unitLeaseRelation {
          items {
            id
            unit {
              code
            }
            bed {
              id
              number
            }
            userResident {
              id
              avatar {
                downloadUrl
              }
              firstName
              lastName
              phoneNumber
              email
              gender
            }
            startDate
            endDate
          }
        }
      }
      room {
        id
        code
        type
      }
      bed {
        id
        code
        number
      }
      leaseContractDocumentRelation {
        items {
          id
          signer
        }
      }
      paymentPlan {
        id
        name
        guarantorRequired
        paymentPlanPaymentPlanItemRelation {
          items {
            id
            dueDate
            dueOn
            type
            category
            description
            amount
            leaseSubPeriod {
              id
              name
            }
          }
        }
      }
      residentApplication {
        areYouCurrentlyA
        byuIEmail
        carLicensePlate
        carMake
        carModel
        colorOfCar
        comments
        driverLicense
        hobbies
        iDNumber
        id
        notes
        selfDescription
        leasingAgent
        howDidYouHearAboutUs
        roommateMatchingPreferences
        extraData
      }
    }
  }
`;

export const CREATE_LEASE_MUTATION = gql`
  mutation CREATE_LEASE_MUTATION(
    $startDate: Date!
    $endDate: Date!
    $userId: ID!
    $availabilityId: ID!
    $propertyId: ID!
    $floorId: ID!
    $unitId: ID!
    $roomId: ID
    $bedId: ID
    $status: String
    $leasePeriodId: ID!
    $isRenewal: Boolean
  ) {
    leaseCreate(
      data: {
        isRenewal: $isRenewal
        startDate: $startDate
        endDate: $endDate
        isApproved: true
        status: $status
        userResident: { connect: { id: $userId } }
        availability: { connect: { id: $availabilityId } }
        property: { connect: { id: $propertyId } }
        floor: { connect: { id: $floorId } }
        unit: { connect: { id: $unitId } }
        room: { connect: { id: $roomId } }
        bed: { connect: { id: $bedId } }
        leasePeriod: { connect: { id: $leasePeriodId } }
      }
    ) {
      id
    }
  }
`;

export const CREATE_LEASE_OPPORTUNITIES = gql`
  mutation CREATE_LEASE_OPPORTUNITIES_MUTATION($data: LeaseCreateInput!) {
    leaseCreate(data: $data) {
      id
    }
  }
`;

export const INVITE_RESIDENT = gql`
  mutation INVITE_RESIDENT($data: InvitationResidentCreateInput!) {
    invitationResidentCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_LEASE_OPPORTUNITIES_MUTATION = gql`
  mutation UPDATE_LEASE_OPPORTUNITIES_MUTATION($data: LeaseUpdateInput!) {
    leaseUpdate(data: $data, force: true) {
      status
      startDate
      endDate
      moveInDate
      moveOutDate
      id
      reservationPaid
      createdAt
      opcionalPreferenceLeaseRelation {
        items {
          name
          id
          title
          isActive
        }
      }
      leasePeriod {
        id
        startDate
        endDate
        name
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
            }
          }
        }
      }
      residentApplication {
        id
        convictedCrime
        attendingCollege
        schoolStartDate
        smoke
        bringingVehicle
        shareRide
        leasingAgent
        howDidYouHearAboutUs
        roommateMatchingPreferences
      }
      publicRoomateProfile {
        displayName
        school
        age
        smoking
        hometown
        allergies
        aboutYourself
        id
      }
      unit {
        id
        code
      }
      property {
        name
        id
        state
        stateCode
        street
        city
        images {
          items {
            downloadUrl
          }
        }
        primaryPropertyImage {
          downloadUrl
        }
        propertyImagePropertyRelation {
          items {
            id
            file {
              downloadUrl
              fileId
            }
            caption
            type
          }
        }
      }
      agent {
        id
        role
        user {
          id
          email
          firstName
          lastName
          avatar {
            fileId
            downloadUrl
          }
        }
      }
      guarantor {
        firstName
        lastName
        email
        birthdate
        phoneNumber
        homePhoneNumber
        studentId
        status
        address1
        city
        state
        zipcode
        displayName
        year
        schoolMajor
        aboutYourself
      }
    }
  }
`;

export const COPY_PAYMENT_PLAN = gql`
  mutation COPY_PAYMENT_PLAN($id: ID!) {
    copyPaymentPlanResolver(id: $id) {
      success
    }
  }
`;

export const UPDATE_MANY_LEASE_OPPORTUNITIES_MUTATION = gql`
  mutation UPDATE_MANY_LEASE_OPPORTUNITIES_MUTATION(
    $data: LeaseUpdateByFilterInput!
    $filter: LeaseFilter
  ) {
    leaseUpdateByFilter(data: $data, filter: $filter) {
      items {
        status
        startDate
        endDate
        id
        createdAt
        userResident {
          id
          firstName
          middleName
          lastName
          preferredName
          email
          taxID
          gender
          genderIdentify
          birthdate
          phoneNumber
          homePhone
          studentIdOrEmployeeNumber
          city
          zipCode
          state
          address
          aboutMe
          allowCreditCards
          allowChecks
        }
        opcionalPreferenceLeaseRelation {
          items {
            name
            id
            status
            isActive
          }
        }
        leasePeriod {
          id
          startDate
          endDate
          name
          leasePeriodPeriodSubPeriodRelation {
            items {
              leaseSubPeriod {
                id
                name
              }
            }
          }
        }
        residentApplication {
          id
          convictedCrime
          attendingCollege
          schoolStartDate
          smoke
          bringingVehicle
          shareRide
        }
        publicRoomateProfile {
          displayName
          school
          age
          smoking
          hometown
          allergies
          aboutYourself
          id
        }
        unit {
          id
          code
        }
        property {
          name
          id
          state
          stateCode
          street
          city
          images {
            items {
              downloadUrl
            }
          }
          primaryPropertyImage {
            downloadUrl
          }
          propertyImagePropertyRelation {
            items {
              id
              file {
                downloadUrl
                fileId
              }
              caption
              type
            }
          }
        }
        agent {
          id
          role
          user {
            id
            email
            firstName
            lastName
            avatar {
              fileId
              downloadUrl
            }
          }
        }
        guarantor {
          firstName
          lastName
          email
          birthdate
          phoneNumber
          homePhoneNumber
          studentId
          status
          address1
          city
          state
          zipcode
          displayName
          year
          schoolMajor
          aboutYourself
        }
      }
    }
  }
`;

export const FETCH_AVAILABILITY = gql`
  query FETCH_AVAILABILITY($filter: AvailabilityFilter!) {
    availabilitiesList(filter: $filter) {
      items {
        id
      }
    }
  }
`;

export const CREATE_DRAFT_TEMPLATE_MUTATION = gql`
  mutation CREATE_DRAFT_TEMPLATE_MUTATION($data: CreateDraftInput!) {
    createDraftTemplate(data: $data) {
      success
      message
      template {
        template_id
        edit_url
        expires_at
      }
    }
  }
`;

export const CREATE_USER_RESIDENT_MUTATION = gql`
  mutation CREATE_USER_RESIDENT_MUTATION($data: UserCreateInput!) {
    userCreate(data: $data) {
      id
      firstName
      lastName
      email
      gender
      phoneNumber
      homePhone
    }
  }
`;

export const GET_OPPORTUNITIES_LEASE_PERIOD = gql`
  query GET_OPPORTUNITIES_LEASE_PERIOD($propertyId: ID!) {
    leasePeriodsList(
      filter: {
        property: { id: { equals: $propertyId } }
        onlineType: { not_equals: "OFF" }
      }
    ) {
      items {
        id
        name
        minimumPrice
        maximumPrice
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
            }
          }
        }
        leasePeriodLeaseRelation {
          items {
            id
            status
            userResident {
              id
            }
          }
        }
        leasePeriodPaymentPlanRelation(first: 1, sort: { status: ASC }) {
          items {
            total
            id
          }
        }
        startDate
        startTime
        endTime
        endDate
        displayOnDashboard
        onlineType
        leasePeriodAvailabilityRelation {
          count
        }
        property {
          id
          client {
            id
          }
        }
      }
    }
  }
`;

export const GET_LEASE_PERIODS_BY_PROPERTIES = gql`
  query GET_LEASE_PERIODS_BY_PROPERTIES($filter: LeasePeriodFilter!) {
    leasePeriodsList(filter: $filter) {
      items {
        id
        name
        minimumPrice
        maximumPrice
        leasePeriodLeaseRelation {
          items {
            id
          }
        }
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
            }
          }
        }
        leasePeriodPaymentPlanRelation(first: 1, sort: { status: ASC }) {
          items {
            total
            id
          }
        }
        startDate
        startTime
        endTime
        endDate
        displayOnDashboard
        onlineType
        leasePeriodAvailabilityRelation {
          count
          items {
            id
            bed {
              id
            }
          }
        }
        room {
          roomBedRelation {
            items {
              id
            }
          }
        }
        property {
          id
          client {
            id
          }
        }
      }
    }
  }
`;
export const GET_LEASE_PERIODS_RANGE_DATES_BY_PROPERTIES = gql`
  query GET_LEASE_PERIODS_RANGE_DATES_BY_PROPERTIES(
    $filter: LeasePeriodFilter!
  ) {
    leasePeriodsList(filter: $filter) {
      items {
        startDate
        startTime
        endTime
        endDate
        name
        id
      }
    }
  }
`;

export const CONTRACT_DOCUMENT_QUERY = gql`
  query CONTRACT_DOCUMENT_QUERY($filter: ContractDocumentFilter) {
    contractDocumentsList(filter: $filter) {
      items {
        id
        signer
        downloadURL
        file {
          id
          fileId
          filename
        }
      }
    }
  }
`;

export const TERMS_DOCUMENT_QUERY = gql`
  query TERMS_DOCUMENT_QUERY($filter: TermsDocumentFilter) {
    termsDocumentsList(filter: $filter) {
      items {
        id
        signer
        downloadURL
        file {
          id
          fileId
          filename
        }
      }
    }
  }
`;

export const GET_MANAGERS = gql`
  query GET_MANAGERS($filter: ClientUserFilter) {
    clientUsersList(filter: $filter) {
      items {
        id
        active
        user {
          id
          firstName
          lastName
          avatar {
            downloadUrl
          }
        }
        __typename
      }
      __typename
    }
  }
`;

export const REQUIRED_DOCUMENTS_QUERY = gql`
  query REQUIRED_DOCUMENTS_QUERY($propertyId: ID!) {
    documentTypesList(
      filter: {
        documentTypeRequiredUploadRelation: {
          some: {
            property: { id: { equals: $propertyId } }
            requiredFileRequiredUploadRelation: {
              every: {
                requiredFileRequiredUploadRelation: {
                  OR: [
                    { isIncluded: { equals: true } }
                    { isRequired: { equals: true } }
                  ]
                }
              }
            }
          }
        }
      }
    ) {
      count
      items {
        name
        id
        isTenantDocument
        isGuarantorDocument
        requiredFileDocumentTypeRelation {
          items {
            requiredFileRequiredUploadRelation {
              documentType {
                id
              }
            }
          }
        }
        documentTypeRequiredUploadRelation(
          filter: { property: { id: { equals: $propertyId } } }
        ) {
          count
          items {
            id
            isTenant
            isGuarantor
            property {
              id
            }
            id
            documentType {
              id
            }
            isIncluded
            isRequired
          }
        }
      }
    }
  }
`;

export const CREATE_REQUIRED_FILE = gql`
  mutation CREATE_REQUIRED_FILE($data: RequiredFileCreateInput!) {
    requiredFileCreate(data: $data) {
      id
    }
  }
`;
export const DELETE_REQUIRED_FILE = gql`
  mutation DELETE_REQUIRED_FILE($data: RequiredFileDeleteInput!) {
    requiredFileDelete(data: $data) {
      success
    }
  }
`;

/* export const UPDATE_REQUIRED_FILE = gql`
  mutation UPDATE_REQUIRED_FILE($data: RequiredFileUpdateInput!) {
    requiredFileUpdate(data: $data) {
      id
    }
  }
`; */

export const GET_USER_REQUIRED_FILES = gql`
  query GET_USER_REQUIRED_FILES($idPredicate: IDPredicate) {
    requiredFilesList(
      filter: { requiredFileUserRelation: { id: $idPredicate } }
    ) {
      items {
        id
        type
        userUploadDate
        updatedAt
        requiredFileDocumentTypeRelation {
          id
          name
          documentTypeRequiredUploadRelation {
            items {
              id
              isIncluded
              isRequired
              isGuarantor
              isTenant
            }
          }
        }
        requiredFileRequiredUploadRelation {
          id
          isTenant
          isGuarantor
          isRequired
        }
        file {
          fileId
          filename
          downloadUrl
        }
      }
    }
  }
`;
export const UPDATE_REQUIRED_FILE = gql`
  mutation UPDATE_REQUIRED_FILE($data: RequiredFileUpdateInput!) {
    requiredFileUpdate(data: $data) {
      file {
        downloadUrl
        fileId
        filename
      }
    }
  }
`;

export const FETCH_PROPERTY_REQUIRED_UPLOAD = gql`
  query FETCH_PROPERTY_REQUIRED_UPLOAD($propertyId: ID!) {
    documentTypesList {
      count
      items {
        name
        id
        isTenantDocument
        isGuarantorDocument
        documentTypeRequiredUploadRelation(
          filter: { property: { id: { equals: $propertyId } } }
        ) {
          count
          items {
            id
            isTenant
            isGuarantor
            property {
              id
            }
            id
            documentType {
              id
            }
            isIncluded
            isRequired
          }
        }
      }
    }
  }
`;

export const CHARGE_APPROVAL = gql`
  mutation CHARGE_APPROVAL(
    $leaseId: String
    $amount: Int
    $payer: String
    $clientId: String
    $stripeCustomerId: String
    $stripeAccountId: String
    $ledgerSubCategory: String
    $ledgerDescription: String
    $paymentMethodId: String
  ) {
    chargeApproval(
      amount: $amount
      stripeCustomerId: $stripeCustomerId
      stripeAccountId: $stripeAccountId
      paymentMethodId: $paymentMethodId
      leaseId: $leaseId
      ledgerSubCategory: $ledgerSubCategory
      ledgerDescription: $ledgerDescription
      clientId: $clientId
      payer: $payer
    ) {
      chargeApproval
    }
  }
`;

export const GET_LEASES = gql`
  query GET_LEASES($filter: LeaseFilter!, $skip: Int) {
    leasesList(filter: $filter, first: 100, skip: $skip) {
      items {
        id
        status
        startDate
        endDate
        moveInDate
        moveOutDate
        reservationPaid
        room {
          number
          code
        }
        floor {
          number
          building {
            code
          }
        }
        bed {
          number
          code
          gender
          id
        }
        unit {
          id
          code
          gender
          unitRoomRelation {
            items {
              roomBedRelation {
                items {
                  id
                }
              }
            }
          }
        }
        isApproved
        updatedAt
      }
    }
  }
`;

export const GET_LEASES_PROPERTY = gql`
  query GET_LEASES_PROPERTY($filter: LeaseFilter!, $skip: Int) {
    leasesList(filter: $filter, first: 100, skip: $skip) {
      items {
        id
        property {
          propertyBuildingRelation {
            items {
              id
              name
              code
              buildingFloorRelation {
                items {
                  number
                  id
                  floorUnitRelation {
                    items {
                      code
                      gender
                      id
                      unitRoomRelation {
                        items {
                          id
                          number
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          id
          name
          buildings
          client {
            id
            clientFee {
              reservationFee
              settings {
                passReservationFeeToTenant
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEASES_RESIDENT = gql`
  query GET_LEASES_RESIDENT($filter: LeaseFilter!, $skip: Int) {
    leasesList(filter: $filter, first: 100, skip: $skip) {
      items {
        id
        userResident {
          id
          firstName
          email
          gender
          genderIdentify
          lastName
          messageThreadTo(last: 1) {
            items {
              updatedAt
            }
          }
          messageThreadFrom(last: 1) {
            items {
              updatedAt
            }
          }
          avatar {
            downloadUrl
          }
        }
      }
    }
  }
`;

export const GET_LEASES_AGENT = gql`
  query GET_LEASES_AGENT($filter: LeaseFilter!, $skip: Int) {
    leasesList(filter: $filter, first: 100, skip: $skip) {
      items {
        id
        agent {
          id
          role
          user {
            id
            email
            firstName
            lastName
            avatar {
              fileId
              downloadUrl
            }
          }
        }
      }
    }
  }
`;

export const FETCH_RESIDENT_FEE_STATUS = gql`
  query FETCH_RESIDENT_FEE_STATUS($id: ID!) {
    lease(id: $id) {
      reservationFeePaid
    }
  }
`;
