import { useContext, useEffect } from 'react';
import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';
import {
  createStyles,
  makeStyles,
  Box,
  Divider,
  Grid,
  Theme,
  Typography,
  Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSession } from '../../session/session-hooks';
import { MyInfo } from './MyInfo';
import {
  updateTenantProfileAction,
  createTenantProfiAction,
} from './my-info-actions';
import {
  UpdateUser,
  UpdateTenantProfile,
  UpdateTenantProfileError,
} from './my-info-events';
import { GreenCheckbox } from '../../../shared/components/ui/inputs/GreenCheckbox';
import { AlertContext } from '../../../routes/AlertContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    verificationContainer: {
      border: 'solid 1px  #D2D2D2',
      borderRadius: 5,
      padding: `${theme.spacing(2, 2.5, 1.5, 2.5)}!important`,
      margin: '12px',
      '& .MuiTypography-root': {
        fontWeight: 'normal',
      },
    },
  }),
);

export const Notifications: React.FC = () => {
  const classes = useStyles();
  const alertContext = useContext(AlertContext);
  const [session, , , , refetch] = useSession();

  const [updateTenantProfile] = useCallAction(updateTenantProfileAction);
  const [createTenantProfile] = useCallAction(createTenantProfiAction);

  const formik = useFormik({
    initialValues: {
      sms: false,
      email: false,
    },
    onSubmit() {
      const copy = {
        ...formik.values,
      };

      if (session?.user.userTenantProfileRelation) {
        updateTenantProfile({
          ...copy,
          id: session?.user.userTenantProfileRelation.id,
        });
      } else {
        createTenantProfile({
          ...copy,
          user: {
            connect: {
              id: session?.user.id,
            },
          },
        });
      }
    },
  });

  useSubscription(UpdateUser, () => {
    refetch();
  });
  useSubscription(UpdateTenantProfile, () => {
    refetch();
    alertContext({
      success: true,
      message: 'Notifications Preferences successfully updated',
    });
    formik.setSubmitting(false);
  });

  useSubscription(UpdateTenantProfileError, () => {
    alertContext({
      error: true,
      message: 'Something went wrong while updating',
    });
  });

  useEffect(() => {
    formik.setValues({
      sms: session?.user.userTenantProfileRelation?.sms ?? false,
      email: session?.user.userTenantProfileRelation?.email ?? false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user]);

  const smsField = formik.getFieldProps('sms');
  const emailField = formik.getFieldProps('email');

  return (
    <MyInfo session={session} loading={false}>
      <Grid container justifyContent="space-around" alignItems="baseline">
        <Grid item xs={11}>
          <Typography variant="h5" className={classes.title}>
            Notifications Preferences
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box padding={4}>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="baseline"
                spacing={3}>
                <Grid item xs={9} md={11}>
                  <Typography variant="h6">Accept SMS</Typography>
                  <Box>
                    Receive notifications and messages to your phone number
                  </Box>
                </Grid>
                <Grid item xs={3} md={1}>
                  <GreenCheckbox
                    disabled={formik.isSubmitting}
                    checked={smsField.value}
                    name=""
                    handleChange={() =>
                      formik.setValues({
                        sms: !smsField.value,
                        email: emailField.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={9} md={11}>
                  <Typography variant="h6">Accept Emails</Typography>
                  <Box>Receive notifications and messages to your email</Box>
                </Grid>
                <Grid item xs={3} md={1}>
                  <GreenCheckbox
                    disabled={formik.isSubmitting}
                    checked={emailField.value}
                    name=""
                    handleChange={() =>
                      formik.setValues({
                        sms: smsField.value,
                        email: !emailField.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    {/* <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          formik.setValues({
                            sms:
                              session?.user.userTenantProfileRelation?.sms ??
                              false,
                            email:
                              session?.user.userTenantProfileRelation?.email ??
                              false,
                          })
                        }
                        fullWidth>
                        Cancel
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button
                        disabled={formik.isSubmitting || !formik.dirty}
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </MyInfo>
  );
};
