import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { ClientUserType } from '@8baselabs/resident-io-shared';
import {
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import { OnFetchSession } from '../../modules/session/session-events';
import { getProperties } from '../../modules/settings-view/settings-actions';
import { Checkbox } from './ui/inputs/Checkbox';
import { OnPrevSelectClient, OnSelectClient } from './components-events';
import { MainLoader } from './MainLoader';
import { useLocalStorage } from './components-hooks';
import { dashboardSelectedProperties } from '../../modules/dashboard/dashboard-events';
import { useLocation } from 'react-router';
import { OnSelectProperties } from '../../modules/settings-view/settings-events';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      background: '#fff',
      padding: theme.spacing(3),
      margin: theme.spacing(-3, -3, -3, -3),
    },
  }),
);

type Props = {
  selectedProperties: string[];
  setSelectedProperties: (val: string[]) => void;
};

export const PropertiesCardMultiSelector: React.FC<Props> = ({
  selectedProperties,
  setSelectedProperties,
}) => {
  const classes = useStyles();
  const [onLoading, setOnLoading] = useState(false);
  const { pathname } = useLocation();
  const session = useEvent(OnFetchSession);
  const [propertiesList, loading] = useFetchAction(getProperties, [
    session?.user?.selectedClient?.id ||
      session?.user?.userClientUserRelation?.items[0]?.client?.id ||
      '',
    session?.user.userClientUserRelation?.items[0]?.id || '',
    (session?.user.userClientUserRelation?.items[0]?.role ||
      '') as ClientUserType,
  ]);

  const [allSelected, setAllSelected] = useState(
    selectedProperties.length === propertiesList?.items.length,
  );

  useSubscription(OnPrevSelectClient, () => setOnLoading(true));

  useSubscription(OnSelectClient, () => {
    setSelectedProperties([]);
  });

  const [, setPropertySelectedId] = useLocalStorage('selected-property-id', '');

  useEffect(() => {
    const propertiesMapped = propertiesList?.items.filter((prop) =>
      selectedProperties.includes(prop?.id as string),
    );
    setPropertySelectedId(selectedProperties?.[0] || '');
    OnSelectProperties.dispatch(propertiesMapped || []);
  }, [selectedProperties, setPropertySelectedId, propertiesList?.items]);

  const handleChangeProperties = (propertyId: string): void => {
    const auxSelectedProperties = [...selectedProperties];
    const index = auxSelectedProperties.findIndex((p) => p === propertyId);
    if (index < 0) {
      auxSelectedProperties.push(propertyId);
    } else {
      auxSelectedProperties.splice(index, 1);
    }
    if (auxSelectedProperties.length !== propertiesList?.items.length) {
      setAllSelected(false);
    }
    setSelectedProperties(auxSelectedProperties);
  };

  const handleChangeAllSelected = (): void => {
    setAllSelected(true);
    const allProperties =
      propertiesList?.items?.map((property) => property?.id ?? '') ?? [];
    setSelectedProperties(allProperties || []);
  };

  useEffect(() => {
    setOnLoading(loading);
  }, [loading]);

  useEffect(() => {
    const dashboardProperties = dashboardSelectedProperties.get()?.properties;
    if (
      dashboardProperties?.length &&
      dashboardSelectedProperties.get()?.autocomplete &&
      pathname.split('/')[1] !== 'dashboard'
    ) {
      setSelectedProperties(dashboardProperties);
      dashboardSelectedProperties.dispatch({
        properties: dashboardProperties,
        autocomplete: false,
      });
    }
  }, [pathname, setSelectedProperties]);

  if (onLoading) return <MainLoader />;

  return (
    <Toolbar className={classes.toolbar}>
      <Grid container alignItems="center">
        <Grid item xs={1} style={{ marginRight: '25px', maxWidth: '100%' }}>
          <Typography variant="h6">Properties</Typography>
        </Grid>
        <Checkbox
          name={'All'}
          label={'All'}
          checked={allSelected}
          handleChange={() => handleChangeAllSelected()}
        />
        {propertiesList?.items.map((property) => (
          <Grid item key={property.id || ''}>
            <Checkbox
              name={property.id || ''}
              label={property.name || ''}
              checked={!!selectedProperties.find((p) => p === property.id)}
              handleChange={() => handleChangeProperties(property.id ?? '')}
            />
          </Grid>
        ))}
        {!loading && (!propertiesList || propertiesList.items.length < 1) ? (
          <Grid item xs={12}>
            <Alert severity="error">You&apos;ve no properties created.</Alert>
          </Grid>
        ) : null}
        {!selectedProperties.length ? (
          <Grid item xs={12}>
            <Alert severity="warning">
              To see more details, please select a property from the options
              provided.
            </Alert>
          </Grid>
        ) : null}
      </Grid>
    </Toolbar>
  );
};
