import * as yup from 'yup';
import { createAction } from '@8baselabs/react-simple-state';
import {
  ClientListResponse,
  UserUpdateInput,
  User,
  Fee,
  FeeListResponse,
  FeeUpdateInput,
  FeeSettingUpdateInput,
  FeeSettingListResponse,
  FeeSetting,
  UserType,
  ClientUserType,
  Query,
  PropertyPortfolioListResponse,
  SchoolPortfolioListResponse,
  AdminSettingListResponse,
  AdminSettingManyResponse,
  StudentLifeCreateInput,
  StudentLifeDeleteInput,
  StudentLifeUpdateInput,
  Property,
  UserDeleteInput,
  ClientFilter,
  PropertyFilter,
  ClientCreateInput,
  // AdminSetting,
} from '@8baselabs/resident-io-shared';
import { FileValue } from '@8base-react/file-input';
import { apolloClient, apolloClient as client } from '../../shared/apollo';
import { ResultType } from '../../shared/constants';
import {
  OnCreateInvitation,
  OnCreateInvitationError,
  OnFetchAllClients,
  OnFetchAllClientsError,
  OnUserToggled,
  OnUserToggledError,
  OnUpdateAllFees,
  OnUpdateAllFeesError,
  OnAssignInitialFees,
  OnAssignInitialFeesError,
  OnConfigureInitialFees,
  OnConfigureInitialFeesError,
  OnUpdateFeeSettings,
  OnUpdateFeeSettingsError,
  OnDeleteSchool,
  OnDeleteSchoolError,
  OnCreateSchoolError,
  OnCreateSchool,
  OnCreateSchoolPortfolio,
  OnCreateSchoolPortfolioError,
  OnFetchPropertyPortfolio,
  OnFetchPropertyPortfolioError,
  OnDeleteSchoolPortfolio,
  OnDeleteSchoolPortfolioError,
  OnFetchSchoolPortfolio,
  OnFetchSchoolPortfolioError,
  onFetchGlobalInstagram,
  onFetchGlobalInstagramError,
  onCreateGlobalInstagram,
  onCreateGlobalInstagramError,
  onUpdateGlobalInstagram,
  onUpdateGlobalInstagramError,
  onCreateStudentLife,
  onCreateStudentLifeError,
  OnDeleteStudentLife,
  OnDeleteStudentLifeError,
  OnEditStudentLife,
  OnEditStudentLifeError,
  onFetchHelloSignSettingError,
  onFetchHelloSignSetting,
  onCreateHelloSignSetting,
  onCreateHelloSignSettingError,
  onUpdateHelloSignSetting,
  onUpdateHelloSignSettingError,
  onUpdateFeatureProperty,
  onUpdateFeaturePropertyError,
  onUpdateSchoolProperty,
  onUpdateSchoolPropertyError,
  onUserDelete,
  onUserDeleteError,
  onFetchClientUserByProperty,
  onFetchClientUserByPropertyError,
  onUpdateClientUserProperty,
  onUpdateClientUserPropertyError,
  onCreateClientUser,
  onCreateClientUserError,
  onDisconnectClientUserProperty,
  onDisconnectClientUserPropertyError,
  OnFetchManager,
  OnFetchManagerError,
  OnManyClientsDelete,
  OnManyClientsDeleteError,
  OnManyPropertiesDeleteError,
  OnManyPropertiesDelete,
  onCreateClient,
  onCreateClientError, OnUpdateSchoolError, OnUpdateSchool,
} from './admin-events';
import {
  TOGGLE_USER_STATUS_MUTATION,
  FETCH_ALL_CLIENTS_QUERY,
  ASSIGN_INITIAL_FEES_MUTATION,
  UPDATE_ALL_FEES_MUTATION,
  CONFIGURE_INITIAL_FEES_MUTATION,
  UPDATE_FEE_SETTINGS_MUTATION,
  CREATE_INVITATION_ADMIN_MUTATION,
  CREATE_INVITATION_RESIDENT_MUTATION,
  CREATE_INVITATION_PROPERTY_MANAGER_MUTATION,
  CREATE_INVITATION_PROPERTY_OWNER_MUTATION,
  DELETE_SCHOOL_MUTATION,
  CREATE_SCHOOL_MUTATION,
  CREATE_SCHOOL_PORTFOLIO,
  GET_PROPERTY_PORTFOLIO,
  GET_SCHOOL_PORTFOLIO,
  DELETE_SCHOOL_PORTFOLIO,
  CHECK_PORTFOLIO_ID,
  GET_INSTAGRAM_GLOBAL_SETTING,
  CREATE_GLOBAL_SETTING,
  UPDATE_GLOBAL_SETTING,
  CREATE_STUDENT_LIFE_MUTATION,
  DELETE_STUDENT_LIFE_MUTATION,
  UPDATE_STUDENT_LIFE_MUTATION,
  GET_HELLOSIGN_SETTING,
  UPDATE_FEATURE_PROPERTY,
  UPDATE_SCHOOL_PROPERTY,
  DELETE_USER,
  FETCH_CLIENT_USER_BY_PROPERTY,
  UPDATE_PROPERTY_CLIENT_USER,
  CREATE_CLIENT_USER,
  DISCONNECT_CLIENT_USER,
  GET_MANAGER,
  DELETE_CLIENTS_BY_FILTER_MUTATION,
  DELETE_PROPERTIES_BY_FILTER_MUTATION,
  CREATE_CLIENT_MUTATION, CREATE_SCHOOL_TESTIMONIAL, UPDATE_SCHOOL_TESTIMONIAL, UPDATE_SCHOOL_MUTATION,
} from './admin-queries';
import {
  HelloSignGlobalSettingData,
  InstagramGlobalData,
} from './admin-types';
import {
  School,
  SchoolCreateInput,
  SchoolDeleteInput,
  SchoolListResponse,
  SchoolTestimonial,
  SchoolTestimonialCreateInput,
  SchoolTestimonialUpdateInput,
} from '../../schema-types';

import {urlRegEx} from "../../shared/constants/validation";




/**
 * @description - Get client users.
 * @returns {User}.
 */
export const getManager = createAction(
  OnFetchManager,
  OnFetchManagerError,
  async (id): Promise<User | undefined> => {
    const response = await client.query<Query>({
      query: GET_MANAGER,
      variables: {
        id,
      },
    });

    return response.data.user;
  },
);

/**
 * @description - fetch all users.
 * @returns {void}.
 */

export const createInvitation = createAction(
  OnCreateInvitation,
  OnCreateInvitationError,
  async (data) => {
    const validationSchema = yup.object({
      firstName: yup
        .string()
        .matches(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]{2,25}$/,
          'Please enter correct last name',
        )
        .required('First name is required')
        .nullable(),
      lastName: yup
        .string()
        .matches(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]{2,25}$/,
          'Please enter correct last name',
        )
        .required('Last name is required')
        .nullable(),
      email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required')
        .nullable(),
      role: yup
        .string()
        .required('Role is required')
        .oneOf([
          UserType.USER,
          UserType.ADMIN,
          ClientUserType.PROPERTY_OWNER,
          ClientUserType.PROPERTY_MANAGER,
        ])
        .nullable(),
      clientId: yup.string().nullable(),
    });

    await validationSchema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
    });
    const clientAssignId = data.clientAssignId;
    let mutation;
    const newData = {
      ...data,
      role: undefined,
      clientId: undefined,
      propertyId: undefined,

      inviter: { connect: { id: data.inviter } },
    };
    delete newData.clientAssignId;

    switch (data.role) {
      case UserType.ADMIN:
        mutation = CREATE_INVITATION_ADMIN_MUTATION;
        break;
      case UserType.USER:
        mutation = CREATE_INVITATION_RESIDENT_MUTATION;
        break;
      case ClientUserType.PROPERTY_OWNER:
        mutation = CREATE_INVITATION_PROPERTY_OWNER_MUTATION;
        newData.clientId = clientAssignId;
        break;
      case ClientUserType.PROPERTY_MANAGER:
        mutation = CREATE_INVITATION_PROPERTY_MANAGER_MUTATION;
        newData.client = {
          connect: {
            id: data.clientId,
          },
        };
        newData.propertyInvitationPropertyManager = {
          create: {
            property: {
              connect: {
                id: data.propertyId,
              },
            },
          },
        };
        break;
      default:
        throw new yup.ValidationError('Invalid role provided');
    }
    await client.mutate({
      mutation,
      variables: {
        data: newData,
      },
    });
  },
);

/**
 * @description - fetch all clients.
 * @returns {void}.
 */
export const fetchAllClients = createAction(
  OnFetchAllClients,
  OnFetchAllClientsError,
  async () =>
    (
      await client.query<{
        clientsList: ClientListResponse;
      }>({
        query: FETCH_ALL_CLIENTS_QUERY,
      })
    ).data.clientsList,
);

/**
 * @description - Delete user.
 * @returns {void}.
 */
export const toggleUserStatus = createAction(
  OnUserToggled,
  OnUserToggledError,
  async (data: UserUpdateInput) => {
    const response =
      (
        await client.mutate<{
          toggledUser: User;
        }>({
          mutation: TOGGLE_USER_STATUS_MUTATION,
          variables: {
            data,
          },
        })
      ).data?.toggledUser ?? null;

    // await apolloClient.cache.reset();

    return response;
  },
);

/**
 * Delete many Lease Users.
 */
export const toggleManyUserStatus = createAction(
  OnUserToggled,
  OnUserToggledError,
  async (ids: string[]): Promise<User> => {
    const dataArr: UserUpdateInput[] = ids.map((id) => ({
      id,
      status: 'inactive',
    }));

    const promises = dataArr.map(async (data) => {
      await client.mutate({
        mutation: TOGGLE_USER_STATUS_MUTATION,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    // await client.cache.reset();

    return {
      id: '',
    };
  },
);

export const assignInitialFees = createAction(
  OnAssignInitialFees,
  OnAssignInitialFeesError,
  async (data) =>
    (
      await client.mutate<{ assignedFees: ResultType }>({
        mutation: ASSIGN_INITIAL_FEES_MUTATION,
        variables: {
          data,
        },
      })
    ).data?.assignedFees as ResultType,
);

export const configureInitialFees = createAction(
  OnConfigureInitialFees,
  OnConfigureInitialFeesError,
  async (id) =>
    (
      await client.mutate<{ configuredFees: Fee }>({
        mutation: CONFIGURE_INITIAL_FEES_MUTATION,
        variables: {
          id,
        },
      })
    ).data?.configuredFees as Fee,
);

/**
 * Update all the fees.
 */
export const updateFees = createAction(
  OnUpdateAllFees,
  OnUpdateAllFeesError,
  async (data: FeeUpdateInput) => {
    const validationSchema = yup.object({
      reservationFee: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      creditCardRateAmount: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      creditCardRatePercentage: yup
        .number()
        .moreThan(0, 'This value must be a percentage between 0 and 100')
        .lessThan(100, 'This value must be a percentage between 0 and 100')
        .required('This field is required'),
      intCreditCardRateAmount: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      intCreditCardRatePercentage: yup
        .number()
        .moreThan(0, 'This value must be a percentage between 0 and 100')
        .lessThan(100, 'This value must be a percentage between 0 and 100')
        .required('This field is required'),
      expiredCreditCardUpdate: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      onlineAchPayment: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      checkSendingDigital: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      checkSendingPaper: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      onlineNSFChargebackFee: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
      perBedHostingAndSetup: yup
        .number()
        .moreThan(0, 'This value must be greater than 0')
        .required('This field is required'),
    });

    await validationSchema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
    });

    const { data: response } = await client.mutate<{
      updatedFeesList: FeeListResponse;
    }>({
      mutation: UPDATE_ALL_FEES_MUTATION,
      variables: { data },
    });

    // await apolloClient.cache.reset();

    return response?.updatedFeesList as Fee;
  },
);

export const updateFeeSettings = createAction(
  OnUpdateFeeSettings,
  OnUpdateFeeSettingsError,
  async (data: FeeSettingUpdateInput) => {
    const { data: response } = await client.mutate<{
      updatedFeeSettingList: FeeSettingListResponse;
    }>({
      mutation: UPDATE_FEE_SETTINGS_MUTATION,
      variables: { data },
    });

    // await apolloClient.cache.reset();

    return response?.updatedFeeSettingList as FeeSetting;
  },
);

/**
 * @description - Delete school.
 * @returns {void}.
 */
export const deleteSchool = createAction(
  OnDeleteSchool,
  OnDeleteSchoolError,
  async (data: SchoolDeleteInput) => {
    const { data: response } = await client.mutate({
      mutation: DELETE_SCHOOL_MUTATION,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();

    return response;
  },
);

/**
 * @description - Delete school.
 * @returns {void}.
 */
export const deleteManySchools = createAction(
  OnDeleteSchool,
  OnDeleteSchoolError,
  async (schools: SchoolDeleteInput[]) => {
    const promises = schools.map(async (data) => {
      await client.mutate({
        mutation: DELETE_SCHOOL_MUTATION,
        variables: {
          data,
        },
      });
    });
    await Promise.all(promises);

    // await apolloClient.cache.reset();

    return {
      id: '',
    };
  },
);

export const createSchool = createAction(
  OnCreateSchool,
  OnCreateSchoolError,
  async (data: SchoolCreateInput) => {
    const validationSchema = yup.object({
      name: yup.string().required('This field is required'),
      address: yup.string().required('This field is required'),
      zipCode: yup.string().required('This field is required'),
      city: yup.string().required('This field is required'),
      state: yup.string().required('This field is required'),
      phoneNumber: yup
        .string()
        .matches(
          /^\+([0-9]+ |[0-9]+)(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
          'Please enter correct phone number',
        )
        .required('This field is required'),
      webUrl: yup
        .string()
        .url('Enter a valid url')
        .required('This field is required'),
    });

    const { ...rest } = data;
    await validationSchema.validate(
      { ...rest },
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );

    const { data: response } = await client.mutate({
      mutation: CREATE_SCHOOL_MUTATION,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();

    return response;
  },
);

export const updateSchool = createAction(
  OnUpdateSchool,
  OnUpdateSchoolError,
  async (
    { id, prevImages, headerImage, siteLogo, city, officeHours, ...rest },
    prevProperties: string[],
    propertiesSelected: string[],
    prevTestimonial: SchoolTestimonial[],
    testimonial: SchoolTestimonial[],
    editTestimonial: SchoolTestimonial[],
    deleteTestimonial: string[],
  ) => {


    const validationSchema = yup.object({
      address: yup.string().required('This field is required'),
      city: yup.string().required('This field is required'),
      state: yup.string().required('This field is required'),
      zipCode: yup.string().required('This field is required'),
      phoneNumber: yup.string().required('This field is required'),
      webUrl: yup
        .string()
        .url('Enter a valid url')
        .required('This field is required'),
      email: yup.string().email('Enter a valid email').nullable(),
      managerLastName: yup.string().nullable(),
      managerFirstName: yup.string().nullable(),
      twitterUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .nullable(),
      facebookUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .nullable(),
      officeHours: yup.string().nullable(),
      aboutYourLocation: yup.string().nullable(),
      instagramUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .nullable(),
      instagramAt: yup.string().nullable(),
      instagramHashtag: yup.string().nullable(),
      urlSubdomain: yup.string().nullable(),
      customDomain: yup.string().url('Enter a valid url').nullable(),
      siteDescription: yup.string().nullable(),
      metaDescription: yup.string().nullable(),
    });

    await validationSchema.validate(
      { city, ...rest },
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );

    let headerImageOperation;

    if (headerImage === null) {
      headerImageOperation = {
        disconnect: {
          fileId: prevImages?.headerImage?.fileId,
        },
      };
    } else if (headerImage?.fileId !== prevImages?.headerImage?.fileId) {
      headerImageOperation = {
        create: {
          fileId: headerImage?.fileId,
          filename: headerImage?.filename,
          public: true,
        },
      };
    }

    // if siteLogo === null -> Delete image

    // else if siteLogo !== prevImages.siteLogo.fileId -> Create or update the image

    // nothing -> do nothing

    let siteLogoOperation;

    if (siteLogo === null) {
      siteLogoOperation = {
        disconnect: {
          fileId: prevImages?.siteLogo?.fileId,
        },
      };
    } else if (siteLogo?.fileId !== prevImages?.siteLogo?.fileId) {
      siteLogoOperation = {
        create: {
          fileId: siteLogo?.fileId,
          filename: siteLogo?.filename,
          public: true,
        },
      };
    }

    let schoolProperties;

    if (prevProperties === propertiesSelected) {
      return;
    } else {
      schoolProperties = {
        reconnect: propertiesSelected.map((value) => ({ id: value })),
      };
    }

    const fileIdTestimonial: Partial<string[]> = prevTestimonial.map(
      (item) => item.image?.fileId,
    );

    const data = {
      id,
      schoolPropertyRelation: schoolProperties,
      websiteVisibility: propertiesSelected.length !== 0,
      headerImage: headerImageOperation || null,
      siteLogo: siteLogoOperation || null,
      officeHours: officeHours,
      city,
      schoolTestimonials: {
        disconnect: deleteTestimonial.map((testimonialId) => ({
          id: testimonialId,
        })),
      },
      ...rest,
    };

    const testimonialWhitOutEdit: SchoolTestimonial[] = testimonial?.filter(
      (filterEdit) => filterEdit.id === undefined,
    );

    const dataTestimonial: SchoolTestimonialCreateInput[] =
      testimonialWhitOutEdit
        ?.filter(
          (item) =>
            !fileIdTestimonial
              .map((element) => element)
              .includes(item.image?.fileId),
        )
        .map((mapTestimonial) => ({
          content: mapTestimonial.content,
          name: mapTestimonial.name,
          image: {
            create: {
              fileId: mapTestimonial.image?.fileId,
              filename: mapTestimonial.image?.filename,
              public: true,
            },
          },
          schoolTestimonialSchoolRelation: { connect: { id: data.id } },
        }));
    const dataEditTestimonial: SchoolTestimonialUpdateInput[] =
      editTestimonial.map((mapTestimonialEdit) => {
        let validateImage;
        const lastImgId = prevTestimonial.find(
          (element) => element.id === mapTestimonialEdit.id,
        )?.image?.fileId;
        if (lastImgId !== mapTestimonialEdit.image?.fileId) {
          validateImage = {
            image: {
              update: {
                fileId: mapTestimonialEdit.image?.fileId,
              },
            },
          };
        }
        return {
          content: mapTestimonialEdit.content,
          name: mapTestimonialEdit.name,
          id: mapTestimonialEdit.id,
          ...validateImage,
        };
      });

    Promise.all(
      dataTestimonial.map(async (item) => {
        await apolloClient.mutate({
          mutation: CREATE_SCHOOL_TESTIMONIAL,
          variables: { data: item },
        });
      }),
    );

    Promise.all(
      dataEditTestimonial.map(async (item) => {
        await apolloClient.mutate({
          mutation: UPDATE_SCHOOL_TESTIMONIAL,
          variables: { data: item },
        });
      }),
    );

    const { data: response } = await client.mutate({
      mutation: UPDATE_SCHOOL_MUTATION,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();

    return response;
  },
);

type FormDialogSend = {
  portfolioId: string;
  schoolId: string;
  image: FileValue;
};

export const createSchoolPortfolio = createAction(
  OnCreateSchoolPortfolio,
  OnCreateSchoolPortfolioError,
  async (data: FormDialogSend) => {
    const filter = {
      school: { id: { equals: data.schoolId } },
      propertyPortfolio: { id: { equals: data.portfolioId } },
    };
    const response = await client.query<{
      schoolPortfoliosList: SchoolPortfolioListResponse;
    }>({
      query: CHECK_PORTFOLIO_ID,
      variables: {
        filter,
      },
    });
    if (response.data.schoolPortfoliosList.count === 0) {
      const parseData = {
        propertyPortfolio: { connect: { id: data.portfolioId } },
        school: { connect: { id: data.schoolId } },
        image: { create: { fileId: data.image.fileId } },
      };
      await client.mutate({
        mutation: CREATE_SCHOOL_PORTFOLIO,
        variables: {
          data: parseData,
        },
      });

      // skipped
      await apolloClient.cache.reset();
      return { message: 'Save success' };
    }
    return { message: 'This portfolio already exist' };
  },
);

export const fetchPropertyPortfolio = createAction(
  OnFetchPropertyPortfolio,
  OnFetchPropertyPortfolioError,
  async () => {
    const response = await client.query<{
      propertyPortfoliosList: PropertyPortfolioListResponse;
    }>({
      query: GET_PROPERTY_PORTFOLIO,
    });
    return response.data.propertyPortfoliosList;
  },
);

export const deleteSchoolPortfolio = createAction(
  OnDeleteSchoolPortfolio,
  OnDeleteSchoolPortfolioError,
  async (id: string) => {
    const filter = { id };
    await client.mutate({
      mutation: DELETE_SCHOOL_PORTFOLIO,
      variables: {
        filter,
      },
    });
    await apolloClient.cache.reset();
    return { message: 'success' };
  },
);

export const fetchSchoolPort = createAction(
  OnFetchSchoolPortfolio,
  OnFetchSchoolPortfolioError,
  async (id: string) => {
    const filter = { school: { id: { equals: id } } };
    const response = await client.query<{
      schoolPortfoliosList: SchoolPortfolioListResponse;
    }>({
      query: GET_SCHOOL_PORTFOLIO,
      variables: {
        filter,
      },
    });
    const responseFixed = response.data.schoolPortfoliosList.items.map(
      (item) => {
        let allNames = '';
        const newUrl = item.image?.downloadUrl?.split(
          'https://cdn.filestackcontent.com',
        );
        const image = `https://cdn.filestackcontent.com/resize=height:40,width:40,fit:clip${
          newUrl ? newUrl[1] : ''
        }`;
        const arrPortfolio = item?.propertyPortfolio?.property?.items;
        arrPortfolio?.map((itemsProperty, index) => {
          if (arrPortfolio.length - 1 === index) {
            allNames += `${itemsProperty.name}`;
          } else {
            allNames += `${itemsProperty.name},`;
          }
          return allNames;
        });

        const dataObject = {
          id: item.id,
          image,
          properties: allNames,
        };
        return dataObject;
      },
    );
    return responseFixed;
  },
);

export const FetchInstagramData = createAction(
  onFetchGlobalInstagram,
  onFetchGlobalInstagramError,
  async () => {
    const response = await client.query<{
      adminSettingsList: AdminSettingListResponse;
    }>({
      query: GET_INSTAGRAM_GLOBAL_SETTING,
    });
    const { items } = response.data.adminSettingsList;
    let object: InstagramGlobalData = {
      instagramAt: '',
      instagramHashtag: '',
      instagramUrl: '',
    };
    if (items.length > 0) {
      Object.keys(items).forEach((key) => {
        const { name, value } = items[key];
        object = {
          ...object,
          [name]: value,
        };
      });
    }

    return object;
  },
);

export const createInstagramData = createAction(
  onCreateGlobalInstagram,
  onCreateGlobalInstagramError,
  async (dataForm: InstagramGlobalData) => {
    let arrayData: ArrayData[] = [];
    Object.keys(dataForm).forEach((keys) => {
      const newData = [{ name: keys, value: dataForm[keys] }];
      arrayData = [...arrayData, ...newData];
    });
    const response = await client.mutate<{
      adminSettingCreateMany: AdminSettingManyResponse;
    }>({
      mutation: CREATE_GLOBAL_SETTING,
      variables: {
        data: arrayData,
      },
    });

    // skipped
    await apolloClient.cache.reset();

    return response.data?.adminSettingCreateMany.count || 0;
  },
);

export type ArrayData = { name: string; value: string };

export const updateInstagramData = createAction(
  onUpdateGlobalInstagram,
  onUpdateGlobalInstagramError,
  async (dataForm: InstagramGlobalData) => {
    let arrayData: ArrayData[] = [];
    Object.keys(dataForm).forEach((keys) => {
      const newData = [{ name: keys, value: dataForm[keys] }];
      arrayData = [...arrayData, ...newData];
    });
    const updateInstagramDataMutation = async (
      instagramItems,
    ): Promise<void> => {
      await client.mutate<{
        adminSettingUpdateByFilter: AdminSettingManyResponse;
      }>({
        mutation: UPDATE_GLOBAL_SETTING,
        variables: {
          data: { value: { set: instagramItems.value } },
          filter: { name: { equals: instagramItems.name } },
        },
      });
    };
    await Promise.all(arrayData.map(updateInstagramDataMutation));

    // skipped
    await apolloClient.cache.reset();
  },
);

/**
 * @description - create student life.
 * @returns {void}.
 */
export const createStudentLife = createAction(
  onCreateStudentLife,
  onCreateStudentLifeError,
  async (data: StudentLifeCreateInput) => {
    const validationSchema = yup.object({
      title: yup.string().required('This field is required'),
      profileName: yup.string().required('This field is required'),
      link: yup
        .string()
        .url('Enter a valid url')
        .required('This field is required'),
    });

    const { ...rest } = data;
    await validationSchema.validate(
      { ...rest },
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );

    const { data: response } = await client.mutate({
      mutation: CREATE_STUDENT_LIFE_MUTATION,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();

    return response;
  },
);

/**
 * @description - Delete Student Life.
 * @returns {void}.
 */
export const deleteStudentLife = createAction(
  OnDeleteStudentLife,
  OnDeleteStudentLifeError,
  async (data: StudentLifeDeleteInput) => {
    const { data: response } = await client.mutate({
      mutation: DELETE_STUDENT_LIFE_MUTATION,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();

    return response;
  },
);

/**
 * @description - Edit student life.
 * @returns {void}.
 */
export const editStudentLife = createAction(
  OnEditStudentLife,
  OnEditStudentLifeError,
  async (data: StudentLifeUpdateInput) => {
    const validationSchema = yup.object({
      title: yup.string().required('This field is required'),
      profileName: yup.string().required('This field is required'),
      link: yup
        .string()
        .url('Enter a valid url')
        .required('This field is required'),
    });

    const { ...rest } = data;
    await validationSchema.validate(
      { ...rest },
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );

    const { data: response } = await client.mutate({
      mutation: UPDATE_STUDENT_LIFE_MUTATION,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();

    return response;
  },
);

export const FetchHelloSignData = createAction(
  onFetchHelloSignSetting,
  onFetchHelloSignSettingError,
  async () => {
    const response = await client.query<{
      adminSettingsList: AdminSettingListResponse;
    }>({
      query: GET_HELLOSIGN_SETTING,
    });
    const { items } = response.data.adminSettingsList;
    let object: HelloSignGlobalSettingData = {
      HELLOSIGN_CLIENT_ID: '',
      HELLOSIGN_API_KEY: '',
    };
    if (items.length > 0) {
      Object.keys(items).forEach((key) => {
        const { name, value } = items[key];
        object = {
          ...object,
          [name]: value,
        };
      });
    }

    return object;
  },
);

export const createHelloSignData = createAction(
  onCreateHelloSignSetting,
  onCreateHelloSignSettingError,
  async (dataForm: HelloSignGlobalSettingData) => {
    let arrayData: ArrayData[] = [];
    Object.keys(dataForm).forEach((keys) => {
      const newData = [{ name: keys, value: dataForm[keys] }];
      arrayData = [...arrayData, ...newData];
    });
    const response = await client.mutate<{
      adminSettingCreateMany: AdminSettingManyResponse;
    }>({
      mutation: CREATE_GLOBAL_SETTING,
      variables: {
        data: arrayData,
      },
    });

    // skipped
    await apolloClient.cache.reset();

    return response.data?.adminSettingCreateMany.count || 0;
  },
);

export const updateHelloSignData = createAction(
  onUpdateHelloSignSetting,
  onUpdateHelloSignSettingError,
  async (dataForm: HelloSignGlobalSettingData) => {
    let arrayData: ArrayData[] = [];
    Object.keys(dataForm).forEach((keys) => {
      const newData = [{ name: keys, value: dataForm[keys] }];
      arrayData = [...arrayData, ...newData];
    });
    const updateHelloSignDataMutation = async (
      helloSignItems,
    ): Promise<void> => {
      await client.mutate<{
        adminSettingUpdateByFilter: AdminSettingManyResponse;
      }>({
        mutation: UPDATE_GLOBAL_SETTING,
        variables: {
          data: { value: { set: helloSignItems.value } },
          filter: { name: { equals: helloSignItems.name } },
        },
      });
    };
    await Promise.all(arrayData.map(updateHelloSignDataMutation));

    // skipped
    await apolloClient.cache.reset();
  },
);

export const updatePropertyFeature = createAction(
  onUpdateFeatureProperty,
  onUpdateFeaturePropertyError,
  async (dataForm: { id: string; features: string }) => {
    await client.mutate<{
      propertyUpdate: Property;
    }>({
      mutation: UPDATE_FEATURE_PROPERTY,
      variables: {
        data: {
          id: dataForm.id,
          featured: dataForm.features,
        },
      },
    });

    // await apolloClient.cache.reset();
  },
);

export const updatePropertySchool = createAction(
  onUpdateSchoolProperty,
  onUpdateSchoolPropertyError,
  async (dataForm: { id: string; schoolId: string }) => {
    await client.mutate<{
      propertyUpdate: Property;
    }>({
      mutation: UPDATE_SCHOOL_PROPERTY,
      variables: {
        data: {
          id: dataForm.id,
          school: { connect: { id: dataForm.schoolId } },
        },
      },
    });

    // await apolloClient.cache.reset();
  },
);

export const deleteUserAction = createAction(
  onUserDelete,
  onUserDeleteError,
  async (data: UserDeleteInput) => {
    const { data: response } = await client.mutate({
      mutation: DELETE_USER,
      variables: {
        data,
      },
    });
    // await apolloClient.cache.reset();
    return response;
  },
);

/**
 * @description - fetch clientUser by property.
 * @returns {void}.
 */
export const fetchClientUserByProperty = createAction(
  onFetchClientUserByProperty,
  onFetchClientUserByPropertyError,
  async (id) => {
    const response = await client.query<{
      property: Property;
    }>({
      query: FETCH_CLIENT_USER_BY_PROPERTY,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    });
    return response.data.property;
  },
);

export const updatePropertyClientUser = createAction(
  onUpdateClientUserProperty,
  onUpdateClientUserPropertyError,
  async (updateData: {
    propertyId: string;
    clientUserId: string;
  }): Promise<Property | undefined> => {
    const data = {
      id: updateData.propertyId,
      clientUser: {
        connect: {
          id: updateData.clientUserId,
        },
      },
    };
    const response = await client.mutate<{
      propertyUpdate: Property;
    }>({
      mutation: UPDATE_PROPERTY_CLIENT_USER,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();
    return response.data?.propertyUpdate;
  },
);

export const createClientUser = createAction(
  onCreateClientUser,
  onCreateClientUserError,
  async (data: {
    userId: string;
    propertyId: string;
    clientId: string;
    role: string;
  }) => {
    const formattedData = {
      user: {
        connect: {
          id: data.userId,
        },
      },
      property: {
        connect: {
          id: data.propertyId,
        },
      },
      role: data.role,
      client: {
        connect: {
          id: data.clientId,
        },
      },
    };

    const response = await client.mutate({
      mutation: CREATE_CLIENT_USER,
      variables: {
        data: formattedData,
      },
    });
    // await apolloClient.cache.reset();
    return response.data;
  },
);

export const disconnectPropertyClientUser = createAction(
  onDisconnectClientUserProperty,
  onDisconnectClientUserPropertyError,
  async (updateData: {
    clientUserId: string;
    propertyId: string;
  }): Promise<Property | undefined> => {
    const data = {
      id: updateData.propertyId,
      clientUser: {
        disconnect: {
          id: updateData.clientUserId,
        },
      },
    };
    const response = await client.mutate<{
      propertyUpdate: Property;
    }>({
      mutation: DISCONNECT_CLIENT_USER,
      variables: {
        data,
      },
    });

    // await apolloClient.cache.reset();
    return response.data?.propertyUpdate;
  },
);

export const deleteManyUsersAction = createAction(
  OnManyClientsDelete,
  OnManyClientsDeleteError,
  async (filter: ClientFilter): Promise<boolean> => {
    const response = await apolloClient.mutate({
      mutation: DELETE_CLIENTS_BY_FILTER_MUTATION,
      variables: {
        filter,
      },
    });

    // We don't need to reset the cache if we change the fetch policy
    // await apolloClient.cache.reset();
    return response.data.clientDeleteByFilter.success;
  },
);

export const deleteManyPropertiesAction = createAction(
  OnManyPropertiesDelete,
  OnManyPropertiesDeleteError,
  async (filter: PropertyFilter): Promise<boolean> => {
    const response = await apolloClient.mutate({
      mutation: DELETE_PROPERTIES_BY_FILTER_MUTATION,
      variables: {
        filter,
      },
    });

    // await apolloClient.cache.reset();
    return response.data.propertyDeleteByFilter.success;
  },
);

export const createClientAction = createAction(
  onCreateClient,
  onCreateClientError,
  async (data: { name: string }) => {
    const input: ClientCreateInput = {
      name: data.name,
    };

    const response = await client.mutate({
      mutation: CREATE_CLIENT_MUTATION,
      variables: {
        data: input,
      },
    });

    return response.data;
  },
);
