import { FileValue } from '@8base-react/file-input';
import {
  Building,
  Floor,
  Unit,
  Bed,
  Room,
  PaymentPlan,
  PaymentPlanListResponse,
  OptionalPreferenceListResponse,
  LeaseStatus,
  LeasePeriodRelationFilter,
  DateTimePredicate,
  IntPredicate,
  UserFilter,
  LeasePeriodFilter,
  IdPredicate,
  LeasePeriod,
  School,
  ClientPage,
} from '@8baselabs/resident-io-shared';

export interface RoomI extends Room {
  beds?: Bed[];
}

export interface UnitI extends Unit {
  rooms?: RoomI[];
}
export interface FloorType extends Floor {
  units?: UnitI[];
}

export interface BuildingType extends Building {
  floors?: FloorType[];
}

export type SchoolCompanySubdomainResponse = {
  getSchoolCompanyBySubdomain: {
    school: School[];
    company: ClientPage[];
  };
};

export type BuildingLayoutAction = {
  type: number;
  payload?: {
    buildingIndex?: number;
    newValue?: string;
    newUnit?: UnitI;
    floorNumber?: string;
    floorId?: string;
    savedLayout?: BuildingType[];
    unitCode?: string;
    newBuilding?: BuildingType;
  };
};

export type ModalUnitAction = {
  type: number;
  payload?: {
    unitCode?: string;
    unitType?: string;
    unitGender?: string;
    roomType?: string;
    roomNumber?: number;
    unitToEdit?: UnitI;
    roomCode?: string;
    bedNumber?: string;
    bedIndex?: number;
  };
};

export type LeasePeriodRenewalFilter = {
  id: IdPredicate;
  createdAt: DateTimePredicate;
  updatedAt: DateTimePredicate;
  deletedAt: IntPredicate;
  _fullText: string;
  createdBy: UserFilter;
  leasePeriodToRenew: LeasePeriodFilter;
  leasePeriods: LeasePeriodRelationFilter;
};

export interface LeasePeriodWithRenewal extends LeasePeriod {
  leasePeriodToRenew: LeasePeriodFilter;
  leasePeriods: LeasePeriodRelationFilter;
  AND: [LeasePeriodRenewalFilter];
  OR: [LeasePeriodRenewalFilter];
}

export enum ModalUnitsActionTypes {
  CHANGE_UNIT_CODE,
  CHANGE_UNIT_TYPE,
  CHANGE_UNIT_GENDER,
  CHANGE_ROOM_TYPE,
  CHANGE_ROOM_CODE,
  CHANGE_BED_NUMBER,
  ADD_NEW_ROOM,
  RESET,
  ADD_BED,
  EDIT,
  RESET_ROOMS,
  RESET_BEDS,
}

export enum BuildingLayoutActionTypes {
  ADD_NEW_BUILDING,
  DELETE_BUILDING,
  CHANGE_BUILDING_NAME,
  ADD_NEW_FLOOR,
  SET_FLOOR_ID,
  UPDATE_FLOOR_ID,
  ADD_NEW_UNIT,
  DELETE_FLOOR,
  DELETE_UNIT,
  SET_SAVED_LAYOUT,
  EDIT_UNIT,
  UPLOAD_FILES,
}

export enum UnitFieldValues {
  type = 'type',
  code = 'code',
  gender = 'gender',
  beds = 'beds',
  price = 'price',
}

export type BuildingCSVType = {
  Bed: string;
  Room: string;
  Gender: string;
  'Room-Type': string;
  Unit: string;
  Floor: string;
  'Unit-Type': string;
  Building: string;
};

export const RoomsEnum = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'll',
];

export type ResultsLocation = {
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
};

export type TypeLocation = {
  status: string;
  results: ResultsLocation[];
};

export type PaymentPlanSelected = {
  id?: string;
  paymentPlan: PaymentPlan;
  action: 'create' | 'delete' | null;
}[];

export type PaymentPlansAndOptionalPreferences = {
  paymentPlansList: PaymentPlanListResponse;
  optionalPreferencesList: OptionalPreferenceListResponse;
};

// this type must to be in the shared dependencie
export type ResidentImported = {
  Email: string;
  'Cell Phone': string;
  'First Name': string;
  'Last Name': string;
  Gender: 'M' | 'F';
  'Property ID': string;
  'Lease Period ID': string;
  'Bed ID': string;
  'Locked?': 'yes' | 'no';
  'Payment Plan ID': string;
  'Reservation Status': LeaseStatus;
  'Current Balance': number;
  'Deposit On Hand': number;
  'Deposit On Hand Credit': number;
};

export interface PropertyImageFile extends FileValue {
  position?: number;
  caption?: string;
  type?: string;
}

export type BedPaymentPlanRowDef = {
  id: string;
  building: string;
  floor: string;
  unit: string;
  unitType: string;
  room: string;
  roomType: string;
  bed: string;
  requiredPlanNewTenant: string;
  optionalPlanNewTenant: string;
  requiredPlanRenewalTenant: string;
  optionalPlanRenewalTenant: string;
  requiredPlanRenewalTransfer: string;
  optionalPlanRenewalTransfer: string;
  available: boolean;
};
