import { gql } from '@apollo/client';

export const CREATE_DOCUMENT_DRAFT_MUTATION = gql`
  mutation CREATE_DOCUMENT_DRAFT_MUTATION($data: CreateDocumentTemplateData!) {
    createDocumentTemplate(data: $data) {
      success
      message
      template {
        template_id
        edit_url
        expires_at
      }
    }
  }
`;

export const EDIT_DOCUMENT_DRAFT_MUTATION = gql`
  mutation EDIT_DOCUMENT_DRAFT_MUTATION($data: EditDraftInput!) {
    editDraftTemplate(data: $data) {
      editUrl
      success
    }
  }
`;

export const CREATE_PROPERTY_DOCUMENT_TEMPLATE = gql`
  mutation CREATE_PROPERTY_DOCUMENT_TEMPLATE($data: PropertyDocumentTemplateCreateInput!) {
    propertyDocumentTemplateCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROPERTY_DOCUMENT_TEMPLATE = gql`
  mutation UPDATE_PROPERTY_DOCUMENT_TEMPLATE($documentId: ID!, $data: PropertyDocumentTemplateUpdateInput!) {
    propertyDocumentTemplateUpdate(filter: { id: $documentId }, data: $data) {
      id
    }
  }
`;

export const GET_PROPERTY_DOCUMENT_TEMPLATES = gql`
  query GET_PROPERTY_DOCUMENT_TEMPLATES($propertyId: ID!) {
    propertyDocumentTemplatesList(
      filter: {
        propertyPropertyDocumentTemplateRelation: {
          some: { id: { equals: $propertyId } }
        }
      }
    ) {
      count
      items {
        id
        name
        type
        startDate
        endDate
        status
        helloSignTemplateId
        document {
          downloadUrl
        }
        leasePeriodResidentPropDocumentRelation {
          items {
            id
            name
          }
        }
        leasePeriodGuarantorPropDocumentRelation {
          items {
            id
            name
          }
        }
        signers
        isForGuarantor
      }
    }
  }
`;

export const GET_PROPERTY_DOCUMENT_TEMPLATES_BETWEEN_DATES = gql`
  query GET_PROPERTY_DOCUMENT_TEMPLATES_BETWEEN_DATES($filter: PropertyDocumentTemplateFilter!) {
    propertyDocumentTemplatesList(filter: $filter) {
      count
      items {
        id
        name
        type
        startDate
        endDate
        signers
        isForGuarantor
        status
        helloSignTemplateId
        document {
          downloadUrl
        }
        leasePeriodResidentPropDocumentRelation {
          items {
            id
            name
          }
        }
        leasePeriodGuarantorPropDocumentRelation {
          items {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY_FORM = gql`
  query GET_PROPERTY_FORM($propertyId: ID!) {
    formsList(filter: { property: { id: { equals: $propertyId } } }) {
      items {
        type
        name
        id
        personalInformation
        leasingAgent
        howDidYouHearAboutUs
        roommateMatchingPreferences
        createdBy {
          email
        }
        createdAt
      }
    }
  }
`;

export const DELETE_MANY_PROPERTY_FORMS = gql`
  mutation DELETE_MANY_PROPERTY_FORMS($ids: [ID!]) {
    formDeleteByFilter(filter: { id: { in: $ids } }) {
      success
    }
  }
`;

export const DELETE_PROPERTY_FORM = gql`
  mutation DELETE_PROPERTY_FORM($formId: ID!) {
    formDelete(data: { id: $formId }) {
      success
    }
  }
`;

export const DELETE_PROPERTY_DOCUMENT_TEMPLATE = gql`
  mutation DELETE_PROPERTY_DOCUMENT_TEMPLATE($documentTemplateId: ID!) {
    propertyDocumentTemplateDelete(filter: { id: $documentTemplateId }) {
      success
    }
  }
`;

export const CREATE_FORM = gql`
  mutation CREATE_FORM($data: FormCreateInput!) {
    formCreate(data: $data) {
      id
    }
  }
`;

export const GET_FORM = gql`
  query GET_FORM($formId: ID!) {
    form(id: $formId) {
      id
      name
      type
      personalInformation
      leasingAgent
      howDidYouHearAboutUs
      roommateMatchingPreferences
      description
      data
      standardSectionsIncludes
      customSections
    }
  }
`;

export const UPDATE_FORM = gql`
  mutation UPDATE_FORM($formId: ID!, $data: FormUpdateInput!) {
    formUpdate(filter: { id: $formId }, data: $data) {
      id
    }
  }
`;

export const GET_PROPERTY_DOCUMENT_TEMPLATE = gql`
  query GET_PROPERTY_DOCUMENT_TEMPLATE($documentId: ID!) {
    propertyDocumentTemplate(id: $documentId) {
      document {
        downloadUrl
      }
      endDate
      startDate
      name
      status
      type
    }
  }
`;

export const FETCH_DOCUMENT_REQUIRED = gql`
  query FETCH_DOCUMENT_REQUIRED($propertyId: ID!) {
    documentTypesList {
      count
      items {
        name
        id
        isTenantDocument
        isGuarantorDocument
        documentTypeRequiredUploadRelation(
          filter: { property: { id: { equals: $propertyId } } }
        ) {
          count
          items {
            id
            isTenant
            isGuarantor
            property {
              id
            }
            id
            documentType {
              id
            }
            isIncluded
            isRequired
          }
        }
      }
    }
  }
`;
export const CREATE_REQUIRED_UPLOAD = gql`
  mutation CREATE_REQUIRED_UPLOAD($data: RequiredUploadCreateInput!) {
    requiredUploadCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_REQUIRED_UPLOAD = gql`
  mutation UPDATE_REQUIRED_UPLOAD($id: ID!, $data: RequiredUploadUpdateInput!) {
    requiredUploadUpdate(filter: { id: $id }, data: $data) {
      id
    }
  }
`;

export const GET_LEASE_PERIOD = gql`
  query GET_LEASE_PERIOD($propertyId: ID!) {
    leasePeriodsList(filter: { property: { id: { equals: $propertyId } } }) {
      items {
        id
        name
        startDate
        endDate
      }
    }
  }
`;

export const CHECK_LEASE_PERIOD_DOCUMENT = gql`
  query CHECK_LEASE_PERIOD_DOCUMENT(
    # $leaseId: ID!,
    $propertyId: ID!
  ) {
    propertyDocumentTemplatesList(
      filter: {
        propertyPropertyDocumentTemplateRelation: {
          every: { id: { equals: $propertyId } }
        }
        status: { equals: "ACTIVE" }
        # leasePeriodPropertyDocumentRelation: { id: { equals: $leaseId } }
      }
    ) {
      items {
        isForGuarantor
        type
        id
      }
    }
  }
`;
