import { DocumentNode } from 'graphql';
import { apolloClient } from '.';
import { makeVar } from "@apollo/client";

/**
 * @param {DocumentNode} document - Graphql document to create suscription.
 * @returns {Function} - Funtion to subscribe.
 */
export function createSubscription<T, U>(document: DocumentNode) {
  return (
    variables: U,
    callback: (data: T | null | undefined) => void,
  ): ZenObservable.Subscription => {
    const subscription = apolloClient.subscribe<T>({
      query: document,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variables,
    });

    return subscription.subscribe({
      next: (sub) => callback(sub.data),
      error: (err) => console.log(err),
    });
  };
}

export const authenticatedVar = makeVar(true);
