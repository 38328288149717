import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    large: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    menuItem: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    residentName: {
      display: 'inline-flex',
      fontSize: 15,
      marginRight: 5,
    },
    residentProperty: {
      fontSize: 13,
      fontWeight: 'bold',
    },
    residentLeasePeriod: {
      fontSize: 13,
    },
    status: {
      display: 'inline-flex',
      fontSize: 15,
      fontWeight: 700,
    },
    residentID: {
      display: 'inline-flex',
      fontSize: 14,
    },
    residentDescription: {
      fontSize: 13,
    },
    statusBadge: {
      height: 25,
      borderRadius: 4,
      color: 'white',
      textAlign: 'center',
    },
  }),
);

type ResidentItemProps = {
  description?: string;
  src: string;
  name: string;
  onClick: () => void;
  status?: string;
  statusBackground?: string;
  email?: string;
  phoneNumber?: string;
  leasePeriod?: {
    name: string;
    property?: {
      name: string;
    };
  };
};

/**
 * @param root0 - ResidentItem props.
 * @param root0.description - Color to show in status indicator.
 * @param root0.name - Status of resident with the PM.
 * @param root0.src - Status of resident with the PM.
 * @param root0.onClick - Status of resident with the PM.
 * @param root0.status - Status of resident with the PM.
 * @param root0.email - Resident email.
 * @param root0.phoneNumber - Resident phone number.
 * @param root0.statusBackground - Status chip background color.
 * @param root0.leasePeriod - Resident lease Period
 * @returns {React.FC} - Component to show resident item in searchs.
 */
export const ResidentItem: React.FC<ResidentItemProps> = ({
  description,
  name,
  src,
  status,
  phoneNumber,
  email,
  onClick,
  statusBackground,
  leasePeriod,
}) => {
  const classes = useStyles();
  return (
    <MenuItem onClick={onClick}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Avatar alt="Remy Sharp" src={src} />
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between">
            <Grid item xs={8}>
              <Typography variant="h6" className={classes.residentName}>
                {name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              className={classes.statusBadge}
              style={{ backgroundColor: statusBackground }}
              alignItems="center"
              justifyContent="center">
              <Typography variant="h6" className={classes.status}>
                {status}
              </Typography>
            </Grid>
            {description ? (
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.residentDescription}>
                  {description}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.residentDescription}>
                    {email}
                  </Typography>
                </Grid>
                <Grid item xs={6} alignItems={'flex-end'}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.residentDescription}>
                    {phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.residentProperty}>
                  {leasePeriod?.property?.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.residentLeasePeriod}>
                  {leasePeriod?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MenuItem>
  );
};
