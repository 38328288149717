import React from 'react';
import {
  TextField as FormikTextField,
  TextFieldProps as FormikTextFieldProps,
} from 'formik-material-ui';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

export const TextFieldDefault: React.FC<TextFieldProps> = ({
  variant = 'outlined',
  fullWidth = true,
  ...rest
}) => (
  <TextField
    variant={variant}
    fullWidth={fullWidth}
    InputLabelProps={{
      shrink: true,
    }}
    {...rest}
  />
);

export const TextFieldMultiline: React.FC<TextFieldProps> = ({
  variant = 'outlined',
  fullWidth = true,
  rows = 4,
  ...rest
}) => <TextField multiline rows={rows} variant={variant} {...rest} />;

export const FormikTextFieldDefault: React.FC<FormikTextFieldProps> = ({
  variant = 'outlined',
  fullWidth = true,
  ...rest
}) => (
  <FormikTextField
    variant={variant}
    fullWidth={fullWidth}
    InputLabelProps={{
      shrink: true,
    }}
    {...rest}
  />
);
