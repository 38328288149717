import { createEvent } from '@8baselabs/react-simple-state';
import {
  BedListResponse,
  LeasePeriodListResponse,
  PaymentPlanListResponse,
  PaymentPlan,
  Property,
  PropertyListResponse,
  PaymentPlanItem,
  SuccessResponse,
  LateFeeListResponse,
  Unit,
  OptionalPreferenceListResponse,
  OptionalPreferenceItem,
  RoomTypeListResponse,
  UnitTypeListResponse,
  LateFee,
  Building,
  OptionalPreferenceInvetory,
  Floor,
  Availability,
  ImportResidentsResponse,
  UnitEditResult,
  ClientUser,
  AvailabilityListResponse,
  SiteSettingListResponse,
  LedgerListResponse,
  Client,
  ClientPage,
} from '@8baselabs/resident-io-shared';
import {
  LeasePeriod,
  LeaseSubPeriodListResponse,
  OptionalPreference,
} from '../../schema-types';
import { BuildingType, SchoolCompanySubdomainResponse } from './settings-types';

export const OnSaveProperty = createEvent<Property>();
export const OnSavePropertyError = createEvent<Error>();

export const OnSaveSubdomain = createEvent<Client>();
export const OnSaveSubdomainError = createEvent<Error>();

export const OnGetClientPageData = createEvent<ClientPage>();
export const OnGetClientPageDataError = createEvent<Error>();

export const OnGetProperties = createEvent<PropertyListResponse | undefined>();
export const OnGetPropertiesError = createEvent<Error>();
export const OnSelectProperty = createEvent<Property>();
export const OnSelectProperties = createEvent<Property[]>({
  initialValue: [],
});

export const OnLeasePeriodSelected = createEvent<LeasePeriod | undefined>();
export const OnLeasePeriodSelectedId = createEvent<{leaseId: string | undefined}>();


export const OnGetSubdomainAvailability =
  createEvent<SchoolCompanySubdomainResponse['getSchoolCompanyBySubdomain']>();
export const OnGetSubdomainAvailabilityError = createEvent<Error>();

export const OnGetPropertiesSubdomains = createEvent<PropertyListResponse>();
export const OnGetPropertiesSubdomainsError = createEvent<Error>();

export const OnChangeClientSelected = createEvent<string | undefined>();

export const OnGetBuildingLayout = createEvent<BuildingType[]>();
export const OnGetBuildingLayoutError = createEvent<Error>();
export const OnGetPropertyInformation = createEvent<Property>();
export const OnGetPropertyInformationError = createEvent<Error>();

export const OnUnitCreated = createEvent<Unit | undefined>();
export const OnUnitCreatedError = createEvent<Error>();

export const OnImportResidentsEvent = createEvent<ImportResidentsResponse>();
export const OnImportResidentsEventError = createEvent<Error>();

export const OnUnitEdited = createEvent<UnitEditResult | undefined>();
export const OnUnitEditedError = createEvent<Error>();

export const OnUnitDeleted = createEvent<void>();
export const OnUnitDeletedError = createEvent<Error>();

export const OnBuildingUpdated = createEvent<Building | undefined>();
export const OnBuildingUpdatedError = createEvent<Error>();

export const OnBuildingDeleted = createEvent<void>();
export const OnBuildingDeletedError = createEvent<Error>();

export const OnFloorAdded = createEvent<Floor | undefined>();
export const OnFloorAddedError = createEvent<Error>();

export const OnFloorUpdated = createEvent<Floor | undefined>();
export const OnFloorUpdatedError = createEvent<Error>();

export const OnFloorDeleted = createEvent<void>();
export const OnFloorDeletedError = createEvent<Error>();

export const OnLeasePeriodList = createEvent<
  LeasePeriodListResponse | undefined
>();
export const OnLeasePeriodListError = createEvent<Error>();

export const DialogLeasePeriodList = createEvent<
  LeasePeriodListResponse | undefined
>();
export const DialogLeasePeriodListError = createEvent<Error>();

export const OnLeasePeriodRangeDatesList = createEvent<
  LeasePeriodListResponse | undefined
>();
export const OnLeasePeriodRangeDatesListError = createEvent<Error>();
export const OnUpdateClientUser = createEvent<ClientUser | undefined>();
export const OnUpdateClientUserError = createEvent<Error>();
export const OnLeasePeriodCreated = createEvent<void>();
export const OnLeasePeriodCreatedError = createEvent<Error>();
export const OnLeasePeriodUpdated = createEvent<void>();
export const OnLeasePeriodUpdatedError = createEvent<Error>();
export const OnLeasePeriodDeleted = createEvent<void>();
export const OnLeasePeriodDeletedError = createEvent<Error>();
export const OnPeriodSubPeriodRelationDeleted = createEvent<void>();
export const OnPeriodSubPeriodRelationDeletedError = createEvent<Error>();
export const onDeleteManyOptionalPreferenceEvent = createEvent<void>();
export const onDeleteManyOptionalPreferenceEventError = createEvent<Error>();
export const onDeleteManyPaymentPlanEvent = createEvent<void>();
export const onDeleteManyPaymentPlanEventError = createEvent<Error>();
export const OnSubperiodList = createEvent<LeaseSubPeriodListResponse>();
export const OnSubperiodListError = createEvent<Error>();
export const OnSubperiodCreated = createEvent<void>();
export const OnSubperiodCreatedError = createEvent<Error>();
export const OnSubperiodUpdated = createEvent<void>();
export const OnSubperiodUpdatedError = createEvent<Error>();
export const OnSubperiodDeleted = createEvent<void>();
export const OnSubperiodDeletedError = createEvent<Error>();

export const OnBuildingLayoutCreated = createEvent<string | undefined>();
export const OnBuildingLayoutCreatedError = createEvent<Error>();

export const OnBuildingLayoutDeleted = createEvent<void>();
export const OnBuildingLayoutDeleteddError = createEvent<Error>();

export const CsvBuildings = createEvent<string[]>({
  initialValue: [],
});
export const OnBuildingLayoutLoaded = createEvent<string>({
  initialValue: '',
});
export const OnBuildingLayoutLoadedError = createEvent<Error>();

export const OnBuildingsDelete = createEvent<void>();
export const OnBuildingsDeleteError = createEvent<Error>();

export const OnBuildingCreated = createEvent<Building | undefined>();
export const OnBuildingCreatedError = createEvent<Error>();

export const onPropertyUpdateEvent = createEvent();
export const onPropertyUpdateEventError = createEvent<Error>();

export const onFetchPaymentPlansEvent = createEvent<PaymentPlanListResponse>();
export const onFetchPaymentPlansEventError = createEvent<Error>();

export const onCreatePaymentPlanItemEvent = createEvent<PaymentPlanItem>();
export const onCreatePaymentPlanItemEventError = createEvent<Error>();
export const onDeletePaymentPlanEvent = createEvent<SuccessResponse>();
export const onDeletePaymentPlanEventError = createEvent<Error>();
export const onUpdatePaymentPlanItemEvent = createEvent<PaymentPlanItem>();
export const onUpdatePaymentPlanItemEventError = createEvent<Error>();
export const onUpdatePaymentPlanEvent = createEvent<PaymentPlan>();
export const onUpdatePaymentPlanEventError = createEvent<Error>();
export const onDeletePaymentPlanItemEvent = createEvent<SuccessResponse>();
export const onDeletePaymentPlanItemEventError = createEvent<Error>();
export const onDeleteManyPaymentPlanItemEvent = createEvent<void>();
export const onDeleteManyPaymentPlanItemEventError = createEvent<Error>();
export const onFetchPaymentPlanEvent = createEvent<PaymentPlan>();
export const onFetchPaymentPlanEventError = createEvent<Error>();
export const onFetchRoomTypesEvent = createEvent<RoomTypeListResponse>();
export const onFetchRoomTypesEventError = createEvent<Error>();
export const onFetchUnitTypesEvent = createEvent<UnitTypeListResponse>();
export const onFetchUnitTypesEventError = createEvent<Error>();
export const onFetchOptionalPreferencesEvent =
  createEvent<OptionalPreferenceListResponse>();
export const onFetchOptionalPreferencesEventError = createEvent<Error>();

export const onCreateOptionalPreferenceItemEvent =
  createEvent<OptionalPreferenceItem>();
export const onCreateOptionalPreferenceItemEventError = createEvent<Error>();
export const onDeleteOptionalPreferenceEvent = createEvent<SuccessResponse>();
export const onDeleteOptionalPreferenceEventError = createEvent<Error>();
export const onUpdateOptionalPreferenceItemEvent =
  createEvent<OptionalPreferenceItem>();
export const onUpdateOptionalPreferenceItemEventError = createEvent<Error>();
export const onUpdateOptionalPreferenceEvent =
  createEvent<OptionalPreference>();
export const onUpdateOptionalPreferenceEventError = createEvent<Error>();
export const onDeleteOptionalPreferenceItemEvent =
  createEvent<SuccessResponse>();
export const onDeleteOptionalPreferenceItemEventError = createEvent<Error>();
export const onDeleteManyOptionalPreferenceItemEvent = createEvent<void>();
export const onDeleteManyOptionalPreferenceItemEventError =
  createEvent<Error>();
export const onFetchOptionalPreferenceEvent = createEvent<OptionalPreference>();
export const onFetchOptionalPreferenceEventError = createEvent<Error>();

export const onFetchPaymentPlansWithoutLeaseEvent =
  createEvent<PaymentPlanListResponse>();
export const onFetchPaymentPlansWithoutLeaseEventError = createEvent<Error>();

export const SelectedPaymentPlan = createEvent<{
  name: string;
  // title: string;
  plan?: PaymentPlan;
}>();
export const SelectedOptionalPreference = createEvent<{
  name: string;
  title: string;
  description: string;
  optionalPreference?: OptionalPreference;
}>();
export const SelectedPaymentPlantItem = createEvent<PaymentPlanItem>();
export const SelectedOptionalPreferencetItem =
  createEvent<OptionalPreferenceItem>();

export const onOptionalPreferenceCreatedEvent = createEvent();
export const onOptionalPreferenceCreatedEventError = createEvent<Error>();

export const onFetchBedsWithOptionalPreferencesEvent =
  createEvent<BedListResponse>();
export const onFetchBedsWithOptionalPreferencesEventError =
  createEvent<Error>();

export const onBedOptionalPreferenceCreatedEvent = createEvent();
export const onBedOptionalPreferenceCreatedEventError = createEvent<Error>();

export const onBedOptionalPreferenceDeletedEvent = createEvent();
export const onBedOptionalPreferenceDeletedEventError = createEvent<Error>();

export const onBedPaymentPlanCreatedEvent = createEvent();
export const onBedPaymentPlanCreatedEventError = createEvent<Error>();

export const onBedPaymentPlanDeletedEvent = createEvent();
export const onBedPaymentPlanDeletedEventError = createEvent<Error>();
export const onPaymentPlanCreatedEvent = createEvent();
export const onPaymentPlanCreatedEventError = createEvent<Error>();

export const OnUnitUpdated = createEvent<Unit>();
export const OnUnitUpdatedError = createEvent<Error>();

export const OnGetPropertyData = createEvent<Property>();
export const OnGetPropertyDataError = createEvent<Error>();

export const OnGetPropertyDataForSettings = createEvent<Property>();
export const OnGetPropertyDataForSettingsError = createEvent<Error>();

export const OnGetPropertySiteSettings = createEvent<SiteSettingListResponse>();
export const OnGetPropertySiteSettingsError = createEvent<Error>();

export const onPropertySiteSettingsUpdate = createEvent();
export const onPropertySiteSettingsUpdateError = createEvent<Error>();

export const onCreatePropertySiteSettings = createEvent();
export const onCreatePropertySiteSettingsError = createEvent<Error>();

export const onPropertyPageUpdate = createEvent();
export const onPropertyPageUpdateError = createEvent<Error>();

export const OnGetPropertyLateFees = createEvent<LateFeeListResponse>();
export const OnGetPropertyLateFeesError = createEvent<Error>();

export const OnUpdateLateFee = createEvent<LateFee>();
export const OnUpdateLateFeeError = createEvent<Error>();

export const OnCreateLateFee = createEvent<LateFee>();
export const OnCreateLateFeeError = createEvent<Error>();

export const OnEditLateFee = createEvent<LateFee>();
export const OnEditLateFeeError = createEvent<Error>();

export const OnDeleteLateFee = createEvent<boolean>();
export const OnDeleteLateFeeError = createEvent<Error>();

export const onMutateAvailability = createEvent<Availability | undefined>();
export const onMutateAvailabilityError = createEvent<Error>();

export const onSaveOptionalPreferenceInventory =
  createEvent<OptionalPreferenceInvetory>();
export const onSaveOptionalPreferenceInventoryError = createEvent<Error>();
export const onSaveOptionalPreferenceAdditionalData =
  createEvent<OptionalPreference>();
export const onSaveOptionalPreferenceAdditionalDataError = createEvent<Error>();

export const onCopy = createEvent({ initialValue: false });

export const OnGetPropertyFilterItems = createEvent<Property>();
export const OnGetPropertyFilterItemsError = createEvent<Error>();

export const OnGetAvailabilityList = createEvent<AvailabilityListResponse>();
export const OnGetAvailabilityListError = createEvent<Error>();

export const OnGetAllAvailabilities = createEvent<AvailabilityListResponse>();
export const OnGetAllAvailabilitiesError = createEvent<Error>();

export const onFetchPaymentPlanAndOptionalPreferences = createEvent<{
  paymentPlansList: PaymentPlanListResponse;
  optionalPreferencesList: OptionalPreferenceListResponse;
}>();
export const onFetchPaymentPlanAndOptionalPreferencesError =
  createEvent<Error>();

export const OnUpdatePropertyDataEvent = createEvent();
export const OnUpdatePropertyDataEventError = createEvent<Error>();

export const OnFetchLedgerEvent = createEvent<LedgerListResponse>();
export const OnFetchLedgerError = createEvent<Error>();

export const OnFetchsubscriptionEvent = createEvent();
export const OnFetchsubscriptionError = createEvent<Error>();

export const SettingsProperties = createEvent<{ properties: Property[] }>({
  initialValue: { properties: [] },
});

export const OnGetAllAvailabilitiesCount =
  createEvent<AvailabilityListResponse>();
export const OnGetAllAvailabilitiesCountError = createEvent<Error>();

export const OnLeaseSubPeriodList = createEvent<
  LeaseSubPeriodListResponse | undefined
>();
export const OnLeaseSubPeriodListError = createEvent<Error>();
