import { User } from '@8baselabs/resident-io-shared';
import { createAction } from '@8baselabs/react-simple-state';
import moment from 'moment';
import { UPDATE_USER } from '../../profile/my-info/my-info-queries';
import {
  CURRENT_USER_QUERY,
  USER_SIGN_UP_MUTATION,
} from './auth-callback-queries';
import { authenticated, authenticatedError } from './auth-callback-event';
import { apolloClient } from '../../../shared/apollo';

export const handleAuthentication = createAction(
  authenticated,
  authenticatedError,
  async (user: { email?: string; createPM?: boolean }): Promise<boolean> => {
    const { email, createPM } = user;

    let user8base;
    try {
      const {
        data: { user: UserResponse },
      } = await apolloClient.query<{ user: User }>({
        query: CURRENT_USER_QUERY,
      });
      user8base = UserResponse;
    } catch (error) {
      const {
        data: { userSignUpWithToken: UserResponse },
      } = await apolloClient.mutate({
        mutation: USER_SIGN_UP_MUTATION,
        variables: {
          user: {
            email,

            // when the application supports client invitations here must be a condition to create or select a client
            userClientUserRelation: createPM
              ? {
                  create: {
                    client: {
                      create: {
                        name: '',
                      },
                    },
                  },
                }
              : undefined,
          },
        },
      });
      user8base = UserResponse;
      await apolloClient.cache.reset();
    } finally {
      if (user8base?.id) {
        const updateUserInput = {
          id: user8base?.id,
          lastLogin: moment().format('YYYY-MM-DD'),
        };
        await apolloClient.mutate({
          mutation: UPDATE_USER,
          variables: {
            data: updateUserInput,
          },
        });
      }
    }
    return true;
  },
);
