/* eslint-disable import/no-extraneous-dependencies */

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@mui/material/styles';
import { Box } from '@material-ui/core';
import { User } from '@8baselabs/resident-io-shared';
import { Alert } from '@material-ui/lab';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { useAutocomplete } from '@mui/material';
import { useEffect } from 'react';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
  width: 698px;
  height: 350px;
  display: flex;
  padding: 24px;
`,
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  width: 698px;
  display: flex;
  justify-content: space-between;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 698px;
  heigth: 100%;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.65)'
        : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

interface TagProps extends ReturnType<any> {
  label: string;
}

const Tag = (props: TagProps): React.ReactElement => {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
};

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

type PropertyUsers = Pick<
  User,
  'email' | 'firstName' | 'lastName' | 'guarantorData' | 'phoneNumber'
>;

interface Props {
  isEmail: boolean | undefined;
  availableRecipients: PropertyUsers[];
  onRecipientsSelection: (value: PropertyUsers[]) => void;
  recipientsSelected: PropertyUsers[];
  channelDisabledUsers: number;
  setDisabledNextButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MessageRecipients = ({
  availableRecipients,
  recipientsSelected,
  onRecipientsSelection,
  isEmail,
  channelDisabledUsers,
  setDisabledNextButton: setDisabled,
}: Props): React.ReactElement => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    defaultValue: recipientsSelected ?? [],
    multiple: true,
    options: availableRecipients ?? [],
    getOptionLabel: (option: PropertyUsers) => option.firstName ?? '',
  });

  useEffect(() => {
    setDisabled(value?.length <= 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Root>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}>
          <Box sx={{ lineHeight: '17px', fontSize: '14px', color: '#252631' }}>
            Send to
          </Box>
          <Box sx={{ lineHeight: '17px', fontSize: '14px', color: '#5D6E7F' }}>
            {value.length} Recipients
          </Box>
        </Label>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {(value as PropertyUsers[])?.map(
            (option: PropertyUsers, index: number) => {
              if (value?.length > 0) onRecipientsSelection(value);
              // eslint-disable-next-line array-callback-return
              if (value.indexOf(option) !== index) return;
              return (
                <StyledTag
                  label={
                    (isEmail ? option?.email : option?.phoneNumber) as string
                  }
                  {...getTagProps({ index })}
                />
              );
            },
          )}
          <input
            disabled={availableRecipients?.length === 0}
            {...getInputProps()}
          />
        </InputWrapper>
        {availableRecipients?.length !== 0 ? (
          <Listbox {...getListboxProps()}>
            {(groupedOptions as typeof availableRecipients)?.map(
              (option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span style={{ cursor: 'pointer' }}>{option.firstName}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ),
            )}
          </Listbox>
        ) : (
          <div>No recipients available</div>
        )}
        {channelDisabledUsers ? (
          <>
            <Box padding={1}>
              <Alert severity="error">
                You have selected users who have disabled this messaging
                channel.
              </Alert>
            </Box>
          </>
        ) : (
          <></>
        )}
      </div>
    </Root>
  );
};
