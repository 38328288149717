import ListItem from '@material-ui/core/ListItem';

import { MenuItem } from '@material-ui/core';
import { possessive } from 'i18n-possessive';
import { Client, Property } from '@8baselabs/resident-io-shared';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useMemo, useState, useEffect } from 'react';
import {
  useCallAction,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';

import { TextFieldDefault } from './ui/inputs/TextFieldDefault';

import { useSession } from '../../modules/session/session-hooks';
import { useLocalStorage } from './components-hooks';

import { selectClient } from './components-actions';
// import { fetchAllClients } from '../../modules/admin/admin-actions';
import { fetchPropertiesByFilter } from '../../modules/properties/properties-actions';

import { OnUpdateClient } from '../../modules/owner-settings/owner-settings-events';
import {
  OnChangeClientSelected,
  onPropertyUpdateEvent,
} from '../../modules/settings-view/settings-events';
import { OnSelectClient } from './components-events';
import { OnManyClientsDelete } from '../../modules/admin/admin-events';
import { useQuery } from '@apollo/client';
import { FETCH_ALL_CLIENTS_QUERY } from '../../modules/admin/admin-queries';

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      '& .MuiOutlinedInput-input': {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
  }),
);

type Props = {
  noPropertySelector?: boolean;
};

/**
 * Client selector component.
 *
 * @param props - Props.
 * @param props.noPropertySelector - NoPropertySelector.
 * @returns {JSX.Element} - Two dropdown selectors to choose client and propertoes.
 */
export const ClientSelector: React.FC<Props> = ({ noPropertySelector }) => {
  const classes = useStyles();
  const [clients, setClients] = useState<Client[]>([]);
  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedPropertyId, setSelectedPropertyId] = useLocalStorage(
    'selected-property-id',
    '',
  );

  const [session, , , , refetchSession] = useSession();
  const [selectedClient, setSelectedClient] = useState(
    session?.user?.selectedClient?.id ?? '',
  );

  const filter = useMemo(
    () => ({ client: { id: { equals: session?.user?.selectedClient?.id } } }),
    [session?.user?.selectedClient?.id],
  );

  const { data: clientsResponse, loading: loadingClientsResponse, refetch: refetchClients } = useQuery(FETCH_ALL_CLIENTS_QUERY, {
    fetchPolicy: 'cache-and-network', 
  });
  
  const [
    propertiesResponse,
    loadingPropertiesResponse,
    { refetch: refetchProperties },
  ] = useFetchAction(fetchPropertiesByFilter, [filter], {
    skip: !session?.user?.selectedClient?.id,
  });

  const [callSelectClient, loadingCallSelectClient] =
    useCallAction(selectClient);

  useEffect(() => {
    if (clientsResponse?.clientsList.items.length > 0) {
      setClients(clientsResponse.clientsList.items as Client[]);

      if (!session?.user?.selectedClient?.id) {
        callSelectClient(session?.user?.id, clientsResponse?.clientsList.items[0].id);
        if (clientsResponse?.clientsList.items[0].id) {
          setSelectedClient(clientsResponse?.clientsList.items[0].id);
        }
      } else {
        setSelectedClient(session?.user?.selectedClient?.id || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsResponse]);

  useEffect(() => {
    if (propertiesResponse?.length > 0) {
      setProperties(propertiesResponse as Property[]);
    }
  }, [propertiesResponse]);

  useSubscription(OnSelectClient, (updatedUser) => {
    refetchSession();
    setSelectedClient(updatedUser?.selectedClient?.id ?? '');
    setProperties(
      updatedUser?.selectedClient?.clientPropertyRelation?.items as Property[],
    );
    setSelectedPropertyId(
      updatedUser?.selectedClient?.clientPropertyRelation?.items[0]?.id || '',
    );
  });

  useSubscription(OnUpdateClient, () => {
    refetchClients();
  });

  useSubscription(OnManyClientsDelete, () => {
    refetchClients();
  });

  useSubscription(onPropertyUpdateEvent, () => {
    refetchProperties();
  });

  useEffect(() => {
    OnChangeClientSelected.dispatch(selectedClient || undefined);
  }, [selectedClient]);

  return (
    <>
      {clients.length > 0 && (
        <ListItem>
          <TextFieldDefault
            label="CLIENT"
            value={selectedClient}
            defaultValue=""
            onChange={(evt) => {
              callSelectClient(session?.user?.id, evt.target.value);
              setSelectedClient(evt.target.value);
            }}
            disabled={loadingCallSelectClient || loadingClientsResponse}
            className={classes.select}
            onError={() => {}}
            select>
            {clients.length > 0 &&
              clients.map((client) => {
                let name = client?.name;
                const firstName =
                  client?.clientClientUserRelation?.items?.[0]?.user?.firstName;
                const email =
                  client?.clientClientUserRelation?.items?.[0]?.user?.email;

                if (name) {
                  //
                } else if (firstName) {
                  name = `${possessive(firstName || '', 'en')} Company`;
                } else if (email) {
                  name = `${possessive(email || '', 'en')} Company`;
                } else {
                  name = `Unnamed Client @ ${client?.id}`;
                }

                return (
                  <MenuItem value={client.id} key={client.id}>
                    {name}
                  </MenuItem>
                );
              })}
          </TextFieldDefault>
        </ListItem>
      )}
      {properties.length > 0 && !noPropertySelector && (
        <ListItem>
          <TextFieldDefault
            label="PROPERTY"
            className={classes.select}
            disabled={
              !session?.user?.selectedClient?.id ||
              loadingCallSelectClient ||
              loadingPropertiesResponse
            }
            value={selectedPropertyId || ''}
            defaultValue=""
            onChange={(evt) => {
              setSelectedPropertyId(evt.target.value);
            }}
            suppressHydrationWarning
            select>
            {properties.length > 0 &&
              properties.map((property) => (
                <MenuItem value={property.id} key={property.id}>
                  {property.name}
                </MenuItem>
              ))}
          </TextFieldDefault>
        </ListItem>
      )}
    </>
  );
};
