import { useEvent } from '@8baselabs/react-simple-state';
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { OnFetchSession } from '../modules/session/session-events';

const NotFound: React.FC = () => {
  const { replace } = useHistory();
  useEffect(() => {
    if (!replace) return;
    replace('/');
    window.location.reload();
  }, [replace]);
  return <>view not allowed</>;
};

export const ProtectedRoute: React.FC<
  RouteProps & { key?: string; role: string[]; element: JSX.Element }
> = ({ element, key, role, ...rest }) => {
  const session = useEvent(OnFetchSession);

  const isValidRole = role.includes(
    (session?.user?.userClientUserRelation?.items?.[0]?.role ||
      session?.user?.userType) ??
      '',
  );
  return (
    <Route {...rest} key={key}>
      {isValidRole ? element : <NotFound />}
    </Route>
  );
};
