import { Redirect, useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/types';

export const CreatePM: React.FC = () => {
  const history = useHistory();

  localStorage.setItem('create-pm', history.location.search);

  return (
    <Redirect
      to={{
        pathname: '/auth',
        state: {
          returnTo: `${ROUTES.settings.path}${history.location.search}`,
        },
      }}
    />
  );
};
