import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useCallAction } from '@8baselabs/react-simple-state';
import {
  Lease,
  DocumentType,
  RequiredFile,
} from '@8baselabs/resident-io-shared';
import { useForm } from 'react-hook-form';
import { makeStyles, MenuItem } from '@material-ui/core';
import { FileValue } from '@8base-react/file-input';
import { createRequiredFile } from '../../opportunities/opportunities-actions';
import { Box, CircularProgress } from '@mui/material';
import { AlertContext } from '../../../routes/AlertContext';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { FilestackUploadButton } from '../../../shared/components/FilestackUploadButton';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';

const useStyles = makeStyles(() => ({
  upload: {
    cursor: 'pointer',
    width: '553px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  files: {
    width: '553px',
    height: '100px',
  },
  uploadButton: {
    backgroundColor: '#E8ECEF',
    borderRadius: '4px',
  },
}));
type PaymentPlanDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  leaseSelected?: Lease | undefined;
  requiredUploads?: DocumentType[];
  uploadedFiles?: RequiredFile[];
  userId?: string;
  RefetchFiles?: () => void;
};
const initialValues = {
  owner: '',
  type: '',
};
export const UploadDocumentModal: React.FC<PaymentPlanDialogProps> = ({
  isOpen,
  onClose,
  leaseSelected,
  uploadedFiles,
  requiredUploads,
  userId = '',
  RefetchFiles,
}) => {
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const [file, setFile] = useState<FileValue | null>();
  const [docId, setDocId] = useState<string>();
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });
  const handleClose = (): void => {
    onClose();
    setFile(null);
    setValue('owner', '');
  };

  const [createRequiredUploadFile, loadingCreation] = useCallAction(
    createRequiredFile,
    {
      onCompleted: () => {
        handleClose();
        RefetchFiles?.();
      },
    },
  );

  const handleSave = (): void => {
    if (file) {
      createRequiredUploadFile({
        documentId: docId as string,
        file,
        type: watch('owner') === 'Tenant' ? 'TENANT' : 'GUARANTOR',
        userId,
        requiredUploadId: '',
      });
      onClose();
      setFile(null);
      setValue('owner', '');
      alert({
        success: true,
        message: 'Document successfully created',
      });
    } else {
      alert({
        error: true,
        message: 'Please upload a document',
      });
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Upload Documents</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              select
              required={true}
              label="OWNER TYPE"
              {...register('owner', { required: true })}
              error={Boolean(errors.owner)}
              helperText={errors.owner?.message}>
              <MenuItem value="Tenant">Resident</MenuItem>
              <MenuItem value="Guarantor">Guarantor</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required={true}
              disabled={
                watch('owner') === '' ||
                (requiredUploads?.filter((doc) =>
                  watch('owner') === 'Tenant'
                    ? doc.isTenantDocument === true
                    : doc.isGuarantorDocument === true,
                ).length || 0) === 0
              }
              select
              label="DOCUMENT TYPE"
              {...register('type', { required: true })}
              error={Boolean(errors.type)}
              helperText={errors.type?.message}>
              {(requiredUploads?.filter((doc) =>
                watch('owner') === 'Tenant'
                  ? doc.isTenantDocument === true
                  : doc.isGuarantorDocument === true,
              ).length || 0) === 0 && <MenuItem value="">Empty</MenuItem>}
              {requiredUploads
                ?.filter(
                  (doc) =>
                    (watch('owner') === 'Tenant'
                      ? doc.isTenantDocument === true
                      : doc.isGuarantorDocument === true) &&
                    uploadedFiles?.filter(
                      (upF) =>
                        upF.type === watch('owner').toUpperCase() &&
                        upF.requiredFileDocumentTypeRelation?.name === doc.name,
                    ).length === 0,
                )
                .map((type) => (
                  <MenuItem
                    key={type.id}
                    onClick={() => setDocId(type.id)}
                    value={type.name}>
                    {type.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            {file ? (
              <Box className={classes.files}>
                <Typography key={file.fileId} variant="body1">
                  {file.filename}
                </Typography>
              </Box>
            ) : !loadingCreation ? (
              <>
                {/*
                TO DO: I Have to check with diego about this logic.
                onClick=
                {(requiredUploads?.filter((doc) =>
                  watch('owner') === 'Tenant'
                    ? doc.isTenantDocument === true
                    : doc.isGuarantorDocument === true,
                ).length || 0) === 0
                  ? () => {
                      alert({
                        message: 'No documents are required for this lease.',
                        error: true,
                      });
                    }
                  : handleDocument} */}
                <Box
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  className={classes.upload}>
                  <FilestackUploadButton
                    accept={['application/pdf']}
                    maxFiles={1}
                    onUploadDone={(document) =>
                      setFile(document[0] as FileValue)
                    }
                  />
                </Box>
                <Box
                  justifyContent="center"
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                  className={classes.uploadButton}>
                  <Button
                    type="button"
                    onClick={
                      (requiredUploads?.filter((doc) =>
                        watch('owner') === 'Tenant'
                          ? doc.isTenantDocument === true
                          : doc.isGuarantorDocument === true,
                      ).length || 0) === 0
                        ? () => {
                            alert({
                              message: 'No documents are required.',
                              error: true,
                            });
                          }
                        : () => {}
                    }>
                    <PublishOutlinedIcon fontSize="small" />
                    {'UPLOAD FILES'}
                  </Button>
                </Box>
              </>
            ) : (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth={false}
          onClick={handleClose}
          disabled={loadingCreation}>
          Cancel
        </Button>
        <ButtonAction
          fullWidth={false}
          style={{ marginRight: '14px' }}
          disabled={!watch('owner') || !watch('type') || loadingCreation}
          onClick={handleSave}
          isLoading={loadingCreation}>
          Submit
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
