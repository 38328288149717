import React, { useContext, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import { SearchOutlined } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  useCallAction,
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import { Gender, Lease, User } from '@8baselabs/resident-io-shared';
import {
  onTransferLeaseCreate,
  TransferReservationExistentResidentEvent,
  TransferReservationSelectResidentEvent,
} from '../../tenant-events';
import { fetchResidentsAction } from '../../../opportunities/opportunities-actions';
import { useFormik } from 'formik';
import { useSession } from '../../../session/session-hooks';
import { TextFieldDefault } from '../../../../shared/components/ui/inputs/TextFieldDefault';
import { Grid, MenuItem, Typography } from '@mui/material';
import { TextFieldPhone } from '../../../../shared/components/ui/inputs/TextFieldPhone';
import * as yup from 'yup';
import { ButtonAction } from '../../../../shared/components/ui/buttons/ButtonAction';
import { AddReservationResidentsEvent } from '../../../opportunities/opportunities-event';
import { MUTED_COLOR } from '../../../../shared/theme';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { AlertContext } from '../../../../routes/AlertContext';
import {
  fetchTransferLeaseByLeaseID,
  transferCreateAction,
} from '../../tenant-actions';
import { MainLoader } from '../../../../shared/components/MainLoader';

const useStyles = makeStyles(() =>
  createStyles({
    inputs: {
      width: '100%',
    },
    existentResidentDatePicker: {
      marginTop: '1.5rem',
    },
  }),
);
interface Props {
  selectedLease: Lease;
  userId?: string;
}

const validationSchema = yup.object({
  firstName: yup.string().required('Firstname is required').nullable(),
  lastName: yup.string().required('Lastname is required').nullable(),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required')
    .nullable(),
  phoneNumber: yup.string().required('Phone number is required'),
  gender: yup.string().required('Gender is required').nullable(),
  startDate: yup.date().required('Contract Start Date is required').nullable(),
});

/**
 * @param selectedLease - Lease.
 * @param selectedLease.selectedLease - Undefined.
 * @param selectedLease.userId - UserID from params.
 * @returns {React.FC} - Transfer Info sub view.
 */
export const TransferInfo: React.FC<Props> = ({ selectedLease, userId }) => {
  const classes = useStyles();
  const alertSnackbar = useContext(AlertContext);
  const [dateToTransfer, setDateToTransfer] = React.useState<string>();
  const [session] = useSession();
  const [open, setOpen] = React.useState(false);
  const [minDate, setMinDate] = React.useState<string>();
  const [residentInfo, setResidentInfo] = React.useState<User>();
  const [inputValue, setInputValue] = React.useState('');
  const existResident = useEvent(TransferReservationExistentResidentEvent);

  /* const [, loadingPeopleUser] = useFetchAction(fetchUserActions, [userId as string], {
    skip: !userId
  }); */
  const [transferCreate, loadingTransferLease] = useCallAction(
    transferCreateAction,
    {
      onCompleted: () => {
        alertSnackbar({
          success: true,
          message: 'Transfer invitation sent successfully',
        });
      },
    },
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    gender: 'ANY',
    phoneNumber: '',
    startDate: selectedLease?.startDate,
    inviter: userId ? userId : session?.user.id,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: !existResident ? validationSchema : undefined,
    enableReinitialize: false,
    onSubmit: (values, action) => {
      if (existResident) {
        transferCreate({
          firstName: (residentInfo?.firstName as string) ?? '',
          lastName: (residentInfo?.lastName as string) ?? '',
          phoneNumber: residentInfo?.phoneNumber ?? '',
          gender: (residentInfo?.gender as string) ?? '',
          startDate: dateToTransfer as string,
          status: 'PENDING',
          newResidentEmail: (residentInfo?.email as string) ?? '',
          inviter: {
            connect: {
              id: userId ? userId : session?.user.id,
            },
          },
          lease: {
            connect: {
              id: selectedLease?.id as string,
            },
          },
        });
        return;
      }
      transferCreate({
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        gender: values.gender,
        startDate: values.startDate as string,
        status: 'PENDING',
        newResidentEmail: values.email,
        inviter: {
          connect: {
            id: userId ? userId : (session?.user.id as string),
          },
        },
        lease: {
          connect: {
            id: selectedLease?.id as string,
          },
        },
      });
    },
  });

  const [
    transferLeaseCreated,
    loadingPreviousTransferLease,
    { refetch: refetchTransferLease },
  ] = useFetchAction(
    fetchTransferLeaseByLeaseID,
    [selectedLease?.id as string],
    {
      onCompleted(data) {
        if (data.length === 0 || data[0].status === 'REJECTED') return;
        const transferLease = data[0];
        formik.setValues({
          firstName: (transferLease?.firstName as string) ?? '',
          lastName: (transferLease?.lastName as string) ?? '',
          phoneNumber: transferLease?.phoneNumber ?? '',
          gender: (transferLease?.gender as string) ?? '',
          startDate: moment(transferLease.startDate as string).toISOString(),
          email: (transferLease?.newResidentEmail as string) ?? '',
          inviter: (transferLease?.inviter?.id as string) ?? '',
        });
      },
    },
  );

  useSubscription(onTransferLeaseCreate, () => {
    refetchTransferLease();
  });

  useEffect(() => {
    const minDateToTranser =
      moment(new Date()).valueOf() < moment(selectedLease?.startDate).valueOf()
        ? moment(selectedLease?.startDate).toISOString()
        : moment(new Date()).add(1, 'day').toISOString();
    formik.setFieldValue('inviter', userId ? userId : session?.user.id);
    formik.setFieldValue('startDate', minDateToTranser);
    setDateToTransfer(minDateToTranser);
    setMinDate(minDateToTranser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, selectedLease]);

  const [fetchResidents, loading, { data }] =
    useCallAction(fetchResidentsAction);

  useSubscription(AddReservationResidentsEvent, (userData) => {
    if (!userData) return;
    setResidentInfo(userData[0]);
  });

  useEffect(() => {
    fetchResidents(inputValue);
  }, [fetchResidents, inputValue]);

  const handleChangeRadio = (value: boolean): void => {
    TransferReservationExistentResidentEvent.dispatch(value);
  };

  if (loadingPreviousTransferLease) <MainLoader />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Radio
          checked={existResident}
          onChange={() => handleChangeRadio(true)}
          color="primary"
        />
        <Typography variant="body1" display="inline">
          Search Existing Resident
        </Typography>
      </Grid>
      {existResident ? (
        <Grid item xs={12}>
          <Autocomplete
            id="resident-async"
            fullWidth
            disabled={transferLeaseCreated?.length !== 0}
            className={classes.inputs}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              `${option.firstName !== null ? option.firstName : 'Not'} ${
                option.lastName !== null ? option.lastName : 'Completed'
              }  ${option.email !== null ? '(' + option.email + ')' : ''}` ??
              `User ${option.id}`
            }
            options={data ?? []}
            loading={loading}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              TransferReservationSelectResidentEvent.dispatch(newValue);
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  label="SEARCH EXISTING RESIDENT"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="search resident" edge="end">
                          <SearchOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <KeyboardDatePicker
                  disabled={transferLeaseCreated?.length !== 0}
                  clearable
                  fullWidth
                  variant="inline"
                  autoOk
                  shouldDisableDate={(date) => date === new Date()}
                  minDate={minDate}
                  maxDate={moment(selectedLease?.endDate).toISOString()}
                  inputVariant="outlined"
                  label={`CONTRACT START DATE (MUST BE ON OR AFTER ${moment(
                    minDate,
                  ).format('MMMM DD, yyyy')}`}
                  format="MMMM dd, yyyy"
                  name="startDate"
                  className={classes.existentResidentDatePicker}
                  onChange={(date, value) => {
                    setDateToTransfer(moment(value).toISOString());
                  }}
                  value={moment(dateToTransfer).toISOString()}
                  InputAdornmentProps={{
                    style: {
                      borderLeft: `1px solid ${MUTED_COLOR}`,
                      height: 'auto',
                    },
                  }}
                  // inputProps={{ disabled: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={existResident}
                />
              </>
            )}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Radio
          checked={!existResident}
          onChange={() => handleChangeRadio(false)}
          color="primary"
        />
        <Typography variant="body1" display="inline">
          Add New Resident
        </Typography>
      </Grid>
      {!existResident ? (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldDefault
                disabled={transferLeaseCreated?.length !== 0}
                label="FIRST NAME"
                placeholder="Enter first name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldDefault
                disabled={transferLeaseCreated?.length !== 0}
                label="LAST NAME"
                placeholder="Enter last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldDefault
                disabled={transferLeaseCreated?.length !== 0}
                select
                label="GENDER"
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}>
                {Object.entries(Gender).map((obj) => {
                  if (obj[1] === 'FIRST_COME' || obj[1] === 'OTHER')
                    return null;
                  return <MenuItem value={obj[1]}>{obj[0]}</MenuItem>;
                })}
              </TextFieldDefault>
            </Grid>
            <Grid item xs={6}>
              <TextFieldPhone
                disabled={transferLeaseCreated?.length !== 0}
                label="PHONE"
                name="phoneNumber"
                defaultCountry="us"
                value={formik.values.phoneNumber}
                onChange={(value) => {
                  formik.setFieldValue('phoneNumber', value);
                }}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors?.phoneNumber)
                }
                helperText={
                  formik.touched?.phoneNumber && formik.errors?.phoneNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldDefault
                disabled={transferLeaseCreated?.length !== 0}
                placeholder="user@mail.com"
                label="EMAIL"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                disabled={transferLeaseCreated?.length !== 0}
                clearable
                fullWidth
                variant="inline"
                autoOk
                shouldDisableDate={(date) => date === new Date()}
                minDate={minDate}
                maxDate={moment(selectedLease?.endDate).toISOString()}
                inputVariant="outlined"
                label={`CONTRACT START DATE (MUST BE ON OR AFTER ${moment(
                  minDate,
                ).format('MMMM DD, yyyy')}`}
                format="MMMM dd, yyyy"
                name="startDate"
                onChange={(date, value) => {
                  formik.setFieldValue(
                    'startDate',
                    moment(value).toISOString(),
                  );
                }}
                value={moment(formik.values.startDate).toISOString()}
                InputAdornmentProps={{
                  style: {
                    borderLeft: `1px solid ${MUTED_COLOR}`,
                    height: 'auto',
                  },
                }}
                // inputProps={{ disabled: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {transferLeaseCreated?.length !== 0 && (
        <Grid item xs={12}>
          <Typography fontWeight={500} color="primary">
            You have already transfer this lease to{' '}
            {transferLeaseCreated?.[0]?.firstName}{' '}
            {transferLeaseCreated?.[0]?.lastName}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="flex-end"
        alignItems="center">
        <ButtonAction
          fullWidth={false}
          disabled={transferLeaseCreated?.length !== 0}
          isLoading={loadingTransferLease}
          onClick={() => formik.handleSubmit()}>
          Transfer
        </ButtonAction>
      </Grid>
    </Grid>
  );
};
