import { Box, BoxProps, CircularProgress } from '@material-ui/core';

export const Loading: React.FC<{ size?: number }> = ({ size = 18 }) => (
  <Box marginRight={2} display="flex" alignItems="center">
    <CircularProgress size={size} />
  </Box>
);

export const LoadingContainer: React.FC<BoxProps> = (props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    {...props}>
    {props.children}
  </Box>
);
