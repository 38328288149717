import { createAction } from '@8baselabs/react-simple-state';
import { OnFetchSession, OnFetchSessionError } from './session-events';
import { FETCH_SESSION_QUERY, SESSION_SUBSCRIPTION } from './session-queries';
import { apolloClient as client } from '../../shared/apollo';
import { FetchSessionData, SessionSubscription } from './session-types';
import { createSubscription } from '../../shared/apollo/apollo-utils';

/**
 * @description - fetch session.
 * @returns {void}.
 */

export const fetchSession = createAction(
  OnFetchSession,
  OnFetchSessionError,
  async () => {
    try {
      const session = (
        await client.query<FetchSessionData>({
          query: FETCH_SESSION_QUERY,
        })
      ).data;
      return session;
    } catch (err) {
      const session = (
        await client.query<FetchSessionData>({
          query: FETCH_SESSION_QUERY,
        })
      ).data;
      return session;
    }
  },
);

export const sessionSubscription = createSubscription<
  SessionSubscription,
  { user: string }
>(SESSION_SUBSCRIPTION);
