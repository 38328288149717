import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { NavigateAction, View } from 'react-big-calendar';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import {
  PRIMARY_COLOR,
  BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../shared/theme';

const navigateConstants = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

const viewsConstants = {
  MONTH: 'month',
  WEEK: 'week',
  WORK_WEEK: 'work_week',
  DAY: 'day',
  AGENDA: 'agenda',
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      marginLeft: 5,
      color: PRIMARY_TEXT_COLOR,
      width: 120,
      backgroundColor: BACKGROUND_COLOR,
      '&:hover': {
        backgroundColor: PRIMARY_COLOR,
        color: 'white',
      },
      fontWeight: 600,
    },
    selectedButton: {
      marginLeft: 5,
      width: 120,
      backgroundColor: PRIMARY_COLOR,
      '&:hover': {
        backgroundColor: PRIMARY_COLOR,
      },
      color: 'white',
      fontWeight: 600,
    },
    buttonContainer: {
      flexDirection: 'row',
      display: 'flex',
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'flex',
      marginBottom: 10,
    },
  }),
);

type CalendarToolbarProps = {
  onNavigate: (navigate: NavigateAction) => void;
  onView: (view: View) => void;
  view: View;
  label: string;
};

export const CalendarToolbar: React.FC<CalendarToolbarProps> = (props) => {
  const { onNavigate, onView, label, view } = props;
  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.container}
        direction="row"
        justifyContent="space-around">
        <Grid className={classes.buttonContainer} xs={5}>
          <ButtonAction
            className={classes.button}
            size="small"
            type="button"
            style={{}}
            onClick={() =>
              onNavigate(navigateConstants.PREVIOUS as NavigateAction)
            }>
            {'Previous'}
          </ButtonAction>
          <ButtonAction
            className={classes.button}
            size="small"
            type="button"
            onClick={() =>
              onNavigate(navigateConstants.TODAY as NavigateAction)
            }>
            {'Today'}
          </ButtonAction>
          <ButtonAction
            className={classes.button}
            size="small"
            type="button"
            onClick={() => onNavigate(navigateConstants.NEXT as NavigateAction)}
            style={{}}>
            {'Next'}
          </ButtonAction>
        </Grid>

        <Grid style={{ fontWeight: 500, alignItems: 'center' }}>{label}</Grid>

        <Grid
          style={{
            justifyContent: 'flex-end',
          }}
          className={classes.buttonContainer}
          xs={5}>
          <ButtonAction
            type="button"
            size="small"
            className={
              view === 'month' ? classes.selectedButton : classes.button
            }
            style={{}}
            onClick={() => {
              onView(viewsConstants.MONTH as View);
            }}>
            Month
          </ButtonAction>
          <ButtonAction
            type="button"
            size="small"
            className={
              view === 'week' ? classes.selectedButton : classes.button
            }
            style={{}}
            onClick={() => {
              onView(viewsConstants.WEEK as View);
            }}>
            Week
          </ButtonAction>
          <ButtonAction
            type="button"
            size="small"
            className={
              view === 'day' ? classes.selectedButton : classes.button
            }
            style={{}}
            onClick={() => {
              onView(viewsConstants.DAY as View);
            }}>
            Day
          </ButtonAction>
        </Grid>
      </Grid>
    </>
  );
};
