import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * Local storage custom hook.
 *
 * @param key - The local storage key.
 * @param initialValue - The initial value for the item.
 * @returns {void}
 */
const getVal = <T>(key: string, initialValue: T) => {
  if (typeof window === 'undefined') {
    return initialValue;
  }

  try {
    const item = window.localStorage.getItem(key);

    return item ? (JSON.parse(item) as T) : initialValue;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);

    return initialValue;
  }
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, SetValue<T>] {
  const readValue = getVal(key, initialValue);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = (value) => {
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, JSON.stringify(newValue));

      setStoredValue(newValue);

      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  const handleSetValue = useCallback((value) => {
    if (value) {
      setStoredValue(value);
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValue])

  useEffect(() => {
    const handleStorageChange = (): void => {
      handleSetValue(readValue);
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedValue]);

  return [storedValue, setValue];
}
