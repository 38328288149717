import { createEvent } from '@8baselabs/react-simple-state';
import { Bed, Property } from '@8baselabs/resident-io-shared';

export const FetchPropertiesByOwnerEvent = createEvent<Property[]>();
export const FetchPropertiesByOwnerEventError = createEvent<Error>();
export const FetchPropertyById = createEvent<Property>();
export const FetchPropertyByIdError = createEvent<Error>();
export const FetchPropertiesByFilter = createEvent<Property[]>();
export const FetchPropertiesByFilterError = createEvent<Error>();

export const updateBedEvent = createEvent<Bed>();
export const updateBedEventError = createEvent<Error>();
