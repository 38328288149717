import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { DRAWER_WIDTH } from '../constants';
import { TENANT_ROUTES } from '../../routes/types';
import { useActiveTenantRouteName } from '../hooks';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR, MUTED_COLOR } from '../theme';
import { Stepper } from './ui/stepper/Stepper';
import { MobileStepper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      transition: 'width 0.35s',
    },
    drawerPaper: {
      transition: 'width 0.35s',
      marginTop: 74,
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    toolbar: {
      justifyContent: 'center',
    },
    drawerLink: {
      color: 'inherit',
      textDecoration: 'none',
      '::focus': {
        color: 'inherit',
      },
    },
    drawerListItem: {
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
      marginTop: 20,
    },
    drawerListItemIcon: {
      minWidth: 35,
    },
    listItemIcon: {
      color: PRIMARY_TEXT_COLOR,
    },
    selectedListItem: {
      color: '#fff !important',
      backgroundColor: `${MUTED_COLOR} !important`,
    },
    selectedListItemIcon: {
      color: '#fff !important',
    },
    ButtomListItem: {
      color: '#fff !important',
      backgroundColor: `${PRIMARY_COLOR} !important`,
      borderRadius: 5,
      marginLeft: '10px',
      padding: theme.spacing(1),
      paddingLeft: 25,
      width: 220,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

type SidebarProps = {
  isOpen: boolean;
  routes: string[];
};

export const TenantSidebar: React.FC<SidebarProps> = ({ isOpen, routes }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const activeRouteName = useActiveTenantRouteName();

  const steps = routes
    .map((item) => {
      if (!TENANT_ROUTES[item].isSidebar) {
        return undefined;
      }
      return TENANT_ROUTES[item].name;
    })
    .filter((step) => step) as string[];

  const activeStep = steps.findIndex((step) => step === activeRouteName);
  return matches ? (
    <Drawer
      className={
        isOpen ? `${classes.drawer} ${classes.drawerOpen}` : classes.drawer
      }
      variant="persistent"
      open={isOpen}
      classes={{
        paper: isOpen
          ? `${classes.drawerPaper} ${classes.drawerOpen}`
          : classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}>
      <div className={classes.drawerContainer}>
        <Stepper steps={steps} activeStep={activeStep} />
      </div>
    </Drawer>
  ) : (
    <MobileStepper
      steps={steps.length}
      activeStep={activeStep}
      nextButton={<>{`${activeStep + 1}/${steps.length}`}</>}
      backButton={<>{steps[activeStep]}</>}
    />
  );
};
