import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ActivityLog } from '@8baselabs/resident-io-shared';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    large: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      backgroundColor: '#4D7CFE',
    },
    feedTopContent: {
      marginBottom: '10px',
    },
    feedUserName: {
      display: 'inline-flex',
      fontSize: 16,
      marginRight: 5,
    },
    feedTitleSecondary: {
      display: 'inline-flex',
      fontSize: 14,
      fontWeight: 'bold',
      marginRight: 5,
    },
    feedTitle: {
      marginBottom: '5px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export const FeedItem: React.FC<{ activity: ActivityLog }> = ({ activity }) => {
  const classes = useStyles();
  const userName = `${activity?.user?.firstName || ''} ${
    activity?.user?.lastName || ''
  }`;

  const activityCreatedAtTime = moment(activity.createdAt).format('hh:mm A');

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}>
        <Grid item xs={1} xl={1} lg={2} className={classes.center}>
          <Avatar
            src={activity?.user?.avatar?.downloadUrl}
            className={classes.large}>
            {activity?.user?.firstName?.[0]} {activity?.user?.lastName?.[0]}
          </Avatar>
        </Grid>
        <Grid item xs xl={11}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} className={classes.feedTopContent}>
              <Typography variant="h6" className={classes.feedUserName}>
                {userName}
              </Typography>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.feedTitleSecondary}>
                  {activityCreatedAtTime}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.feedTitleSecondary}>
                  {activity?.user?.userClientUserRelation?.items[0]?.role || ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.feedTitle}>
              <Typography variant="body1">{activity.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{activity.description}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
