import { Invitation } from '@8baselabs/resident-io-shared';
import { createEvent } from '@8baselabs/react-simple-state';

export const OnFetchInvitation = createEvent<Invitation>();
export const OnFetchInvitationError = createEvent<Error>();

export const OnAcceptInvitation = createEvent();
export const OnAcceptInvitationError = createEvent<Error>();

export const OnAcceptGuarantorInvitation =
  createEvent<{ id: string; status: string; response: boolean }>();
export const OnAcceptGuarantorInvitationError = createEvent<Error>();
