import gql from 'graphql-tag';

export const GET_ALL_PROPERTIES_BY_OWNER = gql`
  query GET_ALL_PROPERTIES_BY_OWNER($userId: ID!) {
    propertiesList(
      filter: {
        propertyPropertyOwnershipRelation: {
          some: { user: { id: { equals: $userId } } }
        }
      }
    ) {
      items {
        id
        name
        location
        needGuarantor
        phone
        city
        street
        street2
        subdomain
        type
        state
      }
    }
  }
`;

export const GET_ALL_PROPERTIES_BY_FILTER = gql`
  query GET_ALL_PROPERTIES_BY_FILTER($propFilter: PropertyFilter) {
    propertiesList(filter: $propFilter) {
      items {
        id
        name
        location
        email
        needGuarantor
        siteSetting {
          requiresRoommateMatchingQuiz
          enablePaymentsOnTenantProfile
        }
        primaryPropertyImage {
          downloadUrl
        }
        propertyPaymentPlanRelation(
          sort: { total: ASC }
          first: 1
          filter: { status: { equals: "APPROVED" } }
        ) {
          items {
            total
          }
        }
        propertyImagePropertyRelation {
          items {
            id
            file {
              downloadUrl
              fileId
            }
            caption
            type
          }
        }
        images {
          items {
            downloadUrl
          }
        }
        phone
        city
        street
        street2
        subdomain
        type
        state
      }
    }
  }
`;

export const FETCH_PROPERTY_BY_ID = gql`
  query FETCH_PROPERTY_BY_ID($id: ID!) {
    property(id: $id) {
      id
      siteSetting {
        enablePaymentsOnReservationCheckout
        requiresRoommateMatchingQuiz
        enablePaymentsOnTenantProfile
      }
      name
      location
      images {
        items {
          downloadUrl
          fileId
        }
      }
      primaryPropertyImage {
        downloadUrl
      }
      propertyBuildingRelation {
        items {
          id
          name
          code
          _description
          buildingFloorRelation {
            items {
              id
              number
              building {
                id
                code
              }
            }
          }
        }
      }
      propertyImagePropertyRelation {
        items {
          id
          file {
            downloadUrl
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_BED_AVAILABILITY = gql`
  mutation UPDATE_BED_AVAILABILITY($data: BedUpdateInput!) {
    bedUpdate(data: $data) {
      id
    }
  }
`;
