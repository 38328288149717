/* eslint-disable  @typescript-eslint/no-unused-vars */

import { Gender } from '@8baselabs/resident-io-shared/lib/constants';

const personalGuaranteeOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const appAckOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];
const ackOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const ferpaConsentOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const backgroundConsentOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const termsAckOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const petsAreNotAllowedOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const guarantorRequiredOptions = [
  {
    label: 'My Parent, guarantor, or someone else will co-sign as my guarantor',
    value: 'YES',
  },
];

const guarantorOptions = [
  {
    label: 'Parent',
    value: 'PARENT',
  },
  {
    label: 'Guardian',
    value: 'GUARDIAN',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

const smokeOptions = [
  {
    label: 'No',
    value: 'NO',
  },
  {
    label: 'Occasionally',
    value: 'OCCASIONALLY',
  },
  {
    label: 'Yes',
    value: 'YES',
  },
];

const referencesFields = [
  { label: 'Name', value: '', type: 'text' },
  { label: 'Phone Number', value: '', type: 'phone' },
];

const roommateRequestsFields = [
  { label: 'First Name', value: '', type: 'text' },
  { label: 'Last Name', value: '', type: 'text' },
  { label: 'Email', value: '', type: 'email' },
  { label: 'Cell Phone Number', value: '', type: 'phone' },
];

const previousAddressesFields = [
  { label: 'Country', value: 'US', type: 'countryUs' },
  { label: 'Street Address', value: '', type: 'text' },
  { label: 'Street Address 2', value: '', type: 'text' },
  { label: 'Zip Code', value: '', type: 'text' },
];

const jobFields = [
  { label: 'Current Employer', value: '', type: 'text' },
  { label: "Supervisor's Name", value: '', type: 'text' },
  { label: 'Phone', value: '', type: 'text' },
  { label: 'Job Title', value: '', type: 'text' },
  { label: 'Date Hired', value: '', type: 'text' },
  { label: 'Monthly Income', value: '', type: 'text' },
  { label: 'Employment Status', value: '', type: 'employmentStatus' },
];

const emergencyContactFields = [
  { label: 'Type', value: '', type: 'emergencyContactType' },
  { label: 'Name', value: '', type: 'text' },
  { label: 'Cell Phone Number', value: '', type: 'phone' },
  { label: 'Email', value: '', type: 'email' },
];

const ferpaContactFields = [
  { label: 'Type', value: '', type: 'ferpaContactType' },
  { label: 'Name', value: '', type: 'text' },
  { label: 'Cell Phone Number', value: '', type: 'phone' },
  { label: 'Email', value: '', type: 'email' },
];

const primaryIncomeSourceOptions = [
  {
    label: 'Self Employed',
    value: 'SELF_EMPLOYED',
  },
  {
    label: 'Employee - Full Time',
    value: 'EMPLOYEE_FULL_TIME',
  },
  {
    label: 'Employed - Part Time',
    value: 'PART_TIME',
  },
  {
    label: 'Student Loans',
    value: 'STUDENT_LOANS',
  },
  {
    label: 'Family Support',
    value: 'FAMILY_SUPPORT',
  },
];

const currentAdressRentOrOwnOptions = [
  {
    label: 'Rent',
    value: 'RENT',
  },
  {
    label: 'Own',
    value: 'Own',
  },
];

const currentlyImOptions = [
  {
    label: 'An Incoming Freshman',
    value: 'FRESHMAN',
  },
  {
    label: 'Living on Campus',
    value: 'LIVING_ON_CAMPUS',
  },
  {
    label: 'Living in an Off - Campus Apartment',
    value: 'LIVING_OFF_CAMPUS_APARTMENT',
  },
  {
    label: 'Living in a House Off Campus',
    value: 'LIVING_HOUSE_OFF_CAMPUS',
  },
  {
    label: 'Transfer Student',
    value: 'TRANSFER_STUDENT',
  },
  {
    label: 'A Commuter Student',
    value: 'COMMUTER_STUDENT',
  },
  {
    label: 'Attending a Secondary University',
    value: 'ATTENDING_SECONDARY_UNIVERSITY',
  },
  {
    label: 'A Current Resident',
    value: 'CURRENT_RESIDENT',
  },
];
const universityClassificationOptions = [
  {
    label: 'Freshman',
    value: 'FRESHMEN',
  },
  {
    label: 'Sophomore',
    value: 'SOPHOMORE',
  },
  {
    label: 'Junior',
    value: 'JUNIOR',
  },
  {
    label: 'Senior',
    value: 'SENIOR',
  },
  {
    label: 'Graduate',
    value: 'GRADUATE',
  },
  {
    label: 'Postdoctoral',
    value: 'POSTDOCTORAL',
  },
  {
    label: 'Non-Student',
    value: 'NON_STUDENT',
  },
];
const documentTypeOptions = [
  {
    label: "Driver's License",
    value: 'DRIVERS_LICENSE',
  },
  {
    label: 'State ID',
    value: 'STATE_ID',
  },
  {
    label: 'Passport',
    value: 'PASSPORT',
  },
  {
    label: 'SSN',
    value: 'SSN',
  },
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'SEVIS I-20 ID',
    value: 'SEVIS_I20_ID',
  },
];

const genderOptions = [
  {
    label: 'Male',
    value: Gender.Male,
  },
  {
    label: 'Female',
    value: Gender.Female,
  },
];

const roommateDisclaimerOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const genderAccomodationOptions = [
  {
    label: 'I prefer to be placed in a Same Gender accommodation.',
    value: 'SAME-GENDER',
  },
  {
    label:
      'I do not have a preference with regard to the gender identity of my roommates and/or prefer to be placed in a Gender Inclusive accommodation.Gender Inclusive accommodations will be assigned without consideration of the gender or gender identity of either you or your roommates.Gender Inclusive accommodations may be limited based on availability.',
    value: 'GENDER-INCLUSIVE',
  },
];
const howDidYouHearAboutUsOptions = [
  {
    value: 'INTERNET-SEARCH',
    label: 'Internet Search',
  },
  {
    value: 'EMAIL',
    label: 'Email',
  },
  {
    value: 'ON_CAMPUS_EVENT',
    label: 'On Campus Event',
  },
  {
    value: 'SPORTING_EVENT',
    label: 'Sporting Event',
  },
  {
    value: 'FLYER',
    label: 'Flyer',
  },
  {
    value: 'MAILER',
    label: 'Mailer',
  },
  {
    value: 'RADIO_SPOTIFY',
    label: 'Radio/Spotify',
  },
  {
    value: 'SIGN_BILLBOARD',
    label: 'Sign/Billboard',
  },
  {
    value: 'PRINT_PUBLICATION',
    label: 'Print/Publication',
  },
  {
    value: 'REFERRAL',
    label: 'Referral',
  },
  {
    value: 'VIP_LIST',
    label: 'VIP List',
  },
  {
    value: 'WEBSITE_GIVEAWAY',
    label: 'Website Giveaway',
  },
  {
    value: 'ON_SITE_EVENT',
    label: 'On Site Event',
  },
  {
    value: 'TEXT_MESSAGE_CAMPAIGN',
    label: 'Text Message Campaign',
  },
  {
    value: 'WEBSITE',
    label: 'Website',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

const currentAdressGooglePlacesOptions = [
  {
    value: 'city',
    label: 'currentAdresssCity',
  },
  {
    value: 'line1',
    label: 'currentAdressLine1',
  },
  {
    value: 'state',
    label: 'currentAdressState',
  },
  {
    value: 'zipCode',
    label: 'currentAdressZipCode',
  },
  {
    value: 'country',
    label: 'currentAdressCountry',
  },
];

const permanentAdressGooglePlacesOptions = [
  {
    value: 'city',
    label: 'permanentAdresssCity',
  },
  {
    value: 'line1',
    label: 'permanentAdressLine1',
  },
  {
    value: 'state',
    label: 'permanentAdressState',
  },
  {
    value: 'zipCode',
    label: 'permanentAdressZipCode',
  },
  {
    value: 'country',
    label: 'permanentAdressCountry',
  },
];

export const standardSections = [
  {
    id: '1',
    title: 'PERSONAL INFORMATION',
    text: 'Please provide the following information',
    userFields: [
      { name: 'firstName', label: 'First Name', required: true, type: 'text' },
      {
        name: 'middleName',
        label: 'Middle Name',
        required: false,
        type: 'text',
      },
      { name: 'lastName', label: 'Last Name', required: true, type: 'text' },
      {
        name: 'birthdate',
        label: 'Date of Birth',
        required: true,
        type: 'date',
      },
      { name: 'email', label: 'Email', required: true, type: 'email' },
      {
        name: 'phoneNumber',
        label: 'Cell Phone Number',
        required: true,
        type: 'cellphone',
      },
      {
        name: 'gender',
        label: 'Gender',
        required: true,
        type: 'radioGender',
        options: genderOptions,
      },
      {
        name: 'genderIdentify',
        label: 'Gender Identify',
        required: true,
        type: 'radioGender',
        options: genderOptions,
      },
      {
        name: 'preferredName',
        label: 'Preferred Name',
        required: true,
        type: 'text',
      },
      {
        name: 'aboutMe',
        label: 'Tell Us About Yourself! (About Me Bio)',
        required: true,
        type: 'textarea',
      },
    ],
    applicationFields: [],
    extraApplicationFields: [],
  },
  {
    id: '2',
    title: 'LEASING AGENT',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'leasingAgent',
        label: 'Are you working with a leasing agent?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'leasingAgentName',
        label: 'Leasing Agent Name',
        required: true,
        type: 'text',
        conditionField: 'leasingAgent',
        condition: 'YES',
      },
    ],
  },
  {
    id: '3',
    title: 'HOW DID YOU HEAR ABOUT US?',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'howDidYouHearAboutUs',
        label: 'How did you hear about us? ',
        required: true,
        type: 'dropdown',
        options: howDidYouHearAboutUsOptions,
      },
    ],
  },
  {
    id: '4',
    title: 'ROOMMATE  MATCHING PREFERENCES',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'genderAccomodation',
        label:
          'Please indicate your preference below as to how you prefer to be roommate matched. Note that this preference is not guaranteed, and assignments will be based on availability.',
        required: true,
        type: 'checkbox',
        options: genderAccomodationOptions,
      },
      {
        name: 'specificRoommates',
        label:
          'Do you have specific roommates that you know and would like to be matched with? ',
        required: true,
        type: 'yesno',
      },
      {
        name: 'roommateRequests',
        label: 'Roommate Request',
        required: true,
        type: 'fieldArray',
        conditionField: 'specificRoommates',
        condition: 'YES',
        options: roommateRequestsFields,
      },
    ],
  },
  {
    id: '5',
    title: 'ROOMMATES  DISCLAIMER',
    text: 'Roommates Disclaimer',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'roommateDisclaimer',
        label:
          'Roommate assignments are offered as a convenience to you, and your housing community assumes no liability for any claims relating in any way to roommate assignments. Your housing community applies the same rental criteria to all applicants but, unless otherwise indicated, does no investigation or background check beyond standard rental application processing. Unless stated otherwise, your housing community makes no representations, guarantees, or warranties of any required: true, type regarding the roommate assignment process or any particular person who is assigned as your roommate. You voluntarily assume any risk in the roommate assignment process and hereby waive and release your housing community from any and all claims related to the roommate assignment process and/or the conduct of any roommates assigned to your apartment. All roommate choices must be mutual, are subject to change, and cannot be guaranteed. Your housing community will not make room assignments based on Race, Color, Religion, National Origin, Disability, Gender, Familial Status, or Sexual Orientation in accordance with the Fair Housing Act. You authorize this information to be provided to potential roommates for the purpose of coordinating the roommate matching and move-in process.',
        required: true,
        type: 'singlecheckbox',
        options: roommateDisclaimerOptions,
      },
    ],
  },
  {
    id: '6',
    title: 'IDENTITY INFORMATION',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'ssn',
        label: 'Do you have a Social Security Number? ',
        required: true,
        type: 'longyesno',
      },
      {
        name: 'ssnNumber',
        label: 'Social Security Number',
        required: true,
        type: 'text',
        conditionField: 'ssn',
        condition: 'YES',
      },
      {
        name: 'otherCountryId',
        label: 'Other Country ID',
        required: true,
        type: 'text',
        conditionField: 'ssn',
        condition: 'NO',
      },
      {
        name: 'country',
        label: 'Country',
        required: true,
        type: 'country',
        conditionField: 'ssn',
        condition: 'NO',
      },
      {
        name: 'documentType',
        label: 'Document type',
        required: true,
        type: 'dropdown',
        options: documentTypeOptions,
        conditionField: 'ssn',
        condition: 'NO',
      },
      {
        name: 'govIssuingId',
        label: 'Government Issued ID',
        required: true,
        type: 'text',
        conditionField: 'ssn',
        condition: 'NO',
      },
      {
        name: 'govIdNumber',
        label: 'Government ID Number',
        required: true,
        type: 'text',
        conditionField: 'ssn',
        condition: 'NO',
      },
    ],
  },
  {
    id: '8',
    title: 'STUDENT INFORMATION & ID',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'fullTimeStudent',
        label: 'Are you a full-time student?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'highSchoolAttended',
        label: 'High school attended',
        required: true,
        type: 'text',
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'highSchoolCity',
        label: 'High school city',
        required: true,
        type: 'text',
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'highSchoolGraduationYear',
        label: 'What year did or will you graduate high school?',
        required: true,
        type: 'text',
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'universityClassification',
        label:
          'In the fall of [current year] what university classification will you be?',
        required: true,
        type: 'dropdown',
        options: universityClassificationOptions,
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'universityAttending',
        label: 'University attending',
        required: true,
        type: 'text',
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'myMajorIs',
        label: 'My major is',
        required: true,
        type: 'text',
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'currentlyIm',
        label: "Currently, I'm ",
        required: true,
        type: 'dropdown',
        options: currentlyImOptions,
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
      {
        name: 'studentIdNumber',
        label: 'Student ID number',
        required: true,
        type: 'text',
        conditionField: 'fullTimeStudent',
        condition: 'YES',
      },
    ],
  },
  {
    id: '9',
    title: 'CURRENT ADDRESS',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'currentAdressGooglePlaces',
        label: '',
        required: true,
        type: 'googlePlaces',
        options: currentAdressGooglePlacesOptions,
      },
      {
        name: 'currentAdressCountry',
        label: 'Country',
        required: true,
        type: 'countryUs',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressLine1',
        label: 'Street Address 1',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressLine2',
        label: 'Street Address 2',
        required: true,
        type: 'text',
      },
      {
        name: 'currentAdresssCity',
        label: 'City / Town',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressState',
        label: 'State',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressZipCode',
        label: 'Zip Code',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressHowLong',
        label: 'How long have you been living at this address? ',
        required: true,
        type: 'date',
      },
      {
        name: 'currentAdressRentOrOwn',
        label: 'Do you rent or own this property? ',
        required: true,
        type: 'dropdown',
        options: currentAdressRentOrOwnOptions,
      },
    ],
  },
  {
    id: '10',
    title: 'PREVIOUS ADDRESS',
    text: 'Please list all prior addresses going back 24 months. ',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'currentAdress24Months',
        label: "I've been in my current address for 24 months or longer",
        required: true,
        type: 'yesno',
      },
      {
        name: 'previousAdresses',
        label: 'Previous Address',
        required: true,
        type: 'fieldArray',
        conditionField: 'currentAdress24Months',
        condition: 'NO',
        options: previousAddressesFields,
      },
    ],
  },
  {
    id: '11',
    title: 'PERMANENT ADDRESS',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'permamentAdressGooglePlaces',
        label: '',
        required: true,
        type: 'googlePlaces',
        options: permanentAdressGooglePlacesOptions,
      },
      {
        name: 'permanentAdressCountry',
        label: 'Country',
        required: true,
        type: 'countryUs',
        alwaysDisabled: true,
      },
      {
        name: 'permanentAdressLine1',
        label: 'Street Address 1',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'permanentAdressLine2',
        label: 'Street Address 2',
        required: true,
        type: 'text',
      },
      {
        name: 'permanentAdresssCity',
        label: 'City / Town',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'permanentAdressState',
        label: 'State',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'permanentAdressZipCode',
        label: 'Zip Code',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
    ],
  },
  {
    id: '12',
    title: 'CURRENT INCOME SOURCES',
    text: 'How are you going to pay rent? ',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'howMakeAnnuallyAfterTax',
        label: 'How much do you make annually after tax?',
        required: false,
        type: 'text',
      },
      {
        name: 'primaryIncomeSource',
        label: 'Primary Income Source',
        required: true,
        type: 'dropdown',
        options: primaryIncomeSourceOptions,
      },
    ],
  },
  {
    id: '13',
    title: 'EMPLOYMENT & INCOME INFORMATION',
    text: 'How are you going to pay rent? ',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'doYouHaveACurrentJob',
        label: 'Do you have a current job?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'jobs',
        label: 'Job',
        required: true,
        type: 'fieldArray',
        conditionField: 'doYouHaveACurrentJob',
        condition: 'YES',
        options: jobFields,
      },
    ],
  },
  {
    id: '14',
    title: 'ONSITE EMPLOYMENT INFORMATION & ID',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'onsiteEmploymentLocation',
        label: 'Location',
        required: true,
        type: 'text',
      },
      {
        name: 'onsiteEmploymentDepartment',
        label: 'Department',
        required: true,
        type: 'text',
      },
      {
        name: 'onsiteEmploymentJobTitle',
        label: 'Job Title',
        required: true,
        type: 'text',
      },
      {
        name: 'onsiteEmploymentIdNumber',
        label: 'Employee ID number',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    id: '16',
    title: 'SMOKING',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'doYouSmoke',
        label: 'Do you smoke? ',
        required: true,
        type: 'dropdown',
        options: smokeOptions,
      },
      {
        name: 'acceptRoommateSmoke',
        label: 'Are you willing to live with roommates who smoke?',
        required: true,
        type: 'yesno',
      },
    ],
  },
  {
    id: '17',
    title: 'HEALTH INSURANCE',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'healthInsurance',
        label: 'Do you have Health Insurance?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'healthInsuranceProvider',
        label: 'Who is your health insurance provider?',
        conditionField: 'healthInsurance',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'healthInsurancepolicyNumber',
        label: 'What is your policy number?',
        conditionField: 'healthInsurance',
        condition: 'YES',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    id: '18',
    title: 'RENTERS INSURANCE',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'rentersInsurance',
        label: 'Do you have Renters Insurance?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'rentersInsuranceProvider',
        label: 'Who is your renters insurance provider?',
        conditionField: 'rentersInsurance',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'rentersInsurancePolicyNumber',
        label: 'What is your policy number?',
        conditionField: 'rentersInsurance',
        condition: 'YES',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    id: '19',
    title: 'RESIDENT HISTORY',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'evictedOrAskedToMove',
        label: 'Have you ever been evicted or asked to move?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'evictedOrAskedToMoveExplain',
        label: 'Please Explain',
        conditionField: 'evictedOrAskedToMove',
        condition: 'YES',
        required: true,
        type: 'textarea',
      },
      {
        name: 'convictedOrFelony',
        label: 'Have you ever been convicted of a felony? ',
        required: true,
        type: 'yesno',
      },
      {
        name: 'convictedOrFelonyExplain',
        label: 'Please Explain',
        conditionField: 'convictedOrFelony',
        condition: 'YES',
        required: true,
        type: 'textarea',
      },
      {
        name: 'bankrupcy',
        label: 'Have you ever declared bankruptcy?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'bankrupcyExplain',
        label: 'Please Explain',
        conditionField: 'bankrupcy',
        condition: 'YES',
        required: true,
        type: 'textarea',
      },
    ],
  },
  {
    id: '20',
    title: 'REFERENCES',
    text: 'Please list at least one leasing or non-family member reference we can contact',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'references',
        label: 'Reference',
        required: true,
        type: 'fieldArray',
        options: referencesFields,
      },
    ],
  },
  {
    id: '21',
    title: 'EMERGENCY CONTACT',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'emergencyContacts',
        label: 'Emergency Contact',
        required: true,
        type: 'fieldArray',
        options: emergencyContactFields,
      },
    ],
  },
  {
    id: '22',
    title: 'PETS ARE NOT ALLOWED',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'petsNotAllowedAck',
        label:
          "Pets are not allowed in the rental property. However, as provided by law, a Service Animal(s) is not considered a pet. Every individual with a disability shall have a right to have a Service Animal(s) on the property. Applicants should submit written reasonable accommodations and modifications requests when a change, exception, or adjustment to a rule, policy, practice, or service is needed based on the U.S. Department of Housing and Urban Development's(HUD) Fair Housing Act(Title VIII).",
        required: true,
        type: 'singlecheckbox',
        options: petsAreNotAllowedOptions,
      },
    ],
  },
  {
    id: '23',
    title: 'PETS',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'bringPets',
        label: 'Do you have any pets that you will be bringing?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'petType',
        label: 'Pet type',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'petWeight',
        label: 'Weight (lbs.)',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'petAge',
        label: 'Age',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'petColor',
        label: 'Color',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'petBreed',
        label: 'Breed',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'petName',
        label: 'Name',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'text',
      },
      {
        name: 'petGender',
        label: 'Gender',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'dropdown',
        options: genderOptions,
      },
      {
        name: 'petSpayedNeutered',
        label: 'Spayed/Neutered',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'yesno',
      },
      {
        name: 'petServiceAnimal',
        label: 'Service Animal',
        conditionField: 'bringPets',
        condition: 'YES',
        required: true,
        type: 'yesno',
      },
    ],
  },
  {
    id: '24',
    title: 'ESA ANIMALS (ROOMMATES)',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'acceptESA',
        label:
          'Are you willing to live with a roommate who has an Emotional Support Animal (ESA)?',
        required: true,
        type: 'longyesno',
      },
    ],
  },
  // {
  //     id: '25',
  //     title: 'REASONABLE ACCOMMODATION & MODIFICATION REQUEST',
  //     text: '',
  //     userFields: [],
  //     applicationFields: [],
  //     extraApplicationFields: [
  //         { name: 'reasonableAccomodationRequest', label: 'Will you be requesting any accommodation under the Fair Housing Act or Americans with Disability Act?', required: true, type: 'yesno', },

  //     ],
  // },
  {
    id: '26',
    title: 'GUARANTOR REQUIRED',
    text: 'Guarantor Required',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'guarantorRequired',
        label:
          'You are required to provide a Guarantor. A guarantor is someone who will sign a Guaranty Agreement and will also be financially responsible under the Lease Agreement. All Guarantors must be over 18, a U.S. Resident, have a current U.S. Address, and have sufficient income to guarantee the Lease Agreement. Most Applicants select a parent or guardian to be their Guarantor. Applicants who wish to be their Guarantor must earn an annual gross income equal to three (3) times the total rent installment, as outlined in the Lease Agreement, and must have a qualifying credit history. Proof of income must be supplied via two (2) recent pay stubs, four (4) if paid weekly, two (2) most recent bank statements, financial aid award, I-20, tax records, or court documents. Applicant must have held that employment for at least one (1) year before the Housing Application date. ',
        required: true,
        type: 'singlecheckbox',
        options: guarantorRequiredOptions,
      },
    ],
  },
  {
    id: '27',
    title: 'PARENT OR GUARDIAN SIGNATURE REQUIRED (APPLICANT AGE)',
    text: 'Parent or Guardian Signature Required',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'guarantorRequiredApplicantAge',
        label:
          'Your date of birth indicates that you are under the legal age to sign a Lease Agreement and must have a parent or guardian sign the Lease Agreement on your behalf. This requirement is common, and we will help you complete this process. After you sign, we will send the contract to your listed Guarantor for their signature. Please list a parent or legal guardian as your Guarantor. ',
        required: true,
        type: 'singlecheckbox',
        options: guarantorRequiredOptions,
      },
    ],
  },
  {
    id: '28',
    title: 'GUARANTOR REQUIRED (PAYMENT PLAN)',
    text: 'Guarantor Required',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'guarantorRequiredPaymentPlan',
        label:
          'All Applicants with your selected payment plan are required to provide a Guarantor. A Guarantor is someone who will sign a Guaranty Agreement and will also be financially responsible under the Lease Agreement. Most Applicants select a parent or legal guardian to be their Guarantor.',
        required: true,
        type: 'singlecheckbox',
        options: guarantorRequiredOptions,
      },
    ],
  },
  {
    id: '29',
    title: 'GUARANTOR INFORMATION',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'guarantorType',
        label: 'Guarantor Type',
        required: true,
        type: 'dropdown',
        options: guarantorOptions,
      },
      {
        name: 'guarantorPrefix',
        label: 'Guarantor Prefix',
        required: false,
        type: 'text',
      },
      {
        name: 'guarantorFirstName',
        label: 'Guarantor first name',
        required: true,
        type: 'text',
      },
      {
        name: 'guarantorLastName',
        label: 'Guarantor last name',
        required: true,
        type: 'text',
      },
      {
        name: 'guarantorBirthDate',
        label: 'Guarantor Date of Birth',
        required: true,
        type: 'date',
      },
      {
        name: 'guarantorCellPhone',
        label: 'Guarantor cell phone',
        required: true,
        type: 'cellphone',
      },
      {
        name: 'guarantorEmail',
        label: 'Guarantor email',
        required: true,
        type: 'email',
      },
    ],
  },
  {
    id: '30',
    title: 'TERMS AND ACKNOWLEDGEMENT',
    text: 'Terms and Acknowledgement',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'termsAck',
        label:
          'No agreement, either written or oral, shall be binding on applicant, agent, or owner, except to the extent that such agreement is set forth in a written Lease Agreement executed by such parties. Acceptance of this application does not assure a room reservation. A bedroom accommodation shall be reserved only upon signing of a Lease Agreement by all parties before the applicable property reaches full capacity. This application will be approved only upon confirmation of a satisfactory criminal background check and verification that all rental requirements and qualifications have been met.',
        required: true,
        type: 'singlecheckbox',
        options: termsAckOptions,
      },
    ],
  },
  {
    id: '31',
    title: 'BACKGROUND SCREENING AND CREDIT CHECK CONSENT',
    text: 'Background Screening and Credit Check Consent',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'backgroundConsent',
        label:
          "I agree that you may conduct a credit, background, and reference check on me. In connection therewith, I hereby authorize you to (a) use any consumer reporting agency, credit bureau, or other investigative agencies employed by such to investigate the references set forth herein and other information obtained from me (or any other person) pertaining to my employment history, credit history, character, general reputation, personal characteristics and mode of living; (b) obtain a consumer report and such other credit information which may result therefrom, and (c) disclose and furnish such information to the owner's agent set forth above in support of my application.I acknowledge and agree that if I fail to answer any question or if I have provided false information: (1) you are entitled to reject this application; (2) you may retain all processing fees and deposits as liquidated damages for time spent and expenses; (3) you may terminate any right to lease the bedroom; and(4) if I have signed a lease, it will constitute a violation of the lease.I have been advised that I have the right, under Section 606B of the Fair Credit Reporting Act, to make a written request, within a reasonable time, for a complete and accurate disclosure of the nature and scope of this investigation.",
        required: true,
        type: 'singlecheckbox',
        options: backgroundConsentOptions,
      },
    ],
  },
  {
    id: '32',
    title: 'CONSENT TO RELEASE EDUCATIONAL ACADEMIC INFORMATION (FERPA)',
    text: 'CONSENT TO RELEASE EDUCATIONAL ACADEMIC INFORMATION (FERPA)',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'ferpaConsent',
        label:
          'The family educational rights and privacy act (FERPA) provides procedures for the appropriate consent to release educational information about a student. FERPA is applicable for those students at or above 18 years of age. Educational information includes grades, academic progress and deficiencies, attendance, class schedules, financial aid, and student billing. This information will not be automatically released but will be available upon request. More information regarding FERPA may be found on this link https://www2.ed.gov/policy/gen/guide/fpco/ferpa/index.html. The purpose of this form is to make clear to whom the student has given consent to receive educational information. Please note that if you are age 18+ and do not authorize us to share this information with your parents, university staff will be unable to discuss specific details about payments and financial aid with them. I understand this release authorization remains in effect as long as I am a student and 60 days after graduation/withdrawal or until I revoke this authorization in writing.',
        required: true,
        type: 'singlecheckbox',
        options: ferpaConsentOptions,
      },
      {
        name: 'ferpaPermission',
        label:
          'Do you grant permission to release confidential academic information?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'ferpaContacts',
        label: 'FERPA Contact',
        required: true,
        type: 'fieldArray',
        options: ferpaContactFields,
        conditionField: 'ferpaPermission',
        condition: 'YES',
      },
    ],
  },
  {
    id: '33',
    title: 'ACKNOWLEDGEMENT',
    text: 'Acknowledgement',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [
      {
        name: 'acknowledgement',
        label:
          'Under penalties of perjury, I certify that the information presented on this form is true and accurate to the best of my knowledge. I understand that providing false representations herein constitutes an act of fraud. Inaccurate, misleading, or incomplete information will result in the denial of application or termination of the lease agreement. I Attest that I have read and understand all rental requirements and qualifications and agree to all terms and conditions above',
        required: true,
        type: 'singlecheckbox',
        options: ackOptions,
      },
      {
        name: 'applicationAck',
        label:
          'I understand that this is only an application to rent and does not guarantee that I will be selected as a resident of the premises.',
        required: true,
        type: 'singlecheckbox',
        options: appAckOptions,
      },
    ],
  },
];
