import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

import { useLocation } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { TenantSidebar } from './TenantSidebar';
import { Topbar } from './Topbar';
import { BACKGROUND_COLOR } from '../theme';
import { useActiveRouteName } from '../hooks';
import { ONBOARDING_ROUTES, ROUTES, TENANT_ROUTES } from '../../routes/types';
import { TenantTopbar } from './TenantTopbar';
import { TenantFooter } from './TenantFooter';
import { OnboardingTopbar } from './OnboardingTopbar';
import { OnboardingSidebar } from './OnboardingSidebar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Step } from '../../modules/tenant/tenant-events';
import { StepType } from '../../modules/tenant/tenant-types';
import { useSubscription } from '@8baselabs/react-simple-state';

const contentStyles = {
  backgroundColor: BACKGROUND_COLOR,
  flexGrow: 1,
  minHeight: '100vh',
  transition: 'width 0.35s',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    root: {
      display: 'flex',
      marginTop: '65px',
    },
    content: {
      ...contentStyles,
      padding: theme.spacing(3),
      position: 'relative',
    },
    main1: {
      ...contentStyles,
      position: 'relative',
      padding: theme.spacing(3),
    },
    main2: {
      ...contentStyles,
      position: 'relative',
      padding: theme.spacing(4, 6),
    },
    noSideBarcontent: {
      backgroundColor: BACKGROUND_COLOR,
      minHeight: '100vh',
      padding: theme.spacing(3, 0.5),
      minWidth: 1100,
      width: '68%',
      margin: '0 auto',
    },
  }),
);

/**
 * @param {object}props - Props.
 * @param {JSX.Element} props.children - Children to render.
  @returns {JSX.Element} - Layout component of the app.
 */
export function Layout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();
  const [isDrawerOpen, setIsOpenDrawer] = React.useState(true);
  const [isDrawerTenantOpen, setIsOpenTenantDrawer] = React.useState(true);
  const activeRoute = useActiveRouteName();
  const [isTenant, setIsTenant] = React.useState(false);
  const [isPM, setIsPM] = React.useState(false);
  const [routes, setRoutes] = React.useState<string[]>([]);
  const [isOnboard, setIsOnboard] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = (): void => {
    setIsOpenDrawer(!isDrawerOpen);
  };

  React.useEffect(() => {
    const pathSplit = location.pathname.split('/');
    const primaryPath = pathSplit[pathSplit.length - 1];
    setIsOpenTenantDrawer(TENANT_ROUTES[primaryPath]?.isSidebar === true);
    if (pathSplit.length > 2) {
      const parentPath = pathSplit[1];
      setIsTenant(Boolean(TENANT_ROUTES[parentPath]));
      setIsOnboard(Boolean(ONBOARDING_ROUTES[parentPath]));
      setIsPM(Boolean(ROUTES[parentPath]));
    } else {
      setIsTenant(Boolean(TENANT_ROUTES[primaryPath]));
      setIsPM(Boolean(ROUTES[primaryPath]));
      setIsOnboard(Boolean(ONBOARDING_ROUTES[primaryPath]));
    }
  }, [location, setIsTenant]);

  const getTopPaddingBox = (): JSX.Element | null =>
    [
      ROUTES.opportunities.name,
      ROUTES.settings.name,
      ROUTES.settings.subRoutes?.assignpaymentplans.name,
      ROUTES.settings.subRoutes?.paymentplans.name,
      ROUTES.settings.subRoutes?.['property-documents'].name,
      ROUTES.settings.subRoutes?.buildinglayouts.name,
      ROUTES.settings.subRoutes?.['optional-preferences'].name,
      ROUTES.settings.subRoutes?.['lease-periods'].name,
      ROUTES.settings.subRoutes?.subperiods.name,
      ROUTES.settings.subRoutes?.['unit-room-types'].name,
      ROUTES.settings.subRoutes?.propertyinformation.name,
    ].includes(activeRoute) ? null : (
      <Box height="5px" />
    );

  const mainStyles = [
    ROUTES.settings.subRoutes?.assignpaymentplans.name,
    ROUTES.settings.subRoutes?.paymentplans.name,
    ROUTES.settings.subRoutes?.['property-documents'].name,
    ROUTES.settings.subRoutes?.buildinglayouts.name,
    ROUTES.settings.subRoutes?.['optional-preferences'].name,
    ROUTES.settings.subRoutes?.['lease-periods'].name,
    ROUTES.settings.subRoutes?.subperiods.name,
    ROUTES.settings.subRoutes?.['lease-periods'].name,
    ROUTES.settings.subRoutes?.['unit-room-types'].name,
  ].includes(activeRoute)
    ? classes.main2
    : classes.main1;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useSubscription(Step, (data: StepType | null) => {
    let keys = Object.keys(TENANT_ROUTES);
    if (data?.disablePayment) {
      keys = keys.filter((value) => value !== 'payment');
    }
    if (data?.disablePersonality) {
      keys = keys.filter((value) => value !== 'personality-assessment');
    }
    setRoutes(keys);
  });

  if (isTenant) {
    return (
      <div className={classes.root}>
        <TenantTopbar />

        {isDrawerTenantOpen && matches && (
          <TenantSidebar routes={routes} isOpen={isDrawerTenantOpen} />
        )}
        <main
          className={`${classes.content} ${!matches ? classes.fullWidth : ''}`}>
          {isDrawerTenantOpen && !matches && (
            <TenantSidebar routes={routes} isOpen={isDrawerTenantOpen} />
          )}

          {children}
          {isDrawerTenantOpen && <TenantFooter isOpen={isDrawerTenantOpen} />}
        </main>
      </div>
    );
  }
  if (isOnboard)
    return (
      <div className={classes.root}>
        <OnboardingTopbar />
        <OnboardingSidebar />
        <main className={classes.content}>
          <Toolbar />
          {children}
        </main>
      </div>
    );
  if (isPM)
    return (
      <div className={classes.root}>
        <Topbar
          isSidebarOpen={isDrawerOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Sidebar isOpen={isDrawerOpen} />
        <main className={mainStyles}>
          <Toolbar />
          {getTopPaddingBox()}
          {children}
        </main>
      </div>
    );

  return <>{children}</>;
}
