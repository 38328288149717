import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSubscription } from '@8baselabs/react-simple-state';
import { ButtonDefault } from '../../shared/components/ui/buttons/ButtonDefault';
import { BoldTitle } from '../../shared/components/ui/texts/CommonTexts';
import EmailVerificationImage from '../../shared/assets/emailVerification.png';
import { resendEmailVerification } from '../../shared/components/components-actions';
import {
  OnRequestEmailVerification,
  OnRequestEmailVerificationError,
} from '../../shared/components/components-events';
import { AlertContext } from '../../routes/AlertContext';
import { useSession } from '../session/session-hooks';
import { SENDGRID_DEFAULT_CC_RECEIVER } from '../session/session-types';
import { PRIMARY_COLOR } from '../../shared/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const VerifyEmailView: React.FC = () => {
  const { isLoading, user } = useAuth0();
  const history = useHistory();
  const alert = useContext(AlertContext);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [session] = useSession();

  const systemConfigEmail = session?.systemConfigsList?.items?.find(
    (config) => config.name === SENDGRID_DEFAULT_CC_RECEIVER,
  );

  if (!isLoading && user && user?.email_verified) {
    history.push('/');
  }

  useSubscription(OnRequestEmailVerification, () => {
    setloadingSubmit(false);
    alert({
      success: true,
      message: 'Email verification successfully requested',
    });
  });

  useSubscription(OnRequestEmailVerificationError, (error) => {
    setloadingSubmit(false);
    alert({
      error: true,
      message: 'Error sending email',
    });
  });

  const handleResend = (): void => {
    resendEmailVerification({ sub: user?.sub });
    setloadingSubmit(true);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box maxWidth="810px" margin="auto" py="60px">
      <BoldTitle align={matches ? 'left' : 'center'} style={{ fontSize: '36px' }}>Email verification</BoldTitle>
      <Box
        style={{ backgroundColor: '#fff' }}
        display="flex"
        alignItems="center"
        textAlign="center"
        flexDirection="column"
        mt="40px"
        boxShadow="0 1px 3px 1px rgba(153,155,168,0.1)"
        borderRadius="4px"
        p="50px">
        <Box>
          <img alt="email" src={EmailVerificationImage} width="70%" />
        </Box>
        <BoldTitle style={{ fontSize: '20px' }}>
          Verify your email address
        </BoldTitle>
        <Box mt="20px" color="#5D6E7F">
          If you don’t see the email, please check your spam folder. For
          assistance, please contact us at{' '}
          {systemConfigEmail?.value || 'our email'}
        </Box>
        <Box width="196px" height="46px" mt="20px">
          <ButtonDefault
            disabled={isLoading || loadingSubmit}
            isLoading={loadingSubmit}
            onClick={handleResend}>
            RESEND
          </ButtonDefault>
        </Box>
        <Box mt="5px">
          <Link
            to="/logout"
            style={{ color: PRIMARY_COLOR, textDecoration: 'none' }}>
            Logout
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
