import gql from 'graphql-tag';

export const CREATE_TRANSFER_LEASE = gql`
  mutation CREATE_TRANSFER_LEASE($data: TransferLeaseCreateInput!) {
    transferLeaseCreate(data: $data) {
      id
    }
  }
`;

export const GET_TRANSFER_LEASE_BY_LEASE_ID = gql`
  query GET_TRANSFER_LEASE_BY_LEASE_ID($filter: TransferLeaseFilter) {
    transferLeasesList(filter: $filter) {
      items {
        id
        firstName
        lastName
        gender
        phoneNumber
        newResidentEmail
      }
    }
  }
`;

export const GET_BED_PREFERENCES = gql`
  query GET_BED_PREFERENCES(
    $leasePeriodId: ID!
    $bedId: ID!
    $leaseId: ID!
    $availabilityType: String
  ) {
    bedsList(
      filter: {
        bedAvailabilityRelation: { some: { bed: { id: { equals: $bedId } } } }
        bedLeaseRelation: {
          some: { leasePeriod: { id: { equals: $leasePeriodId } } }
        }
      }
    ) {
      count
      items {
        id
        bedAvailabilityRelation {
          items {
            availabilityOptionalPreferenceRelation(
              filter: {
                optionalPreference: { status: { equals: "APPROVED" } }
                type: { equals: $availabilityType }
              }
            ) {
              count
              items {
                optionalPreference {
                  id
                  name
                  title
                  description
                  status
                  isActive
                  leasePeriod {
                    id
                    name
                  }
                  total
                  createdAt
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                  opcionalOpcionalPreferenceItemRelation {
                    items {
                      amount
                      type
                    }
                  }

                  optionalPreferenceInvetoryRelation {
                    items {
                      isSold
                      id
                      name
                      leaseOptionalPreference {
                        lease {
                          id
                        }
                      }
                    }
                  }

                  optionalPreferenceAdditionalDataRelation {
                    items {
                      id
                      title
                      required
                    }
                  }

                  optionalPreferenceLeaseRelation(
                    filter: { lease: { id: { equals: $leaseId } } }
                  ) {
                    items {
                      lease {
                        id
                      }
                      id
                      inventory {
                        name
                        id
                      }
                      additionalAppData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PREFERENCE_INVENTORY = gql`
  mutation UPDATE_PREFERENCE_INVENTORY(
    $data: OptionalPreferenceInvetoryUpdateInput!
  ) {
    optionalPreferenceInvetoryUpdate(data: $data) {
      id
      isSold
    }
  }
`;
