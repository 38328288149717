import { DataGrid } from '@material-ui/data-grid';
import React from 'react';

export const CustomDataGrid: React.FC<React.ComponentProps<typeof DataGrid>> = (
  props,
) => {
  return (
    <DataGrid
      {...props}
      disableColumnSelector
    />
  );
};
