export type OptionValue = {
  value: string;
  label: string;
  building?: string;
};

export type CompatibilityScoreValue = {
  lowerThreshold: number;
  upperThreshold: number;
  label: string;
};

export type Price = {
  value: number;
  label: string;
};

export type PaymentMethod = {
  type: PaymentMethodEnum;
};

export enum PaymentMethodEnum {
  ELECTRONIC = 'ELECTRONIC',
  MANUAL = 'MANUAL',
}
