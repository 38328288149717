import { useCallback, useContext, useEffect } from 'react';
import {
  useCallAction,
  useEvent,
  useSubscription,
} from '@8baselabs/react-simple-state';
import {
  Form,
  GuarantorUpdateInput,
  Lease,
  ResidentApplication,
  UserUpdateInput,
} from '../../../schema-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { AlertContext } from '../../../routes/AlertContext';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { MainLoader } from '../../../shared/components/MainLoader';
import { Application } from '../../opportunities/components/Application';
import { updateResidentApplicationLeaseOpportunitiesAction } from '../../opportunities/opportunities-actions';
import {
  AddReservationFetchLeaseEvent,
  AddReservationSelectResidentApplicationEvent,
  AddReservationUpdateResidentApplicationEvent,
  requiredFilesEvent,
} from '../../opportunities/opportunities-event';
import { useLazyQuery } from '@apollo/client';
import { GET_LEASE_BY_ID } from '../../opportunities/opportunities-queries';
import { LeaseStatus } from '@8baselabs/resident-io-shared';

export const GuarantorView: React.FC<{ selectedLease?: Lease }> = ({
  selectedLease,
}) => {
  const [fetchLease, { loading: loadingFetchLease, data: leaseData }] =
    useLazyQuery(GET_LEASE_BY_ID, {
      onCompleted(data) {
        if (!data) return;
        AddReservationFetchLeaseEvent.dispatch(data.lease as Lease);
      },
    });

  useEffect(() => {
    if (selectedLease?.id) {
      fetchLease({ variables: { leaseId: selectedLease.id } });
    }
  }, [selectedLease?.id]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loadingFetchLease && selectedLease && (leaseData?.lease as Lease)) {
      AddReservationSelectResidentApplicationEvent.dispatch({
        sections:
          (leaseData?.lease.leasePeriod?.propertyFormLeasePeriodRelation
            ?.items[0] as Form) || {},
        application:
          (leaseData?.lease.residentApplication as ResidentApplication) || {},
        user: (leaseData?.lease.userResident as UserUpdateInput) || {},
        guarantor: (leaseData?.lease.guarantor as GuarantorUpdateInput) || {},
        fields: [],
      });
    }
  }, [leaseData?.lease, loadingFetchLease, selectedLease]);

  const alertSnackbar = useContext(AlertContext);
  const {
    application,
    user: userUpdate,
    guarantor: guarantorCreate,
    fields,
  } = useEvent(AddReservationSelectResidentApplicationEvent);

  const [
    updateResidentApplicationLease,
    loadingResidentApplicationLeaseUpdate,
  ] = useCallAction(updateResidentApplicationLeaseOpportunitiesAction);

  const updateGuarantorInfo = useCallback(async () => {
    if (!requiredFilesEvent.get()?.allFilesUploaded) {
      const message = 'Please upload all required documents';
      alertSnackbar({ error: true, message });
      return;
    }

    updateResidentApplicationLease(
      selectedLease?.id,
      application,
      userUpdate,
      guarantorCreate,
      fields,
      'guarantor',
      false,
    );
  }, [
    updateResidentApplicationLease,
    selectedLease?.id,
    application,
    userUpdate,
    guarantorCreate,
    fields,
    alertSnackbar,
  ]);

  useSubscription(AddReservationUpdateResidentApplicationEvent, () => {
    fetchLease({ variables: { leaseId: selectedLease?.id } });
  });

  if (loadingFetchLease) {
    return (
      <Box>
        <MainLoader />
      </Box>
    );
  }

  if (!selectedLease) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
        paddingTop="40px">
        <Typography variant="body2">Please select a lease</Typography>
      </Box>
    );
  }

  if (leaseData?.lease?.guarantor === null) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
        paddingTop="40px">
        <Typography variant="body2">
          The selected lease doesn&apos;t have a guarantor
        </Typography>
      </Box>
    );
  }

  const isDisabledByStatus =
    (!!leaseData?.lease &&
      (leaseData?.lease as Lease)?.status === LeaseStatus.PAYMENT) ||
    (leaseData?.lease as Lease)?.status === LeaseStatus.APPROVED ||
    (leaseData?.lease as Lease)?.status === LeaseStatus['MOVE-IN'] ||
    (leaseData?.lease as Lease)?.status === LeaseStatus['MOVE-OUT'] ||
    (leaseData?.lease as Lease)?.status === 'MOVE-OUT_REFUND_ISSUED' ||
    (leaseData?.lease as Lease)?.status === 'MOVE-OUT_BALANCE_OWED';

  return (
    <Box padding="40px">
      <Application
        formType="GUARANTOR_ONLY"
        guarantorRequirement={false}
        disabledSections={isDisabledByStatus}
        readOnly={isDisabledByStatus}
      />
      <Box mt="40px" display="flex" justifyContent="flex-end">
        <ButtonAction
          fullWidth={false}
          disabled={loadingResidentApplicationLeaseUpdate}
          isLoading={loadingResidentApplicationLeaseUpdate}
          onClick={updateGuarantorInfo}
          size="large">
          SAVE CHANGES
        </ButtonAction>
      </Box>
    </Box>
  );
};
