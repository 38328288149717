import { useFetchAction } from '@8baselabs/react-simple-state';
import { Lease, LeasePeriod } from '@8baselabs/resident-io-shared';
import { useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';

import { GridColDef } from '@material-ui/data-grid';
import { isValidString } from '@8baselabs/validation-utils';
import { Avatar, Box } from '@material-ui/core';
import { PageTitle } from '../../../shared/components/ui/texts/CommonTexts';
import { MainLoader } from '../../../shared/components/MainLoader';
import { fetchRoommates } from '../people-actions';
import { BORDER_COLOR } from '../../../shared/theme';
import { CustomDataGrid } from '../../../shared/components/CustomDataGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(4, 0),
    },
    text: { margin: 0, padding: 0, lineHeight: 1.3 },
    root: {
      border: `1.7px solid ${BORDER_COLOR}`,
      borderRadius: 6,

      '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
        {
          opacity: 0,
          pointerEvents: 'none',
        },
      '& .MuiDataGrid-cellWithRenderer, & .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitle':
        {
          whiteSpace: 'normal',
        },

      '& .MuiDataGrid-columnHeader[data-field="actions"]': {
        border: 'none',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader': {
        borderRight: `1.7px solid ${BORDER_COLOR}`,
      },

      '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
        border: 'none',
      },
    },
  }),
);

type DashboardDatagridProps = { selectedLease?: Lease };
type GridRow = {
  avatar: string;
  name: string;
  id: string;
  gender: string;
  email: string;
  phone: string;
  unit: string;
  bed: string;
  leasePeriod: LeasePeriod;
};

export const RoommateTable: React.FC<DashboardDatagridProps> = ({
  selectedLease,
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(0);
  const [rows, setrows] = useState<GridRow[]>([]);
  const [rowPerPage, setrowPerPage] = useState(20);
  const [count, setCount] = useState<number>();
  const [userFilter, leaseFilter] = useMemo(
    () => [
      {
        id: { not_equals: id },
        userResidentLeaseRelation: {
          some: {
            leasePeriod: { id: { equals: selectedLease?.leasePeriod?.id } },
            unit: { id: { equals: selectedLease?.unit?.id } },
          },
        },
      },
      {
        leasePeriod: { id: { equals: selectedLease?.leasePeriod?.id } },
        unit: { id: { equals: selectedLease?.unit?.id } },
      },
    ],
    [selectedLease, id],
  );
  const [, loading] = useFetchAction(
    fetchRoommates,
    [userFilter, leaseFilter, rowPerPage, page],
    {
      skip: selectedLease?.property?.id === undefined,
      onCompleted(data) {
        if (!data) return;
        setCount(data?.count);
        setrows(
          data?.items?.map((user) => ({
            id: user.id || '',
            avatar: user.avatar?.downloadUrl || '',
            name: `${user.firstName || ''} ${user.lastName || ''}`,
            gender: user.gender || '',
            email: user.email || '',
            phone: user.phoneNumber || '',
            unit: selectedLease?.unit?.code || '',
            bed: user.userResidentLeaseRelation?.items[0].bed?.number || '',
            lease: user.userResidentLeaseRelation?.items[0] || '',
            leasePeriod: selectedLease?.leasePeriod as LeasePeriod,
          })),
        );
      },
    },
  );

  if (loading) {
    return (
      <Box>
        <MainLoader />
      </Box>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params) => {
        return params.row.name[0];
      },
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Box mr="10px">
            <Avatar src={params?.row?.avatar} />
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyItems="center"
            flexDirection="column">
            <Box height="20px" fontWeight="600">
              <p className={classes.text}> {params.row.name}</p>
            </Box>
            <Box height="20px">
              <p className={classes.text}> {params.row.gender}</p>
            </Box>
          </Box>
        </Box>
      ),
    },

    {
      field: 'contacts',
      headerName: 'Contacts',
      flex: 1,
      valueGetter: (params) => {
        return params.row.email;
      },
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyItems="center"
          flexDirection="column">
          <Box height="20px" fontWeight="500">
            <p className={classes.text}> {params.row.email}</p>
          </Box>
          <Box height="20px" fontWeight="500">
            <p className={classes.text}> {params.row.phone}</p>
          </Box>
        </Box>
      ),
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 1,
      valueGetter: (params) => {
        return params.row.bed;
      },
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Box height="20px" fontWeight="500">
              {' '}
              <span className={classes.text}>
                Unit {params.row.unit}, Bed {params.row.bed}
              </span>
            </Box>
            <Box fontWeight="500">
              {' '}
              <span className={classes.text}>
                {' '}
                {isValidString(params.row.lease.startDate)
                  ? new Intl.DateTimeFormat('us').format(
                      new Date(params.row.lease.startDate),
                    )
                  : 'No start date found'}{' '}
                -{' '}
                {isValidString(params.row.lease.endDate)
                  ? new Intl.DateTimeFormat('us').format(
                      new Date(params.row.lease.endDate),
                    )
                  : 'No end date found'}
              </span>
            </Box>
          </Box>
        </Box>
      ),
    },
  ];

  const rowsPerPageOptions = [5, 10, 20, 50, 100];
  return (
    <>
      <Paper className={classes.paper}>
        <CustomDataGrid
          classes={{
            root: classes.root,
          }}
          onPageChange={(data) => {
            setPage(data);
          }}
          onPageSizeChange={(data) => {
            setPage(0);
            setrowPerPage(data);
          }}
          pageSize={rowPerPage}
          page={page}
          rows={rows}
          columns={columns}
          loading={loading}
          rowHeight={80}
          // error={error}
          rowCount={count}
          rowsPerPageOptions={rowsPerPageOptions}
          hideFooterPagination={!count}
          pagination
          paginationMode="server"
          autoHeight
        />
      </Paper>
    </>
  );
};
export const RoomMateView: React.FC<DashboardDatagridProps> = ({
  selectedLease,
}) => (
  <Box p="40px" height="1000px">
    <Box display="flex" justifyContent="space-between">
      <PageTitle variant="h5">Roommates</PageTitle>
    </Box>
    <RoommateTable selectedLease={selectedLease} />
  </Box>
);
