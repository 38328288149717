const personalGuaranteeOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const currentAdressRentOrOwnOptions = [
  {
    label: 'Rent',
    value: 'RENT',
  },
  {
    label: 'Own',
    value: 'Own',
  },
];

const documentTypeOptions = [
  {
    label: "Driver's License",
    value: 'DRIVERS_LICENSE',
  },
  {
    label: 'State ID',
    value: 'STATE_ID',
  },
  {
    label: 'Passport',
    value: 'PASSPORT',
  },
  {
    label: 'SSN',
    value: 'SSN',
  },
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'SEVIS I-20 ID',
    value: 'SEVIS_I20_ID',
  },
];

const primaryIncomeSourceOptions = [
  {
    label: 'Self Employed',
    value: 'SELF_EMPLOYED',
  },
  {
    label: 'Employee - Full Time',
    value: 'EMPLOYEE_FULL_TIME',
  },
  {
    label: 'Employed - Part Time',
    value: 'PART_TIME',
  },
  {
    label: 'Student Loans',
    value: 'STUDENT_LOANS',
  },
  {
    label: 'Family Support',
    value: 'FAMILY_SUPPORT',
  },
];

const jobFields = [
  { label: 'Current Employer', value: '', type: 'text' },
  { label: "Supervisor's Name", value: '', type: 'text' },
  { label: 'Phone', value: '', type: 'text' },
  { label: 'Job Title', value: '', type: 'text' },
  { label: 'Date Hired', value: '', type: 'text' },
  { label: 'Monthly Income', value: '', type: 'text' },
  { label: 'Employment Status', value: '', type: 'employmentStatus' },
];

const termsAckOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const appAckOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];
const ackOptions = [
  {
    label: 'I acknowledge that I have read the information above.',
    value: 'YES',
  },
];

const currentAdressGooglePlacesOptions = [
  {
    value: 'city',
    label: 'currentAdresssCity',
  },
  {
    value: 'line1',
    label: 'currentAdressLine1',
  },
  {
    value: 'state',
    label: 'currentAdressState',
  },
  {
    value: 'zipCode',
    label: 'currentAdressZipCode',
  },
  {
    value: 'country',
    label: 'currentAdressCountry',
  },
];

export const guarantorSections = [
  {
    id: '201',
    title: 'GUARANTOR PERSONAL INFORMATION',
    text: 'Please provide the following information',
    userFields: [],
    guarantorFields: [
      { name: 'firstName', label: 'First Name', type: 'text', required: true },
      { name: 'middleName', label: 'Middle Name', type: 'text' },
      { name: 'lastName', label: 'Last Name', type: 'text', required: true },
      {
        name: 'birthdate',
        label: 'Date of Birth',
        type: 'date',
        required: true,
      },
      { name: 'email', label: 'Email', type: 'email', required: true },
      {
        name: 'phoneNumber',
        label: 'Cell Phone Number',
        type: 'cellphone',
        required: true,
      },
    ],
    applicationFields: [],
    extraApplicationFields: [],
  },
  {
    id: '202',
    title: 'GUARANTOR PERSONAL GUARANTEE',
    text: 'Personal Guarantee',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'personalGuarantee',
        label:
          'I promise to personally guarantee, absolutely and unconditionally, the Resident compliance with the financial obligations of the rental lease agreement. In the event that the Resident fails to pay, I understand that I may be required to pay for the following items, including but not limited to, rent, utilities, cleaning charges, water usage charges, penalties (including late fees, and other administrative charges) or damage assessments in such amount as are incurred by the Resident under the terms of this agreement. I further understand that this Co-Signer Agreement will remain in full force throughout the entire term of the tenants’ tenancy, even if the tenancy is extended and/or changed in its terms.',
        type: 'singlecheckbox',
        options: personalGuaranteeOptions,
      },
    ],
  },
  {
    id: '203',
    title: 'GUARANTOR CURRENT ADDRESS',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'currentAdressGooglePlaces',
        label: '',
        required: true,
        type: 'googlePlaces',
        options: currentAdressGooglePlacesOptions,
      },
      {
        name: 'currentAdressCountry',
        label: 'Country',
        required: true,
        type: 'countryUs',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressLine1',
        label: 'Street Address 1',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressLine2',
        label: 'Street Address 2',
        required: true,
        type: 'text',
      },
      {
        name: 'currentAdresssCity',
        label: 'City / Town',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressState',
        label: 'State',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressZipCode',
        label: 'Zip Code',
        required: true,
        type: 'text',
        alwaysDisabled: true,
      },
      {
        name: 'currentAdressHowLong',
        label: 'How long have you been living at this address? ',
        required: true,
        type: 'date',
      },
      {
        name: 'currentAdressRentOrOwn',
        label: 'Do you rent or own this property? ',
        required: true,
        type: 'dropdown',
        options: currentAdressRentOrOwnOptions,
      },
    ],
  },
  {
    id: '204',
    title: 'GUARANTOR IDENTITY INFORMATION',
    text: '',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'ssn',
        label: 'Do you have a Social Security Number? ',
        required: true,
        type: 'longyesno',
      },
      {
        name: 'ssnNumber',
        label: 'Social Security Number',
        required: true,
        type: 'text',
        conditionField: 'ssn',
        condition: 'YES',
      },
      {
        name: 'otherCountryId',
        label: 'Other Country ID',
        required: true,
        type: 'text',
        conditionField: 'ssn',
        condition: 'NO',
      },
      { name: 'country', label: 'Country', required: true, type: 'country' },
      {
        name: 'documentType',
        label: 'Document type',
        required: true,
        type: 'dropdown',
        options: documentTypeOptions,
      },
      {
        name: 'govIssuingId',
        label: 'Government Issued ID',
        required: true,
        type: 'text',
      },
      {
        name: 'govIdNumber',
        label: 'Government ID Number',
        required: true,
        type: 'text',
      },
    ],
  },
  {
    id: '206',
    title: 'GUARANTOR CURRENT INCOME SOURCES',
    text: 'How are you going to guarantee to pay rent? ',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'howMakeAnnuallyAfterTax',
        label: 'How much do you make annually after tax?',
        required: false,
        type: 'text',
      },
      {
        name: 'primaryIncomeSource',
        label: 'Primary Income Source',
        required: true,
        type: 'dropdown',
        options: primaryIncomeSourceOptions,
      },
    ],
  },
  {
    id: '207',
    title: 'GUARANTOR EMPLOYMENT & INCOME INFORMATION',
    text: 'How are you going to guarantee to pay rent? ',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'doYouHaveACurrentJob',
        label: 'Do you have a current job?',
        required: true,
        type: 'yesno',
      },
      {
        name: 'guarantorJobs',
        label: 'Job',
        required: true,
        type: 'fieldArray',
        conditionField: 'doYouHaveACurrentJob',
        condition: 'YES',
        options: jobFields,
      },
    ],
  },
  {
    id: '209',
    title: 'GUARANTOR TERMS AND ACKNOWLEDGEMENT',
    text: 'Terms and Acknowledgement',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'termsAck',
        label:
          'No agreement, either written or oral, shall be binding on applicant, agent, or owner, except to the extent that such agreement is set forth in a written Lease Agreement executed by such parties. Acceptance of this application does not assure a room reservation. A bedroom accommodation shall be reserved only upon signing of a Lease Agreement by all parties before the applicable property reaches full capacity. This application will be approved only upon confirmation of a satisfactory criminal background check and verification that all rental requirements and qualifications have been met.',
        type: 'singlecheckbox',
        options: termsAckOptions,
      },
    ],
  },
  {
    id: '210',
    title: 'GUARANTOR SCREENING AND CREDIT CHECK CONSENT',
    text: 'Background Screening and Credit Check Consent',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'checkConsent',
        label:
          "I agree that you may conduct a credit, background, and reference check on me. In connection therewith, I hereby authorize you to (a) use any consumer reporting agency, credit bureau, or other investigative agencies employed by such to investigate the references set forth herein and other information obtained from me (or any other person) pertaining to my employment history, credit history, character, general reputation, personal characteristics and mode of living; (b) obtain a consumer report and such other credit information which may result therefrom, and (c) disclose and furnish such information to the owner's agent set forth above in support of my application. I acknowledge and agree that if I fail to answer any question or if I have provided false information: (1) you are entitled to reject this application; (2) you may retain all processing fees and deposits as liquidated damages for time spent and expenses; (3) you may terminate any right to lease the bedroom; and (4) if I have signed a lease, it will constitute a violation of the lease. I have been advised that I have the right, under Section 606B of the Fair Credit Reporting Act, to make a written request, within a reasonable time, for a complete and accurate disclosure of the nature and scope of this investigation.",
        type: 'singlecheckbox',
        options: termsAckOptions,
      },
    ],
  },
  {
    id: '211',
    title: 'GUARANTOR ACKNOWLEDGEMENT',
    text: 'Acknowledgement',
    userFields: [],
    applicationFields: [],
    extraApplicationFields: [],
    guarantorFields: [
      {
        name: 'acknowledgement',
        label:
          'Under penalties of perjury, I certify that the information presented on this form is true and accurate to the best of my knowledge. I understand that providing false representations herein constitutes an act of fraud. Inaccurate, misleading, or incomplete information will result in the denial of application or termination of the lease agreement. I Attest that I have read and understand all rental requirements and qualifications and agree to all terms and conditions above.',
        type: 'singlecheckbox',
        options: ackOptions,
      },
      {
        name: 'applicationAck',
        label:
          'I understand that this is only an application to rent and does not guarantee that I will be selected as a resident of the premises.',
        type: 'singlecheckbox',
        options: appAckOptions,
      },
    ],
  },
];
