import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MainLoader } from '../../shared/components/MainLoader';
import { useLocalStorage } from '../../shared/components/components-hooks';

// TODO add loading component
/**
 * @returns {JSX.Element} - Auth component.
 */

export function Auth(): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const [blocked] = useLocalStorage('blockedUser', '');
  const [loginHint] = useLocalStorage('loginHint', '');
  const [registerHint] = useLocalStorage('registerHint', '');
  const screenHint = React.useRef<'login' | 'signup'>();
  React.useEffect(() => {
    screenHint.current = 'login';
    if (registerHint && !loginHint) {
      screenHint.current = 'signup';
    }

    if (!blocked) {
      loginWithRedirect({
        appState: location.state,
        screen_hint: screenHint.current ?? 'login',
        login_hint: screenHint.current === 'login' ? loginHint : registerHint ?? '',
      });
    } 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (blocked) history.push('/blocked-user');

  return <MainLoader />;
}
