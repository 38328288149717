import { createAction } from '@8baselabs/react-simple-state';
import {
  AdminInvitationListResponse,
  User,
  Lease,
  AdminInvitation,
  Query,
  TermsAndConditionsUser,
  TermsAndConditionsUserCreateInput,
  PaymentMethod,
  PaymentMethodFilter,
  UserStripeCustomerFilter,
} from '../../schema-types';
import {
  OnFetchAdminInvitation,
  OnFetchAdminInvitationError,
  onFinishAdminInvitationErrorEvent,
  onFinishAdminInvitationEvent,
  onFinishRegisterErrorEvent,
  onFinishRegisterEvent,
  resendVerifyCodeErrorEvent,
  resendVerifyCodeEvent,
  OnUpdateUser,
  OnUpdateUserError,
  verifyCodeErrorEvent,
  verifyCodeEvent,
  OnFetchTermsError,
  OnFetchTerms,
  CreateUserTerms,
  CreateUserTermsError,
  OnUpdateGuarantor,
  OnUpdateGuarantorError,
  OnUpdateAllowCreditCards,
  OnUpdateAllowCreditCardsError,
  OnUpdateAllowChecks,
  OnUpdateAllowChecksError,
  OnCreateSetupIntent,
  OnCreateSetupIntentError,
  OnGetCardsPaymentMethods,
  OnGetCardsPaymentMethodsError,
  OnGetBanksPaymentMethods,
  OnGetBanksPaymentMethodsError,
  OnCreatePaymentIntent,
  OnCreatePaymentIntentError,
  OnCreatePropertyStripeAccountEvent,
  OnCreatePropertyStripeAccountError,
  OnCreateStripeAccountLinkEvent,
  OnCreateStripeAccountLinkError,
  OnGetStripeAccount,
  OnGetStripeAccountError,
  OnGetAllPaymentMethods,
  OnGetAllPaymentMethodsError,
  OnCreateAssessmentEvent,
  OnCreateAssessmentErrorEvent,
  OnSaveAssessmentErrorEvent,
  OnSaveAssessmentEvent,
} from './user-events';
import { apolloClient as client } from '../../shared/apollo';
import {
  ACCEPT_ADMIN_INVITATION_MUTATION,
  FETCH_ADMIN_INVITATION_QUERY,
  SEND_VERIFICATION_CODE_QUERY,
  USER_UPDATE_MUTATION,
  VERIFY_CODE_QUERY,
  UPDATE_INVITATION,
  FETCH_TERMS_AND_CONDITION,
  CREATE_USER_TERMS,
  CREATE_CUSTOMER_MUTATION,
  LIST_CARDS_PAYMENT_METHODS_QUERY,
  CREATE_SETUP_INTENT_MUTATION,
  LIST_BANKS_PAYMENT_METHODS_QUERY,
  CREATE_PAYMENT_INTENT_MUTATION,
  CREATE_PROPERTY_STRIPE_ACCOUNT_MUTATION,
  CREATE_STRIPE_ACCOUNT_LINK,
  GET_STRIPE_ACCOUNT,
  FETCH_USER_STRIPE_CUSTOMER_BY_FILTER,
  CREATE_ASSESSMENT_ID,
  SAVE_ASSESSMENT,
} from './user-queries';
import { ResultType } from '../../shared/constants';
import { StripeAccount } from '../settings-view/property-information/PropertyInformationView';
import { BankPaymentMethod, CardPaymentMethod } from '../people/people-types';
import { GET_PAYMENT_METHODS_BY_FILTER } from '../people/people-query';
import { Property } from '@8baselabs/resident-io-shared';

export const onFinishRegister = createAction(
  onFinishRegisterEvent,
  onFinishRegisterErrorEvent,
  async (user: User) => {
    // eslint-disable-next-line no-underscore-dangle

    const data: User = {
      id: user.id,
      phoneNumber: user.phoneNumber,
      state: user.state,
      school: user.school,
      firstName: user.firstName,
      lastName: user.lastName,
      gender: user.gender,
      registerFinish: user.registerFinish,
      birthdate: user.birthdate,
    };
    const response = await client.mutate({
      mutation: USER_UPDATE_MUTATION,
      variables: { data },
    });
    client.cache.reset();
    return response;
  },
);

export const resendVerifyCode = createAction(
  resendVerifyCodeEvent,
  resendVerifyCodeErrorEvent,
  async (id: string) =>
    client.query({
      query: SEND_VERIFICATION_CODE_QUERY,
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    }),
);

export const verifyCode = createAction(
  verifyCodeEvent,
  verifyCodeErrorEvent,
  async (code: number) =>
    (
      await client.query({
        query: VERIFY_CODE_QUERY,
        variables: {
          code,
        },
        fetchPolicy: 'no-cache',
      })
    ).data,
);
export const fetchTermAndConditionAction = createAction(
  OnFetchTerms,
  OnFetchTermsError,
  async () =>
    (
      await client.query<Query>({
        query: FETCH_TERMS_AND_CONDITION,
      })
    ).data.termAndConditionsList,
);
/**
 * @description - fetch invitation for owner.
 * @returns {void}.
 */

export const fetchAdminInvitation = createAction(
  OnFetchAdminInvitation,
  OnFetchAdminInvitationError,
  async () =>
    (
      await client.query<{ adminInvitationsList: AdminInvitationListResponse }>(
        {
          query: FETCH_ADMIN_INVITATION_QUERY,
          variables: {
            filter: {
              status: {
                equals: 'PENDING',
              },
            },
          },
        },
      )
    ).data.adminInvitationsList,
);

export const acceptAdminInvitation = createAction(
  onFinishAdminInvitationEvent,
  onFinishAdminInvitationErrorEvent,
  async (id: string) => {
    const result = (
      await client.mutate<{
        acceptAdminInvitation: ResultType;
      }>({
        mutation: ACCEPT_ADMIN_INVITATION_MUTATION,
        variables: { data: { id } },
      })
    ).data?.acceptAdminInvitation as ResultType;

    client.cache.reset();

    return result;
  },
);
export const rejectAdminInvitation = createAction(
  OnUpdateUser,
  OnUpdateUserError,
  async (id: string, invitation: string) => {
    await client.mutate<{
      adminInvitationUpdate: AdminInvitation;
    }>({
      mutation: UPDATE_INVITATION,
      variables: { data: { id: invitation, status: 'REJECTED' } },
    });
    const result = (
      await client.mutate<{
        userUpdate: User;
      }>({
        mutation: USER_UPDATE_MUTATION,
        variables: { data: { id, status: 'inactive' } },
      })
    ).data?.userUpdate as User;

    client.cache.reset();
    return result;
  },
);
export const AcceptTerms = createAction(
  CreateUserTerms,
  CreateUserTermsError,
  async (user: string, terms: string) => {
    const data: TermsAndConditionsUserCreateInput = {
      user: {
        connect: {
          id: user,
        },
      },
      termsAndCondition: {
        connect: {
          id: terms,
        },
      },
    };
    const result = await client.mutate<{
      termsAndConditionsUserCreate: TermsAndConditionsUserCreateInput;
    }>({
      mutation: CREATE_USER_TERMS,
      variables: { data },
    });

    client.cache.reset();
    return result.data?.termsAndConditionsUserCreate as TermsAndConditionsUser;
  },
);

export const updateGuarantorAction = createAction(
  OnUpdateGuarantor,
  OnUpdateGuarantorError,
  async (id: string, data): Promise<User> => {
    const result = await client.mutate<{
      userUpdate: User;
    }>({
      mutation: USER_UPDATE_MUTATION,
      variables: { data: { id, guarantorData: { update: data } } },
    });

    return result.data?.userUpdate as User;
  },
);

export const updateAllowCreditCardsAction = createAction(
  OnUpdateAllowCreditCards,
  OnUpdateAllowCreditCardsError,
  async (id: string, allowCreditCards: boolean): Promise<User> => {
    const result = await client.mutate<{
      userUpdate: User;
    }>({
      mutation: USER_UPDATE_MUTATION,
      variables: { data: { id, allowCreditCards } },
    });

    await client.cache.reset();

    return result.data?.userUpdate as User;
  },
);

export const updateAllowChecksAction = createAction(
  OnUpdateAllowChecks,
  OnUpdateAllowChecksError,
  async (id: string, allowChecks: boolean): Promise<User> => {
    const result = await client.mutate<{
      userUpdate: User;
    }>({
      mutation: USER_UPDATE_MUTATION,
      variables: { data: { id, allowChecks } },
    });

    await client.cache.reset();

    return result.data?.userUpdate as User;
  },
);

export const listCardsPaymentMethods = createAction(
  OnGetCardsPaymentMethods,
  OnGetCardsPaymentMethodsError,
  async (user: User, property: Property): Promise<CardPaymentMethod[]> => {
    const userStripeCustomerFilter: UserStripeCustomerFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
      property: {
        id: {
          equals: property.id,
        },
      },
    };

    const userStripeCustomerResult = await client.query({
      query: FETCH_USER_STRIPE_CUSTOMER_BY_FILTER,
      variables: {
        filter: userStripeCustomerFilter,
      },
    });

    const stripeCustomerId =
      userStripeCustomerResult.data.userStripeCustomersList.items[0]
        ?.stripeCustomerId;

    if (!stripeCustomerId) {
      // Return empty results instead of throwing to avoid faulty behaviour on autopay
      return [];
      throw new Error('No stripe customer assigned to this user');
    }

    const filter: PaymentMethodFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
    };

    const response = await client.query<Query>({
      query: GET_PAYMENT_METHODS_BY_FILTER,
      variables: {
        filter,
      },
    });

    const paymentMethods = response.data.paymentMethodsList
      .items as PaymentMethod[];

    const result = await client.query<{
      listCardPaymentMethods: CardPaymentMethod[];
    }>({
      query: LIST_CARDS_PAYMENT_METHODS_QUERY,
      variables: {
        stripeCustomerId,
        stripeAccountId: property.stripeAccountId,
      },
      fetchPolicy: 'no-cache',
    });

    const fullCardPaymentMethods: CardPaymentMethod[] = [];

    result.data.listCardPaymentMethods.forEach((cardPaymentMethod) => {
      const foundPaymentMethod = paymentMethods.find(
        (pm) => pm.stripeId === cardPaymentMethod.id,
      );

      if (foundPaymentMethod) {
        fullCardPaymentMethods.push({
          ...cardPaymentMethod,
          isPrimary: foundPaymentMethod?.isPrimary,
          stripeId: foundPaymentMethod?.stripeId,

          user: foundPaymentMethod?.user,
        } as CardPaymentMethod);
      }
    });

    return fullCardPaymentMethods;
  },
);

export const listBanksPaymentMethods = createAction(
  OnGetBanksPaymentMethods,
  OnGetBanksPaymentMethodsError,
  async (user: User, property: Property): Promise<BankPaymentMethod[]> => {
    const userStripeCustomerFilter: UserStripeCustomerFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
      property: {
        id: {
          equals: property.id,
        },
      },
    };

    const userStripeCustomerResult = await client.query({
      query: FETCH_USER_STRIPE_CUSTOMER_BY_FILTER,
      variables: {
        filter: userStripeCustomerFilter,
      },
    });

    const stripeCustomerId =
      userStripeCustomerResult.data.userStripeCustomersList.items[0]
        ?.stripeCustomerId;

    if (!stripeCustomerId) {
      return [];
      throw new Error('No stripe customer assigned to this user');
    }

    const filter: PaymentMethodFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
    };

    const response = await client.query<Query>({
      query: GET_PAYMENT_METHODS_BY_FILTER,
      variables: {
        filter,
      },
    });

    const paymentMethods = response.data.paymentMethodsList
      .items as PaymentMethod[];

    const result = await client.query<{
      listBanksPaymentMethods: BankPaymentMethod[];
    }>({
      query: LIST_BANKS_PAYMENT_METHODS_QUERY,
      variables: {
        stripeCustomerId,
        stripeAccountId: property.stripeAccountId,
      },
      fetchPolicy: 'no-cache',
    });

    const fullBankPaymentMethods: BankPaymentMethod[] = [];

    result.data.listBanksPaymentMethods.forEach((bankPaymentMethod) => {
      const foundPaymentMethod = paymentMethods.find(
        (pm) => pm.stripeId === bankPaymentMethod.id,
      );

      if (foundPaymentMethod) {
        fullBankPaymentMethods.push({
          ...bankPaymentMethod,
          isPrimary: foundPaymentMethod?.isPrimary,
          stripeId: foundPaymentMethod?.stripeId,

          user: foundPaymentMethod?.user,
        } as BankPaymentMethod);
      }
    });

    return fullBankPaymentMethods;
  },
);

export const listAllPaymentMethods = createAction(
  OnGetAllPaymentMethods,
  OnGetAllPaymentMethodsError,
  async (
    user: User,
    property: Property,
  ): Promise<(PaymentMethod | BankPaymentMethod)[]> => {
    const userStripeCustomerFilter: UserStripeCustomerFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
      property: {
        id: {
          equals: property.id,
        },
      },
    };

    const userStripeCustomerResult = await client.query({
      query: FETCH_USER_STRIPE_CUSTOMER_BY_FILTER,
      variables: {
        filter: userStripeCustomerFilter,
      },
    });

    const stripeCustomerId =
      userStripeCustomerResult.data.userStripeCustomersList.items[0]
        ?.stripeCustomerId;

    if (!stripeCustomerId) {
      return [];
      throw new Error('No stripe customer assigned to this user');
    }
    const result = await client.query<{
      listCardPaymentMethods: [PaymentMethod];
    }>({
      query: LIST_CARDS_PAYMENT_METHODS_QUERY,
      variables: {
        stripeCustomerId,
        stripeAccountId: property.stripeAccountId,
      },
      fetchPolicy: 'no-cache',
    });

    const resultBank = await client.query<{
      listBanksPaymentMethods: [BankPaymentMethod];
    }>({
      query: LIST_BANKS_PAYMENT_METHODS_QUERY,
      variables: {
        stripeCustomerId,
        stripeAccountId: property.stripeAccountId,
      },
      fetchPolicy: 'no-cache',
    });

    const allMethods = [
      ...resultBank.data.listBanksPaymentMethods,
      ...result.data.listCardPaymentMethods,
    ];

    return allMethods;
  },
);

export const createSetupIntentAction = createAction(
  OnCreateSetupIntent,
  OnCreateSetupIntentError,
  async (
    user: User,
    property: Property,
    type: string,
  ): Promise<{ client_secret: string }> => {
    const filter: UserStripeCustomerFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
      property: {
        id: {
          equals: property.id,
        },
      },
    };

    const userStripeCustomerResult = await client.query({
      query: FETCH_USER_STRIPE_CUSTOMER_BY_FILTER,
      variables: {
        filter: filter,
      },
      fetchPolicy: 'no-cache',
    });

    let stripeCustomerId =
      userStripeCustomerResult.data.userStripeCustomersList.items[0]
        ?.stripeCustomerId;

    if (!stripeCustomerId) {
      const result = await client.mutate<{
        createCustomer: { stripeCustomerId: string };
      }>({
        mutation: CREATE_CUSTOMER_MUTATION,
        variables: {
          userId: user.id,
          propertyId: property.id,
          stripeAccountId: property.stripeAccountId,
        },
      });

      stripeCustomerId = result.data?.createCustomer.stripeCustomerId;
    }

    const result = await client.mutate<{
      createSetupIntent: { id: string; client_secret: string };
    }>({
      mutation: CREATE_SETUP_INTENT_MUTATION,
      variables: {
        stripeCustomerId,
        stripeAccountId: property.stripeAccountId,
        type,
      },
    });

    return result.data?.createSetupIntent as { client_secret: string };
  },
);

export const createPaymentIntentAction = createAction(
  OnCreatePaymentIntent,
  OnCreatePaymentIntentError,
  async (
    user: User,
    amount: number,
    lease: Lease | undefined,
    paymentMethodId: string,
    paymentSubCategory?: string,
    paymentIntentFees?: string[],
    reservationFee?: number,
  ): Promise<{
    id: string;
    client_secret: string;
    connected_account_payment_method_id?: string;
    residentFee: number;
  }> => {
    const filter: UserStripeCustomerFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
      property: {
        id: {
          equals: lease?.property?.id,
        },
      },
    };

    const userStripeCustomerResult = await client.query({
      query: FETCH_USER_STRIPE_CUSTOMER_BY_FILTER,
      variables: {
        filter: filter,
      },
    });

    let stripeCustomerId =
      userStripeCustomerResult.data.userStripeCustomersList.items[0]
        ?.stripeCustomerId;

    if (!stripeCustomerId) {
      const result = await client.mutate<{
        createCustomer: { stripeCustomerId: string };
      }>({
        mutation: CREATE_CUSTOMER_MUTATION,
        variables: {
          userId: user.id,
          propertyId: lease?.property?.id,
          stripeAccountId: lease?.property?.stripeAccountId,
        },
      });

      stripeCustomerId = result.data?.createCustomer.stripeCustomerId;
    }
    const stripeAccountId = lease?.property?.stripeAccountId;

    const result = await client.mutate<{
      createPaymentIntent: {
        id: string;
        client_secret: string;
        connected_account_payment_method_id?: string;
      };
    }>({
      mutation: CREATE_PAYMENT_INTENT_MUTATION,
      variables: {
        amount,
        stripeCustomerId,
        stripeAccountId,
        paymentMethodId,
        paymentSubCategory: paymentSubCategory || undefined,
        paymentIntentFees: paymentIntentFees || [],
        clientId: lease?.property?.client?.id || '',
        reservationFee,
      },
    });

    return result.data?.createPaymentIntent as {
      id: string;
      client_secret: string;
      connected_account_payment_method_id?: string;
      residentFee: number;
    };
  },
);

export const createPropertyStripeAccount = createAction(
  OnCreatePropertyStripeAccountEvent,
  OnCreatePropertyStripeAccountError,
  async (property: Property): Promise<{ accountId: string }> => {
    const result = await client.mutate<{
      createPropertyStripeAccount: { accountId: string };
    }>({
      mutation: CREATE_PROPERTY_STRIPE_ACCOUNT_MUTATION,
      variables: { email: property.email },
    });

    await client.cache.reset();

    return result.data?.createPropertyStripeAccount as { accountId: string };
  },
);

export const createStripeAccountLink = createAction(
  OnCreateStripeAccountLinkEvent,
  OnCreateStripeAccountLinkError,
  async (
    accountId: string,
    refreshUrl: string,
    returnUrl: string,
  ): Promise<{ accountLink: string }> => {
    const result = await client.mutate<{
      createStripeAccountLink: { accountLink: string };
    }>({
      mutation: CREATE_STRIPE_ACCOUNT_LINK,
      variables: {
        accountId,
        refreshUrl,
        returnUrl,
      },
    });

    return result.data?.createStripeAccountLink as { accountLink: string };
  },
);

/**
 * Get property stripe account.
 */
export const getStripeAccount = createAction(
  OnGetStripeAccount,
  OnGetStripeAccountError,
  async (accountId: string): Promise<StripeAccount> => {
    const response = await client.query({
      query: GET_STRIPE_ACCOUNT,
      variables: { accountId },
    });
    return response.data.getStripeAccount.stripeAccount as StripeAccount;
  },
);

export const createAssessment = createAction(
  OnCreateAssessmentEvent,
  OnCreateAssessmentErrorEvent,
  async (userId: string) =>
    (
      await client.mutate({
        mutation: CREATE_ASSESSMENT_ID,
        variables: {
          userId,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.createAssessment.assessmentId,
);

export const saveAssessment = createAction(
  OnSaveAssessmentEvent,
  OnSaveAssessmentErrorEvent,
  async (userId: string, assessmentId: string) =>
    (
      await client.mutate({
        mutation: SAVE_ASSESSMENT,
        variables: {
          userId,
          assessmentId,
        },
        fetchPolicy: 'no-cache',
      })
    ).data.SaveAssessmentResults.success,
);
