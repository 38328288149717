import * as filestack from 'filestack-js';
import moment from 'moment';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { Link } from 'react-router-dom';
import { useCallAction, useFetchAction } from '@8baselabs/react-simple-state';

import { MUTED_COLOR, PRIMARY_COLOR } from '../../../shared/theme';
import { FetchSessionData } from '../../session/session-types';
import { TENANT_ROUTES } from '../../../routes/types';
import { useActiveRouteName } from '../../../shared/hooks';
import { useSession } from '../../session/session-hooks';
import {
  createTenantProfiAction,
  fetchUploadInfoAction,
  updateTenantProfileAction,
  updateUserAction,
} from './my-info-actions';
import { AlertContext } from '../../../routes/AlertContext';
import { MainLoader } from '../../../shared/components/MainLoader';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '0px 150px',
    },
    title: {
      padding: theme.spacing(0, 0, 4, 0),
    },
    borderRight: {
      borderRight: `1px solid ${MUTED_COLOR}`,
      width: '100%',
    },
    content: {
      minHeight: '600px',
      width: '100%',
    },
    birthDate: {
      fontWeight: 'normal',
    },
    userMenuItemIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
      fontSize: '2.7em',
      fontWeight: 500,
      height: theme.spacing(12),
      width: theme.spacing(12),
      borderRadius: '50%',
      overflow: 'hidden',
    },
    selector: {
      width: '96px',
      height: '96px',
      position: 'relative',
      cursor: 'pointer',
    },
    prompt: {
      opacity: '0',
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.31)',
      transition: '300ms',
      color: '#fafafa',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
      '&:hover': {
        opacity: '1',
      },
    },
    loadingProfile: {
      width: '96px',
      height: '96px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #d0d0d0',
      borderRadius: '50%',
    },
    fontWeight: {
      fontWeight: 500,
    },
    drawerLink: {
      color: 'inherit',
      textDecoration: 'none',
      '::focus': {
        color: 'inherit',
      },
    },
    drawerListItem: {
      borderLeft: '5px solid transparent',
      padding: theme.spacing(1, 3),
      width: '100%',
    },
    drawerListItemIcon: {
      minWidth: 35,
    },
    selectedListItem: {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      borderLeft: `5px solid ${PRIMARY_COLOR}`,
    },
    selectedListItemIcon: {
      color: `${PRIMARY_COLOR} !important`,
    },
  }),
);

type AccountSettingsProps = {
  children: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  session: FetchSessionData | null;
  loading?: boolean;
};

/**
 * @param root - Account Settings Props.
 * @param root.children - React Node to render in this section.
 * @param root.loading - Wether the component is loading or not.
 * @returns - AccountSettings Container view.
 */
export const MyInfo: React.FC<AccountSettingsProps> = ({
  children,
  loading,
}) => {
  const classes = useStyles();
  const activeRouteName = useActiveRouteName();

  const [session, , loadingSession] = useSession();
  const [path, setPath] = useState<string>();
  const [file, setFile] = useState<{ fileId?: string; filename?: string }>();
  const [clientFileStack, setClientFileStack] = useState<filestack.Client>();
  const alert = useContext(AlertContext);

  const [updateUser, isLoading] = useCallAction(updateUserAction, {
    onCompleted() {
      alert({
        message: 'Account Settings successfully saved',
        success: true,
      });
      setFile(undefined);
    },
  });
  useEffect(() => {
    if (file?.fileId)
    updateUser({
      id: session?.user.id,
      avatar: {
        create: {
          fileId: file?.fileId,
          filename: file?.filename,
        },
      },
    });
  }, [file, session, updateUser]);

  useFetchAction(fetchUploadInfoAction, [], {
    onCompleted(wrapperUploadInfo) {
      setPath(wrapperUploadInfo.path);
      const clientOptions = {
        security: {
          policy: wrapperUploadInfo.policy,
          signature: wrapperUploadInfo.signature,
        },
      };
      setClientFileStack(
        filestack.init(wrapperUploadInfo.apiKey, clientOptions),
      );
    },
  });

  useCallAction(updateTenantProfileAction, {
    onCompleted() {
      alert({
        message: 'Profile changes successfully saved',
        success: true,
      });
      setFile(undefined);
    },
    onError() {
      alert({
        message: 'Something was wrong',
        error: true,
      });
    },
  });

  useCallAction(createTenantProfiAction, {
    onCompleted() {
      alert({
        message: 'Profile changes successfully saved',
        success: true,
      });
      setFile(undefined);
    },
    onError() {
      alert({
        message: 'Something was wrong',
        error: true,
      });
    },
  });

  const onUploadDone = useCallback((files: filestack.PickerResponse): void => {
    if (files.filesUploaded.length > 0) {
      files.filesUploaded.forEach(
        (photoToCreate: filestack.PickerFileMetadata) => {
          setFile({
            fileId: photoToCreate.handle,
            filename: photoToCreate.filename,
          });
        },
      );
    }
  }, []);

  const handleAddAvatar = useCallback((): void => {
    if (clientFileStack !== undefined) {
      const options = {
        fromSources: [
          'local_file_system',
        
        ],
        accept: 'image/jpeg',
        maxFiles: 1,
        onUploadDone,
        storeTo: { path },
      };
      clientFileStack.picker(options).open();
    }
  }, [clientFileStack, onUploadDone, path]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container className={matches ? classes.container : ''}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}>
        <Grid item xs>
          <Typography variant="h4" className={classes.title}>
            Account Settings
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              justifyContent="space-between"
              style={{ height: '100%' }}>
              <Grid item md={3} className={classes.borderRight}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  marginY={5}>
                  <div
                    className={classes.selector}
                    onClick={handleAddAvatar}
                    role="button"
                    tabIndex={0}>
                    {isLoading ? (
                      <div className={classes.loadingProfile}>
                        <CircularProgress color="primary" />
                      </div>
                    ) : (
                      <>
                        <Avatar
                          src={session?.user.avatar?.downloadUrl}
                          className={classes.userMenuItemIcon}>
                          {session?.user.firstName?.[0]}{' '}
                          {session?.user.lastName?.[0]}
                        </Avatar>
                        <div className={classes.prompt}>
                          <PhotoCameraIcon />
                          <Typography variant="subtitle2">Change</Typography>
                        </div>
                      </>
                    )}
                  </div>
                  <Typography variant="h6" className={classes.fontWeight}>
                    {session?.user.firstName} {session?.user.lastName}
                  </Typography>
                  <Typography className={classes.birthDate}>
                    {session?.user.gender ?? 'OTHER'} -&nbsp;
                    {moment(session?.user.birthdate).format('DD MMM YYYY')}
                  </Typography>
                </Box>
                <Divider />
                {matches ? (
                  <>
                    <List>
                      <Link
                        to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.contactinfo.path}`}
                        className={classes.drawerLink}>
                        <ListItem
                          button
                          classes={{
                            selected: classes.selectedListItem,
                          }}
                          className={classes.drawerListItem}
                          selected={
                            activeRouteName ===
                            TENANT_ROUTES.profile.subRoutes?.contactinfo.name
                          }>
                          <ListItemIcon
                            classes={{
                              root:
                                activeRouteName ===
                                TENANT_ROUTES.profile.subRoutes?.contactinfo
                                  .name
                                  ? classes.selectedListItemIcon
                                  : undefined,
                            }}
                            className={classes.drawerListItemIcon}>
                            {TENANT_ROUTES.profile.subRoutes?.contactinfo.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              TENANT_ROUTES.profile.subRoutes?.contactinfo.name
                            }
                          />
                        </ListItem>
                      </Link>
                    </List>
                    <Link
                      to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.socialnetworks.path}`}
                      className={classes.drawerLink}>
                      <ListItem
                        button
                        classes={{
                          selected: classes.selectedListItem,
                        }}
                        className={classes.drawerListItem}
                        selected={
                          activeRouteName ===
                          TENANT_ROUTES.profile.subRoutes?.socialnetworks.name
                        }>
                        <ListItemIcon
                          classes={{
                            root:
                              activeRouteName ===
                              TENANT_ROUTES.profile.subRoutes?.socialnetworks
                                .name
                                ? classes.selectedListItemIcon
                                : undefined,
                          }}
                          className={classes.drawerListItemIcon}>
                          {TENANT_ROUTES.profile.subRoutes?.socialnetworks.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            TENANT_ROUTES.profile.subRoutes?.socialnetworks.name
                          }
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personality.path}`}
                      className={classes.drawerLink}>
                      <ListItem
                        button
                        classes={{
                          selected: classes.selectedListItem,
                        }}
                        className={classes.drawerListItem}
                        selected={
                          activeRouteName ===
                          TENANT_ROUTES.profile.subRoutes?.personality.name
                        }>
                        <ListItemIcon
                          classes={{
                            root:
                              activeRouteName ===
                              TENANT_ROUTES.profile.subRoutes?.personality.name
                                ? classes.selectedListItemIcon
                                : undefined,
                          }}
                          className={classes.drawerListItemIcon}>
                          {TENANT_ROUTES.profile.subRoutes?.personality.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            TENANT_ROUTES.profile.subRoutes?.personality.name
                          }
                        />
                      </ListItem>
                    </Link>
                    {session?.user.traitifyAssessmentID && (
                      <Link
                        to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personalitydetails.path}`}
                        className={classes.drawerLink}>
                        <ListItem
                          button
                          classes={{
                            selected: classes.selectedListItem,
                          }}
                          className={classes.drawerListItem}
                          selected={
                            activeRouteName ===
                            TENANT_ROUTES.profile.subRoutes?.personalitydetails
                              .name
                          }>
                          <ListItemIcon
                            classes={{
                              root:
                                activeRouteName ===
                                TENANT_ROUTES.profile.subRoutes
                                  ?.personalitydetails.name
                                  ? classes.selectedListItemIcon
                                  : undefined,
                            }}
                            className={classes.drawerListItemIcon}>
                            {
                              TENANT_ROUTES.profile.subRoutes
                                ?.personalitydetails.icon
                            }
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              TENANT_ROUTES.profile.subRoutes
                                ?.personalitydetails.name
                            }
                          />
                        </ListItem>
                      </Link>
                    )}
                    {session?.user.traitifyAssessmentID && (
                      <Link
                        to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.traits.path}`}
                        className={classes.drawerLink}>
                        <ListItem
                          button
                          classes={{
                            selected: classes.selectedListItem,
                          }}
                          className={classes.drawerListItem}
                          selected={
                            activeRouteName ===
                            TENANT_ROUTES.profile.subRoutes?.traits.name
                          }>
                          <ListItemIcon
                            classes={{
                              root:
                                activeRouteName ===
                                TENANT_ROUTES.profile.subRoutes?.traits.name
                                  ? classes.selectedListItemIcon
                                  : undefined,
                            }}
                            className={classes.drawerListItemIcon}>
                            {TENANT_ROUTES.profile.subRoutes?.traits.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              TENANT_ROUTES.profile.subRoutes?.traits.name
                            }
                          />
                        </ListItem>
                      </Link>
                    )}
                    {session?.user.traitifyAssessmentID && (
                      <Link
                        to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personalitybreakdown.path}`}
                        className={classes.drawerLink}>
                        <ListItem
                          button
                          classes={{
                            selected: classes.selectedListItem,
                          }}
                          className={classes.drawerListItem}
                          selected={
                            activeRouteName ===
                            TENANT_ROUTES.profile.subRoutes
                              ?.personalitybreakdown.name
                          }>
                          <ListItemIcon
                            classes={{
                              root:
                                activeRouteName ===
                                TENANT_ROUTES.profile.subRoutes
                                  ?.personalitybreakdown.name
                                  ? classes.selectedListItemIcon
                                  : undefined,
                            }}
                            className={classes.drawerListItemIcon}>
                            {
                              TENANT_ROUTES.profile.subRoutes
                                ?.personalitybreakdown.icon
                            }
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              TENANT_ROUTES.profile.subRoutes
                                ?.personalitybreakdown.name
                            }
                          />
                        </ListItem>
                      </Link>
                    )}

                    <Link
                      to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.tenanthistory.path}`}
                      className={classes.drawerLink}>
                      <ListItem
                        button
                        classes={{
                          selected: classes.selectedListItem,
                        }}
                        className={classes.drawerListItem}
                        selected={
                          activeRouteName ===
                          TENANT_ROUTES.profile.subRoutes?.tenanthistory.name
                        }>
                        <ListItemIcon
                          classes={{
                            root:
                              activeRouteName ===
                              TENANT_ROUTES.profile.subRoutes?.tenanthistory
                                .name
                                ? classes.selectedListItemIcon
                                : undefined,
                          }}
                          className={classes.drawerListItemIcon}>
                          {TENANT_ROUTES.profile.subRoutes?.tenanthistory.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            TENANT_ROUTES.profile.subRoutes?.tenanthistory.name
                          }
                        />
                      </ListItem>
                    </Link>
                    {/* <Link
                  to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.identity.path}`}
                  className={classes.drawerLink}>
                  <ListItem
                    button
                    classes={{
                      selected: classes.selectedListItem,
                    }}
                    className={classes.drawerListItem}
                    selected={
                      activeRouteName ===
                      TENANT_ROUTES.profile.subRoutes?.identity.name
                    }>
                    <ListItemIcon
                      classes={{
                        root:
                          activeRouteName ===
                          TENANT_ROUTES.profile.subRoutes?.identity.name
                            ? classes.selectedListItemIcon
                            : undefined,
                      }}
                      className={classes.drawerListItemIcon}>
                      {TENANT_ROUTES.profile.subRoutes?.identity.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={TENANT_ROUTES.profile.subRoutes?.identity.name}
                    />
                  </ListItem>
                </Link> */}
                    <Link
                      to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.notifications.path}`}
                      className={classes.drawerLink}>
                      <ListItem
                        button
                        classes={{
                          selected: classes.selectedListItem,
                        }}
                        className={classes.drawerListItem}
                        selected={
                          activeRouteName ===
                          TENANT_ROUTES.profile.subRoutes?.notifications.name
                        }>
                        <ListItemIcon
                          classes={{
                            root:
                              activeRouteName ===
                              TENANT_ROUTES.profile.subRoutes?.notifications
                                .name
                                ? classes.selectedListItemIcon
                                : undefined,
                          }}
                          className={classes.drawerListItemIcon}>
                          {TENANT_ROUTES.profile.subRoutes?.notifications.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            TENANT_ROUTES.profile.subRoutes?.notifications.name
                          }
                        />
                      </ListItem>
                    </Link>
                    <Link
                      to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.security.path}`}
                      className={classes.drawerLink}>
                      <ListItem
                        button
                        classes={{
                          selected: classes.selectedListItem,
                        }}
                        className={classes.drawerListItem}
                        selected={
                          activeRouteName ===
                          TENANT_ROUTES.profile.subRoutes?.security.name
                        }>
                        <ListItemIcon
                          classes={{
                            root:
                              activeRouteName ===
                              TENANT_ROUTES.profile.subRoutes?.security.name
                                ? classes.selectedListItemIcon
                                : undefined,
                          }}
                          className={classes.drawerListItemIcon}>
                          {TENANT_ROUTES.profile.subRoutes?.security.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            TENANT_ROUTES.profile.subRoutes?.security.name
                          }
                        />
                      </ListItem>
                    </Link>
                  </>
                ) : (
                  <>
                    <Tabs
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                      variant="scrollable"
                      value={activeRouteName}>
                      <Tab
                        value={
                          TENANT_ROUTES.profile.subRoutes?.contactinfo.name
                        }
                        icon={
                          <Link
                            to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.contactinfo.path}`}
                            className={classes.drawerLink}>
                            <ListItemIcon
                              classes={{
                                root:
                                  activeRouteName ===
                                  TENANT_ROUTES.profile.subRoutes?.contactinfo
                                    .name
                                    ? classes.selectedListItemIcon
                                    : undefined,
                              }}
                              className={classes.drawerListItemIcon}>
                              {
                                TENANT_ROUTES.profile.subRoutes?.contactinfo
                                  .icon
                              }
                            </ListItemIcon>
                          </Link>
                        }></Tab>
                      <Tab
                        value={
                          TENANT_ROUTES.profile.subRoutes?.socialnetworks.name
                        }
                        icon={
                          <Link
                            to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.socialnetworks.path}`}
                            className={classes.drawerLink}>
                            <ListItemIcon
                              classes={{
                                root:
                                  activeRouteName ===
                                  TENANT_ROUTES.profile.subRoutes
                                    ?.socialnetworks.name
                                    ? classes.selectedListItemIcon
                                    : undefined,
                              }}
                              className={classes.drawerListItemIcon}>
                              {
                                TENANT_ROUTES.profile.subRoutes?.socialnetworks
                                  .icon
                              }
                            </ListItemIcon>
                          </Link>
                        }></Tab>
                      <Tab
                        value={
                          TENANT_ROUTES.profile.subRoutes?.personality.name
                        }
                        icon={
                          <Link
                            to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personality.path}`}
                            className={classes.drawerLink}>
                            <ListItemIcon
                              classes={{
                                root:
                                  activeRouteName ===
                                  TENANT_ROUTES.profile.subRoutes?.personality
                                    .name
                                    ? classes.selectedListItemIcon
                                    : undefined,
                              }}
                              className={classes.drawerListItemIcon}>
                              {
                                TENANT_ROUTES.profile.subRoutes?.personality
                                  .icon
                              }
                            </ListItemIcon>
                          </Link>
                        }></Tab>
                      {session?.user.traitifyAssessmentID && (
                        <Tab
                          value={
                            TENANT_ROUTES.profile.subRoutes?.personalitydetails
                              .name
                          }
                          icon={
                            <Link
                              to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personalitydetails.path}`}
                              className={classes.drawerLink}>
                              <ListItemIcon
                                classes={{
                                  root:
                                    activeRouteName ===
                                    TENANT_ROUTES.profile.subRoutes
                                      ?.personalitydetails.name
                                      ? classes.selectedListItemIcon
                                      : undefined,
                                }}
                                className={classes.drawerListItemIcon}>
                                {
                                  TENANT_ROUTES.profile.subRoutes
                                    ?.personalitydetails.icon
                                }
                              </ListItemIcon>
                            </Link>
                          }
                        />
                      )}
                      {session?.user.traitifyAssessmentID && (
                        <Tab
                          value={TENANT_ROUTES.profile.subRoutes?.traits.name}
                          icon={
                            <Link
                              to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.traits.path}`}
                              className={classes.drawerLink}>
                              <ListItemIcon
                                classes={{
                                  root:
                                    activeRouteName ===
                                    TENANT_ROUTES.profile.subRoutes?.traits.name
                                      ? classes.selectedListItemIcon
                                      : undefined,
                                }}
                                className={classes.drawerListItemIcon}>
                                {TENANT_ROUTES.profile.subRoutes?.traits.icon}
                              </ListItemIcon>
                            </Link>
                          }
                        />
                      )}
                      {session?.user.traitifyAssessmentID && (
                        <Tab
                          value={
                            TENANT_ROUTES.profile.subRoutes
                              ?.personalitybreakdown.name
                          }
                          icon={
                            <Link
                              to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.personalitybreakdown.path}`}
                              className={classes.drawerLink}>
                              <ListItemIcon
                                classes={{
                                  root:
                                    activeRouteName ===
                                    TENANT_ROUTES.profile.subRoutes
                                      ?.personalitybreakdown.name
                                      ? classes.selectedListItemIcon
                                      : undefined,
                                }}
                                className={classes.drawerListItemIcon}>
                                {
                                  TENANT_ROUTES.profile.subRoutes
                                    ?.personalitybreakdown.icon
                                }
                              </ListItemIcon>
                            </Link>
                          }
                        />
                      )}
                      <Tab
                        value={
                          TENANT_ROUTES.profile.subRoutes?.tenanthistory.name
                        }
                        icon={
                          <Link
                            to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.tenanthistory.path}`}
                            className={classes.drawerLink}>
                            <ListItemIcon
                              classes={{
                                root:
                                  activeRouteName ===
                                  TENANT_ROUTES.profile.subRoutes?.tenanthistory
                                    .name
                                    ? classes.selectedListItemIcon
                                    : undefined,
                              }}
                              className={classes.drawerListItemIcon}>
                              {
                                TENANT_ROUTES.profile.subRoutes?.tenanthistory
                                  .icon
                              }
                            </ListItemIcon>
                          </Link>
                        }></Tab>
                      <Tab
                        value={
                          TENANT_ROUTES.profile.subRoutes?.notifications.name
                        }
                        icon={
                          <Link
                            to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.notifications.path}`}
                            className={classes.drawerLink}>
                            <ListItemIcon
                              classes={{
                                root:
                                  activeRouteName ===
                                  TENANT_ROUTES.profile.subRoutes?.notifications
                                    .name
                                    ? classes.selectedListItemIcon
                                    : undefined,
                              }}
                              className={classes.drawerListItemIcon}>
                              {
                                TENANT_ROUTES.profile.subRoutes?.notifications
                                  .icon
                              }
                            </ListItemIcon>
                          </Link>
                        }></Tab>
                      <Tab
                        value={TENANT_ROUTES.profile.subRoutes?.security.name}
                        icon={
                          <Link
                            to={`${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.security.path}`}
                            className={classes.drawerLink}>
                            <ListItemIcon
                              classes={{
                                root:
                                  activeRouteName ===
                                  TENANT_ROUTES.profile.subRoutes?.security.name
                                    ? classes.selectedListItemIcon
                                    : undefined,
                              }}
                              className={classes.drawerListItemIcon}>
                              {TENANT_ROUTES.profile.subRoutes?.security.icon}
                            </ListItemIcon>
                          </Link>
                        }></Tab>
                    </Tabs>
                  </>
                )}
              </Grid>
              <Grid item md={9} className={classes.content}>
                {!loadingSession ? children : <MainLoader />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
