import {
  Account,
  Lease,
  LedgerCreateUpdateDialogCallers,
} from '@8baselabs/resident-io-shared';
import { Typography, Grid, MenuItem, TextField } from '@material-ui/core';
import { MainLoader } from '../../../../shared/components/MainLoader';
import { sanitizeTextToTitleCase } from '../../../../shared/utils/index';
import { PaymentIntent } from '../../people-types';
import { LedgerState, ErrorStateType } from './LedgerCreateUpdateDialog';

type AccountSelectorProps = {
  accounts: Account[];
  uniqueCategories: (string | undefined)[];
  uniqueTransactionTypes: (string | undefined)[];
  uniqueSubCategories: (string | undefined)[];
  stateErrors: ErrorStateType;
  initialStateErrors: ErrorStateType;
  setStateErrors: React.Dispatch<React.SetStateAction<ErrorStateType>>;
  state: LedgerState;
  caller: string;
  setState: React.Dispatch<React.SetStateAction<LedgerState>>;
  lease: Lease;
  isEditing: boolean;
  loadingAccounts: boolean;
  paymentIntent?: PaymentIntent;
  isTenant: boolean;
  allowChecks?: boolean;
  allowCreditCards?: boolean;
};

export const AccountSelector: React.FC<AccountSelectorProps> = (props) => {
  const {
    accounts,
    uniqueCategories,
    uniqueTransactionTypes,
    uniqueSubCategories,
    stateErrors,
    initialStateErrors,
    setStateErrors,
    state,
    setState,
    caller,
    lease,
    isEditing,
    loadingAccounts,
    paymentIntent,
    isTenant,
    allowChecks,
    allowCreditCards,
  } = props;

  const paymentIntentAlreadyCreated = !!paymentIntent;

  const getDisabled = (item: string | undefined): boolean => {
    if (!item) return true;

    if (allowChecks === undefined || allowCreditCards === undefined)
      return false;

    if (
      item === 'ONLINE_ACH_ECHECK' &&
      (!lease?.property?.siteSetting?.enableOnlineLeasingACHPayment ||
        !allowChecks)
    )
      return true;

    if (
      item === 'ONLINE_CREDIT_CARD' &&
      (!lease?.property?.siteSetting?.enableOnlineLeasingCreditCardPayment ||
        !allowCreditCards)
    )
      return true;

    return false;
  };

  return loadingAccounts ? (
    <Grid item xs={12}>
      <MainLoader></MainLoader>
    </Grid>
  ) : (
    <>
      <Grid item xs={12}>
        <TextField
          select
          error={stateErrors.category}
          value={state.category}
          onChange={(e) => {
            const selectedType = accounts?.find(
              (account) => account?.accountCategory?.name === e.target.value,
              )?.defaultSign;
            setStateErrors(initialStateErrors);
            setState((prevProps) => ({
              ...prevProps,
              type: selectedType || '',
              category: e.target.value,
              paymentType: '',
              subCategory: '',
            }));
          }}
          helperText={stateErrors.category && 'This field is required'}
          variant="outlined"
          fullWidth
          label="CATEGORY"
          disabled={
            paymentIntentAlreadyCreated ||
            isEditing ||
            isTenant ||
            caller === LedgerCreateUpdateDialogCallers.NEW_LEAD
          }>
          {uniqueCategories.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                <Typography>{sanitizeTextToTitleCase(item)}</Typography>
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          error={stateErrors.paymentType}
          value={state.paymentType}
          onChange={(event) => {
            setStateErrors(initialStateErrors);
            setState((prevProps) => ({
              ...prevProps,
              paymentType: event.target.value,
              subCategory: '',
            }));
          }}
          helperText={stateErrors.paymentType && 'This field is required'}
          variant="outlined"
          fullWidth
          label="PAYMENT TYPE"
          disabled={
            paymentIntentAlreadyCreated || !state.category || isEditing
          }>
          {uniqueTransactionTypes.map((item, index) => {
            return (
              <MenuItem key={index} value={item}>
                <Typography>{sanitizeTextToTitleCase(item)}</Typography>
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          error={stateErrors.subCategory}
          value={state.subCategory}
          onChange={(event) => {
            const selectedAccount = accounts?.find(
              (account) =>
                account?.accountSubCategory?.name === event.target.value,
            );
            setStateErrors(initialStateErrors);
            setState((prevProps) => ({
              ...prevProps,
              description: `${lease?.leasePeriod?.name || ''} - ${
                selectedAccount?.accountSubCategory?.displayName
              }`,
              subCategory: event.target.value,
            }));
          }}
          helperText={stateErrors.subCategory && 'This field is required'}
          variant="outlined"
          disabled={
            paymentIntentAlreadyCreated ||
            !state.category ||
            !state.paymentType ||
            isEditing
          }
          fullWidth
          label="CATEGORY DESCRIPTION">
          {uniqueSubCategories.map((item, index) => {
            if (item === 'BITCOIN') return <></>;
            return (
              <MenuItem key={index} value={item} disabled={getDisabled(item)}>
                <Typography>{sanitizeTextToTitleCase(item)}</Typography>
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    </>
  );
};
