export const MESSAGE_TEMPLATE_TYPE_OPTIONS = [
  {
    label: 'Email Template',
    value: 'email',
  },
  {
    label: 'SMS Template',
    value: 'sms',
  },
];

export const VARIABLES_MODELS = [
  {
    label: 'Resident First Name',
    value: 'Resident First Name',
  },
  {
    label: 'Resident Last Name',
    value: 'Resident Last Name',
  },
  {
    label: 'Resident Full Name',
    value: 'Resident Full Name',
  },
  {
    label: 'Resident Email',
    value: 'Resident Email',
  },
  {
    label: 'Resident Current Balance',
    value: 'Resident Current Balance',
  },
  {
    label: 'Resident Balance Owed',
    value: 'Resident Balance Owed',
  },
  {
    label: 'Resident Login URL',
    value: 'Resident Login URL',
  },

  {
    label: 'Resident Payment URL',
    value: 'Resident Payment URL',
  },
  {
    label: 'Resident Move-In Date',
    value: 'Resident Move-In Date',
  },
  {
    label: 'Resident Move-Out Date',
    value: 'Resident Move-Out Date',
  },
  {
    label: 'Resident Bed Assignment',
    value: 'Resident Bed Assignment',
  },
  {
    label: 'Resident Assigned Property',
    value: 'Resident Assigned Property',
  },
  {
    label: 'Resident Reservation Status',
    value: 'Resident Reservation Status',
  },
  {
    label: 'Guarantor First Name',
    value: 'Guarantor First Name',
  },

  {
    label: 'Guarantor Last Name',
    value: 'Guarantor Last Name',
  },
  {
    label: 'Guarantor Full Name',
    value: 'Guarantor Full Name',
  },
  {
    label: 'Guarantor Email',
    value: 'Guarantor Email',
  },
];

export const VARIABLES_MODELS_SUBJECT = [
  {
    label: 'Resident First Name',
    value: 'Resident First Name',
  },
  {
    label: 'Resident Full Name',
    value: 'Resident Full Name',
  },
  {
    label: 'Resident Last Name',
    value: 'Resident Last Name',
  },
  {
    label: 'Resident Email',
    value: 'Resident Email',
  },
];

export const VARIABLES_MODELS_COMPOSE = [
  {
    label: 'Resident First Name',
    value: 'Resident First Name',
  },
  {
    label: 'Resident Last Name',
    value: 'Resident Last Name',
  },
  {
    label: 'Resident Full Name',
    value: 'Resident Full Name',
  },
  {
    label: 'Resident Email',
    value: 'Resident Email',
  },
];
