import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_BACKGROUND_COLOR,
} from '../../theme';
import mastercardLogo from '../../assets/mastercardLogo.svg';
import visaLogo from '../../assets/visaLogo.svg';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Badge,
  Button,
} from '@material-ui/core';
import { CardPaymentMethod } from '../../../modules/people/people-types';
import { PaymentMethod } from '@8baselabs/resident-io-shared';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelIcon from '@mui/icons-material/Label';
import { useEffect } from 'react';

const getCardLogo = (paymentMethod, classes): JSX.Element | null => {
  switch (paymentMethod.card.brand) {
    case 'visa':
      return (
        <Box className={classes.visaBox}>
          <img className={classes.visaImage} src={visaLogo} alt="Visa logo" />
        </Box>
      );
    case 'mastercard':
      return (
        <Box className={classes.masterCardBox}>
          <img
            className={classes.masterCardImage}
            src={mastercardLogo}
            alt="Mastercard logo"
          />
        </Box>
      );
    default:
      return null;
  }
};

type CardPaymentMethodProps = {
  cardMethod: CardPaymentMethod;
  disabled?: boolean;
  fromAutoPay?: boolean;
  handleSetPrimary?: (cardPaymentMethod: PaymentMethod) => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleRemove?: (e: any, cardPaymentMethod: PaymentMethod) => void;
  isSelector: boolean;
  isReservation?: boolean;
  paymentMethodId?: string;
  setPaymentMethodId?: (type: string, paymentMethodId: string) => void;
};

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'relative',
    width: '100%',
    border: `1.7px solid ${BORDER_COLOR}`,
    borderRadius: '4px',
    padding: (fromAutoPay) => (fromAutoPay ? '0px 0px 0px' : '20px 0px 0px'),
  },
  disabled: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#F1F1F1',
    border: `1.7px solid ${BORDER_COLOR}`,
    borderRadius: '4px',
    padding: '20px 0px 0px',
  },
  reservationBox: {
    position: 'relative',
    width: '100%',
    border: `1.7px solid ${BORDER_COLOR}`,
    borderRadius: '4px',
    padding: '20px 0px 0px',
    '&:hover': {
      border: `2px solid ${PRIMARY_COLOR}`,
      cursor: 'pointer',
    },
  },
  selectedBox: {
    position: 'relative',
    width: '100%',
    border: `2px solid ${PRIMARY_COLOR}`,
    borderRadius: '4px',
    padding: '20px 0px 0px',
  },
  imageBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1.5rem'
  },
  visaImage: {
    height: '18px',
  },
  masterCardImage: {
    height: '30px',
  },
  text: {
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  cardTextBlock: {
    display: (fromAutoPay) => (fromAutoPay ? 'flex' : 'inherit'),
    flexDirection: (fromAutoPay) => (fromAutoPay ? 'column' : 'inherit'),
    justifyContent: (fromAutoPay) => (fromAutoPay ? 'center' : 'inherit'),
    alignItems: (fromAutoPay) => (fromAutoPay ? 'center' : 'inherit'),
    width: '100%',
    minHeight: (isReservation) => (isReservation ? '92px' : ''),
  },
  borderGrid: {
    borderTop: `1.7px solid ${BORDER_COLOR}`,
    padding: '5px 0px',
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  primaryBadge: {
    position: 'absolute',
    top: '25px',
    right: '45px',
    color: PRIMARY_COLOR,
    '& .MuiBadge-badge': {
      borderRadius: '5px',
      backgroundColor: SECONDARY_BACKGROUND_COLOR,
      padding: theme.spacing(2),
    },
  },
}));

export const CardPaymentMethodRender = (
  props: CardPaymentMethodProps,
): JSX.Element => {
  const {
    fromAutoPay = false,
    cardMethod,
    disabled = false,
    handleSetPrimary,
    handleRemove,
    isSelector,
    isReservation = false,
    paymentMethodId,
    setPaymentMethodId,
  } = props;
  const classes = useStyles({ fromAutoPay, isReservation });

  const isSelectedPaymentMethod = paymentMethodId === cardMethod.id;

  useEffect(() => {
    if (setPaymentMethodId && isSelectedPaymentMethod) {
      setPaymentMethodId('card', cardMethod.id);
    }
  }, [cardMethod.id, isSelectedPaymentMethod, setPaymentMethodId]);

  return (
    <Box
      className={
        disabled
          ? classes.disabled
          : isSelectedPaymentMethod
          ? classes.selectedBox
          : isReservation
          ? classes.reservationBox
          : classes.box
      }
      onClick={() =>
        disabled
          ? {}
          : setPaymentMethodId && setPaymentMethodId('card', cardMethod.id)
      }>
      <Grid container>
        <Grid item xl={4} className={classes.imageBox}>
          {getCardLogo(cardMethod, classes)}
        </Grid>
        <Grid item xl={6} className={classes.cardTextBlock}>
          <Typography className={classes.text}>
            {cardMethod.card.brand} **** {cardMethod.card.last4}
          </Typography>
          <Typography variant="subtitle2" className={classes.text}>
            Expires {cardMethod.card.exp_month.toString().padStart(2, '0')}/
            {cardMethod.card.exp_year.toString().slice(-2)}
          </Typography>
        </Grid>
        {!isSelector && (
          <Grid item xs={12} className={classes.borderGrid}>
            {!isReservation && (
              <Button
                onClick={
                  handleSetPrimary && !cardMethod.isPrimary
                    ? () => handleSetPrimary(cardMethod)
                    : () => {}
                }
                variant="text"
                startIcon={<LabelIcon />}
                disabled={cardMethod.isPrimary || disabled}>
                SET AS PRIMARY
              </Button>
            )}
            <Button
              onClick={
                handleRemove ? (e) => handleRemove(e, cardMethod) : () => {}
              }
              variant="text"
              startIcon={<DeleteIcon />}
              disabled={cardMethod.isPrimary || disabled}>
              REMOVE
            </Button>
          </Grid>
        )}

        {cardMethod.isPrimary && (
          <Badge
            className={classes.primaryBadge}
            badgeContent={'Primary'}
            overlap={'rectangular'}></Badge>
        )}
      </Grid>
    </Box>
  );
};
