import {
  BORDER_COLOR,
  PRIMARY_COLOR,
  SECONDARY_BACKGROUND_COLOR,
  SUCCESS_COLOR,
} from '../../theme';
import bankLogo from '../../../shared/assets/bankLogo.png';
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Badge,
  Button,
} from '@material-ui/core';
import { BankPaymentMethod } from '../../../modules/people/people-types';
import { PaymentMethod } from '@8baselabs/resident-io-shared';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelIcon from '@mui/icons-material/Label';
// import VerifiedIcon from '@mui/icons-material/Verified';
import { useEffect } from 'react';

type BankPaymentMethodProps = {
  disabled?: boolean;
  fromAutoPay?: boolean;
  bankMethod: BankPaymentMethod;
  // handleVerify?: (bankPaymentMethod: PaymentMethod) => void;
  handleSetPrimary?: (bankPaymentMethod: PaymentMethod) => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleRemove?: (e: any, bankPaymentMethod: PaymentMethod) => void;
  isSelector: boolean;
  isReservation?: boolean;
  paymentMethodId?: string;
  setPaymentMethodId?: (type: string, paymentMethodId: string) => void;
};

const useStyles = makeStyles((theme) => ({
  box: {
    position: 'relative',
    width: '100%',
    border: `1.7px solid ${BORDER_COLOR}`,
    borderRadius: '4px',
    padding: (fromAutoPay) => (fromAutoPay ? '0px 0px 0px' : '20px 0px 0px'),
  },
  disabled: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#F1F1F1',
    border: `1.7px solid ${BORDER_COLOR}`,
    borderRadius: '4px',
    padding: '20px 0px 0px',
  },
  reservationBox: {
    position: 'relative',
    width: '100%',
    border: `1.7px solid ${BORDER_COLOR}`,
    borderRadius: '4px',
    padding: '20px 0px 0px',
    '&:hover': {
      border: `2px solid ${PRIMARY_COLOR}`,
      cursor: 'pointer',
    },
  },
  selectedBox: {
    position: 'relative',
    width: '100%',
    border: `2px solid ${PRIMARY_COLOR}`,
    borderRadius: '4px',
    padding: '20px 0px 0px',
  },
  imageBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: (isReservation) => (isReservation ? '92px' : ''),
  },
  image: {
    height: '18px',
  },
  cardTextBlock: {
    display: (fromAutoPay) => (fromAutoPay ? 'flex' : 'inherit'),
    flexDirection: (fromAutoPay) => (fromAutoPay ? 'column' : 'inherit'),
    justifyContent: (fromAutoPay) => (fromAutoPay ? 'center' : 'inherit'),
    alignItems: (fromAutoPay) => (fromAutoPay ? 'center' : 'inherit'),
    width: '100%',
    minHeight: (isReservation) => (isReservation ? '92px' : ''),
  },
  text: {
    textTransform: 'capitalize',
  },
  buttonActions: {
    // display: 'flex',
    borderTop: `1.7px solid ${BORDER_COLOR}`,
    padding: '5px 0px',
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  buttonLeft: {
    marginRight: 'auto',
    marginLeft: theme.spacing(1),
  },
  buttonVerified: {
    color: SUCCESS_COLOR,
  },
  primaryBadge: {
    position: 'absolute',
    top: '25px',
    right: '45px',
    color: PRIMARY_COLOR,
    '& .MuiBadge-badge': {
      borderRadius: '5px',
      backgroundColor: SECONDARY_BACKGROUND_COLOR,
      padding: theme.spacing(2),
    },
  },
}));

export const BankAccountPaymentMethodRender = (
  props: BankPaymentMethodProps,
): JSX.Element => {
  const {
    disabled = false,
    fromAutoPay = false,
    bankMethod,
    // handleVerify,
    handleSetPrimary,
    handleRemove,
    isSelector,
    isReservation = false,
    paymentMethodId,
    setPaymentMethodId,
  } = props;
  const classes = useStyles({ fromAutoPay, isReservation });

  const isSelectedPaymentMethod = paymentMethodId === bankMethod.id;
  useEffect(() => {
    if (setPaymentMethodId && isSelectedPaymentMethod) {
      setPaymentMethodId('us_bank_account', bankMethod.id);
    }
  }, [bankMethod.id, isSelectedPaymentMethod, setPaymentMethodId]);

  return (
    <Box
      className={
        disabled
          ? classes.disabled
          : isSelectedPaymentMethod
          ? classes.selectedBox
          : isReservation
          ? classes.reservationBox
          : classes.box
      }
      onClick={
        disabled
          ? () => {}
          : () =>
              setPaymentMethodId &&
              setPaymentMethodId('us_bank_account', bankMethod.id)
      }>
      <Grid container>
        <Grid item xs={3}>
          <Box className={classes.imageBox}>
            <img className={classes.image} src={bankLogo} alt="Bank logo" />
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.cardTextBlock}>
          {bankMethod.us_bank_account && (
            <>
              <Typography className={classes.text}>
                Bank Account **** {bankMethod.us_bank_account.last4}
              </Typography>
              <Typography variant="subtitle2">
                {' '}
                {bankMethod.us_bank_account.bank_name
                  .toString()
                  .padStart(2, '0')}
                /{bankMethod.us_bank_account.routing_number}
              </Typography>
            </>
          )}
        </Grid>
        {!isSelector && (
          <Grid item xs={12} className={classes.buttonActions}>
            {/* <Button
              onClick={
                handleVerify && !bankMethod.isVerified
                  ? () => handleVerify(bankMethod)
                  : () => {}
              }
              variant="text"
              className={`${classes.buttonLeft} ${
                bankMethod.isVerified && classes.buttonVerified
              }`}
              startIcon={<VerifiedIcon />}
              disabled={bankMethod.isVerified}>
              {bankMethod.isVerified ? 'VERIFIED' : 'VERIFY'}
            </Button> */}
            {!isReservation && (
              <Button
                onClick={
                  handleSetPrimary && !bankMethod.isPrimary
                    ? () => handleSetPrimary(bankMethod)
                    : () => {}
                }
                variant="text"
                startIcon={<LabelIcon />}
                disabled={bankMethod.isPrimary || disabled}>
                SET AS PRIMARY
              </Button>
            )}
            <Button
              onClick={
                handleRemove ? (e) => handleRemove(e, bankMethod) : () => {}
              }
              variant="text"
              startIcon={<DeleteIcon />}
              disabled={bankMethod.isPrimary}>
              REMOVE
            </Button>
          </Grid>
        )}
        {bankMethod.isPrimary && (
          <Badge
            className={classes.primaryBadge}
            badgeContent={'Primary'}
            overlap={'rectangular'}></Badge>
        )}
      </Grid>
    </Box>
  );
};
