import React, { useEffect, useState } from 'react';
import {
  listBanksPaymentMethods,
  listCardsPaymentMethods,
} from '../../../user/user-actions';
import { useCallAction } from '@8baselabs/react-simple-state';
import {
  LedgerSubCategory,
} from '@8baselabs/resident-io-shared';

import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { CardPaymentMethodRender } from '../../../../shared/components/ui/CardPaymentMethodRender';
import { BankAccountPaymentMethodRender } from '../../../../shared/components/ui/BankAccountPaymentMethodRender';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../routes/types';
import { BankPaymentMethod, CardPaymentMethod } from '../../people-types';
import { Property, User } from '../../../../schema-types';

type PaymentMethodSelectorProps = {
  user: User;
  fromAutoPay?: boolean;
  property: Property;
  isTenant?: boolean;
  onAddPaymentMethod?: () => void;
  setPaymentMethodId: React.Dispatch<React.SetStateAction<string>>;
  subCategory?: string;
  paymentMethodId?: string;
};

export const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = (
  props,
) => {
  const {
    user,
    property,
    onAddPaymentMethod,
    subCategory,
    paymentMethodId,
    isTenant,
    setPaymentMethodId,
    fromAutoPay,
  } = props;
  const [bankPaymentMethods, setBankPaymentMethods] = useState<
    BankPaymentMethod[]
  >([]);
  const [cardPaymentMethods, setCardPaymentMethods] = useState<
    CardPaymentMethod[]
  >([]);
  const [loadingListBankPaymentMethods, setLoadingListBankPaymentMethods] =
    useState(false);
  const [listBanksPaymentMethodsCall] = useCallAction(listBanksPaymentMethods, {
    onCompleted: (result) => {
      setBankPaymentMethods(result);
      setLoadingListBankPaymentMethods(false);
    },
    onError: (err) => {
      console.error(err);
      setLoadingListBankPaymentMethods(false);
      alert({
        error: true,
        message: 'Failed to load the bank payment methods. Please try again.',
      });
    },
  });

  const [loadingListCardPaymentMethods, setLoadingListCardPaymentMethods] =
    useState(false);
  const [listCardsPaymentMethodsCall] = useCallAction(listCardsPaymentMethods, {
    onCompleted: (result: CardPaymentMethod[]) => {
      setCardPaymentMethods(result);
      setLoadingListCardPaymentMethods(false);
    },
    onError: (err) => {
      console.error(err);
      setLoadingListCardPaymentMethods(false);
      alert({
        error: true,
        message: 'Failed to load the bank payment methods. Please try again.',
      });
    },
  });

  useEffect(() => {
    if (user && property) {
      setCardPaymentMethods([]);
      setBankPaymentMethods([]);
      if (!subCategory) {
        setLoadingListCardPaymentMethods(true);
        listCardsPaymentMethodsCall(user, property);
        setLoadingListBankPaymentMethods(true);
        listBanksPaymentMethodsCall(user, property);
      }
      if (subCategory === LedgerSubCategory.ONLINE_CREDIT_CARD) {
        setLoadingListCardPaymentMethods(true);
        listCardsPaymentMethodsCall(user, property);
      }
      if (subCategory === LedgerSubCategory.ONLINE_ACH_ECHECK) {
        setLoadingListBankPaymentMethods(true);
        listBanksPaymentMethodsCall(user, property);
      }
    }
  }, [
    subCategory,
    user,
    property,
    listBanksPaymentMethodsCall,
    listCardsPaymentMethodsCall,
  ]);

  const loadingEndedWithResults =
    (!loadingListBankPaymentMethods || !loadingListCardPaymentMethods) &&
    (cardPaymentMethods.length > 0 || bankPaymentMethods.length > 0);

  if (loadingListBankPaymentMethods || loadingListCardPaymentMethods) {
    return (
      <Grid item xs={12}>
        <Box
          style={{
            textAlign: 'center',
          }}>
          <CircularProgress />
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {loadingEndedWithResults ? (
        <TextField
          select
          value={paymentMethodId}
          onChange={(event) => {
            setPaymentMethodId(event.target.value);
          }}
          variant="outlined"
          fullWidth
          disabled={
            loadingListBankPaymentMethods || loadingListCardPaymentMethods
          }
          label="Payment Method">
          {cardPaymentMethods &&
            cardPaymentMethods.length > 0 &&
            cardPaymentMethods?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.stripeId}>
                  <CardPaymentMethodRender
                    fromAutoPay={fromAutoPay ?? false}
                    cardMethod={item}
                    isSelector={true}
                  />
                </MenuItem>
              );
            })}
          {bankPaymentMethods &&
            bankPaymentMethods.length > 0 &&
            bankPaymentMethods?.map((item, index) => {
              return (
                <MenuItem key={index} value={item.stripeId}>
                  <BankAccountPaymentMethodRender
                    fromAutoPay={fromAutoPay ?? false}
                    bankMethod={item}
                    isSelector={true}
                  />
                </MenuItem>
              );
            })}
        </TextField>
      ) : (
        <Box mt={2} mb={2} textAlign={'center'}>
          <Typography>
            You need to add payment methods for this user.{' '}
            {isTenant ? (
              <Box
                onClick={onAddPaymentMethod}
                style={{
                  width: 'fit-content',
                  margin: 'auto',
                  cursor: 'pointer',
                }}>
                <Typography
                  style={{ textDecoration: 'underline' }}
                  color="primary">
                  Click here to add payment methods.{' '}
                </Typography>
              </Box>
            ) : (
              <Link
                to={`${ROUTES.people.path}${ROUTES.people.subRoutes?.payment.path}`.replace(
                  ':id',
                  user?.id || '',
                )}>
                Click here to add payment methods.
              </Link>
            )}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
