import { FileInputValue } from '@8base-react/file-input';
import { File } from '@8baselabs/resident-io-shared/src/schema-types';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Loading, LoadingContainer } from '../Loading';
import { DeleteOutline } from '@material-ui/icons';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';

const DashedContainer = styled('div')({
  border: '1px solid #bfbfbf',
  padding: 15,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  cursor: 'pointer',
});

const CheckContainer = styled('div')({
  height: '26px',
  width: '26px',
  borderRadius: '4px',
  backgroundColor: 'rgba(109,210,48,0.08)',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '2vh',
});

export const FilestackInput: React.FC<{
  docs: FileInputValue | undefined;
  label: string;
  handleClick: () => void | undefined;
  handleDelete?: null | (() => void | undefined);
  buttonTitle?: string;
  loading?: boolean;
}> = ({ docs, label, handleClick, buttonTitle, loading, handleDelete }) => {
  const [isSuccessHovered, setIsSuccessHovered] = useState(false);
  return (
    <DashedContainer>
      <Grid
        onClick={() => handleClick()}
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around">
        <Grid item container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography>{label}</Typography>
            <Typography variant="subtitle2">
              {(docs as File)?.filename
                ? (docs as File).filename
                : 'Document title'}
            </Typography>
          </Grid>
          <Grid item>
            {loading ? (
              <>
                <LoadingContainer>
                  <Loading size={32} />
                </LoadingContainer>
              </>
            ) : (
              <>
                <Button type="button">
                  {buttonTitle === 'DOWNLOAD' ? (
                    <GetAppOutlinedIcon fontSize="small" />
                  ) : (
                    <PublishOutlinedIcon fontSize="small" />
                  )}
                  {buttonTitle ? buttonTitle : 'UPLOAD FILES'}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        {docs && handleDelete ? (
          <>
            <CheckContainer
              style={isSuccessHovered ? {backgroundColor: '#fdecea'} : {}}
              onMouseEnter={() => setIsSuccessHovered(true)}
              onMouseLeave={() => setIsSuccessHovered(false)}
              onClick={() => {
                handleDelete();
              }}>
              {isSuccessHovered ? (
                <DeleteOutline color="error" />
              ) : (
                <CheckIcon color="success" />
              )}
            </CheckContainer>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </DashedContainer>
  );
};
