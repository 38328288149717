/* eslint-disable no-alert */
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useSetupAuth0Token } from '../auth-hooks';
import { MainLoader } from '../../../shared/components/MainLoader';
import { handleAuthentication } from './auth-callback-actions';

/**
 * @param {object} props - Props.
 * @param {object} props.history - History of rect router.
 * @returns {JSX.Element} - Auth callback component.
 */
export function AuthCallback({ history }: RouteComponentProps): JSX.Element {
  const { user, error, isAuthenticated } = useAuth0();

  useSetupAuth0Token();

  const createPM = Boolean(localStorage.getItem('create-pm'));

  useEffect(() => {
    if (isAuthenticated) {
      handleAuthentication({ ...user, createPM });
      history.push('/');
    }
    if (error) {
      if (error.message === 'user is blocked') {
        localStorage.setItem('blockedUser', 'true');
      } else {
        alert(`${error.name}: ${error.message}`);
      }
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, error]);

  // NOT ADD LOADING COMPONENT HERE this must be a loading auth component
  return <MainLoader />;
}
