import { gql } from '@apollo/client';

export const MUTATE_AVAILABILITY = gql`
  mutation MUTATE_AVAILABILITY($data: AvailabilityUpdateInput!) {
    availabilityUpdate(data: $data, force: true) {
      id
    }
  }
`;

export const GET_BUILDING_LAYOUT_ID = gql`
  query GET_BUILDING_LAYOUT_ID($id: ID!) {
    buildingLayoutsList(filter: { property: { id: { equals: $id } } }) {
      items {
        id
      }
    }
  }
`;

export const AVAILABILITY_UPDATE_BYFILTER_MUTATION = gql`
  mutation AVAILABILITY_UPDATE_BYFILTER_MUTATION(
    $data: AvailabilityUpdateByFilterInput!
    $filter: AvailabilityFilter
  ) {
    availabilityUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;

/* export const GET_PROPERTIES_SUBDOMAIN = gql`
  query GET_PROPERTIES_SUBDOMAIN($subdomain: String!) {
    propertiesList(filter: { subdomain: { equals: $subdomain } }) {
      items {
        id
      }
    }
  }
`; */

export const GET_SUBDOMAIN_AVAILABILITY = gql`
  query GET_SUBDOMAIN_AVAILABILITY($subdomain: String!) {
    getSchoolCompanyBySubdomain(subdomain: $subdomain) {
      school {
        id
      }
      company {
        id
      }
    }
  }
`;

export const GET_CLIENT_PAGE_DATA = gql`
  query GET_CLIENT_PAGE_DATA($clientOwnerId: ID) {
    client(id: $clientOwnerId) {
      clientPage {
        urlSubdomain
      }
    }
  }
`;

export const SAVE_SUBDOMAIN = gql`
  mutation SAVE_SUBDOMAIN($data: ClientUpdateInput!) {
    clientUpdate(data: $data) {
      id
    }
  }
`;

export const SAVE_PROPERTY_MUTATION = gql`
  mutation SAVE_PROPERTY_MUTATION($data: PropertyCreateInput!) {
    propertyCreate(data: $data) {
      id
      email
    }
  }
`;

export const GET_CLIENT_PROPERTIES_QUERY = gql`
  query GET_CLIENT_PROPERTIES_QUERY($filter: PropertyFilter!) {
    propertiesList(filter: $filter, sort: { name: ASC }) {
      items {
        name
        email
        code
        id
        state
        street
        city
        stripeAccountId
        stripeCustomerId
        hasChangedSiteSettings
        hasChangedPropertyPage
        propertyLeasePeriodRelation {
          count
          items {
            name
            id
            leasePeriodPaymentPlanRelation(
              filter: { isActive: { equals: true } }
            ) {
              count
            }
          }
        }
        buildingLayout {
          id
        }
        primaryPropertyImage {
          downloadUrl
        }
        propertyImagePropertyRelation {
          items {
            id
            file {
              downloadUrl
              fileId
            }
            caption
            position
            type
          }
        }
        propertyCheckbookAccountRelation {
          apiKey
        }
        propertyBuildingRelation {
          items {
            id
            buildingFloorRelation {
              items {
                id
                floorUnitRelation {
                  items {
                    id
                    unitRoomRelation {
                      items {
                        id
                        roomBedRelation {
                          items {
                            id
                            gender
                            code
                            number
                            bedAvailabilityRelation {
                              count
                              items {
                                availabilityPaymentPlanRelation(
                                  filter: {
                                    paymentPlan: { isActive: { equals: true } }
                                  }
                                ) {
                                  count
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        buildings
        beds
        units
        propertyMessageTemplateRelation {
          count
        }
        propertyDocumentTemplatePropertyRelation {
          count
        }
        lateFees {
          count
        }
        unitTypePropertyRelation {
          count
        }
        roomTypePropertyRelation {
          count
        }
        propertyOptionalServiceRelation {
          count
        }
        propertyLeaseSubPeriodRelation {
          count
        }
        propertyPaymentPlanRelation(
          filter: { status: { equals: "APPROVED" } }
        ) {
          count
        }
      }
    }
  }
`;
export const READ_LEASEPERIODS = gql`
  query READ_LEASEPERIODS($propertyId: ID!) {
    leasePeriodsList(
      filter: {
        property: { id: { equals: $propertyId } }
        leasePeriodAvailabilityRelation: {
          some: {
            bed: {
              bedAvailabilityRelation: { some: { available: { equals: true } } }
            }
            available: { equals: true }
          }
        }
      }
    ) {
      items {
        id
        name
        code
        minimumPrice
        maximumPrice
        leasePeriodLeaseRelation {
          items {
            userResident {
              id
            }
            status
            leasePeriod {
              id
            }
          }
        }
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              startDate
              endDate
              name
            }
          }
        }
        leasePeriodPaymentPlanRelation(first: 1, sort: { status: ASC }) {
          items {
            total
            id
          }
        }
        residentPropDocumentLeasePeriodRelation {
          items {
            id
            signers
            name
            status
            startDate
            helloSignTemplateId
            endDate
            type
          }
        }
        guarantorPropDocumentLeasePeriodRelation {
          items {
            id
            signers
            name
            status
            startDate
            helloSignTemplateId
            endDate
            type
          }
        }
        propertyFormLeasePeriodRelation {
          items {
            id
            name
            type
          }
        }
        startDate
        startTime
        endTime
        endDate
        displayOnDashboard
        onlineType
        leasePeriodAvailabilityRelation(
          filter: {
            availabilityPaymentPlanRelation: {
              some: { id: { is_not_empty: true } }
            }
          }
        ) {
          count
          items {
            availabilityPaymentPlanRelation {
              items {
                id
                paymentPlan {
                  name
                  leasePeriod {
                    id
                    name
                  }
                }
              }
            }
            lease {
              id
            }
          }
        }
        leasePeriodRenewals {
          leasePeriods {
            items {
              id
              name
              startDate
              endDate
            }
          }
        }
        property {
          client {
            id
          }
        }
      }
    }
  }
`;

export const FETCH_ONLINE_LEASEPERIODS = gql`
  query OnlineLeasePeriods($propertyId: ID!) {
    leasePeriodsList(
      filter: {
        onlineType: { not_equals: "OFF" }
        property: { id: { equals: $propertyId } }
        leasePeriodAvailabilityRelation: {
          some: {
            bed: {
              bedAvailabilityRelation: { some: { available: { equals: true } } }
            }
          }
        }
      }
    ) {
      items {
        id
        name
        code
        minimumPrice
        maximumPrice
        leasePeriodLeaseRelation {
          items {
            id
            userResident {
              id
            }
            status
            leasePeriod {
              name
              id
            }
          }
        }
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              startDate
              endDate
              name
            }
          }
        }
        leasePeriodPaymentPlanRelation(first: 1, sort: { status: ASC }) {
          items {
            total
            id
          }
        }
        residentPropDocumentLeasePeriodRelation {
          items {
            id
            signers
            name
            status
            startDate
            helloSignTemplateId
            endDate
            type
          }
        }
        guarantorPropDocumentLeasePeriodRelation {
          items {
            id
            signers
            name
            status
            startDate
            helloSignTemplateId
            endDate
            type
          }
        }
        propertyFormLeasePeriodRelation {
          items {
            id
            name
            type
          }
        }
        startDate
        startTime
        endTime
        endDate
        displayOnDashboard
        onlineType
        leasePeriodAvailabilityRelation(
          filter: {
            availabilityPaymentPlanRelation: {
              some: { id: { is_not_empty: true } }
            }
          }
        ) {
          count
        }
        leasePeriodRenewals {
          leasePeriods {
            items {
              id
              name
              startDate
              endDate
            }
          }
        }
        property {
          client {
            id
          }
        }
      }
    }
  }
`;
export const READ_ROOM_TYPES = gql`
  query READ_ROOM_TYPES($propertyId: ID!) {
    roomTypesList(
      filter: { propertyRoomTypeRelation: { id: { equals: $propertyId } } }
    ) {
      items {
        id
        typeName
        occupancyAndBeds
        roomRoomTypeRelation {
          items {
            roomBedRelation {
              items {
                price
                number
              }
            }
            type
          }
        }
      }
    }
  }
`;
export const READ_UNIT_TYPES = gql`
  query READ_UNIT_TYPES($propertyId: ID!) {
    unitTypesList(
      filter: { propertyUnitTypeRelation: { id: { equals: $propertyId } } }
    ) {
      items {
        id
        typeName
      }
    }
  }
`;
export const CREATE_LEASEPERIODS = gql`
  mutation CREATE_LEASEPERIODS($data: LeasePeriodCreateInput!) {
    leasePeriodCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_LEASEPERIODS = gql`
  mutation UPDATE_LEASEPERIODS($data: LeasePeriodUpdateInput!) {
    leasePeriodUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_LEASEPERIODS_DISPLAYMANAGER_FIELD = gql`
  mutation UPDATE_LEASEPERIODS_DISPLAYMANAGER_FIELD(
    $data: LeasePeriodUpdateInput!
  ) {
    leasePeriodUpdate(data: $data) {
      id
    }
  }
`;

export const DELETE_LEASEPERIODS = gql`
  mutation DELETE_LEASEPERIODS($data: LeasePeriodDeleteInput!) {
    leasePeriodDelete(data: $data, force: true) {
      success
    }
  }
`;

export const DELETE_PERIODSUBPERIODRELATIONBYFILTER = gql`
  mutation DELETE_PERIODSUBPERIODRELATIONBYFILTER(
    $filter: PeriodSubPeriodFilter!
  ) {
    periodSubPeriodDeleteByFilter(filter: $filter) {
      success
    }
  }
`;

export const DELETE_PAYMENT_PLAN = gql`
  mutation DELETE_PAYMENT_PLAN($data: PaymentPlanDeleteInput!) {
    paymentPlanDelete(data: $data, force: true) {
      success
    }
  }
`;

export const DELETE_OPTIONAL_PREFERENCE = gql`
  mutation DELETE_OPTIONAL_PREFERENCE($data: OptionalPreferenceDeleteInput!) {
    optionalPreferenceDelete(data: $data, force: true) {
      success
    }
  }
`;

export const READ_SUBPERIODS = gql`
  query READ_SUBPERIODS(
    $filter: LeaseSubPeriodFilter
    $first: Int
    $skip: Int
  ) {
    leaseSubPeriodsList(filter: $filter, first: $first, skip: $skip) {
      items {
        id
        name
        startDate
        endDate
        startTime
        endTime
        leaseSubPeriodPeriodSubPeriodRelation {
          items {
            leasePeriod {
              id
              name
            }
          }
        }
      }
      count
    }
  }
`;

export const CREATE_SUBPERIOD = gql`
  mutation CREATE_SUBPERIOD($data: LeaseSubPeriodCreateInput!) {
    leaseSubPeriodCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_SUBPERIOD = gql`
  mutation UPDATE_SUBPERIOD($data: LeaseSubPeriodUpdateInput!) {
    leaseSubPeriodUpdate(data: $data) {
      id
    }
  }
`;

export const CREATE_BUILDING_LAYOUT = gql`
  mutation CREATE_BUILDING_LAYOUT($data: BuildingLayoutCreateInput!) {
    buildingLayoutCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_BUILDING = gql`
  mutation CREATE_BUILDING($data: BuildingCreateInput!) {
    buildingCreate(data: $data) {
      id
      code
      buildingFloorRelation {
        items {
          id
          number
        }
      }
    }
  }
`;

export const CREATE_BUILDING_UPLOAD = gql`
  mutation CREATE_BUILDING_UPLOAD($data: BuildingLayoutUploadCreateInput!) {
    buildingLayoutUploadCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_FLOORS_RESOLVER = gql`
  mutation CREATE_FLOORS_RESOLVER(
    $buildingLayout: String
    $buildingId: String
    $uploadId: String
  ) {
    createBuildingLayout(
      buildingLayout: $buildingLayout
      buildingId: $buildingId
      uploadId: $uploadId
    ) {
      success
    }
  }
`;

export const UNIT_CREATE = gql`
  mutation UNIT_CREATE($data: UnitCreateInput!) {
    unitCreate(data: $data) {
      id
    }
  }
`;

export const FLOOR_DELETE = gql`
  mutation FLOOR_DELETE($id: ID!) {
    floorDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const EDIT_UNIT = gql`
  mutation EDIT_UNIT($data: UnitUpdateInput!, $id: ID!) {
    unitEditResolver(data: $data, id: $id) {
      success
      data {
        id
        unitRoomRelation {
          items {
            id
            code
            roomBedRelation {
              items {
                id
                number
              }
            }
          }
        }
      }
    }
  }
`;

export const UNIT_DELETE = gql`
  mutation UNIT_DELETE($id: ID!) {
    unitDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const FLOOR_CREATE = gql`
  mutation FLOOR_CREATE($data: FloorCreateInput!) {
    floorCreate(data: $data) {
      id
    }
  }
`;

export const FLOOR_UPDATE = gql`
  mutation FLOOR_UPDATE($data: FloorUpdateInput!) {
    floorUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_BUILDING = gql`
  mutation UPDATE_BUILDING($data: BuildingUpdateInput!) {
    buildingUpdate(data: $data) {
      id
      code
    }
  }
`;

export const DELETE_BUILDING = gql`
  mutation DELETE_BUILDING($id: ID!) {
    buildingDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const DELETE_BIG_BUILDING = gql`
  mutation DELETE_BIG_BUILDING($id: ID!) {
    deleteBuildingResolver(id: $id) {
      success
    }
  }
`;

export const IMPORT_RESIDENTS = gql`
  mutation IMPORT_RESIDENTS($data: ImportResidentData!) {
    importResidents(data: $data) {
      success
      message
    }
  }
`;

export const DELETE_BIG_FLOOR = gql`
  mutation DELETE_BIG_FLOOR($id: ID!) {
    deleteFloorResolver(id: $id) {
      success
    }
  }
`;

export const GET_BUILDING_LAYOUT = gql`
  query GET_BUILDING_LAYOUT($propertyId: ID!) {
    property(id: $propertyId) {
      buildingLayout {
        building {
          items {
            id
            code
            buildingFloorRelation {
              items {
                id
                number
                floorUnitRelation {
                  items {
                    id
                    code
                    gender
                    type
                    unitType {
                      id
                      maxOccupancy
                      typeName
                      maxRooms
                    }
                    unitRoomRelation {
                      items {
                        id
                        code
                        type
                        roomType {
                          id
                          typeName
                          occupancyAndBeds
                        }
                        roomBedRelation {
                          items {
                            id
                            number
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SUBPERIOD = gql`
  mutation DELETE_SUBPERIOD($data: LeaseSubPeriodDeleteInput!) {
    leaseSubPeriodDelete(data: $data) {
      success
    }
  }
`;

export const GET_PROPERTY_INFORMATION = gql`
  query GET_PROPERTY_INFORMATION($propertyId: ID!) {
    property(id: $propertyId) {
      id
      name
      code
      email
      zipCode
      location
      type
      street
      phone
      status
      stripeAccountId
      stripeCustomerId
      timezone
      clientUser {
        items {
          role
          user {
            firstName
            lastName
            email
          }
        }
      }
      primaryPropertyImage {
        downloadUrl
      }
      propertyImagePropertyRelation {
        items {
          id
          file {
            downloadUrl
            fileId
          }
          caption
          position
          type
        }
      }
      buildings
      units
      beds
      propertyPropertyOwnershipRelation {
        items {
          user {
            firstName
            lastName
            email
            phoneNumber
          }
        }
      }
      propertyCheckbookAccountRelation {
        id
        clientId
        apiKey
        apiSecret
        signature
      }
      closedDate
    }
  }
`;

export const UPDATE_PROPERTY_INFORMATION = gql`
  mutation UPDATE_PROPERTY_INFORMATION(
    $propertyId: ID!
    $data: PropertyUpdateInput!
  ) {
    propertyUpdate(filter: { id: $propertyId }, data: $data, force: true) {
      id
    }
  }
`;

export const FETCH_PAYMENT_PLANS_LIST = gql`
  query FETCH_PAYMENT_PLANS_LIST($propertyId: ID!, $leasePeriodId: ID!) {
    paymentPlansList(
      filter: {
        property: { id: { equals: $propertyId } }
        leasePeriod: { id: { equals: $leasePeriodId } }
      }
    ) {
      items {
        id
        total
        name
        status
        isActive
        code

        leasePeriod {
          id
          name
        }

        paymentPlanPaymentPlanItemRelation {
          items {
            id
            createdBy {
              email
            }
            description
            type
            dueDate
            amount
            accountType
            leaseSubPeriod {
              id
            }
            category
            dueOn
          }
        }

        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const FETCH_OPTIONAL_PREFERENCE_LIST = gql`
  query FETCH_OPTIONAL_PREFERENCE_LIST($propertyId: ID!, $subPeriods: [ID!]) {
    optionalPreferencesList(
      filter: {
        property: { id: { equals: $propertyId } }
        subPeriod: { id: { in: $subPeriods } }
      }
    ) {
      items {
        id
        name
        title
        description
        status
        isActive
        leasePeriod {
          id
          name
        }
        opcionalOpcionalPreferenceItemRelation {
          items {
            description
            type
            category
            amount
            leaseSubPeriod {
              id
            }
            dueDate
          }
        }
        total
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const FETCH_PAYMENT_PLANS_AND_OPTIONAL_PREFERENCES = gql`
  query FETCH_PAYMENT_PLANS_AND_OPTIONAL_PREFERENCES(
    $leasePeriodId: ID!
    $subPeriods: [String!]
  ) {
    paymentPlansList(
      filter: {
        status: { equals: "APPROVED" }
        leasePeriod: { id: { equals: $leasePeriodId } }
      }
    ) {
      items {
        id
        name
        status
      }
    }
    optionalPreferencesList(
      filter: {
        status: { equals: "APPROVED" }
        subPeriodId: { in: $subPeriods }
      }
    ) {
      items {
        id
        title
        description
        name
        opcionalOpcionalPreferenceItemRelation {
          items {
            amount
          }
        }
      }
    }
  }
`;

export const FETCH_PAYMENT_PLAN_BY_ID = gql`
  query FETCH_PAYMENT_PLAN_BY_ID($id: ID!) {
    paymentPlan(id: $id) {
      id
      name
      status
      type
      startDate
      endDate
      total
      isActive
      guarantorRequired
      approvedBy {
        id
        firstName
        lastName
      }
      approvedAt
      leasePeriod {
        id
        name
        startDate
        endDate
        onlineType
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              startDate
              endDate
              name
            }
          }
        }
      }
      paymentPlanPaymentPlanItemRelation {
        items {
          id
          dueDate
          dueOn
          type
          category
          description
          amount
          accountType
          leaseSubPeriod {
            id
            name
            startDate
            endDate
          }
        }
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      paymentPlanLeaseRelation {
        items {
          id
        }
      }
    }
  }
`;
export const FETCH_OPTIONAL_PREFERENCE_BY_ID = gql`
  query FETCH_OPTIONAL_PREFERENCE_BY_ID($id: ID!) {
    optionalPreference(id: $id) {
      id
      name
      title
      description
      status
      total
      isActive
      property {
        id
        propertyLeasePeriodRelation {
          items {
            name
            id
            leasePeriodPeriodSubPeriodRelation {
              items {
                leaseSubPeriod {
                  id
                  name
                }
              }
            }
          }
        }
      }
      subPeriod {
        id
        name
      }
      leasePeriod {
        startDate
        endDate
      }
      optionalPreferenceAdditionalDataRelation {
        items {
          id
          title
          required
        }
      }
      optionalPreferenceInvetoryRelation {
        items {
          id
          name
          isSold
        }
      }
      leasePeriod {
        id
        name
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              name
            }
          }
        }
      }
      opcionalOpcionalPreferenceItemRelation {
        items {
          id
          dueDate
          type
          category
          description
          amount
          accountType
          categoryDisplay
          leaseSubPeriod {
            id
            name
          }
        }
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      optionalPreferenceLeaseRelation {
        items {
          id
        }
      }
    }
  }
`;
export const DELETE_OPTIONAL_PREFERENCE_ITEM = gql`
  mutation DELETE_OPTIONAL_PREFERENCE_ITEM($id: ID!) {
    optionalPreferenceItemDelete(data: { id: $id, force: true }) {
      success
    }
  }
`;
export const CREATE_OPTIONAL_PREFERENCE_ITEM = gql`
  mutation CREATE_OPTIONAL_PREFERENCE_ITEM(
    $data: OptionalPreferenceItemCreateInput!
  ) {
    optionalPreferenceItemCreate(data: $data) {
      id
    }
  }
`;
export const UPDATE_OPTIONAL_PREFERENCE_ITEM = gql`
  mutation UPDATE_OPTIONAL_PREFERENCE_ITEM(
    $data: OptionalPreferenceItemUpdateInput!
  ) {
    optionalPreferenceItemUpdate(data: $data) {
      id
    }
  }
`;
export const UPDATE_OPTIONAL_PREFERENCE = gql`
  mutation UPDATE_OPTIONAL_PREFERENCE($data: OptionalPreferenceUpdateInput!) {
    optionalPreferenceUpdate(data: $data) {
      id
    }
  }
`;
export const DELETE_PAYMENT_PLAN_ITEM = gql`
  mutation DELETE_PAYMENT_PLAN_ITEM($id: ID!) {
    paymentPlanItemDelete(data: { id: $id, force: true }) {
      success
    }
  }
`;
export const CREATE_PAYMENT_PLAN_ITEM = gql`
  mutation CREATE_PAYMENT_PLAN_ITEM($newData: PaymentPlanItemCreateInput!) {
    paymentPlanItemCreate(data: $newData) {
      id
    }
  }
`;
export const UPDATE_PAYMENT_PLAN_ITEM = gql`
  mutation UPDATE_PAYMENT_PLAN_ITEM($data: PaymentPlanItemUpdateInput!) {
    paymentPlanItemUpdate(data: $data) {
      id
    }
  }
`;
export const UPDATE_PAYMENT_PLAN = gql`
  mutation UPDATE_PAYMENT_PLAN($data: PaymentPlanUpdateInput!) {
    paymentPlanUpdate(data: $data) {
      id
    }
  }
`;

export const CREATE_PAYMENT_PLAN_MUTATION = gql`
  mutation CREATE_PAYMENT_PLAN_MUTATION($data: PaymentPlanCreateInput!) {
    paymentPlanCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_OPTIONAL_PREFERENCE_MUTATION = gql`
  mutation CREATE_OPTIONAL_PREFERENCE_MUTATION(
    $data: OptionalPreferenceCreateInput!
  ) {
    optionalPreferenceCreate(data: $data) {
      id
    }
  }
`;

export const DELETE_BED_PAYMENT_PLANS = gql`
  mutation DELETE_BED_PAYMENT_PLANS($id: ID!) {
    availabilityDelete(data: { id: $id }) {
      success
    }
  }
`;
export const CREATE_BED_PAYMENT_PLANS = gql`
  mutation CREATE_BED_PAYMENT_PLANS($data: AvailabilityCreateInput!) {
    availabilityCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_UNIT_MUTATION = gql`
  mutation UPDATE_UNIT_MUTATION($data: UnitUpdateInput!) {
    unitUpdate(data: $data) {
      updatedAt
    }
  }
`;

export const GET_PROPERTY_DATA_FOR_SETTINGS = gql`
  query GET_PROPERTY_DATA_FOR_SETTINGS($propertyId: ID!) {
    property(id: $propertyId) {
      formPropertyRelation {
        items {
          standardSectionsIncludes
          id
        }
      }
    }
  }
`;

export const GET_PROPERTY_DATA = gql`
  query GET_PROPERTY_DATA($propertyId: ID!) {
    property(id: $propertyId) {
      id
      aboutDescription
      location
      type
      subdomain
      headerImage {
        id
        fileId
        filename
        downloadUrl
      }
      facebookUrl
      email
      featuresDescription
      friendsDescription
      primaryPropertyImage {
        downloadUrl
      }
      propertyImagePropertyRelation(sort: { position: ASC }) {
        items {
          id
          file {
            downloadUrl
            fileId
          }
          caption
          position
          type
        }
      }
      instagramUrl
      isHtmlAboutDescription
      leasePeriodDescription
      managerFirstName
      managerLastName
      name
      officeHours
      phone
      street
      switchAbout
      switchAdditionalResources
      switchContactForm
      switchFeatures
      switchFriends
      switchGallery
      switchHowItWorks
      switchLatestPost
      switchLeasePeriods
      switchLocation
      switchPeopleSay
      switchSimilarProperties
      twitterUrl
      websiteUrl
      websiteVisibility
      zipCode
      state
      city
      primaryPropertyImage {
        id
        fileId
        filename
        downloadUrl
      }
      testimonialProperty {
        items {
          name
          content
          id
          image {
            fileId
            filename
            downloadUrl
          }
          enable
        }
      }
      aditionalResourcesDescription
      galleryDescription
      propertyLeasePeriodRelation {
        items {
          startDate
          endDate
          name
          id
        }
      }
    }
  }
`;

export const GET_PROPERTY_SITE_SETTINGS = gql`
  query GET_PROPERTY_SITE_SETTINGS($propertyId: ID!) {
    siteSettingsList(filter: { property: { id: { equals: $propertyId } } }) {
      items {
        id
        allowSiteToSendEmails
        conditionallyRequireGuarantor
        autofillNewRenewalApplication
        enableOnlineLeasingACHPayment
        enableOnlineLeasingCreditCardPayment
        enablePaymentsOnReservationCheckout
        enablePaymentsOnTenantProfile
        enableTenantPortalACHPayment
        enableTenantPortalCreditCardPayment
        disableTenantMaintenanceTickets
        offlinePaymentsAddress
        offlinePaymentsAddressGooglePlaces
        offlinePaymentsAddressStreetAddress
        offlinePaymentsAddressCity
        offlinePaymentsAddressZipCode
        requiresIdentityVerificationOnTenant
        requiresRoommateMatchingQuiz
      }
    }
  }
`;

export const UPDATE_PROPERTY_PAGE = gql`
  mutation UPDATE_PROPERTY_PAGE(
    $propertyId: PropertyKeyFilter!
    $data: PropertyUpdateInput!
  ) {
    propertyUpdate(data: $data, filter: $propertyId, force: true) {
      id
    }
  }
`;

export const UPDATE_PROPERTY_SITE_SETTINGS = gql`
  mutation UPDATE_PROPERTY_SITE_SETTINGS(
    $siteSettingId: ID!
    $data: SiteSettingUpdateInput!
  ) {
    siteSettingUpdate(filter: { id: $siteSettingId }, data: $data) {
      id
    }
  }
`;

export const CREATE_PROPERTY_SITE_SETTINGS = gql`
  mutation CREATE_PROPERTY_SITE_SETTINGS($data: SiteSettingCreateInput!) {
    siteSettingCreate(data: $data) {
      id
    }
  }
`;

export const FETCH_IDENTITY_DOCUMENT_REQUIRED = gql`
  query FETCH_IDENTITY_DOCUMENT_REQUIRED($propertyId: ID!) {
    documentTypesList(filter: { name: { contains: "Proof of Identity" } }) {
      items {
        id
        documentTypeRequiredUploadRelation(
          filter: { property: { id: { equals: $propertyId } } }
        ) {
          items {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_TESTIMONIAL = gql`
  mutation CREATE_TESTIMONIAL($data: TestimonialCreateInput!) {
    testimonialCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_TESTIMONIAL = gql`
  mutation UPDATE_TESTIMONIAL($data: TestimonialUpdateInput!) {
    testimonialUpdate(data: $data) {
      id
    }
  }
`;

export const GET_PROPERTY_LATE_FEES = gql`
  query GET_PROPERTY_LATE_FEES($id: ID!) {
    lateFeesList(filter: { property: { id: { equals: $id } } }) {
      items {
        id
        name
        type
        oneTimeStartDay
        oneTimeFeeAmount
        dailyFeeStartDay
        dailyFeeAmount
        lateFeeFinalDay
        status
        minimumDebtAmount
      }
    }
  }
`;

export const TOGGLE_LATE_FEE = gql`
  mutation TOGGLE_LATE_FEE($id: ID!, $status: String!) {
    lateFeeUpdate(filter: { id: $id }, data: { status: $status }) {
      id
      updatedAt
    }
  }
`;

export const CREATE_LATE_FEE = gql`
  mutation CREATE_LATE_FEE($data: LateFeeCreateInput!) {
    lateFeeCreate(data: $data) {
      id
      createdAt
    }
  }
`;

export const DELETE_LATE_FEE = gql`
  mutation DELETE_LATE_FEE($data: LateFeeDeleteInput!) {
    lateFeeDelete(data: $data) {
      success
    }
  }
`;

export const UPDATE_LATE_FEE = gql`
  mutation UPDATE_LATE_FEE($data: LateFeeUpdateInput!) {
    lateFeeUpdate(data: $data) {
      id
    }
  }
`;

export const CREATE_INVENTORY_ITEM = gql`
  mutation CREATE_INVENTORY_ITEM(
    $data: OptionalPreferenceInvetoryCreateInput!
  ) {
    optionalPreferenceInvetoryCreate(data: $data) {
      id
    }
  }
`;

export const CREATE_ADDITIONAL_DATA_MUTATION = gql`
  mutation CREATE_ADDITIONAL_DATA(
    $data: OptionalPreferenceAdditionalDatumCreateInput!
  ) {
    optionalPreferenceAdditionalDatumCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_CLIENT_USER = gql`
  mutation UPDATE_CLIENT_USER(
    $filter: ClientUserKeyFilter!
    $data: ClientUserUpdateInput!
  ) {
    clientUserUpdate(filter: $filter, data: $data) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UPDATE_INVENTORY_ITEM(
    $data: OptionalPreferenceInvetoryUpdateInput!
  ) {
    optionalPreferenceInvetoryUpdate(data: $data) {
      id
    }
  }
`;

export const UPDATE_ADDITIONAL_DATA = gql`
  mutation UPDATE_ADDITIONAL_DATA(
    $data: OptionalPreferenceAdditionalDatumUpdateInput!
  ) {
    optionalPreferenceAdditionalDatumUpdate(data: $data) {
      id
    }
  }
`;

export const FETCH_PROPERTY_FILTER_QUERY = gql`
  query FETCH_PROPERTY_FILTER_ITEMS($propertyId: ID) {
    property(id: $propertyId) {
      id
      propertyBuildingRelation {
        items {
          id
          code
          buildingFloorRelation {
            items {
              id
              number
            }
          }
        }
      }
      unitTypePropertyRelation {
        items {
          typeName
          id
          unitUnitTypeRelation {
            items {
              code
              id
            }
          }
        }
      }
      roomTypePropertyRelation {
        items {
          typeName
          id
        }
      }
    }
  }
`;

export const AVAILABILITY_LIST_QUERY = gql`
  query AVAILABILITY_LIST_QUERY($filter: AvailabilityFilter!) {
    availabilitiesList(filter: $filter) {
      count
      items {
        id
        availabilityPaymentPlanRelation {
          items {
            id
            type
            paymentPlan {
              id
              name
            }
          }
        }
        availabilityOptionalPreferenceRelation {
          items {
            id
            type
            optionalPreference {
              id
              name
            }
          }
        }
        available
        bed {
          id
          number
          price
          gender
          room {
            id
            number
            code
            roomType {
              typeName
            }
            unit {
              id
              gender
              isFirstCome
              unitType {
                typeName
              }
              code
              beds
              floor {
                id
                number
                building {
                  id
                  name
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AVAILABILITY_LIST_BASIC_QUERY = gql`
  query AVAILABILITY_LIST_BASIC_QUERY(
    $filter: BedFilter!
    $skip: Int
    $pageSize: Int
  ) {
    bedsList(filter: $filter, skip: $skip, first: $pageSize) {
      count
      items {
        id
        bedAvailabilityRelation {
          items {
            id
            available
            bed {
              id
              number
              price
              gender
              room {
                id
                number
                code
                roomType {
                  typeName
                }
                unit {
                  id
                  gender
                  isFirstCome
                  unitType {
                    typeName
                  }
                  code
                  beds
                  floor {
                    id
                    number
                    building {
                      id
                      name
                      code
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AVAILABILITY_LIST_PP_QUERY = gql`
  query AVAILABILITY_LIST_PP_QUERY(
    $filter: BedFilter!
    $skip: Int
    $pageSize: Int
  ) {
    bedsList(filter: $filter, skip: $skip, first: $pageSize) {
      count
      items {
        id
        bedAvailabilityRelation {
          items {
            id
            leasePeriod {
              id
              name
            }
            availabilityPaymentPlanRelation {
              items {
                id
                type
                paymentPlan {
                  id
                  name
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AVAILABILITY_LIST_OP_QUERY = gql`
  query AVAILABILITY_LIST_OP_QUERY(
    $filter: BedFilter!
    $skip: Int
    $pageSize: Int
  ) {
    bedsList(filter: $filter, skip: $skip, first: $pageSize) {
      count
      items {
        bedAvailabilityRelation {
          items {
            id
            availabilityOptionalPreferenceRelation {
              items {
                id
                type
                optionalPreference {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UNIT_UPDATE_BY_FILTER = gql`
  mutation UNIT_UPDATE_BY_FILTER(
    $data: UnitUpdateByFilterInput!
    $filter: UnitFilter
  ) {
    unitUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        gender
      }
    }
  }
`;

export const FETCH_PROPERTY_LEASE_PERIOD = gql`
  query FETCH_PROPERTY_LEASE_PERIOD($id: ID!) {
    property(id: $id) {
      id
      propertyLeasePeriodRelation {
        items {
          id
        }
      }
    }
  }
`;

export const FETCH_LEDGERS = gql`
  query FETCH_LEDGERS($filter: LedgerFilter!) {
    ledgersList(filter: $filter) {
      items {
        id
        amount
        type
        amountPaid
        subCategory
        category
        createdAt
      }
    }
  }
`;

export const GET_CUSTOMER_SUBSCRIPTION_INVOICES = gql`
  mutation GET_CUSTOMER_SUBSCRIPTION_INVOICES($stripeCustomerId: String) {
    getSubscription(stripeCustomerId: $stripeCustomerId) {
      invoices {
        status
        amount
        date
        isSubscription
      }
    }
  }
`;

export const AVAILABILITY_COUNT_QUERY = gql`
  query AVAILABILITY_COUNT_QUERY($filter: AvailabilityFilter!) {
    availabilitiesList(filter: $filter) {
      count
      items {
        id
        bed {
          id
        }
      }
    }
  }
`;

export const FETCH_SUBPERIODS = gql`
  query FETCH_SUBPERIODS($leasePeriodId: ID!) {
    leasePeriod(id: $leasePeriodId) {
      leasePeriodPeriodSubPeriodRelation {
        items {
          id
          leaseSubPeriod {
            id
          }
        }
      }
    }
  }
`;

export const FETCH_SUBPERIODS_BY_PROPERTY = gql`
  query FETCH_SUBPERIODS_BY_PROPERTY($propertyId: ID!) {
    leaseSubPeriodsList(filter: { property: { id: { equals: $propertyId } } }) {
      items {
        id
        name
        startDate
        startTime
        endTime
        endDate
        property {
          client {
            id
          }
        }
      }
    }
  }
`;

export const FETCH_LEASE_PERIODS_BY_PROPERTY = gql`
  query FETCH_LEASE_PERIODS_BY_PROPERTY($propertyId: ID!) {
    leasePeriodsList(filter: { property: { id: { equals: $propertyId } } }) {
      items {
        id
        name
        code
        minimumPrice
        maximumPrice
        leasePeriodLeaseRelation {
          items {
            userResident {
              id
            }
            status
            leasePeriod {
              id
            }
          }
        }
        leasePeriodPeriodSubPeriodRelation {
          items {
            leaseSubPeriod {
              id
              startDate
              endDate
              name
            }
          }
        }
        leasePeriodPaymentPlanRelation(first: 1, sort: { status: ASC }) {
          items {
            total
            id
          }
        }
        residentPropDocumentLeasePeriodRelation {
          items {
            id
            signers
            name
            status
            startDate
            helloSignTemplateId
            endDate
            type
          }
        }
        guarantorPropDocumentLeasePeriodRelation {
          items {
            id
            signers
            name
            status
            startDate
            helloSignTemplateId
            endDate
            type
          }
        }
        propertyFormLeasePeriodRelation {
          items {
            id
            name
            type
          }
        }
        startDate
        startTime
        endTime
        endDate
        displayOnDashboard
        onlineType
        leasePeriodAvailabilityRelation(
          filter: {
            availabilityPaymentPlanRelation: {
              some: { id: { is_not_empty: true } }
            }
          }
        ) {
          count
          items {
            availabilityPaymentPlanRelation {
              items {
                id
                paymentPlan {
                  name
                  leasePeriod {
                    id
                    name
                  }
                }
              }
            }
            lease {
              id
            }
          }
        }
        leasePeriodRenewals {
          leasePeriods {
            items {
              id
              name
              startDate
              endDate
            }
          }
        }
        property {
          buildingLayout {
            building {
              items {
                buildingFloorRelation {
                  items {
                    floorUnitRelation {
                      items {
                        unitRoomRelation {
                          items {
                            roomBedRelation {
                              count
                              items {
                                code
                                bedAvailabilityRelation {
                                  items {
                                    available
                                    leasePeriod {
                                      id
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          client {
            id
          }
        }
      }
    }
  }
`;

export const DELETE_BUILDINGS_BY_FILTER = gql`
  mutation DELETE_BUILDINGS_BY_FILTER($data: BuildingFilter!) {
    buildingDeleteByFilter(filter: $data, force: true) {
      success
      __typename
    }
  }
`;

// "clh0sn8ku003i08l78rqh0680"
export const LAYOUT_LOADED_SUBSCRIPTION = gql`
  subscription LAYOUT_LOADED_SUBSCRIPTION($id: ID!) {
    BuildingLayoutUpload(
      filter: { mutation_in: [update], node: { id: { equals: $id } } }
    ) {
      node {
        id
        status
      }
    }
  }
`;
