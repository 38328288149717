import gql from 'graphql-tag';

export const GET_CLIENT_USER_PORTFOLIOS = gql`
  query GET_CLIENT_USER_PORTFOLIOS($clientUserId: ID!, $skip: Int!, $toShow: Int!) {
    propertyPortfoliosList(
      filter: {
        clientUserPropertyPortfolioRelation: { id: { equals: $clientUserId } }
      }
      skip: $skip
      first: $toShow
    ) {
      count
      items {
        id
        name
        property {
          items {
            id
            name
            street
          }
        }
      }
    }
  }
`;

export const CREATE_PORTFOLIO = gql`
  mutation CREATE_PORTFOLIO($data: PropertyPortfolioCreateInput!) {
    propertyPortfolioCreate(data: $data) {
      id
      name
      property {
        items {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_PORTFOLIO = gql`
  mutation DELETE_PORTFOLIO($portfolioId: ID!) {
    propertyPortfolioDelete(filter: { id: $portfolioId }) {
      success
    }
  }
`;

export const UPDATE_PORTFOLIO = gql`
  mutation UPDATE_PORTFOLIO($portfolioId: ID!, $dataToUpdate: PropertyPortfolioUpdateInput!) {
    propertyPortfolioUpdate(filter: { id: $portfolioId }, data: $dataToUpdate) {
      id
      name
      property {
        items {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_CLIENT_QUERY = gql`
  query FETCH_CLIENT($id: ID!) {
    client(id: $id) {
      id
      name
      tradeName
      addressLine1
      addressLine2
      city
      state
      zipCode
      eINNumber
      mainContactName
      mainContactNumber
      mainContactEmail
    }
  }
`;

export const UPDATE_CLIENT_MUTATION = gql`
  mutation UPDATE_CLIENT($data: ClientUpdateInput!) {
    clientUpdate(data: $data) {
      updatedAt
    }
  }
`;

export const CREATE_MANAGER_INVITATION = gql`
  mutation CREATE_MANAGER_INVITATION(
    $data: InvitationPropertyManagerCreateInput!
  ) {
    invitationPropertyManagerCreate(data: $data) {
      id
      firstName
      email
      lastName
      status
      propertyInvitationPropertyManager {
        items {
          property {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_MAINTENANCE_INVITATION = gql`
  mutation CREATE_MAINTENANCE_INVITATION(
    $data: InvitationMaintenanceCreateInput!
  ) {
    invitationMaintenanceCreate(data: $data) {
      id
      firstName
      email
      lastName
      status
      propertyInvitationMaintenance {
        items {
          property {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_TEAM_MEMBER_INVITATION = gql`
  mutation CREATE_TEAM_MEMBER_INVITATION(
    $data: InvitationTeamMemberCreateInput!
  ) {
    invitationTeamMemberCreate(data: $data) {
      id
      firstName
      email
      lastName
      status
      propertyInvitationTeamMember {
        items {
          property {
            id
            name
          }
        }
      }
    }
  }
`;
export const CHANGE_CLIENT_INVITATION_ROLE = gql`
  mutation CHANGE_CLIENT_INVITATION_ROLE($data: ChangeClientInvitationInput!) {
    changeClientInvitationRole(data: $data) {
      message
      success
    }
  }
`;

export const GET_CLIENT_USERS_AND_INVITATIONS = gql`
  query GET_CLIENT_USERS_AND_INVITATIONS($clientId: ID!, $inviterId: ID!) {
    clientUsersList(filter: { client: { id: { equals: $clientId } } }) {
      items {
        id
        user {
          firstName
          lastName
          email
        }
        property {
          items {
            id
            name
          }
        }
        active
        role
      }
    }

    invitationPropertyManagersList(
      filter: {
        client: { id: { equals: $clientId } }
        status: { equals: "PENDING" }
      }
    ) {
      count
      items {
        id
        firstName
        email
        lastName
        propertyInvitationPropertyManager {
          items {
            property {
              id
              name
            }
          }
        }
        status
      }
    }

    invitationMaintenancesList(
      filter: {
        client: { id: { equals: $clientId } }
        status: { equals: "PENDING" }
      }
    ) {
      count
      items {
        id
        firstName
        email
        lastName
        propertyInvitationMaintenance {
          items {
            property {
              id
              name
            }
          }
        }
        status
      }
    }

    invitationTeamMembersList(
      filter: {
        client: { id: { equals: $clientId } }
        status: { equals: "PENDING" }
      }
    ) {
      count
      items {
        id
        firstName
        email
        lastName
        propertyInvitationTeamMember {
          items {
            property {
              id
              name
            }
          }
        }
        status
      }
    }

    invitationPropertyOwnersList(
      filter: {
        inviter: { id: { equals: $inviterId } }
        status: { equals: "PENDING" }
      }
    ) {
      items {
        id
        firstName
        email
        lastName
        status
      }
    }
  }
`;

export const DELETE_INVITATION_PROPERTY_MANAGER = gql`
  mutation DELETE_INVITATION_PROPERTY_MANAGER($id: ID!) {
    invitationPropertyManagerDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const DELETE_INVITATION_PROPERTY_OWNER = gql`
  mutation DELETE_INVITATION_PROPERTY_OWNER($id: ID!) {
    invitationPropertyOwnerDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const DELETE_CLIENT_USER = gql`
  mutation DELETE_CLIENT_USER($id: ID!) {
    clientUserDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;

export const EDIT_PROPERTY_MANAGER_INVITATION = gql`
  mutation EDIT_PROPERTY_MANAGER_INVITATION($id: ID!, $data: InvitationPropertyManagerUpdateInput!) {
    invitationPropertyManagerUpdate(filter: { id: $id }, data: $data) {
      id
      firstName
      lastName
      email
      status
      propertyInvitationPropertyManager {
        items {
          property {
            id
            name
          }
        }
      }
    }
  }
`;

export const EDIT_PROPERTY_OWNER_INVITATION = gql`
  mutation EDIT_PROPERTY_OWNER_INVITATION($id: ID!, $data: InvitationPropertyOwnerUpdateInput!) {
    invitationPropertyOwnerUpdate(filter: { id: $id }, data: $data) {
      id
    }
  }
`;

export const DELETE_PROPERTY_INVITATION_MANAGER = gql`
  mutation DELETE_PROPERTY_INVITATION_MANAGER($data: PropertyInvitationPropertyManagerFilter!) {
    propertyInvitationPropertyManagerDeleteByFilter(filter: $data) {
      success
    }
  }
`;

export const DELETE_PROPERTY_INVITATION_MAINTENANCE = gql`
  mutation DELETE_PROPERTY_INVITATION_MAINTENANCE(
    $data: PropertyInvitationMaintenanceFilter!
  ) {
    propertyInvitationMaintenanceDeleteByFilter(filter: $data) {
      success
    }
  }
`;

export const EDIT_MAINTENANCE_INVITATION = gql`
  mutation EDIT_MAINTENANCE_INVITATION(
    $id: ID!
    $data: InvitationMaintenanceUpdateInput!
  ) {
    invitationMaintenanceUpdate(filter: { id: $id }, data: $data) {
      id
      firstName
      lastName
      email
      status
      propertyInvitationMaintenance {
        items {
          property {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_PROPERTY_INVITATION_TEAM_MEMBER = gql`
  mutation DELETE_PROPERTY_INVITATION_TEAM_MEMBER(
    $data: PropertyInvitationTeamMemberFilter!
  ) {
    propertyInvitationTeamMemberDeleteByFilter(filter: $data) {
      success
    }
  }
`;

export const EDIT_TEAM_MEMBER_INVITATION = gql`
  mutation EDIT_TEAM_MEMBER_INVITATION(
    $id: ID!
    $data: InvitationTeamMemberUpdateInput!
  ) {
    invitationTeamMemberUpdate(filter: { id: $id }, data: $data) {
      id
      firstName
      lastName
      email
      status
      propertyInvitationTeamMember {
        items {
          property {
            id
            name
          }
        }
      }
    }
  }
`;

export const EDIT_CLIENT_USER = gql`
  mutation EDIT_CLIENT_USER($id: ID!, $data: ClientUserUpdateInput!) {
    clientUserUpdate(data: $data, filter: { id: $id }) {
      id
      user {
        firstName
        lastName
        email
      }
      property {
        items {
          id
          name
        }
      }
      active
      role
    }
  }
`;

export const GET_ACCOUNTS_QUERY = gql`
  query GET_ACCOUNTS_QUERY($clientId: ID!) {
    accountsList(filter: { client: { id: { equals: $clientId } } }) {
      items {
        id
        number
        accountType {
          name
          displayName
        }
        accountCategory {
          name
          displayName
        }
        accountSubCategory {
          name
          displayName
        }
        isActive
        defaultSign
      }
    }
  }
`;

export const GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORY = gql`
  query GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORY($subcategory: String) {
    defaultAccountsList(
      filter: { accountSubCategory: { name: { equals: $subcategory } } }
    ) {
      items {
        number
        accountType {
          name
          displayName
        }
        accountCategory {
          name
          displayName
        }
        accountSubCategory {
          name
          displayName
        }
        isActive
        defaultSign
        transactionIdOrReference
        requiresTransactionIdOrReference
        isOnline
        depositsToBank
      }
    }
  }
`;

export const GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORIES = gql`
  query GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORIES($subcategories: [String!]) {
    defaultAccountsList(
      filter: { accountSubCategory: { name: { in: $subcategories } } }
    ) {
      items {
        number
        accountType {
          name
          displayName
        }
        accountCategory {
          name
          displayName
        }
        accountSubCategory {
          name
          displayName
        }
        isActive
        defaultSign
        transactionIdOrReference
        requiresTransactionIdOrReference
        isOnline
        depositsToBank
      }
    }
  }
`;

export const GET_DEFAULT_ACCOUNTS_QUERY = gql`
  query GET_DEFAULT_ACCOUNTS_QUERY {
    defaultAccountsList {
      items {
        number
        accountType {
          name
          displayName
        }
        accountCategory {
          name
          displayName
        }
        accountSubCategory {
          name
          displayName
        }
        isActive
        defaultSign
        transactionIdOrReference
        requiresTransactionIdOrReference
        isOnline
        depositsToBank
      }
    }
  }
`;

export const TOGGLE_ACCOUNT = gql`
  mutation TOGGLE_ACCOUNT($id: ID!, $isActive: Boolean!) {
    accountUpdate(filter: { id: $id }, data: { isActive: $isActive }) {
      id
      updatedAt
    }
  }
`;

export const FETCH_CLIENT_PAGE_QUERY = gql`
  query FETCH_CLIENT_PAGE_QUERY($id: ID) {
    clientPagesList(
      filter: {
        clientPageClientRelation: {
          clientClientUserRelation: { some: { user: { id: { equals: $id } } } }
        }
      }
    ) {
      items {
        id
        headerImage {
          fileId
          downloadUrl
          filename
        }
        clientPageClientRelation {
          stateCode
          zipCodeSuffix
          city
          location
        }
        name
        location
        stateCode
        address
        address2
        city
        managerFirstName
        managerLastName
        state
        zipCode
        phoneNumber
        email
        webUrl
        officeHours
        aboutYourLocation
        instagramUrl
        facebookUrl
        twitterUrl
        instagramAt
        instagramHashtag
        urlSubdomain
        siteDescription
        metaDescription
        siteLogo {
          fileId
          downloadUrl
          filename
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_PAGE_MUTATION = gql`
  mutation UPDATE_CLIENT_PAGE_MUTATION($data: ClientPageUpdateInput!) {
    clientPageUpdate(data: $data) {
      id
    }
  }
`;

export const RESEND_INVITATION = gql`
  mutation RESEND_INVITATION(
    $clientId: String
    $id: ID!
    $inviter: String
    $email: String
    $clientUserType: String
  ) {
    resendInvitation(
      clientId: $clientId
      inviter: $inviter
      id: $id
      email: $email
      clientUserType: $clientUserType
    ) {
      success
    }
  }
`;
