import * as React from 'react';
import { 
  Box, 
  makeStyles, 
  Step, 
  StepIconProps, 
  StepLabel, 
  Stepper, 
  Theme, 
  Typography 
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiStepIcon-active': { 
      color: 'black' 
    },
    '& .MuiStepIcon-completed': { 
      color: 'black' 
    },
    '& .Mui-disabled .MuiStepIcon-root': { 
      color: 'black' 
    },
  },
  completed: {
    backgroundColor: 'cyan'
  },
  stepIcon: {
    height: '3.25rem',
    width: '3.25rem',
    borderRadius: '50%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(152, 169, 188, .4)',
  },
  active: {
    height: '3.25rem',
    width: '3.25rem',
    borderRadius: '50%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(77, 124, 254, .4)',
  },
  stepIconInner: {
    height: '2.5rem',
    textAlign: 'center',
    lineHeight: '2.5rem',
    width: '2.5rem', 
    borderRadius: '50%',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: 'rgb(152, 169, 188)',
  },
  activeInner: {
    height: '2.5rem',
    textAlign: 'center',
    lineHeight: '2.5rem',
    width: '2.5rem', 
    borderRadius: '50%',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: 'rgb(77, 124, 254)',
  },
  connector: {
    position: 'absolute',
    left: '-45px',
    bottom: '65px',
    width: '90px',
    border: '1px solid rgba(152, 169, 188, .2) '
    
  }
}));
interface Props {
  activeStep: number,
  errorMessage?: string,
  steps: string[]
}

export const ComposeMessageStepper = ({ activeStep, errorMessage, steps }: Props): JSX.Element => {

  const classes = useStyles()
  const isStepFailed = (step: number): boolean => {
    return step === steps.indexOf(errorMessage ?? '') ?? false;
  };

  const ColorlibStepIcon = ({ active, completed, icon }: StepIconProps): React.ReactElement => {
    
    const style = active || completed ? classes.active : classes.stepIcon
    const innerStyle = active || completed ? classes.activeInner : classes.stepIconInner
  
    return (
      <div className={style}>
        <div className={innerStyle}>
          {`0${icon}`}
        </div>
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper 
        connector={<div className={classes.connector}></div>}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps.map((label, index) => {
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Alert message
              </Typography>
            );
            labelProps.error = true;
          }

          return (
            <Step key={label}>
              <StepLabel 
                StepIconComponent={ColorlibStepIcon} 
                {...labelProps} 
                StepIconProps={{
                  classes: {
                    active: classes.active,
                    text: classes.active
                  }
              }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

    </Box>
  );
}  