import * as React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import smsCompose from '../../../shared/assets/smsCompose.svg';
import emailCompose from '../../../shared/assets/emailCompose.svg';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '24px 0px',
  },
  cardStyle: {
    width: '21rem',
    height: '13.25rem',
    border: '1.5px solid',
    borderColor: 'transparent',
    padding: '5px',
  },
  cardStyleActive: {
    width: '21rem',
    height: '13.25rem',
    display: 'flex',
    padding: '5px',
    borderColor: '#4D7CFE',
    border: '1.5px solid',
    borderRadius: '4px',
  },
  cardText: {
    color: '#1B1E24',
    fontSize: '14px',
    lineHeight: '17px',
  },
  cardTextActive: {
    color: '#4D7CFE',
    fontSize: '14px',
    lineHeight: '17px',
  },
  cardActionArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  cardIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
}));

interface Props {
  isEmail: boolean | undefined;
  loading: boolean;
  onClick: (value: string) => void;
}

enum MessageVia {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export const MessageType = ({
  isEmail,
  loading,
  onClick,
}: Props): React.ReactElement => {
  const classes = useStyles();

  if (loading) {
    return (
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        alignContent="center"
        style={{
          padding: '24px 0px',
        }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="space-evenly"
      alignItems="center"
      alignContent="flex-start"
      className={classes.container}>
      <Grid item>
        <Card
          className={isEmail ? classes.cardStyleActive : classes.cardStyle}
          onClick={() => onClick(MessageVia.EMAIL)}>
          <CardActionArea className={classes.cardActionArea}>
            <CardMedia
              component="img"
              height={150}
              width={100}
              src={emailCompose}
              alt={MessageVia.EMAIL}
              draggable={false}
            />
            <CardContent>
              <Typography
                variant="h5"
                className={isEmail ? classes.cardTextActive : classes.cardText}
                component="div">
                EMAIL
              </Typography>
              <div hidden={!isEmail} className={classes.cardIcon}>
                <CheckCircle color="primary" fontSize="large" />
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <Card
          className={!isEmail ? classes.cardStyleActive : classes.cardStyle}
          onClick={() => onClick(MessageVia.SMS)}>
          <CardActionArea className={classes.cardActionArea}>
            <CardMedia
              component="img"
              height={150}
              width={100}
              src={smsCompose}
              alt={MessageVia.SMS}
              draggable={false}
            />
            <CardContent>
              <Typography
                variant="h5"
                className={!isEmail ? classes.cardTextActive : classes.cardText}
                component="div">
                SMS
              </Typography>
              <div hidden={isEmail} className={classes.cardIcon}>
                <CheckCircle color="primary" fontSize="large" />
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};
