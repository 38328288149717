/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useCallAction,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import {
  OptionalPreference,
  Lease,
  OptionalPreferenceAdditionalDatum,
  /*   AccountSubCategory,
   */
  LeaseOptionalPreferenceCreateInput,
  LeasePeriod,
} from '../../../schema-types';

import { Box, Grid, MenuItem, Select } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AlertContext } from '../../../routes/AlertContext';
import { Dialog } from '../../../shared/components/Dialog';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { ButtonDefault } from '../../../shared/components/ui/buttons/ButtonDefault';
import { TextFieldDefault } from '../../../shared/components/ui/inputs/TextFieldDefault';
import {
  createOptionalPreference,
  /*   fetchSubcategories,
   */ getAvailableOptionalPreferences,
} from '../people-actions';
import { deleteLeaseOptionalPreferenceEvent } from '../people-event';
import { LeaseStatus } from '@8baselabs/resident-io-shared';

type Props = {
  onClose: () => void;
  open: boolean;
  selectedLease: Lease;
  refetch: () => void;
};

export const AddOptionalPreferencesDialog: React.FC<Props> = ({
  onClose,
  open,
  selectedLease,
  refetch,
}) => {
  const alert = useContext(AlertContext);
  const [selectedOptionalPreference, setselectedOptionalPreferece] =
    useState<OptionalPreference>();
  /*   const [selectedLedgerSubcategory, setSelectedLedgerSubcategory] =
    useState<AccountSubCategory>();
 */
  const [form, setform] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedInventoryItem, setselectedInventoryItem] =
    useState<string>('');

  /*   const [ledgerSubcategories, loadingLedger] = useFetchAction(
    fetchSubcategories,
    [],
  ); */

  const [optionalPreferencesResponse, loading, { refetch: refetchOpOptions }] =
    useFetchAction(
      getAvailableOptionalPreferences,
      [
        selectedLease?.property?.id as string,
        selectedLease?.leasePeriod as LeasePeriod,
        selectedLease?.id as string,
        selectedLease?.isRenewal,
      ],
      {
        skip:
          !selectedLease?.property?.id ||
          !selectedLease?.leasePeriod?.id ||
          !selectedLease?.id,
      },
    );

  const filteredOptionalPreferences = useMemo(
    () =>
      optionalPreferencesResponse?.items?.filter((op) => {
        if (
          op?.optionalPreferenceInvetoryRelation?.items &&
          op.optionalPreferenceInvetoryRelation.items.length > 0
        ) {
          return op.optionalPreferenceInvetoryRelation?.items?.some(
            (inv: any) => {
              const isDenied =
                inv?.leaseOptionalPreference?.lease?.status ===
                LeaseStatus.DENIED;
              if (!inv?.isSold && (!inv?.leaseOptionalPreference || isDenied))
                return true;
              return false;
            },
          );
        }
        return true;
      }),
    [optionalPreferencesResponse],
  );

  const filteredInventory = useMemo(
    () =>
      selectedOptionalPreference?.optionalPreferenceInvetoryRelation?.items?.filter(
        (inventory: any) => {
          const isDenied =
            inventory?.leaseOptionalPreference?.lease?.status ===
            LeaseStatus.DENIED;
          if (
            !inventory?.isSold &&
            (!inventory?.leaseOptionalPreference || isDenied)
          )
            return true;
          return false;
        },
      ),
    [selectedOptionalPreference],
  );

  const handleClose = (): void => {
    onClose();
    setselectedOptionalPreferece(undefined);
  };

  useEffect(() => {
    setselectedInventoryItem('');
    setform({});
    setErrors({});
  }, [selectedOptionalPreference]);

  const [createPreference, loadingCreate] = useCallAction(
    createOptionalPreference,
    {
      onCompleted() {
        handleClose();
        refetch();
        refetchOpOptions();
        alert({
          success: true,
          message: 'Optional Preference added successfully!',
        });
      },
      onError() {
        alert({
          error: true,
          message: 'Failed to add Optional Preference!',
        });
      },
    },
  );

  useSubscription(deleteLeaseOptionalPreferenceEvent, () => refetchOpOptions());

  const onChangeDialogSelect = (id: string): void => {
    const optionalPreference = filteredOptionalPreferences?.find(
      (op) => op?.id === id,
    );
    if (optionalPreference) setselectedOptionalPreferece(optionalPreference as OptionalPreference);
  };

  /*  const onChangeSubcategory = (id: string): void => {
    const subcategory = ledgerSubcategories?.find(
      (category) => category?.id === id,
    );
    if (subcategory) setSelectedLedgerSubcategory(subcategory);
  };
 */
  const onChangeAdditionalData = (
    data: OptionalPreferenceAdditionalDatum,
    value: string,
  ): void => {
    const key = data?.title || '';
    if (errors[key]) {
      setErrors((pe) => ({ ...pe, [key]: false }));
    }
    setform((prevVal) => ({
      ...prevVal,
      [key]: value || '',
    }));
  };

  const handleSubmit = (): void => {
    const additionalDataList =
      selectedOptionalPreference?.optionalPreferenceAdditionalDataRelation
        ?.items || [];
    let withErrors = false;
    for (let i = 0; i < additionalDataList.length; i += 1) {
      const additionalData = additionalDataList[i];

      if (additionalData?.required) {
        const key = additionalData.title || '';
        const value = form[key];
        if (!value) {
          withErrors = true;
          setErrors((pe) => ({ ...pe, [key]: true }));
        }
      }
    }

    if (withErrors) return;

    if (selectedOptionalPreference && selectedLease) {
      const optionalPreferenceInput: LeaseOptionalPreferenceCreateInput = {
        lease: { connect: { id: selectedLease.id || '' } },
        optionalPreference: { connect: { id: selectedOptionalPreference.id } },
        additionalAppData: form,
        inventory: selectedInventoryItem
          ? {
              connect: {
                id: selectedInventoryItem,
              },
            }
          : undefined,
      };
      createPreference(optionalPreferenceInput);
    }
  };

  return (
    <Dialog
      isVisible={open}
      title="Add Optional Preference"
      size="sm"
      fullWidth
      onClose={handleClose}
      actions={
        <>
          <ButtonDefault onClick={handleClose} disabled={loadingCreate}>
            Cancel
          </ButtonDefault>
          <ButtonAction
            isLoading={loading || loadingCreate}
            disabled={!selectedOptionalPreference}
            onClick={handleSubmit}>
            Save
          </ButtonAction>
        </>
      }>
      <Box fontSize="16px" mb="15px" fontWeight={500}>
        Select Optional Preference
      </Box>
      <Select
        variant="outlined"
        fullWidth
        value={selectedOptionalPreference?.id || ''}
        onChange={(e, val) => onChangeDialogSelect(e?.target?.value as string)}>
        {filteredOptionalPreferences?.map((op) => (
          <MenuItem
            key={op?.id}
            value={op?.id}
            selected={op?.id === selectedOptionalPreference?.id}>
            {op?.name}
          </MenuItem>
        ))}
      </Select>

      {selectedOptionalPreference && filteredInventory?.length ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt="20px" fontSize="16px" fontWeight={500}>
              Select available Inventory
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Select
              variant="outlined"
              fullWidth
              value={selectedInventoryItem || ''}
              onChange={(e, val) =>
                setselectedInventoryItem(e?.target.value as string)
              }>
              {filteredInventory
                .sort((a, b) =>
                  (a?.name || '').localeCompare(b?.name || '', undefined, {
                    numeric: true,
                    sensitivity: 'base',
                  }),
                )
                .map((inv) => (
                  <MenuItem
                    key={inv?.id}
                    value={inv?.id}
                    selected={inv?.id === selectedInventoryItem}>
                    {inv?.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      ) : undefined}
      {selectedOptionalPreference &&
      selectedOptionalPreference?.optionalPreferenceAdditionalDataRelation
        ?.items?.length ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt="20px" fontSize="16px" fontWeight={500}>
              Additional App Data
            </Box>
          </Grid>
          {selectedOptionalPreference?.optionalPreferenceAdditionalDataRelation?.items?.map(
            (additionalData) => {
              const key = additionalData?.title || '';
              return (
                <Grid item xs={6} key={key}>
                  <TextFieldDefault
                    value={form[key]}
                    helperText={errors[key] ? 'This field is required' : ''}
                    error={errors[key]}
                    onChange={(e) =>
                      onChangeAdditionalData(
                        additionalData,
                        e.target.value || '',
                      )
                    }
                    label={additionalData?.title?.toLocaleUpperCase()}
                  />
                </Grid>
              );
            },
          )}
        </Grid>
      ) : undefined}
    </Dialog>
  );
};
