/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useFetchAction } from '@8baselabs/react-simple-state';
import {
  makeStyles,
  createStyles,
  Card,
  Button,
  Container,
  Typography,
} from '@material-ui/core';
import { PersonPinOutlined } from '@material-ui/icons';

import { useQuery } from '../../shared/hooks';

const useStyles = makeStyles(
  createStyles({
    background: {
      backgroundColor: '#f1f1f1',
    },
    container: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      padding: '20px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '480px',
    },
    image: {
      width: '200px',
      margin: '20px',
      textAlign: 'center',
    },
    icon: {
      margin: '20px 20px 5px 20px',
      fontSize: '42px',
      color: '#fe4d97',
      zIndex: 999,
    },
    greet: {
      margin: '5px 20px 10px 20px',
      fontWeight: 'bold',
      fontSize: '24px',
    },
    content: {
      margin: '10px 20px',
      fontSize: '18px',
      padding: '0px 10%',
      fontWeight: 'normal',
    },
    action: {
      margin: '10px 20px 20px 20px',
      fontSize: '14px',
      padding: '0px 10%',
      color: '#414141',
      fontWeight: 'normal',
    },
    highlight: {
      color: '#4d7cfe',
    },
    button: {
      fontSize: '14px',
      margin: '20px',
    },
  }),
);

type RouteParams = {
  id: string;
};

export const InvitationPreview: React.FC = () => {
  const query = useQuery();
  const classes = useStyles();
  const history = useHistory();

  const inviterFirstName = query.get('inviterFirstName');
  const inviterLastName = query.get('inviterLastName');

  const userFirstName = query.get('firstName');

  if (
    (!inviterFirstName && !inviterLastName) ||
    (!inviterFirstName && inviterLastName)
  ) {
    history.push('/auth');
  }

  return (
    <div className={classes.background}>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <img
            src="/assets/resident.svg"
            alt="Resident.io"
            className={classes.image}
          />
          <PersonPinOutlined className={classes.icon} />
          <Typography className={classes.greet}>
            Hello {userFirstName || 'There'}!
          </Typography>
          <Typography className={classes.content}>
            {query.get('inviterFirstName')} {query.get('inviterLastName')} has
            invited you to join{' '}
            <strong className={classes.highlight}>resident.io</strong>
          </Typography>
          <Typography className={classes.action}>
            Sign In or Sign Up to accept the invitation
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => history.push('/auth')}>
            Take me there
          </Button>
        </Card>
      </Container>
    </div>
  );
};
