import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { MainLoader } from '../components/MainLoader';
import { STRIPE_PUBLIC_KEY } from '../constants';

type StripeWrapperProps = {
  children: React.ReactNode;
  accountId?: string;
  useAccountId: boolean;
  options?: {
    clientSecret: string;
  };
};
export const StripeWrapper = ({
  children,
  accountId,
  useAccountId,
  options,
}: StripeWrapperProps): React.ReactElement => {
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

  // This function will re-run if the accountId prop changes.
  useEffect(() => {
    if (useAccountId && accountId) {
      loadStripe(STRIPE_PUBLIC_KEY || '', {
        stripeAccount: accountId,
      }).then((stripe) => {
        setStripeObject(stripe);
      });
    } else {
      loadStripe(STRIPE_PUBLIC_KEY || '').then((stripe) => {
        setStripeObject(stripe);
      });
    }
  }, [accountId, useAccountId]);

  // If no Stripe object, do not render the Stripe Element.
  if (!stripeObject) {
    return <MainLoader title="Loading..." />;
  }

  // Once we have the Stripe object, load everything.
  return (
    <Elements stripe={stripeObject} options={options}>
      {children}
    </Elements>
  );
};
