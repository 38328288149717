import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { isValidString } from '@8baselabs/validation-utils';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  LeaseSubPeriod,
  Lease,
  LedgerCreateInput,
  LedgerUpdateInput,
} from '../../../../schema-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MUTED_COLOR } from '../../../../shared/theme';
import { NumberFormatCustom } from '../../../../shared/components/ui/NumberFormat';
import { ButtonAction } from '../../../../shared/components/ui/buttons/ButtonAction';
import { useCallAction, useEvent } from '@8baselabs/react-simple-state';
import {
  createLedgerAction,
  getIntent,
  updateLedgerAction,
} from '../../people-actions';

import { LedgerTransactionInformationForm } from '../LedgerTransactionInformationForm';
import { createPaymentIntentAction } from '../../../user/user-actions';
// import { fetchUserEvent } from '../../people-event';
import { AlertContext } from '../../../../routes/AlertContext';
import { Box, CircularProgress } from '@material-ui/core';
import { useLocalStorage } from '../../../../shared/components/components-hooks';
import { AddReservationSelectResidentEvent } from '../../../opportunities/opportunities-event';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useDebounce } from 'use-debounce';
import { updateLeaseOpportunitiesAction } from '../../../opportunities/opportunities-actions';

import { AccountSelector } from './AccountSelector';
import { useAccounts } from './useAccounts.hook';
import { PaymentMethodSelector } from './PaymentMethodSelector';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent } from '../../people-types';
import { sanitizeTextToTitleCase } from '../../../../shared/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  LeaseStatus,
  LedgerCreateUpdateDialogCallers,
  LedgerPaymentStatus,
  LedgerPaymentTypes,
  LedgerSubCategory,
} from '@8baselabs/resident-io-shared';
// import { useHistory } from 'react-router';

type LedgerCreateUpdateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  caller?: LedgerCreateUpdateDialogCallers;
  effectiveDate?: Date;
  type?: string;
  paymentType?: string;
  id?: string;
  lease: Lease;
  leaseSubPeriod?: LeaseSubPeriod;
  category?: string;
  onAddPaymentMethod?: () => void;
  subCategory?: string;
  notes?: string;
  description?: string;
  amount?: number;
  ledgerTransactionInformationRelation?: {
    checkNumber?: string;
    stripePyId?: string;
    transactionId?: string;
    lastFour?: string;
    paypalTransactionId?: string;
  };
  isTenant: boolean;
  isDueOnSign?: boolean;
  setLoadingGrid?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type LedgerState = {
  type: string;
  paymentType: string;
  category: string;
  subCategory: string;
  notes: string;
  description: string;
  leaseSubPeriod: string | undefined;
  effectiveDate: Date | undefined;
  amount: number;
  ledgerTransactionInformationRelation: {
    checkNumber?: string;
    stripePyId?: string;
    transactionId?: string;
    lastFour?: string;
    paypalTransactionId?: string;
  };
  reservationFee?: number;
};

export type ErrorStateType = {
  type: boolean;
  paymentType: boolean;
  category: boolean;
  subCategory: boolean;
  notes: boolean;
  description: boolean;
  leaseSubPeriod: boolean;
  effectiveDate: boolean;
  amount: boolean;
  ledgerTransactionInformationRelation: {
    checkNumber: boolean;
    stripePyId: boolean;
    transactionId: boolean;
    lastFour: boolean;
    paypalTransactionId: boolean;
  };
};

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '30%',
    },
  },
  stripeDialog: {
    width: '10rem',
    margin: '1rem',
  },
}));

const stateSetter = (isEditing, data): LedgerState => {
  if (isEditing) {
    return {
      type: data.type,
      paymentType: data.paymentType,
      category: data.category,
      subCategory: data.subCategory,
      notes: data.notes,
      description: data.description,
      leaseSubPeriod: data.leaseSubPeriod?.id,
      effectiveDate: data.effectiveDate,
      amount: data.amount,
      ledgerTransactionInformationRelation:
        data.ledgerTransactionInformationRelation,
    };
  } else {
    return {
      type: '',
      paymentType: '',
      category: '',
      subCategory: '',
      notes: '',
      description: '',
      leaseSubPeriod: '',
      effectiveDate: new Date(),
      amount: 1,
      ledgerTransactionInformationRelation: {
        checkNumber: '',
        stripePyId: '',
        transactionId: '',
        lastFour: '',
        paypalTransactionId: '',
      },
    };
  }
};

const initialStateErrors = {
  amount: false,
  effectiveDate: false,
  paymentType: false,
  leaseSubPeriod: false,
  category: false,
  subCategory: false,
  notes: false,
  description: false,
  type: false,
  ledgerTransactionInformationRelation: {
    checkNumber: false,
    stripePyId: false,
    transactionId: false,
    lastFour: false,
    paypalTransactionId: false,
  },
};

export const LedgerCreateUpdateDialog: React.FC<
  LedgerCreateUpdateDialogProps
> = (props) => {
  const {
    isOpen,
    onClose,
    caller = LedgerCreateUpdateDialogCallers.LEDGER,
    id,
    lease,
    isTenant,
    isDueOnSign,
    onAddPaymentMethod,
    setLoadingGrid,
    ...defaultData
  } = props;
  const ledgerAmount = props.amount || 0;
  const stripe = useStripe();
  const classes = useStyles();
  const alert = useContext(AlertContext);

  let user;

  switch (caller) {
    case LedgerCreateUpdateDialogCallers.LEDGER:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      user = lease?.userResident;
      break;

    case LedgerCreateUpdateDialogCallers.NEW_LEAD:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      user = useEvent(AddReservationSelectResidentEvent);
      break;

    default:
      break;
  }

  // const history = useHistory();
  const isEditing = isValidString(id);

  const [, setLocalStoreLedgerFormState] = useLocalStorage('ledger-state', '');

  const [state, setState] = useState<LedgerState>(
    stateSetter(isEditing, defaultData),
  );
  const [stateErrors, setStateErrors] =
    useState<ErrorStateType>(initialStateErrors);

  const [amount, setAmount] = useState(1);

  const [debouncedAmount] = useDebounce(state.amount, 500);

  const [
    loadingElectronicPaymentCreation,
    setLoadingElectronicPaymentCreation,
  ] = useState(false);
  const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState<
    PaymentIntent | undefined
  >();

  const [paymentMethodId, setPaymentMethodId] = useState<string>('');

  const [createPaymentIntent] = useCallAction(createPaymentIntentAction, {
    onCompleted: async (paymentIntentRes) => {
      if (paymentIntentRes) {
        setState((prevState) => ({
          ...prevState,
          ledgerTransactionInformationRelation: {
            ...prevState.ledgerTransactionInformationRelation,
            stripePyId: paymentIntentRes.id,
          },
        }));
        setLoadingPaymentIntent(false);

        setPaymentIntent(paymentIntentRes);
      }
    },
    onError: (err) => {
      console.error(err);
      alert({
        error: true,
        message: 'Failed to load the payment form. Please try again.',
      });
      setLoadingPaymentIntent(false);
    },
  });

  const {
    accounts,
    uniqueCategories,
    uniqueTransactionTypes,
    uniqueSubCategories,
    loadingAccounts,
    isOnlinePayment,
  } = useAccounts({
    lease,
    caller,
    amount,
    setAmount,
    state,
    setState,
    setStateErrors,
    initialStateErrors,
    isTenant,
  });

  const resetForm = (): void => {
    setPaymentMethodId('');
    setState(stateSetter(false, undefined));
  };

  const [createLedgerCall, loadingCreate] = useCallAction(createLedgerAction, {
    onCompleted() {
      resetForm();
      onClose();
    },
  });

  const [editLedgerCall, loadingEdit] = useCallAction(updateLedgerAction, {
    onCompleted() {
      resetForm();
      onClose();
    },
  });

  const [updateLease] = useCallAction(updateLeaseOpportunitiesAction);

  const setupStripeForm = (): void | null => {
    if (
      (!isDueOnSign &&
        (Number.isNaN(debouncedAmount) || debouncedAmount < 1)) ||
      (isDueOnSign && ledgerAmount < 1) ||
      !state.subCategory
    ) {
      setStateErrors((prevErrors) => ({ ...prevErrors, amount: true }));
      return null;
    } else {
      setLocalStoreLedgerFormState(JSON.stringify(state));
      // We multiply amount by 100 because Stripe measures it in cents
      const intentAmount = isDueOnSign
        ? ledgerAmount * 100
        : debouncedAmount * 100;

      if (
        state.paymentType === LedgerPaymentTypes.ELECTRONIC_PAYMENT &&
        state.subCategory !== 'BITCOIN'
      ) {
        createPaymentIntent(
          user,
          intentAmount,
          lease,
          paymentMethodId,
          state.subCategory,
        );
        setLoadingPaymentIntent(true);
      }
    }
  };

  useEffect(() => {
    setupStripeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAmount, paymentMethodId]);

  const handleLedgerCreationAfterStripeSuccess = (
    stripeObject,
    paymentIntentResponse,
    confirmPaymentResponse,
    type: 'card' | 'bankAccount',
  ): void => {
    if (confirmPaymentResponse?.error) {
      alert({
        success: false,
        error: true,
        message:
          'Failed to make the payment with this payment method. Please try again.',
      });
    } else {
      stripeObject
        .retrievePaymentIntent(paymentIntentResponse.client_secret)
        .then(async ({ paymentIntent: resultPaymentIntent }) => {
          if (!resultPaymentIntent || !lease.id) return;

          const paymentIntentFeeData = await getIntent(
            lease?.property?.stripeAccountId || '',
            paymentIntentResponse.id,
          );

          const intentAmount = parseFloat(
            ((paymentIntentFeeData?.amount || 0) / 100).toFixed(2),
          );

          // Multiply the created date by 1000 since we receive a unix_time from Stripe
          const effectiveDate = new Date(resultPaymentIntent.created * 1000);
          const effectiveDateString = effectiveDate.toISOString();
          switch (resultPaymentIntent.status) {
            case LedgerPaymentStatus.SUCCEEDED:
              alert({
                success: true,
                message:
                  'Payment succeeded. Stripe fees will be charged and detailed in a new ledger entry.',
              });
              // Create a Ledger for the ONLINE_CREDIT_CARD payment received
              // We know this because the payment status is succeeded
              await createLedgerCall(
                {
                  amount: intentAmount,
                  category: state.category,
                  subCategory: LedgerSubCategory.ONLINE_CREDIT_CARD,
                  effectiveDate: effectiveDateString,
                  type: isDueOnSign ? 'CREDIT' : state.type,
                  paymentType: state.paymentType,
                  notes: state.notes,
                  description: `${
                    lease?.leasePeriod?.name || ''
                  } - ${sanitizeTextToTitleCase(
                    LedgerSubCategory.ONLINE_CREDIT_CARD,
                  )}`,
                  paymentStatus: LedgerPaymentStatus.SUCCEEDED.toUpperCase(),
                  lease: { connect: { id: lease.id } },
                  leaseSubPeriod: isValidString(state.leaseSubPeriod)
                    ? { connect: { id: state.leaseSubPeriod } }
                    : undefined,
                  ledgerTransactionInformationRelation: {
                    create: {
                      ...state.ledgerTransactionInformationRelation,
                      stripePyId: resultPaymentIntent.id,
                    },
                  },
                } as LedgerCreateInput,
                lease.id || '',
              );

              if (caller === LedgerCreateUpdateDialogCallers.NEW_LEAD) {
                updateLease({
                  id: lease?.id,
                  status: LeaseStatus.PAID,
                  reservationPaid: true,
                });
              }
              break;
            case LedgerPaymentStatus.PROCESSING:
              alert({
                success: true,
                message:
                  'Payment is processing. Stripe fees will be charged and detailed in a new ledger entry.',
              });
              // Create a Ledger for the ONLINE_ACH_ECHECK payment received
              // We know this because the payment status is processing
              await createLedgerCall(
                {
                  amount: intentAmount,
                  category: state.category,
                  subCategory: LedgerSubCategory.ONLINE_ACH_ECHECK,
                  effectiveDate: effectiveDateString,
                  type: isDueOnSign ? 'CREDIT' : state.type,
                  paymentType: state.paymentType,
                  notes: state.notes,
                  description: `${
                    lease?.leasePeriod?.name || ''
                  } - ${sanitizeTextToTitleCase(
                    LedgerSubCategory.ONLINE_ACH_ECHECK,
                  )}`,
                  paymentStatus: LedgerPaymentStatus.PROCESSING.toUpperCase(),
                  lease: { connect: { id: lease.id } },
                  leaseSubPeriod: isValidString(state.leaseSubPeriod)
                    ? { connect: { id: state.leaseSubPeriod } }
                    : undefined,
                  ledgerTransactionInformationRelation: {
                    create: {
                      ...state.ledgerTransactionInformationRelation,
                      stripePyId: resultPaymentIntent.id,
                    },
                  },
                } as LedgerCreateInput,
                lease.id || '',
              );

              if (caller === LedgerCreateUpdateDialogCallers.NEW_LEAD) {
                updateLease({
                  id: lease?.id,
                  status: LeaseStatus.PAID,
                  reservationPaid: true,
                });
              }
              break;
            case LedgerPaymentStatus.REQUIRES_PAYMENT_METHOD:
              alert({
                error: true,
                message:
                  'Failed to make the payment. No charges made. Please try again.',
              });
              break;
            default:
              alert({
                error: true,
                message:
                  'Something went wrong. No charges made. Please try again.',
              });
              break;
          }
          onClose();
        });
    }
  };

  const handleSubmitCreate = async (): Promise<void> => {
    setStateErrors(initialStateErrors);
    let hasErrors = false;
    if (!isEditing) {
      if (!state.type) {
        setStateErrors((prevErrors) => ({ ...prevErrors, type: true }));
        hasErrors = true;
      }
      if (!state.category) {
        setStateErrors((prevErrors) => ({ ...prevErrors, category: true }));
        hasErrors = true;
      }
      if (!state.subCategory) {
        setStateErrors((prevErrors) => ({ ...prevErrors, subCategory: true }));
        hasErrors = true;
      }
      if (!state.paymentType) {
        setStateErrors((prevErrors) => ({ ...prevErrors, paymentType: true }));
        hasErrors = true;
      }
      if (
        !state.effectiveDate ||
        state.effectiveDate.toString() === 'Invalid Date'
      ) {
        setStateErrors((prevErrors) => ({
          ...prevErrors,
          effectiveDate: true,
        }));
        hasErrors = true;
      }
      if (
        Number.isNaN(Number(state.amount)) ||
        (state.amount === 0 && !ledgerAmount && isDueOnSign)
      ) {
        setStateErrors((prevErrors) => ({ ...prevErrors, amount: true }));
        hasErrors = true;
      }
    }
    if (hasErrors) {
      return;
    }

    if (setLoadingGrid) {
      setLoadingGrid(true);
    }

    if (stripe && paymentIntent && paymentMethodId) {
      setLoadingElectronicPaymentCreation(true);

      if (state.subCategory === LedgerSubCategory.ONLINE_CREDIT_CARD) {
        stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentIntent.connected_account_payment_method_id,
          })
          .then((confirmPaymentResponse) => {
            handleLedgerCreationAfterStripeSuccess(
              stripe,
              paymentIntent,
              confirmPaymentResponse,
              'card',
            );
          });
      }

      if (state.subCategory === LedgerSubCategory.ONLINE_ACH_ECHECK) {
        stripe
          .confirmUsBankAccountPayment(paymentIntent.client_secret, {
            payment_method: paymentIntent.connected_account_payment_method_id,
          })
          .then((confirmPaymentResponse) => {
            handleLedgerCreationAfterStripeSuccess(
              stripe,
              paymentIntent,
              confirmPaymentResponse,
              'bankAccount',
            );
          });
      }
    } else {
      if (isEditing) {
        editLedgerCall(
          {
            description: state.description,
            id,
          } as LedgerUpdateInput,
          lease?.id || '',
        );
        return;
      }

      const ledgerTotalAmount = isDueOnSign ? ledgerAmount : state.amount;

      createLedgerCall(
        {
          amount: ledgerTotalAmount,
          category: state.category,
          subCategory: state.subCategory,
          effectiveDate: state.effectiveDate?.toISOString() ?? '',
          type: isDueOnSign ? 'CREDIT' : state.type,
          paymentType: state.paymentType,
          paymentStatus: LedgerPaymentStatus.SUCCEEDED.toUpperCase(),
          notes: state.notes,
          description: state.description,
          lease: { connect: { id: lease?.id } },
          leaseSubPeriod: isValidString(state.leaseSubPeriod)
            ? { connect: { id: state.leaseSubPeriod } }
            : undefined,
          ledgerTransactionInformationRelation: {
            create: { ...state.ledgerTransactionInformationRelation },
          },
        } as LedgerCreateInput,
        lease?.id || '',
      );
      if (caller === LedgerCreateUpdateDialogCallers.NEW_LEAD) {
        updateLease({
          id: lease?.id,
          status: LeaseStatus.PAID,
          reservationPaid: true,
        });
      }
      if (
        caller !== LedgerCreateUpdateDialogCallers.NEW_LEAD &&
        lease.status !== LeaseStatus.APPROVED
      ) {
        updateLease({
          id: lease?.id,
          status: LeaseStatus.PAYMENT,
        });
      }
    }
  };

  const renderManualForm = (): ReactElement => {
    return (
      <>
        {/*  <Grid item xs={12}>
          <KeyboardDatePicker
            clearable
            fullWidth
            disablePast
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Effective Date"
            format="MM-dd-yyyy"
            name="effectiveDate"
            onChange={(_, value) => {
              setStateErrors(initialStateErrors);
              setState((prevProps) => ({
                ...prevProps,
                effectiveDate: new Date(`${value}`),
              }));
            }}
            value={state.effectiveDate}
            InputAdornmentProps={{
              style: {
                borderLeft: `1px solid ${MUTED_COLOR}`,
                height: 'auto',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={stateErrors.effectiveDate}
            helperText={stateErrors.effectiveDate && 'This field is required'}
            disabled={isEditing}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            error={stateErrors.amount}
            helperText={stateErrors.amount && 'The amount has to be at least 1'}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            label="AMOUNT"
            value={isDueOnSign ? ledgerAmount : state.amount}
            onChange={(event) => {
              setStateErrors(initialStateErrors);
              if (Number(event.target.value) < 1) {
                setState((prevProps) => ({
                  ...prevProps,
                  amount: 1,
                  paymentType: prevProps.paymentType,
                }));
              } else {
                setState((prevProps) => ({
                  ...prevProps,
                  amount: parseInt(event.target.value, 10),
                  paymentType: prevProps.paymentType,
                }));
              }
            }}
            disabled={isEditing || isDueOnSign}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            label="DESCRIPTION"
            value={state.description}
            onChange={(event) => {
              setStateErrors(initialStateErrors);
              setState((prevProps) => ({
                ...prevProps,
                description: event.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            label="NOTES (PRIVATE)"
            value={state.notes}
            onChange={(event) => {
              setStateErrors(initialStateErrors);
              setState((prevProps) => ({
                ...prevProps,
                notes: event.target.value,
              }));
            }}
            disabled={isEditing}
          />
        </Grid>
      </>
    );
  };

  const onChangeAutoAmount = (event): void => {
    setStateErrors(initialStateErrors);
    if (Number(event.target.value) < 1) {
      setState((prevProps) => ({
        ...prevProps,
        amount: 1,
        paymentType: prevProps.paymentType,
      }));
    } else {
      if (Number(event.target.value) > 999999) {
        setState((prevProps) => {
          return {
            ...prevProps,
            amount: 999999,
            paymentType: prevProps.paymentType,
          };
        });
      } else {
        setState((prevProps) => ({
          ...prevProps,
          amount: parseInt(event.target.value, 10),
          paymentType: prevProps.paymentType,
        }));
      }
    }
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        resetForm();
        onClose();
      }}
      maxWidth="md"
      className={matches ? classes.dialog : ''}>
      <DialogTitle>
        {isEditing
          ? 'Edit Entry'
          : caller === LedgerCreateUpdateDialogCallers.NEW_LEAD
          ? 'Make a Payment'
          : 'Add Entry'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              clearable
              fullWidth
              disablePast
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="DUE DATE"
              format="MM-dd-yyyy"
              name="effectiveDate"
              onChange={(_, value) => {
                setStateErrors(initialStateErrors);
                setState((prevProps) => ({
                  ...prevProps,
                  effectiveDate: new Date(`${value}`),
                }));
              }}
              value={
                state.category === 'PAYMENT' ? new Date() : state.effectiveDate
              }
              InputAdornmentProps={{
                style: {
                  borderLeft: `1px solid ${MUTED_COLOR}`,
                  height: 'auto',
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={stateErrors.effectiveDate}
              helperText={stateErrors.effectiveDate && 'This field is required'}
              disabled={isEditing || state.category === 'PAYMENT'}
            />
          </Grid>
          <LedgerTransactionInformationForm
            isEditing={isEditing}
            subCategory={state.subCategory}
            ledgerTransactionInformationRelation={
              state.ledgerTransactionInformationRelation
            }
            stateErrors={stateErrors}
            setState={setState}
          />
          <AccountSelector
            accounts={accounts}
            uniqueCategories={uniqueCategories}
            uniqueTransactionTypes={uniqueTransactionTypes}
            uniqueSubCategories={uniqueSubCategories}
            stateErrors={stateErrors}
            initialStateErrors={initialStateErrors}
            setStateErrors={setStateErrors}
            state={state}
            setState={setState}
            caller={caller}
            lease={lease}
            isEditing={isEditing}
            loadingAccounts={loadingAccounts}
            paymentIntent={paymentIntent}
            isTenant={isTenant}
            allowChecks={user?.allowChecks}
            allowCreditCards={user?.allowCreditCards}></AccountSelector>

          {state.subCategory && !isOnlinePayment() && renderManualForm()}

          {lease.property && state.subCategory && isOnlinePayment() && (
            <PaymentMethodSelector
              onAddPaymentMethod={onAddPaymentMethod}
              setPaymentMethodId={setPaymentMethodId}
              paymentMethodId={paymentMethodId}
              user={user}
              isTenant={isTenant}
              property={lease.property}
              subCategory={state.subCategory}
            />
          )}
          {paymentMethodId && (
            <Grid item xs={12}>
              <TextField
                error={stateErrors.amount}
                helperText={
                  stateErrors.amount &&
                  'The amount has to be at least 1 and less than 1,000,000'
                }
                variant="outlined"
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                label="AMOUNT"
                value={isDueOnSign ? ledgerAmount : state.amount}
                onChange={(event) => {
                  onChangeAutoAmount(event);
                }}
                disabled={isEditing || isDueOnSign}
              />
            </Grid>
          )}
          {loadingPaymentIntent && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={2}
              style={{
                width: '100%',
              }}>
              <CircularProgress size="5rem" title="loading.." />
              <Typography color="primary">Loading...</Typography>
            </Box>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}>
          Cancel
        </Button>
        <ButtonAction
          fullWidth={false}
          onClick={() => handleSubmitCreate()}
          disabled={
            loadingPaymentIntent ||
            (state.paymentType === LedgerPaymentTypes.ELECTRONIC_PAYMENT &&
              !paymentMethodId)
          }
          isLoading={
            loadingElectronicPaymentCreation || loadingCreate || loadingEdit
          }>
          {isEditing ? 'Edit' : 'Create'}
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
