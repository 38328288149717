import { useContext, useEffect, useMemo, useState } from 'react';
import { useCallAction, useFetchAction } from '@8baselabs/react-simple-state';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { PageTitle } from '../../../shared/components/ui/texts/CommonTexts';
import {
  createSetupIntentAction,
  listBanksPaymentMethods,
  listCardsPaymentMethods,
} from '../../user/user-actions';
import { MainLoader } from '../../../shared/components/MainLoader';
import { PaymentMethodRestrictions } from '../components/PaymentMethodRestrictions';
import { PaymentMethodList } from '../components/PaymentMethodList';
import { Dialog } from '../../../shared/components/Dialog';
import { AlertContext } from '../../../routes/AlertContext';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
} from '@material-ui/core';
import {
  PaymentMethodCreateInput,
  User,
  Property,
  Lease,
} from '../../../schema-types';
import { Lease as LeaseType } from '@8baselabs/resident-io-shared';
import { createPaymentMethodAction } from '../people-actions';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../../shared/constants';
import React from 'react';
import { Grid, TextField } from '@mui/material';
import { paymentMethodSelected } from '../../reservation/reservation-events';
import { PaymentMethodEnum } from '../../reservation/reservation-types';

type PaymentMethodProps = {
  isTenant?: boolean;
  isReservation?: boolean;
  selectedLease?: Lease;
  fromTab0?: boolean;
  refetchLease?: () => void;
  paymentMethodId?: string;
  setPaymentMethodId?: (type: string, paymentMethodId: string) => void;
};

const PaymentMethodViewRender: React.FC<PaymentMethodProps> = ({
  isTenant = false,
  isReservation = false,
  selectedLease,
  fromTab0 = false,
  paymentMethodId,
  refetchLease,
  setPaymentMethodId,
}) => {
  const [open, setOpen] = useState(fromTab0);
  const [loadingSetupIntent, setLoadingSetupIntent] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [setupIntent, setSetupIntent] = useState<{
    client_secret: string;
  } | null>(null);
  const alert = useContext(AlertContext);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [settingPaymentMethod, setSettingPaymentMethod] = useState(false);
  const [method, setMethod] = React.useState<PaymentMethodEnum>(
    PaymentMethodEnum.ELECTRONIC,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value);
    setMethod((event.target as HTMLInputElement).value as PaymentMethodEnum);
    paymentMethodSelected.dispatch({
      type: event.target.value as PaymentMethodEnum,
    });
  };

  const user = useMemo(() => {
    return selectedLease?.userResident as User;
  }, [selectedLease]);
  const property = selectedLease?.property as Property;
  const [type, setType] = useState<'card' | 'us_bank_account' | undefined>(
    'card',
  );

  useEffect(() => {
    if (!user) return;
    setType(
      user?.allowCreditCards
        ? 'card'
        : user?.allowChecks
        ? 'us_bank_account'
        : undefined,
    );
  }, [user]);
  /* 
  useSubscription(OnUpdateAllowCreditCards, () => {
    refetchAllowCredit?.();
  });
  useSubscription(OnGetAllowBankAccounts, () => {
    refetchAllowChecks?.();
  }); */

  const [
    cardPaymentMethods,
    loadingCardPaymentMethods,
    { refetch: refetchCards },
  ] = useFetchAction(listCardsPaymentMethods, [user, property], {
    skip: !user || !property,
  });
  const [
    bankPaymentMethods,
    loadingBankPaymentMethods,
    { refetch: refetchBanks },
  ] = useFetchAction(listBanksPaymentMethods, [user, property], {
    skip: !user || !property,
  });

  const [createSetupIntent, creatingSetupIntent] = useCallAction(
    createSetupIntentAction,
    {
      onCompleted: (result) => {
        setSetupIntent(result);
        setLoadingSetupIntent(false);
      },
      onError: (err) => {
        alert({
          error: true,
          message: 'Failed to create setup intent',
        });
      },
    },
  );

  const handleOpen = (): void => {
    setOpen(true);

    if (user && property && type) {
      createSetupIntent(user, property, type);
      setLoadingSetupIntent(true);
    }
  };

  const addCard = async (): Promise<void> => {
    if (!stripe || !elements || !setupIntent) {
      alert({
        error: true,
        message: 'Failed to add payment method',
      });
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      alert({
        error: true,
        message: 'Failed to add payment method',
      });
      return;
    }

    try {
      stripe
        .confirmCardSetup(setupIntent.client_secret, {
          payment_method: {
            card: cardElement,
          },
        })
        .then((result) => {
          if (result.error) {
            alert({
              success: false,
              error: true,
              message: 'Failed to add payment method',
            });
          }
          if (result.setupIntent?.payment_method) {
            const cardPaymentMethodData: PaymentMethodCreateInput = {
              stripeId: result.setupIntent?.payment_method as string,
              isVerified: true,
              user: {
                connect: {
                  id: user?.id,
                },
              },
            };
            setSettingPaymentMethod(true);
            createPaymentMethodAction(user, cardPaymentMethodData).then(
              (success) => {
                if (success === false) {
                  alert({
                    error: true,
                    message: 'This payment method has already been added.',
                  });
                  setSettingPaymentMethod(false);
                  setOpen(false);
                  return;
                }
                alert({
                  success: true,
                  message: 'Payment method added successfully',
                });

                refetchCards();
                refetchBanks();
                setSettingPaymentMethod(false);
              },
            );
          }
        });
    } catch (error) {
      alert({
        success: false,
        error: true,
        message: 'Failed to add payment method',
      });
    } finally {
      setOpen(false);
      setSetupIntent(null);
      setLoading(false);
    }
  };

  const addBank = (): void => {
    if (!stripe || !elements || !setupIntent) {
      alert({
        error: true,
        message: 'Failed to add payment method',
      });
      return;
    }

    try {
      stripe
        .collectBankAccountForSetup({
          clientSecret: setupIntent.client_secret,
          params: {
            payment_method_type: 'us_bank_account',
            payment_method_data: {
              billing_details: {
                name: `${user?.firstName} ${user?.lastName}`,
                email: `${user?.email}`,
              },
            },
          },
          expand: ['payment_method'],
        })
        .then(function (result) {
          if (result.error) {
            alert({
              success: false,
              error: true,
              message: 'Failed to add payment method',
            });
          } else {
            if (result.setupIntent?.payment_method) {
              setLoadingSetupIntent(true);
              stripe
                .confirmUsBankAccountSetup(setupIntent.client_secret, {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  payment_method: (result.setupIntent.payment_method as any)
                    .id as string,
                })
                .then(({ setupIntent: setupIntentBankAccount, error }) => {
                  setLoadingSetupIntent(true);
                  if (error) {
                    setErrorText(
                      `An error occurred trying to create the bank account: ${error}`,
                    );
                  } else if (
                    setupIntentBankAccount.status === 'requires_payment_method'
                  ) {
                    setHelperText(
                      `Confirmation failed. Attempt again with a different payment method.`,
                    );
                  } else if (setupIntentBankAccount.status === 'succeeded') {
                    const bankPaymentMethodData: PaymentMethodCreateInput = {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      stripeId: (result.setupIntent?.payment_method as any)
                        .id as string,
                      isVerified: true,
                      user: {
                        connect: {
                          id: user?.id,
                        },
                      },
                    };
                    setSettingPaymentMethod(true);
                    createPaymentMethodAction(user, bankPaymentMethodData).then(
                      (success) => {
                        if (success === false) {
                          alert({
                            error: true,
                            message:
                              'This payment method has already been added.',
                          });
                          setSettingPaymentMethod(false);
                          setOpen(false);
                          return;
                        }
                        alert({
                          success: true,
                          message: 'Payment method added successfully',
                        });

                        refetchCards();
                        refetchBanks();
                        setSettingPaymentMethod(false);
                        setOpen(false);
                      },
                    );
                  } else if (
                    setupIntentBankAccount.next_action?.type ===
                    'verify_with_microdeposits'
                  ) {
                    // TODO: Test this flow to see what url we receive and then save it in the
                    // payment method.
                    const bankPaymentMethodData: PaymentMethodCreateInput = {
                      stripeId: result.setupIntent?.payment_method as string,
                      isVerified: false,
                      verificationUrl:
                        setupIntentBankAccount.next_action?.redirect_to_url
                          ?.url || '',
                      user: {
                        connect: {
                          id: user?.id,
                        },
                      },
                    };
                    setHelperText(
                      'You need to verify this payment method before using it.',
                    );
                    createPaymentMethodAction(user, bankPaymentMethodData).then(
                      (success) => {
                        if (success === false) {
                          alert({
                            error: true,
                            message:
                              'This payment method has already been added.',
                          });
                          setSettingPaymentMethod(false);
                          setOpen(false);
                          return;
                        }
                        alert({
                          success: true,
                          message: 'Payment method added successfully',
                        });

                        refetchCards();
                        refetchBanks();
                        setOpen(false);
                      },
                    );
                  }
                });
            }
          }
        });
    } catch (error) {
      alert({
        success: false,
        error: true,
        message: 'Failed to add payment method',
      });
      setOpen(false);
    } finally {
      setSetupIntent(null);
      setLoading(false);
    }
  };

  const handleSubmit = async (e): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type === 'card' ? addCard() : addBank();
  };

  const handleTypeChange = (event: { target: { value: string } }): void => {
    const newType = event.target.value;
    setType(newType as 'card' | 'us_bank_account' | undefined);
    createSetupIntent(user, property, newType);
  };

  if (
    loadingCardPaymentMethods ||
    loadingBankPaymentMethods ||
    settingPaymentMethod
  ) {
    return (
      <Grid xs={12} height="300px" display="flex" justifyContent={'center'}>
        <MainLoader size="small" />
      </Grid>
    );
  }

  return (
    <Box padding={isTenant ? '0px' : '40px'}>
      {!isTenant && <PageTitle variant="h5">Payment methods</PageTitle>}
      {user && isReservation && (
        <FormControl fullWidth margin="dense">
          <RadioGroup
            aria-labelledby="controlled-payment-method"
            value={method}
            onChange={(e) => handleChange(e)}>
            <FormControlLabel
              value={PaymentMethodEnum.ELECTRONIC}
              control={
                <Radio
                  defaultChecked={
                    (selectedLease as Lease)?.paymentType === PaymentMethodEnum.ELECTRONIC
                  }
                />
              }
              label="Electronic Payment"
            />
            {method === PaymentMethodEnum.ELECTRONIC && (
              <>
                <PaymentMethodList
                  padding="5px"
                  selectedLease={selectedLease as Lease}
                  loadingCardPaymentMethods={loadingCardPaymentMethods}
                  cardPaymentMethods={
                    !user?.allowCreditCards ? [] : cardPaymentMethods
                  }
                  loadingBankPaymentMethods={loadingBankPaymentMethods}
                  bankPaymentMethods={
                    !user?.allowChecks ? [] : bankPaymentMethods
                  }
                  refetchCards={refetchCards}
                  refetchBanks={refetchBanks}
                  isReservation={isReservation}
                  paymentMethodId={paymentMethodId}
                  setPaymentMethodId={setPaymentMethodId}
                />
                <Box
                  marginTop={'15px'}
                  display="flex"
                  justifyContent={'center'}>
                  <Button
                    onClick={handleOpen}
                    color={'primary'}
                    variant={'contained'}>
                    ADD PAYMENT METHOD
                  </Button>
                </Box>
              </>
            )}
            <FormControlLabel
              value={PaymentMethodEnum.MANUAL}
              style={{ marginTop: '1rem' }}
              control={
                <Radio
                  defaultChecked={
                    selectedLease?.paymentType === PaymentMethodEnum.MANUAL
                  }
                />
              }
              label="Manual Payment"
            />
            {method === PaymentMethodEnum.MANUAL && (
              <>
                <Typography variant="body2">
                  This property can receive your check at the following address:
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  label="OFFLINE PAYMENT ADDRESS"
                  value={property.siteSetting?.offlinePaymentsAddress}
                />
              </>
            )}
          </RadioGroup>
        </FormControl>
      )}
      {user && !isReservation && (
        <>
          <PaymentMethodRestrictions
            user={user}
            type={type}
            isTenant={isTenant}
            handleOpen={handleOpen}
            refetchLease={refetchLease}
          />
          <PaymentMethodList
            selectedLease={selectedLease as Lease}
            loadingCardPaymentMethods={loadingCardPaymentMethods}
            cardPaymentMethods={
              !user?.allowCreditCards ? [] : cardPaymentMethods
            }
            loadingBankPaymentMethods={loadingBankPaymentMethods}
            bankPaymentMethods={!user?.allowChecks ? [] : bankPaymentMethods}
            refetchCards={refetchCards}
            refetchBanks={refetchBanks}
            isReservation={isReservation}
            paymentMethodId={paymentMethodId}
            setPaymentMethodId={setPaymentMethodId}
          />
        </>
      )}
      <Dialog
        title="Add Payment Method"
        size="md"
        isVisible={open}
        onClose={() => {
          setOpen(false);
        }}>
        <Box sx={{ minWidth: { xs: '250px', md: '300px' } }}>
          {loadingSetupIntent ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center">
              <CircularProgress size="5rem" title="loading.." />
              <Typography color="primary">Loading...</Typography>
            </Box>
          ) : (
            <form>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  SELECT THE TYPE OF METHOD TO BE ADDED
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={type}
                  onChange={handleTypeChange}
                  row>
                  <FormControlLabel
                    value="card"
                    disabled={!user?.allowCreditCards}
                    control={<Radio />}
                    label="Card"
                  />
                  <FormControlLabel
                    value="us_bank_account"
                    disabled={!user?.allowChecks}
                    control={<Radio />}
                    label="Bank Account"
                  />
                </RadioGroup>
              </FormControl>
              {type === 'card' && (
                <>
                  <Box
                    border="1px solid #00000042"
                    borderRadius="4px"
                    padding="18.5px 14px">
                    <CardElement />
                  </Box>
                  <Box display="flex" justifyContent="end" marginTop="20px">
                    <ButtonAction
                      fullWidth={false}
                      disabled={loading || creatingSetupIntent}
                      isLoading={loading || creatingSetupIntent}
                      size="large"
                      type="submit"
                      onClick={handleSubmit}>
                      ADD CARD
                    </ButtonAction>
                  </Box>
                </>
              )}

              {type === 'us_bank_account' && (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="end"
                    marginTop="20px">
                    {helperText && <Typography>{helperText}</Typography>}
                    {errorText ? (
                      <Typography color={'error'}>{errorText}</Typography>
                    ) : (
                      <>
                        <Typography>
                          You will be redirected to Stripe
                        </Typography>
                        <ButtonAction
                          fullWidth={false}
                          disabled={loading || creatingSetupIntent}
                          isLoading={loading || creatingSetupIntent}
                          size="large"
                          type="submit"
                          style={{ marginTop: '1rem' }}
                          onClick={handleSubmit}>
                          ADD BANK ACCOUNT
                        </ButtonAction>
                      </>
                    )}
                  </Box>
                </>
              )}
            </form>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

type PaymentMethodViewProps = {
  type: 'tenant' | 'reservation' | 'people';
  selectedLease?: Lease;
  fromTab0?: boolean;
  paymentMethodId?: string;
  refetchLease?: () => void;
  setPaymentMethodId?: (type: string, paymentMethodId: string) => void;
};
export const PaymentMethodView: React.FC<PaymentMethodViewProps> = ({
  type,
  selectedLease,
  fromTab0,
  refetchLease,
  paymentMethodId,
  setPaymentMethodId,
}) => {
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);

  useEffect(() => {
    if (selectedLease?.property?.stripeAccountId) {
      loadStripe(STRIPE_PUBLIC_KEY || '').then((stripe) => {
        setStripeObject(stripe);
      });
    }
  }, [selectedLease?.property?.stripeAccountId]);

  if (!selectedLease) {
    return (
      <Box padding="20px">
        <Typography variant="h6">
          Please select a lease before adding payment methods.
        </Typography>
      </Box>
    );
  }

  if (!selectedLease?.property?.stripeAccountId) {
    return (
      <Box padding="20px">
        <Typography variant="h6">
          This property has no Stripe account configured yet.
        </Typography>
      </Box>
    );
  }

  return (
    <Elements stripe={stripeObject}>
      <PaymentMethodViewRender
        fromTab0={fromTab0}
        isTenant={type === 'tenant'}
        refetchLease={refetchLease}
        isReservation={type === 'reservation'}
        selectedLease={selectedLease}
        paymentMethodId={paymentMethodId}
        setPaymentMethodId={setPaymentMethodId}
      />
    </Elements>
  );
};
