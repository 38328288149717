// Accounts handling hook
import { useEvent, useFetchAction } from '@8baselabs/react-simple-state';
import {
  Account,
  UserType,
  Lease,
  LedgerCreateUpdateDialogCallers,
  LedgerPaymentCategories,
  LedgerSubCategory,
  EntryType,
} from '@8baselabs/resident-io-shared';
import { useEffect, useState } from 'react';
import { getAccountsAction } from '../../../owner-settings/owner-settings-actions';
import { useSession } from '../../../session/session-hooks';
import { OnChangeClientSelected } from '../../../settings-view/settings-events';
import { ErrorStateType, LedgerState } from './LedgerCreateUpdateDialog';

interface IUseAccountsHookProps {
  caller: LedgerCreateUpdateDialogCallers;
  lease: Lease;
  amount: number;
  setAmount: React.Dispatch<React.SetStateAction<number>>;
  state: LedgerState;
  setState: React.Dispatch<React.SetStateAction<LedgerState>>;
  setStateErrors: React.Dispatch<React.SetStateAction<ErrorStateType>>;
  initialStateErrors: ErrorStateType;
  isTenant: boolean;
}

type UseAccountHookReturnType = {
  accounts: Account[];
  uniqueCategories: (string | undefined)[];
  uniqueTransactionTypes: (string | undefined)[];
  uniqueSubCategories: (string | undefined)[];
  loadingAccounts: boolean;
  isOnlinePayment: () => boolean;
};

export const useAccounts = ({
  lease,
  caller,
  amount,
  setAmount,
  state,
  setState,
  setStateErrors,
  initialStateErrors,
  isTenant,
}: IUseAccountsHookProps): UseAccountHookReturnType => {
  const [session] = useSession();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [uniqueCategories, setUniqueCategories] = useState<
    (string | undefined)[]
  >([]);
  const [uniqueTransactionTypes, setUniqueTransactionTypes] = useState<
    (string | undefined)[]
  >([]);
  const [uniqueSubCategories, setUniqueSubCategories] = useState<
    (string | undefined)[]
  >([]);

  const selectedClientId = useEvent(OnChangeClientSelected);
  const clientId =
    session?.user.userType === UserType.ADMIN
      ? selectedClientId
      : lease?.property?.client?.id;

  const [accountsResponse] = useFetchAction(getAccountsAction, [clientId], {
    skip: !clientId,
    onError: (error) => {
      setLoadingAccounts(false);
    },
  });

  useEffect(() => {
    if (accountsResponse) {
      setAccounts(accountsResponse.items?.map((item) => item));
      setLoadingAccounts(false);
    }
  }, [accountsResponse]);

  useEffect(() => {
    if (!loadingAccounts && accounts && accounts.length) {
      const categories = accounts.map((account) => {
        return account.accountCategory;
      });
      setUniqueCategories(
        Array.from([...new Set(categories.map((item) => item?.name))]),
      );
    }

    if (caller === LedgerCreateUpdateDialogCallers.NEW_LEAD) {
      const selectedType = accounts?.find(
        (account) =>
          account?.accountCategory?.name === LedgerPaymentCategories.PAYMENT,
      )?.defaultSign;
      setStateErrors(initialStateErrors);
      setState((prevProps) => ({
        ...prevProps,
        type: selectedType || '',
        category: LedgerPaymentCategories.PAYMENT,
        paymentType: '',
        amount: Number(amount),
      }));

      setAmount(Number(amount));
    }

    if (isTenant) {
      setState((prevProps) => ({
        ...prevProps,
        category: LedgerPaymentCategories.PAYMENT,
        type: EntryType.CREDIT,
      }));
    }
  }, [
    loadingAccounts,
    accounts,
    caller,
    amount,
    initialStateErrors,
    setState,
    setStateErrors,
    setAmount,
    isTenant,
  ]);

  useEffect(() => {
    if (state.category && accounts && accounts.length) {
      const transactionTypes = accounts
        .filter((account) => account.accountCategory?.name === state.category)
        .map((account) => {
          return account.accountType;
        });
      setUniqueTransactionTypes(
        Array.from([...new Set(transactionTypes.map((item) => item?.name))]),
      );
    }
  }, [state.category, accounts]);

  useEffect(() => {
    if (state.paymentType && accounts && accounts.length) {
      const subCategories = accounts
        .filter(
          (account) =>
            account.accountCategory?.name === state.category &&
            account.accountType?.name === state.paymentType &&
            account.isActive,
        )
        .map((account) => {
          return account.accountSubCategory;
        });
      setUniqueSubCategories(
        Array.from([...new Set(subCategories.map((item) => item?.name))]),
      );
    }
  }, [state.paymentType, state.category, accounts]);

  const isOnlinePayment = (): boolean => {
    return (
      state.subCategory === LedgerSubCategory.ONLINE_ACH_ECHECK ||
      state.subCategory === LedgerSubCategory.ONLINE_CREDIT_CARD
    );
  };

  return {
    accounts,
    uniqueCategories,
    uniqueTransactionTypes,
    uniqueSubCategories,
    loadingAccounts,
    isOnlinePayment,
  };
};
