import React from 'react';
import { Lease } from '@8baselabs/resident-io-shared';
import { useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { ActivityFeed } from '../../../shared/components/ui/ActivityFeed';

type ActivityViewProps = { selectedLease?: Lease };

export const ActivityViewContent: React.FC<ActivityViewProps> = ({
  selectedLease,
}) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <ActivityFeed selectedLease={selectedLease} userId={id} />
    </>
  );
};
export const ActivityView: React.FC<ActivityViewProps> = ({
  selectedLease,
}) => (
  <Box p="40px" height="1000px">
    <ActivityViewContent selectedLease={selectedLease} />
  </Box>
);
