import { createEvent } from '@8baselabs/react-simple-state';
import {
  ContractDocument,
  CreateSignatureRequest,
  InvitationResident,
  Lease,
  LeaseListResponse,
  MoveOutRequest,
  PaymentPlanListResponse,
  Unit,
  UnitListResponse,
  User,
} from '../../schema-types';
import { PaymentMethod, PaymentMethodEnum } from './reservation-types';

export const paymentMethodSelected = createEvent<PaymentMethod>({
  initialValue: {
    type: PaymentMethodEnum.ELECTRONIC,
  },
});

export const onCreateDocumentsEvent = createEvent<boolean>();
export const onCreateDocumentsEventError = createEvent<Error>();

export const fetchLease = createEvent<Lease>();
export const fetchLeaseError = createEvent<Error>();

export const fetchPaymentPlansByLeasePeriodID =
  createEvent<PaymentPlanListResponse>();
export const fetchPaymentPlansByLeasePeriodIDError = createEvent<Error>();

export const fetchLeases = createEvent<LeaseListResponse>();
export const fetchLeasesError = createEvent<Error>();

export const onUpdateLease = createEvent<Lease>();
export const onUpdateLeaseError = createEvent<Error>();

export const onUpdateLeaseAction = createEvent<Lease>();
export const onUpdateLeaseActionError = createEvent<Error>();

export const onCreateMoveOutRequest = createEvent<MoveOutRequest>();
export const onCreateMoveOutRequestError = createEvent<Error>();

export const onGetUnitList = createEvent<UnitListResponse>();
export const onGetUnitListError = createEvent<Error>();

export const onGetUnitsList = createEvent<UnitListResponse>();
export const onGetUnitsListError = createEvent<Error>();

export const onCreateLeaseContractDocumentEvent =
  createEvent<ContractDocument>();
export const onCreateLeaseContractDocumentError = createEvent<Error>();

export const onGetLeaseContractDocumentEvent =
  createEvent<readonly ContractDocument[]>();
export const onGetLeaseContractDocumentError = createEvent<Error>();

export const OnGetResidentContract =
  createEvent<CreateSignatureRequest | null>();
export const OnGetResidentContractError = createEvent<Error>();

export const OnGetResidentTyA = createEvent<CreateSignatureRequest | null>();
export const OnGetResidentTyAtError = createEvent<Error>();

export const onSendFriendNotification = createEvent<void>();
export const onSendFriendNotificationError = createEvent<Error>();

export const onUpdateUnit = createEvent<Unit>();
export const onUpdateUnitError = createEvent<Error>();

export const onCreateUser = createEvent<User>();
export const onCreateUserError = createEvent<Error>();

export const onCreateInvitation = createEvent<InvitationResident>();
export const onCreateInvitationError = createEvent<Error>();

export const onFetchReservationFeeSetting = createEvent<boolean>();
export const onFetchReservationFeeSettingError = createEvent<Error>();

export const onFetchReservationFee = createEvent<number>();
export const onFetchReservationFeeError = createEvent<Error>();

export const hasManuallyPaid = createEvent<boolean>({
  initialValue: false,
});
