import { useCallAction } from '@8baselabs/react-simple-state';
import {
  Lease,
  PaymentMethod,
  PaymentMethodFilter,
  PaymentMethodUpdateByFilterInput,
} from '@8baselabs/resident-io-shared';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { useHistory } from 'react-router';
import { BankAccountPaymentMethodRender } from '../../../shared/components/ui/BankAccountPaymentMethodRender';
import { CardPaymentMethodRender } from '../../../shared/components/ui/CardPaymentMethodRender';
import {
  deletePaymentMethodByFilterAction,
  updatePaymentMethodByFilterAction,
  updatePaymentMethodsByFilterAction,
} from '../people-actions';
import { BankPaymentMethod, CardPaymentMethod } from '../people-types';

export const PaymentMethodList: React.FC<{
  selectedLease: Lease;
  padding?: string;
  loadingCardPaymentMethods: boolean;
  cardPaymentMethods: CardPaymentMethod[];
  loadingBankPaymentMethods: boolean;
  bankPaymentMethods: BankPaymentMethod[];
  refetchCards: () => void;
  refetchBanks: () => void;
  isReservation?: boolean;
  paymentMethodId?: string;
  setPaymentMethodId?: (type: string, paymentMethodId: string) => void;
}> = ({
  selectedLease,
  padding = '40px',
  loadingCardPaymentMethods,
  cardPaymentMethods,
  loadingBankPaymentMethods,
  bankPaymentMethods,
  refetchCards,
  refetchBanks,
  isReservation = false,
  paymentMethodId,
  setPaymentMethodId,
}) => {
  // const history = useHistory();
  const [updatePaymentMethodByFilter] = useCallAction(
    updatePaymentMethodByFilterAction,
  );
  const [updatePaymentMethodsByFilter] = useCallAction(
    updatePaymentMethodsByFilterAction,
    {
      onCompleted: () => {
        refetchCards();
        refetchBanks();
      },
    },
  );

  const [deletePaymentMethodByFilter] = useCallAction(
    deletePaymentMethodByFilterAction,
    {
      onCompleted: () => {
        refetchCards();
        refetchBanks();
      },
    },
  );

  // const handleVerify = (paymentMethod: PaymentMethod): void => {
  //   history.push(paymentMethod.verificationUrl);
  // };

  const handleSetPrimary = (paymentMethod: PaymentMethod): void => {
    const primaryPaymentMethod: PaymentMethodUpdateByFilterInput = {
      isPrimary: [
        {
          set: true,
        },
      ],
    };
    const filter: PaymentMethodFilter = {
      stripeId: {
        equals: paymentMethod.id,
      },
    };

    updatePaymentMethodByFilter(primaryPaymentMethod, filter);

    const secondaryPaymentMethod: PaymentMethodUpdateByFilterInput = {
      isPrimary: [
        {
          set: false,
        },
      ],
    };
    const secondaryPaymentMethodIds: string[] = bankPaymentMethods
      .map((bpm) => {
        return bpm.stripeId !== paymentMethod.id ? bpm.stripeId || '' : '';
      })
      .concat(
        cardPaymentMethods.map((cpm) => {
          return cpm.stripeId !== paymentMethod.id ? cpm.stripeId || '' : '';
        }),
      );
    updatePaymentMethodsByFilter(
      secondaryPaymentMethod,
      secondaryPaymentMethodIds,
    );
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleRemove = (e: any, paymentMethod: PaymentMethod): void => {
    e.stopPropagation();
    const filter: PaymentMethodFilter = {
      stripeId: {
        equals: paymentMethod.id,
      },
    };
    deletePaymentMethodByFilter(filter);
  };

  return (
    <>
      <Box paddingTop={padding}>
        <Grid container spacing={2}>
          {!loadingCardPaymentMethods &&
            cardPaymentMethods?.length > 0 &&
            cardPaymentMethods?.map((cardPaymentMethod) => (
              <Grid item md={6} key={cardPaymentMethod.id}>
                <CardPaymentMethodRender
                  disabled={
                    isReservation
                      ? !selectedLease.property?.siteSetting
                          ?.enableTenantPortalCreditCardPayment ||
                        !selectedLease.property?.siteSetting
                          ?.enableOnlineLeasingCreditCardPayment ||
                        !selectedLease.userResident?.allowCreditCards
                      : !selectedLease.property?.siteSetting
                          ?.enableOnlineLeasingCreditCardPayment ||
                        !selectedLease.userResident?.allowCreditCards
                  }
                  cardMethod={cardPaymentMethod}
                  handleSetPrimary={handleSetPrimary}
                  handleRemove={handleRemove}
                  isSelector={false}
                  isReservation={isReservation}
                  paymentMethodId={paymentMethodId}
                  setPaymentMethodId={setPaymentMethodId}
                />
              </Grid>
            ))}

          {!loadingBankPaymentMethods &&
            bankPaymentMethods?.length > 0 &&
            bankPaymentMethods?.map((bankPaymentMethod) => (
              <Grid item md={6} key={bankPaymentMethod.id}>
                <BankAccountPaymentMethodRender
                  disabled={
                    isReservation
                      ? !selectedLease.property?.siteSetting
                          ?.enableTenantPortalACHPayment ||
                        !selectedLease.property?.siteSetting
                          ?.enableOnlineLeasingACHPayment ||
                        !selectedLease.userResident?.allowChecks
                      : !selectedLease.property?.siteSetting
                          ?.enableOnlineLeasingACHPayment ||
                        !selectedLease.userResident?.allowChecks
                  }
                  bankMethod={bankPaymentMethod}
                  // handleVerify={handleVerify}
                  handleSetPrimary={handleSetPrimary}
                  handleRemove={handleRemove}
                  isSelector={false}
                  isReservation={isReservation}
                  paymentMethodId={paymentMethodId}
                  setPaymentMethodId={setPaymentMethodId}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      {!loadingCardPaymentMethods &&
        !loadingBankPaymentMethods &&
        !cardPaymentMethods?.length &&
        !bankPaymentMethods?.length && (
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
            paddingTop={padding}>
            <Typography variant="body2">
              We couldn’t find any payment methods
            </Typography>
          </Box>
        )}
    </>
  );
};
