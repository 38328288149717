import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { createAction } from '@8baselabs/react-simple-state/lib/actions';
import { UserType, ClientUserType } from '@8baselabs/resident-io-shared';
import { useHistory } from 'react-router-dom';
import { useEvent, useSubscription } from '@8baselabs/react-simple-state';
import {
  fullToken,
  OnTokenErrorEvent,
  OnTokenEvent,
} from '../../shared/events/token-event';
import {
  GUARANTOR_ROUTES,
  ROUTES,
  ONBOARDING_ROUTES,
  TENANT_ROUTES,
  INVITATIONS_ROUTES,
} from '../../routes/types';
import { onIgnoreInvitation } from '../guarantor/guarantor-events';
import { OnFetchSession } from '../session/session-events';
/**
 * @returns {void}
 */
export function useSetupAuth0Token(): boolean {
  const auth = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tokenAction = createAction(
      OnTokenEvent,
      OnTokenErrorEvent,
      async () => {
        const token = await auth.getIdTokenClaims();
        return { token: token?.__raw as string };
      },
    );
    fullToken.dispatch(auth);
    if (auth.isAuthenticated) {
      setLoading(true);
      tokenAction().then(() => {
        setLoading(false);
      });
    }
  }, [auth, auth.getIdTokenClaims, auth.isAuthenticated]);

  useEffect(() => {
    setLoading(auth.isLoading);
  }, [auth.isLoading]);

  useSubscription(OnTokenErrorEvent, (err) => {
    const tokenAction = createAction(
      OnTokenEvent,
      OnTokenErrorEvent,
      async () => {
        const token = await auth.getIdTokenClaims();
        // eslint-disable-next-line no-underscore-dangle
        return { token: token?.__raw as string };
      },
    );
    if (auth.isAuthenticated) {
      setLoading(true);
      tokenAction()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          auth.logout({
            returnTo: window.location.origin,
          });
        });
    }
  });
  return loading;
}

/**
 * @returns {void}
 */
export function useDefaultRedirect(): void {
  const history = useHistory();
  const session = useEvent(OnFetchSession);
  const { pathname } = history.location;
  const ignoredInvitations = useEvent(onIgnoreInvitation);
  const pathSplit = history.location.pathname.split('/');
  const primaryPath = pathSplit[pathSplit.length - 1];

  const hasPendingInvitations = useMemo(
    () =>
      !!session?.fetchInvitation?.id &&
      !history.location.pathname.includes(
        INVITATIONS_ROUTES.acceptinvitation.path,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session],
  );

  const allowRedirect =
    !history.location.pathname.includes('/blocked-user') &&
    !history.location.pathname.includes(INVITATIONS_ROUTES.verifyEmail.path) &&
    !history.location.pathname.includes(
      INVITATIONS_ROUTES.confirmVerifyEmail.path,
    ) &&
    (!ONBOARDING_ROUTES[primaryPath] ||
      (ONBOARDING_ROUTES['update-profile'] && session?.user.registerFinish)) &&
    !history.location.pathname.includes('/guarantor') &&
    (!history.location.pathname.includes(
      INVITATIONS_ROUTES.acceptinvitation.path,
    ) ||
      (!hasPendingInvitations &&
        history.location.pathname.includes(
          INVITATIONS_ROUTES.acceptinvitation.path,
        )));
  const isAdminPMOrPO = useMemo(
    () =>
      session?.user.userType &&
      [
        UserType.ADMIN,
        ClientUserType.PROPERTY_MANAGER,
        ClientUserType.PROPERTY_OWNER,
      ].includes(
        (session.user.userClientUserRelation?.items?.[0]?.role ||
          session.user.userType) as UserType,
      ),
    [session],
  );

  const isResident = useMemo(
    () =>
      session?.user.userType &&
      [UserType.USER].includes(session.user.userType as UserType),
    [session],
  );

  const isGuarantor = useMemo(
    () =>
      session?.user.userType &&
      [ClientUserType.GUARANTOR].includes(
        session.user.userType as ClientUserType,
      ),
    [session],
  );

  const needRedirect = useMemo(() => {
    if (
      session?.fetchInvitation &&
      pathname.includes(INVITATIONS_ROUTES.acceptinvitation.path)
    )
      return false;
    if (isAdminPMOrPO) {
      const routesKeys = Object.keys(ROUTES);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of routesKeys) {
        if (pathname.includes(ROUTES[key].path)) return false;
      }
    } else if (isResident) {
      const residentRoutesKeys = Object.keys(TENANT_ROUTES);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of residentRoutesKeys) {
        if (pathname.includes(TENANT_ROUTES[key].path)) return false;
      }
    } else if (isGuarantor) {
      const guarantorRoutesKeys = Object.keys(GUARANTOR_ROUTES);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of guarantorRoutesKeys) {
        if (pathname.includes(GUARANTOR_ROUTES[key].path)) return false;
      }
    }
    return true;
  }, [pathname, isGuarantor, isAdminPMOrPO, isResident, session]);

  useEffect(() => {
    if (needRedirect && isAdminPMOrPO && allowRedirect) {
      history.push(ROUTES.dashboard.path);
    } else if (
      needRedirect &&
      isResident &&
      session?.user.registerFinish &&
      allowRedirect
    ) {
      const checkAvailabilityStore = localStorage.getItem('check-availability');

      if (
        checkAvailabilityStore !== '' &&
        checkAvailabilityStore !== null &&
        allowRedirect
      ) {
        history.push(
          `${TENANT_ROUTES['select-unit'].path.replace(
            ':id',
            checkAvailabilityStore as string,
          )}`,
        );
      }
      if (allowRedirect) history.push(TENANT_ROUTES.resident.path);
    } else if (needRedirect && session?.user.registerFinish && allowRedirect) {
      const filteredInvitationsByState =
        session.user.guarantorData?.guarantorId?.items.filter(
          (invitation) => invitation.status !== 'Complete',
        );
      if (filteredInvitationsByState?.length) {
        const filteredInvitationsByIgnored = filteredInvitationsByState.filter(
          (invitation) =>
            ignoredInvitations.some(
              (ignoredInv) => ignoredInv !== invitation.id,
            ),
        );
        if (filteredInvitationsByIgnored.length) {
          history.push(GUARANTOR_ROUTES.invitation.path);
        }
      }
    }
  }, [
    allowRedirect,
    history,
    session,
    isAdminPMOrPO,
    isResident,
    isGuarantor,
    needRedirect,
    pathname,
    ignoredInvitations,
  ]);

}
