import React from 'react';
import NumberFormat from 'react-number-format';

/**
 * Format a nomber to currency.
 *
 * @param props - Raw number.
 * @returns      Output string.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NumberFormatCustom(props): React.ReactElement {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      decimalScale={4}
    />
  );
}
