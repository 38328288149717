import { createEvent } from '@8baselabs/react-simple-state';
import {
  ActivityLog,
  BedListResponse,
  LeaseListResponse,
  Ticket,
  UserListResponse,
} from '@8baselabs/resident-io-shared';
import { Moment } from 'moment';

export const dashboardDelinquency = createEvent<{ amount: number }>();
export const onFetchActivitiesEvent = createEvent<ActivityLog[]>();
export const onFetchActivitiesError = createEvent<Error>();
export const fetchLeasesEvent = createEvent<LeaseListResponse>();
export const fetchLeasesError = createEvent<Error>();
export const onGetTickets = createEvent<readonly Ticket[] | undefined>();
export const onGetTicketsError = createEvent<Error>();

export const activityFeedDatesEvent = createEvent<{
  startDate: Moment;
  endDate: Moment;
}>();
export const onFetchUsersEvent = createEvent<UserListResponse>();
export const onFetchUsersError = createEvent<Error>();

export const fetchActiveLeasesEvent = createEvent<LeaseListResponse>();
export const fetchActiveLeasesError = createEvent<Error>();

export const fetchPropertiesBedsEvent = createEvent<BedListResponse>();
export const fetchPropertiesBedsError = createEvent<Error>();

export const dashboardSelectedProperties = createEvent<{
  properties: string[];
  autocomplete: boolean;
}>({ initialValue: { properties: [], autocomplete: false } });
