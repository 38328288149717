import { createAction } from '@8baselabs/react-simple-state';
import {
  BedListResponse,
  Mutation,
  OptionalPreferenceInvetoryUpdateInput,
  Query,
  TransferLease,
  TransferLeaseCreateInput,
  TransferLeaseFilter,
} from '@8baselabs/resident-io-shared';
import { apolloClient } from '../../shared/apollo';
import {
  CREATE_TRANSFER_LEASE,
  GET_BED_PREFERENCES,
  GET_TRANSFER_LEASE_BY_LEASE_ID,
  UPDATE_PREFERENCE_INVENTORY,
} from './tenant-queries';
import { StepType } from './tenant-types';
import {
  onFetchOptionalPreferencesEvent,
  onFetchOptionalPreferencesEventError,
  onGetTransferLease,
  onGetTransferLeaseError,
  onTransferLeaseCreate,
  onTransferLeaseCreateError,
  onUpdateInventory,
  onUpdateInventoryError,
  Step,
  StepError,
} from './tenant-events';
import { OptionalPreferenceListResponse } from '../../schema-types';

export const stepAction = createAction(
  Step,
  StepError,
  async (next: StepType): Promise<StepType> => next,
);

export const transferCreateAction = createAction(
  onTransferLeaseCreate,
  onTransferLeaseCreateError,
  async (data: TransferLeaseCreateInput) => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: CREATE_TRANSFER_LEASE,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();

    return response.data?.transferLeaseCreate as TransferLease;
  },
);

export const fetchTransferLeaseByLeaseID = createAction(
  onGetTransferLease,
  onGetTransferLeaseError,
  async (id: string) => {
    const filter: TransferLeaseFilter = {
      lease: {
        id: {
          equals: id,
        },
      },
    };

    const response = await apolloClient.query<Query>({
      query: GET_TRANSFER_LEASE_BY_LEASE_ID,
      variables: {
        filter,
      },
    });

    return response.data.transferLeasesList.items as TransferLease[];
  },
);

export const getApprovedOptionalPreferences = createAction(
  onFetchOptionalPreferencesEvent,
  onFetchOptionalPreferencesEventError,
  async (
    bedId: string,
    leasePeriodId: string,
    leaseId: string,
    isRenewal: boolean,
  ): Promise<OptionalPreferenceListResponse> => {
    const {
      data: { bedsList },
    } = await apolloClient.query<{
      bedsList: BedListResponse;
    }>({
      //query: FETCH_APPROVED_PREFERENCES_LIST,
      query: GET_BED_PREFERENCES,
      variables: {
        bedId,
        leasePeriodId,
        leaseId,
        availabilityType: isRenewal ? 'RENEWAL_RESIDENT' : 'NEW_RESIDENT',
      },
    });

    const availabilitiesList = bedsList.items.flatMap((bed) =>
      bed.bedAvailabilityRelation?.items.flatMap(
        (availability) =>
          availability.availabilityOptionalPreferenceRelation?.items,
      ),
    );
    const preferencesList = availabilitiesList
      .map((availability) => availability?.optionalPreference || {})
      .filter(
        (preference, index, self) =>
          index ===
          self.findIndex(
            (samePreference) => samePreference.id === preference.id,
          ),
      );
    const response = {
      items: preferencesList,
      count: preferencesList.length,
      groups: [],
    };
    return response as OptionalPreferenceListResponse;
  },
);

export const updateInventory = createAction(
  onUpdateInventory,
  onUpdateInventoryError,
  async (data: OptionalPreferenceInvetoryUpdateInput) => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_PREFERENCE_INVENTORY,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();

    return response.data?.optionalPreferenceInvetoryUpdate;
  },
);
