enum ErrorMessages {
  Property = 'Please review property field.',
  Recipients = 'Please review Recipients field.',
  Subject = 'Please review the template subject.',
  Message = 'A message cannot be empty. Please review message field.',
  MessageLength = 'The length of the message cannot exceed 900 characters.',
  Other = 'There was a error. Please check all your steps.',
}

export const errorMessages = (err: string): string => {
  switch (err) {
    case 'message':
      return ErrorMessages.Message;
    case 'recipients':
      return ErrorMessages.Recipients;
    case 'property':
      return ErrorMessages.Property;
    case 'subject':
      return ErrorMessages.Subject;
    case 'messageLength':
      return ErrorMessages.MessageLength;
    default:
      return ErrorMessages.Other;
  }
};
