import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useCallAction } from '@8baselabs/react-simple-state';
import {
  LeaseSubPeriod,
  Lease,
  Ledger,
  LedgerCreateInput,
} from '../../../schema-types';

import { reverseLedgersAction } from '../people-actions';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { formatCurrency } from '../../../shared/utils';
import { isValidString } from '@8baselabs/validation-utils';
import { AlertContext } from '../../../routes/AlertContext';
import {
  EntryType,
  LedgerPaymentCategories,
  LedgerPaymentStatus,
  LedgerPaymentTypes,
} from '@8baselabs/resident-io-shared';

type PaymentPlanItemDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  date?: Date;
  type?: string;
  paymentType?: string;
  subCategory?: string;
  id?: string;
  ledger?: Ledger;
  lease: Lease | undefined;
  leaseSubPeriod?: LeaseSubPeriod;
  category?: string;
  description?: string;
  amount?: number;
};
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '30px 34px',
      width: '368px',
    },
  }),
);
export const ReverTransactionDialog: React.FC<PaymentPlanItemDialogProps> = ({
  isOpen,
  onClose,
  id,
  ledger,
  lease,
  leaseSubPeriod,
  date,
  type = '',
  paymentType = '',
  subCategory = '',
  category = '',
  description = '',
  amount = 0,
}) => {
  const alert = useContext(AlertContext);

  const [reverseTransactionsCall, loadingReverseTransaction] = useCallAction(
    reverseLedgersAction,
    {
      onCompleted() {
        onClose();
      },
      onError() {
        alert({
          error: true,
          message: 'An error has happened. Try again.',
        });
      },
    },
  );
  const classes = useStyles();
  const handleSubmitCreate = (): void => {
    const entry = {
      amount: ledger?.amount || 0,
      category:
        ledger?.paymentType === LedgerPaymentTypes.ELECTRONIC_REFUND
          ? LedgerPaymentCategories.REFUND
          : 'Transaction reversal',
      subCategory: ledger?.subCategory || subCategory,
      effectiveDate: ledger?.effectiveDate || date || '',
      type: !ledger?.type
        ? type
        : ledger?.type === EntryType.DEBIT
        ? EntryType.CREDIT
        : EntryType.DEBIT,
      paymentType: !ledger?.paymentType
        ? paymentType
        : ledger?.paymentType === LedgerPaymentTypes.ELECTRONIC_PAYMENT
        ? LedgerPaymentTypes.ELECTRONIC_REFUND
        : LedgerPaymentTypes.MANUAL_REFUND,
      description: ledger?.description || description || '',
      lease: { connect: { id: lease?.id } },
      leaseSubPeriod: isValidString(ledger?.leaseSubPeriod)
        ? { connect: { id: ledger?.leaseSubPeriod?.id } }
        : leaseSubPeriod
        ? { connect: { id: leaseSubPeriod.id } }
        : undefined,
      paymentStatus:
        ledger?.paymentType === LedgerPaymentTypes.ELECTRONIC_REFUND
          ? LedgerPaymentStatus.PROCESSING.toUpperCase()
          : '',
      ledgerTransactionInformationRelation: {
        create: {
          checkNumber:
            ledger?.ledgerTransactionInformationRelation?.checkNumber || '',
          stripePyId:
            ledger?.ledgerTransactionInformationRelation?.stripePyId || '',
          transactionId:
            ledger?.ledgerTransactionInformationRelation?.transactionId || '',
          lastFour:
            ledger?.ledgerTransactionInformationRelation?.lastFour || '',
          paypalTransactionId:
            ledger?.ledgerTransactionInformationRelation?.paypalTransactionId ||
            '',
        },
      },
    } as LedgerCreateInput;

    reverseTransactionsCall(
      [entry],
      lease?.id || '',
      lease?.property?.stripeAccountId || '',
    );
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm">
      <DialogTitle>Reverse Transaction</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} className={classes.content}>
          <Typography variant="body1">
            Confirm you want to reverse the transaction ID {id} for{' '}
            {formatCurrency(amount)}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <ButtonAction
          isLoading={loadingReverseTransaction}
          fullWidth={false}
          onClick={handleSubmitCreate}>
          Reverse transaction
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
