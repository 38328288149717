import React, { FC, ReactNode } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MUIDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3.5),
  },
}))(MuiDialogActions);

type DialogProps = {
  onClose: () => void;
  isVisible: boolean;
  disableEnforceFocus?: boolean;
  title: string;
  size?: 'sm' | 'md' | 'xs' | 'lg' | 'xl' | false;
  maxWidth?: number;
  actions?: ReactNode;
  className?: string;
  fullWidth?: boolean;
};

export const Dialog: FC<DialogProps> = ({
  children,
  onClose,
  disableEnforceFocus = false,
  isVisible,
  title,
  size,
  maxWidth,
  actions,
  className,
  fullWidth,
}) => (
  <MUIDialog
    maxWidth={size}
    PaperProps={{ style: { overflow: 'hidden' } }}
    disableEnforceFocus={disableEnforceFocus}
    onClose={onClose}
    aria-labelledby="customized-dialog-container"
    id="customized-dialog-container"
    open={isVisible}
    fullWidth={fullWidth}>
    <DialogTitle id="customized-dialog-title" onClose={onClose}>
      {title}
    </DialogTitle>
    <DialogContent dividers className={className}>
      <Box width="100%" maxWidth={maxWidth} padding={1}>
        {children}
      </Box>
    </DialogContent>
    {actions && (
      <Grid container style={{ paddingRight: 15 }} justifyContent="flex-end">
        <DialogActions>{actions}</DialogActions>
      </Grid>
    )}
  </MUIDialog>
);

Dialog.defaultProps = {
  size: false,
};
