import { createEvent } from '@8baselabs/react-simple-state';
import {
  Lease_InvitationResidentCreateInput,
  OptionalPreferenceInvetory,
  TransferLease,
  User,
} from '@8baselabs/resident-io-shared';
import { isValidString } from '@8baselabs/validation-utils';
import { ValidationError } from 'yup';

import { SkipedStep, StepType } from './tenant-types';
import { OptionalPreferenceListResponse } from '../../schema-types';

export const Step = createEvent<StepType, StepType>({
  initialValue: {
    step: '',
    leaseId: '',
    propertyId: '',
    leasePeriodId: '',
    bedId: '',
    paymentPlan: '',
    disableStep: '',
    awaitingSign: '',
    application: '',
    paymentResult: false,
  },
  reducer(next) {
    return {
      step: next.step,
      leaseId: isValidString(next.leaseId) ? next.leaseId : Step.get()?.leaseId,
      propertyId: isValidString(next.propertyId)
        ? next.propertyId
        : Step.get()?.propertyId,
      paymentResult: next.paymentResult ?? false,
      leasePeriodId: isValidString(next.leasePeriodId)
        ? next.leasePeriodId
        : Step.get()?.leasePeriodId,
      application: Step.get()?.application,
      awaitingSign: isValidString(next.awaitingSign)
        ? next.awaitingSign
        : Step.get()?.awaitingSign,
      paymentPlan: isValidString(next.paymentPlan)
        ? next.paymentPlan
        : Step.get()?.paymentPlan,
      bedId: isValidString(next.bedId) ? next.bedId : Step.get()?.bedId,
      disableStep: isValidString(next.disableStep)
        ? next.disableStep
        : undefined,
      disablePayment: next.disablePayment ?? false,
      disablePersonality: next.disablePersonality ?? false,
      handleFinish: next.handleFinish,
      handleSave: next.handleSave,
      disableNext: next.disableNext,
    };
  },
});

export const StepError = createEvent<Error>();
export const nextStep = createEvent<SkipedStep | void>();

export const TransferReservationNewResidentEvent =
  createEvent<Lease_InvitationResidentCreateInput>({
    initialValue: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });
export const TransferReservationNewResidentValidationEvent =
  createEvent<ValidationError>();
export const TransferReservationSelectResidentEvent = createEvent<User>();
export const TransferReservationSelectResidentEventError = createEvent<Error>();

export const TransferReservationExistentResidentEvent = createEvent<boolean>({
  initialValue: true,
});
export const TransferReservationCreateEventError = createEvent<
  Error | ValidationError
>();

export const onTransferLeaseCreate = createEvent<TransferLease>();
export const onTransferLeaseCreateError = createEvent<Error>();

export const onGetTransferLease = createEvent<TransferLease[]>();
export const onGetTransferLeaseError = createEvent<Error>();

export const onFetchOptionalPreferencesEvent =
  createEvent<OptionalPreferenceListResponse>();
export const onFetchOptionalPreferencesEventError = createEvent<Error>();

export const onUpdateInventory = createEvent<
  OptionalPreferenceInvetory | undefined
>();
export const onUpdateInventoryError = createEvent<Error>();
