import React, { Dispatch, SetStateAction } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { LEDGER_TRANSACTION_INFORMATION_FORM_BUILDER } from '@8baselabs/resident-io-shared';

type LedgerTransactionInformationFormProps = {
  isEditing: boolean;
  subCategory: string;
  ledgerTransactionInformationRelation: {
    checkNumber?: string;
    stripePyId?: string;
    transactionId?: string;
    lastFour?: string;
    paypalTransactionId?: string;
  };
  stateErrors: {
    type: boolean;
    paymentType: boolean;
    category: boolean;
    subCategory: boolean;
    notes: boolean;
    description: boolean;
    leaseSubPeriod: boolean;
    amount: boolean;
    effectiveDate: boolean;
    ledgerTransactionInformationRelation: {
      checkNumber: boolean;
      stripePyId: boolean;
      transactionId: boolean;
      lastFour: boolean;
      paypalTransactionId: boolean;
    };
  };
  setState: Dispatch<
    SetStateAction<{
      type: string;
      paymentType: string;
      category: string;
      subCategory: string;
      notes: string;
      description: string;
      leaseSubPeriod: string | undefined;
      effectiveDate: Date | undefined;
      amount: number;
      ledgerTransactionInformationRelation: {
        checkNumber?: string;
        stripePyId?: string;
        transactionId?: string;
        lastFour?: string;
        paypalTransactionId?: string;
      };
    }>
  >;
};

export const LedgerTransactionInformationForm: React.FC<
  LedgerTransactionInformationFormProps
> = (props) => {
  const {
    isEditing,
    subCategory,
    ledgerTransactionInformationRelation,
    stateErrors,
    setState,
  } = props;

  return (
    <>
      {LEDGER_TRANSACTION_INFORMATION_FORM_BUILDER.map((relation) => {
        if (relation.subCategory === subCategory) {
          return relation.transactionInformationFields.map((field) => {
            return (
              <Grid item xs={12}>
                <TextField
                  value={ledgerTransactionInformationRelation[field.key]}
                  onChange={(event) => {
                    setState((prevProps) => ({
                      ...prevProps,
                      ledgerTransactionInformationRelation: {
                        ...prevProps.ledgerTransactionInformationRelation,
                        [field.key]: event.target.value,
                      },
                    }));
                  }}
                  helperText={stateErrors[field] && 'This field is required'}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: field.maxLength || null }}
                  label={field?.label ? String(field.label).toLocaleUpperCase() : ''}
                  disabled={isEditing}
                />
              </Grid>
            );
          });
        } else return null;
      })}
    </>
  );
};
