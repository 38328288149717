import { User } from '@8baselabs/resident-io-shared';

export interface PeopleRowType extends User {
  name?: string;
  type?: string;
  leaseId?: string;
}

export enum LedgerFilters {
  ALL = 'All',
  FUTURE = 'Future',
  CURRENT = 'Current'
}