import { useEffect, useRef } from 'react';

/*
 * Function to manage actions that are called multiple times inneccessary.
 */

import { BORDER_COLOR } from '../theme';

let timeout;
export const multiTriggeredActionHandler = (
  cb: () => void,
  delay = 500,
): void => {
  clearTimeout(timeout);

  timeout = setTimeout(() => {
    cb();
  }, delay);
};

/*
 * Function to download images with download link.
 */
export const downloadImageWithDownloadUrl = async (
  urlToDownload: string,
  fileName = 'image',
): Promise<void> => {
  const toDataURL = await fetch(urlToDownload)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));

  const a = document.createElement('a');
  a.href = toDataURL;
  a.download = `${fileName}.png`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/*
 * Function to download files with download link.
 */
export const downloadFileWithDownloadUrl = async (
  urlToDownload: string,
  fileName = 'file',
): Promise<void> => {
  const toDataURL = await fetch(urlToDownload)
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));

  const a = document.createElement('a');
  a.href = toDataURL;
  a.download = `${fileName}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * Replace all the occerencess of $find by $replace in $originalString.
 *
 * @param originalString - Raw string.
 * @param find - Target key word or regex that need to be replaced.
 * @param replace - Replacement key word.
 * @returns      Output string.
 */
export function replaceAll(
  originalString: string,
  find: string,
  replace: string,
): string {
  return originalString.replace(new RegExp(find, 'g'), replace);
}

/**
 * Format a nomber to currency.
 *
 * @param num - Raw number.
 * @param invert - Boolean indicating wheter or not to invert the num sign.
 * @returns      Output string.
 */
export function formatCurrency(num: number, invert = false): string {
  const signedNum = invert === false || num === 0 ? num : num * -1;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  }).format(signedNum);
}

export const rootStyleTable = {
  border: `1.7px solid ${BORDER_COLOR}`,
  borderRadius: 6,
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      opacity: 0,
      pointerEvents: 'none',
    },
  '& .MuiDataGrid-cellWithRenderer, & .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitle':
    {
      whiteSpace: 'normal',
    },

  '& .MuiDataGrid-columnsContainer': {
    borderTop: `1.7px solid ${BORDER_COLOR}`,
  },
  '& .MuiDataGrid-columnHeader[data-field="actions"]': {
    border: 'none',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    borderRight: `1.7px solid ${BORDER_COLOR}`,
    userSelect: 'none',
  },
  '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
    border: 'none',
  },
};

export const rootStyleLPTable = {
  border: `1.7px solid ${BORDER_COLOR}`,
  borderRadius: 6,
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      opacity: 0,
      pointerEvents: 'none',
    },

  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-columnsContainer': {
    borderTop: `1.7px solid ${BORDER_COLOR}`,
  },
  '& .MuiDataGrid-columnHeader[data-field="actions"]': {
    border: 'none',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    borderRight: `1.7px solid ${BORDER_COLOR}`,
  },
  '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
    border: 'none',
  },
};

export const rootStyleTableAssignPaymentPlan = {
  border: `1.7px solid ${BORDER_COLOR}`,
  borderRadius: 6,

  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
    {
      opacity: 0,
      pointerEvents: 'none',
    },
  '& .MuiDataGrid-cellWithRenderer, & .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitle':
    {
      whiteSpace: 'normal',
    },

  '& .MuiDataGrid-columnsContainer': {
    borderTop: `1.7px solid ${BORDER_COLOR}`,
  },
  '& .MuiDataGrid-columnHeader[data-field="actions"]': {
    border: 'none',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    borderRight: `1.7px solid ${BORDER_COLOR}`,
  },
  '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
    border: 'none',
  },
};

export const capitalize = (text: string): string =>
  text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const capitalizeFromUpperCase = (text: string): string =>
  text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEnumKeyByEnumValue = (
  myEnum: Record<string, unknown>,
  enumValue: number | string,
): string => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
};

export const sanitizeTextToTitleCase = (str: string | undefined): string => {
  return str
    ? str.replaceAll('_', ' ').replaceAll(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      })
    : '';
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useEffectDebugger = (
  effectHook,
  dependencies,
  dependencyNames = [],
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, [effectHook, ...dependencies]);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
export const extend = (dataSets: any[]) => {
  /*
   Tips for correct usage:
   This function needs to receive every data set sorted by the same parameters.
   The data arrays should also be ordered by parent tables fetch first. EX: extend([propertyArray, propertyRoomsArray])
   */
  const mergedData: Array<unknown> = [];

  for (let i = 0; i < dataSets[0].length; i++) {
    let mergedEntry = {};
    dataSets.forEach((data) => {
      mergedEntry = { ...mergedEntry, ...data[i] };
    });
    mergedData.push(mergedEntry);
  }

  const formattedData = {
    items: mergedData,
    count: mergedData.length,
    groups: [],
  };
  return formattedData;
};

/**
 * Return wheter or not the tab is active.
 *
 * @returns - True if tab is active, false otherwise.
 */
export function getIsDocumentHidden(): boolean {
  return !document.hidden;
}
