/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  useEvent,
  useFetchAction,
  useSubscription,
} from '@8baselabs/react-simple-state';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import {
  Lease,
  MessageTemplate,
  Property,
  User,
  UserType,
} from '@8baselabs/resident-io-shared';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import { FileValue } from '@8base-react/file-input';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Dialog } from '../../../shared/components/Dialog';
import {
  OnCreateMessages,
  OnCreateMessagesError,
  OnOpenInboxMessageModal,
} from '../inbox-event';
import htmlToDraft from 'html-to-draftjs';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import {
  replyMessage,
  createMessage,
  getUsersByClient,
  getUsersByPMorPO,
} from '../inbox-actions';
import { useSession } from '../../session/session-hooks';
import { fetchPropertiesByFilter } from '../../properties/properties-actions';
import {
  OnChangeClientSelected,
  OnSelectProperties,
} from '../../settings-view/settings-events';
import { AlertContext } from '../../../routes/AlertContext';
import {
  FileTypesEnum,
  PreviewFile,
} from '../../../shared/components/PreviewFile';
import { MUTED_COLOR, TABLE_HEADER } from '../../../shared/theme';
import { MessageEditor } from '../../../shared/components/MessageEditor';
import { MessageType } from './MessageType';
import { ComposeMessageStepper } from './ComposeMessageStepper';
import { MessageRecipients } from './MessageRecipients';
import {
  InsertOnField,
  InsertVariablesButton,
} from '../../settings-view/message-templates/components/InsertVariablesButton';
import {
  onCreateTemplateErrorEvent,
  onCreateTemplateEvent,
} from '../../settings-view/message-templates/message-template-events';
import {
  createTemplate,
  fetchMessageTemplates,
} from '../../settings-view/message-templates/message-template-actions';
import { Alert } from '@mui/material';
import { getLeasesDataForMessage } from '../../opportunities/opportunities-actions';
import { fetchUsersForMessage } from '../../people/people-actions';
import { errorMessages } from './ErrorMessages';
import { FilestackUploadButton } from '../../../shared/components/FilestackUploadButton';
import {
  bracketsStrategy,
  HandleBrackets,
} from '../../../shared/utils/message-decorators';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getErrorsAsObject } from '../../../shared/utils/errors';
import { ValidationError } from 'yup';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '24px 16px',
    },
    button: {
      width: '116px',
      height: '46px',
      fontSize: '.875rem',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10px 20px',
    },
    attachmentsContainer: {
      marginTop: 'auto',
    },
    dialog: {
      padding: '0',
      display: 'flex',
      justifyContent: 'space-between',
      width: '750px',
      borderRadius: '4px',
      backgroundColor: alpha(MUTED_COLOR, 0.1),
      '& > *': {
        padding: 0,
      },
    },
    initialDialog: {
      padding: '0',
      display: 'flex',
      justifyContent: 'space-between',
      width: '750px',
      borderRadius: '4px',
      backgroundColor: alpha(MUTED_COLOR, 0.1),
      '& > *': {
        padding: 0,
      },
    },
    footerText: {
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '1px',
      fontFamily: 'Rubik, sans-serif',
      color: '#000',
    },
    footerTextLast: {
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '1px',
      fontFamily: 'Rubik, sans-serif',
      color: '#5D6E7F',
    },
    progressBar: {
      padding: '10px 0px',
      width: '100%',
    },
    buttonAction: {
      textTransform: 'none',
    },
    previewHeaderFrom: {
      fontSize: '14px',
      fontWeight: 500,
    },
    previewHeaderNames: {
      paddingLeft: '5px',
      fontWeight: 300,
      fontSize: '14px',
    },
    previewHeader: {
      flexGrow: 0,
    },
    previewSubject: {
      fontSize: '18px',
      '& .rdw-editor-wrapper': {
        fontWeight: 'bold',
      },
    },
    previewContainer: {
      padding: '26px',
      borderRadius: '4px',
      border: '1px solid rgba(153,155,168,0.12)',
    },
    previewMessage: {
      flexGrow: 3,
      padding: '20px 0px',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    previewFooter: {
      flexGrow: 0,
    },
    previewFooterText: {
      fontWeight: 300,
      fontSize: '14px',
    },
    subjectContent: {
      border: '1px solid #e5e5e5',
      paddingLeft: '20px',
      width: '300px',
      overflow: 'hidden !important',
      '& .public-DraftStyleDefault-block': {
        overflowX: 'auto',
        whiteSpace: 'pre',
      },
    },
  }),
);

type PropertyUsers = Pick<
  User,
  'email' | 'firstName' | 'lastName' | 'guarantorData' | 'id' | 'phoneNumber'
>;
type UserInfoForVariables = Pick<
  Lease,
  | 'id'
  | 'moveInDate'
  | 'moveOutDate'
  | 'status'
  | 'bed'
  | 'leaseLedgerRelation'
  | 'property'
>;
enum MessageVia {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export const ComposeMessageEmail: React.FC = () => {
  const classes = useStyles();
  const ModalData = useEvent(OnOpenInboxMessageModal);
  const MAX_LENGTH_MESSAGE = 900;
  const [propertyUsers, setPropertyUsers] = useState<PropertyUsers[]>([]);
  const [userInfoForVariables, setUserInfoForVariables] =
    useState<UserInfoForVariables[]>();
  const [session] = useSession();
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [editorSubjectState, seteditorSubjectState] = useState(
    EditorState.createEmpty(),
  );
  const [subject, setSubject] = useState('');
  const [isEmail, setIsEmail] = useState<boolean | undefined>(true);
  const [messageRecipients, setMessageRecipients] = useState<PropertyUsers[]>(
    [],
  );
  const [, setSelectedEmail] = useState<string>();
  const [attachments, setAttachments] = useState<FileValue[]>([]);
  const selectedProperty = useEvent(OnSelectProperties);
  const [notifyGuarantor, setNotifyGuarantor] = useState<boolean>(false);
  const [channelDisabledUsers, setChannelDisabledUsers] = useState<number>(0);
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(false);
  const selectedClientId = useEvent(OnChangeClientSelected);
  const isPropertyManagerOwner =
    session?.user.userClientUserRelation?.items.filter(
      (clientUser) =>
        clientUser.role !== 'RESIDENT' && clientUser.role !== 'ADMIN',
    ).length !== 0;

  const [, loadingPropertyUsers] = useFetchAction(
    getUsersByClient,
    [
      selectedClientId,
      isEmail === true ? isEmail : undefined,
      isEmail === false ? !isEmail : undefined,
    ],
    {
      onCompleted: (data) => {
        if (!data) return;
        const allPropertyUsers = data.clientPropertyRelation?.items
          .filter(
            (property) =>
              property?.id ===
              (ModalData?.leaseProperty !== undefined
                ? ModalData?.leaseProperty?.id
                : selectedProperty[0]?.id),
          )
          .map((prop) =>
            prop.propertyLeaseRelation?.items.map(
              (lease) => lease.userResident,
            ),
          )
          .flat() as User[];
        const dataWithoutDuplicates = allPropertyUsers?.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t?.email === value?.email && t?.id === value?.id,
            ),
        );
        setPropertyUsers(dataWithoutDuplicates as PropertyUsers[]);
      },
      skip:
        (!!ModalData?.leaseIDs?.length && !!ModalData?.userIDs?.length) ||
        isPropertyManagerOwner ||
        (ModalData?.leaseProperty !== undefined
          ? !ModalData?.leaseProperty
          : selectedProperty.length !== 1) ||
        ModalData?.isPeopleProfile,
    },
  );

  const [, loadingPropertyUsersByPMorPO] = useFetchAction(
    getUsersByPMorPO,
    [
      session?.user?.id,
      isEmail === true ? isEmail : undefined,
      isEmail === false ? !isEmail : undefined,
    ],
    {
      onCompleted: (data) => {
        if (!data) return;
        const allPropertyUsers = data?.items
          .filter(
            (property) =>
              property?.id ===
              (ModalData?.leaseProperty !== undefined
                ? ModalData?.leaseProperty?.id
                : selectedProperty[0]?.id),
          )
          .map((prop) =>
            prop.propertyLeaseRelation?.items.map(
              (lease) => lease.userResident,
            ),
          )
          .flat() as User[];
        const dataWithoutDuplicates = allPropertyUsers?.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t?.email === value?.email && t?.id === value?.id,
            ),
        );
        setPropertyUsers(dataWithoutDuplicates as PropertyUsers[]);
      },
      skip:
        (!!ModalData?.leaseIDs?.length && !!ModalData?.userIDs?.length) ||
        !isPropertyManagerOwner ||
        (ModalData?.leaseProperty !== undefined
          ? !ModalData?.leaseProperty
          : selectedProperty.length !== 1),
    },
  );

  const [, loadingLeaseInfo] = useFetchAction(
    getLeasesDataForMessage,
    [ModalData?.leaseIDs],
    {
      onCompleted: (data) => {
        const suitablePropertyUsers = isEmail
          ? (data
              ?.filter(
                (lease) =>
                  lease.userResident?.userTenantProfileRelation?.email === true,
              )
              .map((lease) => lease.userResident) as User[])
          : (data
              ?.filter(
                (lease) =>
                  lease.userResident?.userTenantProfileRelation?.sms === true,
              )
              .map((lease) => lease.userResident) as User[]);
        if (data && data?.length > suitablePropertyUsers.length) {
          setChannelDisabledUsers(data.length - suitablePropertyUsers.length);
        } else {
          setChannelDisabledUsers(0);
        }
        setPropertyUsers(suitablePropertyUsers as PropertyUsers[]);
        setMessageRecipients(suitablePropertyUsers as PropertyUsers[]);
        setUserInfoForVariables(data as UserInfoForVariables[]);
      },
      skip: !ModalData?.leaseIDs?.length && !ModalData?.userIDs?.length,
    },
  );

  const [, loadingUserFromPeopleInfo] = useFetchAction(
    fetchUsersForMessage,
    [ModalData?.userIDs],
    {
      onCompleted: (data) => {
        const suitableUsers = isEmail
          ? data
              .filter((user) => user.userTenantProfileRelation?.email === true)
              .map((user) => user)
          : data
              .filter((user) => user.userTenantProfileRelation?.sms === true)
              .map((user) => user);
        if (data.length > suitableUsers.length) {
          setChannelDisabledUsers(data.length - suitableUsers.length);
        } else {
          setChannelDisabledUsers(0);
        }

        setPropertyUsers(suitableUsers as PropertyUsers[]);
        setMessageRecipients(suitableUsers as PropertyUsers[]);
      },
      skip: !ModalData?.userIDs?.length && !ModalData?.leaseIDs?.length,
    },
  );
  const [properties, setProperties] = useState<Property[]>([]);
  const [sending, setSending] = useState(false);
  const [composeError, setComposeError] = useState({
    property: false,
    recipients: false,
    subject: false,
    message: false,
    messageLength: false,
  });
  const [messageTemplate, setMessageTemplate] = useState<string>('NONE');
  const [templatesList, setTemplatesList] = useState<MessageTemplate[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [smsContent, setSmsContent] = useState<string>();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const steps = [
    'Start',
    'Verify Recipients',
    'Compose Message',
    'Preview & Send',
  ];

  const alert = useContext(AlertContext);

  useEffect(() => {
    if (
      userInfoForVariables &&
      userInfoForVariables?.length > messageRecipients.length
    ) {
      setChannelDisabledUsers(
        userInfoForVariables.length - messageRecipients.length,
      );
    } else {
      setChannelDisabledUsers(0);
    }
  }, [messageRecipients, userInfoForVariables]);

  useEffect(() => {
    (async () => {
      const clientId =
        session?.user.userType === UserType.ADMIN
          ? selectedClientId
          : session?.user?.userClientUserRelation?.items?.[0].client?.id;
      if (!clientId) return;
      const res = await fetchPropertiesByFilter({
        client: {
          id: {
            equals: clientId,
          },
        },
      });
      setProperties(res as Property[]);
    })();
  }, [
    session?.user?.id,
    selectedClientId,
    session?.user.userType,
    session?.user.userClientUserRelation?.items,
  ]);

  const fetchTemplates = useCallback(async () => {
    if (selectedProperty) {
      const filter = {
        property: {
          id: {
            in: selectedProperty?.map((prop) => prop?.id) ?? [],
          },
        },
      };
      const temp = await fetchMessageTemplates(filter);
      setTemplatesList(temp as MessageTemplate[]);
    }
  }, [selectedProperty]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates, selectedProperty]);

  const onSaveTemplate = (): void => {
    setLoading(true);
    const htmlValue = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );

    createTemplate({
      name: subject,
      type: isEmail ? 'email' : 'sms',
      subject: subject,
      template: isEmail ? htmlValue : smsContent,
      property: {
        connect: {
          id: selectedProperty[0]?.id,
        },
      },
    });
    setLoading(false);
  };

  useSubscription(onCreateTemplateEvent, () => {
    alert({
      success: true,
      message: 'Template successfully created',
    });
    seteditorState(editorState);
    fetchTemplates();
    setLoading(false);
  });

  useSubscription(onCreateTemplateErrorEvent, (error) => {
    const errMessages: string[] = [];
    if (error && error instanceof ValidationError) {
      const errorValues = getErrorsAsObject(error);
      Object.keys(errorValues).forEach((key) => {
        const err = errorValues[key] ?? '';
        errMessages.push(err);
      });
    }
    alert({
      error: true,
      message: errMessages.join('. '),
    });
    fetchTemplates();
    setLoading(false);
  });

  const onEditorStateChange = (
    state: React.SetStateAction<EditorState>,
  ): void => {
    seteditorState(state);
  };

  const onEditorSubjectStateChange = (
    state: React.SetStateAction<EditorState>,
  ): void => {
    seteditorSubjectState(state);
  };

  const handleClose = (): void => {
    OnOpenInboxMessageModal.dispatch({ open: false });
    setSelectedEmail('');
    seteditorState(EditorState.createEmpty());
    seteditorSubjectState(EditorState.createEmpty());
    setSubject('');
    setSmsContent('');
    setAttachments([]);
    setSending(false);
    setMessageRecipients([]);
    setActiveStep(0);
    setMessageTemplate('NONE');
  };

  useEffect(() => {
    if (!ModalData?.reply) return;

    setMessageRecipients([
      {
        email: ModalData?.reply.email,
        firstName: ModalData.reply.name,
      },
    ]);
    setSubject(`RE: ${ModalData?.reply.subject}`);
  }, [ModalData?.reply]);

  useSubscription(OnCreateMessages, () => {
    handleClose();
    alert({
      success: true,
      message: 'Message sent successfully',
    });
  });
  useSubscription(OnCreateMessagesError, () => {
    handleClose();
    alert({
      error: true,
      message: 'Something was wrong sending the message',
    });
  });

  useEffect(() => {
    const subjectValue = editorSubjectState.getCurrentContent().getPlainText();
    setSubject(subjectValue);
  }, [editorSubjectState]);

  const onAddVariableInSubject = (placeholderText: {
    label: string;
    value: string;
  }): void => {
    const text = `${placeholderText.value}`;
    const variable = ` ${text} `;

    const currentContent = editorSubjectState.getCurrentContent();
    const currentSelection = editorSubjectState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      variable,
    );

    const newEditorState = EditorState.push(
      editorSubjectState,
      newContent,
      'insert-characters',
    );
    seteditorSubjectState(
      EditorState.forceSelection(
        newEditorState,
        newContent.getSelectionAfter(),
      ),
    );
  };

  const getMessageContent = (
    content: string,
    recipient: PropertyUsers,
    idx: number,
  ): string => {
    return content
      .replaceAll(
        'Resident First Name',
        (recipient?.firstName as string) ?? '@Resident-First-Name',
      )
      .replaceAll(
        'Resident Last Name',
        (recipient?.lastName as string) ?? '@Resident-Last-Name',
      )
      .replaceAll(
        'Resident Full Name',
        (((recipient?.firstName as string) +
          ' ' +
          recipient?.lastName) as string) ?? '@Resident-Full-Name',
      )
      .replaceAll('Resident Email', recipient?.email ?? '@Resident-Email')
      .replaceAll(
        'Resident Bed Assignment',
        (userInfoForVariables?.[idx]?.bed?.number as string) ?? '@Bed-Number',
      )
      .replaceAll(
        'Resident Move-In Date',
        (userInfoForVariables?.[idx]?.moveInDate as string) ?? '@Move-In-Date',
      )
      .replaceAll(
        'Resident Current Balance',
        (userInfoForVariables?.[
          idx
        ]?.leaseLedgerRelation?.items[0]?.amount?.toString() as string) ??
          '@Current-Balance',
      )
      .replaceAll(
        'Resident Balance Owed',
        (userInfoForVariables?.[
          idx
        ]?.leaseLedgerRelation?.items[0]?.amount?.toString() as string) ??
          '@Balance-Owed',
      )
      .replaceAll(
        'Resident Login URL',
        `${process.env.REACT_APP_MARKETING_SITE_URL}` ?? '@Login-URL',
      )
      .replaceAll(
        'Resident Payment URL',
        `${process.env.REACT_APP_MARKETING_SITE_URL}/payments` ??
          '@Payment-URL',
      )
      .replaceAll(
        'Resident Move-Out Date',
        (userInfoForVariables?.[idx]?.moveOutDate as string) ??
          '@Move-Out-Date',
      )
      .replaceAll(
        'Resident Assigned Property',
        (userInfoForVariables?.[idx]?.property?.name as string) ??
          '@Property-Name',
      )
      .replaceAll(
        'Resident Reservation Status',
        (userInfoForVariables?.[idx]?.status as string) ??
          '@Reservation-Status',
      )
      .replaceAll(
        'Guarantor First Name',
        (recipient?.guarantorData?.firstName as string) ??
          '@Guarantor-First-Name',
      )
      .replaceAll(
        'Guarantor Last Name',
        (recipient?.guarantorData?.lastName as string) ??
          '@Guarantor-Last-Name',
      )
      .replaceAll(
        'Guarantor Full Name',
        ((recipient.guarantorData?.firstName +
          ' ' +
          recipient.guarantorData?.lastName) as string) ??
          '@Guarantor-Full-Name',
      )
      .replaceAll(
        'Guarantor Email',
        (recipient.guarantorData?.email as string) ?? '@Guarantor-Email',
      );
  };

  const handleSendMessage = (): void => {
    const localError = {
      property: false,
      recipients: false,
      subject: false,
      message: false,
      messageLength: false,
    };
    if (messageRecipients.length === 0) {
      localError.recipients = true;
    }
    if (!subject) {
      localError.subject = true;
    }
    if (
      ModalData?.leaseProperty !== undefined
        ? !ModalData?.leaseProperty
        : !selectedProperty
    ) {
      localError.property = true;
    }
    if (isEmail) {
      if (!editorState.getCurrentContent().hasText()) {
        localError.message = true;
      }

      if (
        editorState.getCurrentContent().getPlainText().length >
        MAX_LENGTH_MESSAGE
      ) {
        localError.messageLength = true;
      }
    } else {
      if (!smsContent || smsContent.length > 160) {
        localError.message = true;
      }
    }

    if (
      localError.property ||
      localError.recipients ||
      (isEmail && localError.subject) ||
      localError.message ||
      localError.messageLength
    ) {
      setComposeError(localError);
      setShowSnackbar(true);
      return;
    } else {
      setSending(true);
      const newContent: string = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );
      const contentEdited = newContent
        .replaceAll('<del', '<s')
        .replaceAll('</del>', '</s>')
        .replaceAll('<ins', '<u')
        .replaceAll('</ins>', '</u>')
        .replaceAll('<blockquote', '<q')
        .replaceAll('</blockquote>', '</q>');
      if (notifyGuarantor) {
        const guarantorsIDs = messageRecipients
          .filter(
            (guarantorsData) =>
              !!guarantorsData.guarantorData &&
              guarantorsData.guarantorData?.id,
          )
          .map((a) => {
            return a.guarantorData?.id;
          })
          .values();
        const newRecipients = [...messageRecipients];
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        for (const value of guarantorsIDs as any) {
          newRecipients.push(value);
        }
        newRecipients.map((recipient, idx) =>
          createMessage({
            content: isEmail
              ? getMessageContent(contentEdited, recipient, idx)
              : getMessageContent(smsContent as string, recipient, idx),
            id:
              ModalData?.leaseProperty !== undefined
                ? (ModalData?.leaseProperty?.id as string)
                : (selectedProperty[0]?.id as string),
            leaseId:
              (ModalData.fromField === InsertOnField.OPPORTUNITIES ||
                ModalData.fromField === InsertOnField.PEOPLE) &&
              ModalData.leaseIDs?.length !== 0
                ? (userInfoForVariables?.[idx]?.id as string)
                : '',
            subject: subject
              .replaceAll(
                'Resident First Name',
                recipient?.firstName ?? '@Resident-First-Name',
              )
              .replaceAll(
                'Resident Last Name',
                recipient?.lastName ?? '@Resident-Last-Name',
              )
              .replaceAll(
                'Resident Full Name',
                recipient?.firstName + ' ' + recipient?.lastName ??
                  '@Resident-Full-Name',
              )
              .replaceAll(
                'Resident Email',
                recipient?.email ?? '@Resident-Email',
              )
              .replaceAll(
                'Resident Bed Assignment',
                (userInfoForVariables?.[idx]?.bed?.number as string) ??
                  '@Bed-Number',
              )
              .replaceAll(
                'Resident Move-In Date',
                (userInfoForVariables?.[idx]?.moveInDate as string) ??
                  '@Move-In-Date',
              )
              .replaceAll(
                'Resident Current Balance',
                (userInfoForVariables?.[
                  idx
                ]?.leaseLedgerRelation?.items[0]?.amount?.toString() as string) ??
                  '@Current-Balance',
              )
              .replaceAll(
                'Resident Balance Owed',
                (userInfoForVariables?.[
                  idx
                ]?.leaseLedgerRelation?.items[0]?.amount?.toString() as string) ??
                  '@Balance-Owed',
              )
              .replaceAll(
                'Resident Login URL',
                `${process.env.REACT_APP_MARKETING_SITE_URL}` ?? '@Login-URL',
              )
              .replaceAll(
                'Resident Payment URL',
                `${process.env.REACT_APP_MARKETING_SITE_URL}/payments` ??
                  '@Payment-URL',
              )
              .replaceAll(
                'Resident Move-Out Date',
                (userInfoForVariables?.[idx]?.moveOutDate as string) ??
                  '@Move-Out-Date',
              )
              .replaceAll(
                'Resident Assigned Property',
                (userInfoForVariables?.[idx]?.property?.name as string) ??
                  '@Property-Name',
              )
              .replaceAll(
                'Resident Reservation Status',
                (userInfoForVariables?.[idx]?.status as string) ??
                  '@Reservation-Status',
              )
              .replaceAll(
                'Guarantor First Name',
                (recipient?.guarantorData?.firstName as string) ??
                  '@Guarantor-First-Name',
              )
              .replaceAll(
                'Guarantor Last Name',
                (recipient?.guarantorData?.lastName as string) ??
                  '@Guarantor-Last-Name',
              )
              .replaceAll(
                'Guarantor Full Name',
                ((recipient.guarantorData?.firstName +
                  ' ' +
                  recipient.guarantorData?.lastName) as string) ??
                  '@Guarantor-Full-Name',
              )
              .replaceAll(
                'Guarantor Email',
                (recipient.guarantorData?.email as string) ??
                  '@Guarantor-Email',
              ),
            type: isEmail ? MessageVia.EMAIL : MessageVia.SMS,
            propertyId:
              ModalData?.leaseProperty !== undefined
                ? (ModalData?.leaseProperty?.id as string)
                : (selectedProperty[0]?.id as string),
            attachments,
            to:
              (typeof recipient === 'string' ? recipient : recipient?.id) ?? '',
          }),
        );
      } else {
        messageRecipients.map((recipient, idx) =>
          createMessage({
            content: isEmail
              ? getMessageContent(contentEdited, recipient, idx)
              : getMessageContent(smsContent as string, recipient, idx),
            id:
              ModalData?.leaseProperty !== undefined
                ? (ModalData?.leaseProperty?.id as string)
                : (selectedProperty[0]?.id as string),
            leaseId:
              (ModalData.fromField === InsertOnField.OPPORTUNITIES ||
                ModalData.fromField === InsertOnField.PEOPLE) &&
              ModalData.leaseIDs?.length !== 0
                ? (userInfoForVariables?.[idx]?.id as string)
                : '',
            subject: subject
              .replaceAll(
                'Resident First Name',
                recipient?.firstName ?? '@Resident-First-Name',
              )
              .replaceAll(
                'Resident Last Name',
                recipient?.lastName ?? '@Resident-Last-Name',
              )
              .replaceAll(
                'Resident Full Name',
                recipient?.firstName + ' ' + recipient?.lastName ??
                  '@Resident-Full-Name',
              )
              .replaceAll(
                'Resident Email',
                recipient?.email ?? '@Resident-Email',
              )
              .replaceAll(
                'Resident Bed Assignment',
                (userInfoForVariables?.[idx]?.bed?.number as string) ??
                  '@Bed-Number',
              )
              .replaceAll(
                'Resident Move-In Date',
                (userInfoForVariables?.[idx]?.moveInDate as string) ??
                  '@Move-In-Date',
              )
              .replaceAll(
                'Resident Current Balance',
                (userInfoForVariables?.[
                  idx
                ]?.leaseLedgerRelation?.items[0]?.amount?.toString() as string) ??
                  '@Current-Balance',
              )
              .replaceAll(
                'Resident Balance Owed',
                (userInfoForVariables?.[
                  idx
                ]?.leaseLedgerRelation?.items[0]?.amount?.toString() as string) ??
                  '@Balance-Owed',
              )
              .replaceAll(
                'Resident Login URL',
                `${process.env.REACT_APP_MARKETING_SITE_URL}` ?? '@Login-URL',
              )
              .replaceAll(
                'Resident Payment URL',
                `${process.env.REACT_APP_MARKETING_SITE_URL}/payments` ??
                  '@Payment-URL',
              )
              .replaceAll(
                'Resident Move-Out Date',
                (userInfoForVariables?.[idx]?.moveOutDate as string) ??
                  '@Move-Out-Date',
              )
              .replaceAll(
                'Resident Assigned Property',
                (userInfoForVariables?.[idx]?.property?.name as string) ??
                  '@Property-Name',
              )
              .replaceAll(
                'Resident Reservation Status',
                (userInfoForVariables?.[idx]?.status as string) ??
                  '@Reservation-Status',
              )
              .replaceAll(
                'Guarantor First Name',
                (recipient?.guarantorData?.firstName as string) ??
                  '@Guarantor-First-Name',
              )
              .replaceAll(
                'Guarantor Last Name',
                (recipient?.guarantorData?.lastName as string) ??
                  '@Guarantor-Last-Name',
              )
              .replaceAll(
                'Guarantor Full Name',
                ((recipient.guarantorData?.firstName +
                  ' ' +
                  recipient.guarantorData?.lastName) as string) ??
                  '@Guarantor-Full-Name',
              )
              .replaceAll(
                'Guarantor Email',
                (recipient.guarantorData?.email as string) ??
                  '@Guarantor-Email',
              ),
            type: isEmail ? MessageVia.EMAIL : MessageVia.SMS,
            propertyId:
              ModalData?.leaseProperty !== undefined
                ? (ModalData?.leaseProperty?.id as string)
                : (selectedProperty[0]?.id as string),
            attachments,
            to:
              (typeof recipient === 'string' ? recipient : recipient?.id) ?? '',
          }),
        );
      }
    }
  };

  const handleReplyMessage = (): void => {
    if (!editorState.getCurrentContent().hasText() || !ModalData?.reply) return;

    setSending(true);
    replyMessage({
      isPM: true,
      fromProperty: false,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      id: session?.user?.id as string,
      to: ModalData.reply.email,
      attachments,
      type: isEmail ? MessageVia.EMAIL : MessageVia.SMS,
      messageThreadId: ModalData.reply.threadId,
    });
  };

  const insertVariable = (placeholderText: {
    label: string;
    value: string;
  }): void => {
    const variable = ` ${placeholderText.value} `;

    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      variable,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters',
    );
    seteditorState(
      EditorState.forceSelection(
        newEditorState,
        newContent.getSelectionAfter(),
      ),
    );

    const newSMSContent =
      smsContent && smsContent?.length !== 0
        ? smsContent + ' ' + placeholderText.value
        : placeholderText.value;
    setSmsContent(newSMSContent);
  };

  useEffect(() => {
    if (!messageTemplate || messageTemplate === 'NONE') return;
    const templateToDisplay =
      templatesList !== undefined
        ? templatesList.filter((template) => template.name === messageTemplate)
        : [];
    const blocksFromHtml = htmlToDraft(templateToDisplay[0].template);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    const nEditorState = EditorState.createWithContent(contentState);
    seteditorState(nEditorState);

    const blocksFromHtmlToSubject = htmlToDraft(templateToDisplay[0].subject);
    const { contentBlocks: contentBlocksSubject, entityMap: entityMapSubject } =
      blocksFromHtmlToSubject;
    const contentStateSubject = ContentState.createFromBlockArray(
      contentBlocksSubject,
      entityMapSubject,
    );
    const nEditorSubjectState =
      EditorState.createWithContent(contentStateSubject);

    setSubject(templateToDisplay[0].subject as string);
    seteditorSubjectState(nEditorSubjectState);
  }, [messageTemplate, templatesList]);

  if (loadingLeaseInfo || loadingUserFromPeopleInfo) {
    return (
      <Dialog
        title={ModalData?.reply ? 'Reply' : 'Compose Message'}
        className={activeStep === 0 ? classes.initialDialog : classes.dialog}
        isVisible={ModalData?.open}
        onClose={handleClose}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}>
          <CircularProgress />
        </Grid>
      </Dialog>
    );
  }

  return (
    <Dialog
      title={ModalData?.reply ? 'Reply' : 'Compose Message'}
      className={
        activeStep === 0 || activeStep === 1
          ? classes.initialDialog
          : classes.dialog
      }
      isVisible={ModalData?.open}
      onClose={handleClose}>
      <ComposeMessageStepper
        steps={[
          'Start',
          'Verify Recipients',
          `Compose ${isEmail ? 'Email' : 'SMS'}`,
          'Preview & Send',
        ]}
        activeStep={activeStep}
      />
      {activeStep === 0 && (
        <MessageType
          loading={loadingPropertyUsers || loadingPropertyUsersByPMorPO}
          isEmail={isEmail}
          onClick={(value) => {
            if (value === MessageVia.EMAIL) {
              setIsEmail(true);
              setMessageRecipients(propertyUsers[0] ? [propertyUsers[0]] : []);
              return;
            }
            setIsEmail(false);
            setMessageRecipients(propertyUsers[0] ? [propertyUsers[0]] : []);
          }}
        />
      )}
      {activeStep === 1 && (
        <MessageRecipients
          isEmail={isEmail}
          availableRecipients={propertyUsers as PropertyUsers[]}
          onRecipientsSelection={setMessageRecipients}
          recipientsSelected={messageRecipients}
          channelDisabledUsers={channelDisabledUsers}
          setDisabledNextButton={setDisabledNextButton}
        />
      )}
      {activeStep === 2 && isEmail && (
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          alignContent="flex-start"
          className={classes.container}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Box mb="10px" fontWeight={500}>
                Templates
              </Box>
              <TextField
                fullWidth
                select
                value={messageTemplate}
                disabled={Boolean(ModalData?.reply)}
                onChange={(event) => setMessageTemplate(event.target.value)}
                variant="outlined">
                <MenuItem value={'NONE'}>No template</MenuItem>
                {templatesList !== undefined &&
                  templatesList
                    ?.filter((templateType) => templateType.type === 'email')
                    .map((template) => (
                      <MenuItem value={template.name} key={template?.id}>
                        {template.name}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Box mb="10px" fontWeight={500}>
              Subject
            </Box>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                marginBottom: 20,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Grid item xs={8} style={{ display: 'flex' }}>
                <Grid item style={{ width: '100%', display: 'flex' }}>
                  <Editor
                    editorState={editorSubjectState}
                    editorClassName={classes.subjectContent}
                    onEditorStateChange={onEditorSubjectStateChange}
                    toolbarStyle={{
                      border: '1px solid #e5e5e5',
                    }}
                    toolbarHidden={true}
                    customDecorators={[
                      {
                        strategy: bracketsStrategy,
                        component: HandleBrackets,
                      },
                    ]}
                    wrapperClassName="input-editor-wrapper"
                  />

                  <InsertVariablesButton
                    containerProps={{
                      border: '1px solid',
                      padding: '15px 14px',
                      margin: '0',
                    }}
                    field={InsertOnField.SUBJECT}
                    insertVariable={onAddVariableInSubject}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() => setNotifyGuarantor(!notifyGuarantor)}
                      checked={notifyGuarantor}
                      color="primary"
                      disableFocusRipple
                      disableRipple
                    />
                  }
                  label="Notify Guarantor"
                />
              </Grid>
            </Grid>
          </Grid>
          <MessageEditor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            insertVariable={insertVariable}
            insertVariableOnField={ModalData?.fromField}
          />
          <Grid
            container
            direction="column"
            spacing={3}
            className={classes.attachmentsContainer}>
            <Grid item>
              <FilestackUploadButton
                maxFiles={15}
                accept={['image/*']}
                onUploadDone={(newValue) => {
                  setAttachments(newValue);
                }}
              />
            </Grid>
            <Grid item>
              {attachments.map((file) => {
                let fileType: FileTypesEnum = FileTypesEnum.OTHER;

                if (file?.filename) {
                  const auxLegnth = file?.filename?.split('.').length || 0;
                  const auxType = file?.filename?.split('.')[auxLegnth - 1];

                  if (auxType === 'pdf') {
                    fileType = FileTypesEnum.PDF;
                  } else if (
                    auxType === 'jpg' ||
                    auxType === 'jpeg' ||
                    auxType === 'png'
                  ) {
                    fileType = FileTypesEnum.IMAGE;
                  }
                }

                return (
                  <Grid item key={file.fileId}>
                    <PreviewFile
                      fileURL={file?.downloadUrl || ''}
                      fileName={file.filename}
                      fileType={fileType}
                      onDelete={() => {
                        setAttachments((prev) =>
                          prev.filter(
                            (attachment) => attachment.fileId !== file.fileId,
                          ),
                        );
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
      {activeStep === 2 && !isEmail && (
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          alignContent="flex-start"
          className={classes.container}>
          <Grid item xs={12}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Box mb="10px" fontWeight={500}>
                Templates
              </Box>
              <TextField
                fullWidth
                select
                value={messageTemplate}
                disabled={Boolean(ModalData?.reply)}
                onChange={(event) => {
                  setMessageTemplate(event.target.value);
                  setSmsContent(
                    templatesList?.filter(
                      (template) => template.name === event.target.value,
                    )?.[0]?.template ?? '',
                  );
                }}
                variant="outlined">
                <MenuItem value={'NONE'}>No template</MenuItem>
                {templatesList !== undefined &&
                  templatesList
                    ?.filter((templateType) => templateType.type === 'sms')
                    .map((template) => (
                      <MenuItem value={template.name} key={template?.id}>
                        {template.name}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid style={{ position: 'relative' }}>
              <TextField
                style={{ position: 'relative' }}
                variant="outlined"
                fullWidth
                error={smsContent ? smsContent.length > 160 : false}
                multiline
                inputProps={{
                  style: {
                    marginTop: '2rem',
                  },
                }}
                minRows={15}
                value={smsContent}
                onChange={(e) => {
                  setSmsContent(e.target.value);
                }}
              />
              <Box
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  top: 1.5,
                  left: 1.5,
                  backgroundColor: TABLE_HEADER,
                  width: '99.4%',
                }}>
                <InsertVariablesButton
                  containerProps={{
                    ml: '20px',
                    paddingBottom: '10px',
                    mt: '10px',
                    height: '100%',
                    alignItems: 'center',
                  }}
                  field={ModalData.fromField}
                  insertVariable={insertVariable}
                />
              </Box>
            </Grid>
            {smsContent?.length ?? 0}/160
          </Grid>
        </Grid>
      )}
      {activeStep === 3 && (
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          alignContent="flex-start"
          className={classes.container}>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex' }}
              justifyContent="space-between">
              <Box
                sx={{
                  lineHeight: '25px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '14px',
                  color: '#252631',
                }}>
                Preview
              </Box>
              <Box>
                <ButtonAction
                  disabled={messageTemplate !== 'NONE'}
                  onClick={onSaveTemplate}
                  isLoading={loading}
                  variant="text"
                  className={classes.buttonAction}>
                  <SaveIcon />
                  <Box
                    sx={{
                      lineHeight: '25px',
                      fontSize: '14px',
                      color:
                        messageTemplate !== 'NONE' ? 'disabled' : '#252631',
                      fontWeight: '500',
                    }}>
                    Save as template
                  </Box>
                </ButtonAction>
              </Box>
            </Grid>
            <Grid item direction="column" className={classes.previewContainer}>
              <Grid
                item
                justifyContent="space-between"
                direction="column"
                className={classes.previewHeader}>
                <Box sx={{ display: 'flex', lineHeight: '22px' }}>
                  <Typography className={classes.previewHeaderFrom}>
                    Send from:
                  </Typography>
                  {/* {ModalData?.isPeopleProfile ? (
                    <Typography className={classes.previewHeaderNames}>
                      {(ModalData?.fromUser?.firstName ?? '') +
                        ' ' +
                        (ModalData?.fromUser?.lastName ?? '') +
                        '' +
                        ' (' +
                        (ModalData?.fromUser?.email ?? '') +
                        ')'}
                    </Typography>
                  ) : ( */}
                  <Typography className={classes.previewHeaderNames}>
                    {(properties?.find(
                      (prop) =>
                        prop?.id ===
                        (ModalData?.leaseProperty !== undefined
                          ? (ModalData?.leaseProperty?.id as string)
                          : (selectedProperty[0]?.id as string)),
                    )?.name ?? '') +
                      ' ' +
                      ' (' +
                      (properties?.find(
                        (prop) =>
                          prop?.id ===
                          (ModalData?.leaseProperty !== undefined
                            ? (ModalData?.leaseProperty?.id as string)
                            : (selectedProperty[0]?.id as string)),
                      )?.email ?? '') +
                      ')'}
                  </Typography>
                  {/* )} */}
                </Box>
                <Typography className={classes.previewSubject}>
                  <MessageEditor
                    toolbarHidden={true}
                    editorState={editorSubjectState}
                    onPreview={true}
                  />
                </Typography>
                <Divider />
              </Grid>
              <Grid item className={classes.previewMessage}>
                {isEmail ? (
                  <MessageEditor
                    toolbarHidden={true}
                    editorState={editorState}
                    onPreview={true}
                  />
                ) : (
                  <TextField
                    variant="standard"
                    fullWidth
                    error={smsContent ? smsContent.length > 160 : false}
                    multiline
                    minRows={15}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                      readOnly: true,
                    }}
                    defaultValue={smsContent ?? ''}
                  />
                )}
              </Grid>
              <Grid item xs={12} spacing={1} style={{ padding: '20px 0px' }}>
                {attachments.map((file) => {
                  let fileType: FileTypesEnum = FileTypesEnum.OTHER;

                  if (file?.filename) {
                    const auxLegnth = file?.filename?.split('.').length || 0;
                    const auxType = file?.filename?.split('.')[auxLegnth - 1];
                    if (auxType === 'pdf') {
                      fileType = FileTypesEnum.PDF;
                    } else if (
                      auxType === 'jpg' ||
                      auxType === 'jpeg' ||
                      auxType === 'png'
                    ) {
                      fileType = FileTypesEnum.IMAGE;
                    }
                  }
                  return (
                    <Grid item key={file.fileId}>
                      <PreviewFile
                        fileURL={file?.downloadUrl || ''}
                        fileName={file.filename}
                        fileType={fileType}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box className={classes.progressBar}>
          <LinearProgress
            variant="determinate"
            value={((activeStep + 1) / steps.length) * 100}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              if (activeStep === 0) handleClose();
              else if (activeStep === 2 && messageTemplate) {
                setMessageTemplate('NONE');
                setActiveStep(activeStep - 1);
              } else setActiveStep(activeStep - 1);
            }}>
            {activeStep === 0 ? 'Cancel' : 'Back'}
          </Button>
          <Typography style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.footerText}>
              0{activeStep + 1}{' '}
            </Typography>

            <Typography className={classes.footerTextLast}>
              {' '}
              / 0{steps.length}{' '}
            </Typography>
          </Typography>
          {activeStep === steps.length - 1 ? (
            <ButtonAction
              isLoading={sending || loading}
              onClick={
                ModalData?.reply ? handleReplyMessage : handleSendMessage
              }
              className={classes.button}>
              Send
            </ButtonAction>
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => setActiveStep(activeStep + 1)}
              disabled={activeStep === 1 && disabledNextButton}>
              Next
            </Button>
          )}
          {showSnackbar && (
            <Snackbar
              open={showSnackbar}
              autoHideDuration={5000}
              onClose={() => setShowSnackbar(false)}>
              <Alert
                onClose={() => setShowSnackbar(false)}
                severity="info"
                sx={{ width: '100%' }}>
                <b>
                  {errorMessages(
                    Object.entries(composeError)
                      .filter((entry) => entry[1] === true)
                      .map((key) => key[0])[0],
                  )}
                </b>{' '}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Grid>
    </Dialog>
  );
};
