import { createEvent } from '@8baselabs/react-simple-state';
import { FileValue } from '@8base-react/file-input';
import {
  Lease,
  Maybe,
  Scalars,
  InvitationGuarantor,
  TenantProfile,
  Guarantor,
  LeaseListResponse,
  User,
  TermAndConditionListResponse,
  CreateManagerSignatureRequest,
} from '@8baselabs/resident-io-shared';

// export type UserTenantData = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   gender: string;
//   phoneNumber: string;
//   birthdate: string;
//   avatar: {
//     downloadUrl: string;
//     uploadUrl: string;
//     shareUrl: string;
//   };
// };

export type GuarantorFormData = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  birthdate?: string;
  phoneNumber?: string;
  state?: string | null;
  address1?: string;
  address2?: string;
  city?: {
    name: string;
    id: string;
  } | null;
  zipcode?: string;
  agreeTerms?: boolean;
  signature?: string;
  payStub1?: FileValue;
  payStub2?: FileValue;
  driverLicenseF?: FileValue;
  driverLicenseB?: FileValue;
  userTenantID?: User;
  userId?: string;
  status?: string;
  guarantorLeaseRelation?: LeaseListResponse;
};

export type GuarantorSaveFormData = {
  firstName?: string;
  lastName?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  gender?: string;
  birthdate?: string;
  phoneNumber?: string;
  userTenantID?: string;
  guarantorName?: string;
  guarantorPhone?: string;
  guarantorEmail?: string;
};

export type GuarantorReceiveData = {
  userID?: string;
  guarantorID?: string;
  email?: string;
};

export type ResponseInvitation = {
  guarantorUpdate: {
    guarantorId: {
      items: {
        email: string;
        id: string;
      }[];
    };
  };
};

export type AcceptInvitationInput = {
  email?: string;
  invitationId?: string;
};

export type ReturnData = {
  invitationState: string;
  id: string;
};

export type ResponseAcceptInvitation = {
  invitationAceptedGuarantor: {
    items?: {
      id: string;
      invitationState: string;
    }[];
  };
};

export type Response = {
  items?: {
    id: string;
    invitationState: string;
  }[];
};

export type ResponseGuarantorFormData = {
  invitationGuarantor: {
    guarantorId: GuarantorFormData;
    reservationId?: string;
  };
};

export type GuarantorDraftResult = {
  __typename?: 'createDraftTemplateGuarantor';
  message?: Scalars['String'];
  success?: Scalars['Boolean'];
  template?: Maybe<TemplateGuarantor>;
};

export declare type TemplateGuarantor = {
  readonly __typename?: 'Template';
  readonly sign_url?: Maybe<Scalars['String']>;
  readonly edit_url?: Maybe<Scalars['String']>;
  readonly expires_at?: Maybe<Scalars['String']>;
};

export type GuarantorDraftInput = {
  title?: Scalars['String'];
  subject?: Scalars['String'];
  message?: Scalars['String'];
  propertyId?: Scalars['String'];
  email?: Scalars['String'];
  name?: Scalars['String'];
  leasePeriodId?: Scalars['String'];
  isForGuarantor?: Scalars['Boolean'];
};

export type ManagerDraftResult = {
  __typename?: 'createSignaturesRequest';
  message?: Scalars['String'];
  success?: Scalars['Boolean'];
  template?: Maybe<TemplateManager>;
};

export declare type TemplateManager = {
  readonly __typename?: 'Template';
  readonly sign_url?: Maybe<Scalars['String']>;
  readonly edit_url?: Maybe<Scalars['String']>;
  readonly expires_at?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type ManagerDraftInput = {
  title?: Scalars['String'];
  subject?: Scalars['String'];
  message?: Scalars['String'];
  propertyId?: Scalars['String'];
  email?: Scalars['String'];
  name?: Scalars['String'];
  leasePeriodId?: Scalars['String'];
  isForGuarantor?: Scalars['Boolean'];
};

export const OnSendGuarantor = createEvent<GuarantorFormData | void>();
export const OnSendGuarantorError = createEvent<Error>();

export const onGetInvitationData = createEvent<string>();
export const onGetInvitationDataError = createEvent<Error>();

export const OnSaveGuarantor = createEvent<void>();
export const OnSaveGuarantorError = createEvent<Error>();

export const OnSaveDocuments = createEvent<void>();
export const OnSaveDocumentsError = createEvent<Error>();

export const OnUpdateDocuments = createEvent<void>();
export const OnUpdateDocumentsError = createEvent<Error>();

export const OnReceiveGuarantor = createEvent<Guarantor>();
export const OnReceiveGuarantorError = createEvent<Error>();

export const OnAcceptInvitationGuarantor = createEvent<Response | void>();
export const OnAcceptInvitationGuarantorError = createEvent<Error>();

export const OnGetGuarantorData = createEvent<ResponseGuarantorFormData>();
export const OnGetGuarantorDataError = createEvent<Error>();

export const OnGetContract = createEvent<GuarantorDraftResult | null>();
export const OnGetContractError = createEvent<Error>();

export const OnGetManagerContract =
  createEvent<CreateManagerSignatureRequest | null>();
export const OnGetManagerContractError = createEvent<Error>();

export const OnGetTerms = createEvent<GuarantorDraftResult | null>();
export const OnGetTermsError = createEvent<Error>();

export const fetchLease = createEvent<Lease>();
export const fetchLeaseError = createEvent<Error>();

export const OnUpdateTenantVehicle = createEvent<TenantProfile | undefined>();
export const OnUpdateTenantVehicleError = createEvent<Error>();

export const fetchVehicleTenantProfile = createEvent<TenantProfile>();
export const fetchVehicleTenantProfileError = createEvent<Error>();

export const onfinishInvitationGuarantor = createEvent<InvitationGuarantor>();
export const onfinishInvitationGuarantorError = createEvent<Error>();

export const OnFetchTerms = createEvent<TermAndConditionListResponse>();
export const OnFetchTermsError = createEvent<Error>();

export const onIgnoreInvitation = createEvent<string[]>({
  initialValue: [],
});
