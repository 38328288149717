import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CAPTION_TEXT_COLOR } from '../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    mutedButton: {
      color: CAPTION_TEXT_COLOR,
    },
    minButtonWidth: {
      minWidth: 120,
    },
  }),
);

export const DefaultButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <Button {...rest} variant="contained" size="large" color="default">
    {children}
  </Button>
);
export const PrimaryButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      {...rest}
      className={classes.minButtonWidth}
      variant="contained"
      size="large"
      color="primary">
      {children}
    </Button>
  );
};
export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => (
  <Button {...rest} variant="contained" size="large" color="secondary">
    {children}
  </Button>
);

export const MutedButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button {...rest} className={classes.mutedButton}>
      {children}
    </Button>
  );
};

export const InLineDefaultButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => (
  <Button {...rest} color="default">
    {children}
  </Button>
);
export const InLinePrimaryButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => (
  <Button {...rest} color="primary">
    {children}
  </Button>
);
export const InLineSecondaryButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}) => (
  <Button {...rest} color="secondary">
    {children}
  </Button>
);
