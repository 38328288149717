import { createAction } from '@8baselabs/react-simple-state';
import {
  Mutation,
  PropertyPortfolioListResponse,
  Query,
  SuccessResponse,
  Client,
  ClientListResponse,
  ClientUpdateInput,
  ClientUserListResponse,
  InvitationPropertyManagerListResponse,
  InvitationPropertyManager,
  InvitationPropertyManagerCreateInput,
  InvitationPropertyManagerUpdateInput,
  ClientUserUpdateInput,
  ClientUser,
  DefaultAccountListResponse,
  AccountListResponse,
  Account,
  PropertyInvitationPropertyManagerFilter,
  InvitationPropertyOwnerCreateInput,
  InvitationPropertyOwner,
  CurrentInvitationData,
  PropertyPortfolioUpdateInput,
  InvitationPropertyOwnerUpdateInput,
  ClientPageListResponse,
  InvitationMaintenance,
  InvitationMaintenanceCreateInput,
  InvitationTeamMemberCreateInput,
  PropertyInvitationMaintenanceFilter,
  InvitationTeamMember,
  InvitationTeamMemberUpdateInput,
  PropertyInvitationTeamMemberFilter,
  InvitationMaintenanceUpdateInput,
} from '@8baselabs/resident-io-shared';
import * as yup from 'yup';
import { apolloClient, apolloClient as client } from '../../shared/apollo';
import {
  OnCreatePortfolio,
  OnCreatePortfolioError,
  OnDeletePortfolio,
  OnDeletePortfolioError,
  OnEditPortfolio,
  OnEditPortfolioError,
  OnFetchPortfolios,
  OnFetchPortfoliosError,
  OnFetchClient,
  OnFetchClientError,
  OnUpdateClient,
  OnUpdateClientError,
  OnCreateManagerInvitation,
  OnCreateManagerInvitationError,
  OnGetManagersAndInvitationsError,
  OnGetManagersAndInvitations,
  OnDeletePropertyManagerInvitation,
  OnDeletePropertyManagerInvitationError,
  OnDeleteClientUser,
  OnDeleteClientUserError,
  OnEditPropertyManagerInvitation,
  OnEditPropertyManagerInvitationError,
  OnEditPropertyManager,
  OnEditPropertyManagerError,
  OngetAccountsAction,
  OngetAccountsActionError,
  OnGetDefaultAccountsEvent,
  OnGetDefaultAccountsError,
  OnToggleAccount,
  OnToggleAccountError,
  OnUpdateClientInvitations,
  OnUpdateClientInvitationsError,
  OnCreatePropertyOwnerInvitation,
  OnCreatePropertyOwnerInvitationError,
  OnDeletePropertyOwnerInvitation,
  OnDeletePropertyOwnerInvitationError,
  OnEditPropertyOwnerInvitation,
  OnEditPropertyOwnerInvitationError,
  OnUpdateClientPage,
  OnUpdateClientPageError,
  OnFetchClientPage,
  OnFetchClientPageError,
  OnCreateMaintenanceInvitation,
  OnCreateMaintenanceInvitationError,
  OnCreateTeamMemberInvitation,
  OnCreateTeamMemberInvitationError,
  OnEditMaintenanceInvitation,
  OnEditMaintenanceInvitationError,
  OnEditTeamMemberInvitation,
  OnEditTeamMemberInvitationError,
} from './owner-settings-events';
import {
  CREATE_PORTFOLIO,
  DELETE_PORTFOLIO,
  GET_CLIENT_USER_PORTFOLIOS,
  UPDATE_PORTFOLIO,
  FETCH_CLIENT_QUERY,
  UPDATE_CLIENT_MUTATION,
  CREATE_MANAGER_INVITATION,
  GET_CLIENT_USERS_AND_INVITATIONS,
  DELETE_INVITATION_PROPERTY_MANAGER,
  DELETE_CLIENT_USER,
  EDIT_PROPERTY_MANAGER_INVITATION,
  EDIT_CLIENT_USER,
  GET_ACCOUNTS_QUERY,
  GET_DEFAULT_ACCOUNTS_QUERY,
  TOGGLE_ACCOUNT,
  DELETE_PROPERTY_INVITATION_MANAGER,
  CHANGE_CLIENT_INVITATION_ROLE,
  DELETE_INVITATION_PROPERTY_OWNER,
  EDIT_PROPERTY_OWNER_INVITATION,
  GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORY,
  GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORIES,
  FETCH_CLIENT_PAGE_QUERY,
  UPDATE_CLIENT_PAGE_MUTATION,
  RESEND_INVITATION,
  CREATE_MAINTENANCE_INVITATION,
  CREATE_TEAM_MEMBER_INVITATION,
  DELETE_PROPERTY_INVITATION_MAINTENANCE,
  EDIT_MAINTENANCE_INVITATION,
  DELETE_PROPERTY_INVITATION_TEAM_MEMBER,
  EDIT_TEAM_MEMBER_INVITATION,
} from './owner-settings-queries';
import {
  ManageUsersFormFields,
  ManageUsersFormType,
} from './owner-settings-types';
import { CREATE_INVITATION_PROPERTY_OWNER_MUTATION } from '../admin/admin-queries';
import { AccountSubCategory } from '@8baselabs/resident-io-shared/src/schema-types';
import { urlRegEx } from "../../shared/constants/validation";

/**
 * @description - Fetch all client user portfolios.
 * @returns {PropertyPortfolioListResponse}.
 */
export const getPropertyPortfolios = createAction(
  OnFetchPortfolios,
  OnFetchPortfoliosError,
  async ({
    clientUserId,
    skip,
    toShow,
  }: {
    clientUserId: string;
    skip: number;
    toShow: number;
  }) => {
    const response = await client.query<Query>({
      query: GET_CLIENT_USER_PORTFOLIOS,
      fetchPolicy: 'no-cache',
      variables: { clientUserId, skip, toShow },
    });

    return response.data.propertyPortfoliosList;
  },
);

/**
 * @description - Create portfolio.
 * @returns {PropertyPortfolioListResponse}.
 */
export const createPropertyPortfolio = createAction(
  OnCreatePortfolio,
  OnCreatePortfolioError,
  async ({
    userId,
    name,
    propertyIds,
  }: {
    userId: string | undefined;
    name: string;
    propertyIds: string[] | undefined;
  }) => {
    if (!userId || !propertyIds) return;
    const data = {
      name,
      clientUserPropertyPortfolioRelation: {
        connect: {
          id: userId,
        },
      },
      property: {
        connect: propertyIds.map((pid) => ({ id: pid })),
      },
    };

    const res = await client.mutate<Mutation>({
      mutation: CREATE_PORTFOLIO,
      fetchPolicy: 'no-cache',
      variables: { data },
    });

    // eslint-disable-next-line consistent-return
    return res.data?.propertyPortfolioCreate;
  },
);

/**
 * @description - Delete portfolio.
 * @returns {PropertyPortfolioListResponse}.
 */
export const deletePropertyPortfolio = createAction(
  OnDeletePortfolio,
  OnDeletePortfolioError,
  async (portfolioId: string | undefined) => {
    const response = await client.mutate<Mutation>({
      mutation: DELETE_PORTFOLIO,
      fetchPolicy: 'no-cache',
      variables: { portfolioId },
    });

    return response.data?.propertyPortfolioDelete
      ?.success as unknown as SuccessResponse;
  },
);

/**
 * @description - Update portfolio.
 * @returns {PropertyPortfolioListResponse}.
 */
export const updatePropertyPortfolio = createAction(
  OnEditPortfolio,
  OnEditPortfolioError,
  async ({
    portfolioId,
    name,
    propertiesIds,
  }: {
    portfolioId: string | undefined;
    name: string;
    propertiesIds: string[] | undefined;
  }) => {
    const dataToUpdate: PropertyPortfolioUpdateInput = {
      name,
      property: {
        reconnect: propertiesIds?.map((pid) => ({ id: pid })) || [],
      },
    };

    const res = await client.mutate<Mutation>({
      mutation: UPDATE_PORTFOLIO,
      fetchPolicy: 'no-cache',
      variables: { portfolioId, dataToUpdate },
    });

    return res.data?.propertyPortfolioUpdate;
  },
);

/**
 * Fetch all existing fees.
 */
export const fetchClient = createAction(
  OnFetchClient,
  OnFetchClientError,
  async (id) =>
    (
      await client.query<Query>({
        query: FETCH_CLIENT_QUERY,
        variables: { id },
      })
    ).data.client as Client,
);
/**
 * Update client information.
 */
export const updateClient = createAction(
  OnUpdateClient,
  OnUpdateClientError,
  async (data: ClientUpdateInput) => {
    const validationSchema = yup.object({
      name: yup
        .string()
        .matches(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]{2,25}$/,
          'This value must be a valid client name',
        )
        .required('This value is required')
        .nullable(),
      tradeName: yup
        .string()
        .matches(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]{2,25}$/,
          'This value must be a valid trade name',
        )
        .required('This value is required')
        .nullable(),
      addressLine1: yup.string().required('This value is required').nullable(),
      addressLine2: yup.string().nullable(),
      city: yup
        .string()
        .matches(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]{2,25}$/,
          'This value must be a valid city',
        )
        .required('This value is required')
        .nullable(),
      state: yup.string().required('This value is required'),
      zipCode: yup.string().required('This value is required').nullable(),
      eINNumber: yup
        .string()
        .matches(/^[1-9]\d?-\d{7}$/, 'This value must be a valid EIN number')
        .required('This value is required')
        .nullable(),
      mainContactName: yup
        .string()
        .matches(
          /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]{2,25}$/,
          'This value must be a valid name',
        )
        .required('This value is required')
        .nullable(),
      mainContactNumber: yup
        .string()
        .matches(
          /^\+([0-9]+ |[0-9]+)(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
          'This value must be a valid US phone number',
        )
        .required('This value is required')
        .nullable(),
      mainContactEmail: yup
        .string()
        .email('This value must be a valid email')
        .required('This value is required')
        .nullable(),
    });
    await validationSchema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
    });

    const { data: response } = await client.mutate<{
      updatedClientList: ClientListResponse;
    }>({
      mutation: UPDATE_CLIENT_MUTATION,
      variables: { data },
      refetchQueries: [
        { query: FETCH_CLIENT_QUERY, variables: { id: data.id } },
      ],
      awaitRefetchQueries: true,
    });

    return response?.updatedClientList as Client;
  },
);

/**
 * @description - Create manager invitation.
 * @returns {InvitationPropertyManager}.
 */
export const createManagerInvitation = createAction(
  OnCreateManagerInvitation,
  OnCreateManagerInvitationError,
  async (
    formData: Omit<ManageUsersFormType, ManageUsersFormFields.ROLE>,
    clientId: string | undefined,
    userId: string | undefined,
  ) => {
    const data: InvitationPropertyManagerCreateInput = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      client: {
        connect: {
          id: clientId,
        },
      },

      propertyInvitationPropertyManager: {
        create: formData.properties.map((id) => ({
          property: { connect: { id } },
        })),
      },
      inviter: {
        connect: {
          id: userId,
        },
      },
    };

    const res = await client.mutate<Mutation>({
      mutation: CREATE_MANAGER_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data },
    });

    return res.data?.invitationPropertyManagerCreate;
  },
);

/**
 * @description - Create maintenance invitation.
 * @returns {InvitationMaintenance}.
 */
export const createMaintenanceInvitation = createAction(
  OnCreateMaintenanceInvitation,
  OnCreateMaintenanceInvitationError,
  async (
    formData: Omit<ManageUsersFormType, ManageUsersFormFields.ROLE>,
    clientId: string | undefined,
    userId: string | undefined,
  ) => {
    const data: InvitationMaintenanceCreateInput = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      client: {
        connect: {
          id: clientId,
        },
      },

      propertyInvitationMaintenance: {
        create: formData.properties.map((id) => ({
          property: { connect: { id } },
        })),
      },
      inviter: {
        connect: {
          id: userId,
        },
      },
    };

    const res = await client.mutate<Mutation>({
      mutation: CREATE_MAINTENANCE_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data },
    });

    return res.data?.invitationMaintenanceCreate;
  },
);

/**
 * @description - Create maintenance invitation.
 * @returns {InvitationMaintenance}.
 */
export const createTeamMemberInvitation = createAction(
  OnCreateTeamMemberInvitation,
  OnCreateTeamMemberInvitationError,
  async (
    formData: Omit<ManageUsersFormType, ManageUsersFormFields.ROLE>,
    clientId: string | undefined,
    userId: string | undefined,
  ) => {
    const data: InvitationTeamMemberCreateInput = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      client: {
        connect: {
          id: clientId,
        },
      },
      propertyInvitationTeamMember: {
        create: formData.properties.map((id) => ({
          property: { connect: { id } },
        })),
      },
      inviter: {
        connect: {
          id: userId,
        },
      },
    };

    const res = await client.mutate<Mutation>({
      mutation: CREATE_TEAM_MEMBER_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data },
    });

    return res.data?.invitationTeamMemberCreate;
  },
);

/**
 * @description - Change invitation role.
 * @returns {void}.
 */
export const changeClientInvitationRole = createAction(
  OnUpdateClientInvitations,
  OnUpdateClientInvitationsError,
  async ({
    currentInvitationData,
    newManagerInvitation,
    newOwnerInvitation,
  }: {
    currentInvitationData: CurrentInvitationData;
    newManagerInvitation: InvitationPropertyManagerCreateInput | null;
    newOwnerInvitation: InvitationPropertyOwnerCreateInput | null;
  }) => {
    const data = {
      currentInvitationData,
      newManagerInvitation,
      newOwnerInvitation,
    };
    await client.mutate<Mutation>({
      mutation: CHANGE_CLIENT_INVITATION_ROLE,
      variables: {
        data,
      },
    });
  },
);

/**
 * @description - Property owner invitation.
 * @returns {InvitationPropertyOwner}.
 */
export const createPropertyOwnerInvitation = createAction(
  OnCreatePropertyOwnerInvitation,
  OnCreatePropertyOwnerInvitationError,
  async (data: InvitationPropertyOwnerCreateInput) => {
    const res = await client.mutate<Mutation>({
      mutation: CREATE_INVITATION_PROPERTY_OWNER_MUTATION,
      variables: { data },
    });

    return res.data?.invitationPropertyOwnerCreate;
  },
);

/**
 * @description - Get client property managers and invitations.
 * @returns {ClientUserListResponse & InvitationPropertyManagerListResponse}.
 */
export const getClientsAndInvitations = createAction(
  OnGetManagersAndInvitations,
  OnGetManagersAndInvitationsError,
  async (clientId: string | undefined, inviterId: string | undefined) => {
    if (!clientId || !inviterId) return undefined;
    const res = await client.query<Query>({
      query: GET_CLIENT_USERS_AND_INVITATIONS,
      fetchPolicy: 'no-cache',
      variables: { clientId, inviterId },
    });

    return {
      managerInvitations: res.data?.invitationPropertyManagersList,
      clientUsers: res.data?.clientUsersList,
      ownerInvitations: res.data?.invitationPropertyOwnersList,
      maintenanceInvitations: res.data?.invitationMaintenancesList,
      teamMemberInvitations: res.data?.invitationTeamMembersList,
    };
  },
);

/**
 * @description - Delete invitation property manager.
 * @returns {SuccessResponse}.
 */
export const deleteInvitationPropertyManager = createAction(
  OnDeletePropertyManagerInvitation,
  OnDeletePropertyManagerInvitationError,
  async (id: string) => {
    const res = await client.mutate<Mutation>({
      mutation: DELETE_INVITATION_PROPERTY_MANAGER,
      variables: { id },
    });

    return res.data?.invitationPropertyManagerDelete;
  },
);

/**
 * @description - Delete invitation property owner.
 * @returns {SuccessResponse}.
 */
export const deleteInvitationPropertyOwner = createAction(
  OnDeletePropertyOwnerInvitation,
  OnDeletePropertyOwnerInvitationError,
  async (id: string) => {
    const res = await client.mutate<Mutation>({
      mutation: DELETE_INVITATION_PROPERTY_OWNER,
      variables: { id },
    });

    return res.data?.invitationPropertyOwnerDelete;
  },
);

/**
 * @description - Delete client user.
 * @returns {SuccessResponse}.
 */
export const deleteClientUser = createAction(
  OnDeleteClientUser,
  OnDeleteClientUserError,
  async (id: string) => {
    const res = await client.mutate<Mutation>({
      mutation: DELETE_CLIENT_USER,
      fetchPolicy: 'no-cache',
      variables: { id },
    });

    return res.data?.clientUserDelete;
  },
);

/**
 * @description - Edit property manager invitation.
 * @returns {InvitationPropertyManager}.
 */
export const editManagerInvitation = createAction(
  OnEditPropertyManagerInvitation,
  OnEditPropertyManagerInvitationError,
  async (
    id: string,
    data: InvitationPropertyManagerUpdateInput,
    propertiesToDisconnect: string[],
  ) => {
    if (propertiesToDisconnect?.length) {
      const disconnectData: PropertyInvitationPropertyManagerFilter = {
        OR: propertiesToDisconnect.map((pid) => ({
          property: {
            id: {
              equals: pid,
            },
          },
        })),
      };

      await client.mutate<Mutation>({
        mutation: DELETE_PROPERTY_INVITATION_MANAGER,
        variables: {
          data: disconnectData,
        },
      });
    }

    const res = await client.mutate<Mutation>({
      mutation: EDIT_PROPERTY_MANAGER_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data, id },
    });

    return res.data?.invitationPropertyManagerUpdate;
  },
);

/**
 * @description - Edit maintenance invitation.
 * @returns {InvitationMaintenance}.
 */
export const editMaintenanceInvitation = createAction(
  OnEditMaintenanceInvitation,
  OnEditMaintenanceInvitationError,
  async (
    id: string,
    data: InvitationMaintenanceUpdateInput,
    propertiesToDisconnect: string[],
  ) => {
    if (propertiesToDisconnect?.length) {
      const disconnectData: PropertyInvitationMaintenanceFilter = {
        OR: propertiesToDisconnect.map((pid) => ({
          property: {
            id: {
              equals: pid,
            },
          },
        })),
      };

      await client.mutate<Mutation>({
        mutation: DELETE_PROPERTY_INVITATION_MAINTENANCE,
        variables: {
          data: disconnectData,
        },
      });
    }

    const res = await client.mutate<Mutation>({
      mutation: EDIT_MAINTENANCE_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data, id },
    });

    return res.data?.invitationMaintenanceUpdate;
  },
);

/**
 * @description - Edit team member invitation.
 * @returns {InvitationTeamMember}.
 */
export const editTeamMemberInvitation = createAction(
  OnEditTeamMemberInvitation,
  OnEditTeamMemberInvitationError,
  async (
    id: string,
    data: InvitationTeamMemberUpdateInput,
    propertiesToDisconnect: string[],
  ) => {
    if (propertiesToDisconnect?.length) {
      const disconnectData: PropertyInvitationTeamMemberFilter = {
        OR: propertiesToDisconnect.map((pid) => ({
          property: {
            id: {
              equals: pid,
            },
          },
        })),
      };

      await client.mutate<Mutation>({
        mutation: DELETE_PROPERTY_INVITATION_TEAM_MEMBER,
        variables: {
          data: disconnectData,
        },
      });
    }

    const res = await client.mutate<Mutation>({
      mutation: EDIT_TEAM_MEMBER_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data, id },
    });

    return res.data?.invitationTeamMemberUpdate ?? undefined;
  },
);

/**
 * @description - Edit property owner invitation.
 * @returns {InvitationPropertyOwnerUpdateInput}.
 */
export const editPropertyOwnerInvitation = createAction(
  OnEditPropertyOwnerInvitation,
  OnEditPropertyOwnerInvitationError,
  async (id: string, data: InvitationPropertyOwnerUpdateInput) => {
    const res = await client.mutate<Mutation>({
      mutation: EDIT_PROPERTY_OWNER_INVITATION,
      fetchPolicy: 'no-cache',
      variables: { data, id },
    });

    return res.data?.invitationPropertyManagerUpdate;
  },
);

/**
 * @description - Edit client user.
 * @returns {ClientUser}.
 */
export const editClientUser = createAction(
  OnEditPropertyManager,
  OnEditPropertyManagerError,
  async (id: string, data: ClientUserUpdateInput) => {
    const res = await client.mutate<Mutation>({
      mutation: EDIT_CLIENT_USER,
      fetchPolicy: 'no-cache',
      variables: { data, id },
    });

    return res.data?.clientUserUpdate;
  },
);

export const getAccountsAction = createAction(
  OngetAccountsAction,
  OngetAccountsActionError,
  async (id): Promise<AccountListResponse> => {
    const { accountsList } = (
      await client.query({
        query: GET_ACCOUNTS_QUERY,
        variables: { clientId: id },
      })
    ).data;

    return accountsList as AccountListResponse;
  },
);

export const getDefaultAccounts = createAction(
  OnGetDefaultAccountsEvent,
  OnGetDefaultAccountsError,
  async (id): Promise<DefaultAccountListResponse> => {
    const { defaultAccountsList } = (
      await client.query({
        query: GET_DEFAULT_ACCOUNTS_QUERY,
        variables: { clientId: id },
      })
    ).data;

    //await apolloClient.cache.reset();
    return defaultAccountsList as DefaultAccountListResponse;
  },
);

export const getDefaultAccountsBySubcategory = createAction(
  OnGetDefaultAccountsEvent,
  OnGetDefaultAccountsError,
  async (
    subcategory: AccountSubCategory['name'],
  ): Promise<DefaultAccountListResponse> => {
    const { defaultAccountsList } = (
      await client.query({
        query: GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORY,
        variables: { subcategory: subcategory },
      })
    ).data;

    return defaultAccountsList as DefaultAccountListResponse;
  },
);

export const getDefaultAccountsBySubcategories = createAction(
  OnGetDefaultAccountsEvent,
  OnGetDefaultAccountsError,
  async (
    subcategories: Array<AccountSubCategory['name']>,
  ): Promise<DefaultAccountListResponse> => {
    const filter = subcategories.map((subcategory) => ({
      accountSubcategory: {
        name: {
          equals: subcategory,
        },
      },
    }));
    const response = await client.query<{
      defaultAccountsList: DefaultAccountListResponse;
    }>({
      query: GET_DEFAULT_ACCOUNTS_QUERY_BY_SUBCATEGORIES,
      variables: { filter },
    });

    //await client.cache.reset();
    return response.data.defaultAccountsList as DefaultAccountListResponse;
  },
);

export const toggleAccount = createAction(
  OnToggleAccount,
  OnToggleAccountError,
  async (id: string, isActive: boolean): Promise<Account> => {
    const response = await apolloClient.mutate({
      mutation: TOGGLE_ACCOUNT,
      variables: { id, isActive },
    });

    //await apolloClient.cache.reset();

    return response.data.accountUpdate as Account;
  },
);

export const fetchClientPage = createAction(
  OnFetchClientPage,
  OnFetchClientPageError,
  async (id: string) => {
    const response = (
      await client.query<{
        clientPagesList: ClientPageListResponse;
      }>({
        query: FETCH_CLIENT_PAGE_QUERY,
        variables: {
          id,
        },
      })
    ).data;

    return response?.clientPagesList?.items?.[0];
  },
);

export const updateClientPage = createAction(
  OnUpdateClientPage,
  OnUpdateClientPageError,
  async ({
    id,
    prevImages,
    headerImage,
    siteLogo,
    city,
    officeHours,
    ...rest
  }) => {
    const validationSchema = yup.object({
      address: yup.string().notRequired(),
      address2: yup.string().nullable(),
      city: yup.string().notRequired(),
      // city: yup.string().required('This field is required'),
      // state: yup.string().required('This field is required'),
      state: yup.string().notRequired(),
      // zipCode: yup.string().required('This field is required'),
      zipCode: yup.string().notRequired(),
      webUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .required('This field is required'),
      email: yup.string().email('Enter a valid email').nullable(),
      managerLastName: yup.string().nullable(),
      managerFirstName: yup.string().nullable(),
      officeHours: yup
        .number()
        .positive('the number must be positive')
        .nullable(),
      aboutYourLocation: yup.string().nullable(),
      twitterUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .nullable(),
      facebookUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .nullable(),
      instagramUrl: yup
        .string()
        .matches(urlRegEx, 'The field should be a url')
        .nullable(),
      instagramAt: yup.string().nullable(),
      instagramHashtag: yup.string().nullable(),
      urlSubdomain: yup.string().nullable(),
      siteDescription: yup.string().nullable(),
      metaDescription: yup.string().nullable(),
    });

   const res =  await validationSchema.validate(
      { city, ...rest },
      {
        abortEarly: false,
        stripUnknown: true,
      },
    );
    console.log({res});

    // if headerImage === null -> Delete image

    // else if headerImage !== prevImages.headerImage.fileId -> Create or update the image

    // nothing -> do nothing

    let headerImageOperation;

    if (headerImage === null) {
      headerImageOperation = null;
    } else if (headerImage?.fileId !== prevImages?.headerImage?.fileId) {
      headerImageOperation = {
        create: {
          fileId: headerImage?.fileId,
          filename: headerImage?.filename,
          public: true,
        },
      };
    }

    // if siteLogo === null -> Delete image

    // else if siteLogo !== prevImages.siteLogo.fileId -> Create or update the image

    // nothing -> do nothing

    let siteLogoOperation;

    if (siteLogo === null) {
      siteLogoOperation = null;
    } else if (siteLogo?.fileId !== prevImages?.siteLogo?.fileId) {
      siteLogoOperation = {
        create: {
          fileId: siteLogo?.fileId,
          filename: siteLogo?.filename,
          public: true,
        },
      };
    }

    const data = {
      id,
      headerImage: headerImageOperation || null,
      siteLogo: siteLogoOperation || null,
      officeHours: Number(officeHours),
      city,
      ...rest,
    };
    const { data: response } = await client.mutate({
      mutation: UPDATE_CLIENT_PAGE_MUTATION,
      variables: {
        data,
      },
    });

    console.log({response});

    //await apolloClient.cache.reset();

    return response;
  },
);

export const resendInvitation = createAction(
  OnCreateManagerInvitation,
  OnCreateManagerInvitationError,
  async (
    formData: Omit<ManageUsersFormType, ManageUsersFormFields.ROLE>,
    clientId: string | undefined,
    userId: string | undefined,
    inviteId: string,
    clientUserType: string,
  ) => {

    const res = await client.mutate<Mutation>({
      mutation: RESEND_INVITATION,
      fetchPolicy: 'no-cache',
      variables: {
        email: formData.email,
        clientId,
        inviter: userId,
        id: inviteId,
        clientUserType: clientUserType,
      },
    });

    return res.data?.invitationPropertyManagerCreate;
  },
);
