import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { usePageVisibility, usePageActivity } from '../../shared/hooks';

import {
  AUTH_CLIENT_ID,
  AUTH_CLIENT_DOMAIN,
  AUTH_CLIENT_REDIRECT_URI,
} from '../../shared/constants';
import {
  addToMainWindowsList,
  removeFromMainWindowsList,
  addToActiveWindows,
  getActiveWindowsList,
  removeFromActiveWindows,
} from './auth-utils';
import { useLocalStorage } from '../../shared/components/components-hooks';
import { useSubscription } from '@8baselabs/react-simple-state';
import { OnFetchSession } from '../session/session-events';

export type AppState = {
  returnTo?: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

/**
 * @param {object} props -  Props.
 * @param {JSX.Element} props.children -  Props.
 * @returns {JSX.Element} Auth0ProviderWithHistory.
 */
export function Auth0ProviderWithHistory({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState | undefined): void => {
    setTimeout(() => {
      if (appState?.returnTo) {
        history.push(appState.returnTo);
      }
    }, 1);
  };

  //Create a windows ID for each windows that is oppened
  const currentWindowId = React.useRef(Date.now().toString());

  const [timeout, setTimeoutValue] = React.useState(900000); // Timeout default = 15 minutes

  useSubscription(OnFetchSession, (res) => {
    
    const TIMEOUT = parseInt(
    res?.systemConfigsList?.items?.filter(
      (sys) => sys.name === 'INACTIVITY_MILLISECONDS_LOGOUT_EXPIRY',
    )?.[0]?.value as string,
    );

    if (TIMEOUT) {
      setTimeoutValue(TIMEOUT);
    }

  });

  React.useLayoutEffect(() => {
    addToMainWindowsList(currentWindowId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**************************************************************
   ** Remove this window from current ones on close or refresh **
   **************************************************************/

  React.useEffect(() => {
    const onUnload = (): void => {
      removeFromMainWindowsList(currentWindowId.current);
    };

    window.addEventListener('beforeunload', onUnload);

    return () => {
      document.removeEventListener('beforeunload', onUnload);
    };
  });

  const isVisible = usePageVisibility(); // Whether or not the window is active (focused)
  const pulse = usePageActivity(timeout); // Pulse is activities made by the user on the window

  const logoutTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);
  const activityTimeoutRef = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const startLogoutTimer = React.useCallback(() => {
    logoutTimeoutRef.current = setTimeout(() => {
      const activeWindowsList = getActiveWindowsList();
      if (activeWindowsList.length < 1) {
        localStorage.loggedIn = 'false';
        history.push('/logout');
      }
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  // Timer to check if we reached the timeout of inactivity
  const startInactivityTimer = React.useCallback(() => {
    activityTimeoutRef.current = setTimeout(() => {
      const expiredTime = parseInt(localStorage.expiredTime || 0, 10);
      if (expiredTime > 0 && expiredTime < Date.now()) {
        localStorage.loggedIn = 'false'; // Log user out on other tabs
        history.push('/logout');
      }
    }, timeout * 1.1); // Put the timeout 10% after to check if no other window has had any activity later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout]);

  React.useEffect(() => {
    if (!isVisible) {
      removeFromActiveWindows(currentWindowId.current);
      startLogoutTimer();
    } else {
      addToActiveWindows(currentWindowId.current);
      //update activeWindow to current window
      localStorage.activeWindow = currentWindowId.current;
      clearTimeout(logoutTimeoutRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  // Log user out on an active window if they don't interact with it
  React.useEffect(() => {
    clearTimeout(activityTimeoutRef.current);
    startInactivityTimer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pulse]);

  const location = useLocation();
  const search = location.search;

  const query = new URLSearchParams(search);
  const loginHint = query.get('login');
  const registerHint = query.get('register');

  const [, setLoginHint] = useLocalStorage('loginHint', '');
  const [, setRegisterHint] = useLocalStorage('registerHint', '');

  React.useEffect(() => {
    if (loginHint) setLoginHint(loginHint);
    if (registerHint) { 
      if (registerHint === 'true') {
        setRegisterHint('your@email') }
      else {
        setRegisterHint(registerHint);
      }
    }
    if (!loginHint && !registerHint) {
      setLoginHint('');
      setRegisterHint('');
    }
  }, [loginHint, registerHint, setLoginHint, setRegisterHint]);

  return (
    <Auth0Provider
      domain={AUTH_CLIENT_DOMAIN}
      clientId={AUTH_CLIENT_ID}
      redirectUri={AUTH_CLIENT_REDIRECT_URI}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens>
      {children}
    </Auth0Provider>
  );
}
