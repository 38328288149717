/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { differenceInYears, isValid } from 'date-fns';
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { createAction } from '@8baselabs/react-simple-state';
import {
  BedListResponse,
  AvailabilityListResponse,
  BuildingListResponse,
  FloorListResponse,
  Lease,
  LeaseListResponse,
  RoomListResponse,
  UnitListResponse,
  UserListResponse,
  UnitFilter,
  Lease_InvitationResidentCreateInput,
  LeaseUpdateInput,
  AvailabilityFilter,
  LeaseFilter,
  Query,
  OpportunityNotificationCreateInput,
  Mutation,
  OpportunityNotificationUpdateInput,
  OpportunityNotificationFilter,
  MessageTemplateFilter,
  LeaseCreateInput,
  InvitationResidentCreateInput,
  PaymentPlan,
  UserCreateInput,
  User,
  ResidentApplication,
  ResidentApplicationCreateInput,
  ResidentApplicationUpdateInput,
  LeasePeriodListResponse,
  ContractDocumentListResponse,
  ContractDocument,
  ClientUserListResponse,
  ClientUserFilter,
  LeaseUpdateByFilterInput,
  LeaseManyResponse,
  SendLeadInvitationInput,
  SendLeadInvitationResult,
  PaymentPlanItemListResponse,
  PaymentPlanItem,
  RequiredFile,
  PropertyFilter,
  PropertyListResponse,
  SuccessResponse,
  TermsDocumentListResponse,
  TermsDocument,
  Guarantor,
  LeaseGuarantorUpdateRelationInput,
  LeasePeriodFilter,
  InvitationResident,
  InvitationResidentListResponse,
  FormUpdateInput,
  MessageTemplate,
} from '../../schema-types';
import { updateUserAction } from '../profile/my-info/my-info-actions';
import {
  OnGetAvailabilityList,
  OnGetAvailabilityListError,
  OnLeasePeriodList,
  OnLeasePeriodListError,
  OnLeasePeriodRangeDatesList,
  OnLeasePeriodRangeDatesListError,
} from '../settings-view/settings-events';
import { apolloClient } from '../../shared/apollo';
import {
  AddReservationBedsEvent,
  AddReservationBedsEventError,
  AddReservationBuildingsEvent,
  AddReservationBuildingsEventError,
  AddReservationCreateEvent,
  AddReservationCreateEventError,
  AddReservationFetchLeaseEvent,
  AddReservationFetchLeaseEventError,
  AddReservationFloorsEvent,
  AddReservationFloorsEventError,
  AddReservationPaymentPlansEvent,
  AddReservationPaymentPlansEventError,
  AddReservationResidentsEvent,
  AddReservationResidentsEventError,
  AddReservationRoomsEvent,
  AddReservationRoomsEventError,
  AddReservationUnitsEvent,
  AddReservationUnitsEventError,
  AddReservationUpdateEvent,
  AddReservationUpdateEventError,
  AddReservationUpdateResidentApplicationEvent,
  AddReservationUpdateResidentApplicationEventError,
  createInvitationEvent,
  createInvitationEventError,
  getInvitations,
  getInvitationsError,
  getLeaseList,
  getLeaseListError,
  OnCreateOpportunityNotification,
  OnCreateOpportunityNotificationError,
  OnCreateResidentInvitation,
  OnCreateResidentInvitationError,
  OnDeleteOpportunityNotification,
  OnDeleteOpportunityNotificationError,
  OnGetMessageTemplates,
  OnGetMessageTemplatesError,
  OnGetOpportunitiesMetrics,
  OnGetOpportunitiesMetricsError,
  OnGetOpportunitiesNotifications,
  OnGetOpportunitiesNotificationsError,
  OnUpdateOpportunityNotification,
  OnUpdateOpportunityNotificationError,
  OpportunitiesLeaseDataEvent,
  OpportunitiesLeaseDataEventError,
  OpportunitiesLeasesEvent,
  OpportunitiesLeasesEventError,
  SendRemindersEvent,
  SendRemindersEventError,
  AddReservationSelectResidentEvent,
  LeasePeriodBedsAvailableEvent,
  LeasePeriodBedsAvailableError,
  AddReservationContractEvent,
  AddReservationContractEventError,
  getManagersEvent,
  getManagersEventError,
  UpdateManyReservationEventError,
  UpdateManyReservationEvent,
  OpportunitiesLeasesDataEventError,
  OpportunitiesLeasesDataEvent,
  sendLeadInvitationEvent,
  sendLeadInvitationEventError,
  OnGetPaymentPlanItemsByLease,
  OnGetPaymentPlanItemsByLeaseError,
  getRequiredFilesEvent,
  getRequiredFilesEventError,
  deleteRequiredFilesEvent,
  deleteRequiredFilesEventError,
  updateRequiredFileEvent,
  updateRequiredFileEventError,
  createRequiredFileEvent,
  createRequiredFileEventError,
  onGetPropertyRequiredUploadsError,
  onGetPropertyRequiredUploads,
  getRequiredDocumentsEvent,
  getRequiredDocumentsEventError,
  copyPaymentPlanEvent,
  copyPaymentPlanError,
  AddReservationTermsEvent,
  AddReservationTermsEventError,
  OpportunitiesFetchLeasesEventError,
  OpportunitiesFetchLeasesEvent,
  chargeApprovalEvent,
  chargeApprovalError,
  OnUpdateSectionPropertyForm,
  OnUpdateSectionPropertyFormError,
  UpdateApplicationEvent,
  UpdateApplicationEventError,
  SaveResidentApplicationEvent,
  SaveResidentApplicationEventError,
  onResidentFeeStatusEvent,
  onResidentFeeStatusError,
  AddReservationUnitsByGenderEvent,
  AddReservationUnitsByGenderEventError,
} from './opportunities-event';
import {
  CREATE_LEASE_MUTATION,
  GET_BEDS_BY_ROOM,
  GET_BUILDINGS_BY_PROPERTY,
  GET_FLOORS_BY_BUILDING,
  GET_OPPORTUNITIES_UNITS_BY_FILTER,
  GET_LEASES_BY_RESIDENT,
  GET_RESIDENT_USERS,
  GET_ROOMS_BY_UNIT,
  GET_UNITS_BY_FLOOR,
  UPDATE_LEASE_OPPORTUNITIES_MUTATION,
  UPDATE_MANY_LEASE_OPPORTUNITIES_MUTATION,
  GET_PAYMENT_PLANS_BY_BED,
  GET_LEASE_BY_ID,
  FETCH_AVAILABILITY,
  CREATE_INVITATION,
  GET_INVITATIONS_BY_USER_RESIDENT_AND_PROPERTY,
  GET_LEASE_BY_USER_EMAIL_AND_PROPERTY,
  SEND_REMINDERS,
  GET_LEASE_DATA,
  GET_ALL_OPPORTUNITIES_NOTIFICATIONS,
  CREATE_OPPORTUNITY_NOTIFICATION,
  DELETE_OPPORTUNITY_NOTIFICATION,
  UPDATE_OPPORTUNITY_NOTIFICATION,
  GET_MESSAGE_TEMPLATES,
  GET_LEASES_CATEGORIES_METRICS,
  CREATE_LEASE_OPPORTUNITIES,
  INVITE_RESIDENT,
  FETCH_AVAILABILITIES_BY_UNIT,
  CREATE_USER_RESIDENT_MUTATION,
  GET_OPPORTUNITIES_LEASE_PERIOD,
  GET_BEDS_AVAILABLE,
  CONTRACT_DOCUMENT_QUERY,
  GET_MANAGERS,
  GET_LEASES_DATA_FOR_MESSAGE,
  SEND_LEAD_INVITATION,
  GET_PAYMENT_PLAN_ITEMS_BY_LEASE,
  GET_LEASE_PERIODS_BY_PROPERTIES,
  REQUIRED_DOCUMENTS_QUERY,
  CREATE_REQUIRED_FILE,
  GET_USER_REQUIRED_FILES,
  DELETE_REQUIRED_FILE,
  UPDATE_REQUIRED_FILE,
  FETCH_PROPERTY_REQUIRED_UPLOAD,
  COPY_PAYMENT_PLAN,
  TERMS_DOCUMENT_QUERY,
  GET_LEASE_PERIODS_RANGE_DATES_BY_PROPERTIES,
  CHARGE_APPROVAL,
  GET_LEASES_PROPERTY,
  GET_LEASES_AGENT,
  GET_LEASES_RESIDENT,
  GET_LEASES,
  FETCH_RESIDENT_FEE_STATUS,
  GET_UNITS_BY_FLOOR_AND_GENDER,
} from './opportunities-queries';
import { LeaseCategories } from './opportunities-types';
import {
  FileCreateInputType,
  getLeaseFilterByCategory,
  shouldFieldValidate,
} from './opportunities-utils';
import { GET_LEASE_LIST, GET_LEDGERS_BY_FILTER } from '../people/people-query';
import { FileValue } from '@8base-react/file-input';
import { Metrics } from '../timeline/TimelineView';
import { UPDATE_FORM } from '../settings-view/property-documents/property-documents-queries';
import { LeaseStatus } from '@8baselabs/resident-io-shared';
import moment from 'moment/moment';

/**
 * Fetch all resident users.
 */
export const fetchResidentsAction = createAction(
  AddReservationResidentsEvent,
  AddReservationResidentsEventError,
  async (filter: string) => {
    const apollo = apolloClient;

    const {
      data: { usersList },
    } = await apollo.query<{ usersList: UserListResponse }>({
      query: GET_RESIDENT_USERS,
      variables: { filter },
    });

    return [...usersList.items];
  },
);

/**
 * Fetch all buildings by property id.
 */
export const fetchBuildingsAction = createAction(
  AddReservationBuildingsEvent,
  AddReservationBuildingsEventError,
  async (propertyId: string) => {
    const apollo = apolloClient;

    const {
      data: { buildingsList },
    } = await apollo.query<{ buildingsList: BuildingListResponse }>({
      query: GET_BUILDINGS_BY_PROPERTY,
      variables: { propertyId },
    });
    return [...buildingsList.items];
  },
);

/**
 * Fetch all floors by building id.
 */
export const fetchFloorsAction = createAction(
  AddReservationFloorsEvent,
  AddReservationFloorsEventError,
  async (buildingId: string) => {
    const apollo = apolloClient;

    const {
      data: { floorsList },
    } = await apollo.query<{ floorsList: FloorListResponse }>({
      query: GET_FLOORS_BY_BUILDING,
      variables: { buildingId },
    });

    return [...floorsList.items];
  },
);

/**
 * Fetch all units by floor id.
 */
export const fetchUnitsAction = createAction(
  AddReservationUnitsEvent,
  AddReservationUnitsEventError,
  async (floorId: string) => {
    const apollo = apolloClient;

    const {
      data: { unitsList },
    } = await apollo.query<{ unitsList: UnitListResponse }>({
      query: GET_UNITS_BY_FLOOR,
      variables: { floorId },
    });

    return [...unitsList.items];
  },
);

/**
 * Fetch all units matching a gender by floor id.
 */
export const fetchUnitsByGenderAction = createAction(
  AddReservationUnitsByGenderEvent,
  AddReservationUnitsByGenderEventError,
  async (floorId: string, gender: string | undefined, leasePeriodId: string | undefined) => {
    
    if (!gender || !leasePeriodId) {
      return []
    }

    const apollo = apolloClient;

    const {
      data: { unitsList },
    } = await apollo.query<{ unitsList: UnitListResponse }>({
      query: GET_UNITS_BY_FLOOR_AND_GENDER,
      variables: { floorId, gender, leasePeriodId },
    });

    return [...unitsList.items];
  },
);

/**
 * Fetch all units by floor id.
 */
export const fetchUnitsActionByFilter = createAction(
  AddReservationUnitsEvent,
  AddReservationUnitsEventError,
  async (filter: UnitFilter) => {
    const apollo = apolloClient;

    const {
      data: { unitsList },
    } = await apollo.query<{ unitsList: UnitListResponse }>({
      query: GET_OPPORTUNITIES_UNITS_BY_FILTER,
      variables: {
        filter,
      },
    });

    return [...unitsList.items];
  },
);

export const fetchAvailabilitiesByUnit = createAction(
  OnGetAvailabilityList,
  OnGetAvailabilityListError,
  async (building: string, leasePeriod): Promise<AvailabilityListResponse> => {
    const apollo = apolloClient;

    const filter: AvailabilityFilter = {
      leasePeriod: {
        id: {
          equals: leasePeriod,
        },
      },
      bed: {
        room: {
          unit: {
            floor: {
              building: {
                id: {
                  equals: building,
                },
              },
            },
          },
        },
      },
    };

    const {
      data: { availabilitiesList },
    } = await apollo.query<{ availabilitiesList: AvailabilityListResponse }>({
      query: FETCH_AVAILABILITIES_BY_UNIT,
      variables: {
        filter,
      },
    });

    return availabilitiesList;
  },
);

/**
 * Fetch all rooms by unit id.
 */
export const fetchRoomsAction = createAction(
  AddReservationRoomsEvent,
  AddReservationRoomsEventError,
  async (unitId: string) => {
    const apollo = apolloClient;

    const {
      data: { roomsList },
    } = await apollo.query<{ roomsList: RoomListResponse }>({
      query: GET_ROOMS_BY_UNIT,
      variables: { unitId },
    });

    return [...roomsList.items];
  },
);

/**
 * Fetch all beds by room id.
 */
export const fetchBedsAction = createAction(
  AddReservationBedsEvent,
  AddReservationBedsEventError,
  async (roomId: string, leasePeriodId, leaseId = 'none') => {
    const apollo = apolloClient;

    const {
      data: { bedsList },
    } = await apollo.query<{ bedsList: BedListResponse }>({
      query: GET_BEDS_BY_ROOM,
      variables: { roomId, leasePeriodId, leaseId },
    });

    return [...bedsList.items];
  },
);

export const fetchBedsAvailablesAction = createAction(
  LeasePeriodBedsAvailableEvent,
  LeasePeriodBedsAvailableError,
  async (leasePeriodId, propertyId) => {
    const apollo = apolloClient;

    const {
      data: { bedsList },
    } = await apollo.query<{ bedsList: BedListResponse }>({
      query: GET_BEDS_AVAILABLE,
      variables: { leasePeriodId, propertyId },
    });

    let maleCount = 0,
      femaleCount = 0;

    // TODO: define how the OTHER and rest of genders affect this
    bedsList.items.forEach((bed) => {
      const leasePeriodAvailability = bed.bedAvailabilityRelation?.items.find(
        (relation) => {
          return relation.leasePeriod?.id === leasePeriodId;
        },
      );

      if (
        leasePeriodAvailability?.availabilityPaymentPlanRelation?.items.find(
          (paymentPlan) => paymentPlan.type === 'NEW_RESIDENT',
        )
      ) {
        if (
          leasePeriodAvailability?.gender === 'MALE' ||
          leasePeriodAvailability?.gender === 'ANY' ||
          !leasePeriodAvailability?.gender
        )
          maleCount++;
        if (
          leasePeriodAvailability?.gender === 'FEMALE' ||
          leasePeriodAvailability?.gender === 'ANY' ||
          !leasePeriodAvailability?.gender
        )
          femaleCount++;
      }
    });

    return { maleCount, femaleCount, leasePeriod: leasePeriodId };
  },
);

/**
 * Fetch all payment plans by bed id.
 */
export const fetchPaymentPlansByAvailabilityAction = createAction(
  AddReservationPaymentPlansEvent,
  AddReservationPaymentPlansEventError,
  async (bedId: string, leasePeriodId: string, type: string) => {
    const apollo = apolloClient;

    const {
      data: { availabilitiesList },
    } = await apollo.query<{ availabilitiesList: AvailabilityListResponse }>({
      query: GET_PAYMENT_PLANS_BY_BED,
      variables: { bedId, leasePeriodId, type },
    });

    if (!availabilitiesList) return [];

    const availability = availabilitiesList?.items[0];

    if (!availability?.availabilityPaymentPlanRelation?.items) return [];

    const paymentPlans = availability.availabilityPaymentPlanRelation.items.map(
      (aPM) => aPM.paymentPlan as PaymentPlan,
    );

    const paymentPlansFetched = Array.from([...new Set(paymentPlans)]);

    return paymentPlansFetched || [];
  },
);

export const fetchLeasesByProperty = createAction(
  OpportunitiesFetchLeasesEvent,
  OpportunitiesFetchLeasesEventError,
  async (
    propertiesIds: string[],
    startDate,
    endDate,
    leaseCategory = undefined,
    filterState = undefined,
    leasePeriodsSelectedIds = [],
  ) => {
    const filter: LeaseFilter = {
      AND: [
        {
          startDate:
            startDate !== undefined && endDate !== undefined
              ? {
                  gte: startDate,
                  lte: endDate,
                }
              : undefined,
        },
        {
          leasePeriod:
            leasePeriodsSelectedIds.length !== 0
              ? {
                  id: {
                    in: leasePeriodsSelectedIds,
                  },
                }
              : undefined,
        },
        {
          property: {
            id: {
              in: propertiesIds,
            },
          },
        },
      ],
    };

    let page = 0;

    const getPaginatedLeases = async () => {
      const leaseFetch = [
        await apolloClient.query<{ leasesList: LeaseListResponse }>({
          query: GET_LEASES,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
        await apolloClient.query<{
          leasesList: LeaseListResponse;
        }>({
          query: GET_LEASES_RESIDENT,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
        await apolloClient.query<{
          leasesList: LeaseListResponse;
        }>({
          query: GET_LEASES_AGENT,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
        await apolloClient.query<{
          leasesList: LeaseListResponse;
        }>({
          query: GET_LEASES_PROPERTY,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
      ];
      const [baseLeases, leasesResident, leasesAgent, leasesPropertyUnit] =
        await Promise.all(leaseFetch);

      const completeLeasesPromise = baseLeases.data.leasesList.items.map(
        (lease) => {
          const agent = leasesAgent.data.leasesList.items.find(
            (leaseAgent) => leaseAgent.id === lease.id,
          );
          const leaseProperty = leasesPropertyUnit.data.leasesList.items.find(
            (leasePropertyData) => leasePropertyData.id === lease.id,
          );
          const resident = leasesResident.data.leasesList.items.find(
            (leaseResident) => leaseResident.id === lease.id,
          );

          const fullLease = {
            ...lease,
            ...agent,
            ...leaseProperty,
            ...resident,
          };

          return fullLease;
        },
      );

      const completeLeases = await Promise.all(completeLeasesPromise);
      return completeLeases;
    };

    let hasMore = true;
    let leaseAccumulator: Lease[] = [];
    const generateLeases = async () => {
      while (hasMore === true) {
        const leasesPage = await getPaginatedLeases();
        if (!leasesPage.length) {
          hasMore = false;
          return;
        }

        if (hasMore) {
          page++;
          leaseAccumulator = [...leaseAccumulator, ...leasesPage];
        }
      }
    };
    await generateLeases();
    return leaseAccumulator;
  },
);

/**
 * Fetch all leases by property id.
 */
export const fetchLeasesAction = createAction(
  OpportunitiesLeasesEvent,
  OpportunitiesLeasesEventError,
  async (
    propertiesIds: string[],
    startDate,
    endDate,
    leaseCategory = undefined,
    filterState = undefined,
    leasePeriodsSelectedIds = [],
  ) => {
    const orFilter = leaseCategory
      ? getLeaseFilterByCategory(leaseCategory)
      : [];

    const filter: LeaseFilter = filterState
      ? {
          AND: [
            {
              startDate:
                startDate !== undefined && endDate !== undefined
                  ? {
                      gte: startDate,
                      lte: endDate,
                    }
                  : undefined,
            },
            {
              leasePeriod:
                leasePeriodsSelectedIds.length !== 0
                  ? {
                      id: {
                        in: leasePeriodsSelectedIds,
                      },
                    }
                  : undefined,
            },
            {
              OR: leaseCategory ? orFilter : undefined,
            },
            {
              property: {
                id: {
                  in: propertiesIds,
                },
              },
            },
            filterState !== undefined
              ? {
                  property: filterState?.property
                    ? { id: { equals: filterState?.property } }
                    : undefined,
                  leasePeriod: filterState?.leasePeriod
                    ? { id: { equals: filterState?.leasePeriod } }
                    : undefined,
                  unit: filterState?.unit
                    ? { id: { equals: filterState?.unit } }
                    : undefined,
                  bed: filterState?.bed
                    ? { id: { equals: filterState?.bed } }
                    : undefined,
                  room: filterState?.room
                    ? { id: { equals: filterState?.room } }
                    : undefined,
                  floor:
                    (filterState?.building !== undefined &&
                      filterState?.building.length !== 0) ||
                    (filterState?.floor !== undefined &&
                      filterState?.floor.length !== 0)
                      ? {
                          AND: [
                            filterState?.building
                              ? {
                                  building: {
                                    id: { equals: filterState?.building },
                                  },
                                }
                              : {},
                            filterState?.floor
                              ? { id: { equals: filterState?.floor } }
                              : {},
                          ],
                        }
                      : undefined,
                  moveInDate: filterState?.moveIn
                    ? {
                        equals: filterState?.moveIn,
                      }
                    : undefined,
                  moveOutDate: filterState?.moveOut
                    ? {
                        equals: filterState?.moveOut,
                      }
                    : undefined,
                }
              : {},
          ],
        }
      : {
          AND: [
            {
              startDate:
                startDate !== undefined
                  ? {
                      gte: startDate,
                    }
                  : undefined,
              endDate:
                endDate !== undefined
                  ? {
                      lte: endDate,
                    }
                  : undefined,
            },
            {
              property: {
                id: {
                  in: propertiesIds,
                },
              },
            },
            {
              leasePeriod:
                leasePeriodsSelectedIds.length !== 0
                  ? {
                      id: {
                        in: leasePeriodsSelectedIds,
                      },
                    }
                  : undefined,
            },
            {
              OR: leaseCategory ? orFilter : undefined,
            },
          ],
        };

    let page = 0;
    const getPaginatedLeases = async () => {
      const leaseFetch = [
        await apolloClient.query<{ leasesList: LeaseListResponse }>({
          query: GET_LEASES,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
        await apolloClient.query<{
          leasesList: LeaseListResponse;
        }>({
          query: GET_LEASES_RESIDENT,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
        await apolloClient.query<{
          leasesList: LeaseListResponse;
        }>({
          query: GET_LEASES_AGENT,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
        await apolloClient.query<{
          leasesList: LeaseListResponse;
        }>({
          query: GET_LEASES_PROPERTY,
          variables: { filter, first: 100, skip: page ? page * 100 : 0 },
        }),
      ];
      const [baseLeases, leasesResident, leasesAgent, leasesProperty] =
        await Promise.all(leaseFetch);

      const completeLeases = baseLeases.data.leasesList.items.map((lease) => {
        const agent = leasesAgent.data.leasesList.items.find(
          (leaseAgent) => leaseAgent.id === lease.id,
        );
        const property = leasesProperty.data.leasesList.items.find(
          (leaseProperty) => leaseProperty.id === lease.id,
        );
        const resident = leasesResident.data.leasesList.items.find(
          (leaseResident) => leaseResident.id === lease.id,
        );

        const fullLease = { ...lease, ...agent, ...property, ...resident };

        return fullLease;
      });
      return [...completeLeases] as Lease[];
    };

    let hasMore = true;
    let leaseAccumulator: Lease[] = [];
    const generateLeases = async () => {
      while (hasMore === true) {
        const leasesPage = await getPaginatedLeases();
        if (!leasesPage.length) {
          hasMore = false;
          return;
        }

        if (hasMore) {
          page++;
          leaseAccumulator = [...leaseAccumulator, ...leasesPage];
        }
      }
    };
    await generateLeases();
    return leaseAccumulator;
  },
);

/**
 * Get lease categories data for metrics.
 */
export const getLeaseCategoriesMetrics = createAction(
  OnGetOpportunitiesMetrics,
  OnGetOpportunitiesMetricsError,
  async (
    propertiesIds: string[],
    minDate: string,
    maxDate: string,
    leasePeriodsIds: string[],
    type = false,
  ) => {
    if (!propertiesIds) {
      return {
        total: 0,
        open_invitation: 0,
        in_process: 0,
        manager_ready: 0,
        new: 0,
        blocked: 0,
        beds: 0,
        approved: 0,
        all: 0,
      };
    }

    const allFilter: LeaseFilter = {
      AND: [
        {
          property: {
            id: {
              in: propertiesIds,
            },
          },
        },
        {
          leasePeriod: leasePeriodsIds
            ? {
                id: {
                  in: leasePeriodsIds,
                },
              }
            : undefined,
        },
        {
          AND: [
            {
              startDate:
                minDate !== undefined && maxDate !== undefined
                  ? {
                      gte: type === false ? minDate : maxDate,
                    }
                  : undefined,
            },
            {
              endDate:
                minDate !== undefined && maxDate !== undefined
                  ? {
                      lte: type === false ? maxDate : minDate,
                    }
                  : undefined,
            },
          ],
        },
      ],
    };

    const filterGenerator = (category: LeaseCategories): LeaseFilter => {
      const filter: LeaseFilter = {
        property: {
          id: {
            in: propertiesIds,
          },
        },
        leasePeriod: leasePeriodsIds
          ? {
              id: {
                in: leasePeriodsIds,
              },
            }
          : undefined,
        OR: getLeaseFilterByCategory(category),
        AND: [
          {
            startDate:
              minDate !== undefined && maxDate !== undefined
                ? {
                    gte: type === false ? minDate : maxDate,
                  }
                : undefined,
          },
          {
            endDate:
              minDate !== undefined && maxDate !== undefined
                ? {
                    lte: type === false ? maxDate : minDate,
                  }
                : undefined,
          },
        ],
      };
      return filter;
    };

    const openInvitationFilter: LeaseFilter = filterGenerator(
      LeaseCategories.OPEN_INVITATION,
    );

    const inProcessStatusFilter: LeaseFilter = filterGenerator(
      LeaseCategories.IN_PROCESS,
    );

    const inProcessFilter: LeaseFilter = {
      ...inProcessStatusFilter,
    };
    const managerReadyFilter: LeaseFilter = filterGenerator(
      LeaseCategories.MANAGER_READY,
    );

    /* TODO: Fix this filter to be able to set the blocked count with Properties Query,
    and NOT from Timeline itself. makeAvailable is deprecated, and is available from
    bedAvailabiltyRelation the one we should use. */
    const blockedFilter: LeaseFilter = {
      id: {
        in: propertiesIds,
      },
      bed: {
        makeAvailable: {
          equals: false,
        },
      },
    };

    const bedsCount: PropertyFilter = {
      id: {
        in: propertiesIds,
      },
    };

    const newFilter: LeaseFilter = filterGenerator(LeaseCategories.NEW);

    const approvedFilter: LeaseFilter = filterGenerator(
      LeaseCategories.APPROVED,
    );

    const totalFilter: LeaseFilter = filterGenerator(LeaseCategories.TOTAL);

    const response = await apolloClient.query<{
      total: LeaseListResponse | undefined;
      open_invitation: LeaseListResponse | undefined;
      in_process: LeaseListResponse | undefined;
      manager_ready: LeaseListResponse | undefined;
      approved: LeaseListResponse | undefined;
      blocked: LeaseListResponse | undefined;
      beds: PropertyListResponse | undefined;
      new: LeaseListResponse | undefined;
      all: LeaseListResponse | undefined;
    }>({
      query: GET_LEASES_CATEGORIES_METRICS,
      variables: {
        totalFilter,
        openInvitationFilter,
        inProcessFilter,
        managerReadyFilter,
        newFilter,
        bedsCount,
        blockedFilter,
        approvedFilter,
        allFilter,
      },
    });

    let beds = 0;
    let total = 0;
    response.data.beds?.items.forEach((p) => {
      const numLeasePeriods = p.propertyLeasePeriodRelation?.count as number;
      p.buildingLayout?.building?.items.forEach((b) => {
        b.buildingFloorRelation?.items.forEach((f) => {
          f.floorUnitRelation?.items.forEach((u) => {
            u.unitRoomRelation?.items.forEach((r) => {
              total += numLeasePeriods * (r.roomBedRelation?.count as number);
              beds += r.roomBedRelation?.count as number;
            });
          });
        });
      });
    });

    return {
      total: total || 0,
      open_invitation: response.data.open_invitation?.count || 0,
      in_process: response.data.in_process?.count || 0,
      new: response.data.new?.count || 0,
      beds: beds || 0,
      blocked: response.data.blocked?.count || 0,
      manager_ready: response.data.manager_ready?.count || 0,
      approved: response.data.approved?.count || 0,
      all: response.data.all?.count || 0,
    };
  },
);

export const getLeaseCategoriesMetricsByLeasesAndPropertiesIDs = createAction(
  OnGetOpportunitiesMetrics,
  OnGetOpportunitiesMetricsError,
  async (leasesIDs: string[], propertiesIDs?: string[]): Promise<Metrics> => {
    if (!leasesIDs || !propertiesIDs)
      return {
        total: 0,
        open_invitation: 0,
        in_process: 0,
        new: 0,
        beds: 0,
        blocked: 0,
        manager_ready: 0,
        approved: 0,
        all: 0,
      };

    const allFilter: LeaseFilter = {
      id: {
        in: leasesIDs,
      },
    };

    const filterGenerator = (category: LeaseCategories): LeaseFilter => {
      const filter: LeaseFilter = {
        id: {
          in: leasesIDs,
        },
        OR: getLeaseFilterByCategory(category),
      };
      return filter;
    };
    const openInvitationFilter: LeaseFilter = filterGenerator(
      LeaseCategories.OPEN_INVITATION,
    );

    const inProcessFilter: LeaseFilter = filterGenerator(
      LeaseCategories.IN_PROCESS,
    );

    const managerReadyFilter: LeaseFilter = filterGenerator(
      LeaseCategories.MANAGER_READY,
    );

    const blockedFilter: LeaseFilter = {
      bed: {
        id: {
          equals: '',
        },
        makeAvailable: {
          equals: false,
        },
      },
    };

    const bedsCount: PropertyFilter = {
      id: {
        in: propertiesIDs,
      },
    };

    const newFilter: LeaseFilter = filterGenerator(LeaseCategories.NEW);

    const approvedFilter: LeaseFilter = filterGenerator(
      LeaseCategories.APPROVED,
    );

    const totalFilter: LeaseFilter = filterGenerator(LeaseCategories.TOTAL);

    const response = await apolloClient.query<{
      total: LeaseListResponse | undefined;
      open_invitation: LeaseListResponse | undefined;
      in_process: LeaseListResponse | undefined;
      manager_ready: LeaseListResponse | undefined;
      approved: LeaseListResponse | undefined;
      blocked: LeaseListResponse | undefined;
      beds: PropertyListResponse | undefined;
      new: LeaseListResponse | undefined;
      all: LeaseListResponse | undefined;
    }>({
      query: GET_LEASES_CATEGORIES_METRICS,
      variables: {
        totalFilter,
        openInvitationFilter,
        inProcessFilter,
        managerReadyFilter,
        newFilter,
        bedsCount,
        blockedFilter,
        approvedFilter,
        allFilter,
      },
    });

    let beds = 0;
    response.data.beds?.items.forEach((p) => {
      beds += p.beds as number;
    });
    return {
      total: response.data.total?.count || 0,
      open_invitation: response.data.open_invitation?.count || 0,
      in_process: response.data.in_process?.count || 0,
      new: response.data.new?.count || 0,
      beds: beds || 0,
      blocked: response.data.blocked?.count || 0,
      manager_ready: response.data.manager_ready?.count || 0,
      approved: response.data.approved?.count || 0,
      all: response.data.all?.count || 0,
    };
  },
);

/**
 * Get lease data.
 */
export const getLeaseData = createAction(
  OpportunitiesLeaseDataEvent,
  OpportunitiesLeaseDataEventError,
  async (leaseId: string | undefined) => {
    if (!leaseId) return undefined;
    const response = await apolloClient.query<Query>({
      query: GET_LEASE_DATA,
      variables: { id: leaseId },
    });

    return response.data?.lease;
  },
);

/**
 * Get lease data.
 */
export const getLeasesDataForMessage = createAction(
  OpportunitiesLeasesDataEvent,
  OpportunitiesLeasesDataEventError,
  async (leaseIDs: string[] | undefined) => {
    if (!leaseIDs) return undefined;
    const response = await apolloClient.query<Query>({
      query: GET_LEASES_DATA_FOR_MESSAGE,
      variables: { ids: leaseIDs },
    });

    return response.data?.leasesList.items;
  },
);

/**
 * Create Opportunity notification.
 */
export const createOpportunityNotification = createAction(
  OnCreateOpportunityNotification,
  OnCreateOpportunityNotificationError,
  async (data: OpportunityNotificationCreateInput) => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: CREATE_OPPORTUNITY_NOTIFICATION,
      variables: { data },
    });

    return response.data?.opportunityNotificationCreate;
  },
);

/**
 * Delete Opportunity notification.
 */
export const deleteOpportunityNotification = createAction(
  OnDeleteOpportunityNotification,
  OnDeleteOpportunityNotificationError,
  async (id: string) => {
    await apolloClient.mutate<Mutation>({
      mutation: DELETE_OPPORTUNITY_NOTIFICATION,
      variables: { id },
    });

    return id;
  },
);

/**
 * Update Opportunity notification.
 */
export const updateOpportunityNotification = createAction(
  OnUpdateOpportunityNotification,
  OnUpdateOpportunityNotificationError,
  async (id: string | undefined, data: OpportunityNotificationUpdateInput) => {
    if (!id) return undefined;
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_OPPORTUNITY_NOTIFICATION,
      variables: { data, id },
    });

    return response.data?.opportunityNotificationUpdate;
  },
);

/**
 * Get Message templates.
 */
export const getMessageTemplates = createAction(
  OnGetMessageTemplates,
  OnGetMessageTemplatesError,
  async (properties: string[], selectedLeasePeriods = []) => {
    const filter: MessageTemplateFilter = {
      OR: properties.map((id) => ({
        property: {
          id: {
            equals: id,
          },
        },
      })),
      property: {
        propertyLeasePeriodRelation:
          selectedLeasePeriods.length !== 0
            ? {
                some: {
                  id: {
                    in: selectedLeasePeriods,
                  },
                },
              }
            : undefined,
      },
    };
    const response = await apolloClient.query<Query>({
      query: GET_MESSAGE_TEMPLATES,
      variables: {
        filter,
      },
    });

    return response.data?.messageTemplatesList.items as MessageTemplate[];
  },
);

/**
 * Get Opportunities notifications data.
 */
export const getOpportunitiesNotifications = createAction(
  OnGetOpportunitiesNotifications,
  OnGetOpportunitiesNotificationsError,
  async (propertyIds: string[], selectedLeasePeriods = []) => {
    const filter: OpportunityNotificationFilter = {
      opportunitiesNotificationsPropertyRelation: {
        some: {
          property: {
            OR: propertyIds.map((id) => ({
              id: {
                equals: id,
              },
            })),
            propertyLeasePeriodRelation:
              selectedLeasePeriods.length !== 0
                ? {
                    some: {
                      id: {
                        in: selectedLeasePeriods,
                      },
                    },
                  }
                : undefined,
          },
        },
      },
    };

    const response = await apolloClient.query<Query>({
      query: GET_ALL_OPPORTUNITIES_NOTIFICATIONS,
      variables: { filter },
    });

    return response.data?.opportunityNotificationsList.items;
  },
);

/**
 * Send reminders.
 */

export const sendRemindersAction = createAction(
  SendRemindersEvent,
  SendRemindersEventError,
  async (leases: string[]) => {
    await apolloClient.mutate<{ sendReminders: boolean }>({
      mutation: SEND_REMINDERS,
      variables: { leases },
    });
  },
);
/**
 * Fetch all leases by user id.
 */
export const fetchLeasesByUserAction = createAction(
  OpportunitiesLeasesEvent,
  OpportunitiesLeasesEventError,
  async (userId: string) => {
    const apollo = apolloClient;

    const {
      data: { leasesList },
    } = await apollo.query<{ leasesList: LeaseListResponse }>({
      query: GET_LEASES_BY_RESIDENT,
      variables: { userId },
    });

    return [...leasesList.items] as Lease[];
  },
);
/**
 * Fetch all lease by id.
 */
export const fetchLeaseByIdAction = createAction(
  AddReservationFetchLeaseEvent,
  AddReservationFetchLeaseEventError,
  async (leaseId: string) => {
    const apollo = apolloClient;

    const {
      data: { lease },
    } = await apollo.query<{ lease: Lease }>({
      query: GET_LEASE_BY_ID,
      variables: { leaseId },
    });

    return lease;
  },
);

export const sendLeadInvitationAction = createAction(
  sendLeadInvitationEvent,
  sendLeadInvitationEventError,
  async (data: SendLeadInvitationInput) => {
    const { data: responseData } = await apolloClient.mutate<{
      sendLeadInvitation: SendLeadInvitationResult;
    }>({
      mutation: SEND_LEAD_INVITATION,
      variables: {
        data,
      },
    });
    return responseData?.sendLeadInvitation as SendLeadInvitationResult;
  },
);

export const createInvitationAction = createAction(
  createInvitationEvent,
  createInvitationEventError,
  async (data: InvitationResidentCreateInput) => {
    const { data: responseData } = await apolloClient.mutate<{
      invitationCreate: InvitationResident;
    }>({
      mutation: CREATE_INVITATION,
      variables: {
        data,
      },
    });

    return responseData?.invitationCreate as InvitationResident;
  },
);

/**
 * Create a Reservation in PM.
 */
export const createLeaseAction = createAction(
  AddReservationCreateEvent,
  AddReservationCreateEventError,
  async (
    startDate: string,
    endDate: string,
    userId: string,
    leasePeriodId: string,
    propertyId: string,
    floorId: string,
    unitId: string,
    roomId?: string,
    bedId?: string,
    status?: LeaseStatus,
    isRenewal?: boolean,
  ) => {
    const apollo = apolloClient;
    const filter: AvailabilityFilter = {
      bed: {
        id: {
          equals: bedId,
        },
      },
      leasePeriod: {
        id: {
          equals: leasePeriodId,
        },
      },
    };
    const { data: list } = await apollo.mutate<{
      availabilitiesList: AvailabilityListResponse;
    }>({
      mutation: FETCH_AVAILABILITY,
      variables: {
        filter,
      },
    });
    const availabilityId = list?.availabilitiesList?.items?.[0]?.id;

    const { data } = await apollo.mutate<{ leaseCreate: Lease }>({
      mutation: CREATE_LEASE_MUTATION,
      variables: {
        startDate,
        endDate,
        userId,
        availabilityId,
        propertyId,
        floorId,
        unitId,
        roomId,
        status,
        leasePeriodId,
        bedId,
        isRenewal: isRenewal || false,
      },
    });

    return data?.leaseCreate ?? null;
  },
);

export const invitationSchema = yup.object({
  firstName: yup
    .string()
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,25}$/,
      'Please enter correct first name',
    )
    .required('First name is required')
    .nullable(),
  lastName: yup
    .string()
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,25}$/,
      'Please enter correct last name',
    )
    .required('Last name is required')
    .nullable(),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required')
    .nullable(),
  gender: yup.string().required('Gender is required').nullable(),
  phoneNumber: yup
    .string()
    .matches(
      /^\+([0-9]+ |[0-9]+)(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
      'Please enter correct phone number',
    )
    .required('Phone number is required')
    .nullable(),
});

/**
 * Update a Reservation in PM.
 */
export const updateLeaseOpportunitiesAction = createAction(
  AddReservationUpdateEvent,
  AddReservationUpdateEventError,
  async (
    data: LeaseUpdateInput,
    userId?: string | undefined,
    clientId?: string | undefined,
  ) => {
    const response = await apolloClient.mutate<{ leaseUpdate: Lease }>({
      mutation: UPDATE_LEASE_OPPORTUNITIES_MUTATION,
      variables: {
        data,
      },
      refetchQueries: userId
        ? [
            {
              query: GET_LEASE_LIST,
              variables: {
                userId,
                clientId,
              },
            },
            {
              query: GET_LEDGERS_BY_FILTER,
              variables: {
                filter: {
                  lease: {
                    id: { equals: data.id },
                  },
                },
              },
            },
          ]
        : undefined,
      awaitRefetchQueries: userId ? true : undefined,
    });

    return response.data?.leaseUpdate ?? null;
  },
);

export const copyPaymentPlanAction = createAction(
  copyPaymentPlanEvent,
  copyPaymentPlanError,
  async (id: string) =>
    (
      await apolloClient.mutate<Mutation>({
        mutation: COPY_PAYMENT_PLAN,
        variables: {
          id,
        },
      })
    ).data?.ledgerDelete as SuccessResponse,
);

/**
 * Update many Reservations.
 */

export const updateManyReservationsAction = createAction(
  UpdateManyReservationEvent,
  UpdateManyReservationEventError,
  async (data: LeaseUpdateByFilterInput, reservationIds: string[]) => {
    const filter: LeaseFilter = {
      id: {
        in: reservationIds,
      },
    };

    const response = await apolloClient.mutate<{
      leaseResponseList: LeaseManyResponse;
    }>({
      mutation: UPDATE_MANY_LEASE_OPPORTUNITIES_MUTATION,
      variables: {
        data,
        filter,
      },
    });
    return response?.data?.leaseResponseList;
  },
);

/**
 * Get all payment plan items for a particular lease.
 */

export const getPaymentPlanItemsByLease = createAction(
  OnGetPaymentPlanItemsByLease,
  OnGetPaymentPlanItemsByLeaseError,
  async (leaseId: string) => {
    const apollo = apolloClient;

    const { data } = await apollo.query<{
      paymentPlanItemsList: PaymentPlanItemListResponse;
    }>({
      query: GET_PAYMENT_PLAN_ITEMS_BY_LEASE,
      variables: { id: leaseId },
    });

    return data.paymentPlanItemsList.items as PaymentPlanItem[];
  },
);

/**
 * Create a Reservation in PM.
 */
export const createLeaseOpportunitiesAction = createAction(
  AddReservationCreateEvent,
  AddReservationCreateEventError,
  async (
    invitation: Lease_InvitationResidentCreateInput | null,
    userId: string | null,
    inviter: string | undefined,
    leaseId?: string,
  ) => {
    if (invitation) {
      await invitationSchema.validate(
        { ...invitation, phoneNumber: invitation.phoneNumber?.number },
        {
          abortEarly: false,
          stripUnknown: true,
        },
      );

      const createUserData: UserCreateInput = {
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        email: invitation.email as string,
        phoneNumber: invitation.phoneNumber?.number,
        gender: invitation?.gender,
        roles: {
          connect: [
            {
              name: 'User',
            },
          ],
        },
        status: 'active',
      };

      let userData;

      try {
        const { data: userDataR } = await apolloClient.mutate<{
          userCreate: User;
        }>({
          mutation: CREATE_USER_RESIDENT_MUTATION,
          variables: {
            data: createUserData,
          },
        });
        userData = userDataR;
      } catch (error: any) {
        const errorData = JSON.stringify(error, null, 2);
        const emailError = JSON.parse(errorData)?.graphQLErrors?.find(
          (err: any) => err.code === 'ValidationError',
        );
        if (emailError)
          throw new Error(
            'There is already a resident belonging to that email, select it in the search engine',
          );
        else throw new Error('Failed to create Resident');
      }

      if (userData?.userCreate)
        AddReservationSelectResidentEvent.dispatch(userData?.userCreate);

      const createData: LeaseCreateInput = {
        invitationResident: {
          create: {
            ...invitation,
            inviter: {
              connect: {
                id: inviter,
              },
            },
          },
        },
        userResident: {
          connect: {
            id: userData?.userCreate.id,
          },
        },
      };

      if (leaseId) {
        const response = await apolloClient.mutate<{ leaseUpdate: Lease }>({
          mutation: UPDATE_LEASE_OPPORTUNITIES_MUTATION,
          variables: {
            data: {
              ...createData,
              id: leaseId,
            },
          },
        });
        return response?.data?.leaseUpdate || null;
      }

      const { data } = await apolloClient.mutate<{ leaseCreate: Lease }>({
        mutation: CREATE_LEASE_OPPORTUNITIES,
        variables: {
          data: createData,
        },
      });
      return data?.leaseCreate ?? null;
    }

    if (userId === null) {
      throw new Error('You need to select one user!');
    }

    const createData: LeaseCreateInput = {
      userResident: {
        connect: {
          id: userId,
        },
      },
    };

    if (leaseId) {
      const response = await apolloClient.mutate<{ leaseUpdate: Lease }>({
        mutation: UPDATE_LEASE_OPPORTUNITIES_MUTATION,
        variables: {
          data: {
            ...createData,
            id: leaseId,
          },
        },
      });
      return response?.data?.leaseUpdate || null;
    }

    const { data } = await apolloClient.mutate<{ leaseCreate: Lease }>({
      mutation: CREATE_LEASE_OPPORTUNITIES,
      variables: {
        data: createData,
      },
    });
    //await apolloClient.cache.reset();
    return data?.leaseCreate ?? null;
  },
);

/**
 * Create resident invitation.
 */
export const createResidentInvitationAction = createAction(
  OnCreateResidentInvitation,
  OnCreateResidentInvitationError,
  async (data: InvitationResidentCreateInput) => {
    const response = await apolloClient.mutate<Mutation>({
      mutation: INVITE_RESIDENT,
      variables: {
        data,
      },
    });

    return response.data?.invitationResidentCreate;
  },
);

/**
 * Update property form.
 */

export const updatePropertyForm = createAction(
  OnUpdateSectionPropertyForm,
  OnUpdateSectionPropertyFormError,
  async (data: FormUpdateInput, formId: string | undefined) => {
    if (!formId) return undefined;
    const response = await apolloClient.mutate<Mutation>({
      mutation: UPDATE_FORM,
      variables: {
        data,
        formId,
      },
    });

    return response.data?.formUpdate;
  },
);

/**
 * Update a Reservation Application for Lease in PM.
 */
export const updateResidentApplicationLeaseOpportunitiesAction = createAction(
  AddReservationUpdateResidentApplicationEvent,
  AddReservationUpdateResidentApplicationEventError,
  async (
    leaseId: string,
    dataApp: ResidentApplication,
    userUpdate: any,
    guarantorData: Guarantor,
    fields: any,
    category: string,
    isPM = true,
    leaseData = undefined,
    newResidentApplicationData = true,
    newUserData = true,
    justValidate?: boolean,
  ) => {
    const applicationData = { ...dataApp };
    const guarantorInfo = { ...guarantorData };
    if (isPM && userUpdate.__typename) delete userUpdate.__typename;
    // eslint-disable-next-line no-underscore-dangle
    if (guarantorInfo.__typename) delete guarantorInfo.__typename;
    if (guarantorInfo.id) delete guarantorInfo.id;
    if (applicationData.__typename) delete applicationData.__typename;
    if (applicationData.id) delete applicationData.id;
    const validateSchema = {};
    const validateArraySchema = {};
    const validateArrayData = {};

    fields?.map((field) => {
      if (!field.required) return null;
      if (field.category === 'user') return null;
      if (field.category === 'guarantor') return null;
      if (field.conditionField) {
        if (dataApp?.extraData?.[field.conditionField] !== field.condition) {
          return null;
        }
      }
      const dataToMap = dataApp?.extraData?.[field.name]
        ? (dataApp?.extraData?.[field.name] as Array<any>)
        : null;
      switch (field.type) {
        case 'text':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'textarea':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'email':
          if (field.name === 'guarantorEmail') {
            Object.assign(validateSchema, {
              [field.name]: yup
                .string()
                .email(`${field.section} - Enter a valid email`)
                .required(`${field.section} - ${field.label} is required`)
                .test(
                  'is-different-from-applicants-email',
                  'GUARANTOR - Email Cannot be the applicants email',
                  (value) => value !== userUpdate.email,
                )
                .nullable(),
            });
            break;
          }
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .email(`${field.section} - Enter a valid email`)
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'cellphone':
          if (field.name === 'guarantorCellPhone') {
            Object.assign(validateSchema, {
              [field.name]: yup
                .string()
                .matches(
                  /^\+{0,1}([0-9]+ |[0-9]{0,1})(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
                  `${field.section} - Please enter correct phone number`,
                )
                .required(`${field.section} - ${field.label} is required`)
                .test(
                  'is-different-from-applicants-phone-number',
                  'GUARANTOR - Phone Cannot be the applicants phone number',
                  (value) => value !== userUpdate.phoneNumber,
                )
                .nullable(),
            });
            break;
          }
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .matches(
                /^\+{0,1}([0-9]+ |[0-9]{0,1})(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
                `${field.section} - Please enter correct phone number`,
              )
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'dropdown':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'longdropdown':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'yesno':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'longyesno':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'country':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'countryUs':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'stateUs':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'checkbox':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'singlecheckbox':
          Object.assign(validateSchema, {
            [field.name]: yup
              .string()
              .required(`${field.section} - ${field.label} is required`)
              .nullable(),
          });
          break;
        case 'fieldArray':
          field.options?.map((fieldData, idx) => {
            switch (fieldData.type) {
              case 'text': {
                Object.assign(validateArraySchema, {
                  [`${field.name}${idx}`]: yup
                    .string()
                    .required(
                      `${field.section} - ${fieldData.label} is required`,
                    )
                    .nullable(),
                });
                Object.assign(validateArrayData, {
                  [`${field.name}${idx}`]: dataToMap
                    ? dataToMap[0][idx].value
                    : '',
                });
                break;
              }
              case 'emergencyContactType': {
                Object.assign(validateArraySchema, {
                  [`${field.name}${idx}`]: yup
                    .string()
                    .required(
                      `${field.section} - ${fieldData.label} is required`,
                    )
                    .nullable(),
                });
                Object.assign(validateArrayData, {
                  [`${field.name}${idx}`]: dataToMap
                    ? dataToMap[0][idx].value
                    : '',
                });
                break;
              }
              case 'ferpaContactType': {
                Object.assign(validateArraySchema, {
                  [`${field.name}${idx}`]: yup
                    .string()
                    .required(
                      `${field.section} - ${fieldData.label} is required`,
                    )
                    .nullable(),
                });
                Object.assign(validateArrayData, {
                  [`${field.name}${idx}`]: dataToMap
                    ? dataToMap[0][idx].value
                    : '',
                });
                break;
              }
              case 'employmentStatus': {
                Object.assign(validateArraySchema, {
                  [`${field.name}${idx}`]: yup
                    .string()
                    .required(
                      `${field.section} - ${fieldData.label} is required`,
                    )
                    .nullable(),
                });
                Object.assign(validateArrayData, {
                  [`${field.name}${idx}`]: dataToMap
                    ? dataToMap[0][idx].value
                    : '',
                });
                break;
              }
              case 'email': {
                Object.assign(validateArraySchema, {
                  [`${field.name}${idx}`]: yup
                    .string()
                    .email(`${field.section} - Enter a valid email`)
                    .required(
                      `${field.section} - ${fieldData.label} is required`,
                    )
                    .nullable(),
                });
                Object.assign(validateArrayData, {
                  [`${field.name}${idx}`]: dataToMap
                    ? dataToMap[0][idx].value
                    : '',
                });
                break;
              }
              case 'phone': {
                Object.assign(validateArraySchema, {
                  [`${field.name}${idx}`]: yup
                    .string()
                    .matches(
                      /^\+{0,1}([0-9]+ |[0-9]{0,1})(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
                      `${field.section} - Please enter correct phone number`,
                    )
                    .required(
                      `${field.section} - ${fieldData.label} is required`,
                    )
                    .nullable(),
                });
                Object.assign(validateArrayData, {
                  [`${field.name}${idx}`]: dataToMap
                    ? dataToMap[0][idx].value
                    : '',
                });
                break;
              }
            }
            return null;
          });
          break;
        case 'date':
          if (field.name === 'guarantorBirthDate') {
            Object.assign(validateSchema, {
              [field.name]: yup
                .date()
                .max(new Date())
                .required(`${field.section} - ${field.label} is required`)
                .test(
                  'is-valid-date',
                  `${field.section} - You must provide a valid date`,
                  (date) => isValid(date),
                )
                .test(
                  'is-older-than-18',
                  'GUARANTOR - Must be 18 or older in order to register',
                  (value) =>
                    differenceInYears(
                      new Date(),
                      value ? new Date(`${value}`) : new Date(),
                    ) > 17,
                )
                .nullable(),
            });

            break;
          }

          Object.assign(validateSchema, {
            [field.name]: yup
              .date()
              .max(new Date())
              .required(`${field.section} - ${field.label} is required`)
              .test(
                'is-valid-date',
                `${field.section} - You must provide a valid date`,
                (date) => isValid(date),
              )
              .nullable(),
          });

          break;
      }
      return null;
    });

    const updateUserValidation = yup.object({
      firstName: shouldFieldValidate(fields, 'firstName', 'user')
        ? yup.string().required('First name is required').nullable()
        : yup.string().nullable(),
      lastName: shouldFieldValidate(fields, 'lastName', 'user')
        ? yup.string().required('Last name is required').nullable()
        : yup.string().nullable(),
      gender: shouldFieldValidate(fields, 'gender', 'user')
        ? yup.string().required('Gender is required').nullable()
        : yup.string().nullable(),
      genderIdentify: shouldFieldValidate(fields, 'genderIdentify', 'user')
        ? yup.string().required('Gender Identify is required').nullable()
        : yup.string().nullable(),
      preferredName: shouldFieldValidate(fields, 'preferredName', 'user')
        ? yup.string().required('Preferred name is required').nullable()
        : yup.string().nullable(),
      aboutMe: shouldFieldValidate(fields, 'aboutMe', 'user')
        ? yup.string().required('About me is required').max(1000, 'About me should be at most 1000 characters long').nullable()
        : yup.string().nullable(),

      email: shouldFieldValidate(fields, 'email', 'user')
        ? yup
            .string()
            .email('Enter a valid email')
            .required('Email is required')
            .nullable()
        : yup.string().nullable(),
      phoneNumber: shouldFieldValidate(fields, 'phoneNumber', 'user')
        ? yup
            .string()
            .matches(
              /^\+{0,1}([0-9]+ |[0-9]{0,1})(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
              'Please enter correct phone number',
            )
            .required('Cell Phone is required')
            .nullable()
        : yup.string().nullable(),
      birthdate: shouldFieldValidate(fields, 'birthdate', 'user')
        ? yup
            .date()
            .max(new Date())
            .required('Birthday is required')
            .test(
              'is-older-than-14',
              'You must be 14 or older in order to register',
              (value) =>
                differenceInYears(
                  new Date(),
                  value ? new Date(`${value}`) : new Date(),
                ) > 13,
            )
            .test('is-valid-date', 'You must provide a valid date', (date) =>
              isValid(date),
            )
            .nullable()
        : yup.string().nullable(),
    });

    const createGuarantorValidation = yup.object({
      firstName: shouldFieldValidate(fields, 'firstName', 'guarantor')
        ? yup.string().required('Guarantor - First name is required').nullable()
        : yup.string().nullable(),
      lastName: shouldFieldValidate(fields, 'lastName', 'guarantor')
        ? yup.string().required('Guarantor - Last name is required').nullable()
        : yup.string().nullable(),
      birthdate: shouldFieldValidate(fields, 'birthdate', 'guarantor')
        ? yup
            .date()
            .max(new Date())
            .required('Guarantor - Birthday is required')
            .test(
              'is-older-than-18',
              'Guarantor - Must be 18 or older in order to register',
              (value) =>
                differenceInYears(
                  new Date(),
                  value ? new Date(`${value}`) : new Date(),
                ) > 17,
            )
            .test(
              'is-valid-date',
              'Guarantor - You must provide a valid date',
              (date) => isValid(date),
            )
            .nullable()
        : yup.string().nullable(),
      email: shouldFieldValidate(fields, 'email', 'guarantor')
        ? yup
            .string()
            .email('Guarantor - Enter a valid email')
            .required('Guarantor - Email is required')
            .test(
              'is-different-from-applicants-email',
              'Guarantor - Email cannot be the same as the resident',
              (value) => value !== userUpdate.email,
            )
            .nullable()
        : yup.string().nullable(),
      phoneNumber: shouldFieldValidate(fields, 'phoneNumber', 'guarantor')
        ? yup
            .string()
            .matches(
              /^\+{0,1}([0-9]+ |[0-9]{0,1})(\([0-9]{3}\) |[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}$/,
              'Guarantor - Please enter correct phone number',
            )
            .required('Guarantor - Cell Phone is required')
            .nullable()
        : yup.string().nullable(),
      personalGuarantee: shouldFieldValidate(
        fields,
        'personalGuarantee',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - Personal Guarantee is required')
            .nullable()
        : yup.string().nullable().nullable(),
      currentAdressCountry: shouldFieldValidate(
        fields,
        'currentAdressCountry',
        'guarantor',
      )
        ? yup.string().required('Guarantor - Country is required').nullable()
        : yup.string().nullable(),
      currentAdressLine1: shouldFieldValidate(
        fields,
        'currentAdressLine1',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - Street Address 1 is required')
            .nullable()
        : yup.string().nullable(),
      currentAdressLine2: shouldFieldValidate(
        fields,
        'currentAdressLine2',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - Street Address 2 is required')
            .nullable()
        : yup.string().nullable(),
      currentAdresssCity: shouldFieldValidate(
        fields,
        'currentAdresssCity',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - City / Town is required')
            .nullable()
        : yup.string().nullable(),
      currentAdressState: shouldFieldValidate(
        fields,
        'currentAdressState',
        'guarantor',
      )
        ? yup.string().required('Guarantor - State is required').nullable()
        : yup.string().nullable(),
      currentAdressZipCode: shouldFieldValidate(
        fields,
        'currentAdressZipCode',
        'guarantor',
      )
        ? yup.string().required('Guarantor - Zip Code is required').nullable()
        : yup.string().nullable(),
      currentAdressHowLong: shouldFieldValidate(
        fields,
        'currentAdressHowLong',
        'guarantor',
      )
        ? yup
            .string()
            .required(
              'Guarantor - How long have you been living at this address? is required',
            )
            .nullable()
        : yup.string().nullable(),
      currentAdressRentOrOwn: shouldFieldValidate(
        fields,
        'currentAdressRentOrOwn',
        'guarantor',
      )
        ? yup
            .string()
            .required(
              'Guarantor - Do you rent or own this property? is required',
            )
            .nullable()
        : yup.string().nullable(),
      ssn: shouldFieldValidate(fields, 'ssn', 'guarantor')
        ? yup
            .string()
            .required(
              'Guarantor - Do you have a Social Security Number? is required',
            )
            .nullable()
        : yup.string().nullable(),
      country: shouldFieldValidate(fields, 'country', 'guarantor')
        ? yup.string().required('Guarantor - Country is required').nullable()
        : yup.string().nullable(),
      documentType: shouldFieldValidate(fields, 'documentType', 'guarantor')
        ? yup
            .string()
            .required('Guarantor - Document Type is required')
            .nullable()
        : yup.string().nullable(),
      govIssuingId: shouldFieldValidate(fields, 'govIssuingId', 'guarantor')
        ? yup
            .string()
            .required('Guarantor - Government Issued ID is required')
            .nullable()
        : yup.string().nullable(),
      govIdNumber: shouldFieldValidate(fields, 'govIdNumber', 'guarantor')
        ? yup
            .string()
            .required('Guarantor - Government ID Number is required')
            .nullable()
        : yup.string().nullable(),
      primaryIncomeSource: shouldFieldValidate(
        fields,
        'primaryIncomeSource',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - Primary Income Source is required')
            .nullable()
        : yup.string().nullable(),
      termsAck: shouldFieldValidate(fields, 'termsAck', 'guarantor')
        ? yup
            .string()
            .required('Guarantor - Terms and Acknowledgement is required')
            .nullable()
        : yup.string().nullable(),
      checkConsent: shouldFieldValidate(fields, 'checkConsent', 'guarantor')
        ? yup
            .string()
            .required(
              'Guarantor - Background Screening and Credit Check Consent is required',
            )
            .nullable()
        : yup.string().nullable(),
      acknowledgement: shouldFieldValidate(
        fields,
        'acknowledgement',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - Acknowledgement is required')
            .nullable()
        : yup.string().nullable(),
      applicationAck: shouldFieldValidate(fields, 'applicationAck', 'guarantor')
        ? yup
            .string()
            .required('Guarantor - Application is required')
            .nullable()
        : yup.string().nullable(),
      doYouHaveACurrentJob: shouldFieldValidate(
        fields,
        'doYouHaveACurrentJob',
        'guarantor',
      )
        ? yup
            .string()
            .required('Guarantor - Do you have a current job? is required')
            .nullable()
        : yup.string().nullable(),
    });

    if (guarantorData.ssn === 'YES')
      Object.assign(createGuarantorValidation, {
        ssnNumber: yup
          .string()
          .required('Social Security Number is required')
          .nullable(),
      });
    if (guarantorData.ssn === 'NO')
      Object.assign(createGuarantorValidation, {
        otherCountryId: yup
          .string()
          .required('Other Country ID is required')
          .nullable(),
      });

    const validateGuarantorJobsArraySchema = {};
    const validateGuarantorJobsArrayData = {};
    if (guarantorData.doYouHaveACurrentJob === 'YES') {
      const guarantorJobs = guarantorData?.guarantorJobs
        ? (guarantorData.guarantorJobs as unknown as Array<any>)
        : null;
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs0: yup
          .string()
          .required('Guarantor - Current Employer is required')
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs0: guarantorJobs && guarantorJobs[0][0].value,
      });
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs1: yup
          .string()
          .required("Guarantor - Supervisor's Name is required")
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs1: guarantorJobs && guarantorJobs[0][1].value,
      });
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs2: yup
          .string()
          .required('Guarantor - Job Phone is required')
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs2: guarantorJobs && guarantorJobs[0][2].value,
      });
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs3: yup
          .string()
          .required('Guarantor - Job Title is required')
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs3: guarantorJobs && guarantorJobs[0][3].value,
      });
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs4: yup
          .string()
          .required('Guarantor - Date Hired is required')
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs4: guarantorJobs && guarantorJobs[0][4].value,
      });
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs5: yup
          .string()
          .required('Guarantor - Monthly Income is required')
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs5: guarantorJobs && guarantorJobs[0][5].value,
      });
      Object.assign(validateGuarantorJobsArraySchema, {
        guarantorJobs6: yup
          .string()
          .required('Guarantor - Employment Status is required')
          .nullable(),
      });
      Object.assign(validateGuarantorJobsArrayData, {
        guarantorJobs6: guarantorJobs && guarantorJobs[0][6].value,
      });
    }
    if (category !== 'guarantor') {
      await updateUserValidation.validate(userUpdate, {
        abortEarly: false,
        stripUnknown: true,
      });
    }

    const applicationValidate = yup.object(validateSchema);

    await applicationValidate.validate(dataApp.extraData ?? {}, {
      abortEarly: false,
      stripUnknown: true,
    });

    await yup.object(validateArraySchema).validate(validateArrayData ?? {}, {
      abortEarly: false,
      stripUnknown: true,
    });

    if (category !== 'user') {
      await createGuarantorValidation.validate(guarantorData, {
        abortEarly: false,
        stripUnknown: true,
      });

      await yup
        .object(validateGuarantorJobsArraySchema)
        .validate(validateGuarantorJobsArrayData ?? {}, {
          abortEarly: false,
          stripUnknown: true,
        });
    }

    if (justValidate) throw new Error('Just validation executed');

    if (newUserData) {
      await updateUserAction(userUpdate);
    }

    const apollo = apolloClient;

    const getGuarantorData = (): Guarantor | undefined => {
      if (
        fields.filter((field) => field.section === 'GUARANTOR INFORMATION')
          .length !== 0
      ) {
        if (!applicationData.extraData) return {};
        const guarantorCreateData: Guarantor = {
          ...guarantorInfo,
          birthdate: applicationData.extraData.guarantorBirthDate as string,
          phoneNumber: applicationData.extraData.guarantorCellPhone as string,
          email: applicationData.extraData.guarantorEmail as string,
          firstName: applicationData.extraData.guarantorFirstName as string,
          lastName: applicationData.extraData.guarantorLastName as string,
        };
        return guarantorCreateData;
      }
    };

    const dataLease: LeaseUpdateInput = {
      id: leaseId,
      status:
        isPM &&
        leaseData?.status !== LeaseStatus.CONTRACT &&
        leaseData?.status !== LeaseStatus.PAYMENT &&
        leaseData?.status !== LeaseStatus.PAYMENT_FAILED &&
        leaseData?.status !== LeaseStatus.PAYMENT_PROCESSING &&
        leaseData?.status !== LeaseStatus.ON_REVIEW
          ? LeaseStatus.CONTRACT
          : undefined,
      residentApplication: !dataApp?.id
        ? {
            create: { ...(applicationData as ResidentApplicationCreateInput) },
          }
        : {
            update: { ...(applicationData as ResidentApplicationUpdateInput) },
          },
      guarantor:
        getGuarantorData() !== undefined && !guarantorData?.id
          ? ({
              create: {
                ...getGuarantorData(),
                userTenantID: {
                  connect: {
                    id: userUpdate?.id,
                  },
                },

                guarantorId: {
                  create: [
                    {
                      inviter: { connect: { id: userUpdate?.id } },
                      email: getGuarantorData()?.email,
                      reservationId: leaseId,
                    },
                  ],
                },
              },
            } as LeaseGuarantorUpdateRelationInput)
          : guarantorData?.id && getGuarantorData() !== undefined
          ? ({
              update: {
                ...getGuarantorData(),
              },
            } as LeaseGuarantorUpdateRelationInput)
          : guarantorData?.id && getGuarantorData() === undefined
          ? ({
              update: {
                ...guarantorInfo,
              },
            } as LeaseGuarantorUpdateRelationInput)
          : undefined,
    };
    if (newResidentApplicationData) {
      const response = await apollo.mutate<{ leaseUpdate: Lease }>({
        mutation: UPDATE_LEASE_OPPORTUNITIES_MUTATION,
        variables: {
          data: dataLease,
        },
      });

      return response.data?.leaseUpdate ?? null;
    } else {
      return null;
    }
  },
);

export const fetchInvitationsByUserResidentAndProperty = createAction(
  getInvitations,
  getInvitationsError,
  async (userId: string, propertyId: string) => {
    const response = await apolloClient.query<{
      invitationResidentsList: InvitationResidentListResponse;
    }>({
      query: GET_INVITATIONS_BY_USER_RESIDENT_AND_PROPERTY,
      variables: {
        userId,
        propertyId,
      },
    });
    return response.data.invitationResidentsList;
  },
);

export const fetchLeaseByEmailAndProperty = createAction(
  getLeaseList,
  getLeaseListError,
  async (email: string, propertyId: string) => {
    const response = await apolloClient.query<{
      leasesList: LeaseListResponse;
    }>({
      query: GET_LEASE_BY_USER_EMAIL_AND_PROPERTY,
      variables: {
        email,
        propertyId,
      },
    });
    return response.data.leasesList;
  },
);

export const getOpportunityLeasesPeriod = createAction(
  OnLeasePeriodList,
  OnLeasePeriodListError,
  async (
    propertyId: string | undefined,
  ): Promise<LeasePeriodListResponse | undefined> => {
    if (!propertyId) return undefined;
    const {
      data: { leasePeriodsList },
    } = await apolloClient.query<{
      leasePeriodsList: LeasePeriodListResponse;
    }>({
      query: GET_OPPORTUNITIES_LEASE_PERIOD,
      variables: {
        propertyId,
      },
    });

    return leasePeriodsList;
  },
);

export const getLeasesPeriodRangeDatesByProperties = createAction(
  OnLeasePeriodRangeDatesList,
  OnLeasePeriodRangeDatesListError,
  async (
    selectedProperties: string[] | undefined,
  ): Promise<LeasePeriodListResponse | undefined> => {
    if (!selectedProperties) return undefined;

    const filter: LeasePeriodFilter = {
      property: {
        id: {
          in: selectedProperties,
        },
      },
    };
    const {
      data: { leasePeriodsList },
    } = await apolloClient.query<{
      leasePeriodsList: LeasePeriodListResponse;
    }>({
      query: GET_LEASE_PERIODS_RANGE_DATES_BY_PROPERTIES,
      variables: {
        filter,
      },
    });

    return leasePeriodsList;
  },
);

export const getLeasesPeriodByProperties = createAction(
  OnLeasePeriodList,
  OnLeasePeriodListError,
  async (
    selectedProperties: string[] | undefined,
  ): Promise<LeasePeriodListResponse | undefined> => {
    if (!selectedProperties) return undefined;

    const filter = {
      OR: selectedProperties.map((propID) => {
        return {
          property: {
            id: {
              equals: propID,
            },
          },
        };
      }),
    };
    const {
      data: { leasePeriodsList },
    } = await apolloClient.query<{
      leasePeriodsList: LeasePeriodListResponse;
    }>({
      query: GET_LEASE_PERIODS_BY_PROPERTIES,
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
    });

    return leasePeriodsList;
  },
);

export const fetchContractDocuments = createAction(
  AddReservationContractEvent,
  AddReservationContractEventError,
  async (leaseId: string) => {
    const apollo = apolloClient;

    const filter = {
      lease: {
        id: {
          equals: leaseId,
        },
      },
    };

    const {
      data: { contractDocumentsList },
    } = await apollo.query<{
      contractDocumentsList: ContractDocumentListResponse;
    }>({
      query: CONTRACT_DOCUMENT_QUERY,
      variables: { filter },
    });

    return (contractDocumentsList?.items as ContractDocument[]) || [];
  },
);

export const fetchTermsDocument = createAction(
  AddReservationTermsEvent,
  AddReservationTermsEventError,
  async (leaseId: string) => {
    const apollo = apolloClient;

    const filter = {
      lease: {
        id: {
          equals: leaseId,
        },
      },
    };

    const {
      data: { termsDocumentsList },
    } = await apollo.query<{
      termsDocumentsList: TermsDocumentListResponse;
    }>({
      query: TERMS_DOCUMENT_QUERY,
      variables: { filter },
    });

    return (termsDocumentsList?.items as TermsDocument[]) || [];
  },
);

/**
 * Fetch active Manager users, filtering by Property ID (if any).
 */

export const fetchManagers = createAction(
  getManagersEvent,
  getManagersEventError,
  async (propertyId: string | undefined) => {
    const apollo = apolloClient;

    if (!propertyId) return null;

    const filter: ClientUserFilter = {
      role: {
        equals: 'PROPERTY_MANAGER',
      },
      active: {
        equals: true,
      },
      property: propertyId
        ? {
            some: {
              id: {
                equals: propertyId,
              },
            },
          }
        : undefined,
    };

    const {
      data: { clientUsersList: propertyManagersList },
    } = await apollo.query<{
      clientUsersList: ClientUserListResponse;
    }>({
      query: GET_MANAGERS,
      variables: { filter },
    });

    return propertyManagersList?.items || [];
  },
);

export const getRequiredDocuments = createAction(
  getRequiredDocumentsEvent,
  getRequiredDocumentsEventError,
  async (propertyId: string | undefined) => {
    if (!propertyId) return [];

    const response = await apolloClient.query({
      query: REQUIRED_DOCUMENTS_QUERY,
      variables: {
        propertyId,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data?.documentTypesList.items || ([] as DocumentType[]);
  },
);

export const createRequiredFile = createAction(
  createRequiredFileEvent,
  createRequiredFileEventError,
  async (input: FileCreateInputType) => {
    const data = {
      file: {
        create: {
          fileId: input.file?.fileId,
          filename: input.file?.filename,
        },
      },
      requiredFileUserRelation: { connect: { id: input.userId } },
      requiredFileDocumentTypeRelation: { connect: { id: input.documentId } },
      requiredFileRequiredUploadRelation: input.requiredUploadId
        ? {
            connect: { id: input.requiredUploadId },
          }
        : undefined,
      type: input.type || 'TENANT',
      userUploadDate: moment().format('YYYY-MM-DD'),
    };

    const filter = { id: input.documentId };

    const mutation = await apolloClient.mutate<{ id: string }>({
      mutation: CREATE_REQUIRED_FILE,
      variables: {
        data,
        filter,
      },
      fetchPolicy: 'no-cache',
    });

    return mutation.data as RequiredFile;
  },
);

export const updateRequiredFile = createAction(
  updateRequiredFileEvent,
  updateRequiredFileEventError,
  async (data: { file: { create: FileValue }; id: string }) => {
    const updateResponse = await apolloClient.mutate({
      mutation: UPDATE_REQUIRED_FILE,
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
    });
    return updateResponse.data.requiredFileUpdate;
  },
);

export const getUserRequiredFiles = createAction(
  getRequiredFilesEvent,
  getRequiredFilesEventError,
  async (userId: string) => {
    let idPredicate;

    if (userId !== '') {
      idPredicate = {
        equals: userId,
      };
    }

    const response = await apolloClient.query({
      query: GET_USER_REQUIRED_FILES,
      variables: {
        idPredicate,
      },
      fetchPolicy: 'no-cache',
    });

    return response.data?.requiredFilesList?.items;
  },
);

export const deleteRequiredFile = createAction(
  deleteRequiredFilesEvent,
  deleteRequiredFilesEventError,
  async (id: string) => {
    const data = {
      id,
    };
    const response = await apolloClient.mutate({
      mutation: DELETE_REQUIRED_FILE,
      variables: {
        data,
      },
    });

    return response.data;
  },
);

export const getPropertyRequiredUploads = createAction(
  onGetPropertyRequiredUploads,
  onGetPropertyRequiredUploadsError,
  async (propertyId: string | undefined) => {
    if (!propertyId) return undefined;

    const response = await apolloClient.query({
      query: FETCH_PROPERTY_REQUIRED_UPLOAD,
      variables: {
        propertyId,
      },
    });

    return response.data?.documentTypesList.items;
  },
);

export const chargeApprovalAction = createAction(
  chargeApprovalEvent,
  chargeApprovalError,
  async ({
    amount,
    stripeCustomerId,
    stripeAccountId,
    paymentMethodId,
    leaseId,
    ledgerSubCategory,
    ledgerDescription,
    clientId,
    payer,
  }) => {
    const mutation = await apolloClient.mutate<Mutation>({
      mutation: CHARGE_APPROVAL,
      variables: {
        amount: amount * 100 || 0,
        stripeCustomerId: stripeCustomerId || '',
        stripeAccountId: stripeAccountId || '',
        paymentMethodId: paymentMethodId || '',
        leaseId: leaseId || '',
        ledgerSubCategory: ledgerSubCategory || '',
        ledgerDescription: ledgerDescription || '',
        clientId: clientId || '',
        payer: payer || 'PROPERTY',
      },
    });

    return mutation?.data?.chargeApproval?.chargeApproval || false;
  },
);

export const updateApplicationAction = createAction(
  UpdateApplicationEvent,
  UpdateApplicationEventError,
  async (
    leaseId: string,
    dataApp: ResidentApplication,
    userUpdate: any,
    guarantorData: Guarantor,
    fields: any,
    category: string,
    isPM = true,
    leaseData = undefined,
    newResidentApplicationData = true,
    newUserData = true,
  ) => {
    const applicationData = { ...dataApp };
    const guarantorInfo = { ...guarantorData };
    if (userUpdate.__typename) delete userUpdate.__typename;
    // eslint-disable-next-line no-underscore-dangle
    if (guarantorInfo.__typename) delete guarantorInfo.__typename;
    if (guarantorInfo.id) delete guarantorInfo.id;
    if (applicationData.__typename) delete applicationData.__typename;
    if (applicationData.id) delete applicationData.id;

    if (newUserData) {
      await updateUserAction(userUpdate);
    }

    const apollo = apolloClient;

    const getGuarantorData = (): Guarantor | undefined => {
      if (
        fields.filter((field) => field.section === 'GUARANTOR INFORMATION')
          .length !== 0
      ) {
        if (!applicationData.extraData) return {};
        const guarantorCreateData: Guarantor = {
          ...guarantorInfo,
          birthdate: applicationData.extraData.guarantorBirthDate as string,
          phoneNumber: applicationData.extraData.guarantorCellPhone as string,
          email: applicationData.extraData.guarantorEmail as string,
          firstName: applicationData.extraData.guarantorFirstName as string,
          lastName: applicationData.extraData.guarantorLastName as string,
        };
        return guarantorCreateData;
      }
    };

    const dataLease: LeaseUpdateInput = {
      id: leaseId,
      status:
        isPM &&
        leaseData?.status !== LeaseStatus.CONTRACT &&
        leaseData?.status !== LeaseStatus.PAYMENT &&
        leaseData?.status !== LeaseStatus.PAYMENT_FAILED &&
        leaseData?.status !== LeaseStatus.PAYMENT_PROCESSING &&
        leaseData?.status !== LeaseStatus.ON_REVIEW
          ? LeaseStatus.CONTRACT
          : undefined,
      residentApplication: !dataApp?.id
        ? {
            create: { ...(applicationData as ResidentApplicationCreateInput) },
          }
        : {
            update: { ...(applicationData as ResidentApplicationUpdateInput) },
          },
      guarantor:
        getGuarantorData() !== undefined && !guarantorData?.id
          ? ({
              create: {
                ...getGuarantorData(),
                userTenantID: {
                  connect: {
                    id: userUpdate?.id,
                  },
                },

                guarantorId: {
                  create: [
                    {
                      inviter: { connect: { id: userUpdate?.id } },
                      email: getGuarantorData()?.email,
                      reservationId: leaseId,
                    },
                  ],
                },
              },
            } as LeaseGuarantorUpdateRelationInput)
          : guarantorData?.id && getGuarantorData() !== undefined
          ? ({
              update: {
                ...getGuarantorData(),
              },
            } as LeaseGuarantorUpdateRelationInput)
          : guarantorData?.id && getGuarantorData() === undefined
          ? ({
              update: {
                ...guarantorInfo,
              },
            } as LeaseGuarantorUpdateRelationInput)
          : undefined,
    };
    if (newResidentApplicationData) {
      const response = await apollo.mutate<{ leaseUpdate: Lease }>({
        mutation: UPDATE_LEASE_OPPORTUNITIES_MUTATION,
        variables: {
          data: dataLease,
        },
      });

      return response.data?.leaseUpdate ?? null;
    } else {
      return null;
    }
  },
);

export const saveResidentApplication = createAction(
  SaveResidentApplicationEvent,
  SaveResidentApplicationEventError,
  async (
    leaseId: string,
    dataApp: ResidentApplication,
    userUpdate: any,
    guarantorData: Guarantor,
    fields: any,
    category: string,
    isPM = true,
    leaseData = undefined,
    newResidentApplicationData = true,
    newUserData = true,
    justValidate?: boolean,
  ) => {
    const applicationData = { ...dataApp };
    const guarantorInfo = { ...guarantorData };
    if (isPM && userUpdate.__typename) delete userUpdate.__typename;
    // eslint-disable-next-line no-underscore-dangle
    if (guarantorInfo.__typename) delete guarantorInfo.__typename;
    if (guarantorInfo.id) delete guarantorInfo.id;
    if (applicationData.__typename) delete applicationData.__typename;
    if (applicationData.id) delete applicationData.id;

    if (newUserData) {
      delete userUpdate.__typename;
      await updateUserAction(userUpdate);
    }

    const apollo = apolloClient;

    const getGuarantorData = (): Guarantor | undefined => {
      if (
        fields.filter((field) => field.section === 'GUARANTOR INFORMATION')
          .length !== 0
      ) {
        if (!applicationData.extraData) return {};
        const guarantorCreateData: Guarantor = {
          ...guarantorInfo,
          birthdate: applicationData.extraData.guarantorBirthDate as string,
          phoneNumber: applicationData.extraData.guarantorCellPhone as string,
          email: applicationData.extraData.guarantorEmail as string,
          firstName: applicationData.extraData.guarantorFirstName as string,
          lastName: applicationData.extraData.guarantorLastName as string,
        };
        return guarantorCreateData;
      }
    };

    const dataLease: LeaseUpdateInput = {
      id: leaseId,
      status:
        isPM &&
        leaseData?.status !== LeaseStatus.CONTRACT &&
        leaseData?.status !== LeaseStatus.PAYMENT &&
        leaseData?.status !== LeaseStatus.PAYMENT_FAILED &&
        leaseData?.status !== LeaseStatus.PAYMENT_PROCESSING &&
        leaseData?.status !== LeaseStatus.ON_REVIEW
          ? LeaseStatus.CONTRACT
          : undefined,
      residentApplication: !dataApp?.id
        ? {
            create: { ...(applicationData as ResidentApplicationCreateInput) },
          }
        : {
            update: { ...(applicationData as ResidentApplicationUpdateInput) },
          },
      guarantor:
        getGuarantorData() !== undefined && !guarantorData?.id
          ? ({
              create: {
                ...getGuarantorData(),
                userTenantID: {
                  connect: {
                    id: userUpdate?.id,
                  },
                },

                guarantorId: {
                  create: [
                    {
                      inviter: { connect: { id: userUpdate?.id } },
                      email: getGuarantorData()?.email,
                      reservationId: leaseId,
                    },
                  ],
                },
              },
            } as LeaseGuarantorUpdateRelationInput)
          : guarantorData?.id && getGuarantorData() !== undefined
          ? ({
              update: {
                ...getGuarantorData(),
              },
            } as LeaseGuarantorUpdateRelationInput)
          : guarantorData?.id && getGuarantorData() === undefined
          ? ({
              update: {
                ...guarantorInfo,
              },
            } as LeaseGuarantorUpdateRelationInput)
          : undefined,
    };
    if (newResidentApplicationData) {
      const response = await apollo.mutate<{ leaseUpdate: Lease }>({
        mutation: UPDATE_LEASE_OPPORTUNITIES_MUTATION,
        variables: {
          data: dataLease,
        },
      });

      return response.data?.leaseUpdate ?? null;
    } else {
      return null;
    }
  },
);

export const fetchResidentFeeStatus = createAction(
  onResidentFeeStatusEvent,
  onResidentFeeStatusError,
  async (id: string) => {
    const {
      data: { lease },
    } = await apolloClient.query<{
      lease: Lease;
    }>({
      query: FETCH_RESIDENT_FEE_STATUS,
      variables: { id },
    });

    return lease?.reservationFeePaid || false;
  },
);
