import { createAction } from '@8baselabs/react-simple-state';
import {
  PropertyListResponse,
  Property,
  PropertyFilter,
  BedUpdateInput,
  Bed,
} from '@8baselabs/resident-io-shared';
import { apolloClient } from '../../shared/apollo';
import {
  FetchPropertiesByOwnerEvent,
  FetchPropertyById,
  FetchPropertyByIdError,
  FetchPropertiesByFilter,
  FetchPropertiesByFilterError,
  FetchPropertiesByOwnerEventError,
  updateBedEventError,
  updateBedEvent,
} from './properties-events';
import {
  GET_ALL_PROPERTIES_BY_OWNER,
  FETCH_PROPERTY_BY_ID,
  GET_ALL_PROPERTIES_BY_FILTER,
  UPDATE_BED_AVAILABILITY,
} from './properties-queries';

/**
 * Fetch all properties by property owner.
 */
export const fetchPropertiesByOwnerAction = createAction(
  FetchPropertiesByOwnerEvent,
  FetchPropertiesByOwnerEventError,
  async (userId: string) => {
    const apollo = apolloClient;

    const {
      data: { propertiesList },
    } = await apollo.query<{ propertiesList: PropertyListResponse }>({
      query: GET_ALL_PROPERTIES_BY_OWNER,
      variables: { userId },
    });

    return [...propertiesList.items];
  },
);
/**
 * Fetch property by id.
 */
export const fetchPropertiesById = createAction(
  FetchPropertyById,
  FetchPropertyByIdError,
  async (id: string) => {
    const apollo = apolloClient;
    const {
      data: { property },
    } = await apollo.query<{ property: Property }>({
      query: FETCH_PROPERTY_BY_ID,
      variables: { id },
    });

    await apolloClient.cache.reset();
    return property;
  },
);
/**
 * Fetch all properties by property owner.
 */
export const fetchPropertiesByFilter = createAction(
  FetchPropertiesByFilter,
  FetchPropertiesByFilterError,
  async (filter?: PropertyFilter) => {
    const apollo = apolloClient;
    const propFilter: PropertyFilter = {
      ...filter,
      websiteVisibility: {
        equals: true,
      },
    };
    const {
      data: { propertiesList },
    } = await apollo.query<{ propertiesList: PropertyListResponse }>({
      query: GET_ALL_PROPERTIES_BY_FILTER,
      variables: { propFilter },
    });

    return [...propertiesList.items];
  },
);

/**
 * Update bed availability.
 */
export const updateBedAvailability = createAction(
  updateBedEvent,
  updateBedEventError,
  async (data?: BedUpdateInput) => {
    const response = await apolloClient.mutate<{ bed: Bed }>({
      mutation: UPDATE_BED_AVAILABILITY,
      variables: { data },
    });

    //await apolloClient.cache.reset();
    return response.data?.bed as Bed;
  },
);
