import React, { Suspense, useState } from 'react';
import { PropertiesCardMultiSelector } from '../../shared/components/PropertiesCardMultiSelector';

const PeopleTable = React.lazy(() => import('./components/PeopleTable')
  .then((module) => ({ default: module.PeopleTable })));
/**
 * @returns {React.FC} - Opportunities view.
 */
export const PeopleView: React.FC = () => {
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);

  return (
    <Suspense fallback={<>Loading...</>}>
      <PropertiesCardMultiSelector
        selectedProperties={selectedProperties}
        setSelectedProperties={setSelectedProperties}
      />
      {selectedProperties.length ? (
        <>
          <PeopleTable propertiesSelected={selectedProperties} />
        </>
      ) : null}
    </Suspense>
  );
};
