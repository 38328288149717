import { useContext, useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useCallAction } from '@8baselabs/react-simple-state';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import {
  updateAllowChecksAction,
  updateAllowCreditCardsAction,
} from '../../user/user-actions';
import { AlertContext } from '../../../routes/AlertContext';
import { AddCircleButton } from '../../../shared/components/ui/buttons/AddCircleButton';
import { User } from '@8baselabs/resident-io-shared';

export const PaymentMethodRestrictions: React.FC<{
  user: User;
  type?: 'card' | 'us_bank_account' | undefined;
  isTenant: boolean;
  refetchLease?: () => void;
  handleOpen: () => void;
}> = ({ user, isTenant, type, handleOpen, refetchLease }) => {
  const [allowCreditCards, setAllowCreditCards] = useState(
    user.allowCreditCards,
  );
  const [allowChecks, setAllowChecks] = useState(user.allowChecks);
  const alert = useContext(AlertContext);

  useEffect(() => {
    setAllowChecks(user.allowChecks);
    setAllowCreditCards(user.allowCreditCards);
  }, [user]);

  const [updateAllowCreditCards, loadingAllowCreditCards] = useCallAction(
    updateAllowCreditCardsAction,
    {
      onCompleted: () => {
        alert({
          success: true,
          message: 'User updated successfully',
        });
        refetchLease?.();
      },
      onError: () => {
        setAllowCreditCards(user.allowCreditCards);
        alert({
          error: true,
          message: 'Failed to update User',
        });
      },
    },
  );

  const [updateAllowChecks, loadingAllowChecks] = useCallAction(
    updateAllowChecksAction,
    {
      onCompleted: () => {
        alert({
          success: true,
          message: 'User updated successfully',
        });
        refetchLease?.();
      },
      onError: () => {
        setAllowChecks(user.allowChecks);
        alert({
          error: true,
          message: 'Failed to update User',
        });
      },
    },
  );

  const changeAllowCreditCards = (e): void => {
    if (user.id) {
      setAllowCreditCards(e.target.checked);
      updateAllowCreditCards(user.id, e.target.checked);
    }
  };

  const changeAllowChecks = (e): void => {
    if (user.id) {
      setAllowChecks(e.target.checked);
      updateAllowChecks(user.id, e.target.checked);
    }
  };

  return (
    <Box display="flex" justifyContent="end">
      {!isTenant && (
        <>
          {' '}
          <FormControlLabel
            control={
              <Switch
                checked={allowCreditCards}
                disabled={loadingAllowCreditCards}
                color="primary"
                onChange={changeAllowCreditCards}
              />
            }
            label="Credit Card"
          />
          <FormControlLabel
            control={
              <Switch
                checked={allowChecks}
                disabled={loadingAllowChecks}
                color="primary"
                onChange={changeAllowChecks}
              />
            }
            label="Checks"
          />
        </>
      )}
      <AddCircleButton disabled={type === undefined} onClick={handleOpen}>
        Add Payment Method
      </AddCircleButton>
    </Box>
  );
};
