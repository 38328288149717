export enum RegexTypes {
  ComposeEmail,
  MessageTemplate,
}

// const REGEX_COMPOSE_EMAIL =
//   /\b(?:Resident Last Name|Resident Email|Resident Full Name|Resident First Name)\b/g;

const REGEX_MESSAGE_TEMPLATE =
  /\b(?:Resident Last Name|Resident Email|Resident Full Name|Resident First Name|Resident Current Balance|Resident Balance Owed|Resident Login URL|Resident Payment URL|Resident Move-In Date|Resident Move-Out Date|Resident Bed Assignment|Resident Assigned Property|Resident Reservation Status|Guarantor First Name|Guarantor Last Name|Guarantor Full Name|Guarantor Email)\b/g;

const findWithRegex = (regex, contentBlock, callback): void => {
  const text = contentBlock.getText();
  let matchArr, start;
  // eslint-disable-next-line
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

export function bracketsStrategy(
  contentBlock,
  callback,
  contentState,
  type,
): void {
  findWithRegex(REGEX_MESSAGE_TEMPLATE, contentBlock, callback);
}

// export function TemplateStrategy(
//   contentBlock,
//   callback,
//   contentState,
//   type,
// ): void {
//   findWithRegex(REGEX_MESSAGE_TEMPLATE, contentBlock, callback);
// }

export const HandleBrackets = (props) => {
  return (
    <span
      style={{
        backgroundColor: '#f0f4ff',
        color: '#6980ae',
        padding: '0 10px',
      }}>
      {props.children}
    </span>
  );
};
