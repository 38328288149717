import {
  useCallAction,
  useEvent,
  useFetchAction,
} from '@8baselabs/react-simple-state';
import { Lease } from '@8baselabs/resident-io-shared';
import { Avatar, Box, Divider, makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { AlertContext } from '../../../routes/AlertContext';
import { MainLoader } from '../../../shared/components/MainLoader';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';
import { createNoteAction, fetchNotesActions } from '../people-actions';
import { fetchUserEvent } from '../people-event';
import { PageTitle } from '../../../shared/components/ui/texts/CommonTexts';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
  point: {
    width: '5px',
    height: '5px',
    backgroundColor: '#98A9BC',
    borderRadius: '100%',
    marginLeft: '10px',
    marginRight: '10px',
  },
}));

export const NotesView: React.FC<{ selectedLease?: Lease }> = () => {
  const user = useEvent(fetchUserEvent);
  const classes = useStyles();

  const alert = useContext(AlertContext);
  const [note, setnote] = useState('');
  const [noteList, loading, action] = useFetchAction(fetchNotesActions, [
    user?.id as string,
  ]);
  const [submitNote, loadingSubmit] = useCallAction(createNoteAction, {
    onCompleted: () => {
      alert({
        success: true,
        message: 'Note submited successfully',
      });
      setnote('');
      action.refetch();
    },
    onError: () => {
      alert({
        error: true,
        message: 'Failed to create note',
      });
    },
  });

  if (loading) {
    return (
      <Box>
        <MainLoader />
      </Box>
    );
  }

  return (
    <Box p="40px">
      <Box>
        <PageTitle variant="h5">Notes</PageTitle>
      </Box>
      <Box mt="30px">
        {noteList?.items.map((item) => (
          <Box className={classes.card} mb="15px">
            <Box p="20px" fontWeight="500">
              {item.note}
            </Box>
            <Divider />
            <Box p="15px" display="flex" alignItems="center">
              <Avatar
                src={item.createdBy?.avatar?.downloadUrl}
                style={{ marginRight: '10px' }}>
                {item.createdBy?.firstName?.[0]} {item.createdBy?.lastName?.[0]}
              </Avatar>
              {item.createdBy?.email}
              <Box className={classes.point} />
              {moment(item.createdAt).format('MMM DD YYYY')}
            </Box>
          </Box>
        ))}
        <Box mt="50px">
          <TextField
            onChange={(e) => setnote(e.target.value)}
            placeholder="Type a new note..."
            multiline
            rows={8}
            variant="outlined"
            fullWidth
          />
          <Box display="flex" justifyContent="flex-end" mt="15px">
            <ButtonAction
              fullWidth={false}
              disabled={loadingSubmit || !note}
              onClick={() => submitNote(note, user.id)}>
              ADD NOTE
            </ButtonAction>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
