import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import MaterialStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { makeStyles, createStyles } from '@material-ui/core';
import { ButtonDefault } from '../buttons/ButtonDefault';
import { ButtonAction } from '../buttons/ButtonAction';

const useStyles = makeStyles(() =>
  createStyles({
    StepperContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  }),
);

type TypeButtonProps = {
  isLoading: boolean;
  onNext: () => void;
  activeStep: number;
  finalStep: number;
};

const TypeButton: React.FC<TypeButtonProps> = ({
  isLoading,
  onNext,
  activeStep,
  finalStep,
}) => (
  <ButtonAction
    variant="contained"
    color="primary"
    size="medium"
    type="button"
    isLoading={isLoading}
    fullWidth={false}
    onClick={onNext}
    style={{ marginBottom: '10px', width: '90px' }}>
    {activeStep === finalStep ? 'Finish' : 'Next'}
  </ButtonAction>
);

type StepperProps = {
  steps: string[];
  activeStep: number;
  onBack?: () => void;
  onNext?: () => void;
  isLoading?: boolean;
  className?: string;
};

export const Stepper: React.FC<StepperProps> = ({
  activeStep,
  isLoading,
  onBack,
  onNext,
  steps,
  className,
}) => {
  const classes = useStyles();
  return (
    <MaterialStepper
      className={`${classes.StepperContainer} ${className}`}
      orientation="vertical"
      activeStep={activeStep}>
      {steps.map((label, index) => {
        const labelProps: { optional?: React.ReactNode } = {};
        return (
          <Step key={label}>
            <StepLabel {...labelProps}>{label}</StepLabel>
            {onBack ||
              (onNext && (
                <StepContent>
                  <div>
                    <ButtonDefault
                      variant="outlined"
                      size="medium"
                      fullWidth={false}
                      disabled={activeStep === 0}
                      onClick={onBack}
                      style={{ marginBottom: '10px', width: '90px' }}>
                      Back
                    </ButtonDefault>
                    <TypeButton
                      isLoading={Boolean(isLoading)}
                      onNext={onNext}
                      activeStep={activeStep}
                      finalStep={steps.length - 1}
                    />
                  </div>
                </StepContent>
              ))}
          </Step>
        );
      })}
    </MaterialStepper>
  );
};
