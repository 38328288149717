import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useCallAction } from '@8baselabs/react-simple-state';
import { EntryType } from '@8baselabs/resident-io-shared';

import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../shared/utils';
import { sendReceiptAction } from '../people-actions';
import { ButtonAction } from '../../../shared/components/ui/buttons/ButtonAction';

type PaymentPlanItemDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  type: EntryType;
  amount: number;
};
const useStyles = makeStyles(() =>
  createStyles({
    content: {
      padding: '30px 34px',
      width: '368px',
    },
  }),
);
export const SendReceiptDialog: React.FC<PaymentPlanItemDialogProps> = ({
  isOpen,
  type,
  onClose,
  id,
  amount,
}) => {
  const [sendReceiptCall, loading] = useCallAction(sendReceiptAction, {
    onCompleted() {
      onClose();
    },
  });

  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm">
      <DialogTitle>Send Receipt</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} className={classes.content}>
          <Typography variant="body1">
            Confirm you want to send receipt for {formatCurrency(amount)}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <ButtonAction
          isLoading={loading}
          fullWidth={false}
          onClick={() => {
            sendReceiptCall(id, type);
          }}>
          Send
        </ButtonAction>
      </DialogActions>
    </Dialog>
  );
};
