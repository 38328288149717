import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  Theme,
  lighten,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Badge } from '@material-ui/core';
import clsx from 'clsx';
import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { PRIMARY_COLOR } from '../theme';
import { TENANT_ROUTES } from '../../routes/types';
import { useSession } from '../../modules/session/session-hooks';
import { resendEmailVerification } from './components-actions';
import {
  OnRequestEmailVerification,
  OnRequestEmailVerificationError,
} from './components-events';
import { AlertContext } from '../../routes/AlertContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: '0px 5px 15px rgb(171 171 171 / 17%)',
      padding: theme.spacing(0.6),
    },
    menuButton: {
      color: '#B2B2B2',
      marginRight: theme.spacing(2),
    },
    send: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    title: {
      flexGrow: 1,
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    userMenuButton: {
      margin: theme.spacing(0, 0, 0, 3),
      borderRadius: 0,
    },
    userMenuIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
    },
    userMenuItemLogout: {
      color: PRIMARY_COLOR,
      '& .MuiListItemIcon-root': {
        color: PRIMARY_COLOR,
      },
    },
    userMenuItemIcon: {
      backgroundColor: PRIMARY_COLOR,
      color: '#fff',
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    tool: {
      justifyContent: 'space-between',
    },
    secondaryAppBar: {
      marginTop: 70,
      boxShadow: '0px 5px 15px rgb(171 171 171 / 17%)',
      padding: theme.spacing(1),
      zIndex: 40,
    },
    menuItem: {
      padding: theme.spacing(2),
    },
    fontWeight: {
      fontWeight: 'bold',
    },
    cursor: {
      cursor: 'pointer',
    },
    avatarName: {
      margin: theme.spacing(0, 1),
      fontWeight: 'bold',
    },
    green: {
      color: theme.palette.success.main,
    },
    advMenuItem: {
      backgroundColor: 'red',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
    },
    reservationProgress: {
      backgroundColor: lighten(PRIMARY_COLOR, 0.9),
      borderRadius: '100%',
    },
    primary: {
      color: PRIMARY_COLOR,
    },
  }),
);

export const TenantTopbar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth0();
  const [session] = useSession();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const anchorProfileRef = useRef<HTMLButtonElement>(null);
  const handleProfileMenu = (): void => {
    setOpenProfileMenu((prevOpen) => !prevOpen);
  };

  const { user } = useAuth0();
  const alert = useContext(AlertContext);

  const [callResendEmailVerification] = useCallAction(resendEmailVerification);

  useSubscription(OnRequestEmailVerification, () => {
    alert({
      success: true,
      message: 'Email verification successfully sent',
    });
  });

  useSubscription(OnRequestEmailVerificationError, () => {
    alert({
      error: true,
      message: 'Something went wrong while sending email verification',
    });
  });

  const handleProfileClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      anchorProfileRef.current &&
      anchorProfileRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenProfileMenu(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProfileMenu(false);
    }
  };

  const handleLogout = (): void =>
    logout({
      returnTo: window.location.origin,
    });

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openProfileMenu);
  useEffect(() => {
    if (prevOpen.current === true && openProfileMenu === false) {
      anchorProfileRef.current?.focus();
    }

    prevOpen.current = openProfileMenu;
  }, [openProfileMenu]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <AppBar className={classes.appBar} color="default">
        <Toolbar className={classes.tool}>
          <div className={classes.cursor}>
            <a href="/">
              <img
                src="/assets/resident.svg"
                alt="Resident.io Logo"
                style={{ width: '192px' }}
              />
            </a>
          </div>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls={
                openProfileMenu ? 'menu-profile-appbar' : undefined
              }
              aria-haspopup="true"
              ref={anchorProfileRef}
              className={matches ? classes.userMenuButton : ''}
              onClick={handleProfileMenu}
              color="inherit">
              {user?.email_verified ? (
                <Avatar
                  src={session?.user.avatar?.downloadUrl}
                  className={classes.userMenuIcon}>
                  {session?.user.firstName?.[0]} {session?.user.lastName?.[0]}
                </Avatar>
              ) : (
                <Badge badgeContent="" overlap="circular" color="secondary">
                  <Avatar
                    src={session?.user.avatar?.downloadUrl}
                    className={classes.userMenuIcon}>
                    {session?.user.firstName?.[0]} {session?.user.lastName?.[0]}
                  </Avatar>
                </Badge>
              )}

              <Typography className={classes.avatarName}>
                {' '}
                {session?.user.firstName} {session?.user.lastName}
              </Typography>
              <ExpandMoreOutlinedIcon />
            </IconButton>
            <Popper
              open={openProfileMenu}
              anchorEl={anchorProfileRef.current}
              role={undefined}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleProfileClose}>
                      <MenuList
                        autoFocusItem={openProfileMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        style={{ paddingBottom: 0 }}>
                        <Box
                          width={220}
                          display="flex"
                          padding={2}
                          alignItems="center"
                          justifyContent="space-between">
                          <Avatar
                            src={session?.user.avatar?.downloadUrl}
                            className={classes.userMenuItemIcon}>
                            {session?.user.firstName?.[0]}{' '}
                            {session?.user.lastName?.[0]}
                          </Avatar>
                          <Typography
                            variant="h6"
                            className={classes.fontWeight}>
                            {`${session?.user?.firstName ?? ''} ${session?.user?.lastName ?? ''}`}
                          </Typography>
                        </Box>
                        {!user?.email_verified && (
                          <div className={classes.advMenuItem}>
                            Verify your email, or&nbsp;
                            <span
                              className={classes.send}
                              onClick={() => callResendEmailVerification(user)}
                              role="none">
                              send the verification email again
                            </span>
                          </div>
                        )}
                        <Divider />
                        <MenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            history.push(TENANT_ROUTES.resident.path);
                            setOpenProfileMenu(false);
                          }}>
                          <ListItemIcon>
                            <FileCopyIcon fontSize="small" />
                          </ListItemIcon>
                          My Reservations
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            history.push(
                              `${TENANT_ROUTES.profile.path}${TENANT_ROUTES.profile.subRoutes?.contactinfo.path}`,
                            );
                            setOpenProfileMenu(false);
                          }}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          Account Settings
                        </MenuItem>
                        {/* <Divider />
                        <MenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            history.push(
                              TENANT_ROUTES['email-preferences'].path,
                            );
                            setOpenProfileMenu(false);
                          }}>
                          <ListItemIcon>
                            <CommentIcon fontSize="small" />
                          </ListItemIcon>{' '}
                          Communication
                        </MenuItem>*/}
                        <Divider />
                        <MenuItem
                          className={clsx(
                            classes.userMenuItemLogout,
                            classes.menuItem,
                          )}
                          onClick={handleLogout}>
                          <ListItemIcon>
                            <PowerSettingsNewOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          Sign Out
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};
