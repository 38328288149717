import React from 'react';
import MaterialSelect, {
  StandardTextFieldProps,
} from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export type SelectOptions = {
  value: string | number;
  label: string;
};

export interface SelectProps extends StandardTextFieldProps {
  options: Array<SelectOptions>;
}

export const SelectDefault: React.FC<SelectProps> = ({ options, ...rest }) => {
  const renderOptions = options.map((option: SelectOptions) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  return (
    <MaterialSelect {...rest} select variant="outlined" fullWidth>
      {renderOptions}
    </MaterialSelect>
  );
};
