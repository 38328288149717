import { createAction } from '@8baselabs/react-simple-state';
import * as yup from 'yup';
import { MessageTemplateType } from './message-template-types';
import {
  MESSAGE_TEMPLATE_CREATE_MUTATION,
  MESSAGE_TEMPLATE_DELETE_MUTATION,
  MESSAGE_TEMPLATE_LIST_QUERY,
  MESSAGE_TEMPLATE_QUERY,
  MESSAGE_TEMPLATE_UPDATE_MUTATION,
  SEND_EMAIL_RESOLVER,
} from './message-templates-queries';
import { apolloClient } from '../../../shared/apollo/index';
import {
  onCreateTemplateErrorEvent,
  onCreateTemplateEvent,
  onDeleteTemplateErrorEvent,
  onDeleteTemplateEvent,
  onFetchTemplateErrorEvent,
  onFetchTemplateEvent,
  onFetchTemplatesErrorEvent,
  onFetchTemplatesEvent,
  onSendTemplateErrorEvent,
  onSendTemplateEvent,
  onUpdateTemplateErrorEvent,
  onUpdateTemplateEvent,
} from './message-template-events';
import { MessageTemplateListResponse } from '@8baselabs/resident-io-shared';

export const createTemplate = createAction(
  onCreateTemplateEvent,
  onCreateTemplateErrorEvent,
  async (data) => {
    const validationSchema = yup.object({
      type: yup.string().required('Type is required').nullable(),
      subject: !data.type
        ? yup.string().required('Subject is required').nullable()
        : data.type === 'email'
        ? yup.string().required('Subject is required').nullable()
        : yup.string().nullable(),
      template: !data.type
        ? yup
            .string()
            .required('Description is required')
            .min(10, 'Description is required')
            .nullable()
        : data.type === 'email'
        ? yup.string().required().min(10, 'Description is required').nullable()
        : yup
            .string()
            .required('Description is required')
            .min(10, 'Description is required')
            .max(160, 'Description must be less than 160 characters')
            .nullable(),
    });

    await validationSchema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
    });

    await apolloClient.mutate({
      mutation: MESSAGE_TEMPLATE_CREATE_MUTATION,
      variables: {
        data,
      },
    });
    await apolloClient.cache.reset();
  },
);

export const updateTemplate = createAction(
  onUpdateTemplateEvent,
  onUpdateTemplateErrorEvent,
  async (data) => {
    const validationSchema = yup.object({
      name: yup.string().required('Template name is required').nullable(),
      type: yup.string().required('Type is required').nullable(),
      subject: !data.type
        ? yup.string().required('Subject is required').nullable()
        : data.type === 'email'
        ? yup.string().required('Subject is required').nullable()
        : yup.string().nullable(),
      template: !data.type
        ? yup
            .string()
            .required('Description is required')
            .min(10, 'Description is required')
            .nullable()
        : data.type === 'email'
        ? yup.string().required().min(10, 'Description is required').nullable()
        : yup
            .string()
            .required('Description is required')
            .min(10, 'Description is required')
            .max(160, 'Description must be less than 160 characters')
            .nullable(),
    });

    await validationSchema.validate(data, {
      abortEarly: false,
      stripUnknown: true,
    });

    await apolloClient.mutate({
      mutation: MESSAGE_TEMPLATE_UPDATE_MUTATION,
      variables: {
        data,
      },
    });

    await apolloClient.cache.reset();
  },
);

export const deleteTemplate = createAction(
  onDeleteTemplateEvent,
  onDeleteTemplateErrorEvent,
  async (id) => {
    await apolloClient.mutate({
      mutation: MESSAGE_TEMPLATE_DELETE_MUTATION,
      variables: {
        id,
      },
    });

    await apolloClient.cache.reset();
  },
);

export const fetchMessageTemplates = createAction(
  onFetchTemplatesEvent,
  onFetchTemplatesErrorEvent,
  async (filter = {}, page = 0, pageSize = 10) =>
    (
      await apolloClient.query<{
        messageTemplatesList: MessageTemplateListResponse;
      }>({
        query: MESSAGE_TEMPLATE_LIST_QUERY,
        variables: {
          skip: page * pageSize,
          first: pageSize,
          filter,
        },
      })
    ).data.messageTemplatesList.items,
);

export const fetchMessageTemplate = createAction(
  onFetchTemplateEvent,
  onFetchTemplateErrorEvent,
  async (id: string) =>
    (
      await apolloClient.query<{
        messageTemplate: MessageTemplateType;
      }>({
        query: MESSAGE_TEMPLATE_QUERY,
        variables: {
          id,
        },
      })
    ).data.messageTemplate,
);

export const sendTemplateEmail = createAction(
  onSendTemplateEvent,
  onSendTemplateErrorEvent,
  async (data) => {
    await apolloClient.mutate({
      mutation: SEND_EMAIL_RESOLVER,
      variables: {
        data,
      },
    });
  },
);
