import { gql } from '@apollo/client';

export const FETCH_USER_LIST_QUERY = gql`
  query FETCH_USER_LIST_QUERY($filter: UserFilter, $first: Int, $skip: Int) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        userType
        firstName
        lastName
        email
        gender
        phoneNumber
        lastLogin
        avatar {
          downloadUrl
        }
        guarantorData {
          id
        }
        userResidentLeaseRelation(sort: { createdAt: DESC }) {
          items {
            createdAt
            startDate
            moveInDate
            moveOutDate
            endDate
            id
            unit {
              code
            }
            bed {
              number

              room {
                number
                code
              }
            }
            property {
              id
              name
              street
              units
            }
          }
        }
      }
    }
  }
`;
export const FETCH_ROOMMATES_QUERY = gql`
  query FETCH_ROOMMATES_QUERY(
    $filter: UserFilter
    $first: Int
    $skip: Int
    $leaseFilter: LeaseFilter
  ) {
    usersList(filter: $filter, first: $first, skip: $skip) {
      count
      items {
        id
        userType
        firstName
        lastName
        email
        gender
        phoneNumber
        lastLogin
        guarantorData {
          id
        }
        userResidentLeaseRelation(
          sort: { createdAt: DESC }
          filter: $leaseFilter
        ) {
          items {
            createdAt
            startDate
            endDate
            id
            unit {
              code
            }
            bed {
              number

              room {
                number
                code
              }
            }
            property {
              id
              name
              street
              units
            }
          }
        }
      }
    }
  }
`;
export const NOTE_CREATE_MUTATION = gql`
  mutation NOTE_CREATE_MUTATION($data: NoteCreateInput!) {
    noteCreate(data: $data) {
      id
    }
  }
`;

export const FETCH_USER_PEOPLE_QUERY = gql`
  query FETCH_USER_PEOPLE_QUERY($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      birthdate
      address
      gender
      state
      phoneNumber
      emergencyPhoneNumber
      homePhone
      city
      zipCode
      studentIdOrEmployeeNumber
      displayNameAndPhoto
      allowCreditCards
      allowChecks
      stripeCustomerId
      avatar {
        downloadUrl
      }
      userResidentLeaseRelation(sort: { createdAt: DESC }) {
        items {
          status
          startDate
          endDate
          id
          createdAt
          leasePeriod {
            id
            startDate
            endDate
            name
          }
          residentApplication {
            id
            convictedCrime
            attendingCollege
            schoolStartDate
            smoke
            bringingVehicle
            shareRide
          }
          publicRoomateProfile {
            displayName
            school
            age
            smoking
            hometown
            allergies
            aboutYourself
            id
          }
          property {
            name
            id
            state
            stateCode
            street
            city
            images {
              items {
                downloadUrl
              }
            }
            primaryPropertyImage {
              downloadUrl
            }
            propertyImagePropertyRelation {
              items {
                id
                file {
                  downloadUrl
                  fileId
                }
                caption
                type
              }
            }
          }
        }
      }
      guarantorData {
        firstName
        lastName
        email
        birthdate
        phoneNumber
        homePhoneNumber
        studentId
        status
        address1
        city
        state
        zipcode
        displayName
        year
        schoolMajor
        aboutYourself
      }
    }
  }
`;

export const FETCH_USERS_MESSAGE_QUERY_FROM_PEOPLE = gql`
  query FETCH_USERS_MESSAGE_QUERY_FROM_PEOPLE($id: [ID!]) {
    usersList(filter: { id: { in: $id } }) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        userTenantProfileRelation {
          email
          sms
        }
      }
    }
  }
`;

export const GET_LEASE_LIST = gql`
  query GET_LEASE_LIST($userId: ID!, $clientId: ID!) {
    leasesList(
      filter: {
        property: { client: { id: { equals: $clientId } } }
        userResident: { id: { equals: $userId } }
      }
      sort: { createdAt: DESC }
    ) {
      items {
        status
        startDate
        endDate
        moveOutDate
        isRenewal
        reservationPaid
        id
        createdAt
        userResident {
          id
          firstName
          lastName
          email
          state
          zipCode
          city
          allowCreditCards
          allowChecks
        }
        unit {
          id
          code
        }
        room {
          id
          code
        }
        floor {
          id
          number
          building {
            code
          }
        }
        bed {
          id
          code
        }
        opcionalPreferenceLeaseRelation {
          items {
            name
            id
            title
            status
            isActive
          }
        }
        leaseTermsDocumentRelation {
          items {
            downloadURL
            signer
            updatedAt
            id
          }
        }
        leaseContractDocumentRelation {
          items {
            downloadURL
            signer
            updatedAt
            id
          }
        }
        leasePeriod {
          id
          startDate
          endDate
          name
          leasePeriodPeriodSubPeriodRelation {
            items {
              leaseSubPeriod {
                id
                name
              }
            }
          }
        }
        residentApplication {
          id
          convictedCrime
          attendingCollege
          schoolStartDate
          smoke
          bringingVehicle
          shareRide
        }
        publicRoomateProfile {
          displayName
          school
          age
          smoking
          hometown
          allergies
          aboutYourself
          id
        }
        unit {
          id
          code
        }
        property {
          name
          id
          state
          stateCode
          street
          email
          city
          siteSetting {
            enableOnlineLeasingCreditCardPayment
            enableOnlineLeasingACHPayment
          }
          stripeAccountId
          client {
            id
          }
          images {
            items {
              downloadUrl
            }
          }
          primaryPropertyImage {
            downloadUrl
          }
          propertyImagePropertyRelation {
            items {
              id
              file {
                downloadUrl
                fileId
              }
              caption
              type
            }
          }
        }
        guarantor {
          id
        }
        leaseLedgerRelation {
          items {
            type
            amount
            subCategory
            category
            description
          }
        }
      }
    }
  }
`;

export const NOTE_QUERY_LIST = gql`
  query NOTE_QUERY_LIST($filter: NoteFilter) {
    notesList(filter: $filter) {
      items {
        id
        note
        createdAt
        user {
          id
          lastName
          firstName
          email
        }
        createdBy {
          id
          lastName
          firstName
          email
          avatar {
            downloadUrl
          }
        }
      }
    }
  }
`;

export const UPDATE_LEASE_QUERY = gql`
  mutation UPDATE_LEASE_QUERY(
    $data: LeaseUpdateInput!
    $filter: LeaseKeyFilter
  ) {
    leaseUpdate(data: $data, filter: $filter, force: true) {
      id
    }
  }
`;

export const UPDATE_LEASE_OPTIONAL_PREFERENCES = gql`
  mutation UPDATE_LEASE_OPTIONAL_PREFERENCES(
    $data: LeaseOptionalPreferenceUpdateInput!
    $filter: LeaseOptionalPreferenceKeyFilter
  ) {
    leaseOptionalPreferenceUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;

export const DELETE_LEASE = gql`
  mutation DELETE_LEASE($filter: LeaseFilter!) {
    leaseDeleteByFilter(filter: $filter, force: true) {
      success
    }
  }
`;

export const CREATE_MANY_LEASE_DOCUMENT = gql`
  mutation CREATE_MANY_LEASE_DOCUMENT($data: [LeaseDocumentCreateManyInput]!) {
    leaseDocumentCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;
export const DELETE_LEASE_DOCUMENT = gql`
  mutation DELETE_LEASE_DOCUMENT($data: RequiredFileDeleteInput!) {
    requiredFileDelete(data: $data) {
      success
    }
  }
`;
export const FETCH_DOCUMENT_TYPE = gql`
  query FETCH_DOCUMENT_TYPE($filter: DocumentTypeFilter) {
    documentTypesList(filter: $filter) {
      items {
        name
        id
      }
    }
  }
`;
export const CREATE_MANY_FILE = gql`
  mutation CREATE_MANY_FILE($data: [FileCreateManyInput]!) {
    fileCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;

export const FETCH_OPTIONAL_PREFERENCES_QUERY = gql`
  query FETCH_OPTIONAL_PREFERENCES_QUERY($filter: OptionalPreferenceFilter) {
    optionalPreferencesList(filter: $filter) {
      items {
        id
        name
        title
        description
        status
        isActive
        total
        createdAt
        optionalPreferenceInvetoryRelation {
          items {
            id
            name
            isSold
            leaseOptionalPreference {
              id
              lease {
                status
              }
            }
          }
        }
        optionalPreferenceAdditionalDataRelation {
          items {
            id
            title
            required
          }
        }
      }
    }
  }
`;

export const LEASE_OPTIONAL_PREFERENCE_CREATE_INPUT = gql`
  mutation LEASE_OPTIONAL_PREFERENCE_CREATE_INPUT(
    $data: LeaseOptionalPreferenceCreateInput!
  ) {
    leaseOptionalPreferenceCreate(data: $data) {
      id
    }
  }
`;

export const LEASE_OPTIONAL_PREFERENCE_QUERY = gql`
  query LEASE_OPTIONAL_PREFERENCE_QUERY($leaseId: ID!) {
    leaseOptionalPreferencesList(
      filter: { lease: { id: { equals: $leaseId } } }
    ) {
      items {
        id
        lease {
          id
        }
        optionalPreference {
          id
          name
          description
          title
          optionalPreferenceAdditionalDataRelation {
            items {
              id
              title
            }
          }
        }
        additionalAppData
        inventory {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_BY_FILTER_LEASE_OPTIONAL_PREFERENCES_MUTATION = gql`
  mutation DELETE_BY_FILTER_LEASE_OPTIONAL_PREFERENCES_MUTATION(
    $filter: LeaseOptionalPreferenceFilter!
  ) {
    leaseOptionalPreferenceDeleteByFilter(filter: $filter) {
      success
    }
  }
`;

export const CREATE_LEDGER = gql`
  mutation CREATE_LEDGER($data: LedgerCreateInput!) {
    ledgerCreate(data: $data) {
      id
    }
  }
`;
export const CREATE_PROPERTY_LEDGER = gql`
  mutation CREATE_PROPERTY_LEDGER($data: PropertyLedgerCreateInput!) {
    propertyLedgerCreate(data: $data) {
      id
    }
  }
`;
export const CREATE_LEDGER_TRANSACTION_INFORMATION = gql`
  mutation CREATE_LEDGER_TRANSACTION_INFORMATION(
    $data: LedgerTransactionInformationCreateInput!
  ) {
    ledgerTransactionInformationCreate(data: $data) {
      id
    }
  }
`;
export const UPDATE_LEDGER = gql`
  mutation UPDATE_LEDGER($data: LedgerUpdateInput!) {
    ledgerUpdate(data: $data) {
      id
    }
  }
`;

export const SEND_RECEIPT = gql`
  mutation SEND_RECEIPT($id: ID!, $type: String!) {
    sendReceiptResolver(id: $id, type: $type) {
      success
    }
  }
`;

export const GET_LEDGERS_BY_FILTER = gql`
  query GET_LEDGERS_BY_FILTER($filter: LedgerFilter!) {
    ledgersList(filter: $filter) {
      items {
        id

        createdAt
        updatedAt
        amount
        account
        processedAtDate
        description
        notes
        effectiveDate
        type
        availableOnDate
        payoutDate
        reconciliationDate
        category
        subCategory
        paymentType
        paymentStatus
        ledgerTransactionInformationRelation {
          id
          checkNumber
          stripePyId
          transactionId
          lastFour
          paypalTransactionId
        }
        ledgerDepositRelation {
          id
          bankAccountReferenceId
          referenceId
        }
        createdBy {
          id
          firstName
          lastName
        }
        files {
          items {
            downloadUrl
          }
        }
        leaseSubPeriod {
          id
          name
        }
        lease {
          id

          leasePeriod {
            id
            name
          }
          userResident {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY_LEDGER_BY_FILTER = gql`
  query GET_PROPERTY_LEDGER_BY_FILTER($filter: PropertyLedgerFilter!) {
    propertyLedgersList(filter: $filter) {
      items {
        id
        createdAt
        updatedAt
        amount
        account
        processedAtDate
        description
        notes
        effectiveDate
        type
        availableOnDate
        payoutDate
        reconciliationDate
        category
        subCategory
        paymentType
        ledgerTransactionPropertyLedgerRelation {
          id
          checkNumber
          stripePyId
          transactionId
          lastFour
          paypalTransactionId
        }
        createdBy {
          id
          firstName
          lastName
        }
        files {
          items {
            downloadUrl
          }
        }
        leaseSubPeriod {
          id
          name
        }
        lease {
          id
          leasePeriod {
            id
            name
          }
          userResident {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_GUARANTOR_BY_ID = gql`
  query GET_GUARANTOR_BY_ID($id: ID!) {
    guarantor(id: $id) {
      id
      firstName
      lastName
      email
      birthdate
      phoneNumber
      homePhoneNumber
      studentId
      status
      address1
      city
      state
      zipcode
      displayName
      year
      schoolMajor
      aboutYourself
    }
  }
`;

export const UPDATE_GUARANTOR_QUERY = gql`
  mutation UPDATE_GUARANTOR_QUERY($data: GuarantorUpdateInput!) {
    guarantorUpdate(data: $data) {
      id
    }
  }
`;

export const GET_PAYMENT_SUBCATEGORIES_LIST = gql`
  query GET_PAYMENT_SUBCATEGORIES_LIST {
    paymentSubCategoriesList {
      items {
        id
        createdAt
        updatedAt
        name
        type
        category
      }
    }
  }
`;
export const GET_ACCOUNT_SUBCATEGORIES_LIST = gql`
  query GET_ACCOUNT_SUBCATEGORIES_LIST {
    accountSubCategoriesList {
      items {
        id
        displayName
      }
    }
  }
`;

export const GET_AUTOPAY_BY_FILTER = gql`
  query GET_AUTOPAY_BY_FILTER($filter: AutoPayFilter!) {
    autoPaysList(filter: $filter) {
      items {
        id
        createdAt
        updatedAt
        begins
        expires
        payInFull
        amount
        chargeOn
        lastRun
        status
        paymentMethodId
        lease {
          id
          leasePeriod {
            id
            name
          }
          userResident {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const CREATE_AUTOPAY = gql`
  mutation CREATE_AUTOPAY($data: AutoPayCreateInput!) {
    autoPayCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_AUTOPAY = gql`
  mutation UPDATE_AUTOPAY($data: AutoPayUpdateInput!) {
    autoPayUpdate(data: $data) {
      id
    }
  }
`;

export const FETCH_USER_ACTIVITIES = gql`
  query FETCH_USER_ACTIVITIES(
    $filter: ActivityLogFilter!
    $sort: [ActivityLogSort!]
  ) {
    activityLogsList(filter: $filter, sort: $sort) {
      items {
        id
        createdAt
        updatedAt
        title
        description
        lease {
          id
        }
        user {
          id
          createdAt
          updatedAt
          id
          firstName
          lastName
          avatar {
            downloadUrl
            fileId
          }
          userClientUserRelation {
            items {
              id
              role
            }
          }
        }
      }
    }
  }
`;

export const FETCH_SUBCATEGROIES = gql`
  query FETCH_SUBCATEGROIES {
    accountSubCategoriesList {
      items {
        id
        name
        displayName
      }
    }
  }
`;

export const DELETE_LEDGER_BY_FILTER = gql`
  mutation DELETE_LEDGER_BY_FILTER($filter: LedgerFilter!) {
    ledgerDeleteByFilter(filter: $filter, force: true) {
      success
    }
  }
`;

export const CREATE_REFUND = gql`
  mutation CREATE_REFUND($ledger: LedgerCreateInput, $accountId: String) {
    createRefund(ledger: $ledger, accountId: $accountId) {
      success
    }
  }
`;

export const DELETE_LEDGER_BY_OPTIONAL_PREFERENCE = gql`
  mutation DELETE_LEDGER_BY_OPTIONAL_PREFERENCE($filter: LedgerFilter!) {
    ledgerDeleteByFilter(filter: $filter) {
      success
    }
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation CREATE_PAYMENT_METHOD($data: PaymentMethodCreateInput!) {
    paymentMethodCreate(data: $data) {
      id
    }
  }
`;

export const UPDATE_PAYMENT_METHOD_BY_FILTER = gql`
  mutation UPDATE_PAYMENT_METHOD(
    $data: PaymentMethodUpdateByFilterInput!
    $filter: PaymentMethodFilter!
  ) {
    paymentMethodUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;

export const UPDATE_PAYMENT_METHODS_BY_FILTER = gql`
  mutation UPDATE_PAYMENT_METHODS_BY_FILTER(
    $data: PaymentMethodUpdateByFilterInput!
    $filter: PaymentMethodFilter!
  ) {
    paymentMethodUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD_BY_FILTER = gql`
  mutation DELETE_PAYMENT_METHOD_BY_FILTER($filter: PaymentMethodFilter!) {
    paymentMethodDeleteByFilter(filter: $filter) {
      success
    }
  }
`;

export const GET_PAYMENT_METHODS_BY_FILTER = gql`
  query GET_PAYMENT_METHODS_BY_FILTER($filter: PaymentMethodFilter!) {
    paymentMethodsList(filter: $filter) {
      items {
        stripeId
        isPrimary
        fingerprint
        user {
          id
        }
      }
    }
  }
`;

export const GET_PAYMENT_INTENT = gql`
  mutation GET_PAYMENT_INTENT(
    $stripeAccountId: String!
    $clientSecret: String!
  ) {
    getPaymentIntent(
      stripeAccountId: $stripeAccountId
      clientSecret: $clientSecret
    ) {
      id
      fee
      amount
      transferData
    }
  }
`;

export const GET_METHOD_SIGNATURE = gql`
  mutation GET_METHOD_SIGNATURE($paymentMethodId: String) {
    getPaymentMethodFingerprint(paymentMethodId: $paymentMethodId) {
      fingerprint
    }
  }
`;

export const CREATE_LEASE_PREFERENCE = gql`
  mutation CREATE_LEASE_PREFERENCE($data: LeaseOptionalPreferenceCreateInput!) {
    leaseOptionalPreferenceCreate(data: $data) {
      id
    }
  }
`;

export const DELETE_LEASE_PREFERENCES = gql`
  mutation DELETE_LEASE_PREFERENCES($filter: LeaseOptionalPreferenceFilter!) {
    leaseOptionalPreferenceDeleteByFilter(filter: $filter) {
      success
    }
  }
`;

export const GET_INVENTORY = gql`
  query GET_INVENTORY($id: ID!) {
    optionalPreference(id: $id) {
      id
      optionalPreferenceInvetoryRelation(
        filter: { isSold: { equals: false }, leaseOptionalPreference: null }
      ) {
        items {
          id
          name
          isSold
          leaseOptionalPreference {
            id
          }
        }
      }
    }
  }
`;
