import { createEvent } from '@8baselabs/react-simple-state';
import { Check, PropertyCheckbookAccount } from '@8baselabs/resident-io-shared';

export const fetchChecksEvent = createEvent<Check[]>();
export const fetchChecksEventError = createEvent<Error>();

export const updateCheckEvent = createEvent<Check>();
export const updateCheckEventError = createEvent<Error>();

export const updateChecksEvent = createEvent<void[]>();
export const updateChecksEventError = createEvent<Error>();

export const createCheckEvent = createEvent<Check>();
export const createCheckEventError = createEvent<Error>();

export const updatePropertyCheckbookAccountByIdEvent =
  createEvent<PropertyCheckbookAccount>();
export const updatePropertyCheckbookAccountByIdEventError =
  createEvent<Error>();

export const sendChecksEvent = createEvent<void>();
export const sendChecksEventError = createEvent<Error>();

export const addSignatureEvent = createEvent<boolean>();
export const addSignatureEventError = createEvent<Error>();
