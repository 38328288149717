import { Box } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonDefault } from '../../shared/components/ui/buttons/ButtonDefault';
import { BoldTitle } from '../../shared/components/ui/texts/CommonTexts';
import EmailVerificationImage from '../../shared/assets/emailVerificationConfirmed.png';

export const ConfirmVerifyEmailView: React.FC = () => {
  const { isLoading, user } = useAuth0();
  const history = useHistory();

  if (!isLoading && !user?.email_verified) history.push('/verify-email');

  return (
    <Box width="810px" margin="auto" py="60px">
      <BoldTitle style={{ fontSize: '36px' }}>Email verification</BoldTitle>
      <Box
        style={{ backgroundColor: '#fff' }}
        display="flex"
        alignItems="center"
        textAlign="center"
        flexDirection="column"
        mt="40px"
        boxShadow="0 1px 3px 1px rgba(153,155,168,0.1)"
        borderRadius="4px"
        p="50px"
      >
        <Box>
          <img alt="email" src={EmailVerificationImage} width="70%" />
        </Box>
        <BoldTitle style={{ fontSize: '20px' }}>
          Email Verification Successful
        </BoldTitle>
        <Box mt="20px" color="#5D6E7F">
          Your email was confirmed! You can now continue
        </Box>
        <Box width="196px" height="46px" mt="20px">
          <ButtonDefault onClick={() => history.push('/')}>
            Continue
          </ButtonDefault>
        </Box>
      </Box>
    </Box>
  );
};
