import React, { useEffect, useState } from 'react';
import { alpha, createStyles, Grid, Box, makeStyles } from '@material-ui/core';
import { Lease, Message } from '@8baselabs/resident-io-shared';
import { MUTED_COLOR } from '../../../shared/theme';
import { MessagesToolbar } from '../../../shared/components/MessagesToolbar';
import { ListedMessages } from '../../inbox/components/ListedMessages';
import { ComposeMessageEmail } from '../../inbox/components/ComposeMessageEmail';
import { MessageContent } from '../../inbox/components/MessageContent';
import { Property } from '../../../schema-types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      justifyContent: 'space-evenly',
    },
    groupOfMessages: {
      margin: '0',
      minWidth: '290px',
    },
    tabs: {
      padding: '20px',
    },
    message: {
      width: 'calc(100% - 330px)',
      backgroundColor: alpha(MUTED_COLOR, 0.1),
    },
    noMessages: {
      height: '250px',
      backgroundColor: 'white',
      border: `1px dashed ${alpha(MUTED_COLOR, 1)}`,
    },
  }),
);

export const MessagesView: React.FC<{
  userId?: string;
  selectedLeaseProperty?: Property;
  selectedLease?: Lease;
}> = ({ userId, selectedLeaseProperty, selectedLease }) => {
  const classes = useStyles();
  const [selectedThread, setSelectedThread] = useState<Message[]>();
  const [filterBy, setFilterBy] = useState<{
    search: string;
    idFrom: string[];
    idTo: string[];
    conversation: boolean;
    switches?: {
      read: boolean;
    };
  }>({
    search: '',
    idFrom: [],
    idTo: [],
    conversation: false,
    switches: {
      read: true,
    },
  });
  const [sortMessagesDesc, setSortMessagesDesc] = useState<boolean>(true);

  useEffect(() => {
    setFilterBy({
      search: '',
      idFrom: [selectedLeaseProperty?.id as string] || [],
      idTo: [userId as string] || [],
      conversation: true,
      switches: {
        read: true,
      },
    });
  }, [selectedLeaseProperty?.id, userId]);

  return (
    <Box>
      <Grid item className={classes.tabs} container>
        <MessagesToolbar
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          sortMessagesDesc={sortMessagesDesc}
          setSortMessagesDesc={setSortMessagesDesc}
          userIDs={[userId || '']}
          isPeopleProfile={true}
          leaseProperty={selectedLeaseProperty}
          leaseId={selectedLease?.id || ''}></MessagesToolbar>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item className={classes.groupOfMessages} direction="column">
          {filterBy.idFrom && filterBy.idTo && (
            <ListedMessages
              filterBy={filterBy}
              sortDesc={sortMessagesDesc}
              leaseID={selectedLease?.id ?? ''}
              setSelectedThread={setSelectedThread}
              fromPeople={true}
            />
          )}
        </Grid>
        <Grid className={classes.message}>
          {selectedThread?.length ? (
            <MessageContent
              messagesFromList={selectedThread}
              fromPeople={true}
            />
          ) : (
            <Grid className={classes.noMessages} />
          )}
        </Grid>
      </Grid>
      <ComposeMessageEmail />
    </Box>
  );
};
