import { createAction } from '@8baselabs/react-simple-state';
import {
  FileManyResponse,
  LeaseDocumentDeleteInput,
  LeaseDocumentManyResponse,
  LeaseOptionalPreferenceListResponse,
  LeaseUpdateInput,
  Ledger,
  LedgerCreateInput,
  LedgerUpdateInput,
  AutoPayCreateInput,
  AutoPayUpdateInput,
  AutoPay,
  Mutation,
  NoteListResponse,
  OptionalPreferenceListResponse,
  LeaseOptionalPreferenceUpdateInput,
  LeaseOptionalPreferenceKeyFilter,
  LeaseOptionalPreferenceFilter,
  Query,
  SuccessResponse,
  User,
  UserFilter,
  UserListResponse,
  DocumentTypeFilter,
  Guarantor,
  LeaseFilter,
  ActivityLog,
  ActivityLogFilter,
  ActivityLogSort,
  PropertyLedgerCreateInput,
  PropertyLedger,
  AccountSubCategory,
  PaymentMethodFilter,
  PaymentMethod,
  PaymentMethodCreateInput,
  PaymentMethodUpdateByFilterInput,
  PaymentMethodManyResponse,
  RequiredFileDeleteInput,
  LeaseOptionalPreferenceCreateInput,
  LeasePeriod,
  OptionalPreferenceFilter,
  OptionalPreference,
  OptionalPreferenceInvetory,
} from '../../schema-types';
import { isValidString } from '@8baselabs/validation-utils';
import moment from 'moment';
import { apolloClient, apolloClient as client } from '../../shared/apollo';
import { GET_LEASE } from '../../shared/components/components-queries';
import {
  onFetchOptionalPreferencesEvent,
  onFetchOptionalPreferencesEventError,
} from '../settings-view/settings-events';
import {
  createNoteError,
  createNoteEvent,
  fetchNotesError,
  createDocumentsEvent,
  fetchResidentAndGuarantorError,
  fetchResidentAndGuarantorEvent,
  fetchUserError,
  fetchUserEvent,
  updateLeaseError,
  updateLeaseEvent,
  createDocumentsError,
  fetchNotesEvent,
  OnGetLeaseList,
  OnGetLeaseListError,
  fetchDocumentTypeEvent,
  fetchDocumentTypeError,
  fetchLeaseOptionalPreferencesEvent,
  fetchLeaseOptionalPreferencesError,
  deleteLeaseOptionalPreferenceEvent,
  deleteLeaseOptionalPreferenceError,
  deleteDocumentEvent,
  deleteDocumentsEvent,
  fetchLedgerError,
  fetchLedgerEvent,
  fetchAutoPayEvent,
  fetchAutoPayError,
  createLedgerEvent,
  createLedgerError,
  createAutoPayEvent,
  createAutoPayError,
  updateAutoPayError,
  updateAutoPayEvent,
  sendReceiptEvent,
  sendReceiptError,
  OnGetGuarantorById,
  OnGetGuarantorByIdError,
  OnUpdateGuarantor,
  OnUpdateGuarantorError,
  fetchPaymentSubCategoriesEvent,
  fetchPaymentSubCategoriesError,
  fetchUsersEvent,
  fetchUsersError,
  OnFetchUserActivitiesEvent,
  OnFetchUserActivitiesError,
  OnLeaseResetError,
  OnLeaseReset,
  fetchPropertyLedgerEvent,
  fetchPropertyLedgerError,
  createPropertyLedgerEvent,
  createPropertyLedgerError,
  createPaymentMethodEvent,
  createPaymentMethodEventError,
  updatePaymentMethodByFilterEvent,
  updatePaymentMethodByFilterEventError,
  updatePaymentMethodsByFilterEvent,
  updatePaymentMethodsByFilterEventError,
  deletePaymentMethodByFilterEvent,
  deletePaymentMethodByFilterEventError,
  getPaymentMethodsEvent,
  getPaymentMethodsEventError,
  onFetchSubcategoriesError,
  onFetchSubcategoriesEvent,
  deleteLedgerByFilterEvent,
  deleteLedgerByFilterError,
  createOptionalPreferenceLedgerEvent,
  createOptionalPreferenceLedgerError,
  getPaymentIntentEvent,
  getPaymentIntentEventError,
  fetchAccountSubCategoriesEvent,
  fetchAccountSubCategoriesError,
  getInventoryEvent,
  getInventoryError,
} from './people-event';
import {
  CREATE_MANY_FILE,
  CREATE_MANY_LEASE_DOCUMENT,
  FETCH_OPTIONAL_PREFERENCES_QUERY,
  FETCH_DOCUMENT_TYPE,
  FETCH_USER_PEOPLE_QUERY,
  GET_LEASE_LIST,
  NOTE_CREATE_MUTATION,
  NOTE_QUERY_LIST,
  UPDATE_LEASE_QUERY,
  UPDATE_LEASE_OPTIONAL_PREFERENCES,
  LEASE_OPTIONAL_PREFERENCE_QUERY,
  // DELETE_BY_FILTER_LEASE_OPTIONAL_PREFERENCES_MUTATION,
  DELETE_LEASE_DOCUMENT,
  GET_LEDGERS_BY_FILTER,
  CREATE_LEDGER,
  UPDATE_LEDGER,
  SEND_RECEIPT,
  GET_GUARANTOR_BY_ID,
  UPDATE_GUARANTOR_QUERY,
  FETCH_ROOMMATES_QUERY,
  GET_PAYMENT_SUBCATEGORIES_LIST,
  GET_AUTOPAY_BY_FILTER,
  CREATE_AUTOPAY,
  UPDATE_AUTOPAY,
  FETCH_USERS_MESSAGE_QUERY_FROM_PEOPLE,
  FETCH_USER_ACTIVITIES,
  DELETE_LEASE,
  GET_PROPERTY_LEDGER_BY_FILTER,
  CREATE_PROPERTY_LEDGER,
  FETCH_USER_LIST_QUERY,
  FETCH_SUBCATEGROIES,
  DELETE_LEDGER_BY_FILTER,
  CREATE_REFUND,
  DELETE_LEDGER_BY_OPTIONAL_PREFERENCE,
  CREATE_PAYMENT_METHOD,
  GET_PAYMENT_METHODS_BY_FILTER,
  DELETE_PAYMENT_METHOD_BY_FILTER,
  UPDATE_PAYMENT_METHOD_BY_FILTER,
  UPDATE_PAYMENT_METHODS_BY_FILTER,
  GET_PAYMENT_INTENT,
  GET_ACCOUNT_SUBCATEGORIES_LIST,
  GET_METHOD_SIGNATURE,
  CREATE_LEASE_PREFERENCE,
  DELETE_LEASE_PREFERENCES,
  GET_INVENTORY,
} from './people-query';
import { LedgerPaymentTypes, UserType } from '@8baselabs/resident-io-shared';

type FilterType = {
  property: string;
  leasePeriod: string;
  leaseSubPeriod: string;
  unit: string;
  building: string;
  bed: string;
  room: string;
  floor: string;
  gender: string;
  moveOut: string;
  moveIn: string;
};

export const fetchResidentsAndGuarantorsActions = createAction(
  fetchResidentAndGuarantorEvent,
  fetchResidentAndGuarantorError,
  async (
    search,
    properties: string[],
    preFilter?: FilterType,
    page = 0,
    pageSize = 5,
  ) => {
    const filter: UserFilter = {
      AND: [
        {
          userType: { equals: UserType.USER },
          gender: isValidString(preFilter?.gender)
            ? {
                equals: preFilter?.gender,
              }
            : undefined,
          userResidentLeaseRelation: {
            some: {
              AND: [
                {
                  OR: [
                    ...properties
                      .filter((property) =>
                        isValidString(preFilter?.property)
                          ? property === preFilter?.property
                          : true,
                      )
                      .map((propertyId) => ({
                        moveInDate:
                          preFilter?.moveIn &&
                          isValidString(
                            moment(preFilter?.moveIn as string).format(
                              'YYYY-MM-DD',
                            ),
                          )
                            ? {
                                gt: moment(
                                  preFilter?.moveIn as string,
                                ).toISOString(),
                              }
                            : undefined,
                        moveOutDate:
                          preFilter?.moveOut &&
                          isValidString(
                            moment(preFilter?.moveOut as string).toISOString(),
                          )
                            ? {
                                lt: moment(
                                  preFilter?.moveOut as string,
                                ).toISOString(),
                              }
                            : undefined,
                        property: {
                          id: {
                            equals: propertyId,
                          },
                          propertyBuildingRelation: isValidString(
                            preFilter?.building,
                          )
                            ? {
                                some: {
                                  buildingFloorRelation: isValidString(
                                    preFilter?.floor,
                                  )
                                    ? {
                                        some: {
                                          floorUnitRelation: isValidString(
                                            preFilter?.unit,
                                          )
                                            ? {
                                                some: {
                                                  unitRoomRelation:
                                                    isValidString(
                                                      preFilter?.room,
                                                    )
                                                      ? {
                                                          some: {
                                                            roomBedRelation:
                                                              isValidString(
                                                                preFilter?.bed,
                                                              )
                                                                ? {
                                                                    some: {
                                                                      id: {
                                                                        equals:
                                                                          preFilter?.bed,
                                                                      },
                                                                    },
                                                                  }
                                                                : undefined,
                                                            id: {
                                                              equals:
                                                                preFilter?.room,
                                                            },
                                                          },
                                                        }
                                                      : undefined,

                                                  id: {
                                                    equals: preFilter?.unit,
                                                  },
                                                },
                                              }
                                            : undefined,
                                          id: { equals: preFilter?.floor },
                                        },
                                      }
                                    : undefined,
                                  id: {
                                    equals: preFilter?.building,
                                  },
                                },
                              }
                            : undefined,
                        },
                      })),
                  ],
                },
                {
                  leasePeriod: isValidString(preFilter?.leasePeriod)
                    ? {
                        leasePeriodPeriodSubPeriodRelation: isValidString(
                          preFilter?.leaseSubPeriod,
                        )
                          ? {
                              some: {
                                leaseSubPeriod: {
                                  id: { equals: preFilter?.leaseSubPeriod },
                                },
                              },
                            }
                          : undefined,
                        id: { equals: preFilter?.leasePeriod },
                      }
                    : undefined,
                },
              ],
            },
          },
        },
        {
          _fullText: search,
        },
      ],
    };

    return (
      await client.query<{
        usersList: UserListResponse;
      }>({
        query: FETCH_USER_LIST_QUERY,
        variables: {
          filter,
          skip: page * pageSize,
          first: pageSize,
        },
      })
    ).data.usersList;
  },
);

export const fetchRoommates = createAction(
  fetchResidentAndGuarantorEvent,
  fetchResidentAndGuarantorError,
  async (
    userFilter: UserFilter,
    leaseFilter: LeaseFilter,
    pageSize = 5,
    page = 0,
  ) => {
    const response = await client.query<{
      usersList: UserListResponse;
    }>({
      query: FETCH_ROOMMATES_QUERY,
      variables: {
        filter: userFilter,
        skip: page * pageSize,
        first: pageSize,
        leaseFilter,
      },
    });

    return response.data.usersList;
  },
);
export const createNoteAction = createAction(
  createNoteEvent,
  createNoteError,
  async (note, userId) => {
    const data = {
      note,
      user: {
        connect: {
          id: userId,
        },
      },
    };

    const response = await client.mutate({
      mutation: NOTE_CREATE_MUTATION,
      variables: {
        data,
      },
    });

    await client.cache.reset();
    return response.data.noteCreate;
  },
);
export const fetchDocumentType = createAction(
  fetchDocumentTypeEvent,
  fetchDocumentTypeError,
  async (filter?: DocumentTypeFilter) => {
    const response = await client.query({
      query: FETCH_DOCUMENT_TYPE,
      variables: {
        filter: filter || undefined,
      },
    });

    return response.data.documentTypesList;
  },
);
export const fetchUserActions = createAction(
  fetchUserEvent,
  fetchUserError,
  async (id: string) =>
    (
      await client.query<{
        user: User;
      }>({
        query: FETCH_USER_PEOPLE_QUERY,
        variables: {
          id,
        },
      })
    ).data.user,
);

/* Fetch Users Info: First Name - Last Name - Phone Number */
export const fetchUsersForMessage = createAction(
  fetchUsersEvent,
  fetchUsersError,
  async (id: string[] | undefined) =>
    (
      await apolloClient.query<Query>({
        query: FETCH_USERS_MESSAGE_QUERY_FROM_PEOPLE,
        variables: {
          id,
        },
      })
    ).data.usersList.items,
);

export const getLeaseList = createAction(
  OnGetLeaseList,
  OnGetLeaseListError,
  async (userId: string | undefined, clientId: string | undefined) => {
    const response = await client.query<Query>({
      query: GET_LEASE_LIST,
      variables: {
        userId,
        clientId,
      },
    });

    await client.cache.reset();
    return response.data?.leasesList?.items;
  },
);

export const fetchNotesActions = createAction(
  fetchNotesEvent,
  fetchNotesError,
  async (id: string) =>
    (
      await client.query<{ notesList: NoteListResponse }>({
        query: NOTE_QUERY_LIST,
        variables: {
          filter: {
            user: {
              id: {
                equals: id,
              },
            },
          },
        },
      })
    ).data.notesList,
);
export const createManyDocuments = createAction(
  createDocumentsEvent,
  createDocumentsError,
  async (
    documents: { id: string; type: string; name: string }[],
    leaseId: string,
    owner: string,
  ) => {
    if (!isValidString(leaseId)) {
      throw new Error('Please select a lease');
    }

    const files = (
      await client.mutate<{ fileCreateMany: FileManyResponse }>({
        mutation: CREATE_MANY_FILE,
        variables: {
          data: documents.map((document) => ({
            fileId: document.id,
            filename: document.name,
          })),
        },
      })
    ).data?.fileCreateMany as FileManyResponse;
    return (
      await client.mutate<{
        leaseDocumentCreateMany: LeaseDocumentManyResponse;
      }>({
        mutation: CREATE_MANY_LEASE_DOCUMENT,
        variables: {
          data: files?.items.map((file, index) => ({
            document: {
              connect: {
                id: file.id,
              },
            },
            type: documents[index].type,
            code: '',
            isTenant: owner === 'Tenant',
            isGuarantor: !(owner === 'Tenant'),
            lease: {
              connect: {
                id: leaseId,
              },
            },
          })),
        },
        refetchQueries: [{ query: GET_LEASE, variables: { id: leaseId } }],
        awaitRefetchQueries: true,
      })
    ).data?.leaseDocumentCreateMany as LeaseDocumentManyResponse;
  },
);
export const deleteDocument = createAction(
  deleteDocumentEvent,
  createDocumentsError,
  async (id: string, leaseId: string) => {
    if (!isValidString(id)) {
      throw new Error('Please select document');
    }
    if (!isValidString(leaseId)) {
      throw new Error('Please select a lease');
    }
    const data: RequiredFileDeleteInput = {
      id,
    };
    return (
      await client.mutate<Mutation>({
        mutation: DELETE_LEASE_DOCUMENT,
        variables: {
          data,
        },
        refetchQueries: [{ query: GET_LEASE, variables: { id: leaseId } }],
        awaitRefetchQueries: true,
      })
    ).data?.requiredFileDelete as SuccessResponse;
  },
);
export const deleteDocuments = createAction(
  deleteDocumentsEvent,
  createDocumentsError,
  async (ids: string[], leaseId: string) => {
    if (!isValidString(leaseId)) {
      throw new Error('Please select a lease');
    }
    const promises = ids.map(async (id) => {
      const data: LeaseDocumentDeleteInput = {
        id,
      };
      return (
        await client.mutate<Mutation>({
          mutation: DELETE_LEASE_DOCUMENT,
          variables: {
            data,
          },
          refetchQueries: [{ query: GET_LEASE, variables: { id: leaseId } }],
          awaitRefetchQueries: true,
        })
      ).data?.leaseDocumentDelete;
    });

    Promise.all(promises);
  },
);
export const updateLeaseAction = createAction(
  updateLeaseEvent,
  updateLeaseError,
  async (data: LeaseUpdateInput) => {
    const response = await client.mutate({
      mutation: UPDATE_LEASE_QUERY,
      variables: {
        data,
      },
    });

    await client.cache.reset();
    return response.data;
  },
);

export const updateLeaseOptionalPreferencesAction = createAction(
  updateLeaseEvent,
  updateLeaseError,
  async (
    data: LeaseOptionalPreferenceUpdateInput,
    filter: LeaseOptionalPreferenceKeyFilter,
  ) => {
    const response = await client.mutate({
      mutation: UPDATE_LEASE_OPTIONAL_PREFERENCES,
      variables: {
        data,
        filter,
      },
    });

    await client.cache.reset();
    return response.data;
  },
);

/**
 * Delete a Lease.
 */
export const deleteLeaseAction = createAction(
  OnLeaseReset,
  OnLeaseResetError,
  async (filter: LeaseFilter): Promise<void> => {
    await apolloClient.mutate({
      mutation: DELETE_LEASE,
      variables: {
        filter,
      },
    });
    await apolloClient.cache.reset();
  },
);

export const getAvailableOptionalPreferences = createAction(
  onFetchOptionalPreferencesEvent,
  onFetchOptionalPreferencesEventError,
  async (
    propertyId: string,
    leasePeriod: LeasePeriod,
    leaseId: string,
    isRenewal: boolean | undefined,
  ): Promise<OptionalPreferenceListResponse> => {
    const subPeriods =
      leasePeriod.leasePeriodPeriodSubPeriodRelation?.items.map(
        (subPeriod) => subPeriod.leaseSubPeriod?.id || '',
      ) || [];

    const filter: OptionalPreferenceFilter = {
      property: {
        id: {
          equals: propertyId,
        },
      },
      status: {
        equals: 'APPROVED',
      },

      OR: subPeriods?.map((subPeriod) => {
        return {
          subPeriod: {
            id: {
              equals: subPeriod,
            },
          },
        };
      }),

      optionalPreferenceLeaseRelation: {
        none: {
          lease: {
            id: {
              equals: leaseId,
            },
          },
        },
      },
      optionalPreferenceAvailabilityRelation: {
        some: {
          type: {
            equals: isRenewal ? 'RENEWAL_RESIDENT' : 'NEW_RESIDENT',
          },
          /* availability: {
            leasePeriod: {
              leasePeriodPeriodSubPeriodRelation: {
                some: { id: { in: subPeriods } },
              },
            },
          }, */
        },
      },
    };

    const {
      data: { optionalPreferencesList },
    } = await client.query<{
      optionalPreferencesList: OptionalPreferenceListResponse;
    }>({
      query: FETCH_OPTIONAL_PREFERENCES_QUERY,
      variables: {
        filter,
      },
    });

    return optionalPreferencesList;
  },
);

export const getLeaseOptionalPreferences = createAction(
  fetchLeaseOptionalPreferencesEvent,
  fetchLeaseOptionalPreferencesError,
  async (leaseId: string): Promise<LeaseOptionalPreferenceListResponse> => {
    const {
      data: { leaseOptionalPreferencesList },
    } = await client.query<{
      leaseOptionalPreferencesList: LeaseOptionalPreferenceListResponse;
    }>({
      query: LEASE_OPTIONAL_PREFERENCE_QUERY,
      variables: {
        leaseId,
      },
    });

    return leaseOptionalPreferencesList;
  },
);

export const deleteManyLeaseOptionalPreference = createAction(
  deleteLeaseOptionalPreferenceEvent,
  deleteLeaseOptionalPreferenceError,
  async (preferenceIds: string[], leaseId: string) => {
    if (preferenceIds?.length < 1) return;

    const filter: LeaseOptionalPreferenceFilter = {
      OR: preferenceIds.map((id) => ({
        id: {
          equals: id,
        },
      })),
    };

    try {
      await client.mutate({
        //mutation: DELETE_LEDGER_BY_OPTIONAL_PREFERENCE,
        mutation: DELETE_LEASE_PREFERENCES,
        variables: {
          filter,
        },
      });
    } catch {
      return;
    }

    /*     await client.mutate({
      mutation: DELETE_BY_FILTER_LEASE_OPTIONAL_PREFERENCES_MUTATION,
      variables: {
        filter,
      },
    }); */

    await client.cache.reset();
  },
);

export const deleteOptionalPreferenceLedger = createAction(
  deleteLeaseOptionalPreferenceEvent,
  deleteLeaseOptionalPreferenceError,
  async (ids: string[]) => {
    if (ids?.length < 1) return;

    try {
      await client.mutate({
        mutation: DELETE_LEDGER_BY_OPTIONAL_PREFERENCE,
        variables: {
          filter: {
            OR: ids.map((id) => ({
              optionalPreference: {
                id: {
                  equals: id,
                },
              },
            })),
          },
        },
      });
    } catch {
      return;
    }

    await client.cache.reset();
  },
);

export const createPropertyLedgerAction = createAction(
  createPropertyLedgerEvent,
  createPropertyLedgerError,
  async (data: PropertyLedgerCreateInput, id: string) =>
    (
      await client.mutate<Mutation>({
        mutation: CREATE_PROPERTY_LEDGER,
        variables: {
          data,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_PROPERTY_LEDGER_BY_FILTER,
            variables: {
              filter: {
                lease: {
                  id: { equals: id },
                },
              },
            },
          },
        ],
      })
    ).data?.propertyLedgerCreate as PropertyLedger,
);

export const createLedgerAction = createAction(
  createLedgerEvent,
  createLedgerError,
  async (data: LedgerCreateInput, leaseId: string) => {
    await client.mutate<Mutation>({
      mutation: CREATE_LEDGER,
      variables: {
        data,
      },
    });

    client.cache.reset();

    const result = await client.query<Query>({
      query: GET_LEDGERS_BY_FILTER,
      variables: {
        filter: {
          lease: {
            id: { equals: leaseId },
          },
        },
      },
    });

    return result.data?.ledgersList.items as Ledger[];
  },
);

export const createOptionalPreferenceLedger = createAction(
  createOptionalPreferenceLedgerEvent,
  createOptionalPreferenceLedgerError,
  async (data: LedgerCreateInput) => {
    const result = await client.mutate<Mutation>({
      mutation: CREATE_LEDGER,
      variables: {
        data,
      },
    });

    client.cache.reset();

    return result.data?.ledgerCreate as Ledger;
  },
);

export const reverseLedgersAction = createAction(
  createLedgerEvent,
  createLedgerError,
  async (ledgers: LedgerCreateInput[], leaseId: string, accountId: string) => {
    const createLedgerPromises = ledgers.map(async (ledger) =>
      client.mutate<Mutation>({
        mutation: CREATE_LEDGER,
        variables: {
          data: ledger,
        },
      }),
    );

    await Promise.all(createLedgerPromises);
    client.cache.reset();

    const createRefundPromises = ledgers.map((ledger: LedgerCreateInput) => {
      if (ledger.paymentType === LedgerPaymentTypes.ELECTRONIC_REFUND) {
        return client.mutate<Mutation>({
          mutation: CREATE_REFUND,
          variables: {
            ledger,
            accountId,
          },
        });
      }
      return Promise.resolve();
    });

    await Promise.all(createRefundPromises);

    const result = await client.query<Query>({
      query: GET_LEDGERS_BY_FILTER,
      variables: {
        filter: {
          lease: {
            id: { equals: leaseId },
          },
        },
      },
    });
    return result.data?.ledgersList.items as Ledger[];
  },
);

export const sendReceiptAction = createAction(
  sendReceiptEvent,
  sendReceiptError,
  async (id: string, type: string) => {
    const response = (
      await client.mutate<Mutation>({
        mutation: SEND_RECEIPT,
        variables: {
          id,
          type,
        },
      })
    ).data;

    return response?.sendReceiptResolver as SuccessResponse;
  },
);

export const updateLedgerAction = createAction(
  createLedgerEvent,
  createLedgerError,
  async (data: LedgerUpdateInput, leaseId: string) => {
    await client.mutate<Mutation>({
      mutation: UPDATE_LEDGER,
      variables: {
        data,
        id: data.id,
      },
    });

    client.cache.reset();

    const result = await client.query<Query>({
      query: GET_LEDGERS_BY_FILTER,
      variables: {
        filter: {
          lease: {
            id: { equals: leaseId },
          },
        },
      },
    });

    return result.data?.ledgersList.items as Ledger[];
  },
);

export const getLedgersByFilter = createAction(
  fetchLedgerEvent,
  fetchLedgerError,
  async (id: string) => {
    const response = await client.query<Query>({
      query: GET_LEDGERS_BY_FILTER,
      variables: {
        filter: {
          lease: {
            id: { equals: id },
          },
        },
      },
    });

    return response.data.ledgersList;
  },
);

//Fetch PropertyLedger
export const getPropertyLedgers = createAction(
  fetchPropertyLedgerEvent,
  fetchPropertyLedgerError,
  async (id: string) => {
    const response = await client.query<Query>({
      query: GET_PROPERTY_LEDGER_BY_FILTER,
      variables: {
        filter: {
          lease: {
            id: { equals: id },
          },
        },
      },
    });

    return response.data.propertyLedgersList;
  },
);

export const getGuarantorById = createAction(
  OnGetGuarantorById,
  OnGetGuarantorByIdError,
  async (id: string): Promise<Guarantor> => {
    const response = await client.query<{ guarantor: Guarantor }>({
      query: GET_GUARANTOR_BY_ID,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    return response.data.guarantor;
  },
);

export const updateGuarantorAction = createAction(
  OnUpdateGuarantor,
  OnUpdateGuarantorError,
  async (data: LeaseUpdateInput) => {
    const response = await client.mutate({
      mutation: UPDATE_GUARANTOR_QUERY,
      variables: {
        data,
      },
    });

    return response.data.guarantorUpdate;
  },
);

export const getPaymentSubCategories = createAction(
  fetchPaymentSubCategoriesEvent,
  fetchPaymentSubCategoriesError,
  async () => {
    const response = await client.query<Query>({
      query: GET_PAYMENT_SUBCATEGORIES_LIST,
    });

    return response.data.paymentSubCategoriesList.items;
  },
);
export const getAccountSubCategories = createAction(
  fetchAccountSubCategoriesEvent,
  fetchAccountSubCategoriesError,
  async () => {
    const response = await client.query<Query>({
      query: GET_ACCOUNT_SUBCATEGORIES_LIST,
    });

    return response.data.accountSubCategoriesList.items;
  },
);

export const getAutoPays = createAction(
  fetchAutoPayEvent,
  fetchAutoPayError,
  async (id: string) => {
    const response = await client.query<Query>({
      query: GET_AUTOPAY_BY_FILTER,
      variables: {
        filter: {
          lease: {
            id: { equals: id },
          },
        },
      },
    });

    return response.data.autoPaysList.items;
  },
);

export const createAutoPayAction = createAction(
  createAutoPayEvent,
  createAutoPayError,
  async (data: AutoPayCreateInput) => {
    const response = await client.mutate<Mutation>({
      mutation: CREATE_AUTOPAY,
      variables: {
        data,
      },
      awaitRefetchQueries: true,
    });

    await apolloClient.cache.reset();

    return response.data?.autoPayCreate as AutoPay;
  },
);

export const updateAutoPayAction = createAction(
  updateAutoPayEvent,
  updateAutoPayError,
  async (data: AutoPayUpdateInput) => {
    const response = await client.mutate<Mutation>({
      mutation: UPDATE_AUTOPAY,
      variables: {
        id: data.id,
        data,
      },
      awaitRefetchQueries: true,
    });

    await apolloClient.cache.reset();

    return response.data?.autoPayUpdate as AutoPay;
  },
);

export const fetchUserActivities = createAction(
  OnFetchUserActivitiesEvent,
  OnFetchUserActivitiesError,
  async (
    leaseId: string,
    userId: string,
    createdById: string,
    sort: ActivityLogSort,
  ) => {
    let filter: ActivityLogFilter = {};
    filter = {
      user: {
        id: { equals: userId },
      },
    };

    if (leaseId) {
      filter = {
        ...filter,
        lease: {
          id: { equals: leaseId },
        },
      };
    }

    if (createdById) {
      filter = {
        ...filter,
        createdBy: {
          id: { equals: createdById },
        },
      };
    }

    const response = await client.query<Query>({
      query: FETCH_USER_ACTIVITIES,
      variables: {
        filter,
        sort,
      },
    });

    return response.data.activityLogsList.items.map(
      (activity) => activity as ActivityLog,
    );
  },
);

export const fetchSubcategories = createAction(
  onFetchSubcategoriesEvent,
  onFetchSubcategoriesError,
  async () => {
    const response = await client.query<Query>({
      query: FETCH_SUBCATEGROIES,
    });
    return response.data.accountSubCategoriesList.items.map(
      (activity) => activity as AccountSubCategory,
    );
  },
);

export const deleteLedgerByFilter = createAction(
  deleteLedgerByFilterEvent,
  deleteLedgerByFilterError,
  async (id: string): Promise<SuccessResponse | undefined> => {
    const filter = {
      ledgerOptionalPreference: {
        id: {
          equals: id,
        },
      },
    };

    const response = await client.mutate<Mutation>({
      mutation: DELETE_LEDGER_BY_FILTER,
      variables: {
        filter,
      },
    });
    return response.data?.ledgerDelete;
  },
);

export const createPaymentMethodAction = createAction(
  createPaymentMethodEvent,
  createPaymentMethodEventError,
  async (user: User, data: PaymentMethodCreateInput) => {
    const filter: PaymentMethodFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
    };

    const paymentMethodsResponse = await client.query<Query>({
      query: GET_PAYMENT_METHODS_BY_FILTER,
      variables: {
        filter,
      },
    });

    const isPrimary =
      paymentMethodsResponse.data?.paymentMethodsList.items.length > 0
        ? false
        : true;

    const fingerprintResponse = await client.mutate<Mutation>({
      mutation: GET_METHOD_SIGNATURE,
      variables: {
        paymentMethodId: data?.stripeId,
      },
    });

    const createPaymentMethodData = {
      ...data,
      isPrimary,
      fingerprint:
        fingerprintResponse?.data?.getPaymentMethodFingerprint?.fingerprint ||
        '',
    };
    const fingerprint = //@ts-expect-error pending package
      fingerprintResponse?.data?.getPaymentMethodFingerprint.fingerprint ?? '';
    const isMethodRegistered =
      paymentMethodsResponse.data?.paymentMethodsList.items.find((method) => {
        return method.fingerprint === fingerprint;
      });

    if (isMethodRegistered) {
      return false;
    }

    const response = await client.mutate<Mutation>({
      mutation: CREATE_PAYMENT_METHOD,
      variables: {
        data: createPaymentMethodData,
      },
    });

    await apolloClient.cache.reset();

    return response.data?.paymentMethodCreate as PaymentMethod;
  },
);

export const updatePaymentMethodByFilterAction = createAction(
  updatePaymentMethodByFilterEvent,
  updatePaymentMethodByFilterEventError,
  async (
    data: PaymentMethodUpdateByFilterInput,
    filter: PaymentMethodFilter,
  ) => {
    const response = await client.mutate<Mutation>({
      mutation: UPDATE_PAYMENT_METHOD_BY_FILTER,
      variables: {
        data,
        filter,
      },
    });

    await apolloClient.cache.reset();

    return response.data?.paymentMethodUpdate as PaymentMethod;
  },
);

export const updatePaymentMethodsByFilterAction = createAction(
  updatePaymentMethodsByFilterEvent,
  updatePaymentMethodsByFilterEventError,
  async (
    data: PaymentMethodUpdateByFilterInput,
    paymentMethodIds: string[],
  ) => {
    const filter: PaymentMethodFilter = {
      stripeId: {
        in: paymentMethodIds,
      },
    };

    const response = await apolloClient.mutate<{
      paymentMethodsResponseList: PaymentMethodManyResponse;
    }>({
      mutation: UPDATE_PAYMENT_METHODS_BY_FILTER,
      variables: {
        data,
        filter,
      },
    });
    apolloClient.cache.reset();
    return response?.data?.paymentMethodsResponseList;
  },
);

export const deletePaymentMethodByFilterAction = createAction(
  deletePaymentMethodByFilterEvent,
  deletePaymentMethodByFilterEventError,
  async (filter: PaymentMethodFilter) => {
    const response = await client.mutate<Mutation>({
      mutation: DELETE_PAYMENT_METHOD_BY_FILTER,
      variables: {
        filter,
      },
    });

    await apolloClient.cache.reset();

    return response.data?.paymentMethodDeleteByFilter;
  },
);

export const getPaymentMethodsAction = createAction(
  getPaymentMethodsEvent,
  getPaymentMethodsEventError,
  async (user: User) => {
    const filter: PaymentMethodFilter = {
      user: {
        id: {
          equals: user.id,
        },
      },
    };
    const response = await client.query<Query>({
      query: GET_PAYMENT_METHODS_BY_FILTER,
      variables: {
        filter,
      },
    });

    await apolloClient.cache.reset();

    return response.data?.paymentMethodsList.items as PaymentMethod[];
  },
);

export const getPaymentIntent = createAction(
  getPaymentIntentEvent,
  getPaymentIntentEventError,
  async (stripeAccountId: string, clientSecret: string) => {
    const response = await client.mutate<Mutation>({
      mutation: DELETE_PAYMENT_METHOD_BY_FILTER,
      variables: {
        stripeAccountId,
        clientSecret,
      },
    });

    await apolloClient.cache.reset();
    return response.data?.getPaymentIntent as { id: string; fee: number };
  },
);

export const getIntent = async (
  stripeAccountId: string,
  clientSecret: string,
): Promise<{
  id: string;
  fee: number;
  amount: number;
  transferData: number;
}> => {
  const response = await client.mutate<Mutation>({
    mutation: GET_PAYMENT_INTENT,
    variables: {
      stripeAccountId,
      clientSecret,
    },
  });

  await apolloClient.cache.reset();
  return response.data?.getPaymentIntent as {
    id: string;
    fee: number;
    amount: number;
    transferData: number;
  };
};

export const createOptionalPreference = createAction(
  createOptionalPreferenceLedgerEvent,
  createOptionalPreferenceLedgerError,
  async (data: LeaseOptionalPreferenceCreateInput) => {
    const result = await client.mutate<Mutation>({
      mutation: CREATE_LEASE_PREFERENCE,
      variables: {
        data,
      },
    });

    client.cache.reset();

    return result.data?.ledgerCreate as Ledger;
  },
);

export const getOptionalPreferenceInventoryAction = createAction(
  getInventoryEvent,
  getInventoryError,
  async (id: string): Promise<OptionalPreferenceInvetory[]> => {
    const {
      data: { optionalPreference },
    } = await client.query<{
      optionalPreference: OptionalPreference;
    }>({
      query: GET_INVENTORY,
      variables: {
        id,
      },
    });
    const inventory = [
      ...(optionalPreference.optionalPreferenceInvetoryRelation?.items || []),
    ];

    return [...inventory].sort((a, b) =>
      (a?.name || '').localeCompare(b?.name || '', undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
  },
);
