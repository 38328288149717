import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useCallAction, useSubscription } from '@8baselabs/react-simple-state';
import { fetchUserActivities } from '../../../modules/people/people-actions';
import { OnFetchUserActivitiesEvent } from '../../../modules/people/people-event';

import { Lease } from '@8baselabs/resident-io-shared';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { MainLoader } from '../MainLoader';
import { FeedItem } from './FeedItem';
import {
  CREATED_AT_DESC,
  CREATED_AT_ASC,
  sortTypes,
  ActivityLogRender,
} from '../../../modules/people/people-model';

import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxHeight: 800,
      overflowY: 'auto',
      padding: theme.spacing(4),
    },
    alignIcon: {
      verticalAlign: 'bottom',
    },
    timelineWithoutContent: {
      minHeight: 10,
    },
    timelineOpposite: {
      flex: 'inherit',
      width: 120,
    },
    timelineDot: {
      marginBottom: 0,
      marginTop: 0,
    },
    timelineDotHide: {
      opacity: 0,
    },
  }),
);

type ActivityFeedProps = {
  selectedLease?: Lease;
  userId: string;
  createdById?: string;
};

const TimelineFeedItemWithoutContent: React.FC = () => {
  const classes = useStyles();

  return (
    <TimelineItem
      classes={{
        root: classes.timelineWithoutContent,
      }}>
      <TimelineOppositeContent
        classes={{
          root: classes.timelineOpposite,
        }}>
        <Box height={3} />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          classes={{ root: classes.timelineDotHide }}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Box height={3} />
      </TimelineContent>
    </TimelineItem>
  );
};

const TimelineFeedItem: React.FC<{ activity: ActivityLogRender }> = ({
  activity,
}) => {
  const classes = useStyles();

  return (
    <TimelineItem>
      <TimelineOppositeContent
        classes={{
          root: classes.timelineOpposite,
        }}>
        {activity.renderCreatedAt && (
          <Typography variant="body2" color="textSecondary">
            {moment(activity.createdAt).format('MMM D, YYYY')}
          </Typography>
        )}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          variant="outlined"
          classes={{ root: classes.timelineDot }}
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <FeedItem activity={activity} />
      </TimelineContent>
    </TimelineItem>
  );
};

export const ActivityFeed: React.FC<ActivityFeedProps> = (props) => {
  const { selectedLease, userId, createdById } = props;
  const classes = useStyles();
  const [sortBy, setSortBy] = useState<string>(CREATED_AT_DESC);
  const [getUserActivities, loading] = useCallAction(fetchUserActivities);

  const [activities, setActivities] = useState<ActivityLogRender[]>([]);

  useEffect(() => {
    if (selectedLease || userId) {
      let sort;
      if (sortBy === CREATED_AT_ASC) {
        sort = { createdAt: 'ASC' };
      } else if (sortBy === CREATED_AT_DESC) {
        sort = { createdAt: 'DESC' };
      }
      getUserActivities(
        selectedLease?.id || '',
        userId,
        createdById || '',
        sort,
      );
    }
  }, [selectedLease, userId, createdById, sortBy, getUserActivities]);

  useSubscription(OnFetchUserActivitiesEvent, (activityLogs) => {
    if (activityLogs) {
      const activitiesSanitized = activityLogs.map((activity, index) => {
        if (index > 0) {
          const previousActivity = activityLogs[index - 1];
          const previousActivityCreatedAtMoment = moment(
            previousActivity.createdAt,
          );
          const activityCreatedAtMoment = moment(activity.createdAt);
          if (
            previousActivityCreatedAtMoment.isSame(
              activityCreatedAtMoment,
              'day',
            )
          ) {
            const sanitizedActivity = { ...activity } as ActivityLogRender;
            sanitizedActivity.renderCreatedAt = false;
            return sanitizedActivity;
          }
        }
        const sanitizedActivity = { ...activity } as ActivityLogRender;
        sanitizedActivity.renderCreatedAt = true;
        return sanitizedActivity;
      });

      setActivities(activitiesSanitized);
    }
  });
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h5">Activity Feed</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="SORT BY"
            select
            variant="outlined"
            value={sortBy}
            onChange={(e) => setSortBy(e?.target?.value)}>
            {/* <MenuItem style={{ color: '#919191' }} value="">
              Remove
            </MenuItem> */}
            {sortTypes.map((val) => {
              return (
                <MenuItem value={val} key={val}>
                  {val}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Box>
              <MainLoader />
            </Box>
          ) : activities && activities.length > 0 ? (
            <Timeline align="left">
              <TimelineFeedItemWithoutContent />
              {activities.map((activity, index) => (
                <TimelineFeedItem key={index} activity={activity} />
              ))}
            </Timeline>
          ) : (
            <Box p={2} paddingLeft={'0px'}>
              <Typography variant="body2">No activity found.</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
