import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  alpha,
} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { PRIMARY_COLOR } from '../../../theme';
// eslint-disable-next-line no-restricted-imports
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      borderRadius: theme.spacing(4),
      padding: theme.spacing(0, 3, 0, 1),
    },
    checked: {
      background: alpha(PRIMARY_COLOR, 0.085),
    },
    label: {
      fontSize: 14,
    },
  }),
);

type CheckboxProps = {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  checked,
  disabled,
  handleChange,
  style
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={`${classes.checkbox} ${checked ? classes.checked : undefined}`}
      control={
        <MaterialCheckbox
          style={style || {}}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          checked={checked}
          onChange={handleChange}
          name={name}
          color="primary"
          disabled={disabled}
        />
      }
      label={
        <Typography className={classes.label} variant="h6">
          {label}
        </Typography>
      }
    />
  );
};
