import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    email
    status
    firstName
    middleName
    aboutMe
    genderIdentify
    preferredName
    lastName
    homePhone
    gender
    address
    stripeCustomerId
    traitifyAssessmentID
    traitifyAssessmentResults
    userRole
    avatar {
      downloadUrl
      fileId
      filename
    }
    taxID
    phoneNumber
    emergencyPhoneNumber
    userUserDocumentRelation {
      items {
        id
        type
        document {
          id
          filename
          previewUrl
          downloadUrl
        }
      }
    }
    userTenantProfileRelation {
      id
      primaryFocus
      workPlace
      occupation
      workHours
      schoolName
      fieldStudy
      classLevel
      studyPlace
      likeToLiveWith
      roomType
      age
      smoke
      smokeRoommate
      car
      allergies
      pets
      petsRoommate
      esaAnimal
      cleaningHabits
      wakeUpHour
      goToBedHour
      loudMusic
      hostParties
      peopleOverOften
      cooking
      tvHoursPerDay
      aboutMe
      sms
      email
      facebook
      twitter
      linkedin
      pinterest
      instagram
      whatspp
      wechat
      snaptchat
      step
      vehicle
      vehicleDescription
      address2
      city
      zipCode
      instagram
      iWillBeA
      shedule
      social
      hostAParty
      goingToBed
      studying
      gradually
      cleaning
      drinking
      hasBeenEvicted
      hasCausedDamage
      hasBeenConvicted
      sms
      email
      fullLegalName
      proofIdentify {
        downloadUrl
        fileId
        filename
      }
      creditReport {
        downloadUrl
        fileId
        filename
      }
      backgroundReport {
        downloadUrl
        fileId
        filename
      }
      reasonableAcommodation {
        downloadUrl
        fileId
        filename
      }
      ssn {
        downloadUrl
        fileId
        filename
      }
      eSARequest {
        downloadUrl
        fileId
        filename
      }
    }
    userClientUserRelation {
      count
      items {
        role
        id
        client {
          clientClientUserRelation {
            items {
              id
              role
            }
          }
          id
          clientFee {
            id
          }
        }
      }
    }
    birthdate
    state
    school
    registerFinish
    timezone
    lang
    userType
    hasPendingGuarantorActions
    guarantorData {
      guarantorId {
        items {
          id
        }
      }
    }
    guarantorDocuments {
      items {
        id
        payStub1 {
          downloadUrl
          filename
          fileId
        }
        driveLicenseFront {
          downloadUrl
          filename
          fileId
        }
        driveLicenseBack {
          downloadUrl
          filename
          fileId
        }
        payStub2 {
          filename
          downloadUrl
          fileId
        }
      }
    }
    userTermsAndConditionsUserRelation(first: 1, sort: { createdAt: DESC }) {
      items {
        accepted
        termsAndCondition {
          id
        }
      }
    }
    selectedClient {
      id
      name
      clientClientUserRelation {
        items {
          role
          id
        }
      }
      clientPropertyRelation {
        items {
          id
          name
        }
      }
      clientFee {
        id
      }
    }
  }
`;

export const FETCH_SESSION_QUERY = gql`
  query fetchSessionQuery {
    user {
      ...UserFragment
    }
    termAndConditionsList(first: 1, sort: { createdAt: DESC }) {
      items {
        id
      }
    }
    fetchInvitation(email: "__loggedInUserEmail") {
      ... on InvitationAdmin {
        id
      }
      ... on InvitationPropertyOwner {
        id
      }
      ... on InvitationPropertyManager {
        id
      }
      ... on InvitationResident {
        id
      }
      ... on InvitationGuarantor {
        id
      }
      ... on TransferLease {
        id
      }
      # ... on Invitation {
      #   id
      # }
    }
    systemConfigsList(filter: { name: { not_contains: "AUTH" } }) {
      items {
        value
        name
      }
    }
  }

  ${UserFragment}
`;

export const SESSION_SUBSCRIPTION = gql`
  subscription SESSION_SUBSCRIPTION($user: ID!) {
    Users(
      filter: {
        mutation_in: update
        updatedFields: { contains: ["avatar"] }
        node: { id: { equals: $user } }
      }
    ) {
      mutation
      updatedFields
      node {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;
