import React, { useState } from 'react';
import { EventCalendar } from './components/EventCalendar';
import { Grid } from '@material-ui/core';
import { EventDialog } from './components/EventDialog';
import { PropertiesCardSelector } from '../../shared/components/PropertiesCardSelector';
import { useFetchAction } from '@8baselabs/react-simple-state';
import { fetchEventsAction } from './calendar-actions';

/**
 * @returns {React.FC} - Opportunities view.
 */
export const CalendarView: React.FC = () => {
  const [selectedProperty, setSelectedProperty] = useState<string>('');
  const [, loading] = useFetchAction(fetchEventsAction, [
    selectedProperty,
  ]);
  
  return (
    <>
      <Grid container xs={12}>
        <PropertiesCardSelector
          selectedPropertyId={selectedProperty}
          setSelectedPropertyId={setSelectedProperty}
          disabled={loading}
        />
        {selectedProperty.length ? (
          <>
            <EventCalendar selectedProperty={selectedProperty} />
          </>
        ) : null}
      </Grid>
      <EventDialog propertyId={selectedProperty} />
    </>
  );
};
